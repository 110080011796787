import {
    createCollector,
    createValidator,
    defined,
    maxLength,
    required,
    oneOf,
    dependOn,
    itemRules,
    convertItemErrorsToMap,
    minLength,
} from 'shared/validation'

import { BLOCK_KEYS, Playground as PlaygroundModel, Card as CardModel } from './rankBattleService'

import { AVAILABLE_CARDS_COUNT, CARD_TYPES, MAX_CARD_TEXT_LENGTH, MAX_CARD_DESCRIPTION_LENGTH } from './constants'

const RANK_BATTLE_VALIDATION_RULES_FIELDS = {
    [BLOCK_KEYS.PLAYGROUND]: {
        [PlaygroundModel.cardsCount]: [required(), oneOf(AVAILABLE_CARDS_COUNT)],
        [PlaygroundModel.cardType]: [required(), oneOf(Object.values(CARD_TYPES))],
        [PlaygroundModel.cardProportions]: [defined()],
    },
    [BLOCK_KEYS.CARDS]: [
        itemRules(
            {
                [CardModel.id]: [required()],
                [CardModel.text]: [
                    dependOn(({ parent }) => {
                        return parent[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardType] === CARD_TYPES.TEXT
                            ? required()
                            : null
                    }),
                    dependOn(({ parent }) => {
                        return parent[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardType] === CARD_TYPES.TEXT
                            ? minLength(1)
                            : null
                    }),
                    dependOn(({ parent }) => {
                        return parent[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardType] === CARD_TYPES.TEXT
                            ? maxLength(MAX_CARD_TEXT_LENGTH)
                            : null
                    }),
                ],
                [CardModel.imageUrl]: [
                    dependOn(({ parent }) => {
                        return parent[PlaygroundModel.cardType] === CARD_TYPES.IMAGE ? required() : null
                    }),
                ],
                [CardModel.description]: [maxLength(MAX_CARD_DESCRIPTION_LENGTH)],
            },
            convertItemErrorsToMap,
        ),
    ],
}

export const validateTabs = (tmpStructure, activeTabKey = null) => {
    const isValid = createValidator(RANK_BATTLE_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)
    const errors = createCollector(RANK_BATTLE_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)

    return {
        tmpStructure,
        errors,
        isHasError: !isValid,
    }
}
