import { cloneDeep } from 'lodash'
import { API__WEBHOOKS } from 'api'
import pagesService from 'common/services/PagesService'
import store from 'store'

import { BLOCK_SCHEMA } from 'pages/Editor/Tabs/Editor/schema'
import Normalizer from 'pages/Editor/Tabs/Editor/util/normalizer'
import DataSchema from 'pages/Editor/Tabs/Editor/util/schema'

import { getUniqueId } from '../utils/getUniqueId'

function addItem(pages, pageId, insertBeforeWithBlockId, blockType, blockData, blockId = null) {
    const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
    const updatedPage = pagesService.getPageById(newPages, pageId)

    let insertBeforeIndex = updatedPage.blocks.length
    if (insertBeforeWithBlockId) {
        insertBeforeIndex = _getBlockIndexById(updatedPage, insertBeforeWithBlockId)
        if (insertBeforeIndex === -1) throw Error('Not found block with id: ' + insertBeforeWithBlockId)
    }

    const id = blockId || getUniqueId()
    const newBlock = _createItem(blockType, blockData, id)
    updatedPage.blocks.splice(insertBeforeIndex, 0, newBlock)
    return { newBlock, newPages, updatedPage }
}

function _createItem(blockType, data, blockId) {
    blockType = parseInt(blockType)

    return _normalizeBlock({
        ...data,
        id: blockId,
        t: blockType,
    })
}

async function cloneItem(pages, pageId, blockId) {
    const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
    const updatedPage = pagesService.getPageById(newPages, pageId)
    const blockIndex = _getBlockIndexById(updatedPage, blockId)

    const sourceBlock = updatedPage.blocks[blockIndex]
    const clonedBlock = _createItem(sourceBlock.t, sourceBlock, getUniqueId())
    updatedPage.blocks.splice(blockIndex + 1, 0, clonedBlock)

    return { newPages, updatedPage, clonedBlock }
}

async function deleteItem(pages, pageId, blockId, projectId) {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()
    const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
    const updatedPage = pagesService.getPageById(newPages, pageId)
    const blockIndex = _getBlockIndexById(updatedPage, blockId)
    updatedPage.blocks.splice(blockIndex, 1)

    await API__WEBHOOKS.DELETE_BLOCKS_WEBHOOKS({
        organizationId: selectedOrganizationId,
        blockIds: [blockId],
        projectId,
    })
    return { newPages, updatedPage }
}

function _normalizeBlock(block) {
    for (const p in block) {
        if (Array.isArray(block[p])) {
            const pSchema = BLOCK_SCHEMA[`${block.t}.${p}`]
            if (pSchema) {
                // Если задана схема типа propName.subProp для массива, то нормализуем по ней все элементы массива
                const pNorm = new Normalizer(new DataSchema(pSchema))
                block[p] = block[p].map(e => pNorm.process(e))
            }
        }
    }
    const s = BLOCK_SCHEMA[block.t]
    const n = new Normalizer(new DataSchema(s))
    return n.process(block)
}

function moveUp(pages, pageId, blockId) {
    const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
    const updatedPage = pagesService.getPageById(newPages, pageId)

    const index = _getBlockIndexById(updatedPage, blockId)

    const movedBlock = updatedPage.blocks[index - 1]
    updatedPage.blocks[index - 1] = updatedPage.blocks[index]
    updatedPage.blocks[index] = movedBlock

    return { newPages, updatedPage, movedBlock }
}

function moveDown(pages, pageId, blockId) {
    const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
    const updatedPage = pagesService.getPageById(newPages, pageId)

    const index = _getBlockIndexById(updatedPage, blockId)

    const movedBlock = updatedPage.blocks[index + 1]
    updatedPage.blocks[index + 1] = updatedPage.blocks[index]
    updatedPage.blocks[index] = movedBlock
    return { newPages, updatedPage, movedBlock }
}

function _getBlockIndexById(updatedPage, blockId) {
    const blockIndex = updatedPage.blocks.findIndex(el => el.id === blockId)
    if (blockIndex === -1) throw Error('Invalid block id for clone operation: ' + blockId)
    return blockIndex
}

export default {
    addItem,
    cloneItem,
    deleteItem,
    moveUp,
    moveDown,
}
