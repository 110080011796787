import React from 'react'
import classNames from 'classnames'

import styles from './Card.module.scss'

const Card = ({ image, backgroundImage, title, isAloneCard, children }) => (
    <li
        className={classNames(styles.card, { [styles.isAloneCard]: isAloneCard })}
        style={{ backgroundImage: !!backgroundImage ? `url(${backgroundImage})` : 'none' }}
    >
        {!!image && (
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>
        )}
        <div className={styles.content}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.children}>{children}</div>
        </div>
    </li>
)

export default Card
