import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getTips, getWatchedTipsMap, putWatchedTipsMap, Tip, TIP_IDS } from './TipsService'

/**
 *
 * @param blocks array of BLOCK enum values
 * @param isMultiplayerGame boolean
 * @returns {{tips: Array<Tip>, hasUnwatchedTips: boolean, selectedTipIndex: number, markAsWatched: Function, switchSelectedTip: Function}}
 */
export function useBlockTips(blocks, isMultiplayerGame = false) {
    const [watchedTipsMap, setWatchedTipsMap] = useState(() => getWatchedTipsMap())
    const [hasUnwatchedTips, setHasUnwatchedTips] = useState(false)
    const [selectedTipIndex, setSelectedTipIndex] = useState(0)
    const [tips] = useState(() => getTips({ blocks, isMultiplayerGame }))

    const markAsWatched = tipId => {
        const _watchedTipsMap = cloneDeep(watchedTipsMap)
        _watchedTipsMap[tipId] = true
        setWatchedTipsMap(_watchedTipsMap)
        putWatchedTipsMap(_watchedTipsMap)
    }

    const switchSelectedTip = index => {
        let _index = index
        if (_index >= tips.length) _index = 0
        else if (_index < 0) _index = tips.length - 1
        setSelectedTipIndex(_index)
    }

    useEffect(() => {
        const existsBlockTypes = new Set(blocks.map(block => block.t))
        for (let [index, tip] of tips.entries()) {
            if (
                watchedTipsMap[tip.id] !== true &&
                (existsBlockTypes.has(tip.blockType) || (isMultiplayerGame && tip.id === TIP_IDS.MULTIPLAYER))
            ) {
                setSelectedTipIndex(index)
                setHasUnwatchedTips(true)
                break
            }
        }
    }, [watchedTipsMap])

    return { tips, hasUnwatchedTips, selectedTipIndex, markAsWatched, switchSelectedTip }
}
