import { useEffect } from 'react'

const useDoubleClick = (ref, { latency = 300, onSingleClick = () => null, onDoubleClick = () => null }) => {
    useEffect(() => {
        if (!ref?.current) return
        const clickRef = ref.current
        let clickCount = 0
        const handleClick = e => {
            clickCount += 1

            setTimeout(() => {
                if (clickCount === 1) onSingleClick(e)
                else if (clickCount === 2) onDoubleClick(e)

                clickCount = 0
            }, latency)
        }

        clickRef.addEventListener('click', handleClick)

        return () => {
            clickRef.removeEventListener('click', handleClick)
        }
    })
}

export default useDoubleClick
