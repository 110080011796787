import React from 'react'

import styles from './MobileLayout.module.scss'

const MobileLayout = ({ imageSrc }) => (
    <div className={styles.layout}>
        <div className={styles.mockup}>
            <span></span>
        </div>
        <div className={styles.image} style={{ backgroundImage: `url(${imageSrc})` }}></div>
    </div>
)

export default MobileLayout
