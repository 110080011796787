import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Select from 'components/Form/Select/Select'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import TextInput from 'components/Form/TextInput/TextInput'

import Preview from '../../../../../SlidingPuzzle/components/Preview/Preview'

import TabContent from '../../../components/TabContent/TabContent'
import GamePreview from '../../../components/GamePreview/GamePreview'

import { Playground as PlaygroundModel } from '../../slidingPuzzleService'
import { AVAILABLE_TILES_COUNT, GAME_TYPES, GAME_TYPES_OPTIONS } from '../../constants'

import styles from './Playground.module.scss'

const getTilesCountSelectLabel = tilesCount => `${Math.sqrt(tilesCount)}x${Math.sqrt(tilesCount)}`
const getGameTypeSelectLabel = gameType => {
    const option = GAME_TYPES_OPTIONS.find(option => option.value === gameType)
    if (!option) return ''
    return option.label
}

const Playground = ({ errors, structure, onUpdate, colorTheme, correctColor, isHighlightCorrect, fontFamily }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.playground}>
            <TabContent header={t('Playground')}>
                <div className={styles.block}>
                    <div className={styles.flexBox}>
                        <div className={styles.blockGameType}>
                            <Select
                                label={t('Game type')}
                                errorMessages={errors?.[PlaygroundModel.gameType]}
                                value={{
                                    value: structure[PlaygroundModel.gameType],
                                    label: getGameTypeSelectLabel(structure[PlaygroundModel.gameType]),
                                }}
                                onUpdate={option => onUpdate({ [PlaygroundModel.gameType]: option.value })}
                                options={GAME_TYPES_OPTIONS}
                            />
                        </div>
                        {structure[PlaygroundModel.gameType] === GAME_TYPES.IMAGE && (
                            <UploadImage
                                className={styles.uploadBox}
                                label={t('Image')}
                                isDisabledDelete
                                errorMessages={errors?.[PlaygroundModel.imageUrl]}
                                value={structure[PlaygroundModel.imageUrl]}
                                onUpdate={value => onUpdate({ [PlaygroundModel.imageUrl]: value })}
                            />
                        )}
                    </div>
                </div>
                <div className={classNames(styles.block, styles.blockLayout)}>
                    <Select
                        label={t('Layout')}
                        errorMessages={errors?.[PlaygroundModel.tilesCount]}
                        value={{
                            value: structure[PlaygroundModel.tilesCount],
                            label: getTilesCountSelectLabel(structure[PlaygroundModel.tilesCount]),
                        }}
                        onUpdate={option => onUpdate({ [PlaygroundModel.tilesCount]: option.value })}
                        options={AVAILABLE_TILES_COUNT.map(item => ({
                            value: item,
                            label: getTilesCountSelectLabel(item),
                        }))}
                    />
                </div>
                {structure[PlaygroundModel.gameType] === GAME_TYPES.IMAGE && (
                    <div className={classNames(styles.block, styles.blockShowNumbersOnTiles)}>
                        <Checkbox
                            className={styles.checkboxBox}
                            label={t('Show numbers on tiles')}
                            value={structure[PlaygroundModel.isShowImageNumbers]}
                            onUpdate={value => onUpdate({ [PlaygroundModel.isShowImageNumbers]: value })}
                        />
                    </div>
                )}
                <div className={styles.block}>
                    <Checkbox
                        className={classNames(styles.checkboxBox, styles.checkboxBoxCover)}
                        label={t('Show cover')}
                        value={structure[PlaygroundModel.isShowCover]}
                        onUpdate={v => onUpdate({ [PlaygroundModel.isShowCover]: v })}
                    />
                    {structure[PlaygroundModel.isShowCover] && (
                        <>
                            <TextInput
                                label={t('Header')}
                                isRequired
                                errorMessages={errors?.[PlaygroundModel.coverHeader]}
                                value={structure[PlaygroundModel.coverHeader]}
                                onUpdate={v => onUpdate({ [PlaygroundModel.coverHeader]: v })}
                            />
                            <TextInput
                                className={styles.inputBox}
                                label={t('Button text')}
                                isRequired
                                errorMessages={errors?.[PlaygroundModel.coverButtonText]}
                                value={structure[PlaygroundModel.coverButtonText]}
                                onUpdate={v => onUpdate({ [PlaygroundModel.coverButtonText]: v })}
                            />
                        </>
                    )}
                </div>
            </TabContent>
            <GamePreview>
                <Preview
                    isModal
                    isShowCover={structure.isShowCover}
                    coverHeader={structure.coverHeader}
                    coverButtonText={structure.coverButtonText}
                    tilesCount={structure.tilesCount}
                    gameType={structure.gameType}
                    imageUrl={structure.imageUrl}
                    isShowImageNumbers={structure.isShowImageNumbers}
                    colorTheme={colorTheme}
                    correctColor={correctColor}
                    isHighlightCorrect={isHighlightCorrect}
                    fontFamily={fontFamily}
                />
            </GamePreview>
        </div>
    )
}

export default Playground
