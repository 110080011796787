import React, { useEffect, useState } from 'react'

import Tooltip from 'components/Tooltip/Tooltip'

import { isValue } from 'utils/validator'

const TextInput = ({ value, id, name, tooltipText, descriptionText, schema, onUpdate, payload }) => {
    const [_value, setValue] = useState(value || '')
    const [additionalParams, setAdditionalParams] = useState({})

    useEffect(() => {
        setValue(value)
    }, [value])

    useEffect(() => {
        const newAdditionalParams = {}
        if (isValue(schema.min)) newAdditionalParams.min = schema.min
        if (isValue(schema.max)) newAdditionalParams.max = schema.max
        setAdditionalParams(newAdditionalParams)
    }, [schema.min, schema.max])

    const onChange = evt => {
        setValue(evt.target.value)

        if (schema.type === 'number') return

        onUpdate(id, { [name]: evt.target.value }, payload)
    }

    const onBlur = () => {
        if (schema.type !== 'number') return
        if (!_value) {
            setValue(schema.default)
            onUpdate(id, { [name]: schema.default }, payload)
        } else {
            const { min, max } = schema
            if (typeof min === 'number' && _value < min) {
                setValue(min)
                onUpdate(id, { [name]: min }, payload)
                return
            }
            if (typeof max === 'number' && _value > max) {
                setValue(max)
                onUpdate(id, { [name]: max }, payload)
                return
            }
            onUpdate(id, { [name]: _value }, payload)
        }
    }

    return (
        <div className="control-box">
            {!!schema.label?.length && (
                <p className="label">
                    {schema.label}
                    {<Tooltip tooltipText={tooltipText} />}
                </p>
            )}
            <div className="content input">
                <input
                    className="text"
                    type={schema.type || 'text'}
                    value={_value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...additionalParams}
                />
            </div>
            {!!descriptionText && <p className="description">{descriptionText}</p>}
        </div>
    )
}

export default TextInput
