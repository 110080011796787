import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { InView } from 'react-intersection-observer'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import AddListItemButton from '../../../../Controls/AddListItemButton/AddListItemButton'
import NavigationListBar from '../../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../../Controls/NavigationListBar/hooks/useNavigation'

import { QuizResult as ResultModel } from '../../../quizModels'

import { calculateResultDistribution } from '../../utils/utils'

import { VALIDATION_RULES } from '../../utils/constants'

import Result from '../../components/Result/Result'

import './ResultView.scss'

const ResultView = ({ tmpStructure, validationResults = {}, methods, pages, t }) => {
    const [probability, setProbability] = useState({})

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    const isAllowedAdd = tmpStructure.results.length < VALIDATION_RULES.maxResults

    useEffect(() => {
        onCalculateResultDistribution(tmpStructure.results)
    }, [])

    const onCalculateResultDistribution = resultList => {
        const prob = calculateResultDistribution(
            tmpStructure.questions,
            resultList.map(result => result.id),
        )
        setProbability(prob)
    }

    const onAddResult = () => {
        const result = new ResultModel()
        const newResultList = tmpStructure.results.concat(result)
        methods.onUpdateStructure(
            {
                results: newResultList,
            },
            () => {
                onCalculateResultDistribution(newResultList)
            },
        )
    }

    const onCloneResult = id => {
        const sourceResult = tmpStructure.results.find(card => card.id === id)
        const clonedResult = new ResultModel(sourceResult)
        const newResultList = tmpStructure.results.concat(clonedResult)
        methods.onUpdateStructure(
            {
                results: newResultList,
            },
            () => {
                onCalculateResultDistribution(newResultList)
            },
        )
    }

    const onRemoveResult = id => {
        const newResultList = tmpStructure.results.filter(item => id !== item.id)
        methods.onUpdateStructure({
            results: newResultList,
        })
        onCalculateResultDistribution(newResultList)
    }

    const onUpdateResult = (id, newFields) => {
        methods.onUpdateStructure({
            results: tmpStructure.results.map(result => (result.id === id ? { ...result, ...newFields } : result)),
        })
    }

    const onChangeResultPosition = (currentIndex, newIndex) => {
        const results = cloneDeep(tmpStructure.results)
        const movingItem = results[currentIndex]
        results[currentIndex] = results[newIndex]
        results[newIndex] = movingItem
        methods.onUpdateStructure({
            results: results,
        })
    }

    const getResultDistributionText = probability => {
        if (typeof probability === 'number') {
            return `${probability !== 0 ? '' : t('This result will never be displayed')}`
        }
        return false
    }

    const onSetFocusedItemId = async (inView, entry) => {
        if (inView) onSetFocusedElementId(entry.target)
    }

    return (
        <div className="result-view">
            <aside className="result-view__navigation">
                <NavigationListBar
                    titleOfItem={t('Result')}
                    focusedItemId={focusedElementId}
                    items={tmpStructure.results}
                    iconAccessor={'image'}
                    maxLength={VALIDATION_RULES.maxResults}
                    onClickItem={scrollContainerToElement}
                    onClickAddNew={onAddResult}
                />
            </aside>
            <div className="result-view__body">
                <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                    <div className="result-view__body-list-wrapper">
                        <ul className="result-view__body-list">
                            {tmpStructure.results.map((result, index) => (
                                <InView
                                    as="li"
                                    key={result.id}
                                    id={`item_${result.id}`}
                                    threshold={0.1}
                                    onChange={onSetFocusedItemId}
                                >
                                    <Result
                                        pages={pages}
                                        result={result}
                                        resultDistributionText={getResultDistributionText(probability[result.id])}
                                        index={index}
                                        listLength={tmpStructure.results.length}
                                        minResults={VALIDATION_RULES.minResults}
                                        maxResults={VALIDATION_RULES.maxResults}
                                        validationResults={validationResults[index]}
                                        methods={{
                                            onCloneResult,
                                            onRemoveResult,
                                            onUpdateResult,
                                            onChangeResultPosition,
                                        }}
                                    />
                                </InView>
                            ))}
                        </ul>
                        {isAllowedAdd && (
                            <AddListItemButton
                                title={t('Add result')}
                                length={tmpStructure.results.length}
                                onClick={onAddResult}
                            />
                        )}
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}

export default ResultView
