import React, { useRef } from 'react'

import useResize from 'hooks/useResize'

import CoverPreview from '../../../components/Modals/components/CoverPreview/CoverPreview'

import SpinButton from './components/SpinButton/SpinButton'
import WinArrow from './components/WinArrow/WinArrow'
import Sections from './components/Sections/Sections'
import Section from './components/Section/Section'
import SectionContent from './components/SectionContent/SectionContent'

import useWheelParams from './hooks/useWheelParams'

import { getSectionColors } from './utils'

import styles from './Preview.module.scss'

const Preview = ({
    colorTheme,
    colorScheme,
    fontFamily,
    sections,
    sectionType,
    spinImageUrl,
    isShowCover,
    coverHeader,
    coverButtonText,
    isModal = false,
}) => {
    const containerRef = useRef()
    const [containerWidth] = useResize(containerRef)

    const {
        wheelDiameter,
        wheelInnerDiameter,
        borderWidth,
        pinSize,
        arrowHeight,
        arrowTop,
        contentWidth,
        contentHeight,
        contentBottomGap,
    } = useWheelParams(containerWidth, sectionType, 360 / sections.length, sections.length)

    return (
        <div className={styles.preview}>
            {isShowCover && (
                <CoverPreview
                    isModal={isModal}
                    colorTheme={colorTheme}
                    headerText={coverHeader}
                    buttonText={coverButtonText}
                />
            )}
            <div className={styles.wheelSpin} ref={containerRef}>
                <div className={styles.wheelSpinContainer} style={{ width: wheelDiameter }}>
                    <SpinButton imageUrl={spinImageUrl} size={pinSize} />
                    <WinArrow colorTheme={colorTheme} size={arrowHeight} top={arrowTop} />
                    <Sections borderWidth={borderWidth} wheelSize={wheelDiameter}>
                        {sections.map((section, index) => {
                            const { backgroundColor, textColor } = getSectionColors(
                                colorTheme,
                                sections.length,
                                index,
                                colorScheme,
                            )

                            return (
                                <Section
                                    key={index}
                                    rotateDeg={(360 / sections.length) * index}
                                    sectionsCount={sections.length}
                                    backgroundColor={backgroundColor}
                                    wheelInnerDiameter={wheelInnerDiameter}
                                >
                                    <SectionContent
                                        width={contentWidth}
                                        bottom={pinSize / 2 + contentBottomGap}
                                        height={contentHeight}
                                        section={section}
                                        sectionType={sectionType}
                                        textColor={textColor}
                                        fontFamily={fontFamily}
                                    />
                                </Section>
                            )
                        })}
                    </Sections>
                </div>
            </div>
        </div>
    )
}

export default Preview
