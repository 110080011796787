import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { invertColor } from 'utils/colors'

import styles from './CoverPreview.module.scss'

const CoverPreview = ({ isModal = false, colorTheme, headerText, buttonText }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.coverPreview}>
            <div className={classNames(styles.coverBox, { [styles.isModalCoverBox]: isModal })}>
                {!headerText && !buttonText && (
                    <div className={styles.noData}>{t('You have not entered any data yet')}</div>
                )}
                {!!headerText && <div className={styles.header}>{headerText}</div>}
                {!!buttonText && (
                    <button
                        className={styles.button}
                        style={{
                            color: invertColor(colorTheme),
                            backgroundColor: colorTheme,
                        }}
                    >
                        {buttonText}
                    </button>
                )}
            </div>
        </div>
    )
}

export default CoverPreview
