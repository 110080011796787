import React from 'react'
import classNames from 'classnames'

import styles from './Banner.module.scss'

const Banner = ({ title, description, buttonText, buttonLink }) => (
    <div className={styles.banner}>
        <div className={styles.texts}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.buttonContainer}>
            <button
                className={classNames('button', styles.button)}
                data-variant="primary"
                data-size="lg"
                onClick={() => (window.location.href = buttonLink)}
            >
                {buttonText}
            </button>
        </div>
    </div>
)

export default Banner
