const openCenteredPopup = ({ url, title, width, height }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const screenWidth = getScreenWidth()
    const screenHeight = getScreenHeight()

    const systemZoom = screenWidth / window.screen.availWidth
    const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft
    const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${width / systemZoom}, 
      height=${height / systemZoom}, 
      top=${top}, 
      left=${left}
      `,
    )

    if (window.focus && newWindow) newWindow.focus()
}

const getScreenWidth = () => {
    if (window.innerWidth) {
        return window.innerWidth
    }
    return document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
}

const getScreenHeight = () => {
    if (window.innerHeight) {
        return window.innerHeight
    }
    return document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height
}

export default openCenteredPopup
