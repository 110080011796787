import React from 'react'
import { useTranslation } from 'react-i18next'

import { convertToStatus, formatExpirationDate, getSubscriptionIcon, isExpired } from '../../../../../../../../utils'

const ActiveSection = ({ recurringEnabled, expirationDate, billingCycleValue, isWaiting }) => {
    const { t } = useTranslation()
    const expired = isExpired(expirationDate)
    const statusIcon = getSubscriptionIcon(recurringEnabled, expired, isWaiting)
    const formattedExpirationDate = formatExpirationDate(billingCycleValue, expirationDate)

    return (
        <div className="subscriptions__state__item">
            <div className="subscriptions__state__item-icon-value">
                <img className="icon" src={statusIcon} alt="" />
                <div className="subscriptions__mobile-status-group">
                    <p className="subscriptions__status">{convertToStatus(expired, isWaiting)}</p>
                    <p className="subscriptions__info">
                        {isWaiting
                            ? `${t('Expires')}: ${formattedExpirationDate}`
                            : `${t('Until')} ${formattedExpirationDate}`}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ActiveSection
