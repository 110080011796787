import { PAYOUTS_STATUSES_DATA } from './constants'

export const getPayoutStatusText = status => {
    if (PAYOUTS_STATUSES_DATA[status]) return PAYOUTS_STATUSES_DATA[status].label
    return status
}

export const getPayoutStatusColor = status => {
    if (PAYOUTS_STATUSES_DATA[status]) return PAYOUTS_STATUSES_DATA[status].color
    return '#979797'
}

export const getSumWithCommission = (value, percent) => ((value * (percent / 100)) / 100).toFixed(2)
