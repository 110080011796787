import { SHAPES } from './constants'

export function getRoundedCanvas(sourceCanvas) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const width = sourceCanvas.width
    const height = sourceCanvas.height

    canvas.width = width
    canvas.height = height
    context.imageSmoothingEnabled = true
    context.drawImage(sourceCanvas, 0, 0, width, height)
    context.globalCompositeOperation = 'destination-in'
    context.beginPath()
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
    context.fill()
    return canvas
}

export const getFileType = (file, shape) => {
    if (shape === SHAPES.CIRCLE) return 'image/png'
    return file?.type || 'image/png'
}
