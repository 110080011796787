export const getAgreementData = (str, fallbackText) => {
    let url = str,
        text = fallbackText || str

    const [firstSeparatorIndex, lastSeparatorIndex] = [str.indexOf(' ('), str.lastIndexOf(')')]
    if (firstSeparatorIndex !== -1) {
        url = str.slice(0, firstSeparatorIndex)
        if (lastSeparatorIndex !== -1) text = str.substring(firstSeparatorIndex + 2, lastSeparatorIndex)
        else text = url
    }

    return {
        text,
        url,
    }
}
