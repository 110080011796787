import React, { useEffect, useRef } from 'react'

const useAnimationFrame = (payload, callback) => {
    const requestRef = useRef()
    const previousTimeRef = useRef()

    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current
            callback(payload, deltaTime)
        }
        previousTimeRef.current = time
        requestRef.current = requestAnimationFrame(animate)
    }

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(requestRef.current)
    }, [payload])
}

export default useAnimationFrame
