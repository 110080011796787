import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { createCollector, createValidator, isPasswordRules, required } from 'shared/validation'

import Toast from 'components/Toast/Toast'

import { API__USER } from 'api'

import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormPasswordInput from 'components/Form/components/Helpers/FormPasswordInput/FormPasswordInput'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'

const ChangePasswordForm = ({ userDetails, onSuccess = () => {} }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    const [errors, setErrors] = useState({})

    const [isDisabledButton, setIsDisabledButton] = useState(false)

    useEffect(() => {
        if (oldPassword.length && newPassword.length && newPassword2.length) {
            setIsDisabledButton(false)
        } else {
            setIsDisabledButton(true)
        }
    }, [oldPassword, newPassword, newPassword2])

    const onSubmit = async () => {
        setIsLoading(true)
        setErrors({})

        try {
            if (newPassword !== newPassword2) {
                setErrors({
                    newPassword: [t('Passwords must match')],
                    newPassword2: [t('Passwords must match')],
                })
                return
            }
            if (oldPassword === newPassword) {
                setErrors({
                    oldPassword: [t('Passwords must be different')],
                    newPassword: [t('Passwords must be different')],
                })
                return
            }

            const _oldPassword = oldPassword.trim()
            const _newPassword = newPassword.trim()
            const fields = {
                oldPassword: _oldPassword,
                newPassword: _newPassword,
            }
            const validationRules = {
                oldPassword: [required()],
                newPassword: isPasswordRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__USER.CHANGE_PASSWORD(userDetails.id, {
                oldPassword: _oldPassword,
                newPassword: _newPassword,
            })

            setOldPassword('')
            setNewPassword('')
            setNewPassword2('')

            await onSuccess()
            Toast('success', {
                message: t('Your password is changed successfully'),
            })
        } catch (err) {
            console.error(err)

            if (err.response) {
                const {
                    response: { status, data },
                } = err
                if (status === 400) {
                    if (data.message === 'Old password is not valid') {
                        setErrors({
                            oldPassword: [t('Password is not valid')],
                        })
                        return
                    }
                    if (data.message === 'New password is not valid') {
                        setErrors({
                            newPassword: [t('Password is not valid')],
                        })
                        return
                    }
                    if (data.message === "Old passwords doesn't matches") {
                        setErrors({
                            oldPassword: [t("Old passwords doesn't matches")],
                        })
                        return
                    }
                    if (data.message === 'New password matches with old password') {
                        setErrors({
                            newPassword: [t('Password matches with old password')],
                        })
                        return
                    }
                }
            }

            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form>
            <FormInputs>
                <FormInputBlock>
                    <FormPasswordInput
                        label={t('Current password')}
                        value={oldPassword}
                        controlType="password"
                        errorMessages={errors.oldPassword || []}
                        onUpdate={v => {
                            setOldPassword(v)
                            setErrors({ ...errors, oldPassword: [] })
                        }}
                    />
                </FormInputBlock>
                <FormInputBlock>
                    <FormPasswordInput
                        label={t('New password')}
                        value={newPassword}
                        controlType="password"
                        errorMessages={errors.newPassword || []}
                        onUpdate={v => {
                            setNewPassword(v)
                            setErrors({ ...errors, newPassword: [] })
                        }}
                    />
                </FormInputBlock>
                <FormInputBlock>
                    <FormPasswordInput
                        label={t('Retype new password')}
                        value={newPassword2}
                        controlType="password"
                        errorMessages={errors.newPassword2 || []}
                        onUpdate={v => {
                            setNewPassword2(v)
                            setErrors({ ...errors, newPassword2: [] })
                        }}
                    />
                </FormInputBlock>
            </FormInputs>
            <FormButtons>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary"
                    disabled={isDisabledButton || isLoading}
                    onClick={() => onSubmit()}
                >
                    {t('Save')}
                </button>
            </FormButtons>
        </Form>
    )
}

export default ChangePasswordForm
