import i18n from 'i18n'

export const STEPS_ENUM = {
    SELECT_GROUP: 1,
    SELECT_ROLE: 2,
}

export const TITLES = {
    [STEPS_ENUM.SELECT_GROUP]: i18n.t('Choose your sector'),
    [STEPS_ENUM.SELECT_ROLE]: i18n.t('Choose your profile'),
}
export const SUB_TITLES = {
    [STEPS_ENUM.SELECT_GROUP]: i18n.t('Choose the option that best represents you'),
    [STEPS_ENUM.SELECT_ROLE]: i18n.t('Choose the role that suits\nyou best'),
}
