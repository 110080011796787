import React from 'react'
import { withTranslation } from 'react-i18next'

import ImagePicker from 'components/Form/ImagePicker/ImagePicker'
import TextArea from 'components/Form/TextArea/TextArea'
import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import HiddenGroup from 'components/Form/HiddenGroup/HiddenGroup'

import TextInputWithPageSelection from '../../../../../components/TextInputWithPageSelection/TextInputWithPageSelection'

import { Card } from '../cardService'

import './CardEditor.scss'

const CardEditor = ({ pages, card, cardError, methods: { updateCard }, t }) => (
    <div className="card-editor" key={card.id}>
        <div className="card-editor__area">
            <div className="card-editor__cover-group">
                <div className="card-editor__group-item">
                    <ImagePicker
                        label={t('Card cover')}
                        value={card.coverImage}
                        errorMessages={cardError && cardError.coverImage}
                        onUpdate={value => updateCard(card.id, { [Card.coverImage]: value })}
                    />
                </div>
                <div className="card-editor__group-item">
                    <TextInput
                        label={t('1 st-page disclaimer')}
                        value={card.disclaimer}
                        data-test="card-disclaimer-input"
                        onUpdate={value => updateCard(card.id, { [Card.disclaimer]: value })}
                    />
                </div>
            </div>
            <div className="card-editor__detail-group">
                <div className="card-editor__group-item">
                    <TextInput
                        label={t('Header')}
                        value={card.header}
                        isRequired
                        errorMessages={cardError && cardError.header}
                        onUpdate={value => updateCard(card.id, { [Card.header]: value })}
                    />
                </div>
                <div className="card-editor__group-item">
                    <TextArea
                        label={t('Description')}
                        value={card.description}
                        onUpdate={value => updateCard(card.id, { [Card.description]: value })}
                    />
                </div>
                <div className="card-editor__group-item">
                    <UploadImage
                        label={t('Illustration')}
                        value={card.illustrationImage}
                        onUpdate={value => updateCard(card.id, { [Card.illustrationImage]: value })}
                    />
                </div>
                <HiddenGroup>
                    <ElementsGroup isEquallyWidth>
                        <TextInput
                            label={t('Button text')}
                            value={card.buttonText}
                            onUpdate={value => updateCard(card.id, { [Card.buttonText]: value })}
                        />
                        <TextInputWithPageSelection
                            pages={pages}
                            value={card.buttonLink}
                            onUpdate={value => updateCard(card.id, { [Card.buttonLink]: value })}
                        />
                    </ElementsGroup>
                </HiddenGroup>
            </div>
        </div>
    </div>
)

export default withTranslation('translations')(CardEditor)
