import React from 'react'

import styles from './Button.module.scss'

const Button = ({ text, onClickText, children }) => (
    <div className={styles.button}>
        {!!text && (
            <div className={styles.textBox}>
                <span className={styles.text} onClick={() => onClickText()}>
                    {text}
                </span>
            </div>
        )}
        {children}
    </div>
)

export default Button
