import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TextInput from 'components/Form/TextInput/TextInput'

import { GET_BANK } from 'api/questionsBanks'

import placeholderImage from './i/placeholder.svg'

import styles from './QuestionBankSettings.module.scss'

const QuestionBankSettings = ({ questionBank, errors, onUpdateStructure, onChangeBank }) => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [questionsCount, setQuestionsCount] = useState(0)

    useEffect(() => {
        GET_BANK(questionBank.bankId)
            .then(data => {
                setName(data.name)
                setQuestionsCount(data.questionsCount)
            })
            .catch(err => console.error(err))
    }, [questionBank.bankId])

    return (
        <>
            <div className={styles.title}>
                {t('Question bank')}: <span>{name}</span>
            </div>
            <button className="button" data-variant="primary" data-size="sm" onClick={() => onChangeBank()}>
                {t('Change')}
            </button>
            <div className={styles.playground}>
                <div className={styles.placeholder}>
                    <img src={placeholderImage} alt="" />
                </div>
                <div className={styles.texts}>
                    <div className={styles.textTitle}>{t('Select the number of questions to use in the quiz')}</div>
                    <div className={styles.textColored}>
                        {t('Must be no greater than {{max}}', { max: questionsCount })}
                    </div>
                    <div className={styles.input}>
                        <TextInput
                            controlType="number"
                            value={questionBank.questionsCount}
                            errorMessages={errors?.questionBank?.questionsCount}
                            onUpdate={value => {
                                onUpdateStructure({
                                    questionBank: {
                                        ...questionBank,
                                        questionsCount: value,
                                    },
                                })
                            }}
                            min={1}
                            max={questionsCount}
                        />
                    </div>
                    <p className={styles.note}>
                        {t(
                            'The specified number of questions will be randomly selected from the list with each new quiz.',
                        )}
                        &nbsp;
                        <a
                            className={styles.noteLink}
                            target="_blank"
                            href="https://help.interacty.me/content/how-to-create-a-question-bank-for-your-quiz"
                        >
                            {t('Read more')}
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default QuestionBankSettings
