import React from 'react'
import { useTranslation } from 'react-i18next'

import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormTextInput from 'components/Form/components/Helpers/FormTextInput/FormTextInput'

const Integration = ({ userDetails }) => {
    const { t } = useTranslation()

    return (
        <Form>
            <FormInputs>
                <FormInputBlock>
                    <FormTextInput label={t('Public API key')} value={userDetails.publicApiKey} isReadOnly={true} />
                </FormInputBlock>
            </FormInputs>
        </Form>
    )
}

export default Integration
