import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { IconWatches } from 'svg/index.js'
import Progress from 'components/Progress'

import OverBanner from './OverBanner/OverBanner'

const TrialProgressBanner = ({ startDate, expirationDate, isVisible }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const currentDate = moment()
    const numberOfDaysPassed = moment(currentDate).diff(startDate, 'days')
    const totalNumberOfDays = moment(expirationDate).diff(startDate, 'days')
    const numberOfDaysLeft = totalNumberOfDays - numberOfDaysPassed
    const isExpired = moment(expirationDate).diff(currentDate.valueOf()) <= 0

    function onClickLink({ state }) {
        const path = '/billing-and-payments?tab=subscription'
        history.push(path, { state })
    }

    if (!isVisible) return null

    return (
        <div>
            {!isExpired ? (
                <Progress
                    value={numberOfDaysPassed > 0 ? numberOfDaysPassed : 0.1} // тонкая начальная полоска, чтобы не выглядело неактивно
                    total={totalNumberOfDays}
                    title={t('{{totalNumberOfDays}}-days trial period', { totalNumberOfDays })}
                    formattedTextResult={t('{{count}} days left', { count: numberOfDaysLeft })}
                />
            ) : (
                <OverBanner
                    icon={<IconWatches />}
                    message={t('Sorry, the trial period is over. To continue, choose the plan that suits you.')}
                    btnText={t('Choose a plan')}
                    onAction={onClickLink}
                />
            )}
        </div>
    )
}

export default TrialProgressBanner
