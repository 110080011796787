import { defined, dependOn, itemRules, maxLength, required } from 'shared/validation'

import i18n from 'i18n'

import coverIcon from 'components/Modal/components/Body/components/SideNav/i/cover.svg'
import questions2Icon from 'components/Modal/components/Body/components/SideNav/i/questions2.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const PERSONALITY_TABS = {
    startView: 1,
    questionList: 2,
    resultList: 3,
}

export const PERSONALITY_TABS_ARRAY = [
    {
        id: PERSONALITY_TABS.startView,
        label: i18n.t('Start screen'),
        icon: coverIcon,
    },
    {
        id: PERSONALITY_TABS.questionList,
        label: i18n.t('Questions'),
        icon: questions2Icon,
    },
    {
        id: PERSONALITY_TABS.resultList,
        label: i18n.t('Results'),
        icon: resultsIcon,
    },
]

export const TAB_TO_FIELD_MAP = {
    [PERSONALITY_TABS.startView]: 'cover',
    [PERSONALITY_TABS.questionList]: 'questions',
    [PERSONALITY_TABS.resultList]: 'results',
}

export const PERSONALITY_LINKS_ARRAY = [
    {
        weight: 0,
        label: i18n.t('Unrelated'),
    },
    {
        weight: 1,
        label: i18n.t('Neutral'),
    },
    {
        weight: 2,
        label: i18n.t('Related'),
    },
]

export const VALIDATION_RULES = {
    minQuestions: 1,
    maxQuestions: 100,
    minAnswersInQuestion: 2,
    maxAnswersInQuestion: 50,
    minResults: 2,
    maxResults: 100,
}

const answerRules = {
    text: [dependOn(({ parent }) => (parent.isText ? required() : null)), maxLength(500)],
    imageLabel: [maxLength(40)],
    image: [dependOn(({ parent }) => (parent.isText ? required() : null))],
}

const questionRules = {
    text: [required(), maxLength(500)],
    image: [defined()],
    imageDisclaimer: [maxLength(100)],
    answers: [itemRules(answerRules)],
}

const resultRules = {
    header: [required(), maxLength(500)],
    description: [maxLength(5000)],
    image: [defined()],
    imageDisclaimer: [maxLength(100)],
}

export const PERSONALITY_RULES_FIELDS = {
    cover: {
        header: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(400),
        ],
        description: [maxLength(500)],
        buttonText: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(20),
        ],
        image: [defined()],
        imageDisclaimer: [maxLength(100)],
    },
    questions: [itemRules(questionRules)],
    results: [itemRules(resultRules)],
}
