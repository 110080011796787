import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'
import TextInput from 'components/Form/TextInput/TextInput'

import CURRENCIES from 'common/constants/currencies'

import { isInn } from 'utils/validator'

import ChooseBlock from './components/ChooseBlock/ChooseBlock'

import individualIcon from './i/individual.svg'
import companyIcon from './i/company.svg'
import arrowRight from './i/arrowRight.svg'

import styles from './ChooseCheckoutModal.module.scss'

const ChooseCheckoutModal = ({ onClose, onCheckout }) => {
    const { t } = useTranslation()
    const { paymentCurrency } = useSelector(state => state)
    const [isLoading, setIsLoading] = useState(false)
    const [isBusinessPayment, setIsBusinessPayment] = useState(false)

    const [isShowRussianPaymentDetails, setIsShowRussianPaymentDetails] = useState(false)

    const [companyName, setCompanyName] = useState('')
    const [inn, setInn] = useState('')

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (paymentCurrency === CURRENCIES.RUB && isBusinessPayment) setIsShowRussianPaymentDetails(true)
        else {
            setIsShowRussianPaymentDetails(false)

            setCompanyName('')
            setInn('')

            setErrors({})
        }
    }, [isBusinessPayment])

    const onChangePaymentDetails = (value, func, key) => {
        func(value)
        setErrors(prevState => {
            prevState[key] = ''
            return prevState
        })
    }

    const onSubmit = async () => {
        try {
            setIsLoading(true)
            const newErrors = {}
            if (isShowRussianPaymentDetails) {
                if (inn && !isInn(inn)) newErrors.inn = 'Некорректное значание ИНН'
            }

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors)
                return
            }

            await onCheckout(isBusinessPayment, { companyName: companyName || undefined, inn: inn || undefined })
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal isShowCloseIcon onClose={() => onClose()} closeIconClassName={styles.closeIcon}>
            <div className={styles.content}>
                <h3 className={styles.title}>{t('Choose how you want to pay')}</h3>
                <ul className={styles.chooseBlocks}>
                    <ChooseBlock
                        icon={individualIcon}
                        title={t('As an individual')}
                        description={t('Simple payment option')}
                        isSelected={!isBusinessPayment}
                        onSelect={() => setIsBusinessPayment(false)}
                    />
                    <ChooseBlock
                        icon={companyIcon}
                        title={t('As a company')}
                        description={t('More invoice details')}
                        isSelected={isBusinessPayment}
                        onSelect={() => setIsBusinessPayment(true)}
                    />
                </ul>
                {isShowRussianPaymentDetails && (
                    <div className={styles.paymentDetails}>
                        <h4 className={styles.paymentDetailsTitle}>
                            Укажите данные компании. Мы отобразим их в счете.
                        </h4>
                        <div className={styles.paymentDetailsInputs}>
                            <TextInput
                                label="Название компании"
                                value={companyName}
                                onUpdate={v => onChangePaymentDetails(v, setCompanyName, 'companyName')}
                                errorMessages={errors.companyName ? [errors.companyName] : []}
                                className={styles.paymentDetailsInputBox}
                                inputClassName={styles.paymentDetailsInput}
                            />
                            <TextInput
                                label="ИНН"
                                value={inn}
                                onUpdate={v => onChangePaymentDetails(v, setInn, 'inn')}
                                errorMessages={errors.inn ? [errors.inn] : []}
                                className={styles.paymentDetailsInputBox}
                                inputClassName={styles.paymentDetailsInput}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.buttonBox}>
                    <button
                        className={classNames('button', styles.button)}
                        data-size="lg"
                        data-variant="primary"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        {t('Proceed to checkout')}
                        <div className={styles.buttonIconBox}>
                            <img src={arrowRight} alt="" />
                        </div>
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ChooseCheckoutModal
