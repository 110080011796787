import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextArea from 'components/Form/TextArea/TextArea'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import TextInput from 'components/Form/TextInput/TextInput'
import Image from 'components/Form/Image'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'

import { FINAL_SCREEN_FIELDS } from '../utils'

import './FinalScreenTab.scss'

export const FinalScreenTab = ({ structure, validateErrors, methods: { updateStructure }, isDisabledDelete }) => {
    const { t } = useTranslation()
    return (
        <div className="memory-modal-final-screen">
            <div className="memory-modal-final-screen__workplace-wrapper">
                <ScrollableBlock>
                    <div className="memory-modal-final-screen__workplace">
                        <h3 className="memory-modal-final-screen__workplace-title">{t('Final screen')}</h3>
                        <div className="memory-modal-final-screen__workplace-content">
                            <TextInput
                                label={t('Header')}
                                isRequired
                                value={structure.finalScreen.header}
                                errorMessages={validateErrors.finalScreen?.header}
                                onUpdate={value => updateStructure({ [FINAL_SCREEN_FIELDS.header]: value })}
                            />
                            <TextArea
                                label={t('Description')}
                                value={structure.finalScreen.description}
                                onUpdate={value => updateStructure({ [FINAL_SCREEN_FIELDS.description]: value })}
                            />
                            <ElementsGroup>
                                <UploadImage
                                    label={t('Image')}
                                    value={structure.finalScreen.imageSrc}
                                    isDisabledDelete={isDisabledDelete}
                                    onUpdate={value => updateStructure({ [FINAL_SCREEN_FIELDS.imageSrc]: value })}
                                />
                                {structure.finalScreen.imageSrc && (
                                    <TextInput
                                        label={t('Image disclaimer')}
                                        value={structure.finalScreen.imageDisclaimer}
                                        onUpdate={value =>
                                            updateStructure({ [FINAL_SCREEN_FIELDS.imageDisclaimer]: value })
                                        }
                                    />
                                )}
                            </ElementsGroup>
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className="memory-modal-final-screen__preview-wrapper">
                <ScrollableBlock>
                    <div className="memory-modal-final-screen__preview">
                        <p className="memory-modal-final-screen__preview-title">{t('Approximate preview')}</p>
                        {structure.finalScreen.imageSrc ||
                        structure.finalScreen.header.length ||
                        structure.finalScreen.description.length ||
                        structure.finalScreen.imageDisclaimer.length ? (
                            <div className="memory-modal-final-screen__preview-content">
                                {structure.finalScreen.imageSrc && (
                                    <Image
                                        className="memory-modal-final-screen__preview-image"
                                        src={structure.finalScreen.imageSrc}
                                        alt="img"
                                    />
                                )}
                                <div className="memory-modal-final-screen__preview-header">
                                    {structure.finalScreen.header}
                                </div>
                                <div className="memory-modal-final-screen__preview-description">
                                    {structure.finalScreen.description}
                                </div>
                                <div className="memory-modal-final-screen__preview-image-disclaimer">
                                    {structure.finalScreen.imageDisclaimer}
                                </div>
                            </div>
                        ) : (
                            <p className="memory-modal-final-screen__preview-empty">
                                {t('You have not entered any data yet')}
                            </p>
                        )}
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}
