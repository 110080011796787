import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import { getFullUrlToSsr } from 'utils/router'
import { isEducation } from 'common/utils/permissions'

import CardsList from './components/CardsList/CardsList'
import Card from './components/Card/Card'
import Info from './components/Info/Info'
import TemplateCardsBlock from '../TemplateCardsBlock/TemplateCardsBlock'

import communityBgImage from './i/communityBg.png'
import drawGirlImage from './i/drawGirl.svg'
import templatesIcon from './i/templates.svg'
import pencilIcon from './i/pencil.svg'
import arrowIcon from './i/arrow.svg'

import styles from './EmptyProjectList.module.scss'

const EmptyProjectList = ({ hasAppliedFilter, onCreateProject }) => {
    const { t } = useTranslation()

    const _isEducation = isEducation()

    return (
        <div className={styles.emptyProjectList}>
            <CardsList>
                <Card
                    title={hasAppliedFilter ? t('No matching projects found') : t('Create your first project!')}
                    image={drawGirlImage}
                    isAloneCard={!_isEducation}
                >
                    <a
                        className={classNames(styles.button, styles.blueButton)}
                        href={getFullUrlToSsr('/template-gallery')}
                        target="_blank"
                    >
                        <img className={styles.buttonIcon} src={templatesIcon} alt="" />
                        {t('Create from template')}
                    </a>
                    <div className={styles.button} onClick={() => onCreateProject()}>
                        <img className={styles.buttonIcon} src={pencilIcon} alt="" />
                        {t('Create from scratch')}
                    </div>
                </Card>
                {_isEducation && (
                    <Card title={t('Browse projects in our Сommunity')} backgroundImage={communityBgImage}>
                        <a
                            className={classNames(styles.button, styles.greenButton)}
                            href={getFullUrlToSsr('/community')}
                            target="_blank"
                        >
                            {t('Go to Community')}
                            <img
                                className={classNames(styles.buttonIcon, styles.rightButtonIcon)}
                                src={arrowIcon}
                                alt=""
                            />
                        </a>
                    </Card>
                )}
            </CardsList>
            {_isEducation && (
                <Info>
                    <Trans
                        i18nKey="Think it's a good idea to always start with detailed instructions? Welcome to our <lnk>Help Center</lnk>"
                        components={{
                            lnk: <a href="https://help.interacty.me/" target="_blank" />,
                        }}
                    />
                </Info>
            )}
            <TemplateCardsBlock onCreateProject={onCreateProject} />
        </div>
    )
}

export default EmptyProjectList
