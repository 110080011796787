import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'

import { API__AUTH } from 'api'
import languages, { findLanguageByCode } from 'i18n/languages'
import { IconArrowDown } from 'svg'
import { COMMON__SET_LANGUAGE } from 'store/actions'
import { delay } from 'utils/common'
import useRouter from 'hooks/useRouter'

import Menu from '../Menu'

import styles from './Profile.module.scss'

function Profile({ i18n, menu, avaText, ava, firstName, fullName, t }) {
    const email = useSelector(state => state.user_details.email)

    const dispatch = useDispatch()
    const { pathname } = useRouter()

    const [isOpenedDropdown, setIsOpenedDropdown] = useState(false)

    const closeDropdown = useCallback(() => {
        setIsOpenedDropdown(false)
        setIsAwaiting(false)
    }, [])
    const toggleDropdown = useCallback(() => setIsOpenedDropdown(isOpened => !isOpened), [])

    const handleClickChangeLanguage = useCallback(
        code => evt => {
            evt.preventDefault()
            try {
                dispatch(COMMON__SET_LANGUAGE({ code }))
                document.location.reload()
            } catch (err) {
                console.error(err)
            }
        },
        [i18n],
    )

    const current_lng = findLanguageByCode(i18n.language)
    const other_lngs = languages.filter(el => el.is_visible && el.code !== i18n.language)

    const [isAwaiting, setIsAwaiting] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const openLngs = useCallback(() => {
        if (other_lngs.length) {
            setIsHovered(true)
        }
    }, [])
    const closeLngs = useCallback(async () => {
        if (other_lngs.length) {
            setIsHovered(false)
            if (!isAwaiting) {
                setIsAwaiting(true)
                await delay(1000)
                setIsAwaiting(false)
            }
        }
    }, [])

    return (
        <div className="profile">
            <OutsideClickHandler onOutsideClick={closeDropdown}>
                <div className="profile-top" onClick={toggleDropdown}>
                    <div
                        className="profile-top__ava"
                        style={{
                            backgroundImage: ava ? `url(${ava})` : 'none',
                            backgroundColor: ava ? 'transparent' : '#2990fb',
                        }}
                    >
                        {!ava && avaText}
                    </div>
                    <div className="profile-top__name">{firstName ? firstName : email.split('@')[0]}</div>
                    <div className={`profile-top__icon${isOpenedDropdown ? ' is-opened' : ''}`}>
                        <IconArrowDown />
                    </div>
                </div>
                <Menu show={isOpenedDropdown} align="right">
                    <div
                        className={`block block--2-1${isHovered || (!isHovered && isAwaiting) ? ' is-visible' : ''}`}
                        onMouseEnter={openLngs}
                        onMouseLeave={closeLngs}
                    >
                        <ul>
                            {other_lngs.map(el => (
                                <li
                                    key={el.code}
                                    className={`${el.is_enabled ? '' : 'is-disabled '}`}
                                    onClick={handleClickChangeLanguage(el.code)}
                                >
                                    <div>
                                        <img src={el.icon} alt="" />
                                        <span>{el.label}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="block block--2">
                        <div className="header-1">
                            <div
                                className="ava"
                                style={{
                                    backgroundColor: ava ? '#fff' : '#2990fb',
                                    backgroundImage: ava ? `url(${ava})` : 'none',
                                }}
                            >
                                {!ava && avaText}
                            </div>
                            <div className="info">
                                {fullName ? <div className="full_name">{fullName}</div> : null}
                                <div className="email">{email}</div>
                            </div>
                        </div>
                        <div className="divider" />
                        <ul className="items-2">
                            {menu.map((item, index) => {
                                if (!item) return null
                                const Icon = item.Icon
                                return (
                                    <li className={styles.item} key={index}>
                                        {!!Icon && (
                                            <div className={styles.icon}>
                                                <Icon isActive={pathname === item.to} />
                                            </div>
                                        )}
                                        {item.isExternal ? (
                                            <a href={item.to} onClick={closeDropdown} target="_blank">
                                                {item.label}
                                            </a>
                                        ) : (
                                            <NavLink activeClassName="is-active" to={item.to} onClick={closeDropdown}>
                                                {item.label}
                                            </NavLink>
                                        )}
                                        {!!item.labelIcon && (
                                            <div className={styles.labelIcon}>
                                                <img src={item.labelIcon} alt="" />
                                            </div>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="lng-wrap">
                            <div className="lng" onMouseEnter={openLngs} onMouseLeave={closeLngs}>
                                <img src={current_lng.icon} alt="" />
                                <span className={`${isHovered || (!isHovered && isAwaiting) ? 'is-hovered' : ''}`}>
                                    {current_lng.label}
                                </span>
                            </div>
                        </div>
                        <div className="divider" />
                        <div className="footer-1">
                            <button className="logout" onClick={() => API__AUTH.LOGOUT()}>
                                {t('Logout')}
                            </button>
                        </div>
                    </div>
                </Menu>
            </OutsideClickHandler>
        </div>
    )
}

export default withTranslation('translations')(Profile)
