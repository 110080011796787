import React from 'react'
import { useTranslation } from 'react-i18next'

import icon_addIframe from '../../i/embedIframe_icon_white.svg'

const EmbedIframe = ({ blockData: { t: type, height } }) => {
    const { t } = useTranslation()
    return (
        <div className={`block __${type}`} style={{ height: `${height}px` }}>
            <img className="ico" alt="logo iframe" src={icon_addIframe}></img>
            <p>{t('Iframe is displayed in preview mode or after publishing')}</p>
        </div>
    )
}

export default EmbedIframe
