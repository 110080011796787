import React, { useEffect, useState } from 'react'
import { imgLoader } from 'common/services/imageLoader'
import Image from 'components/Form/Image'
import invertColor from '../../../../util/invertColor'

import { Slide } from './slideService'
import './SlideshowPreview.scss'

const SlideshowPreview = ({ colorTheme, slides = [], textColor, isTransparentBackground }) => {
    const [firstSlide, setFirstSlide] = useState(() => {
        if (Array.isArray(slides)) {
            return slides[0]
        }
        return new Slide({})
    })

    useEffect(() => {
        if (Array.isArray(slides)) setFirstSlide(slides[0])
    }, [slides])

    return (
        <div className="slideshow__container">
            <div className="slideshow__container-thumbnails">
                <div className="slideshow__container-thumbnails-items">
                    {slides.map((slide, index) => (
                        <div
                            key={`thumbnails-img-${index}`}
                            className={`slideshow__container-thumbnails-items-img ${
                                firstSlide.id === slide.id ? 'selected' : ''
                            }`}
                            style={{
                                backgroundImage: `url(${imgLoader(slide.image)})`,
                                backgroundColor: isTransparentBackground ? 'transparent' : colorTheme,
                            }}
                        />
                    ))}
                </div>
            </div>
            <div
                className="slideshow__container-carousel"
                style={{
                    backgroundColor: isTransparentBackground ? 'transparent' : colorTheme,
                }}
            >
                <div className="slideshow__container-carousel-items">
                    <Image
                        className="slideshow__container-carousel-items-img"
                        src={firstSlide.image}
                        style={{ objectFit: 'contain' }}
                    />
                </div>
            </div>
            <div className="slideshow__container-descriptions">
                {firstSlide.hasTextOptions && firstSlide.header ? (
                    <div className="slideshow__container-descriptions-header" style={{ color: textColor }}>
                        {firstSlide.header}
                    </div>
                ) : null}
                {firstSlide.description ? (
                    <div className="slideshow__container-descriptions-text" style={{ color: textColor }}>
                        {firstSlide.description}
                    </div>
                ) : null}
                {firstSlide.hasTextOptions && firstSlide.imageCaption ? (
                    <div className="slideshow__container-descriptions-image-caption" style={{ color: textColor }}>
                        {firstSlide.imageCaption}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SlideshowPreview
