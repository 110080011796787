import React from 'react'
import classNames from 'classnames'

import LoaderIcon from './svg/Loader'

import styles from './Button.module.scss'

const Button = ({
    className,
    color = '#fff',
    backgroundColor = '#2990FB',
    borderColor = backgroundColor,
    onClick = () => {},
    isDisabled = false,
    isLoading = false,
    children,
}) => (
    <button
        className={classNames(styles.button, { [className]: !!className, [styles.isDisabledButton]: isDisabled })}
        style={{ color, backgroundColor, borderColor }}
        onClick={onClick}
    >
        {isLoading && (
            <div className={styles.loading} style={{ backgroundColor }}>
                <LoaderIcon color={color} />
            </div>
        )}
        {children}
    </button>
)

export default Button
