import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './MainInfo.module.scss'

const MainInfo = ({ title, description, isDisabled = false, isSelected = false, onSelect }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.mainInfo}>
            <div
                className={classNames(styles.leftGroup, { [styles.isDisabledLeftGroup]: isDisabled })}
                onClick={onSelect}
            >
                <div className={classNames(styles.circle, { [styles.isActiveCircle]: isSelected })} />
                <div className={styles.texts}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
            {isDisabled && <div className={styles.disabledLabel}>{t('Coming soon')}</div>}
        </div>
    )
}

export default MainInfo
