import React, { useState, useEffect, useCallback, useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import { FinalScreenTab } from 'components/ModalTabs/FinalScreenTab'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import Pairs from './views/Pairs/Pairs'

import { INIT_STRUCTURE, TABS, TABS_ARRAY } from './utils/constants'

import { validateTabs } from './utils/validation'

import styles from './MatchingModal.module.scss'

const MatchingModal = ({ t, methods, data: { structure, colorTheme, id } }) => {
    const [tmpStructure, setTmpStructure] = useState(cloneDeep(INIT_STRUCTURE))
    const [activeTab, setActiveTab] = useState(TABS.pairs)
    const [validateErrors, setValidateErrors] = useState({})

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useEffect(() => {
        if (Object.keys(structure).length) {
            setTmpStructure(cloneDeep(structure))
        }
    }, [structure])

    const onSave = async () => {
        try {
            const validationResult = validateTabs(tmpStructure)
            if (!validationResult.hasError) {
                await methods.save({ [id]: { data: { struct: tmpStructure } } })
                onClose()
            } else {
                setValidateErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const onClose = () => {
        methods.closeModal()
    }

    const onUpdateStructure = type => newFields => {
        setTmpStructure(prevState => {
            return {
                ...prevState,
                [type]: {
                    ...prevState[type],
                    ...newFields,
                },
            }
        })
    }

    const onChangeTab = useCallback(
        tabId => {
            const validateErr = validateTabs(tmpStructure, activeTab)
            setValidateErrors(validateErr.errors)
            if (!validateErr.hasError) {
                setActiveTab(tabId)
            } else {
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            }
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Match Up options')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'MATCHING',
                }}
                onSave={onSave}
            >
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        {activeTab === TABS.pairs && (
                            <Pairs
                                t={t}
                                colorTheme={colorTheme}
                                structure={tmpStructure}
                                validateErrors={validateErrors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('pairs') }}
                            />
                        )}
                        {activeTab === TABS.finalScreen && (
                            <FinalScreenTab
                                structure={tmpStructure}
                                validateErrors={validateErrors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('finalScreen') }}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}

export default withTranslation('translations')(MatchingModal)
