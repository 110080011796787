import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import { CONDITIONS, hasPermission, isOwner, isSubscriptionExpired } from 'common/utils/permissions'
import { isBlockArticleType } from 'common/utils/isBlockArticleType'
import { getAvatarText } from 'common/utils/getAvatarText'

import projectModes from 'common/constants/projectModes'

import InlineInput from 'components/Form/InlineInput/InlineInput'
import { TooltipWrapper } from 'components/Tooltip/Tooltip'
import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog'

import {
    IconDelete,
    IconDuplicate,
    IconRename,
    IconVisible,
    IconHidden,
    IconHiddenPurple,
    IconDomain,
    IconMove,
    IconCrown,
} from 'svg'
import { getDate } from 'utils/dateTime'

import { PROJECT_STATUSES } from 'common/constants/projects'

import { MODAL_TYPES } from '../../modals/ModalController'

import ProjectStatistic from './components/ProjectStatistic/ProjectStatistic'
import PreviewBlock from './components/PreviewBlock/PreviewBlock'
import ActionButtons from './components/ActionButtons/ActionButtons'
import ProjectUpdateInfo from './components/ProjectUpdateInfo/ProjectUpdateInfo'

import { mapMemberName } from '../../utils/mapper'

import './ProjectListItem.scss'

const ProjectListItem = ({
    project,
    onOpenModal,
    onEditProject,
    onOpenStartGameModal,
    onOpenShareModal,
    onDuplicateProject,
    onRenameProject,
    onChangeProjectVisibility,
    isProjectCloning,
    onMoveProject,
    isLoading = false,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const {
        organizations: { selectedOrganizationId, selectedSubscription },
        user_details,
    } = useSelector(state => ({
        organizations: state.organizations,
        user_details: state.user_details,
    }))
    const { memberList } = useSelector(state => state.organizations)

    const getIsUserAuthor = () => project.author.userId === user_details.id
    const getIsUserOwnerAndAuthor = () => isOwner() && getIsUserAuthor()

    const [isUserAuthor, setIsUserAuthor] = useState(() => getIsUserAuthor())
    const [isUserOwnerAndAuthor, setIsUserOwnerAndAuthor] = useState(() => getIsUserOwnerAndAuthor())
    const [isEditableName, seIsEditableName] = useState(false)
    const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState(false)

    useEffect(() => {
        setIsUserAuthor(getIsUserAuthor())
        setIsUserOwnerAndAuthor(getIsUserOwnerAndAuthor())
    }, [selectedOrganizationId, project, user_details])

    const onSaveRename = val => {
        if (val !== project.name) {
            onRenameProject(project, val)
        }
        seIsEditableName(false)
    }

    const isPublished = project.projectStatus === PROJECT_STATUSES.PUBLISHED
    const isDraft = project.projectStatus === PROJECT_STATUSES.DRAFT
    const isTemplate = project.projectStatus === PROJECT_STATUSES.TEMPLATE

    const isMultiplayer = project.mode === projectModes.MULTIPLAYER

    const visibilityMarker = useMemo(() => {
        if (isLoading) return null

        return (
            <div className="project-list-item__top-right-box">
                {memberList.length > 1 && project.hidden && (
                    <div className="project-list-item__visibility-icon">
                        <TooltipWrapper
                            tooltipText={t(
                                'This project is hidden from the team members. Go to the project settings menu to make it visible to the team.',
                            )}
                        >
                            <span>
                                <IconHiddenPurple />
                            </span>
                        </TooltipWrapper>
                    </div>
                )}
                {!isTemplate && project.projectSubscription && !project.projectSubscription.expired && (
                    <TooltipWrapper
                        tooltipText={
                            <div className="project-list-item__status-indicator-tooltip">
                                <p>
                                    {t('Special plan')}:{' '}
                                    <a
                                        href="https://help.interacty.me/content/can-i-purchase-a-subscription-just-for-one-project"
                                        target="_blank"
                                    >
                                        {t(project.projectSubscription.productName)}
                                    </a>
                                </p>
                                <p>
                                    {t('Active until')}:{' '}
                                    <span>{getDate(project.projectSubscription.expirationDate)}</span>
                                </p>
                            </div>
                        }
                    >
                        <div className="project-list-item__upgraded-icon">
                            <IconCrown color="#fff" />
                            <p>{t(project.projectSubscription.productName)}</p>
                        </div>
                    </TooltipWrapper>
                )}
                {isMultiplayer && (
                    <div className={classNames('project-list-item__status-indicator', 'is-multiplayer')}>
                        <span>{t('Game event')}</span>
                    </div>
                )}
                <div
                    className={classNames('project-list-item__status-indicator', {
                        'is-published': isPublished,
                        'is-draft': isDraft,
                        'is-template': isTemplate,
                    })}
                >
                    {isPublished && <span>{t('Live')}</span>}
                    {isDraft && <span>{t('Draft')}</span>}
                    {isTemplate && <span>{t('Template')}</span>}
                </div>
            </div>
        )
    }, [memberList, project, isLoading, isPublished, isDraft, isTemplate, isMultiplayer])

    const dropdownMenuItems = useMemo(() => {
        if (isLoading) return []

        const list = [
            isBlockArticleType(project.projectType.projectType) && (
                <p
                    className={`project-list-item__dropdown-menu-item ${isProjectCloning ? 'is-loading' : ''}`}
                    onClick={evt => onDuplicateProject(project, evt)}
                >
                    <span className="project-list-item__dropdown-menu-icon">
                        <IconDuplicate />
                    </span>
                    {t('Clone project')}
                </p>
            ),
            !isTemplate && (
                <p
                    className="project-list-item__dropdown-menu-item"
                    onClick={
                        isSubscriptionExpired(project.projectSubscription)
                            ? evt => onOpenModal(MODAL_TYPES.subscriptionExpired, project, evt)
                            : () => seIsEditableName(true)
                    }
                >
                    <span className="project-list-item__dropdown-menu-icon">
                        <IconRename />
                    </span>
                    {t('Rename')}
                </p>
            ),
            memberList.length > 1 &&
                isUserOwnerAndAuthor &&
                (project.hidden ? (
                    <p
                        className="project-list-item__dropdown-menu-item"
                        onClick={() => onChangeProjectVisibility(project, false)}
                    >
                        <span className="project-list-item__dropdown-menu-icon">
                            <IconVisible />
                        </span>
                        {t('Make visible to the team')}
                    </p>
                ) : (
                    <p
                        className="project-list-item__dropdown-menu-item"
                        onClick={() => onChangeProjectVisibility(project, true)}
                    >
                        <span className="project-list-item__dropdown-menu-icon">
                            <IconHidden />
                        </span>
                        {t('Hide from the team')}
                    </p>
                )),
            !isMultiplayer && isPublished && (
                <p
                    className="project-list-item__dropdown-menu-item"
                    onClick={
                        hasPermission(CONDITIONS.CAN_USE_CUSTOM_DOMAINS) || project.projectSubscription
                            ? evt => onOpenModal(MODAL_TYPES.manageCustomDomains, { projectId: project.id }, evt)
                            : evt => onOpenModal(MODAL_TYPES.upgradeDialog, evt)
                    }
                >
                    <span className="project-list-item__dropdown-menu-icon">
                        <IconDomain />
                    </span>
                    {t('Manage domains')}
                </p>
            ),
            <p
                className="project-list-item__dropdown-menu-item"
                onClick={() => {
                    if (!selectedSubscription.expired) onMoveProject(project.id)
                    else setIsOpenUpgradeModal(true)
                }}
            >
                <span className="project-list-item__dropdown-menu-icon">
                    <IconMove />
                </span>
                {t('Move to folder')}
            </p>,
            !isTemplate && (
                <p
                    className="project-list-item__dropdown-menu-item"
                    onClick={evt => onOpenModal(MODAL_TYPES.deleteProjectModal, project, evt)}
                >
                    <span className="project-list-item__dropdown-menu-icon">
                        <IconDelete />
                    </span>
                    {t('Delete')}
                </p>
            ),
            isTemplate && (
                <p
                    className="project-list-item__dropdown-menu-item"
                    onClick={evt => onOpenModal(MODAL_TYPES.deleteTemplateModal, project, evt)}
                >
                    <span className="project-list-item__dropdown-menu-icon">
                        <IconDelete />
                    </span>
                    {t('Delete')}
                </p>
            ),
        ]

        return list.filter(item => !!item)
    }, [
        isMultiplayer,
        memberList,
        project,
        onOpenModal,
        onDuplicateProject,
        isProjectCloning,
        isUserOwnerAndAuthor,
        isLoading,
        selectedSubscription,
    ])

    return (
        <>
            <li className={classNames('project-list-item')}>
                {isMobile ? (
                    <div className="project-list-item__mobile-view">
                        <div className="project-list-item__mobile-view-header">
                            <div className="project-list-item__mobile-view-header--top">
                                <div className="project-list-item__mobile-view-title" title={project.name}>
                                    <InlineInput
                                        value={project.name}
                                        maxLength={128}
                                        isEditable={isEditableName}
                                        onSave={val => onSaveRename(val)}
                                        isLoading={isLoading}
                                    />
                                </div>
                                {visibilityMarker}
                            </div>
                            {!isLoading && (
                                <div className="project-list-item__author-info">
                                    {project.author.avatarUrl ? (
                                        <img
                                            className="project-list-item__author-info-icon"
                                            src={project.author.avatarUrl}
                                        />
                                    ) : (
                                        <div className="project-list-item__author-info-icon">
                                            {getAvatarText(project.author)}
                                        </div>
                                    )}
                                    <p className="project-list-item__author-info-title">
                                        {mapMemberName(project.author)}
                                    </p>
                                </div>
                            )}
                        </div>
                        <PreviewBlock project={project} isLoading={isLoading} />
                        {!isLoading && isPublished && (
                            <ProjectStatistic project={project} isMultiplayer={isMultiplayer} />
                        )}

                        <ActionButtons
                            isMultiplayer={isMultiplayer}
                            isUserAuthor={isUserAuthor}
                            project={project}
                            onOpenModal={onOpenModal}
                            onDuplicateProject={onDuplicateProject}
                            onEditProject={onEditProject}
                            onOpenStartGameModal={onOpenStartGameModal}
                            onOpenShareModal={onOpenShareModal}
                            isUserOwnerAndAuthor={isUserOwnerAndAuthor}
                            dropdownMenuItems={dropdownMenuItems}
                            isLoading={isLoading}
                            isSubscriptionExpired={isSubscriptionExpired(project.projectSubscription)}
                        />
                    </div>
                ) : (
                    <div className="project-list-item__desktop-view">
                        <PreviewBlock project={project} isLoading={isLoading} />
                        <div className="project-list-item__desktop-view-block">
                            <div className="project-list-item__desktop-view-info">
                                <div className="project-list-item__desktop-view-info-header">
                                    <div className="project-list-item__desktop-view-info-title" title={project.name}>
                                        <InlineInput
                                            value={project.name}
                                            maxLength={128}
                                            isEditable={isEditableName}
                                            onSave={val => onSaveRename(val)}
                                            isLoading={isLoading}
                                        />
                                    </div>

                                    {!isLoading && (
                                        <div className="project-list-item__author-info">
                                            {project.author.avatarUrl ? (
                                                <img
                                                    className="project-list-item__author-info-icon"
                                                    style={{ backgroundColor: '#fff' }}
                                                    src={project.author.avatarUrl}
                                                />
                                            ) : (
                                                <div
                                                    className="project-list-item__author-info-icon"
                                                    style={{ backgroundColor: '#2990fb' }}
                                                >
                                                    {getAvatarText(project.author)}
                                                </div>
                                            )}
                                            <p className="project-list-item__author-info-title">
                                                {mapMemberName(project.author)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <ProjectUpdateInfo project={project} isLoading={isLoading} />
                            </div>
                            <div className="project-list-item__desktop-view-actions">
                                {!isLoading && isPublished ? (
                                    <ProjectStatistic project={project} isMultiplayer={isMultiplayer} />
                                ) : (
                                    <div />
                                )}
                                <ActionButtons
                                    isMultiplayer={isMultiplayer}
                                    isUserAuthor={isUserAuthor}
                                    project={project}
                                    onOpenModal={onOpenModal}
                                    onDuplicateProject={onDuplicateProject}
                                    onEditProject={onEditProject}
                                    onOpenStartGameModal={onOpenStartGameModal}
                                    onOpenShareModal={onOpenShareModal}
                                    isUserOwnerAndAuthor={isUserOwnerAndAuthor}
                                    dropdownMenuItems={dropdownMenuItems}
                                    isLoading={isLoading}
                                    isSubscriptionExpired={isSubscriptionExpired(project.projectSubscription)}
                                />
                            </div>
                        </div>
                        {visibilityMarker}
                    </div>
                )}

                {project.isRemoved && (
                    <div className="project-list-item__deleted-view">
                        <div className="project-list-item__deleted-view-box">
                            <p>{t('The project has been deleted.')}</p>
                            <p>{t('This block will be hidden after page refresh')}</p>
                        </div>
                    </div>
                )}
            </li>

            {isOpenUpgradeModal && (
                <UpgradeDialog
                    onClose={() => setIsOpenUpgradeModal(false)}
                    onUpgrade={() => history.push('/billing-and-payments?tab=subscription')}
                />
            )}
        </>
    )
}

export default ProjectListItem
