import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { API__ANALYTICS } from 'api'

import useInitialLoading from 'hooks/useInitialLoading'

import { decodeStringsInObject } from 'common/utils/objectStringsAction'
import { PROJECTS__SET_PROJECT_SUBSCRIPTION } from 'store/actions'

import DashboardLayout from './layouts/Dashboard/Dashboard'
import GeographyLayout from './layouts/Geography/Geography'
import BlocksLayout from './layouts/Blocks/Blocks'

import Sidebar from './components/Sidebar/Sidebar'
import Band from './components/Band/Band'
import { getMenuItemsByProjectProjection } from './menu'

import './ProjectAnalytics.scss'

const ProjectAnalytics = ({
    location: { pathname },
    match: {
        url,
        params: { id },
    },
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isReady, onReady } = useInitialLoading()
    const [projectProjection, setProjectProjection] = useState(null)
    const [menuItems, setMenuItems] = useState(null)

    useEffect(() => {
        API__ANALYTICS.GET_PROJECT_PROJECTION(id).then(_projectProjection => {
            dispatch(PROJECTS__SET_PROJECT_SUBSCRIPTION(_projectProjection.subscription))
            setProjectProjection(decodeStringsInObject(_projectProjection))
            setMenuItems(getMenuItemsByProjectProjection(url, _projectProjection?.projections))
            onReady()
        })

        return () => {
            dispatch(PROJECTS__SET_PROJECT_SUBSCRIPTION(null))
        }
    }, [])

    return (
        <div className="project-analytics">
            <Helmet>
                <title>{t('Project analytics')} | Interacty</title>
            </Helmet>
            {isReady && (
                <>
                    <Band backPath="/my-projects" projectName={projectProjection.projectName} />
                    <div className="project-analytics__workplace">
                        <Sidebar menuItems={menuItems} activePathname={pathname} />
                        <div className="project-analytics-content">
                            <div className="project-analytics-content__inner">
                                <Switch>
                                    <Route
                                        exact
                                        path="/my-projects/:projectId/analytics"
                                        render={() => <DashboardLayout projectId={projectProjection.projectId} />}
                                    />
                                    <Route
                                        exact
                                        path="/my-projects/:projectId/analytics/geography"
                                        render={() => <GeographyLayout projectId={projectProjection.projectId} />}
                                    />
                                    <Route
                                        exact
                                        path="/my-projects/:projectId/analytics/blocks/:blockId"
                                        render={() => <BlocksLayout projectProjection={projectProjection} />}
                                    />
                                    <Redirect from="*" to={`/my-projects/${id}/analytics`} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ProjectAnalytics
