import React from 'react'
import Image from '../Image'
import { useAssetManager } from '../../AssetManager'

import './ImagePicker.scss'

const ImagePicker = ({
    label,
    value,
    onUpdate = () => {
        /**/
    },
    errorMessages = [],
}) => {
    const { chooseAsset } = useAssetManager()
    const isValueColor = value.includes('#') && value.length === 7

    return (
        <div className="image-picker">
            {label.length ? <p className="image-picker__label">{label}</p> : null}
            <div
                className={`image-picker__area ${errorMessages.length ? 'is-error' : ''}`}
                style={{ backgroundColor: isValueColor ? value : null }}
            >
                <Image className="image-picker__preview" src={isValueColor ? null : value} alt="" />
                <button className="image-picker__button" onClick={() => chooseAsset(value, onUpdate)} />
            </div>
            {errorMessages.length
                ? errorMessages.map((message, i) => (
                      <p key={i} className="image-picker__error">
                          {message}
                      </p>
                  ))
                : null}
        </div>
    )
}

export default ImagePicker
