import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AnalyticsMonitor from 'common/services/AnalyticsMonitor'

import Toast from 'components/Toast/Toast'
import Loader from 'components/Loader/Loader'

import { API__USER } from 'api'

import useRouter from 'hooks/useRouter'

import Authorization from './Authorization'

import useAction from '../hooks/useAction'
import { ACTIONS } from '../constants'

const Activation = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const { onChangeAction } = useAction()
    const { queryParams } = useRouter()

    useEffect(() => {
        setIsLoading(true)
        if (!queryParams.activationCode) {
            onChangeAction(ACTIONS.AUTHENTICATION)
            return
        }
        API__USER.ACTIVATE(queryParams.activationCode)
            .then(() => {
                AnalyticsMonitor.track('User activated', {})
            })
            .catch(err => {
                console.error(err)
                Toast('error', {
                    message: t('Activation failed...'),
                })
                onChangeAction(ACTIONS.AUTHENTICATION)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    if (isLoading) return <Loader />
    return <Authorization title={t('Success!')} description={t("Now log in and you're there")} />
}

export default Activation
