import React, { useState } from 'react'
import { cloneDeep, isEqual } from 'lodash'
import { InView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import { VALIDATION_RULES } from '../../utils/constants'
import Question from '../../components/Question/Question'
import LinkBar from '../../components/LinkBar/LinkBar'

import AddListItemButton from '../../../../Controls/AddListItemButton/AddListItemButton'
import NavigationListBar from '../../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../../Controls/NavigationListBar/hooks/useNavigation'

import { calculateResultDistribution } from '../../utils/utils'
import { Question as QuestionModel } from '../../utils/models'

import './QuestionView.scss'

const QuestionView = ({ tmpStructure, validationResults = {}, methods }) => {
    const { t } = useTranslation()
    const [isShowLinkBar, setIsShowLinkBar] = useState(false)
    const [selectedElements, setSelectedElements] = useState()
    const [probability, setProbability] = useState({})

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    const isAllowedAdd = tmpStructure.questions.length < VALIDATION_RULES.maxQuestions

    const onAddQuestion = () => {
        const question = new QuestionModel()
        const newQuestionList = tmpStructure.questions.concat(question)
        methods.onUpdateStructure(
            {
                questions: newQuestionList,
            },
            () => {
                if (isShowLinkBar) onCalculateResultDistribution(newQuestionList)
            },
        )
    }

    const onCloneQuestion = id => {
        const sourceQuestion = tmpStructure.questions.find(card => card.id === id)
        const clonedQuestion = new QuestionModel(sourceQuestion)
        const newQuestionList = tmpStructure.questions.concat(clonedQuestion)
        methods.onUpdateStructure(
            {
                questions: newQuestionList,
            },
            () => {
                if (isShowLinkBar) onCalculateResultDistribution(newQuestionList)
            },
        )
    }

    const onRemoveQuestion = id => {
        const newQuestionList = tmpStructure.questions.filter(item => id !== item.id)
        methods.onUpdateStructure({
            questions: newQuestionList,
        })
        if (isShowLinkBar) onCalculateResultDistribution(newQuestionList)
    }

    const onUpdateQuestion = (id, newFields) => {
        methods.onUpdateStructure({
            questions: tmpStructure.questions.map(question =>
                question.id === id ? { ...question, ...newFields } : question,
            ),
        })
    }

    const onChangeQuestionPosition = (currentIndex, newIndex) => {
        const questions = cloneDeep(tmpStructure.questions)
        const movingItem = questions[currentIndex]
        questions[currentIndex] = questions[newIndex]
        questions[newIndex] = movingItem
        methods.onUpdateStructure({
            questions: questions,
        })
    }

    const onOpenLinkBar = selectIds => {
        if (isEqual(selectedElements, selectIds)) {
            return
        }
        setSelectedElements(selectIds)
        setIsShowLinkBar(true)
        onCalculateResultDistribution(tmpStructure.questions)
    }

    const onCLoseLinkBar = () => {
        setSelectedElements(null)
        setIsShowLinkBar(false)
    }

    const onUpdateLinks = newAnswerList => {
        const newQuestionList = tmpStructure.questions.map(question =>
            question.id === selectedElements.questionId
                ? { ...question, [QuestionModel.answers]: newAnswerList }
                : question,
        )
        methods.onUpdateStructure({
            questions: newQuestionList,
        })
        if (isShowLinkBar) {
            onCalculateResultDistribution(newQuestionList)
        }
    }

    const onCalculateResultDistribution = questionList => {
        const prob = calculateResultDistribution(
            questionList,
            tmpStructure.results.map(result => result.id),
        )
        setProbability(prob)
    }

    const handleSectionVisible = (inView, entry) => {
        if (inView) onSetFocusedElementId(entry.target)
    }

    return (
        <div className="question-view">
            <div className={`question-view-wrapper ${isShowLinkBar ? 'is-slide' : ''}`}>
                <aside className="question-view__navigation">
                    <NavigationListBar
                        titleOfItem={t('Question')}
                        focusedItemId={focusedElementId}
                        items={tmpStructure.questions}
                        iconAccessor={'image'}
                        maxLength={VALIDATION_RULES.maxQuestions}
                        onClickAddNew={onAddQuestion}
                        onClickItem={scrollContainerToElement}
                    />
                </aside>
                <div className="question-view__body">
                    <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                        <div className="question-view__body-list-wrapper">
                            <ul className="question-view__body-list">
                                {tmpStructure.questions.map((question, index) => {
                                    return (
                                        <InView
                                            as="li"
                                            key={question.id}
                                            id={`item_${question.id}`}
                                            threshold={0.1}
                                            onChange={handleSectionVisible}
                                        >
                                            <Question
                                                question={question}
                                                qIndex={index}
                                                selectedElements={selectedElements}
                                                listLength={tmpStructure.questions.length}
                                                results={tmpStructure.results}
                                                maxAnswers={VALIDATION_RULES.maxAnswersInQuestion}
                                                minAnswers={VALIDATION_RULES.minAnswersInQuestion}
                                                maxQuestions={VALIDATION_RULES.maxQuestions}
                                                validationResults={validationResults[index]}
                                                methods={{
                                                    onOpenLinkBar,
                                                    onAddQuestion,
                                                    onCloneQuestion,
                                                    onRemoveQuestion,
                                                    onUpdateQuestion,
                                                    onChangeQuestionPosition,
                                                }}
                                                t={t}
                                            />
                                        </InView>
                                    )
                                })}
                            </ul>
                            {isAllowedAdd && (
                                <AddListItemButton
                                    title={t('Add question')}
                                    length={tmpStructure.questions.length}
                                    onClick={onAddQuestion}
                                />
                            )}
                        </div>
                    </ScrollableBlock>
                </div>
                {isShowLinkBar ? (
                    <aside className="question-view__link-bar">
                        <div className="question-view__link-bar-closer" onClick={onCLoseLinkBar} />
                        <LinkBar
                            selectedElements={selectedElements}
                            probability={probability}
                            tmpStructure={tmpStructure}
                            methods={{
                                onUpdateQuestion,
                                onCalculateResultDistribution,
                                onUpdateLinks,
                            }}
                            t={t}
                        />
                    </aside>
                ) : null}
            </div>
        </div>
    )
}

export default QuestionView
