import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import invertColor from '../../../../../util/invertColor'

import BlockTitle from '../BlockTitle/BlockTitle'

import { CARD_BACKGROUND_COLOR } from './constants'

import styles from './ColorBlock.module.scss'

const ColorBlock = ({ backgroundColor, onChange }) => {
    const { t } = useTranslation()

    return (
        <>
            <BlockTitle text={t('Card color')} />
            <ul className={styles.list}>
                {CARD_BACKGROUND_COLOR.map(color => (
                    <li
                        key={color}
                        className={classNames(styles.item, { [styles.isSelectedItem]: backgroundColor === color })}
                        style={{ backgroundColor: color, color: invertColor(color, true) }}
                        onClick={() => onChange(color)}
                    />
                ))}
            </ul>
        </>
    )
}

export default ColorBlock
