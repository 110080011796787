import React, { useMemo } from 'react'
import { DROPZONE, PLAYGROUND_PREVIEW_TABLE_DRAGZONE_CLASS, PLAYGROUND_PREVIEW_TABLE_DROPZONE_CLASS } from '../utils'

export const PuzzlePlaygroundPreviewTable = ({ isModal, height, numberOfCells, zoneType }) => {
    const className =
        zoneType === DROPZONE ? PLAYGROUND_PREVIEW_TABLE_DROPZONE_CLASS : PLAYGROUND_PREVIEW_TABLE_DRAGZONE_CLASS

    const tableRows = useMemo(() => {
        const rowsArr = []
        let rows = isModal ? numberOfCells / 2 : numberOfCells
        let cols = isModal ? numberOfCells : numberOfCells / 2

        if (zoneType === DROPZONE) {
            rows = numberOfCells
            cols = numberOfCells
        }

        for (let i = 0; i < rows; ++i) {
            const cells = []
            for (let j = 0; j < cols; ++j) {
                cells.push(
                    <td
                        key={j}
                        style={{ height: `${height / numberOfCells}px`, width: `${height / numberOfCells}px` }}
                    />,
                )
            }
            rowsArr.push(<tr key={i}>{cells}</tr>)
        }
        return rowsArr
    }, [isModal, height, numberOfCells, zoneType])

    return (
        <table className={className}>
            <tbody>{tableRows}</tbody>
        </table>
    )
}
