import { throttle } from 'lodash'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

const PanelHeader = ({ className = '', blockName, scrollRef }) => {
    const [titleClass, setTitleClass] = useState('')

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', throttle(handleScroll, 50))
        }

        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', throttle(handleScroll, 50))
            }
        }
    }, [scrollRef, titleClass])

    const handleScroll = evt => {
        try {
            const newTitleClass = evt.target.scrollTop > 0 ? 'is-sticky' : ''

            if (titleClass !== newTitleClass) {
                setTitleClass(newTitleClass)
            }
        } catch (err) {
            console.error(err)
        }
    }

    return !!blockName && <h3 className={classNames(className, titleClass)}>{blockName}</h3>
}

export default PanelHeader
