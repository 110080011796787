const Loader = ({ color = "#ffffff" }) => (
    <svg svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
         version="1.0" width="252px" height="69px" viewBox="0 0 128 35">
<g><circle fill={color} cx="17.5" cy="17.5" r="17.5"/>
    <animate attributeName="opacity" dur="1200ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1"
             values="0.3;1;1;0.3;0.3"/></g>
        <g><circle fill={color} cx="110.5" cy="17.5" r="17.5"/>
            <animate attributeName="opacity" dur="1200ms" begin="0s" repeatCount="indefinite"
                     keyTimes="0;0.334;0.5;0.835;1" values="0.3;0.3;1;1;0.3"/></g>
        <g><circle fill={color} cx="64" cy="17.5" r="17.5"/>
            <animate attributeName="opacity" dur="1200ms" begin="0s" repeatCount="indefinite"
                     keyTimes="0;0.167;0.334;0.668;0.835;1" values="0.3;0.3;1;1;0.3;0.3"/></g>
</svg>
)

export default Loader