import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import Checkbox from 'components/Form/Checkbox/Checkbox.jsx'

import LEADER_BOARD_STUDENTS from './i/Leaderboard-students.png'
import LEADER_BOARD_STUDENTS_2 from './i/Leaderboard-students-2.png'
import { delayGamificationPromo } from './utils/utils.js'

import './GamificationPromo.scss'

const GamificationPromo = ({ isOpen, onPublish, onUpSubscription }) => {
    const { t } = useTranslation()
    const [dontShowAgain, setDontShowAgain] = useState(false)

    const onAction = () => {
        if (dontShowAgain) {
            delayGamificationPromo()
        }
        onPublish()
    }

    return isOpen ? (
        <Modal closeOnEsc={false} closeOnOverlayClick={false}>
            <div className="gamification-promo-modal">
                <div className="gamification-promo-modal__images">
                    <img
                        className="gamification-promo-modal__img"
                        src={LEADER_BOARD_STUDENTS_2}
                        alt="gamification-promo-modal-img"
                    />
                    <img
                        className="gamification-promo-modal__img"
                        src={LEADER_BOARD_STUDENTS}
                        alt="gamification-promo-modal-img"
                    />
                </div>
                <div className="gamification-promo-modal__content">
                    <h3 className="gamification-promo-modal__announce">{t('Before you continue')}</h3>
                    <h2 className="gamification-promo-modal__title">{t('Enable player rating for Memory game?')}</h2>
                    <div className="gamification-promo-modal__description">
                        <p>{t('Students love to compete.')}</p>
                        <p>{t('Upgrade your plan to Intermediate to add a leaderboard to the game.')}</p>
                    </div>
                    <div className="gamification-promo-modal__buttons">
                        <button
                            className="gamification-promo-modal__button button"
                            data-size="lg"
                            data-variant="primary"
                            onClick={onUpSubscription}
                        >
                            {t('Upgrade')}
                        </button>
                        <button
                            className="gamification-promo-modal__button button"
                            data-size="lg"
                            data-variant="secondary"
                            onClick={onAction}
                        >
                            {t('Continue without leaderboard')}
                        </button>
                    </div>
                    <Checkbox value={dontShowAgain} onUpdate={setDontShowAgain} label={t('Do not show again')} />
                </div>
            </div>
        </Modal>
    ) : null
}

export default GamificationPromo
