import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast'

import useAnimationFrame from 'hooks/useAnimationFrame'

import { ASSET_MANAGER__ADD_UPLOAD_MEDIA } from 'store/actions/asset_manager'

import { NUMBER_OF_COLUMNS, UINT8ARRAY, AUDIO_FORMAT } from './constants'

import Preview from './components/Preview/Preview'
import Waves from './components/Waves/Waves'
import Controls from './components/Controls/Controls'

import styles from './VoiceRecorder.module.scss'

const VoiceRecorder = ({ onClose = () => {} }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isRecording, setIsRecording] = useState(false)

    const [mediaRecorder, setMediaRecorder] = useState(null)
    const [context, setContext] = useState(null)
    const [analyser, setAnalyser] = useState(null)
    const [columnsHeight, setColumnsHeight] = useState(Array.from(Array(NUMBER_OF_COLUMNS)).fill(0))
    const [audioBlob, setAudioBlob] = useState(null)
    const [audioElement, setAudioElement] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [audioName, setAudioName] = useState('')

    useEffect(() => {
        return () => stopRecord()
    }, [])

    useEffect(() => {
        if (!audioElement) {
            setIsPlaying(false)
            return
        }
        audioElement.addEventListener('ended', () => {
            setIsPlaying(false)
        })
        audioElement.addEventListener('pause', () => {
            setIsPlaying(false)
        })
        audioElement.addEventListener('play', () => {
            setIsPlaying(true)
        })
    }, [audioElement])

    useEffect(() => {
        if (!mediaRecorder) return
        mediaRecorder.addEventListener('start', () => {
            setIsRecording(true)
            setAudioBlob(null)
            setAudioElement(null)
            setAudioName('')
        })
        mediaRecorder.addEventListener('stop', () => {
            setIsRecording(false)
            setContext(null)
            setMediaRecorder(null)
            setAnalyser(null)
            setAudioName(`voice_${Date.now()}`)
        })
    }, [mediaRecorder])

    useAnimationFrame(analyser, _analyser => {
        const _columnsHeight = Array.from(Array(NUMBER_OF_COLUMNS)).fill(0)

        if (!_analyser) {
            setColumnsHeight(_columnsHeight)
            return
        }

        _analyser.getByteFrequencyData(UINT8ARRAY)
        for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
            _columnsHeight[i] = UINT8ARRAY[i + NUMBER_OF_COLUMNS]
        }
        setColumnsHeight(_columnsHeight)
    })

    const startRecord = () => {
        if (context) return

        const _context = new AudioContext()
        setContext(_context)
        const _analyser = _context.createAnalyser()

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
            })
            .then(stream => {
                const audioChunks = []

                const _mediaRecorder = new MediaRecorder(stream)
                setMediaRecorder(_mediaRecorder)

                _mediaRecorder.start()

                _mediaRecorder.addEventListener('start', () => {
                    const mediaStreamSource = _context.createMediaStreamSource(stream)
                    mediaStreamSource.connect(_analyser)
                    setAnalyser(_analyser)
                })

                _mediaRecorder.addEventListener('dataavailable', evt => audioChunks.push(evt.data))

                _mediaRecorder.addEventListener('stop', () => {
                    const audioBlob = new Blob(audioChunks, {
                        type: `audio/${AUDIO_FORMAT}`,
                    })

                    const audioUrl = URL.createObjectURL(audioBlob)

                    const audio = new Audio(null)
                    audio.setAttribute('src', audioUrl)
                    audio.load()

                    setAudioElement(audio)
                    setAudioBlob(audioBlob)
                })
            })
            .catch(err => {
                console.error(err)
                Toast('error', {
                    message: t(
                        'It looks like you did not grant access to use the microphone. Please change your browser settings and reload the page.',
                    ),
                    time: 6000,
                })
            })
    }

    const stopRecord = () => {
        if (mediaRecorder) mediaRecorder.stop()
        if (audioElement) audioElement.pause()
    }

    const saveRecord = () => {
        dispatch(ASSET_MANAGER__ADD_UPLOAD_MEDIA({ file: audioBlob, name: `${audioName.trim()}.${AUDIO_FORMAT}` }))
        close()
    }

    const close = () => {
        stopRecord()
        onClose()
    }

    return (
        <Modal isShowCloseIcon onClose={close} closeOnOverlayClick={false}>
            <div className={styles.voiceRecorder}>
                {!audioElement ? (
                    <>
                        <Controls
                            isRecording={isRecording}
                            startRecord={() => startRecord()}
                            stopRecord={() => stopRecord()}
                        />
                        {isRecording && <Waves columns={columnsHeight} />}
                    </>
                ) : (
                    <Preview
                        isPlaying={isPlaying}
                        name={audioName}
                        onChangeName={v => setAudioName(v)}
                        onSave={() => saveRecord()}
                        onPause={() => {
                            audioElement.pause()
                            audioElement.currentTime = 0
                        }}
                        onPlay={() => audioElement.play()}
                        onBack={() => {
                            if (audioElement) audioElement.pause()
                            setAudioBlob(null)
                            setAudioElement(null)
                            setAudioName('')
                        }}
                    />
                )}
            </div>
        </Modal>
    )
}

export default VoiceRecorder
