import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextInput from 'components/Form/TextInput/TextInput'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import Select from 'components/Form/Select/Select'
import UploadImage from 'components/Form/UploadImage/UploadImage'

import { LAYOUT_TEMPLATE, PLAYGROUND_FIELDS } from '../../utils'

import { PuzzlePlaygroundPreview } from '../../components'
import './Playground.scss'

export const Playground = ({ structure, colorTheme, validateErrors, methods: { updateStructure } }) => {
    const { t } = useTranslation()
    return (
        <div className="puzzle-modal-playground">
            <div className="puzzle-modal-playground__workplace-wrapper">
                <ScrollableBlock>
                    <div className="puzzle-modal-playground__workplace">
                        <h3 className="puzzle-modal-playground__workplace-title">{t('Playground')}</h3>
                        <div className="puzzle-modal-playground__workplace-content">
                            <div className="puzzle-modal-playground__layout-row">
                                <Select
                                    label={t('Layout')}
                                    value={structure.playground.layout}
                                    onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.layout]: value })}
                                    options={LAYOUT_TEMPLATE}
                                />
                                <UploadImage
                                    label={t('Image')}
                                    isDisabledDelete
                                    value={structure.playground.image}
                                    onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.image]: value })}
                                />
                            </div>
                            <Checkbox
                                label={t('Show cover')}
                                value={structure.playground.isShowCover}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.isShowCover]: value })}
                            />
                            {structure.playground.isShowCover && (
                                <>
                                    <TextInput
                                        label={t('Header')}
                                        isRequired
                                        isDisabled={!structure.playground.isShowCover}
                                        value={structure.playground.coverHeader}
                                        errorMessages={validateErrors.playground.coverHeader}
                                        onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.coverHeader]: value })}
                                    />
                                    <TextInput
                                        label={t('Button text')}
                                        isRequired
                                        isDisabled={!structure.playground.isShowCover}
                                        value={structure.playground.coverButtonText}
                                        errorMessages={validateErrors.playground.coverButtonText}
                                        onUpdate={value =>
                                            updateStructure({ [PLAYGROUND_FIELDS.coverButtonText]: value })
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className="puzzle-modal-playground__preview-wrapper">
                <ScrollableBlock>
                    <div className="puzzle-modal-playground__preview">
                        <p className="puzzle-modal-playground__preview-title">{t('Approximate preview')}</p>
                        <PuzzlePlaygroundPreview
                            colorTheme={colorTheme}
                            image={structure.playground.image}
                            layout={structure.playground.layout}
                            isShowCover={structure.playground.isShowCover}
                            coverHeader={structure.playground.coverHeader}
                            coverButtonText={structure.playground.coverButtonText}
                            height={240}
                            isModal={true}
                        />
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}
