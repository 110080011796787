import React from 'react'
import i18n from 'i18n/index.js'
import { FIELD_TYPES } from 'common/constants/fields.js'

export const getColumnView = field => {
    if (field.type === FIELD_TYPES.EMAIL) {
        return <a href={`mailto:${field.value}`}>{field.value}</a>
    }
    if (field.type === FIELD_TYPES.PHONE_NUMBER) {
        return <a href={`tel:${field.value}`}>{field.value}</a>
    }
    if (field.type === FIELD_TYPES.URL) {
        return <a href={field.value}>{field.value}</a>
    }
    if (field.type === FIELD_TYPES.CHECKBOX) {
        if (field.value === 'true') return i18n.t('Yes')
        if (field.value === 'false' || field.value === '') return i18n.t('No')
        if (field.value === true) return i18n.t('Yes')
        if (field.value === false) return i18n.t('No')
        return field.value
    }
    return field.value
}
