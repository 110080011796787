import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './CustomHeaderTitle.module.scss'

const CustomHeaderTitle = ({ onBack, label }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.customHeaderTitle}>
            <div className={styles.backButton} onClick={() => onBack()}>
                {t('Back to options')}
            </div>
            {label}
        </div>
    )
}

export default CustomHeaderTitle
