import i18n from 'i18n'

export const COST = {
    FREE: 'FREE',
    PAID: 'PAID',
}

export const PRODUCT_TYPE = {
    TEMPLATE: 'TEMPLATE',
    STATIC: 'STATIC',
}

export const CURRENCY = {
    USD: 'USD',
}

export const PRODUCT_TYPE_DATA = {
    [PRODUCT_TYPE.TEMPLATE]: {
        title: i18n.t('Sell as Interacty template'),
        description: i18n.t(
            'charges when somebody clones template on Interacty, they can create own projects using your template',
        ),
        defaultPriceValue: 1.99,
        prices: [0.99, 1.49, 1.99, 2.25, 2.99, 3.45, 3.99, 4.99, 7.0, 8.5, 9.45, 12.0, 14.5, 17.15, 21.5, 24.99, 29.75],
    },
    [PRODUCT_TYPE.STATIC]: {
        title: i18n.t(''),
        description: i18n.t(''),
        defaultPriceValue: 1.99,
        prices: [],
    },
}

export const SUBJECTS = [
    {
        value: 'ART_AND_MUSIC',
        label: 'Arts & Music',
    },
    {
        value: 'ENGLISH_LANGUAGE_ARTS',
        label: 'English Language Arts',
    },
    {
        value: 'WORLD_LANGUAGE',
        label: 'World Language',
    },
    {
        value: 'MATH',
        label: 'Math',
    },
    {
        value: 'SCIENCE',
        label: 'Science',
    },
    {
        value: 'SOCIAL_STUDIES_HISTORY',
        label: 'Social Studies - History',
    },
    {
        value: 'SPECIALTY',
        label: 'Specialty',
    },
    {
        value: 'HOLIDAYS_SEASONAL',
        label: 'Holidays / Seasonal',
    },
]

export const GRADE = [
    {
        value: 'PREK',
        label: 'PreK',
    },
    {
        value: 'K',
        label: 'K',
    },
    {
        value: '1ST',
        label: '1st',
    },
    {
        value: '2ND',
        label: '2nd',
    },
    {
        value: '3RD',
        label: '3rd',
    },
    {
        value: '4TH',
        label: '4th',
    },
    {
        value: '5TH',
        label: '5th',
    },
    {
        value: '6TH',
        label: '6th',
    },
    {
        value: '7TH',
        label: '7th',
    },
    {
        value: '8TH',
        label: '8th',
    },
    {
        value: '9TH',
        label: '9th',
    },
    {
        value: '10TH',
        label: '10th',
    },
    {
        value: '11TH',
        label: '11th',
    },
    {
        value: '12TH',
        label: '12th',
    },
    {
        value: 'NOT_GRADE_SPECIFIC',
        label: 'Not Grade Specific',
    },
    {
        value: 'HIGHER_EDUCATION',
        label: 'Higher Education',
    },
    {
        value: 'ADULT_EDUCATION',
        label: 'Adult Education',
    },
    {
        value: 'HOMESCHOOL',
        label: 'Homeschool',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
]
