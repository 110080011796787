import i18n from 'i18n'
import { CONDITIONS, isAppSumoUserOrBusinessSubscription } from 'common/utils/permissions'
import { isEuVersion } from 'common/constants/env'

import { isRussianClientCountry } from 'utils/common'

import iconGa from '../i/ga.png'
import iconGTM from '../i/gtm.png'
import iconEmail from '../i/mail.png'
import iconHs from '../i/hs.png'
import iconSf from '../i/sf.png'
import iconMch from '../i/mch.png'
import iconZapier from '../i/zapier.svg'
import iconWebhooks from '../i/webhooks.png'
import iconYm from '../i/ym.svg'

export const INTEGRATION_IDS = {
    ga: 'ga',
    gtm: 'gtm',
    email: 'email',
    hs: 'hs',
    sf: 'sf',
    mch: 'mch',
    zapier: 'zapier',
    webhooks: 'webhooks',
    ym: 'ym',
}

export const INTEGRATIONS_MAP = {
    [INTEGRATION_IDS.email]: {
        id: INTEGRATION_IDS.email,
        name: i18n.t('Email notifications'),
        description: i18n.t('Receive in the mail all the necessary information about the activities in your projects'),
        image: iconEmail,
        isChecked: false,
        isDisabled: false,
        settings: {
            email: '',
        },
        onCheckHidden: () => false,
    },
    [INTEGRATION_IDS.ym]: {
        id: INTEGRATION_IDS.ym,
        name: i18n.t('Yandex Metric'),
        description: i18n.t('Using Yandex Metric, you can track user actions in games and interactive blocks.'),
        image: iconYm,
        isChecked: false,
        isDisabled: false,
        settings: {
            id: '',
        },
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription() || !isRussianClientCountry(),
    },
    [INTEGRATION_IDS.ga]: {
        id: INTEGRATION_IDS.ga,
        name: i18n.t('Google Analytics'),
        description: i18n.t('Connect Google Analytics ID to collect statistics'),
        image: iconGa,
        isChecked: false,
        isDisabled: false,
        settings: {
            id: '',
        },
        condition: CONDITIONS.CAN_USE_GOOGLE_ANALYTICS,
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.mch]: {
        id: INTEGRATION_IDS.mch,
        name: i18n.t('mailchimp'),
        description: i18n.t('Bring your audience data, marketing channels, and insights together'),
        image: iconMch,
        isChecked: false,
        isDisabled: false,
        settings: {},
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.gtm]: {
        id: INTEGRATION_IDS.gtm,
        name: i18n.t('Google Tag Manager'),
        description: i18n.t('Connect Google Tag Manager ID to collect statistics'),
        image: iconGTM,
        isChecked: false,
        isDisabled: false,
        settings: {
            id: '',
        },
        condition: CONDITIONS.CAN_USE_GOOGLE_ANALYTICS,
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.webhooks]: {
        id: INTEGRATION_IDS.webhooks,
        name: i18n.t('Webhooks'),
        description: i18n.t('Use Webhooks to send Interacty data to a compatible web app or URL'),
        image: iconWebhooks,
        isChecked: false,
        isDisabled: false,
        settings: {
            url: '',
        },
        condition: CONDITIONS.CAN_USE_INTEGRATIONS,
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.zapier]: {
        id: INTEGRATION_IDS.zapier,
        name: i18n.t('Zapier'),
        description: '',
        image: iconZapier,
        isChecked: false,
        isDisabled: true,
        settings: {},
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.hs]: {
        id: INTEGRATION_IDS.hs,
        name: i18n.t('HubSpot'),
        description: '',
        image: iconHs,
        isChecked: false,
        isDisabled: true,
        settings: {},
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
    [INTEGRATION_IDS.sf]: {
        id: INTEGRATION_IDS.sf,
        name: i18n.t('salesforce'),
        description: '',
        image: iconSf,
        isChecked: false,
        isDisabled: true,
        settings: {},
        onCheckHidden: () => isEuVersion || !isAppSumoUserOrBusinessSubscription(),
    },
}
