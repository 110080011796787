import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import { isEducation } from 'common/utils/permissions'

import HelpBox from './components/HelpBox/HelpBox'
import FreeOffer from './components/FreeOffer/FreeOffer'

import closeIcon from './i/close.svg'
import girlImage from './i/girl.png'

import styles from './BaseModal.module.scss'

const BaseModal = ({ title, description, onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal closeOnEsc={true} closeOnOverlayClick={true} onClose={onClose}>
            <div className={styles.baseModal}>
                <div className={styles.close} onClick={onClose}>
                    <img src={closeIcon} alt="close" />
                </div>
                <div className={styles.imageBox}>
                    <img src={girlImage} alt="" />
                </div>
                <p className={styles.title}>{title}</p>
                <div className={styles.description}>{description}</div>
                <div className={styles.buttonBox}>
                    <Link className={styles.link} to="/billing-and-payments?tab=subscription" onClick={onClose}>
                        {t('Upgrade plan')}
                    </Link>
                </div>
                {isEducation() ? <FreeOffer /> : <HelpBox />}
            </div>
        </Modal>
    )
}

export default BaseModal
