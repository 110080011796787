import React from 'react'

import TextInput from 'components/Form/TextInput/TextInput'
import PasswordInput from 'components/Form/TextInput/Wrappers/PasswordInput/PasswordInput'

import styles from './Text.module.scss'

const Text = ({ ...props }) => {
    if (props.isSecure) {
        return <PasswordInput {...props} className={styles.text} />
    }

    return <TextInput {...props} className={styles.text} />
}

export default Text
