import React from 'react'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import { useAssetManager } from 'components/AssetManager'

import { Answer as AnswerModel } from '../../utils/models.js'

import './Answer.scss'

const Answer = ({
    answer,
    results,
    aIndex,
    isText,
    isSelected,
    isAllowedRemoveAnswer,
    validationResults = {},
    methods,
    t,
}) => {
    const linksLength = answer.links.filter(link => link.weight && results.map(({ id }) => id).includes(link.resultId))
        .length
    const { deleteAsset } = useAssetManager()
    const onDeleteAnswer = () => {
        if (answer.image) deleteAsset(answer.image, () => methods.onRemoveAnswer(answer.id))
        else methods.onRemoveAnswer(answer.id)
    }
    return (
        <div className="answer__wrapper">
            <div className="answer__body">
                <div className="answer__body-title">
                    <p className={`answer__body-title${isText ? '-required' : ''}`}>
                        {t('Answer')} {aIndex + 1}
                    </p>
                    <div className="answer__body-title-link" onClick={() => methods.onOpenLinkBar(answer)}>
                        <p className={classNames('answer__body-title-link-txt', { 'is-have-links': !!linksLength })}>
                            {linksLength ? t('Link to result: {{linksLength}}', { linksLength }) : t('Link to result')}
                        </p>
                    </div>
                </div>
                <div className="answer__body-content">
                    {isText ? (
                        <>
                            <TextInput
                                isFocused={isSelected}
                                isRequired
                                value={answer.text}
                                errorMessages={validationResults.text}
                                onUpdate={value => {
                                    methods.onUpdateAnswer(answer.id, { [AnswerModel.text]: value })
                                }}
                            />
                        </>
                    ) : (
                        <ElementsGroup>
                            <UploadImage
                                isDisabledDelete
                                value={answer.image}
                                onUpdate={value => {
                                    methods.onUpdateAnswer(answer.id, { [AnswerModel.image]: value })
                                }}
                            />
                            <TextInput
                                isFocused={isSelected}
                                value={answer.imageLabel}
                                errorMessages={validationResults.imageLabel}
                                onUpdate={value => {
                                    methods.onUpdateAnswer(answer.id, {
                                        [AnswerModel.imageLabel]: value,
                                    })
                                }}
                            />
                        </ElementsGroup>
                    )}
                </div>
                {isAllowedRemoveAnswer ? (
                    <div
                        className="answer__body-remove"
                        data-icon="remove"
                        title={t('Remove')}
                        onClick={onDeleteAnswer}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default Answer
