import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isUndefined } from 'lodash'
import classNames from 'classnames'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import PanelHeader from '../components/PanelHeader'
import MobileTongue from '../components/MobileTongue/MobileTongue'

import useControls from './hooks/useControls'

import { CONTROLS_COMPONENTS } from './constants'

const ControlPanel = ({
    schema,
    blockName,
    totalPagesCount,
    methods: { openModalByName },
    isMultiplayerGame,
    onUpdate,
    isApp,
    blockData,
    target,
    selectedBlockArrayProperty,
    integrations,
}) => {
    const panelScrollRef = useRef(null)
    const [isMobileOpened, setIsMobileOpened] = useState(false)

    const [data, setData] = useState({
        blockData: {},
        payload: {},
        schemaItemsWithControl: [],
        colorTheme: null,
    })

    const { controls } = useControls({
        isMultiplayerGame,
        target,
        typeIdentifier: !!selectedBlockArrayProperty
            ? `${blockData.t}.${selectedBlockArrayProperty.arrName}`
            : blockData.t,
        blockData,
        onUpdate,
        integrations,
        totalPagesCount,
    })

    useEffect(() => {
        let _blockData = cloneDeep(blockData),
            _schema = cloneDeep(schema),
            _payload = {}

        if (selectedBlockArrayProperty) {
            const { arrName, index, schema: arrayPropertySchema } = selectedBlockArrayProperty
            if (arrName && index >= 0 && arrayPropertySchema) {
                // редактирование подсвойства (элемента в массиве) блока, вместо самого блока
                _blockData = blockData[arrName][index]
                _schema = selectedBlockArrayProperty.schema
                _payload['selectedBlockArrayProperty'] = selectedBlockArrayProperty
            }
        }

        setData({
            blockData: _blockData,
            payload: _payload,
            schemaItemsWithControl: getSchemaItemsWithControl(_schema, _blockData),
            colorTheme: blockData?.colorTheme ? blockData.colorTheme : blockData.pcl || null,
        })
    }, [selectedBlockArrayProperty, blockData, schema])

    const getSchemaItemsWithControl = (s, d) => {
        const result = []
        for (const [key, value] of Object.entries(s)) {
            if (value.control && CONTROLS_COMPONENTS[value.control]) {
                result.push({
                    name: key,
                    value: d[key],
                    valueName: d['name'],
                    schema: value,
                })
            }
        }
        return result
    }

    const onControlUpdate = (id, data, payload) => {
        if (isApp) {
            onUpdate(data)
        } else {
            onUpdate({
                [id]: {
                    data,
                    payload,
                },
            })
        }
    }

    const getSchemaItemByName = (s, n) => s.find(item => item.name === n)

    return (
        <div
            className={classNames('editor-panel', 'editor-panel--controls', {
                'editor-panel--controls--opened': isMobileOpened,
            })}
            onClick={evt => {
                evt.preventDefault()
                evt.stopPropagation()
            }}
        >
            <PanelHeader blockName={blockName} scrollRef={panelScrollRef} />
            <MobileTongue isOpened={isMobileOpened} onChange={v => setIsMobileOpened(v)} />
            <ScrollableBlock styles={{ height: 'calc(100% - 56px)', width: '100%' }} scrollableNodeRef={panelScrollRef}>
                {controls.length ? (
                    <ul className="list">
                        {controls.map((group, i) => {
                            if (!group.isVisible) return null
                            const values = group.values
                                .map(item => {
                                    const el = getSchemaItemByName(data.schemaItemsWithControl, item.value)
                                    if (!el) return item

                                    if (!isUndefined(item.fieldValue) && item.fieldValue !== el.value) {
                                        onControlUpdate(blockData.id, { [el.name]: item.fieldValue })
                                    }
                                    return item
                                })
                                .filter(value => value.isVisible)

                            if (!values.length) return null

                            return (
                                <li key={i} className={classNames('group-block', { disabled: group.isDisabled })}>
                                    <p className="group-label">{group.label}</p>
                                    <ul>
                                        {values.map(item => {
                                            const el = getSchemaItemByName(data.schemaItemsWithControl, item.value)
                                            if (!el) return null

                                            const Component = CONTROLS_COMPONENTS[el.schema.control]
                                            return (
                                                <li key={item.value}>
                                                    <Component
                                                        id={blockData.id}
                                                        blockType={blockData.t}
                                                        isEnableRating={blockData.isEnableRating}
                                                        name={el.name}
                                                        value={el.value}
                                                        valueName={el.valueName}
                                                        tooltipText={item.tooltipText}
                                                        tooltipCallback={item.tooltipCallback}
                                                        descriptionText={item.descriptionText}
                                                        schemaName={item.value}
                                                        schema={el.schema}
                                                        onUpdate={onControlUpdate}
                                                        payload={data.payload}
                                                        openModalByName={openModalByName}
                                                        colorTheme={data.colorTheme}
                                                        fontFamily={blockData.fontFamily}
                                                        leadFormFontFamily={blockData.leadFormFontFamily}
                                                        leadFormBackgroundColor={blockData.leadFormBackgroundColor}
                                                        disabled={group.isDisabled || item.isDisabled}
                                                        isMultiplayerGame={isMultiplayerGame}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                ) : (
                    <p className="empty">No additional settings</p>
                )}
            </ScrollableBlock>
        </div>
    )
}

export default ControlPanel
