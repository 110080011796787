import React from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../Title/Title'

import styles from './PinBlock.module.scss'

const PinBlock = ({ pin }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.pinBlock}>
            <Title isCentered>{t('Your game PIN')}</Title>
            <div className={styles.pin}>{pin}</div>
        </div>
    )
}

export default PinBlock
