import React from 'react'
import { useTranslation } from 'react-i18next'

import TextArea from 'components/Form/TextArea/TextArea'
import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import ImagePicker from 'components/Form/ImagePicker/ImagePicker'

import TextInputWithPageSelection from '../../../../../../components/TextInputWithPageSelection/TextInputWithPageSelection'

import { TreasureHuntTile } from '../../utils/models'

import './TreasureHuntTileEditor.scss'

export const TreasureHuntTileEditor = props => {
    const { pages, tile, tileError, updateTile } = props
    const { t } = useTranslation()

    const updateImage = value => {
        updateTile(tile.id, { [TreasureHuntTile.overlaySrc]: value, [TreasureHuntTile.color]: null })
    }

    return (
        <>
            <div className="tile-editor" key={tile.id}>
                <div className="tile-editor__area">
                    <div className="tile-editor__cover-group">
                        <div className="tile-editor__group-item">
                            <ImagePicker
                                label={t('Quest cover')}
                                value={tile.overlaySrc ? tile.overlaySrc : tile.color}
                                onUpdate={value => updateImage(value)}
                            />
                        </div>
                    </div>
                    <div className="tile-editor__detail-group">
                        <div className="tile-editor__group-item">
                            <TextInput
                                label={t('Quest name')}
                                value={tile.quest}
                                description={t('Maximum length: {{length}} characters.', { length: 60 })}
                                maxLength={60}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.quest]: value })}
                                errorMessages={tileError && tileError.quest}
                            />
                        </div>
                        <div className="tile-editor__group-item">
                            <TextArea
                                label={t('Quest description')}
                                value={tile.questDescription}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.questDescription]: value })}
                            />
                        </div>
                        <ElementsGroup isEquallyWidth>
                            <TextInput
                                label={t('Button text')}
                                value={tile.buttonText}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.buttonText]: value })}
                            />
                            <TextInputWithPageSelection
                                pages={pages}
                                value={tile.buttonLink}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.buttonLink]: value })}
                            />
                        </ElementsGroup>
                        <ElementsGroup isEquallyWidth>
                            <TextInput
                                label={t('Secret code to open the cell')}
                                value={tile.password}
                                isRequired
                                errorMessages={tileError && tileError.password}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.password]: value })}
                            />
                            <UploadImage
                                label={t('Quest header illustration')}
                                value={tile.headerImgSrc}
                                onUpdate={value => updateTile(tile.id, { [TreasureHuntTile.headerImgSrc]: value })}
                            />
                        </ElementsGroup>
                    </div>
                </div>
            </div>
        </>
    )
}
