import { isValue } from 'utils/validator'

import BILLING_SYSTEMS from 'common/constants/billingSystems'
import CURRENCIES from 'common/constants/currencies'

import { MAIN_SERVER } from './instance'

export const GET_PAYMENTS_HISTORY = async obj => {
    try {
        const params = {}

        if (isValue(obj.per_page)) {
            params.size = obj.per_page
        }
        if (isValue(obj.offset_pages)) {
            params.page = obj.offset_pages
        }
        if (isValue(obj.sort)) {
            params.sort = obj.sort
        }

        return await MAIN_SERVER({
            method: 'get',
            url: `/payments`,
            params,
        })
    } catch (err) {
        throw err
    }
}

export const CREATE_ORDER = async guid =>
    MAIN_SERVER({
        method: 'post',
        url: '/orders',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            guid,
        },
    })

export const GET_ORDER = async guid =>
    MAIN_SERVER({
        method: 'get',
        url: `/orders/${guid}`,
    })

export const GET_INVOICE = async orderReference => {
    const response = await MAIN_SERVER({
        method: 'get',
        url: `/payments/invoice/${orderReference}/`,
    })
    if (response.billingSystem === BILLING_SYSTEMS.STRIPE) {
        return window.open(response.invoice, '_blank')
    }

    if (response.billingSystem === BILLING_SYSTEMS.TWO_CHECKOUT) {
        window.open()?.document?.write(
            `<iframe src="data:${response.invoice}" 
                        frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`,
        )
    }
}

export const GET_PUBLIC_CONTENT_INVOICE = async (userId, invoiceId) => {
    const response = await MAIN_SERVER({
        method: 'get',
        url: `/public-content/${userId}/invoices/${invoiceId}/`,
    })
    if (response.billingSystem === BILLING_SYSTEMS.STRIPE) {
        return window.open(response.invoice, '_blank')
    }

    if (response.billingSystem === BILLING_SYSTEMS.TWO_CHECKOUT) {
        window.open()?.document?.write(
            `<iframe src="data:${response.invoice}" 
                        frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`,
        )
    }
}

export const GET_PAYMENT_LINK = ({
    productCode,
    projectId,
    couponCode = '',
    currency = CURRENCIES.USD,
    isBusinessPayment = false,
    additionalParams = {},
}) =>
    MAIN_SERVER({
        method: 'post',
        url: '/orders/generate-link',
        data: {
            productCode,
            couponCode: window.promo || couponCode || undefined,
            projectId,
            backRedirectUrl: window.location.href,
            isBusinessPayment,
            currency,
            ...additionalParams,
        },
    })
