import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { API__ANALYTICS } from 'api'
import ANALYTICS_TARGETS from 'common/constants/AnalyticsTargets'

import './CountryList.scss'

const CountryList = ({ projectId, mapData, dateRange }) => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [totalUsers] = useState(() => mapData.reduce((acc, cur) => acc + cur.clientsCount, 0))

    useEffect(() => {
        API__ANALYTICS.GET_PROJECT_STATISTICS_MAP_DATA(
            {
                projectId,
                target: ANALYTICS_TARGETS.AVERAGE_SESSION_TIME_AND_ACTIONS,
                from: dateRange.from,
                to: dateRange.to,
            },
            true,
        ).then(result => {
            setData(
                result.map(item => ({
                    ...item,
                    clientsCount: mapData.find(el => el.isoCountryCode === item.isoCountryCode).clientsCount,
                })),
            )
        })
    }, [dateRange])

    return (
        <div className="country-list">
            <ul className="row table-header">
                <li>{t('Country')}</li>
                <li>{t('Users')}</li>
                <li>{t('% of Total')}</li>
                <li>{t('Average session duration')}</li>
                <li>{t('Target actions')}</li>
            </ul>
            {data.map((datum, i) => (
                <ul key={i} className="row">
                    <li>
                        {datum.country && (
                            <>
                                <img className="flag" src={datum.country.flags.svg} alt="flag" />
                                {datum.country.name.common}
                            </>
                        )}
                    </li>
                    <li>{datum.clientsCount}</li>
                    <li>{Math.round((datum.clientsCount / totalUsers) * 100)}%</li>
                    <li>{new Date(datum.avgSessionTime * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]}</li>
                    <li>{datum.actionCount}</li>
                </ul>
            ))}
        </div>
    )
}

export default CountryList
