import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'

import EmbedCode from './views/EmdedCode/EmbedCode'
import QrCode from './views/QrCode/QrCode'
import Main from './views/Main/Main'
import { SHARE_MODAL_TABS } from './utils/constants'

import CustomHeaderTitle from './components/CustomHeaderTitle/CustomHeaderTitle'

import styles from './ShareProjectModal.module.scss'

const ShareProjectModal = ({ title, onClose, data, isPublished, onOpenCreateTemplateModal }) => {
    const { t } = useTranslation()
    const [tab, setTab] = useState(SHARE_MODAL_TABS.main)

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <div className={styles.shareProjectModal}>
                {tab === SHARE_MODAL_TABS.main && (
                    <>
                        <Header
                            title={title}
                            onClose={onClose}
                            className={classNames(styles.modalHeader, { [styles.modalHeaderPublished]: isPublished })}
                            classNameTitle={styles.modalHeaderTitle}
                            classNameClose={styles.modalHeaderClose}
                        />
                        <Main
                            projectAuthor={data.project.author}
                            projectUrl={data.projectUrl}
                            project={data.project}
                            setTab={setTab}
                            onOpenCreateTemplateModal={() => {
                                onOpenCreateTemplateModal(data)
                                onClose()
                            }}
                        />
                    </>
                )}
                {tab === SHARE_MODAL_TABS.embedCode && (
                    <>
                        <Header
                            title={
                                <CustomHeaderTitle
                                    onBack={() => setTab(SHARE_MODAL_TABS.main)}
                                    label={t('Install embed code')}
                                />
                            }
                            onClose={onClose}
                            className={styles.modalHeader}
                            classNameTitle={styles.modalHeaderTitle}
                            classNameClose={styles.modalHeaderClose}
                        />
                        <EmbedCode embedCode={data.embedCode} iframeCode={data.iframeCode} />
                    </>
                )}
                {tab === SHARE_MODAL_TABS.qrCode && (
                    <>
                        <Header
                            title={
                                <CustomHeaderTitle onBack={() => setTab(SHARE_MODAL_TABS.main)} label={t('QR code')} />
                            }
                            onClose={onClose}
                            className={styles.modalHeader}
                            classNameTitle={styles.modalHeaderTitle}
                            classNameClose={styles.modalHeaderClose}
                        />
                        <QrCode projectUrl={data.projectUrl} />
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ShareProjectModal
