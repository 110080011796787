import React, { useState, useEffect, useMemo } from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Toggle from 'components/Form/Toggle/Toggle'

import { getUniqueId } from 'common/utils/getUniqueId'

import useAudioPlayer from 'hooks/useAudioPlayer'

import EditorCardTextModal from '../../../../EditorCardTextModal/EditorCardTextModal'
import PairCard from '../../../../PairCard/PairCard'
import { TYPES } from '../../../../EditorCardTextModal/constants'

import { CARD_TYPES } from '../../../MemoryCardsModal/utils/constants'
import removeIcon from '../../../i/remove.svg'

import { MatchingPair } from '../../utils/models'
import { PAIRS_FIELDS, MAX_PAIRS_COUNT } from '../../utils/constants'

import './Pairs.scss'

const Pairs = ({ structure, colorTheme, t, methods: { updateStructure } }) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [selectedCardInfo, setSelectedCardInfo] = useState(null)
    const [dataSet, setDataSet] = useState([])
    const { onPlayAudio, elementId, isAudioPlay } = useAudioPlayer()

    useEffect(() => {
        setDataSet(structure.pairs.pairList)
    }, [structure.pairs.pairList])

    useEffect(() => {
        if (dataSet.length) {
            updateStructure({ [PAIRS_FIELDS.pairList]: dataSet })
        }
    }, [dataSet])

    const onCloseModal = () => {
        setSelectedCardInfo(null)
        setIsShowModal(false)
    }

    const onOpenModal = (pair, typeCard) => {
        setSelectedCardInfo({
            pairId: pair.id,
            typeCard,
            text: pair[typeCard].text,
            bgColor: pair[typeCard].bgColor,
        })
        setIsShowModal(true)
    }

    const onSaveCardText = fields => {
        updateCard(selectedCardInfo.pairId, selectedCardInfo.typeCard, fields)
        onCloseModal()
    }

    const updateCard = (pairId, typeCard, fields) => {
        setDataSet(
            dataSet.map(item =>
                item.id === pairId
                    ? {
                          ...item,
                          [typeCard]: {
                              ...item[typeCard],
                              ...fields,
                          },
                      }
                    : item,
            ),
        )
    }

    const removePair = pairId => setDataSet(dataSet.filter(({ id }) => id !== pairId))

    const addPair = () => setDataSet([...dataSet, new MatchingPair()])

    const getSecondCardStyles = ({ isText }) => {
        const result = {}

        if (isText) {
            result.backgroundColor = '#fff'
            result.color = '#000'
        }

        return result
    }

    const itemsView = useMemo(() => {
        if (dataSet && dataSet.map) {
            return dataSet.map((item, i) => {
                return (
                    <div className="matching-modal-pairs__card-block" key={item.id}>
                        <div className="matching-modal-pairs__card-pair">
                            <div className="matching-modal-pairs__card-pair-title">
                                {t('Pair')} {i + 1}
                                {dataSet.length > 2 && (
                                    <span
                                        className="matching-modal-pairs__card-pair-remove"
                                        title={t('Remove')}
                                        onClick={() => removePair(item.id)}
                                    >
                                        <img src={removeIcon} alt="" />
                                    </span>
                                )}
                            </div>
                            <div className="matching-modal-pairs__card-pair-view">
                                <PairCard
                                    colorTheme={colorTheme}
                                    card={item[MatchingPair.firstImage]}
                                    onTextIconClick={() => onOpenModal(item, MatchingPair.firstImage)}
                                    onImageSelect={data =>
                                        updateCard(item.id, MatchingPair.firstImage, {
                                            cardType: CARD_TYPES.image,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onAudioSelect={data =>
                                        updateCard(item.id, MatchingPair.firstImage, {
                                            cardType: CARD_TYPES.audio,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onPlayAudio={onPlayAudio}
                                    isAudioPlay={isAudioPlay}
                                    playedElementId={elementId}
                                />
                                <PairCard
                                    colorTheme={colorTheme}
                                    card={item[MatchingPair.secondImage]}
                                    styles={getSecondCardStyles(item)}
                                    onTextIconClick={() => onOpenModal(item, MatchingPair.secondImage)}
                                    onImageSelect={data =>
                                        updateCard(item.id, MatchingPair.secondImage, {
                                            cardType: CARD_TYPES.image,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onAudioSelect={data =>
                                        updateCard(item.id, MatchingPair.secondImage, {
                                            cardType: CARD_TYPES.audio,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onPlayAudio={onPlayAudio}
                                    isAudioPlay={isAudioPlay}
                                    playedElementId={elementId}
                                />
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }, [dataSet, elementId, onPlayAudio, colorTheme])

    return (
        <div className="matching-modal-pairs">
            <div className="matching-modal-pairs__head-wrapper">
                <h3 className="matching-modal-pairs__head-title">{t('Pairs')}</h3>
                <div className="matching-modal-pairs__head__right">
                    <Toggle
                        label={t('Large cards')}
                        value={structure.pairs[PAIRS_FIELDS.isLargeCards]}
                        onUpdate={value => updateStructure({ [PAIRS_FIELDS.isLargeCards]: value })}
                    />
                    {dataSet.length < MAX_PAIRS_COUNT && (
                        <div className="matching-modal-pairs__head-add" onClick={() => addPair()}>
                            + {t('Add pair')}
                        </div>
                    )}
                </div>
            </div>
            <div className="matching-modal-pairs__body-wrapper">
                <ScrollableBlock>
                    <div className="matching-modal-pairs__card-block-wrapper">{itemsView}</div>
                </ScrollableBlock>
            </div>
            {isShowModal && (
                <EditorCardTextModal
                    type={selectedCardInfo.typeCard === MatchingPair.firstImage ? TYPES.DEFAULT : TYPES.MATCHING}
                    text={selectedCardInfo.text}
                    bgColor={selectedCardInfo.bgColor}
                    onClose={onCloseModal}
                    onSubmit={onSaveCardText}
                />
            )}
        </div>
    )
}

export default Pairs
