import React from 'react'

import { Triangle } from './svg/Triangle'

import styles from './WinArrow.module.scss'

const WinArrow = ({ colorTheme, size, top }) => (
    <div className={styles.winArrow} style={{ height: size, top }}>
        <Triangle color={colorTheme} />
    </div>
)

export default WinArrow
