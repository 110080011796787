import React from 'react'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip/Tooltip'

import TextInput from './TextInput.js'

const InputWithModal = props => {
    const { t } = useTranslation()
    const {
        id,
        name,
        value,
        tooltipText,
        schema: { label = '', payload: schemePayload },
        schemaName,
        openModalByName,
        colorTheme,
        payload,
        onUpdate,
        isMultiplayerGame,
    } = props

    return (
        <div className="control-box">
            <p className="label">
                {label}
                {!isMultiplayerGame && (
                    <span
                        className="link"
                        onClick={() =>
                            openModalByName(schemePayload.modalName, {
                                value,
                                blockId: id,
                                fieldName: schemaName,
                                colorTheme,
                                payload,
                            })
                        }
                    >
                        {t('Internal page')}
                    </span>
                )}
                <Tooltip tooltipText={tooltipText} />
            </p>
            <TextInput
                id={id}
                name={name}
                value={value}
                schema={{ payload: schemePayload }}
                payload={payload}
                onUpdate={onUpdate}
            />
        </div>
    )
}

export default InputWithModal
