import React from 'react'
import classNames from 'classnames'

import Image from 'components/Form/Image'

import { DEFAULT_IMAGE_BG_WIDE_URL } from 'common/constants/constants'

const Logo = ({ blockData: { t, url, author, position, width, padding } }) => {
    const imageSrc = url || DEFAULT_IMAGE_BG_WIDE_URL

    return (
        <div
            className={classNames('block', `__${t}`, { __empty: !url })}
            style={{ textAlign: position.value, padding }}
        >
            <Image
                src={imageSrc}
                style={{
                    width: `${width}%`,
                }}
            />
            {author && (
                <p className="img__author">
                    <span>
                        Photo by <a>{author.name}</a> on <a>Unsplash</a>
                    </span>
                </p>
            )}
        </div>
    )
}

export default Logo
