import { createCollector, createValidator } from 'shared/validation'

import { TABS, VALIDATE_RULES } from './constants'

const isValidFields = createValidator(VALIDATE_RULES)
const collectFieldsErrors = createCollector(VALIDATE_RULES)

export const validateTabs = (tmpStructure, activeTab) => {
    let activeTabField = TABS[activeTab] || null
    const isValid = isValidFields(tmpStructure, activeTabField)
    const errors = collectFieldsErrors(tmpStructure, activeTabField)

    return {
        tmpStructure,
        errors,
        activeTabHasError: activeTabField && !isValid,
        hasError: !isValid,
    }
}
