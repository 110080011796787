import React, { useEffect, useState } from 'react'

import { API__ANALYTICS } from 'api'

import { TOOLTIP_LABELS } from '../../constants'

import Chart from './components/Chart/Chart'

function isInt(n) {
    return n % 1 === 0
}
const formatAxisLeftNumbers = value => {
    if (value < 1000) {
        if (!isInt(value)) {
            return ''
        }
        return value
    }
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)} M`
    }

    return `${(value / 1000).toFixed(1)} K`
}

const Wrapper = ({ code, projectId, description, isInteger, axisLeftFormat = formatAxisLeftNumbers, dateRange }) => {
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        API__ANALYTICS.GET_PROJECT_STATISTICS_CHART_DATA({
            code,
            projectId,
            from: dateRange.from,
            to: dateRange.to,
        }).then(data => {
            setChartData(
                data.map(point => ({
                    x: point.date,
                    y: point.indicator,
                })),
            )
        })
    }, [dateRange])

    return (
        <>
            <div className="description">{description}</div>
            <div data-test="chart-area" style={{ width: '100%', height: '340px' }}>
                <Chart
                    data={[{ id: 1, color: '#FBDFC4', data: chartData }]}
                    tooltipYLabel={TOOLTIP_LABELS[code]}
                    from={dateRange.from}
                    to={dateRange.to}
                    axisLeftFormat={axisLeftFormat}
                    integer={isInteger}
                />
            </div>
        </>
    )
}

export default Wrapper
