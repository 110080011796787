import React from 'react'
import { useTranslation } from 'react-i18next'

import { isBlockedMeta } from 'utils/common'

import { isEuVersion } from 'common/constants/env'

import { ACTIONS } from '../../../../../constants'
import useAction from '../../../../../hooks/useAction'

import List from './components/List/List'
import Item from './components/Item/Item'

import googleLogo from './i/google.svg'
import facebookLogo from './i/facebook.svg'
import skolonLogo from './i/skolon.png'

import { getAuthLink } from './utils'
import { PROVIDERS } from './constants'

import styles from './Social.module.scss'

const Social = () => {
    const { t } = useTranslation()
    const { action } = useAction()

    return (
        <div className={styles.social}>
            <List>
                <Item
                    href={getAuthLink(PROVIDERS.GOOGLE)}
                    provider="Google"
                    iconSrc={googleLogo}
                    label={
                        action === ACTIONS.AUTHENTICATION
                            ? t('Sign in with {{provider}}', { provider: 'Google' })
                            : t('Sign up with {{provider}}', { provider: 'Google' })
                    }
                />
                {isEuVersion ? (
                    <Item
                        href={getAuthLink(PROVIDERS.SKOLON)}
                        provider="Skolon"
                        iconSrc={skolonLogo}
                        label={
                            action === ACTIONS.AUTHENTICATION
                                ? t('Sign in with {{provider}}', { provider: 'Skolon' })
                                : t('Sign up with {{provider}}', { provider: 'Skolon' })
                        }
                    />
                ) : (
                    <>
                        {/*{!isBlockedMeta() && (*/}
                        {/*    <Item*/}
                        {/*        href={getAuthLink(PROVIDERS.FACEBOOK)}*/}
                        {/*        provider="Facebook"*/}
                        {/*        iconSrc={facebookLogo}*/}
                        {/*        label={*/}
                        {/*            action === ACTIONS.AUTHENTICATION*/}
                        {/*                ? t('Sign in with {{provider}}', { provider: 'Facebook' })*/}
                        {/*                : t('Sign up with {{provider}}', { provider: 'Facebook' })*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*)}*/}
                    </>
                )}
            </List>
            <div className={styles.divider} data-text={t('or')} />
        </div>
    )
}

export default Social
