import store from 'store'

import { MAIN_SERVER } from './instance'

export const CREATE_PUBLIC_CONTENT = async ({
    authorId,
    projectId,
    previewImageUrl,
    title,
    description,
    productsSettings = [],
    grade,
    subject,
    previewImages = [],
}) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()

    return MAIN_SERVER({
        method: 'post',
        url: `/public-content`,
        data: {
            previewImageUrl,
            title,
            grade,
            subject,
            description,
            contentType: 'INTERACTY_TEMPLATE',
            previewImages,
            contentAdditionalData: {
                authorId,
                projectId,
                organizationId: selectedOrganizationId,
                productsSettings,
            },
        },
    })
}

export const UPDATE_PUBLIC_CONTENT = async (
    contentId,
    { previewImageUrl, title, description, grade, subject, previewImages = [] },
) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/public-content/${contentId}`,
        data: {
            previewImageUrl,
            title,
            grade,
            subject,
            description,
            previewImages,
        },
    })

export const GET_PUBLIC_CONTENT = async contentId =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-content/${contentId}`,
    })

export const GET_PURCHASES = async userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-content/${userId}/purchases`,
        params: {
            size: 1000,
            page: 0,
        },
    })
