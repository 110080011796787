import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Modal from 'components/Modal/Modal'

import arrowIcon from './i/arrow.svg'

import styles from './UpgradeSuccessful.module.scss'

const UpgradeSuccessful = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal onClose={onClose}>
            <div className={styles.upgradeSuccessful}>
                <div className={styles.close} onClick={onClose} />
                <h2 className={styles.title}>{t('You have successfully upgraded your tariff plan!')}</h2>
                <div className={styles.buttonBox}>
                    <button className="button" data-size="lg" data-variant="primary" onClick={onClose}>
                        {t("Excellent! Let's start!")}
                    </button>
                </div>
                <div className={styles.linkBox}>
                    <Link to="/billing-and-payments?tab=subscription">
                        {t('Manage my subscription')}
                        <img src={arrowIcon} alt="arrow" />
                    </Link>
                </div>
            </div>
        </Modal>
    )
}
export default UpgradeSuccessful
