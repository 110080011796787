export default {
    "Oh no...": {
        "ru": "Ой...",
        "es": "Oh no...",
        "pt": "Oh não...",
        "fr": "Oh non...",
        "de": "Oh nein...",
        "it": "Oh no...",
        "nl": "Oh nee...",
        "pl": "O nie...",
        "id": "Oh tidak...",
        "tr": "Olamaz...",
        "ko": "이런...",
        "no": "Å nei...",
        "sv": "Åh, nej...",
        "jp": "そんな...",
        "fi": "Voi ei...",
        "lv": "Ak nē...",
        "da": "Åh nej...",
        "uk": "О, ні...",
        "ro": "Oh, nu..."
    },
    "The project is temporarily disabled because the owner hasn't renewed the subscription.": {
        "ru": "Автор этого проекта не продлил подписку, и проект временно отключен.",
        "es": "El proyecto está temporalmente desactivado porque el propietario no ha renovado la suscripción.",
        "pt": "O projeto está temporariamente desativado porque o proprietário não renovou a subscrição.",
        "fr": "Le projet est temporairement désactivé car le propriétaire n'a pas renouvelé son abonnement.",
        "de": "Das Projekt ist vorübergehend deaktiviert, weil der Eigentümer das Abonnement nicht verlängert hat.",
        "it": "Il progetto è temporaneamente disattivato perché il proprietario non ha rinnovato l'abbonamento.",
        "nl": "Het project is tijdelijk uitgeschakeld omdat de eigenaar het abonnement niet heeft vernieuwd.",
        "pl": "Projekt jest tymczasowo wyłączony, ponieważ właściciel nie odnowił subskrypcji.",
        "id": "Proyek ini dinonaktifkan sementara karena pemiliknya belum memperbarui langganan.",
        "tr": "Proje, sahibi aboneliğini yenilemediği için geçici olarak devre dışı bırakıldı.",
        "ko": "소유자가 구독을 갱신하지 않아서 프로젝트가 일시적으로 비활성화되었습니다.",
        "no": "Prosjektet er midlertidig deaktivert fordi eieren ikke har fornyet abonnementet.",
        "sv": "Projektet är tillfälligt inaktiverat eftersom ägaren inte har förnyat prenumerationen.",
        "jp": "オーナーがサブスクリプションを更新していないため、プロジェクトは一時的に無効になっています。",
        "fi": "Projekti on tilapäisesti poistettu käytöstä, koska omistaja ei ole uusinut tilausta.",
        "lv": "Projekts uz laiku ir atspējots, jo īpašnieks nav atjaunojis abonementu.",
        "da": "Projektet er midlertidigt deaktiveret, fordi ejeren ikke har fornyet sit abonnement.",
        "uk": "Проєкт тимчасово не працює, оскільки власник не продовжив підписку.",
        "ro": "Proiectul este temporar dezactivat deoarece proprietarul nu a reînnoit abonamentul."
    }
}