import React from 'react'

import useAction from '../../../hooks/useAction'

import styles from './Inputs.module.scss'

const Inputs = ({ children }) => {
    const { action } = useAction()

    if (!children) return null
    const components = Array.isArray(children) ? children.filter(component => !!component) : [children]

    return (
        <ul className={styles.list}>
            {components.map((item, i) => (
                <li key={action + i} className={styles.item}>
                    {item}
                </li>
            ))}
        </ul>
    )
}

export default Inputs
