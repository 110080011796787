import React from 'react'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import Button from 'components/Form/Button/Button'
import Toast from 'components/Toast/Toast'

import Title from '../Title/Title'

import copyIcon from './i/copy.svg'
import viewIcon from './i/view.svg'

import styles from './UrlBlock.module.scss'

const UrlBlock = ({ url, masterUrl, title, isHost = false }) => {
    const { t } = useTranslation()

    const onCopy = () => {
        copy(url)
        Toast('success', {
            title: t('Link copied!'),
            time: 2000,
        })
    }

    const onView = () => {
        if (isHost) window.open(masterUrl, '_blank')
        else window.open(url, '_blank')
    }

    return (
        <div className={styles.urlBlock}>
            <Title>{title}</Title>
            <div className={styles.box}>
                <div className={styles.url}>{url}</div>
                <Button
                    className={styles.copyButton}
                    backgroundColor="transparent"
                    borderColor={'#2990FB'}
                    color={'#2990FB'}
                    onClick={onCopy}
                >
                    <img src={copyIcon} alt="" />
                    {t('Copy')}
                </Button>
                <Button className={styles.viewButton} onClick={onView}>
                    <img src={viewIcon} alt="" />
                    {isHost ? t('Run game as host') : t('Run game')}
                </Button>
            </div>
        </div>
    )
}

export default UrlBlock
