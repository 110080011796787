import React from 'react'
import classNames from 'classnames'

import Text from '../components/Text/Text'
import Button from '../components/QuillForms/Button/Button'

import styles from './TextAndImage.module.scss'

const TextAndImage = ({
    blockData: {
        id,
        t,
        imageUrl,
        text,
        textPosition,
        isShowButton,
        buttonText,
        buttonBackgroundColor,
        buttonBorderRadius,
        isTransparentBackground,
        backgroundColor,
    },
    onUpdate,
}) => (
    <div
        className={classNames('block', `__${t}`, styles.textAndImage, {
            [styles.isLeftText]: textPosition === 'left',
        })}
        style={{ backgroundColor: isTransparentBackground ? 'transparent' : backgroundColor }}
    >
        <img className={styles.image} src={imageUrl} alt="image" />
        <div className={styles.content}>
            <p className={styles.contentText}>
                <Text id={id} text={text} propName="text" onUpdate={onUpdate} />
            </p>
            {isShowButton && (
                <div className={styles.contentButton}>
                    <Button
                        blockId={id}
                        text={buttonText}
                        backgroundColor={buttonBackgroundColor}
                        borderRadius={buttonBorderRadius}
                        onUpdateText={onUpdate}
                    />
                </div>
            )}
        </div>
    </div>
)

export default TextAndImage
