import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'
import Checkbox from 'components/Form/Checkbox/Checkbox'

import storage from 'common/utils/storage'

import presentationsImage from './i/presentations.svg'
import multiplayerImage from './i/multiplayer.svg'

import styles from './AddInternalPageModal.module.scss'

const AddInternalPageModal = ({ isOpen, onAddPage, onClose }) => {
    const { t } = useTranslation()

    const [dontShow, setDontShow] = useState(false)

    const onAction = () => {
        if (dontShow) storage.setValue('DONT_SHOW_HOW_ADD_INTERNAL_PAGE', 'true')
        onAddPage()
        onClose()
    }

    if (!isOpen) return null

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isShowCloseIcon>
            <div className={styles.addInternalPageModal}>
                <h2 className={styles.title}>{t('How you can use pages')}</h2>
                <div className={styles.sides}>
                    <div className={styles.side}>
                        <img className={styles.image} src={presentationsImage} alt="presentations" />
                        <div className={styles.sideText}>
                            <h3 className={styles.subTitle}>{t('Interactive presentations')}</h3>
                            <p className={styles.description}>
                                <Trans
                                    i18nKey="Enable page navigation and create a stunning presentation with interactive elements and games. <lnk>Read more</lnk>"
                                    components={{
                                        lnk: (
                                            <a
                                                href="https://help.interacty.me/en/content/presentation-mode"
                                                className={styles.link}
                                                target="_blank"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    <div className={styles.side}>
                        <img className={styles.image} src={multiplayerImage} alt="multiplayer" />
                        <div className={styles.sideText}>
                            <h3 className={styles.subTitle}>{t('Multi-level games')}</h3>
                            <p className={styles.description}>
                                <Trans
                                    i18nKey="Hide navigation and connect internal pages with links and buttons. Great for multi-level games, sequential tasks or training courses. <lnk>Read more</lnk>"
                                    components={{
                                        lnk: (
                                            <a
                                                href="https://help.interacty.me/en/content/how-to-add-a-new-page"
                                                className={styles.link}
                                                target="_blank"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        className={classNames('button', styles.button)}
                        data-size="lg"
                        data-variant="secondary"
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        className={classNames('button', styles.button)}
                        data-size="lg"
                        data-variant="primary"
                        onClick={onAction}
                    >
                        {t('Add page ')}
                    </button>
                </div>
                <div className={styles.showAgain}>
                    <Checkbox label={t("Don't show again")} value={dontShow} onUpdate={value => setDontShow(value)} />
                </div>
            </div>
        </Modal>
    )
}

export default AddInternalPageModal
