import { convertConstraints } from 'common/utils/constraints'

export const USER__SET_DETAILS = obj => {
    return {
        type: 'USER__SET_DETAILS',
        payload: {
            email: obj.login,
            ...obj,
        },
    }
}

export const USER__SET_SUBSCRIPTIONS = obj => ({
    type: 'USER__SET_SUBSCRIPTIONS',
    payload: obj,
})

export const USER__SET_SUBSCRIPTION = subscription => dispatch => {
    dispatch({
        type: 'USER__SET_SUBSCRIPTION',
        payload: subscription,
    })
}

export const USER__SET_SUBSCRIPTION_CONSTRAINTS = obj => {
    if (Array.isArray(obj)) obj = convertConstraints(obj)

    return {
        type: 'USER__SET_SUBSCRIPTION_CONSTRAINTS',
        payload: obj,
    }
}

export const USER__SET_PUBLIC_PROFILE = obj => ({
    type: 'USER__SET_PUBLIC_PROFILE',
    payload: obj,
})
