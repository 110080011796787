import React from 'react'
import { withTranslation } from 'react-i18next'

import iconCsv from './i/iconCsv.svg'

const RatingHeader = ({ isLoading, isHasRatingData, getCSV, startNewRating, t }) => (
    <div>
        {isHasRatingData && (
            <div className="export" onClick={() => getCSV()}>
                {t('Export CSV')}
                <img src={iconCsv} alt="csv" />
            </div>
        )}
    </div>
)

export default withTranslation('translations')(RatingHeader)
