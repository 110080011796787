import i18n from 'i18n'

export const REASONS = [
    {
        id: 11,
        code: 'DONT_LIKE_LONG_TERM_COMMITMENTS',
        label: i18n.t("I don't like long term commitments"),
    },
    {
        id: 20,
        code: 'PLAN_PURCHASE_LATER',
        label: i18n.t('I plan to purchase a subscription later'),
    },
    {
        id: 30,
        code: 'PLATFORM_DOESNT_SUIT',
        label: i18n.t("The platform doesn't suit me"),
    },
    {
        id: 40,
        code: 'PRICES_TOO_EXPENSIVE',
        label: i18n.t('Prices are too expensive for me'),
    },
    {
        id: 50,
        code: 'DID_EVERYTHING_WANTED',
        label: i18n.t('I did everything I wanted'),
    },
    {
        id: 100,
        code: 'OTHER',
        label: i18n.t('Other'),
    },
]
