import React from 'react'

import Tooltip from 'components/Tooltip/Tooltip'

import './DemoWithModal.scss'

const DemoWithModal = ({
    id,
    value,
    tooltipText,
    schema: { label = '', payload },
    schemaName,
    openModalByName,
    colorTheme,
}) => {
    return (
        <div className="demo-with-modal">
            <div className="demo-with-modal__content">
                <div className="title">
                    <span>{label}</span>
                    <Tooltip tooltipText={tooltipText} position={'bottom'} />
                </div>
                {payload.description && <p className="description">{payload.description}</p>}
            </div>
            <div
                className="demo-with-modal__toggle"
                onClick={() =>
                    openModalByName(payload.modalName, {
                        value,
                        blockId: id,
                        fieldName: schemaName,
                        colorTheme,
                    })
                }
            />
        </div>
    )
}

export default DemoWithModal
