import React, { useEffect, useState } from 'react'
import Popper from '@mui/material/Popper'
import Checkbox from 'components/Form/Checkbox/Checkbox.jsx'
import { useTranslation } from 'react-i18next'

import { markHintIsShown } from 'utils/hintsManager'

import './Hint.scss'

const Hint = ({ hintName = '', parentRef = {}, text = '', placement = 'bottom-start', onCloseHint }) => {
    const { t } = useTranslation()
    const [hintCheckBoxVal, setHintCheckBoxVal] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [parentWidth, setParentWidth] = useState(null)

    useEffect(() => {
        if (parentRef.current) {
            setAnchorEl(parentRef.current)
            setParentWidth(parentRef.current.getBoundingClientRect().width)
        }
    }, [parentRef])

    const closeHint = () => {
        if (hintCheckBoxVal) {
            markHintIsShown(hintName)
        }
        setHintCheckBoxVal(false)
        onCloseHint()
    }

    return (
        <Popper className="hint-popper" id={hintName} open placement={placement} anchorEl={anchorEl}>
            <div
                className="hint-wrapper"
                style={{
                    marginLeft: placement === 'bottom-start' ? parentWidth / 2 : null,
                    marginRight: placement === 'bottom-end' ? parentWidth / 2 : null,
                }}
            >
                <div className="hint">
                    <div className="hint__text">{text}</div>
                    <div className="hint__actions">
                        <Checkbox
                            label={t('Do not show again')}
                            value={hintCheckBoxVal}
                            onUpdate={val => setHintCheckBoxVal(val)}
                        />
                        <button className="hint__button" data-test="modal-hint-button" onClick={closeHint}>
                            {t('All clear!')}
                        </button>
                    </div>
                    <div className="hint__close" onClick={closeHint} />
                </div>
            </div>
        </Popper>
    )
}

export default Hint
