import React from 'react'

import styles from './DesktopLayout.module.scss'

const DesktopLayout = ({ imageSrc }) => (
    <div className={styles.layout}>
        <div className={styles.mockup}>
            <ul>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <div className={styles.image} style={{ backgroundImage: `url(${imageSrc})` }}></div>
    </div>
)

export default DesktopLayout
