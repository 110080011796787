import { createCollector, createValidator, defined, maxLength, required, oneOf, dependOn } from 'shared/validation'

import { BLOCK_KEYS, Playground as PlaygroundModel, Final as FinalModal } from './slidingPuzzleService'

import { AVAILABLE_TILES_COUNT, GAME_TYPES } from './constants'

const CROSSWORD_VALIDATION_RULES_FIELDS = {
    [BLOCK_KEYS.PLAYGROUND]: {
        [PlaygroundModel.gameType]: [required(), oneOf(Object.values(GAME_TYPES))],
        [PlaygroundModel.tilesCount]: [required(), oneOf(AVAILABLE_TILES_COUNT)],
        [PlaygroundModel.imageUrl]: [
            dependOn(({ self }) => {
                return self[PlaygroundModel.gameType] === GAME_TYPES.IMAGE ? required() : null
            }),
        ],
        [PlaygroundModel.isShowImageNumbers]: [defined()],
        [PlaygroundModel.isShowCover]: [defined()],
        [PlaygroundModel.coverHeader]: [
            dependOn(({ self }) => {
                return self[PlaygroundModel.isShowCover] ? required() : null
            }),
            maxLength(400),
        ],
        [PlaygroundModel.coverButtonText]: [
            dependOn(({ self }) => {
                return self[PlaygroundModel.isShowCover] ? required() : null
            }),
            maxLength(20),
        ],
    },
    [BLOCK_KEYS.FINAL]: {
        [FinalModal.header]: [required(), maxLength(500)],
        [FinalModal.description]: [maxLength(5000)],
        [FinalModal.image]: [defined()],
        [FinalModal.imageDisclaimer]: [maxLength(100)],
    },
}

export const validateTabs = (tmpStructure, activeTabKey = null) => {
    const isValid = createValidator(CROSSWORD_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)
    const errors = createCollector(CROSSWORD_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)

    return {
        tmpStructure,
        errors,
        isHasError: !isValid,
    }
}
