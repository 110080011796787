import React from 'react'

import styles from './Head.module.scss'

const Head = ({ columnsData }) => (
    <div className={styles.head}>
        <ul className={styles.list}>
            {Object.values(columnsData).map(({ width, label }, i) => (
                <li key={i} className={styles.item} style={{ width }}>
                    {label}
                </li>
            ))}
        </ul>
    </div>
)

export default Head
