import i18n from 'i18n'

export const CHART_CODES = {
    VIEWS: 'SESSION_COUNT',
    USERS: 'UNIQUE_USERS',
    AVERAGE_ATTENTION_TIME: 'AVG_SESSION_TIME',
}

export const TOOLTIP_LABELS = {
    [CHART_CODES.VIEWS]: i18n.t('Views'),
    [CHART_CODES.USERS]: i18n.t('Users'),
    [CHART_CODES.AVERAGE_ATTENTION_TIME]: i18n.t('Time'),
}
