import React from 'react'

import './OverBanner.scss'

export const OverBanner = ({ icon, message, btnText, onAction }) => {
    return (
        <div className="over-banner banner" data-padding="normal" data-variant="normal">
            <div className="banner-box container-fluid">
                <div>
                    <span className="over-banner__icon icon" data-icon="attention">
                        {icon}
                    </span>
                    <div className="over-banner__message">{message}</div>
                </div>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary-light"
                    data-test="choose-subscription-options"
                    onClick={onAction}
                >
                    {btnText}
                </button>
            </div>
        </div>
    )
}

export default OverBanner
