import { FIELD_KEYS } from 'pages/Editor/Tabs/Editor/Modals/LeadFormModal/constants'
import { isEmpty } from 'lodash'
import React from 'react'
import { FIELD_TYPES } from '../../common/constants/fields'

const fieldKeyToTestValueMap = {
    [FIELD_KEYS.EMAIL]: 'john@interacty.me',
    [FIELD_KEYS.FIRST_NAME]: 'John',
    [FIELD_KEYS.LAST_NAME]: 'Awesome',
    [FIELD_KEYS.COMPANY]: 'Interacty',
    [FIELD_KEYS.COMPANY_URL]: 'https://interacty.me',
    [FIELD_KEYS.PHONE_NUMBER]: '+1 650 513 0511',
}

const typeToTestValueMap = {
    [FIELD_TYPES.TEXT_AREA]: 'Text value',
    [FIELD_TYPES.TEXT_INPUT]: 'Text value',
    [FIELD_TYPES.CHECKBOX]: 'true',
    [FIELD_TYPES.EMAIL]: 'john@interacty.me',
    [FIELD_TYPES.URL]: 'https://interacty.me',
    [FIELD_TYPES.PHONE_NUMBER]: '+1 650 513 0511',
}

export const getTestValueByField = (fieldKey, type) => {
    if (fieldKey.includes(FIELD_KEYS.CUSTOM_FIELD)) return typeToTestValueMap[type]
    else return fieldKeyToTestValueMap[fieldKey]
}

export const generateColumns = (dropDownColumnCellRender, headerClassName) => {
    return [
        {
            field: 'interactyField',
            headerName: 'Interacty field',
            flex: 0.2,
            sortable: false,
            disableColumnMenu: true,
            headerClassName,
        },
        {
            field: 'mailchimpField',
            headerName: 'Mailchimp field',
            flex: 0.2,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: dropDownColumnCellRender,
            headerClassName,
        },
        {
            field: 'testValue',
            headerName: 'Test value',
            flex: 0.2,
            sortable: false,
            disableColumnMenu: true,
            headerClassName,
        },
    ]
}

export function generateRows(leadFormFields, fieldMap, mailchimpFields) {
    const rows = leadFormFields
        .filter(field => field.key !== FIELD_KEYS.EMAIL)
        .map(field => {
            const mailchimpTag = fieldMap[field.key]
            return {
                id: field.key,
                key: field.key,
                interactyField: field.label,
                mailchimpField: mailchimpTag ? { value: mailchimpTag, label: mailchimpFields.get(mailchimpTag) } : '',
                testValue: getTestValueByField(field.key, field.type),
            }
        })

    rows.unshift({
        id: FIELD_KEYS.EMAIL,
        key: FIELD_KEYS.EMAIL,
        interactyField: 'Email',
        mailchimpField: 'Email',
        testValue: getTestValueByField(FIELD_KEYS.EMAIL),
    })
    return rows
}

export function findMissedRequiredFields(fieldMap, mailchimpFields) {
    const mappedTags = Object.values(fieldMap)
    return mailchimpFields.filter(f => !mappedTags.includes(f.tag)).filter(f => f.required)
}

export function initFieldMap(connector, leadFormFields) {
    const fieldMap = {}
    if (isEmpty(connector)) return fieldMap

    const selectedFieldsSetByUser = new Set(leadFormFields.map(f => f.key))
    for (const item of connector.mappings) {
        const exists = selectedFieldsSetByUser.has(item.internalFieldName)
        if (exists) {
            fieldMap[item.internalFieldName] = item.externalFieldTag
        }
    }
    return fieldMap
}
