import COOKIES from 'common/constants/cookies'

import { getCookie, setCookie } from 'utils/cookies'

function getRandomId(t = 16) {
    let s = ''
    const r = crypto.getRandomValues(new Uint8Array(t))
    for (; t--; ) {
        const n = 63 & r[t]
        s += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? '_' : '-'
    }
    return s
}

const getCookieClientId = () => getCookie(COOKIES.CLIENT_ID)
const setCookieClientId = value => setCookie(COOKIES.CLIENT_ID, value)

export function getClientId() {
    let clientId = null
    try {
        clientId = getCookieClientId()
        if (!clientId) {
            clientId = getRandomId()
            setCookieClientId(clientId)
        }
    } catch (err) {
        console.error(err)
    } finally {
        if (!clientId) clientId = getRandomId()
    }
    return clientId
}
