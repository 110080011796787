import { isEmpty, isString, isUndefined } from 'lodash'

export const VALIDATE_TYPES = {
    required: 1,
}

export const validateValue = (type, value) => {
    switch (type) {
        case VALIDATE_TYPES.required: {
            if (!value.toString().trim().length) {
                return false
            }
        }

        default: {
            return true
        }
    }
}

export function hasValue(value) {
    const _value = isString(value) ? value.trim() : value
    return !(isEmpty(_value) || isUndefined(_value))
}
