import i18n from 'i18n'

export const IMAGE_SIZES = {
    original: {
        value: 'original',
        label: i18n.t('Оригинальный'),
    },
    custom: {
        value: 'custom',
        label: i18n.t('Custom'),
    },
}
