import { createCollector, createValidator, itemRules, maxLength, minLength, required } from 'shared/validation'
import { DEFAULT_IMAGE_URL } from '../../../../../../../../common/constants/constants'
import { getUniqueId } from '../../../../../../../../common/utils/getUniqueId'

export const MIN_SLIDES = 1
export const MAX_SLIDES = 50

export class Slide {
    id
    image
    description
    hasTextOptions
    header
    imageCaption

    constructor({
        image = DEFAULT_IMAGE_URL,
        description = '',
        hasTextOptions = false,
        header = '',
        imageCaption = '',
    }) {
        this.id = getUniqueId()
        this.image = image
        this.description = description
        this.hasTextOptions = hasTextOptions
        this.header = header
        this.imageCaption = imageCaption
    }

    static get image() {
        return 'image'
    }

    static get description() {
        return 'description'
    }

    static get hasTextOptions() {
        return 'hasTextOptions'
    }

    static get header() {
        return 'header'
    }

    static get imageCaption() {
        return 'imageCaption'
    }
}

const convertSlidesErrorsToMap = (slides, errors) =>
    new Map(
        slides.map((slide, index) => {
            return [slide.id, errors[index]]
        }),
    )

const slideRules = {
    image: [required()],
}

const SlidesBlockRules = {
    slides: [minLength(MIN_SLIDES), maxLength(MAX_SLIDES), itemRules(slideRules, convertSlidesErrorsToMap)],
}

export const isValidSlidesBlock = createValidator(SlidesBlockRules)

export const collectSlidesErrors = createCollector(SlidesBlockRules)
