import React, { useRef, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import Modal from '../../../../../../../components/Modal/Modal'
import Header from '../../../../../../../components/Modal/components/Header/Header'

import './CustomProjectLinkModal.scss'

const CustomProjectLinkModal = ({
    i18n,
    data: { status = '', value, canCheck, canSave },
    onCheck,
    onChange,
    onSave,
    onClose,
}) => {
    const inputRef = useRef(null)
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    return (
        <Modal onClose={onClose}>
            <Header title={i18n.t('Setting a custom link')} onClose={onClose} />
            <div className="custom-project-link-modal">
                <h2>{i18n.t('Choose a URL for your project.')}</h2>
                <p>{i18n.t('You can change the link after publication.')}</p>
                <div className={`input-place ${focused ? 'focused' : ''} ${status}`}>
                    <div className="side side--left">
                        <div className="placeholder">{`${process.env.REACT_APP_BACKEND_URL.replace(
                            'api.',
                            '',
                        )}/projects/`}</div>
                        <input
                            type="text"
                            maxLength={25}
                            ref={inputRef}
                            placeholder="best-project-name"
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            value={value}
                            onChange={evt => onChange(evt.target.value)}
                        />
                    </div>
                    <div className="side side--right">
                        {status === '' || status === 'focus' ? (
                            <button
                                className="button"
                                data-size="lg"
                                data-variant="primary"
                                onClick={() => onCheck()}
                                disabled={!canCheck}
                            >
                                {i18n.t('Check availability')}
                            </button>
                        ) : null}
                        {status === 'free' && <div className="available">{i18n.t('Available')}</div>}
                        {status === 'already_taken' && <div className="unavailable">{i18n.t('Unavailable')}</div>}
                        {status === 'invalid' && <div className="invalid">{i18n.t('Invalid characters')}</div>}
                    </div>
                </div>
                <div className="note">
                    {i18n.t('Maximum length: {{length}} characters.', { length: 25 })}
                    <br />
                    {i18n.t('Allowed characters:')}
                </div>
                <ul className="allowed">
                    <li>a-z</li>
                    <li>A-Z</li>
                    <li>0-9</li>
                    <li>-</li>
                    <li>_</li>
                </ul>
                <div className="buttons">
                    <button className="button" data-size="lg" data-variant="secondary" onClick={() => onClose()}>
                        {i18n.t('Cancel')}
                    </button>
                    <button
                        className="button"
                        data-size="lg"
                        data-variant="primary"
                        disabled={!canSave}
                        onClick={() => onSave()}
                    >
                        {i18n.t('Save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default withTranslation('translations')(CustomProjectLinkModal)
