import i18n from 'i18n'

export const SERVICE_COMMISSION_PERCENTAGE = 30

export const DATES = {
    DAYS_7: 'DAYS_7',
    DAYS_30: 'DAYS_30',
    ALL_TIME: 'ALL_TIME',
}

export const DATE_MS = {
    [DATES.DAYS_7]: 604800000,
    [DATES.DAYS_30]: 2592000000,
}

export const DATES_OPTIONS = {
    [DATES.DAYS_7]: {
        label: i18n.t('{{count}} days', { count: 7 }),
        value: DATES.DAYS_7,
        getTimesRange: (to = Date.now()) => ({ to, from: to - DATE_MS[[DATES.DAYS_7]] }),
    },
    [DATES.DAYS_30]: {
        label: i18n.t('{{count}} days', { count: 30 }),
        value: DATES.DAYS_30,
        getTimesRange: (to = Date.now()) => ({ to, from: to - DATE_MS[[DATES.DAYS_30]] }),
    },
    [DATES.ALL_TIME]: {
        label: i18n.t('All this time'),
        value: DATES.ALL_TIME,
        getTimesRange: () => ({ to: Date.now(), from: 1546300800000 }),
    },
}

export const PAYOUTS_STATUSES = {
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    PAID: 'PAID',
    DECLINED: 'DECLINED',
}

export const PAYOUTS_STATUSES_DATA = {
    [PAYOUTS_STATUSES.PENDING]: {
        label: i18n.t('Pending'),
        color: '#979797',
    },
    [PAYOUTS_STATUSES.COMPLETED]: {
        label: i18n.t('Completed '),
        color: '#65BB5A',
    },
    [PAYOUTS_STATUSES.PAID]: {
        label: i18n.t('Paid'),
        color: '#65BB5A',
    },
    [PAYOUTS_STATUSES.DECLINED]: {
        label: i18n.t('Declined'),
        color: '#FF5656',
    },
    [PAYOUTS_STATUSES.FAILED]: {
        label: i18n.t('Failed'),
        color: '#FF5656',
    },
}
