import React from 'react'
import { useTranslation } from 'react-i18next'

import NavigationListBar from '../../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../../Controls/NavigationListBar/hooks/useNavigation'

import { Card as CardModel } from '../../rankBattleService'

import { CARD_TYPES } from '../../constants'

import CardsList from './components/CardsList/CardsList'

import styles from './Cards.module.scss'

const Cards = ({ cardType, structure, onUpdateCard, onUpdateCardsPosition, colorTheme, header, errors }) => {
    const { t } = useTranslation()

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    return (
        <div className={styles.sections}>
            <div className={styles.sectionsNavigation}>
                <h3 className={styles.sectionsNavigationTitle}>{header}</h3>
                <div className={styles.sectionsNavigationContent}>
                    <NavigationListBar
                        isShowIcon={cardType === CARD_TYPES.IMAGE}
                        colorTheme={colorTheme}
                        items={structure}
                        isHideAdd
                        titleOfItem={t('Card')}
                        focusedItemId={focusedElementId}
                        iconAccessor={CardModel.imageUrl}
                        navigationListBarClassName={styles.navigationListBarClassName}
                        onClickItem={scrollContainerToElement}
                    />
                </div>
            </div>
            <div className={styles.sectionsContent}>
                <CardsList
                    errors={errors}
                    scrollableContentNodeRef={scrollableContentNodeRef}
                    cardType={cardType}
                    structure={structure}
                    onSetFocusToSection={onSetFocusedElementId}
                    onUpdateCard={onUpdateCard}
                    onUpdateCardsPosition={onUpdateCardsPosition}
                />
            </div>
        </div>
    )
}

export default Cards
