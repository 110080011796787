export default [
    {
        isoCountryCode: 'RU',
        clientsCount: 400,
    },
    {
        isoCountryCode: 'DE',
        clientsCount: 90,
    },
    {
        isoCountryCode: 'GP',
        clientsCount: 75,
    },
    {
        isoCountryCode: 'UK',
        clientsCount: 50,
    },
    {
        isoCountryCode: 'US',
        clientsCount: 500,
    },
    {
        isoCountryCode: 'AU',
        clientsCount: 300,
    },
    {
        isoCountryCode: 'CA',
        clientsCount: 50,
    },
]
