import React from 'react'
import { isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'

import { getDateTime } from 'utils/dateTime'
import { downloadCSV } from 'common/utils/files'
import GAMIFICATION_ACTION_TYPES from 'common/constants/GamificationActionTypes'
import { CONDITIONS, hasPermission } from 'common/utils/permissions'
import Loader from 'components/Loader/Loader'
import { API__ANALYTICS } from 'api'

import { generateNameCSVFile } from '../../../../utils'

import { RATING_TITLE } from '../InteractiveBlockWrapper/utils.js'
import Upgrade from '../Upgrade/Upgrade'

import { isRatingActive } from './utils'
import RatingHeader from './components/RatingHeader/RatingHeader'
import RatingData from './components/RatingData/RatingData'

import './RatingTable.scss'

class RatingTable extends React.Component {
    constructor(props) {
        super(props)
        this.projectId = props.projectId
        this.blockId = props.blockProjection.blockId
        this.blockTypeId = props.blockProjection.blockTypeId
        this.isUserCanUseGamification = hasPermission(CONDITIONS.CAN_USE_GAMIFICATION)

        this.state = {
            isInitialLoading: true,
            ratings: [],
            selectedRating: null,
            participantsPage: null,
            page: 0,
            isLoading: true,
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        try {
            if (!this.isUserCanUseGamification) return

            const ratingsData = await this.getRatings()
            const latestRating = isEmpty(ratingsData) ? null : ratingsData[ratingsData.length - 1]
            let participantsPage = null
            if (latestRating) {
                const { page } = this.state
                participantsPage = await this.getRatingsOfParticipants({
                    from: latestRating.from,
                    to: latestRating.to,
                    page,
                })
            }

            this.setState({
                ratings: ratingsData,
                selectedRating: latestRating,
                participantsPage: participantsPage,
            })
        } finally {
            this.setState({ isInitialLoading: false, isLoading: false })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!this.isUserCanUseGamification) return

        const { selectedRating, page, ratings } = this.state
        const { periodInterval, dateRange } = this.props
        if (dateRange && prevProps.dateRange !== dateRange) {
            if (periodInterval.includes(RATING_TITLE)) {
                const number = periodInterval.split('_')[1]
                const rating = ratings.find(rating => rating.number === Number(number))
                this.setState({ selectedRating: rating ? rating : dateRange })
            } else {
                this.setState({ selectedRating: dateRange })
            }
        }

        const selectedIsChanged = selectedRating && selectedRating !== prevState.selectedRating
        if (selectedIsChanged || page !== prevState.page) {
            this.setState({ isLoading: true })
            try {
                const participantsPage = await this.getRatingsOfParticipants({
                    from: selectedRating.from,
                    to: selectedRating.to,
                    page,
                })

                this.setState({
                    participantsPage: participantsPage,
                })
            } finally {
                this.setState({ isLoading: false })
            }
        }
    }

    getRatings = () =>
        API__ANALYTICS.GET_PROJECT_STATISTICS_BY_ACTION_TYPE({
            projectId: this.projectId,
            blockId: this.blockId,
            actionName: GAMIFICATION_ACTION_TYPES[this.blockTypeId].reset,
        })

    getRatingsOfParticipants = ({ from, to, page }) =>
        API__ANALYTICS.GET_PROJECT_STATISTICS_RATING({
            projectId: this.projectId,
            blockId: this.blockId,
            actionName: GAMIFICATION_ACTION_TYPES[this.blockTypeId].reset,
            from,
            to,
            page,
        })
    getCSV = async () => {
        const { selectedRating } = this.state

        const content = await API__ANALYTICS.GET_BLOCK_RATING_CSV({
            projectId: this.projectId,
            blockId: this.blockId,
            actionName: GAMIFICATION_ACTION_TYPES[this.blockTypeId].finish,
            from: selectedRating.from,
            to: selectedRating.to,
        })

        const fileName = generateNameCSVFile('Ratings', selectedRating.from, selectedRating.to)
        downloadCSV(content, fileName)
    }

    render() {
        if (!this.isUserCanUseGamification) return <Upgrade />

        const { t, blockProjection, projectId } = this.props
        const { isInitialLoading, ratings, selectedRating, participantsPage, isLoading } = this.state

        const startDate = selectedRating && getDateTime(selectedRating.from)
        const endDate = selectedRating && selectedRating.to ? getDateTime(selectedRating.to) : null
        const isHasAnyRatings = !isEmpty(ratings)
        const isHasRatingData = !!participantsPage && !isEmpty(participantsPage.content)
        const isSelectedActiveRating = isRatingActive(selectedRating)
        const countParticipant = participantsPage && participantsPage.totalElements

        return isInitialLoading ? (
            <Loader />
        ) : (
            <div className="leader-board-panel">
                <div className="leader-board-panel__header">
                    <h2>{t('Leader Board')}</h2>
                    {isHasAnyRatings && (
                        <RatingHeader isLoading={isLoading} isHasRatingData={isHasRatingData} getCSV={this.getCSV} />
                    )}
                </div>
                <RatingData
                    projectId={projectId}
                    blockProjection={blockProjection}
                    isHasRatingData={isHasRatingData}
                    isLoading={isLoading}
                    isSelectedActiveRating={isSelectedActiveRating}
                    startDate={startDate}
                    endDate={endDate}
                    countParticipant={countParticipant}
                    participantsPage={participantsPage}
                    selectedRating={selectedRating}
                    onPageChange={page => this.setState({ page })}
                />
            </div>
        )
    }
}

export default withTranslation('translations')(RatingTable)
