import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Toast from 'components/Toast/Toast'

import { API__USER } from 'api'

import iconError from './i/error.svg'
import iconSuccess from './i/success.svg'

import styles from './NewsSubscription.module.scss'

const NewsSubscription = ({ userDetails, onSuccess = () => {} }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)

        try {
            const updatedUser = await API__USER.UPDATE_DETAILS(userDetails.id, {
                ...userDetails,
                login: userDetails.email,
                newsSubscription: !userDetails.newsSubscription,
            })
            await onSuccess(updatedUser)

            setIsLoading(false)
            Toast('success', {
                message: userDetails.newsSubscription
                    ? t('You have successfully unsubscribed from the email newsletter')
                    : t('You have successfully subscribed to the email newsletter'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.newsSubscription}>
            <div className={styles.side}>
                <img className={styles.icon} src={userDetails.newsSubscription ? iconSuccess : iconError} alt="" />
                <p className={styles.text}>{t('Keep me updated about new features and interactive formats')}</p>
            </div>
            <div className={styles.buttonBlock}>
                <button
                    className="button"
                    data-size="md"
                    disabled={isLoading}
                    data-variant={userDetails.newsSubscription ? 'secondary' : 'primary'}
                    onClick={() => onSubmit()}
                >
                    {userDetails.newsSubscription ? t('Unsubscribe') : t('Subscribe')}
                </button>
            </div>
        </div>
    )
}

export default NewsSubscription
