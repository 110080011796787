import { isValue } from 'utils/validator'
import { getClientId } from 'utils/clientId'

import { MAIN_SERVER } from './instance'

export const DELETE = async userId =>
    MAIN_SERVER({
        method: 'delete',
        url: `/users/${userId}`,
    })

export const GET_DETAILS = userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/users/${userId || 'me'}`,
    })

export const UPDATE_DETAILS = async (userId, data) =>
    MAIN_SERVER({
        method: 'put',
        url: `/users/${userId}`,
        data,
    })

export const SET_USER_ROLE = async (userId, roleId) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/users/${userId}/role`,
        data: {
            roleId,
        },
    })

export const SET_USER_INTERESTS = async (userId, interests) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/users/${userId}/interests`,
        data: {
            interestTags: interests,
        },
    })

export const CHANGE_PASSWORD = async (userId, data) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/users/${userId}/changepassword`,
        data,
    })

export const GET_RESTORE_PASSWORD_CODE = async email =>
    MAIN_SERVER({
        method: 'post',
        url: '/users/forgot',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            email,
        },
    })

export const ACTIVATE = async code =>
    MAIN_SERVER({
        method: 'get',
        url: '/users/activate',
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            activationCode: code,
        },
    })

export const RESTORE_PASSWORD = async obj => {
    try {
        const data = {}
        if (isValue(obj.email)) {
            data.login = obj.email
        }
        if (isValue(obj.password)) {
            data.newPassword = obj.password
        }
        if (isValue(obj.code)) {
            data.verificationCode = obj.code
        }

        return await MAIN_SERVER({
            method: 'patch',
            url: '/users/forgot',
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        })
    } catch (err) {
        throw err
    }
}

export const RESEND_ACTIVATION_LINK = async login =>
    MAIN_SERVER({
        method: 'post',
        url: '/users/activation/resend',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { login },
    })

export const GET_SUBSCRIPTIONS = userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/users/${userId}/subscriptions`,
    })

export const GET_SELECTED_SUBSCRIPTION = async userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/users/${userId}/selected-subscription`,
    })

export const GET_SUBSCRIPTION_CONSTRAINTS = async subscriptionId =>
    MAIN_SERVER({
        method: 'get',
        url: `/subscriptions/${subscriptionId}/constraints`,
    })

export const UPDATE_CURRENT_SUBSCRIPTION = (subscriptionId, update) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/subscriptions/${subscriptionId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(update),
    })

export const SEND_SUBSCRIPTION_CANCELLATION_REASON = (subscriptionId, reasonId, reasonText, userComment) =>
    MAIN_SERVER({
        method: 'post',
        url: '/subscriptions/cancellation-reason',
        data: {
            subscriptionId,
            reasonId,
            reasonText,
            userComment,
        },
    })

export const GET_TRIAL_COUNT = userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/users/${userId}/trial-count`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {},
    })

export const GET_AUTHORS_TAGS = () =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-content-tags`,
        params: {
            size: 1000,
            page: 0,
            sort: 'name,asc',
        },
    })

export const GET_PUBLIC_PROFILE = userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-profile/${userId}`,
    })

export const UPDATE_PUBLIC_PROFILE = (userId, data) =>
    MAIN_SERVER({
        method: 'put',
        url: `/public-profile/${userId}`,
        data,
    })

export const CHANGE_PROFILE_ACTIVATION = (userId, isActive) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/public-profile/${userId}/visibility`,
        data: {
            visible: isActive,
        },
    })

export const SET_USER_AVATAR = (userId, contentType, bytes) =>
    MAIN_SERVER({
        method: 'post',
        url: `/users/${userId}/avatar`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileLength: bytes.length || bytes.byteLength,
        },
        data: bytes,
        timeout: 60000,
    })

export const GET_USER_PUBLIC_PROFILE_STATISTIC_ACTIVITY = userId =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-profile/${userId}/statistic/activity`,
    })

export const GET_USER_PUBLIC_PROFILE_PAYOUTS = (userId, params) =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-profile/${userId}/payouts`,
        params: {
            ...params,
            size: 1000,
            page: 0,
        },
    })

export const GET_USER_PUBLIC_PROFILE_SALES = (userId, params) =>
    MAIN_SERVER({
        method: 'get',
        url: `/public-profile/${userId}/sales`,
        params: {
            ...params,
            size: 1000,
            page: 0,
        },
    })

export const SEND_PUBLIC_PROFILE_ACTION = ({ type, profileId, contentId = null }) =>
    MAIN_SERVER({
        method: 'post',
        url: 'public-profile-actions ',
        data: {
            type,
            profileId,
            contentId,
            clientId: getClientId(),
        },
    })

export const GET_USER_PUBLIC_PROFILE_FOLLOWERS = (userId, params = {}) =>
    MAIN_SERVER({
        method: 'get',
        url: `/followings/${userId}`,
        params,
    })

export const ADD_USER_PUBLIC_PROFILE_FOLLOWER = (userId, followerId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/public-profile/${userId}/followers`,
        data: {
            followerId,
        },
    })

export const DELETE_USER_PUBLIC_PROFILE_FOLLOWER = (userId, followerId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/public-profile/${userId}/followers/${followerId}`,
        data: {},
    })

export const GET_STRIPE_ACCOUNT_CREATE_LINK = userId =>
    MAIN_SERVER({
        method: 'post',
        url: `/public-profile/${userId}/stripe-connect`,
        data: {},
    })

export const GET_STRIPE_ACCOUNT_UPDATE_LINK = userId =>
    MAIN_SERVER({
        method: 'post',
        url: `/public-profile/${userId}/stripe-connect/onboarding-link `,
        data: {},
    })

export const GET_STRIPE_ACCOUNT_LOGIN_LINK = userId =>
    MAIN_SERVER({
        method: 'post',
        url: `/public-profile/${userId}/stripe-connect/login-link`,
        data: {},
    })

export const DELETE_STRIPE_ACCOUNT = userId =>
    MAIN_SERVER({
        method: 'delete',
        url: `/public-profile/${userId}/stripe-connect`,
        data: {},
    })

export const GET_INTERESTS_TAGS = () =>
    MAIN_SERVER({
        method: 'get',
        url: `/interests-tags`,
    })
