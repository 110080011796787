import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { getAvatarText } from 'common/utils/getAvatarText'
import { getFullUrlToSsr } from 'utils/router'

import styles from './FollowerItem.module.scss'

const FollowerItem = ({ data: { publicProfileId, avatarUrl, firstName, lastName }, onFollow, onUnfollow }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [isFollowing, setIsFollowing] = useState(true)

    return (
        <li className={styles.followerItem}>
            <a className={styles.user} href={getFullUrlToSsr(`/authors/${publicProfileId}`)} target="_blank">
                <div
                    className={styles.avatar}
                    style={{
                        backgroundColor: avatarUrl ? '#fff' : '#2990fb',
                        backgroundImage: avatarUrl ? `url(${avatarUrl})` : 'none',
                    }}
                >
                    {!avatarUrl && getAvatarText({ firstName, lastName })}
                </div>
                <p className={styles.name}>
                    {firstName} {lastName}
                </p>
            </a>
            <div
                className={classNames(styles.button, {
                    [styles.isLoadingButton]: isLoading,
                    [styles.isUnfollowButton]: isFollowing,
                    [styles.isFollowButton]: !isFollowing,
                })}
                onClick={async () => {
                    setIsLoading(true)
                    if (isFollowing) {
                        await onUnfollow(publicProfileId)
                        setIsFollowing(false)
                    } else {
                        await onFollow(publicProfileId)
                        setIsFollowing(true)
                    }
                    setIsLoading(false)
                }}
            >
                {isFollowing ? t('Unfollow') : t('Follow')}
            </div>
        </li>
    )
}

export default FollowerItem
