import React from 'react'
import { useTranslation } from 'react-i18next'

import { SUBSCRIPTION_TABS } from '../../constants'

const PageBar = ({ currentTab, handleTabClick }) => {
    const { t } = useTranslation()
    return (
        <div className="page-bar">
            <div className="title">
                <h1>{t('Billing and payments')}</h1>
            </div>
            <div className="tab">
                <ul>
                    <li
                        className={`${currentTab === SUBSCRIPTION_TABS.subscription ? 'is-active' : ''}`}
                        onClick={handleTabClick(SUBSCRIPTION_TABS.subscription)}
                    >
                        {t('Subscription')}
                    </li>
                    <li
                        className={`${currentTab === SUBSCRIPTION_TABS.billingHistory ? 'is-active' : ''}`}
                        onClick={handleTabClick(SUBSCRIPTION_TABS.billingHistory)}
                    >
                        {t('Billing history')}
                    </li>
                </ul>
            </div>
            <div></div>
        </div>
    )
}

export default PageBar
