import React, { useMemo } from 'react'
import { imgLoader } from 'common/services/imageLoader'
import Image from 'components/Form/Image'

import invertColor from '../../../../util/invertColor'

import './CardsBlockPreview.scss'

const CardsBlockPreview = ({ cards, colorTheme, backgroundImage, fontFamily, isTransparentBackground }) => {
    const getItemsCell = useMemo(() => {
        return cards.map(card => {
            return (
                <div key={card.id} className="cards-block-preview__card">
                    <Image
                        className="cards-block-preview__card-cover-image"
                        src={card.coverImage}
                        style={{ backgroundImage: `url(${imgLoader(card.coverImage)})` }}
                        alt={card.disclaimer}
                    />
                    {!!card.disclaimer && (
                        <div
                            className="cards-block-preview__card-disclaimer"
                            style={{ color: invertColor(colorTheme, true), fontFamily: `"${fontFamily}", sans-serif` }}
                        >
                            {card.disclaimer}
                        </div>
                    )}
                </div>
            )
        })
    }, [cards])

    return (
        <div
            className="cards-block-preview"
            style={{
                backgroundColor: isTransparentBackground ? 'transparent' : colorTheme,
                backgroundImage: isTransparentBackground ? 'none' : `url(${imgLoader(backgroundImage)})`,
            }}
        >
            {getItemsCell}
        </div>
    )
}

export default CardsBlockPreview
