const getBlocksFromStructure = projectStructureJson => {
    if (!projectStructureJson.hasOwnProperty('pages')) {
        console.error("projectStructureJson don't have properties pages")
        return []
    }
    return projectStructureJson.pages.reduce((acc, val) => {
        return [...acc, ...val.blocks]
    }, [])
}

export default getBlocksFromStructure
