import { MAIN_SERVER } from './instance'

export const GET_TEMPLATES = async params =>
    MAIN_SERVER({
        method: 'get',
        url: `/templates`,
        params,
    })

export const GET_BLOCK_TEMPLATES = async (blockTypeId, params) =>
    MAIN_SERVER({
        method: 'get',
        url: `/templates/blocks/${blockTypeId}`,
        params,
    })
