import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const convertConstraints = arr => Object.fromEntries(arr.map(datum => [datum.featureProduct.featureType, datum]))

const useUserSubscriptions = (subscriptionIdForConstraints = null) => {
    const { user_subscriptions, organizations } = useSelector(state => state)
    const [constraints, setConstraints] = useState(null)

    useEffect(() => {
        if (subscriptionIdForConstraints) {
            const subscription = user_subscriptions.find(sub => sub.id === subscriptionIdForConstraints)
            if (!!subscription && !subscription.expired) {
                setConstraints(convertConstraints(subscription.constraints))
                return
            }
        }

        if (!organizations.selectedSubscription?.constraints) return
        setConstraints(convertConstraints(organizations.selectedSubscription.constraints))
    }, [organizations.selectedSubscription, subscriptionIdForConstraints])

    return { subscriptions: user_subscriptions, constraints }
}

export default useUserSubscriptions
