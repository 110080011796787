import React, { useEffect, useState } from 'react'

import { CARD_PROPORTIONS } from 'components/Form/CardProportions/constants'
import { calculateCardSideSize } from '../../MemoryCardsModal/utils/utils.js'

import { getSize } from '../utils'

import { TreasureHuntTile } from './TreasureHuntTile'

export const TreasureHuntTileWrapper = ({
    tile,
    width,
    colorTheme,
    cols,
    isShowQuestCover,
    cardProportions,
    coverPosition,
}) => {
    const [currentProportions, setCurrentProportions] = useState(CARD_PROPORTIONS[0].proportionHeight)
    useEffect(() => {
        if (cardProportions) {
            const _currentProportions = CARD_PROPORTIONS.find(proportions => cardProportions === proportions.value)
            setCurrentProportions(_currentProportions ? _currentProportions.proportionHeight : 1)
        }
    }, [cardProportions])

    const rowHeight = `${calculateCardSideSize(width, cols, currentProportions)}px`
    const cellWidth = `${calculateCardSideSize(width, cols)}px`

    const size = getSize(cols)

    return (
        <div className="treasure-hunt-preview__tile-wrapper" style={{ width: cellWidth, height: rowHeight }}>
            <TreasureHuntTile
                tile={tile}
                colorTheme={colorTheme}
                isShowQuestCover={isShowQuestCover}
                size={size}
                coverPosition={coverPosition}
                currentProportions={currentProportions}
            />
        </div>
    )
}
