import React from 'react'
import { useTranslation } from 'react-i18next'

import NavigationListBar from '../../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../../Controls/NavigationListBar/hooks/useNavigation'

import { SECTION_TYPES } from '../../constants'
import { BLOCK_KEYS, Playground as PlaygroundModel, Section as SectionModel } from '../../wheelSpinService'

import SectionsList from './components/SectionsList/SectionsList'

import styles from './Sections.module.scss'

const Sections = ({ structure, onUpdateSection, onUpdateSectionsPosition, colorTheme, errors }) => {
    const { t } = useTranslation()

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    return (
        <div className={styles.sections}>
            <div className={styles.sectionsNavigation}>
                <h3 className={styles.sectionsNavigationTitle}>{t('Sectors')}</h3>
                <div className={styles.sectionsNavigationContent}>
                    <NavigationListBar
                        isShowIcon={
                            structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType] === SECTION_TYPES.IMAGE
                        }
                        colorTheme={colorTheme}
                        items={structure[BLOCK_KEYS.SECTIONS]}
                        isHideAdd
                        titleOfItem={t('Sector')}
                        focusedItemId={focusedElementId}
                        iconAccessor={SectionModel.imageUrl}
                        navigationListBarClassName={styles.navigationListBarClassName}
                        onClickItem={scrollContainerToElement}
                    />
                </div>
            </div>
            <div className={styles.sectionsContent}>
                <SectionsList
                    errors={errors}
                    scrollableContentNodeRef={scrollableContentNodeRef}
                    structure={structure}
                    onSetFocusToSection={onSetFocusedElementId}
                    onUpdateSection={onUpdateSection}
                    onUpdateSectionsPosition={onUpdateSectionsPosition}
                />
            </div>
        </div>
    )
}

export default Sections
