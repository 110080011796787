import React from 'react'

import { useAssetManager } from 'components/AssetManager'
import ImagePicker from '../../../../../../../../components/Form/ImagePicker/ImagePicker'
import TextArea from '../../../../../../../../components/Form/TextArea/TextArea'
import TextInput from '../../../../../../../../components/Form/TextInput/TextInput'
import ListActionBar from '../../Controls/ListActionBar/ListActionBar'

import { Slide, MAX_SLIDES } from './slideService'

const SlideCard = ({
    slide,
    slideError,
    index,
    count,
    t,
    methods: { onChangePosition, cloneSlide, removeSlide, updateSlide },
}) => {
    const { deleteAsset } = useAssetManager()

    return (
        <div className="slide-card">
            <div className="slide-card__image">
                <ImagePicker
                    label={`${index + 1}`}
                    value={slide.image}
                    errorMessages={slideError && slideError.image}
                    onUpdate={value => updateSlide(slide.id, { [Slide.image]: value })}
                />
            </div>
            <div className="slide-card__settings">
                <div className="slide-card__settings-header">
                    <div className="slide-card__settings-header-desc-label">{t('Description')}</div>
                    <div className="slide-card__settings-header-action">
                        <span
                            className="slide-card__settings-header-action-button"
                            onClick={() => updateSlide(slide.id, { [Slide.hasTextOptions]: !slide.hasTextOptions })}
                        >
                            {slide.hasTextOptions ? t('Less text options') : t('More text options')}
                        </span>
                        <ListActionBar
                            index={index}
                            length={count}
                            maxLength={MAX_SLIDES}
                            onChangePosition={onChangePosition}
                            onClone={() => cloneSlide(slide.id)}
                            onRemove={() => deleteAsset(slide.image, () => removeSlide(slide.id))}
                        />
                    </div>
                </div>
                <div className="slide-card__settings-desc-input">
                    <TextArea
                        value={slide.description}
                        onUpdate={value => updateSlide(slide.id, { [Slide.description]: value })}
                        rows={3}
                    />
                </div>
                {slide.hasTextOptions && (
                    <div className="slide-card__settings-text-options">
                        <div className="slide-card__settings-text-options-header">
                            <TextInput
                                label={t('Header')}
                                value={slide.header}
                                onUpdate={value => updateSlide(slide.id, { [Slide.header]: value })}
                            />
                        </div>
                        <div className="slide-card__settings-text-options-image-caption">
                            <TextInput
                                label={t('Image caption')}
                                value={slide.imageCaption}
                                onUpdate={value => updateSlide(slide.id, { [Slide.imageCaption]: value })}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SlideCard
