import React, { useEffect, useState } from 'react'

import { calculateTan } from 'utils/numbers'

import styles from './Section.module.scss'

const Section = ({ rotateDeg, sectionsCount, wheelInnerDiameter, backgroundColor, children }) => {
    const [additionalStyles, setAdditionalSStyles] = useState({})

    useEffect(() => {
        const _styles = {
            width: 0,
            clipPath: 'none',
            '--sectionRotateDeg': `${rotateDeg}deg`,
            left: 0,
            backgroundColor,
        }
        switch (sectionsCount) {
            case 2: {
                _styles.clipPath = 'none'
                _styles.width = wheelInnerDiameter
                break
            }
            default: {
                _styles.clipPath = 'polygon(100% 0, 50% 100%, 0 0)'
                _styles.width = wheelInnerDiameter * calculateTan(180 / sectionsCount)
                break
            }
        }

        _styles.left = wheelInnerDiameter / 2 - _styles.width / 2

        setAdditionalSStyles(_styles)
    }, [rotateDeg, backgroundColor, sectionsCount, wheelInnerDiameter])

    return (
        <li className={styles.section} style={additionalStyles}>
            {children}
        </li>
    )
}

export default Section
