import { MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { API__FOLDERS } from 'api'
import { IconFolder } from 'svg'

import MoveModal, { ALL_PROJECTS_ID } from '../MoveModal/MoveModal'

import styles from '../MoveModal/MoveModal.module.scss'

const MoveFolderModal = ({ movingFolderId, folders, onUpdateList, path, onClose }) => {
    const { t } = useTranslation()
    const [foldersList, setFoldersList] = useState(() => folders.filter(folder => folder.id !== movingFolderId))
    const [targetFolderId, setTargetFolderId] = useState(
        (foldersList.length && path.length) === 1 ? foldersList[0].id : ALL_PROJECTS_ID,
    )
    const { selectedOrganizationId } = useSelector(state => ({
        selectedOrganizationId: state.organizations.selectedOrganizationId,
    }))

    useEffect(() => {
        setFoldersList(folders.filter(folder => folder.id !== movingFolderId))
    }, [folders, movingFolderId])

    const handleMove = () => {
        const parentId = targetFolderId === ALL_PROJECTS_ID ? null : targetFolderId
        API__FOLDERS.UPDATE_FOLDER({
            id: movingFolderId,
            organizationId: selectedOrganizationId,
            parentId,
        }).then(() => {
            onUpdateList()
            onClose()
        })
    }

    return (
        <MoveModal
            title={t('Move folder to')}
            value={targetFolderId}
            onChange={evt => setTargetFolderId(evt.target.value)}
            isHideAllProjectsItem={path.length === 1}
            menuItems={foldersList.map(folder => (
                <MenuItem key={folder.id} value={folder.id}>
                    <IconFolder />
                    <span className={styles.selectItem}>{folder.name}</span>
                </MenuItem>
            ))}
            onMove={handleMove}
            onClose={onClose}
        />
    )
}

export default MoveFolderModal
