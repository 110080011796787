import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep } from 'lodash'

import { API__MAILCHIMP, HTTP_STATUS } from 'api'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'
import Toast from 'components/Toast/Toast'
import Loader from 'components/Loader/Loader'
import StopPanel from 'components/Modal/components/StopPanel/StopPanel'

import iconGirl from '../../pages/Editor/Tabs/Editor/Blocks/components/Modals/i/girl.svg'

import MailchimpMappingView from './MailchimpMappingView'

import { findMissedRequiredFields, getTestValueByField, initFieldMap } from './utilities'

import styles from './MailChimpManagerModal.module.scss'

const MailChimpManagerModal = ({
    connector,
    organizationId,
    projectId,
    blockId,
    leadFormFields,
    setIsAuthorized,
    setConnector,
    onClose,
}) => {
    const { t } = useTranslation()
    const [lists, setLists] = useState([])
    const [selectedListId, setSelectedListId] = useState(!isEmpty(connector) ? connector.listId : null)
    const [fieldMap, setFieldMap] = useState(() => initFieldMap(connector, leadFormFields))
    const [mailchimpFields, setMailchimpFields] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState({})

    const onHandleIfAccessRevoked = err => {
        if (err.response.status === HTTP_STATUS.Forbidden) {
            API__MAILCHIMP.DELETE_CONFIG(organizationId, projectId).then(() => {
                setIsAuthorized(false)
                onClose()
            })
            return null
        } else return err
    }

    useEffect(() => {
        API__MAILCHIMP.GET_MAILCHIMP_LISTS(organizationId, projectId)
            .then(_lists => {
                setLists(
                    _lists.map(item => {
                        return { label: item.name, value: item.listId }
                    }),
                )
            })
            .catch(onHandleIfAccessRevoked)
            .finally(() => {
                setIsLoading(false)
            })
    }, [organizationId])

    useEffect(() => {
        if (selectedListId !== null) {
            API__MAILCHIMP.GET_FIELDS_BY_LIST(organizationId, selectedListId, projectId)
                .then(fields => {
                    setMailchimpFields(fields)
                })
                .catch(onHandleIfAccessRevoked)
        }
    }, [organizationId, selectedListId])

    const onSelectList = item => {
        if (selectedListId !== item.value) {
            setSelectedListId(item.value)
            setFieldMap({})
        }
    }

    const onFieldSelected = (value, id) => {
        const newFieldMap = cloneDeep(fieldMap)
        newFieldMap[id] = value.value
        setFieldMap(newFieldMap)
    }

    const onSave = async () => {
        if (selectedListId === null) {
            setError({ message: t('Please choose list item') })
            return
        }

        const missedRequiredFields = findMissedRequiredFields(fieldMap, mailchimpFields)
        if (!isEmpty(missedRequiredFields)) {
            const requiredFields = ': ' + missedRequiredFields.map(item => item.name).join()
            setError({ message: t('Please map all required mailchimp fields') + requiredFields })
            return
        }

        setIsSaving(true)
        const fields = Object.entries(fieldMap).map(([key, value]) => {
            const field = leadFormFields.find(_field => _field.key === key)
            return { tag: value, value: getTestValueByField(key, field.type) }
        })
        API__MAILCHIMP.TEST_CONTACT(organizationId, selectedListId, fields, projectId)
            .then(() => {
                const mappings = Object.entries(fieldMap).map(([key, value]) => {
                    return { externalFieldTag: value, internalFieldName: key }
                })
                API__MAILCHIMP.CREATE_CONNECTOR({
                    organizationId,
                    projectId,
                    blockId,
                    listId: selectedListId,
                    mappings,
                })
                    .then(connectorId => {
                        const _connector = { connectorId, mappings, listId: selectedListId, disabled: false }
                        setConnector(_connector)
                        setFieldMap(initFieldMap(_connector, leadFormFields))
                        Toast('success', { message: t('Integrations connected to lead form') })
                    })
                    .finally(() => onClose())
            })
            .catch(err => {
                if (err.response.status === HTTP_STATUS.Forbidden) {
                    API__MAILCHIMP.DELETE_CONFIG(organizationId, projectId).then(() => {
                        setIsAuthorized(false)
                        Toast('info', { message: t('Looks like access to mailchimp has been revoked') })
                        onClose()
                    })
                    return null
                } else return err
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Mailchimp integration')} isCloseIcon={false} />
            <div className={styles.MailchimpModalWrapper}>
                {isLoading && <Loader forModal />}
                {!isLoading && (
                    <ScrollableBlock>
                        <MailchimpMappingView
                            lists={lists}
                            connector={connector}
                            leadFormFields={leadFormFields}
                            selectedListId={selectedListId}
                            mailchimpFields={mailchimpFields}
                            fieldMap={fieldMap}
                            onSelectList={onSelectList}
                            onFieldSelected={onFieldSelected}
                        />
                    </ScrollableBlock>
                )}
                {!isEmpty(error) ? (
                    <StopPanel
                        t={t}
                        headText={t('Oh! Need more information')}
                        descriptionText={error.message}
                        image={iconGirl}
                        onBack={() => setError({})}
                        isShowQuit={false}
                    />
                ) : null}
            </div>
            <Footer
                noteText={
                    <Trans
                        i18nKey="For more information click <lnk>here</lnk>"
                        components={{
                            lnk: (
                                <a
                                    target="_blank"
                                    href={'https://help.interacty.me/content/how-to-collecting-leads-with-mailchimp'}
                                />
                            ),
                        }}
                    />
                }
                actionText={t('Test & Save')}
                cancelText={t('Cancel')}
                isProcessing={isSaving}
                onClose={onClose}
                onAction={onSave}
            />
        </Modal>
    )
}

export default MailChimpManagerModal
