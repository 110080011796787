export const msFormat = value => {
    const sec = 1
    const min = 60 * sec
    const hour = 60 * min
    const day = 24 * hour
    const month = 30 * day

    if (value === 0) {
        return '0'
    }

    if (value >= month) {
        return `${prepare(value / month)} mo`
    }

    if (value >= day) {
        return `${prepare(value / day)} day`
    }

    if (value >= hour) {
        return `${prepare(value / hour)} h`
    }

    if (value >= min) {
        return `${prepare(value / min)} min`
    }

    return `${prepare(value / sec)} sec`
}

const prepare = value => (value - (value | 0) < 0.1 ? (value | 0).toString() : value.toFixed(1)).split('.').join(',')
