import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

class CustomDatePicker extends React.Component {
    render() {
        const { value, onClick } = this.props

        return (
            <div onClick={onClick} className="date-picker-container">
                {moment(value, 'MM.DD.YYYY').format('DD.MM.YYYY')}
            </div>
        )
    }
}

export default function Picker({ onChange, selected, minDate = new Date('01.01.2010'), maxDate }) {
    return (
        <DatePicker
            showYearDropdown
            maxDate={maxDate}
            minDate={minDate}
            selected={selected}
            onChange={onChange}
            customInput={<CustomDatePicker />}
        />
    )
}
