import React from 'react'
import { useTranslation } from 'react-i18next'

import TextInput from 'components/Form/TextInput/TextInput'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import Select from 'components/Form/Select/Select'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import RadioPair from 'components/Form/RadioPair/RadioPair'

import Preview from '../../../../../WheelSpin/components/Preview/Preview'

import { SECTIONS_COUNT_ARRAY, MIN_ANIMATION_DURATION, MAX_ANIMATION_DURATION } from '../../constants'

import { BLOCK_KEYS, Playground as PlaygroundModel } from '../../wheelSpinService'

import styles from './Playground.module.scss'

const Playground = ({ structure, colorTheme, colorScheme, fontFamily, onUpdatePlayground, errors }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.playground}>
            <div className={styles.workplaceWrapper}>
                <ScrollableBlock>
                    <div className={styles.workplace}>
                        <h3 className={styles.workplaceTitle}>{t('Playground')}</h3>
                        <div className={styles.workplaceContent}>
                            <div className={styles.workplaceLayoutRow}>
                                <Select
                                    label={t('Number of sectors')}
                                    errorMessages={errors?.[PlaygroundModel.sectionsCount]}
                                    value={{
                                        value: structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionsCount],
                                        label: structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionsCount],
                                    }}
                                    onUpdate={option =>
                                        onUpdatePlayground({ [PlaygroundModel.sectionsCount]: option.value })
                                    }
                                    options={SECTIONS_COUNT_ARRAY.map(item => ({
                                        value: item,
                                        label: item,
                                    }))}
                                />
                                <RadioPair
                                    label={t('Sector type')}
                                    activeItem={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType]}
                                    onSelect={v => onUpdatePlayground({ [PlaygroundModel.sectionType]: v })}
                                />
                            </div>
                            <UploadImage
                                label={t('Center pin image')}
                                isDisabledDelete
                                value={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.spinImageUrl]}
                                onUpdate={v => onUpdatePlayground({ [PlaygroundModel.spinImageUrl]: v })}
                            />
                            <br />
                            <TextInput
                                label={`${t('Animation time')} (${t('sec')})`}
                                isRequired
                                controlType="number"
                                errorMessages={errors?.[PlaygroundModel.animationDuration]}
                                min={MIN_ANIMATION_DURATION}
                                max={MAX_ANIMATION_DURATION}
                                value={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.animationDuration]}
                                onUpdate={v => onUpdatePlayground({ [PlaygroundModel.animationDuration]: v })}
                            />
                            <br />
                            <Checkbox
                                label={t('Show cover')}
                                value={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.isShowCover]}
                                onUpdate={v => onUpdatePlayground({ [PlaygroundModel.isShowCover]: v })}
                            />
                            {structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.isShowCover] && (
                                <>
                                    <TextInput
                                        label={t('Header')}
                                        isRequired
                                        errorMessages={errors?.[PlaygroundModel.coverHeader]}
                                        isDisabled={!structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.isShowCover]}
                                        value={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.coverHeader]}
                                        onUpdate={v => onUpdatePlayground({ [PlaygroundModel.coverHeader]: v })}
                                    />
                                    <TextInput
                                        label={t('Button text')}
                                        isRequired
                                        errorMessages={errors?.[PlaygroundModel.coverButtonText]}
                                        isDisabled={!structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.isShowCover]}
                                        value={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.coverButtonText]}
                                        onUpdate={v => onUpdatePlayground({ [PlaygroundModel.coverButtonText]: v })}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className={styles.previewWrapper}>
                <div className={styles.preview}>
                    <p className={styles.previewTitle}>{t('Approximate preview')}</p>
                    <Preview
                        isModal
                        colorTheme={colorTheme}
                        colorScheme={colorScheme}
                        fontFamily={fontFamily}
                        sections={structure[BLOCK_KEYS.SECTIONS]}
                        sectionType={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType]}
                        spinImageUrl={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.spinImageUrl]}
                        isShowCover={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.isShowCover]}
                        coverHeader={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.coverHeader]}
                        coverButtonText={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.coverButtonText]}
                    />
                </div>
            </div>
        </div>
    )
}

export default Playground
