import React from 'react'

import styles from './BlockImage.module.scss'

const BlockImage = ({ height, imageUrl }) => (
    <div className={styles.sectionContentImage} style={{ maxWidth: height }}>
        <div
            className={styles.image}
            style={{
                backgroundImage: `url(${imageUrl})`,
            }}
        />
    </div>
)

export default BlockImage
