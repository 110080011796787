import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { withTranslation } from 'react-i18next'

import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'

import TextArea from 'components/Form/TextArea/TextArea'
import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import Checkbox from 'components/Form/Checkbox/Checkbox'

import TextInputWithPageSelection from '../../components/TextInputWithPageSelection/TextInputWithPageSelection'

import invertColor from '../../util/invertColor'
import { timeIsUpValidator, collectTimeIsUpErrors } from './utils/utils'
import { TIME_IS_UP_INIT_STATE } from './utils/constants'

import './TimeIsUpModal.scss'

const TimeIsUpModal = ({ i18n, data: { value, blockId, fieldName, colorTheme }, onClose, onUpdate }) => {
    const [structure, setStructure] = useState(TIME_IS_UP_INIT_STATE)
    const [validateErrors, setValidateErrors] = useState({})

    useEffect(() => {
        if (Object.keys(value).length) {
            setStructure(cloneDeep(value))
        }
    }, [value])

    const onSave = async () => {
        const isValid = timeIsUpValidator(structure)

        if (!isValid) {
            setValidateErrors(collectTimeIsUpErrors(structure))
            return
        }

        onUpdate({
            [blockId]: {
                data: { [fieldName]: structure },
            },
        })
        onClose()
    }

    const updateStructure = newFields => {
        setStructure(prevState => {
            return {
                ...prevState,
                ...newFields,
            }
        })
    }

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false}>
            <Header title={i18n.t('“Time is up” page')} isCloseIcon={false} />

            <div className="time-is-up-screen">
                <div className="time-is-up-screen__workplace-wrapper">
                    <ScrollableBlock>
                        <div className="time-is-up-screen__workplace">
                            <h3 className="time-is-up-screen__workplace-title">
                                {i18n.t('The user will see this page if the game time is up.')}
                            </h3>
                            <div className="time-is-up-screen__workplace-content">
                                <TextInput
                                    label={i18n.t('Header')}
                                    isRequired
                                    value={structure.header}
                                    errorMessages={validateErrors.header}
                                    onUpdate={_value => updateStructure({ header: _value })}
                                />
                                <TextArea
                                    label={i18n.t('Description')}
                                    value={structure.description}
                                    onUpdate={_value => updateStructure({ description: _value })}
                                />
                                <UploadImage
                                    value={structure.imageSrc}
                                    onUpdate={_value => updateStructure({ imageSrc: _value })}
                                />
                                <Checkbox
                                    label={i18n.t('Call to action button')}
                                    value={structure.isActionButton}
                                    onUpdate={_value => updateStructure({ isActionButton: _value })}
                                />
                                {structure.isActionButton && (
                                    <>
                                        <ElementsGroup isEquallyWidth>
                                            <TextInput
                                                label={i18n.t('Button text')}
                                                isDisabled={!structure.isActionButton}
                                                value={structure.actionButtonText}
                                                onUpdate={_value => updateStructure({ actionButtonText: _value })}
                                            />
                                            <TextInputWithPageSelection
                                                value={structure.actionButtonLink}
                                                onUpdate={_value => updateStructure({ actionButtonLink: _value })}
                                            />
                                        </ElementsGroup>
                                    </>
                                )}
                            </div>
                        </div>
                    </ScrollableBlock>
                </div>
                <div className="time-is-up-screen__preview-wrapper">
                    <ScrollableBlock>
                        <p className="time-is-up-screen__preview-title">{i18n.t('Approximate preview')}</p>
                        <div className="time-is-up-screen__preview">
                            {structure.imageSrc ||
                            structure.header ||
                            structure.description ||
                            structure.isActionButton ? (
                                <div className="time-is-up-screen__preview-content">
                                    {structure.imageSrc && (
                                        <img
                                            className="time-is-up-screen__preview-image"
                                            src={structure.imageSrc}
                                            alt="img"
                                        />
                                    )}
                                    <div className="time-is-up-screen__preview-header">{structure.header}</div>
                                    <div className="time-is-up-screen__preview-description">
                                        {structure.description}
                                    </div>
                                    <div className="time-is-up-screen__preview-buttons">
                                        {structure.isActionButton && (
                                            <button
                                                className="time-is-up-screen__preview-button"
                                                style={{
                                                    backgroundColor: colorTheme,
                                                    color: invertColor(colorTheme, true),
                                                }}
                                            >
                                                {structure.actionButtonText}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <p className="time-is-up-screen__preview-empty">
                                    {i18n.t('You have not entered any data yet')}
                                </p>
                            )}
                        </div>
                    </ScrollableBlock>
                </div>
            </div>

            <Footer actionText={i18n.t('Save')} cancelText={i18n.t('Cancel')} onAction={onSave} onClose={onClose} />
        </Modal>
    )
}

export default withTranslation('translations')(TimeIsUpModal)
