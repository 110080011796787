import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import MobileTongue from '../components/MobileTongue/MobileTongue'
import { DIRECTIONS } from '../components/MobileTongue/constants'
import PanelHeader from '../components/PanelHeader'

import Round from './components/Round'

import './InternalRounds.scss'

const InternalRounds = ({
    rounds,
    selectedRound,
    onSelectRound,
    onAddRound,
    onRenameRound,
    onCloneRound,
    onDeleteRound,
    onChangePosition,
}) => {
    const { t } = useTranslation()
    const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState(false)
    const [deletedRoundId, setDeletedRoundId] = useState(null)
    const panelScrollRef = useRef()
    const [isMobileOpened, setIsOpenedMobile] = useState(false)

    const onOpenDeleteConfirmationDialog = id => {
        setDeletedRoundId(id)
        setIsOpenConfirmationDialog(true)
    }

    const cloneRound = id => {
        onCloneRound(id)
    }

    const deleteRound = () => {
        onDeleteRound(deletedRoundId)
        setIsOpenConfirmationDialog(false)
    }
    const addRound = () => {
        onAddRound()
    }

    return (
        <div className={classNames('internal-rounds', { 'internal-rounds--opened': isMobileOpened })}>
            <MobileTongue
                isOpened={isMobileOpened}
                onChange={value => setIsOpenedMobile(value)}
                direction={DIRECTIONS.LEFT}
                closeText={t('Close rounds')}
                openText={t('Open rounds')}
            />
            <PanelHeader className="internal-rounds__title" blockName={t('Rounds')} scrollRef={panelScrollRef} />
            <ScrollableBlock styles={{ height: 'calc(100% - 56px)', width: '100%' }} scrollableNodeRef={panelScrollRef}>
                <div className="internal-rounds__round-list">
                    {rounds.map((round, index) => (
                        <Round
                            key={round.id}
                            round={round}
                            selectedRound={selectedRound}
                            index={index}
                            arrayLength={rounds.length}
                            methods={{
                                onSelectRound,
                                onRenameRound,
                                onRemove: onOpenDeleteConfirmationDialog,
                                onClone: cloneRound,
                                onChangePosition,
                            }}
                        />
                    ))}
                </div>
                <div className="internal-rounds__add-button" onClick={addRound}>
                    {t('Add round')}
                </div>
            </ScrollableBlock>

            {isOpenConfirmationDialog && (
                <ConfirmationDialog
                    onClose={() => setIsOpenConfirmationDialog(false)}
                    onAction={deleteRound}
                    data={{
                        headText: t('Are you sure you want to delete this round?'),
                        noteText: t('All blocks on this round will also be deleted.'),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </div>
    )
}

export default InternalRounds
