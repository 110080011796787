import React from 'react'
import { useTranslation } from 'react-i18next'

import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'

import TabContent from '../../../components/TabContent/TabContent'
import FinalPreview from '../../../components/FinalPreview/FinalPreview'

import { Final as FinalModel } from '../../slidingPuzzleService'

import styles from './Final.module.scss'

const Final = ({ errors, structure, onUpdate }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.final}>
            <TabContent>
                <TextInput
                    isRequired
                    label={t('Header')}
                    errorMessages={errors?.[FinalModel.header]}
                    value={structure[FinalModel.header]}
                    onUpdate={value => onUpdate({ [FinalModel.header]: value })}
                />
                <TextArea
                    label={t('Description')}
                    errorMessages={errors?.[FinalModel.description]}
                    value={structure[FinalModel.description]}
                    onUpdate={value => onUpdate({ [FinalModel.description]: value })}
                />
                <ElementsGroup>
                    <UploadImage
                        label={t('Result image')}
                        value={structure[FinalModel.image]}
                        onUpdate={value => onUpdate({ [FinalModel.image]: value })}
                    />
                    {structure[FinalModel.image] && (
                        <TextInput
                            label={t('Image disclaimer')}
                            isOptional
                            errorMessages={errors?.[FinalModel.imageDisclaimer]}
                            value={structure[FinalModel.imageDisclaimer]}
                            onUpdate={value => onUpdate({ [FinalModel.imageDisclaimer]: value })}
                        />
                    )}
                </ElementsGroup>
            </TabContent>
            <FinalPreview
                imageUrl={structure[FinalModel.image]}
                imageDisclaimer={structure[FinalModel.imageDisclaimer]}
                header={structure[FinalModel.header]}
                description={structure[FinalModel.description]}
            />
        </div>
    )
}

export default Final
