import React from 'react'

import { Item as ItemModel } from '../../../components/Modals/CrosswordModal/crosswordService'

import Playground from './components/Playground/Playground'

import styles from './Preview.module.scss'

const Preview = ({ struct, fontFamily, colorTheme, letterColor }) => (
    <div className={styles.preview}>
        <Playground
            items={struct.items.filter(word => word[ItemModel.isIncluded]).sort((a, b) => b.position - a.position)}
            colorTheme={colorTheme}
            letterColor={letterColor}
            fontFamily={fontFamily}
        />
    </div>
)

export default Preview
