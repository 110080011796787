import { isEuVersion } from './env'

const EMAILS = {
    INTERACTY_INFO: isEuVersion ? 'info@interacty.eu' : 'info@interacty.me',
    INTERACTY_SUPPORT: isEuVersion ? 'support@interacty.eu' : 'support@interacty.me',
}

Object.freeze(EMAILS)

export default EMAILS
