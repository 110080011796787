import { useSelector, useDispatch } from 'react-redux'

import { USER_PUBLIC_PROFILE_STATUSES } from 'common/constants/user'

import { isEducation } from 'common/utils/permissions'

import { API__USER } from 'api'

import { USER__SET_PUBLIC_PROFILE } from 'store/actions'

const useUserPublicProfile = () => {
    const { user_details, userPublicProfile } = useSelector(state => state)
    const dispatch = useDispatch()

    const refreshUserPublicProfile = async () => {
        try {
            if (!isEducation()) return
            const response = await API__USER.GET_PUBLIC_PROFILE(user_details.id)
            await dispatch(USER__SET_PUBLIC_PROFILE(response))
        } catch (err) {
            console.error(err)
        }
    }

    return {
        userPublicProfile,
        isPublishedUserPublicProfile: userPublicProfile
            ? userPublicProfile.publicStatus === USER_PUBLIC_PROFILE_STATUSES.PUBLIC
            : false,
        refreshUserPublicProfile,
    }
}

export default useUserPublicProfile
