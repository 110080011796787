import i18n from 'i18n'

export const MODE = {
    CREATE_FROM_FILE: 'CREATE_FROM_FILE',
    CREATE_NEW: 'CREATE_NEW',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

export const TITLE = {
    [MODE.CREATE_FROM_FILE]: i18n.t('Review questions'),
    [MODE.CREATE_NEW]: i18n.t('Create questions'),
    [MODE.EDIT]: i18n.t('Edit questions'),
    [MODE.VIEW]: i18n.t('View questions'),
}
