import React from 'react'
import { Trans, withTranslation } from 'react-i18next'

import Modal from '../Modal'

import imageWoman from './i/woman.svg'

import './UpgradeDialog.scss'

const UpgradeDialog = ({
    i18n,
    data: {
        headText = i18n.t('Upgrade your plan'),
        upgradeVariant = 'primary',
        upgradeText = i18n.t('Upgrade'),
        cancelText = i18n.t('Next time'),
        tariff,
        text = i18n.t('This feature is not available for your current plan'),
    } = {},
    closeOnEsc = true,
    closeOnOverlayClick = true,
    onUpgrade,
    onClose,
}) => {
    return (
        <Modal closeOnEsc={closeOnEsc} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
            <div className="upgrade-dialog">
                <div className="upgrade-dialog__close" onClick={() => onClose()}></div>
                <h2 className="upgrade-dialog__head">{headText}</h2>
                <div className="upgrade-dialog__image">
                    <img src={imageWoman} alt="" />
                </div>
                <div className="upgrade-dialog__description">
                    {tariff ? (
                        <Trans
                            i18nKey="This feature is available for paid plans starting
from <0>{{tariffName}}</0>"
                            values={{ tariffName: tariff }}
                            components={[<span>, </span>]}
                        />
                    ) : (
                        text
                    )}
                </div>
                <div className="confirmation-dialog__buttons">
                    <button
                        className="confirmation-dialog__button button"
                        data-size="lg"
                        data-variant="secondary"
                        onClick={() => onClose()}
                    >
                        {cancelText}
                    </button>
                    <button
                        className="confirmation-dialog__button button"
                        data-size="lg"
                        data-variant={upgradeVariant}
                        onClick={() => onUpgrade()}
                    >
                        {upgradeText}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default withTranslation('translations')(UpgradeDialog)
