import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MemoryPlaygroundPreview from '../components/Modals/MemoryCardsModal/components/MemoryPlaygroundPreview/MemoryPlaygroundPreview'
import MemoryCardsModal from '../components/Modals/MemoryCardsModal/MemoryCardsModal'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'
import StatisticPreview from '../components/StatisticPreview/StatisticPreview'

const Memory = ({
    struct,
    colorTheme,
    enableTimer,
    isEnableRating,
    numberedCardBacks,
    numberedCardBacksType,
    onUpdate,
    id,
}) => {
    const { t } = useTranslation()
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            {enableTimer && <StatisticPreview />}
            <MemoryPlaygroundPreview
                data={{
                    colorTheme,
                    cardLayout: struct.playground.cardLayout,
                    cardBackImage: struct.playground.cardBackImage,
                    cardProportions: struct.playground.cardProportions,
                    isShowCover: struct.playground.isShowCover,
                    coverHeader: struct.playground.coverHeader,
                    coverButtonText: struct.playground.coverButtonText,
                    enableTimer,
                    isEnableRating,
                    numberedCardBacks,
                    numberedCardBacksType,
                }}
            />

            {isShowModal && (
                <MemoryCardsModal
                    data={{
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                    modalTitle={t('Memory Options')}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Memory
