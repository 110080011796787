import i18n from 'i18n'

import coverIcon from 'components/Modal/components/Body/components/SideNav/i/cover.svg'
import questionsIcon from 'components/Modal/components/Body/components/SideNav/i/questions.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const TRIVIA_TABS = {
    startView: 1,
    questionView: 2,
    resultView: 3,
}

export const TAB_TO_FIELD_MAP = {
    [TRIVIA_TABS.startView]: 'cover',
    [TRIVIA_TABS.questionView]: 'questions',
    [TRIVIA_TABS.resultView]: 'results',
}

export const TRIVIA_TABS_ARRAY = [
    {
        id: TRIVIA_TABS.startView,
        label: i18n.t('Start screen'),
        icon: coverIcon,
    },
    {
        id: TRIVIA_TABS.questionView,
        label: i18n.t('Questions'),
        icon: questionsIcon,
    },
    {
        id: TRIVIA_TABS.resultView,
        label: i18n.t('Results'),
        isHideFromMultiplayer: true,
        icon: resultsIcon,
    },
]

export const MIN_QUESTIONS = 1
export const MAX_QUESTIONS = 100
export const MIN_ANSWERS_IN_QUESTION = 2
export const MAX_ANSWERS_IN_QUESTION = 50
export const MIN_CORRECT_ANSWERS_IN_QUESTION = 1
export const MIN_INCORRECT_ANSWERS_IN_QUESTION = 1
export const MIN_RESULTS = 2
