import { DEFAULT_IMAGE_BG_BIG_URL, DEFAULT_IMAGE_BG_BIG_BRIGHT_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'

import { CARD_TYPES } from '../../MemoryCardsModal/utils/constants'

export class MatchingCard {
    id // <String> id
    src // <null/String[URL]> image
    cardType // <String> card type
    text // <String> text
    bgColor // <String> background color card

    constructor({ text = '', src = DEFAULT_IMAGE_BG_BIG_URL, bgColor = '#ffffff', cardType = CARD_TYPES.image } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.src = src
        this.bgColor = bgColor
        this.cardType = cardType
    }

    static get text() {
        return 'text'
    }

    static get src() {
        return 'src'
    }

    static get bgColor() {
        return 'bgColor'
    }

    static get cardType() {
        return 'cardType'
    }
}

export class MatchingPair {
    id // <String> id
    description // <String> description
    firstImage // <MatchingCard> first card
    secondImage // <MatchingCard> second card

    constructor({
        description = '',
        firstImage = new MatchingCard({ src: DEFAULT_IMAGE_BG_BIG_URL }),
        secondImage = new MatchingCard({ src: DEFAULT_IMAGE_BG_BIG_BRIGHT_URL }),
    } = {}) {
        this.id = getUniqueId()
        this.description = description
        this.firstImage = firstImage
        this.secondImage = secondImage
    }

    static get description() {
        return 'description'
    }

    static get firstImage() {
        return 'firstImage'
    }

    static get secondImage() {
        return 'secondImage'
    }
}
