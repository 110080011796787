import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import Loader from 'components/Loader/Loader'
import Toast from 'components/Toast/Toast'

import useUserPublicProfile from 'hooks/useUserPublicProfile'

import { API__CONTENT } from 'api'

import TopBanner from '../../components/TemplateModal/TopBanner/TopBanner'
import ContentTitle from '../../components/TemplateModal/ContentTitle/ContentTitle'

import SetUpProduct from './views/SetUpProduct/SetUpProduct'

import styles from './EditTemplateModal.module.scss'

const EditTemplateModal = ({
    data: {
        project: { id, templatePublicContentId },
    },
    onClose,
    onSuccess,
    onLoadProjects,
}) => {
    const { t } = useTranslation()
    const { user_details } = useSelector(state => state)
    const [isLoading, setIsLoading] = useState(true)
    const [content, setContent] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        API__CONTENT.GET_PUBLIC_CONTENT(templatePublicContentId)
            .then(data => {
                setContent(data)
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err)
                Toast('error', {})
                onClose()
            })
    }, [])

    const { userPublicProfile } = useUserPublicProfile()

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose} isWhiteCloseIcon>
            <div className={styles.editTemplateModal}>
                <TopBanner title={t('Set up your product')} userPublicProfile={userPublicProfile} />
                <div className={styles.content}>
                    <ContentTitle />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <SetUpProduct
                            projectId={id}
                            userDetails={user_details}
                            content={content}
                            onSuccess={onSuccess}
                            onLoadProjects={onLoadProjects}
                        />
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default EditTemplateModal
