import getBlocksFromStructure from 'utils/getBlocksFromStructure'

import { createFieldsGetter } from 'common/utils/getterService'

import { REMIX_DEFAULT_TEXTS } from './REMIX_DEFAULT_TEXTS'
import { BLOCK_LIST_SCHEME } from './BLOCK_LIST_SCHEME'

const traverseAndFlatten = (currentNode, flattenedKey, target = {}) => {
    for (const key in currentNode) {
        if (currentNode.hasOwnProperty(key)) {
            let newKey
            if (flattenedKey === undefined) {
                newKey = key
            } else {
                newKey = `${flattenedKey}.${key}`
            }

            const value = currentNode[key]
            if (typeof value === 'object') {
                traverseAndFlatten(value, newKey, target)
            } else {
                target[newKey] = value
            }
        }
    }
    return target
}

const fieldsGetter = createFieldsGetter(BLOCK_LIST_SCHEME)

export const getBlocksTranslatedValues = app => {
    const allBlocks = getBlocksFromStructure(app)
    const data = fieldsGetter({ blocks: allBlocks })
    const blockLocales = data.blocks.reduce((acc, block) => {
        const flatBlock = traverseAndFlatten(block)
        Object.values(flatBlock).forEach(val => {
            if (!acc[val]) {
                acc[val] = {}
            }
        })

        return acc
    }, {})
    return { ...blockLocales, ...REMIX_DEFAULT_TEXTS }
}
