import React from 'react'
import classNames from 'classnames'

import { DEFAULT_ITEMS } from './constants'

import styles from './RadioPair.module.scss'

const RadioPair = ({ items = DEFAULT_ITEMS, activeItem, onSelect, label }) => (
    <div className={styles.radioPair}>
        {!!label && <div className={styles.label}>{label}</div>}
        <ul className={styles.list}>
            {items.map((item, index) => {
                const Icon = item.icon
                const isActive = activeItem === item.value
                return (
                    <li
                        key={index}
                        className={classNames(styles.item, { [styles.isActiveItem]: isActive })}
                        onClick={() => onSelect(item.value)}
                    >
                        <Icon color={isActive ? '#2990fb' : '#3C3C3C'} />
                        <p className={styles.itemLabel}>{item.label}</p>
                    </li>
                )
            })}
        </ul>
    </div>
)

export default RadioPair
