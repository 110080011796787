import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import Hint from './components/Hint/Hint'

import styles from './Tile.module.scss'

const Tile = ({
    data,
    borderWidth,
    fontSize,
    hintFontSize,
    colorTheme,
    letterColor,
    isHasLeftNeighbor,
    isHasRightNeighbor,
    isHasUpNeighbor,
    isHasDownNeighbor,
    fontFamily,
}) => {
    const inputRef = useRef(null)

    const [borderRadius, setBorderRadius] = useState({ topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 })

    useEffect(() => {
        const radius = borderWidth * 2
        let topLeft = 0,
            topRight = 0,
            bottomRight = 0,
            bottomLeft = 0

        if (!isHasUpNeighbor && !isHasLeftNeighbor) topLeft = radius
        if (!isHasUpNeighbor && !isHasRightNeighbor) topRight = radius
        if (!isHasDownNeighbor && !isHasRightNeighbor) bottomRight = radius
        if (!isHasDownNeighbor && !isHasLeftNeighbor) bottomLeft = radius

        setBorderRadius({
            topLeft,
            topRight,
            bottomRight,
            bottomLeft,
        })
    }, [isHasLeftNeighbor, isHasRightNeighbor, isHasUpNeighbor, isHasDownNeighbor, borderWidth])

    useEffect(() => {
        if (!inputRef.current) return
        if (data?.isFocused) forceFocus()
        else forceBlur()
    }, [inputRef.current, data?.isFocused])

    const forceFocus = () => {
        if (!inputRef.current) return
        if (document.activeElement === inputRef.current) return

        inputRef.current.focus()
        inputRef.current.select()
    }
    const forceBlur = () => {
        if (!inputRef.current) return
        inputRef.current.blur()
    }

    return (
        <li
            className={classNames(styles.tile, {
                [styles.inputtableTile]: !!data,
            })}
            style={{
                '--colorTheme': colorTheme,
                '--borderWidth': `${borderWidth}px`,
                '--borderOffset': `${0 - borderWidth / 2}px`,
                '--borderRadius': `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
            }}
        >
            <div className={styles.tileInner}>
                {!!data && (
                    <div className={styles.tileContent}>
                        {!!data.hint && <Hint fontSize={hintFontSize} text={data.hint} />}
                        <input
                            ref={inputRef}
                            className={styles.tileInput}
                            style={{
                                '--fontColor': letterColor,
                                '--positionOffset': `${borderWidth - 1}px`,
                                fontSize,
                                fontFamily,
                            }}
                            type="text"
                            value={data.correctValue}
                        />
                    </div>
                )}
            </div>
        </li>
    )
}

export default Tile
