export const MAX_WHEEL_DIAMETER = 720

export const SECTION_TYPE = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
}

export const COLOR_SCHEME = {
    MONOCHROMATIC: 'MONOCHROMATIC',
    ANALOGOUS: 'ANALOGOUS',
    TRIADIC: 'TRIADIC',
    SPLIT_COMPLEMENTARY: 'SPLIT_COMPLEMENTARY',
}
