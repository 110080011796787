import i18n from 'i18n'

import wheelIcon from 'components/Modal/components/Body/components/SideNav/i/wheel.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

import { DEFAULT_VALUES } from 'components/Form/RadioPair/constants'

export const TABS = {
    PLAYGROUND: 1,
    SECTIONS: 2,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Playground'),
        icon: wheelIcon,
    },
    {
        id: 2,
        label: i18n.t('Sectors'),
        icon: resultsIcon,
    },
]

export const DEFAULT_ANIMATION_DURATION = 10
export const MIN_ANIMATION_DURATION = 2
export const MAX_ANIMATION_DURATION = 60

export const SECTIONS_COUNT_ARRAY = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const DEFAULT_SECTIONS_COUNT = 6
export const MIN_SECTIONS_COUNT = SECTIONS_COUNT_ARRAY[0]
export const MAX_SECTIONS_COUNT = SECTIONS_COUNT_ARRAY[SECTIONS_COUNT_ARRAY.length - 1]

export const SECTION_TYPES = DEFAULT_VALUES
export const DEFAULT_SECTION_TYPE = DEFAULT_VALUES.TEXT

export const SECTION_WIN_PROBABILITY = {
    NORMAL: 'NORMAL',
    VERY_HIGH: 'VERY_HIGH',
    HIGH: 'HIGH',
    LOW: 'LOW',
    VERY_LOW: 'VERY_LOW',
}

export const SECTION_WIN_PROBABILITY_FACTORS = {
    [SECTION_WIN_PROBABILITY.NORMAL]: 1,
    [SECTION_WIN_PROBABILITY.VERY_HIGH]: 3,
    [SECTION_WIN_PROBABILITY.HIGH]: 1.5,
    [SECTION_WIN_PROBABILITY.LOW]: 0.5,
    [SECTION_WIN_PROBABILITY.VERY_LOW]: 0.1,
}
