import React, { useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO } from 'store/actions/asset_manager'

import { mapListOnStatus, defineMeasure } from '../utils/utils'
import { UPLOAD_STATUS } from '../utils/constants'

import loader from '../i/loader.png'
import error from '../i/error.png'

import './UploadBar.scss'

const UploadBar = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const uploadMedia = Object.entries(useSelector(state => state.assetManager.uploadMedia))
    const [isShowBar, setIsShowBar] = useState(false)
    const [list, setList] = useState([])
    const [mappedList, setMappedList] = useState([])

    useEffect(() => {
        if (!isEqual(uploadMedia, list)) {
            setList(uploadMedia)
            setMappedList(mapListOnStatus(uploadMedia))
            if (uploadMedia.length) {
                setIsShowBar(true)
            } else if (uploadMedia.length === 0) {
                setIsShowBar(false)
            }
        }
    }, [uploadMedia])

    const barTitle = getBarTitle(list.length, t, mappedList[UPLOAD_STATUS.error])

    const uploadIcon = useMemo(() => {
        if (mappedList[UPLOAD_STATUS.prepare]) {
            return <img className="upload-bar__loading-prepare" src={loader} alt="" />
        }
        if (mappedList[UPLOAD_STATUS.error]) {
            return <img className="upload-bar__loading-error" src={error} alt="" />
        }
    }, [mappedList])

    const onCloseBar = () => {
        setIsShowBar(false)
        list.forEach(([id]) => {
            dispatch(ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO(id))
        })
    }

    return (
        isShowBar && (
            <div className="upload-bar">
                <div className="upload-bar__loading-box">
                    <div className="upload-bar__loading">{uploadIcon}</div>
                </div>
                <div className="upload-bar__list">
                    <h1 className={`upload-bar__list-title ${mappedList[UPLOAD_STATUS.error] ? 'error' : ''}`}>
                        {barTitle}
                    </h1>
                    {list.map(([id, info]) => (
                        <div className="upload-bar__item" key={id} title={t(info.message)}>
                            <span className={info.status} title={info.name}>
                                {info.name}
                            </span>
                            <span className="stats-bar__measure">{defineMeasure(info.total)}</span>
                        </div>
                    ))}
                </div>
                <div className="upload-bar__close-box">
                    {!mappedList[UPLOAD_STATUS.prepare] ? (
                        <div className="upload-bar__close-icon" onClick={onCloseBar}>
                            <IconClose />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    )
}

const getBarTitle = (totalLength, t, errors = []) => {
    if (errors.length === 0) {
        return totalLength <= 1
            ? `${t('Uploading {{length}} item', { length: totalLength })}`
            : `${t('Uploading {{length}} items', { length: totalLength })}`
    } else if (errors.length === 1) {
        return errors[0].message
    } else {
        return t('These files cannot be uploaded')
    }
}

const IconClose = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/close copy">
            <path
                id="Line 3"
                d="M2.14309 2.14306L9.85698 9.85695"
                stroke="#979797"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Line 3_2"
                d="M9.85691 2.14306L2.14302 9.85695"
                stroke="#979797"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)

export default UploadBar
