import React from 'react'
import { useTranslation } from 'react-i18next'

import Select from 'components/Form/Select/Select'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import CardProportions from 'components/Form/CardProportions/CardProportions'
import RadioPair from 'components/Form/RadioPair/RadioPair'

import Preview from '../../../../../RankBattle/components/Preview/Preview'

import TabContent from '../../../components/TabContent/TabContent'
import GamePreview from '../../../components/GamePreview/GamePreview'

import { AVAILABLE_CARDS_COUNT } from '../../constants'

import { Playground as PlaygroundModel } from '../../rankBattleService'

import styles from './Playground.module.scss'

const Playground = ({ structure, allStructure, fontFamily, colorTheme, onUpdate, header, errors }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.playground}>
            <TabContent header={header} className={styles.tabContent}>
                <div className={styles.workplaceContent}>
                    <div className={styles.workplaceLayoutRow}>
                        <Select
                            label={t('Number of cards')}
                            errorMessages={errors?.[PlaygroundModel.cardsCount]}
                            value={{
                                value: structure[PlaygroundModel.cardsCount],
                                label: structure[PlaygroundModel.cardsCount],
                            }}
                            onUpdate={option => onUpdate({ [PlaygroundModel.cardsCount]: option.value })}
                            options={AVAILABLE_CARDS_COUNT.map(item => ({
                                value: item,
                                label: item,
                            }))}
                        />
                        <RadioPair
                            label={t('Card type')}
                            activeItem={structure[PlaygroundModel.cardType]}
                            onSelect={v => onUpdate({ [PlaygroundModel.cardType]: v })}
                        />
                    </div>
                    <CardProportions
                        label={t('Card proportions')}
                        value={structure[PlaygroundModel.cardProportions]}
                        onUpdate={value => onUpdate({ [PlaygroundModel.cardProportions]: value })}
                    />
                </div>
            </TabContent>
            <GamePreview className={styles.preview} width={400}>
                <Preview struct={allStructure} colorTheme={colorTheme} fontFamily={fontFamily} />
            </GamePreview>
        </div>
    )
}

export default Playground
