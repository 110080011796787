export const DEFAULT_MULTIPLAYER_BG = 'https://p.interacty.me/multiplayer/realtime-games-background-image.png'
export const DEFAULT_IMAGE_URL = 'https://p.interacty.me/default-image-bg.svg'
export const DEFAULT_IMAGE_BG_WIDE_URL = 'https://p.interacty.me/default-image-bg-wide.svg'
export const DEFAULT_IMAGE_BG_BIG_URL = 'https://p.interacty.me/default-image-bg-big.svg'
export const DEFAULT_IMAGE_BG_BIG_BRIGHT_URL = 'https://p.interacty.me/default-image-bg-big-bright.svg'
export const DEFAULT_WHEEL_SPIN_IMAGE_URL = 'https://p.interacty.me/default-wheel-pin-image.svg'

export const API_URL = process.env.REACT_APP_BACKEND_URL

export const CDN_URL = API_URL.replace('api.', 'p.')

export const AUDIO_PLAYER_CONTROLS = {
    CURRENT_TIME: 'CURRENT_TIME',
    CURRENT_LEFT_TIME: 'CURRENT_LEFT_TIME',
    PROGRESS_BAR: 'PROGRESS_BAR',
    DURATION: 'DURATION',
    LOOP: 'LOOP',
    VOLUME_BAR: 'VOLUME_BAR',
    MUTE: 'MUTE',
    PLAY: 'PLAY',
    PREVIOUS: 'PREVIOUS',
    NEXT: 'NEXT',
    REWIND: 'REWIND',
    FAST_FORWARD: 'FAST_FORWARD',
    MENU: 'MENU',
    SPEED: 'SPEED',
}
