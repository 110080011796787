import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'

import AddListItemButton from '../../../Controls/AddListItemButton/AddListItemButton'
import ListActionBar from '../../../Controls/ListActionBar/ListActionBar'

import Question from './Question'
import { MAX_QUESTIONS, MIN_QUESTIONS } from '../constants'

const QuestionList = ({
    tmpStructure,
    validationStructure,
    errors,
    onAddQuestion,
    onCloneQuestion,
    onRemoveQuestion,
    onSetFocusToQuestion,
    onSetAnswerCorrect,
    onChangePositionQuestion,
    onUpdateQuestion,
    onUpdateQuestionAnswer,
    onAddAnswer,
    onRemoveAnswer,
}) => {
    const { t } = useTranslation()

    const handleOnScrollHighlightVisibleItem = async (inView, entry) => {
        if (inView) await onSetFocusToQuestion(entry.target)
    }

    const isMaxLimitExcited = tmpStructure.questions.length === MAX_QUESTIONS

    return (
        <>
            {!!tmpStructure.questions.length && (
                <ul className={classNames('list', { 'is-full': isMaxLimitExcited })}>
                    {tmpStructure.questions.map((question, qIndex) => (
                        <InView
                            as="li"
                            key={question.id}
                            onChange={handleOnScrollHighlightVisibleItem}
                            id={`item_${question.id}`}
                            threshold={0.1}
                        >
                            <h3 className="scroll-item tab-title" data-scroll={qIndex}>
                                <span>
                                    {t('Question')} {qIndex + 1}
                                </span>
                                <div className="control">
                                    <ListActionBar
                                        index={qIndex}
                                        length={tmpStructure.questions.length}
                                        minLength={MIN_QUESTIONS}
                                        maxLength={MAX_QUESTIONS}
                                        onChangePosition={onChangePositionQuestion}
                                        onClone={() => onCloneQuestion(question.id)}
                                        onRemove={() => onRemoveQuestion(question.id)}
                                    />
                                </div>
                            </h3>
                            <Question
                                question={question}
                                validationStructure={validationStructure}
                                errors={errors}
                                onSetAnswerCorrect={onSetAnswerCorrect}
                                onChangePosition={onChangePositionQuestion}
                                onUpdateQuestion={onUpdateQuestion}
                                onUpdateQuestionAnswer={onUpdateQuestionAnswer}
                                onAddAnswer={onAddAnswer}
                                onRemoveAnswer={onRemoveAnswer}
                                qIndex={qIndex}
                            />
                        </InView>
                    ))}
                </ul>
            )}
            {!isMaxLimitExcited && (
                <AddListItemButton
                    title={t('Add question')}
                    length={tmpStructure.questions.length}
                    onClick={onAddQuestion}
                />
            )}
        </>
    )
}

export default QuestionList
