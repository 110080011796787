import React from 'react'
import { useTranslation } from 'react-i18next'
import Switch from '../Form/Switch/Switch'
import icon from './i/mailchimp-small.png'
import StatusIndicator from '../StatusIndicator/StatusIndicator'
import styles from './MailChimpIntegrations.module.scss'

const MailChimpIntegrations = ({ isEnabled, openManager, onSwitchConnector }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.MailchimpIntegrations}>
            <div className={styles.group}>
                <img src={icon} alt="mailchimp" />
                <p className={styles.name}>Mailchimp</p>
            </div>
            <div className={styles.group}>
                <Switch checked={isEnabled} onChange={onSwitchConnector} />
                <StatusIndicator hideLed={true} isConnected={isEnabled} />
                <button onClick={openManager}>{t('Set up')}</button>
            </div>
        </div>
    )
}

export default MailChimpIntegrations
