import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import LoaderIcon from 'components/LoaderIcon/LoaderIcon'

import Search from '../../components/Search'
import PlaceBar from '../../components/PlaceBar'
import MediaGrid from '../../components/MediaGrid/MediaGrid.js'

import { mapAudioList } from '../../utils/utils'

import { GET_PUBLIC_AUDIO_FOLDERS, GET_PUBLIC_AUDIO } from 'api/media'

import folderIcon from './i/folder.svg'
import arrowIcon from './i/arrow.svg'

import styles from './FreeAudioFiles.module.scss'

const FreeAudioFiles = ({ onPlaceItem }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [folderList, setFolderList] = useState(null)
    const [search, setSearch] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [audioList, setAudioList] = useState(null)
    const [selectedAudioId, setSelectedAudioId] = useState(null)

    const filteredFolderList = useMemo(
        () =>
            !folderList
                ? []
                : folderList.content.filter(({ name }) => name.toLowerCase().includes(search.trim().toLowerCase())),
        [folderList, search],
    )
    const filteredAudioList = useMemo(
        () =>
            !audioList
                ? []
                : audioList.content.filter(({ name }) => name.toLowerCase().includes(search.trim().toLowerCase())),
        [audioList, search],
    )

    const placeItem = useCallback(() => {
        const [{ normalUrl = '', authorInfo, id, name } = {}] = mapAudioList(audioList).content.filter(
            item => item.id === selectedAudioId,
        )

        setSelectedAudioId(null)
        onPlaceItem({ src: normalUrl, id, author: authorInfo, name }, false)
    }, [onPlaceItem, selectedAudioId])

    useEffect(() => {
        setIsLoading(true)
        GET_PUBLIC_AUDIO_FOLDERS()
            .then(data => setFolderList(data))
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        setSearch('')
        setAudioList(null)
        setSelectedAudioId(null)
        if (!selectedFolder) return
        setIsLoading(true)
        GET_PUBLIC_AUDIO(selectedFolder.id)
            .then(data => setAudioList(data))
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [selectedFolder])

    return (
        <div className={styles.audioLibrary}>
            <div className={styles.header}>
                <div
                    className={classNames(styles.title, { [styles.clickableTitle]: !!selectedFolder })}
                    onClick={() => {
                        if (!selectedFolder) return
                        setSelectedFolder(null)
                    }}
                >
                    {t('Free Interacty library')}
                </div>
                {selectedFolder && (
                    <>
                        <img className={styles.titleArrow} src={arrowIcon} alt="back" />
                        <span className={styles.titleFolderName}>{selectedFolder.name}</span>
                    </>
                )}
            </div>
            <Search key={selectedFolder} onSearchValueChange={value => setSearch(value)} />

            {selectedFolder ? (
                <div className={styles.audioWrapper}>
                    <MediaGrid
                        isAudio
                        data={filteredAudioList.length ? mapAudioList({ content: filteredAudioList }).content : []}
                        selectedId={selectedAudioId}
                        isLoadData={isLoading}
                        onSelectImage={id => setSelectedAudioId(id)}
                        onUnselectImage={() => setSelectedAudioId(null)}
                    />
                    {selectedAudioId && <PlaceBar onClick={placeItem} isAudio />}
                </div>
            ) : (
                <>
                    <ul className={styles.foldersList}>
                        {filteredFolderList.map(folder => (
                            <li key={folder.id} className={styles.folder} onClick={() => setSelectedFolder(folder)}>
                                <div className={styles.folderImage}>
                                    <img src={folderIcon} alt="folder-icon" />
                                </div>
                                <p className={styles.folderName}>{folder.name}</p>
                            </li>
                        ))}
                    </ul>
                    {isLoading && <LoaderIcon />}
                </>
            )}
        </div>
    )
}

export default FreeAudioFiles
