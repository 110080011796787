import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'
import Loader from 'components/Loader/Loader'
import Toast from 'components/Toast/Toast'

import { API__WEBHOOKS } from 'api'

import WebhooksMappingView from '../WebhooksMappingView/WebhooksMappingView'

import './WebhooksManagerModal.scss'

const WebhooksManagerModal = ({ organizationId, projectId, blockId, onClose }) => {
    const { t } = useTranslation()
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)

    const getWebhooks = () => {
        setIsLoading(true)
        API__WEBHOOKS.GET_WEBHOOKS_BY_BLOCK({ organizationId, blockId, projectId })
            .then(_webhooks => {
                setList(_webhooks)
            })
            .finally(() => setIsLoading(false))
    }
    useEffect(() => {
        getWebhooks()
    }, [organizationId, blockId, projectId])

    const onSendTestData = webhookId => {
        setIsProcessing(true)
        API__WEBHOOKS.SEND_TEST_DATA(organizationId, webhookId, projectId)
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                console.error(error)
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    const onRemove = webhookId => {
        setIsProcessing(true)
        API__WEBHOOKS.DELETE_WEBHOOK(organizationId, webhookId)
            .then(() => {
                const newList = list.filter(item => item.id !== webhookId)
                setList(newList)
            })
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                console.error(error)
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    const onSwitchWebhook = webhookId => {
        setIsProcessing(true)
        API__WEBHOOKS.SWITCH_WEBHOOK_STATUS(webhookId)
            .then(webhook => {
                const newList = list.map(item => (item.id !== webhookId ? item : webhook))
                setList(newList)
            })
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                console.error(error)
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    const onAddWebhook = url => {
        setIsProcessing(true)
        API__WEBHOOKS.CREATE_WEBHOOK({ projectId, blockId, url })
            .then(webhook => {
                setList([...list, webhook])
            })
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                console.error(error)
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    const onUpdateWebhookUrl = (url, webhookId) => {
        setIsProcessing(true)
        API__WEBHOOKS.UPDATE_WEBHOOK_URL(url, webhookId)
            .then(webhook => {
                const newList = list.map(item => (item.id !== webhookId ? item : webhook))
                setList(newList)
            })
            .catch(error => {
                Toast('error', {
                    message: t(error.response.data.message),
                })
                console.error(error)
            })
            .finally(() => {
                setIsProcessing(false)
            })
    }

    return (
        <Modal className="webhooks-modal" closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Webhooks integration')} isCloseIcon={false} />
            <div className="webhooks-modal__wrapper">
                {isLoading && <Loader forModal />}
                {!isLoading && (
                    <ScrollableBlock>
                        <WebhooksMappingView
                            list={list}
                            onSendTestData={onSendTestData}
                            onRemove={onRemove}
                            onSwitchWebhook={onSwitchWebhook}
                            onAddWebhook={onAddWebhook}
                            onUpdateWebhookUrl={onUpdateWebhookUrl}
                        />
                    </ScrollableBlock>
                )}
            </div>
            <Footer
                noteText={
                    <Trans
                        i18nKey="For more information click <lnk>here</lnk>"
                        components={{
                            lnk: (
                                <a
                                    target="_blank"
                                    href={
                                        'https://help.interacty.me/content/how-to-set-up-a-webhook-for-your-lead-form'
                                    }
                                />
                            ),
                        }}
                    />
                }
                actionText={t('Close')}
                isProcessing={isProcessing}
                onAction={onClose}
            />
        </Modal>
    )
}

export default WebhooksManagerModal
