import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PadlockInCircle } from 'svg'
import { imgLoader } from 'common/services/imageLoader'
import invertColor from '../../../../../util/invertColor'

export const TreasureHuntTile = ({ tile, colorTheme, isShowQuestCover, size, coverPosition, currentProportions }) => {
    const { t } = useTranslation()
    const { quest, color, overlaySrc } = tile

    const getBackground = () => {
        if (color) {
            return { backgroundColor: color }
        } else {
            return { backgroundImage: `url(${imgLoader(overlaySrc)})` }
        }
    }

    return (
        <div
            className={classnames('treasure-hunt-preview__tile', size, coverPosition)}
            data-proportion={currentProportions}
            style={getBackground()}
        >
            <PadlockInCircle color={colorTheme} />
            <div className="treasure-hunt-preview__tile-text" style={{ color: invertColor(colorTheme, true) }}>
                {isShowQuestCover ? quest : ''}
            </div>
            <button className="treasure-hunt-preview__tile-btn" style={{ color: colorTheme }}>
                {t('Open')}
            </button>
        </div>
    )
}
