import React, { useEffect, useRef, useState } from 'react'

const createPopup = ({ url, title, height, width }) => {
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    return window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`)
}

function useOAuthPopup(title, url, redirectUrl, onCode, onClose) {
    const [externalWindow, setExternalWindow] = useState()
    const intervalRef = useRef()

    useEffect(() => {
        if (externalWindow) {
            intervalRef.current = window.setInterval(() => {
                try {
                    const currentUrl = externalWindow.location.href
                    const params = new URL(currentUrl).searchParams
                    const code = params.get('code')
                    if (!code) {
                        return
                    }
                    onCode(code, params)
                    clearTimer()
                    externalWindow.close()
                } catch (error) {
                } finally {
                    if (!externalWindow || externalWindow.closed) {
                        if (onClose) onClose()
                        clearTimer()
                    }
                }
            }, 700)
        }
        return () => {
            if (externalWindow) externalWindow.close()
            if (onClose) onClose()
        }
    }, [externalWindow])

    const clearTimer = () => window.clearInterval(intervalRef.current)

    const onOpenPopup = () => {
        const fullUrl = url + `&redirect_url=${redirectUrl}`
        const window = createPopup({
            url: fullUrl,
            title,
            width: 600,
            height: 830,
        })
        setExternalWindow(window)
    }

    return { startAuth: () => onOpenPopup() }
}

export default useOAuthPopup
