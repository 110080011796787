import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PREVIEW_FOLLOWERS_COUNT } from '../../constants'

import NoFollowing from './components/NoFollowing/NoFollowing'
import FollowerItem from './components/FollowerItem/FollowerItem'
import FollowingModal from './components/FollowingModal/FollowingModal'

import styles from './Following.module.scss'

const Following = ({ content, total, onFollow, onUnfollow }) => {
    const { t } = useTranslation()

    const [isOpenedModal, setIsOpenedModal] = useState(false)
    const [unfollowIds, setUnfollowIds] = useState([])

    const follow = async authorId => {
        await onFollow(authorId)
        setUnfollowIds(unfollowIds.filter(id => id !== authorId))
    }
    const unfollow = async authorId => {
        await onUnfollow(authorId)
        setUnfollowIds([...unfollowIds, authorId])
    }

    return (
        <div className={styles.following}>
            {!content.length && <NoFollowing />}
            {!!content.length && (
                <>
                    <ul className={styles.list}>
                        {content.map(item => (
                            <FollowerItem
                                key={item.publicProfileId}
                                data={item}
                                onFollow={follow}
                                onUnfollow={unfollow}
                                unfollowIds={unfollowIds}
                            />
                        ))}
                    </ul>
                    {total > PREVIEW_FOLLOWERS_COUNT && (
                        <div className={styles.seeMoreContainer}>
                            <div className={styles.seeMoreButton} onClick={() => setIsOpenedModal(true)}>
                                {t('See all')} ({total})
                            </div>
                        </div>
                    )}
                </>
            )}
            {isOpenedModal && (
                <FollowingModal onClose={() => setIsOpenedModal(false)} onFollow={follow} onUnfollow={unfollow} />
            )}
        </div>
    )
}

export default Following
