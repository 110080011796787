import React from 'react'

import styles from './Item.module.scss'

const Item = ({ href, provider, iconSrc, label }) => (
    <li className={styles.item}>
        <a className={styles.link} href={href} title={provider}>
            <img className={styles.icon} src={iconSrc} alt="" />
            <p className={styles.label}>{label}</p>
        </a>
    </li>
)

export default Item
