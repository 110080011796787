import React, { useState, useEffect, useMemo } from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextArea from 'components/Form/TextArea/TextArea'
import { getUniqueId } from 'common/utils/getUniqueId'
import useAudioPlayer from 'hooks/useAudioPlayer'

import EditorCardTextModal from '../../../../EditorCardTextModal/EditorCardTextModal'
import PairCard from '../../../../PairCard/PairCard'
import PairsFeedbackToggle from '../../components/PairsFeedbackToggle/PairsFeedbackToggle'
import { CARD_TYPES, PAIRS_FIELDS } from '../../utils/constants'
import { MemoryPair } from '../../utils/models.js'

import './Pairs.scss'

const Pairs = ({ structure, colorTheme, t, methods: { updateStructure } }) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [selectedCardInfo, setSelectedCardInfo] = useState(null)
    const [dataSet, setDataSet] = useState([])
    const [pairsCount, setPairsCount] = useState(0)
    const { onPlayAudio, elementId, isAudioPlay } = useAudioPlayer()

    useEffect(() => {
        setDataSet(structure.pairs.pairList)
    }, [structure.pairs.pairList])

    useEffect(() => {
        if (structure.playground.cardLayout && Object.keys(structure.playground.cardLayout).length) {
            const [cells, rows] = structure.playground.cardLayout.value.split('x').map(x => Number(x))
            setPairsCount((rows * cells) / 2)
        }
    }, [structure.playground.cardLayout])

    useEffect(() => {
        if (dataSet.length) {
            updateStructure({ [PAIRS_FIELDS.pairList]: dataSet })
        }
    }, [dataSet])

    const onCloseModal = () => {
        setSelectedCardInfo(null)
        setIsShowModal(false)
    }

    const onOpenModal = (pair, typeCard) => {
        setSelectedCardInfo({
            pairId: pair.id,
            typeCard: typeCard,
            text: pair[typeCard].text,
            bgColor: pair[typeCard].bgColor,
        })
        setIsShowModal(true)
    }

    const onSaveCardText = fields => {
        updateCard(selectedCardInfo.pairId, selectedCardInfo.typeCard, fields)
        onCloseModal()
    }

    const updateCard = (pairId, typeCard, fields) => {
        setDataSet(
            dataSet.map(item =>
                item.id === pairId
                    ? {
                          ...item,
                          [typeCard]: {
                              ...item[typeCard],
                              ...fields,
                          },
                      }
                    : item,
            ),
        )
    }

    const updatePair = (pairId, field, value) => {
        setDataSet(dataSet.map(item => (item.id === pairId ? { ...item, [field]: value } : item)))
    }

    const itemsView = useMemo(() => {
        const pairTitle = t('Pair')
        const descriptionTitle = t('Description')
        if (dataSet && dataSet.map) {
            return dataSet.map((item, i) => {
                const isActivePair = i < pairsCount
                return isActivePair ? (
                    <div
                        className={`memory-modal-pairs__card-block ${
                            structure.pairs.isShowFeedback ? 'with-description' : ''
                        }`}
                        key={item.id}
                    >
                        <div className="memory-modal-pairs__card-pair">
                            <div className="memory-modal-pairs__card-pair-title">{`${pairTitle} ${i + 1}`}</div>
                            <div className="memory-modal-pairs__card-pair-view">
                                <PairCard
                                    colorTheme={colorTheme}
                                    card={item[MemoryPair.firstImage]}
                                    onTextIconClick={() => onOpenModal(item, MemoryPair.firstImage)}
                                    onImageSelect={data =>
                                        updateCard(item.id, MemoryPair.firstImage, {
                                            cardType: CARD_TYPES.image,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onAudioSelect={data =>
                                        updateCard(item.id, MemoryPair.firstImage, {
                                            cardType: CARD_TYPES.audio,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onPlayAudio={onPlayAudio}
                                    isAudioPlay={isAudioPlay}
                                    playedElementId={elementId}
                                />
                                <PairCard
                                    colorTheme={colorTheme}
                                    card={item[MemoryPair.secondImage]}
                                    onTextIconClick={() => onOpenModal(item, MemoryPair.secondImage)}
                                    onImageSelect={data =>
                                        updateCard(item.id, MemoryPair.secondImage, {
                                            cardType: CARD_TYPES.image,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onAudioSelect={data =>
                                        updateCard(item.id, MemoryPair.secondImage, {
                                            cardType: CARD_TYPES.audio,
                                            ...data,
                                            id: getUniqueId(),
                                        })
                                    }
                                    onPlayAudio={onPlayAudio}
                                    isAudioPlay={isAudioPlay}
                                    playedElementId={elementId}
                                />
                            </div>
                        </div>
                        {structure.pairs.isShowFeedback && (
                            <div className="memory-modal-pairs__card-description">
                                <TextArea
                                    label={descriptionTitle}
                                    rows={3}
                                    value={item.description}
                                    onUpdate={value => updatePair(item.id, MemoryPair.description, value)}
                                />
                            </div>
                        )}
                    </div>
                ) : null
            })
        }
    }, [dataSet, pairsCount, structure.pairs.isShowFeedback, elementId, onPlayAudio, isAudioPlay, colorTheme])

    return (
        <div className="memory-modal-pairs">
            <div className="memory-modal-pairs__head-wrapper">
                <h3 className="memory-modal-pairs__head-title">{t('Pairs')}</h3>
                <div className="memory-modal-pairs__head-switcher">
                    <PairsFeedbackToggle
                        label={t('Show feedback for found pairs')}
                        isShowFeedback={structure.pairs.isShowFeedback}
                        onUpdate={value => updateStructure({ [PAIRS_FIELDS.isShowFeedback]: value })}
                    />
                </div>
            </div>
            <div className="memory-modal-pairs__body-wrapper">
                <ScrollableBlock maxHeight="300px">
                    <div className="memory-modal-pairs__card-block-wrapper">{itemsView}</div>
                </ScrollableBlock>
            </div>
            {isShowModal && (
                <EditorCardTextModal
                    text={selectedCardInfo.text}
                    bgColor={selectedCardInfo.bgColor}
                    onClose={onCloseModal}
                    onSubmit={onSaveCardText}
                />
            )}
        </div>
    )
}

export default Pairs
