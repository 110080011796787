import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isEducation, isSubscriptionActivated, isTrialSubscription, isAppSumoUser } from 'common/utils/permissions'
import AnalyticsMonitor from 'common/services/AnalyticsMonitor'
import { EDUCATION_GROUP_ID } from 'common/constants/ProductConstants'

import Modal from 'components/Modal/Modal'

import { API__PRODUCTS, API__USER } from 'api'

import { ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION, USER__SET_DETAILS, USER__SET_SUBSCRIPTIONS } from 'store/actions'

import ChooseRole from './views/ChooseRole/ChooseRole'
import ChooseInterests from './views/ChooseInterests/ChooseInterests'

import { VIEWS } from './constants'

import styles from './NewUserModal.module.scss'

const NewUserModal = ({ onClose }) => {
    const dispatch = useDispatch()
    const { user_details, organizations } = useSelector(state => state)
    const [interestsTags, setInterestsTags] = useState([])

    const [selectedRoleId, setSelectedRoleId] = useState(null)

    const [view, setView] = useState(VIEWS.SELECT_USER_ROLE)

    useEffect(() => {
        API__USER.GET_INTERESTS_TAGS()
            .then(data => {
                setInterestsTags(data)
            })
            .catch(err => console.error(err))
    }, [])

    const onHandleRoleChoose = async ({ group, role }) => {
        if (group.customId === EDUCATION_GROUP_ID) {
            setSelectedRoleId(role.id)
            setView(VIEWS.SELECT_INTERESTS)
            return
        }

        await setUserRole(role.id)

        onClose(!isAppSumoUser())
    }

    const onHandleInterestsChoose = async interests => {
        await setUserRole()
        await setUserInterests(interests)
    }

    const setUserRole = async roleId => {
        const updatedUser = await API__USER.SET_USER_ROLE(user_details.id, roleId || selectedRoleId)
        dispatch(USER__SET_DETAILS(updatedUser))

        if (!isSubscriptionActivated() && isTrialSubscription()) {
            const { selectedOrganizationId } = organizations
            if (isEducation()) await API__PRODUCTS.ACTIVATE_EDUCATION_FREE(selectedOrganizationId)
            else await API__PRODUCTS.ACTIVATE_TRIAL(selectedOrganizationId)

            AnalyticsMonitor.track('User role chosen', { role: roleId || selectedRoleId })
            await dispatch(ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION())
            await dispatch(USER__SET_SUBSCRIPTIONS(await API__USER.GET_SUBSCRIPTIONS(user_details.id)))
        }
    }

    const setUserInterests = async interests => {
        const updatedUser = await API__USER.SET_USER_INTERESTS(user_details.id, interests)
        dispatch(USER__SET_DETAILS(updatedUser))
    }

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false}>
            <div className={styles.newUserModal}>
                {view === VIEWS.SELECT_USER_ROLE && <ChooseRole onSelect={onHandleRoleChoose} />}
                {view === VIEWS.SELECT_INTERESTS && (
                    <ChooseInterests tags={interestsTags} onClose={onClose} onSelect={onHandleInterestsChoose} />
                )}
            </div>
        </Modal>
    )
}

export default NewUserModal
