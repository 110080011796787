export const HomeIcon = ({ isActive = false }) => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99935 2.74167L13.166 6.49167V13H11.4993V8H6.49935V13H4.83268V6.49167L8.99935 2.74167V2.74167ZM8.99935 0.5L0.666016 8H3.16602V14.6667H8.16602V9.66667H9.83268V14.6667H14.8327V8H17.3327L8.99935 0.5Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)

export const PublicIcon = ({ isActive = false }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99935 0.666656C4.39935 0.666656 0.666016 4.39999 0.666016 8.99999C0.666016 13.6 4.39935 17.3333 8.99935 17.3333C13.5993 17.3333 17.3327 13.6 17.3327 8.99999C17.3327 4.39999 13.5993 0.666656 8.99935 0.666656ZM2.33268 8.99999C2.33268 8.49166 2.39935 7.99166 2.50768 7.51666L6.49102 11.5V12.3333C6.49102 13.25 7.24102 14 8.15768 14V15.6083C4.88268 15.1917 2.33268 12.3917 2.33268 8.99999ZM13.9077 13.5C13.691 12.825 13.0743 12.3333 12.3243 12.3333H11.491V9.83332C11.491 9.37499 11.116 8.99999 10.6577 8.99999H5.65768V7.33332H7.32435C7.78268 7.33332 8.15768 6.95832 8.15768 6.49999V4.83332H9.82435C10.741 4.83332 11.491 4.08332 11.491 3.16666V2.82499C13.9327 3.80832 15.666 6.20832 15.666 8.99999C15.666 10.7333 14.991 12.3167 13.9077 13.5Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)

export const ChartIcon = ({ isActive = false }) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.834 3.66669H9.33398V11.8334H11.834V3.66669ZM7.16732 0.166687H4.83398V11.8334H7.16732V0.166687V0.166687ZM2.50065 6.83335H0.167318V11.8334H2.50065V6.83335Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)

export const PurchasesIcon = ({ isActive = false }) => (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.50065 7.49998H0.833984V16.6666C0.833984 17.5916 1.57565 18.3333 2.50065 18.3333H14.1673C15.0923 18.3333 15.834 17.5916 15.834 16.6666H2.50065V7.49998ZM15.0006 4.16665V2.49998C15.0006 1.57498 14.259 0.833313 13.334 0.833313H10.0007C9.07565 0.833313 8.33398 1.57498 8.33398 2.49998V4.16665H4.16732V13.3333C4.16732 14.2583 4.90898 15 5.83398 15H17.5006C18.4256 15 19.1673 14.2583 19.1673 13.3333V4.16665H15.0006ZM10.0007 2.49998H13.334V4.16665H10.0007V2.49998ZM17.5006 13.3333H5.83398V5.83331H17.5006V13.3333ZM10.0007 12.5L14.584 9.16665L10.0007 6.66665V12.5Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)

export const AccountIcon = ({ isActive = false }) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.00065 1.91665C7.96732 1.91665 8.75065 2.69998 8.75065 3.66665C8.75065 4.63331 7.96732 5.41665 7.00065 5.41665C6.03398 5.41665 5.25065 4.63331 5.25065 3.66665C5.25065 2.69998 6.03398 1.91665 7.00065 1.91665ZM7.00065 9.41665C9.47565 9.41665 12.084 10.6333 12.084 11.1666V12.0833H1.91732V11.1666C1.91732 10.6333 4.52565 9.41665 7.00065 9.41665ZM7.00065 0.333313C5.15898 0.333313 3.66732 1.82498 3.66732 3.66665C3.66732 5.50831 5.15898 6.99998 7.00065 6.99998C8.84232 6.99998 10.334 5.50831 10.334 3.66665C10.334 1.82498 8.84232 0.333313 7.00065 0.333313ZM7.00065 7.83331C4.77565 7.83331 0.333984 8.94998 0.333984 11.1666V13.6666H13.6673V11.1666C13.6673 8.94998 9.22565 7.83331 7.00065 7.83331Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)

export const PaymentsIcon = ({ isActive = false }) => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.666 0.333313H2.33268C1.40768 0.333313 0.674349 1.07498 0.674349 1.99998L0.666016 12C0.666016 12.925 1.40768 13.6666 2.33268 13.6666H15.666C16.591 13.6666 17.3327 12.925 17.3327 12V1.99998C17.3327 1.07498 16.591 0.333313 15.666 0.333313ZM15.666 12H2.33268V6.99998H15.666V12ZM15.666 3.66665H2.33268V1.99998H15.666V3.66665Z"
            fill={isActive ? '#2990FB' : '#3C3C3C'}
        />
    </svg>
)
