import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import AnalyticsMonitor from '../common/services/AnalyticsMonitor'
import { isEmpty } from 'lodash'
import { EDUCATION_GROUP_ID } from '../common/constants/ProductConstants'

const defineGroup = groupId => (groupId === EDUCATION_GROUP_ID ? 'Education' : 'Business')

const useAnalyticsMonitor = () => {
    const { selectedOrganizationId, selectedSubscription, userDetails } = useSelector(state => ({
        selectedOrganizationId: state.organizations.selectedOrganizationId,
        selectedSubscription: state.organizations.selectedSubscription,
        userDetails: state.user_details,
    }))

    useEffect(() => {
        if (!isEmpty(userDetails)) {
            if (userDetails.isTest) AnalyticsMonitor.opt_out_tracking()

            const roleInProjectDto = userDetails.roleInProjectDto
            AnalyticsMonitor.register({
                group: roleInProjectDto && defineGroup(roleInProjectDto.userGroupInProjectDto.customId),
                role: roleInProjectDto && roleInProjectDto.id,
            })
        }
    }, [userDetails])

    useEffect(() => {
        if (selectedOrganizationId && !isEmpty(selectedSubscription))
            AnalyticsMonitor.register({
                organizationId: selectedOrganizationId,
                productCode: selectedSubscription.product.productCode,
            })
    }, [selectedOrganizationId, selectedSubscription])
}

export default useAnalyticsMonitor
