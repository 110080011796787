import React from 'react'
import { useTranslation } from 'react-i18next'

import './PlaceBar.scss'

const PlaceBar = ({ onClick, children, isAudio, buttonText }) => {
    const { t } = useTranslation()
    return (
        <div className="asset-manager-place-bar">
            <div className="asset-manager-place-bar__content">
                <div>{children}</div>
                <button className="button" data-size="lg" data-variant="primary" onClick={onClick}>
                    {buttonText || (isAudio ? t('Place audio') : t('Place image'))}
                </button>
            </div>
        </div>
    )
}
export default PlaceBar
