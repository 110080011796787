import { cloneDeep, set, isEmpty } from 'lodash'
import { validateValue } from 'common/utils/validator'
import { MemoryPair } from './models.js'
import { INIT_VALIDATE_STRUCTURE, TABS, VALIDATE_MESSAGES, VALIDATE_RULES } from './constants'

export const updateValidateRules = (structure, validateRules = VALIDATE_RULES, activeTabId) => {
    const rules = cloneDeep(validateRules)
    if (!structure.playground.isShowCover) {
        delete rules.playground.coverHeader
        delete rules.playground.coverButtonText
    }
    Object.entries(TABS).forEach(([key, value]) => {
        if (activeTabId && value !== activeTabId) {
            delete rules[key]
        }
    })
    return rules
}

export const validatePairs = pairList =>
    pairList.filter(pair => {
        return isEmpty(pair.firstImage.src) && isEmpty(pair.secondImage.src)
    })

export const validateFields = (structure, validateRules = VALIDATE_RULES) => {
    const validateErrors = cloneDeep(INIT_VALIDATE_STRUCTURE)
    for (const viewKey of Object.keys(structure)) {
        const view = structure[viewKey]
        if (!validateRules[viewKey]) {
            continue
        }
        for (const fieldKey of Object.keys(view)) {
            const value = view[fieldKey]
            const rules = validateRules[viewKey][fieldKey]
            const errorMessages = rules
                ? rules.filter(rule => !validateValue(rule, value)).map(rule => VALIDATE_MESSAGES[rule])
                : []

            if (errorMessages.length) {
                set(validateErrors, `${viewKey}.${fieldKey}`, errorMessages)
                set(validateErrors, 'hasError', true)
            }
        }
    }

    return validateErrors
}

export const calculateCardSideSize = (wrapperWidth, cardCount, proportion = 1) => {
    return (wrapperWidth / cardCount) * proportion
}

export const memoryProjectGetData = cardsCount => {
    const cardsData = []
    for (let i = 0; i < cardsCount; i++) {
        cardsData.push(new MemoryPair())
    }
    return cardsData
}

export const getClassSizeCardText = length => (length <= 6 ? 'big' : length <= 30 ? 'medium' : 'small')
