import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import InputFile from '../InputFile/InputFile'

import deleteIcon from './i/delete.svg'

import styles from './UploadImage.module.scss'

const UploadImage = ({
    label,
    description,
    url,
    projectId,
    onChange = () => {},
    className,
    isCanDelete = true,
    isGalleryImage = false,
}) => {
    const { t } = useTranslation()

    const uploadRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className={classNames(styles.uploadImage, { [className]: !!className })}>
            <InputFile
                ref={uploadRef}
                isGalleryImage={isGalleryImage}
                projectId={projectId}
                onChangeLoading={v => setIsLoading(v)}
                onChange={url => onChange(url)}
            />
            {!!label && <p className={styles.label}>{label}</p>}
            <div className={styles.box}>
                <div className={styles.leftSide}>
                    {url && <div className={styles.preview} style={{ backgroundImage: `url(${url})` }} />}
                    <button
                        className={classNames('button', styles.button)}
                        data-size="s"
                        data-variant="primary"
                        disabled={isLoading}
                        onClick={() => uploadRef.current.click()}
                    >
                        {url ? t('Change') : t('Upload')}
                    </button>
                    {url && isCanDelete && (
                        <div
                            className={classNames(styles.delete, { [styles.isDisabledDelete]: isLoading })}
                            onClick={() => onChange(null)}
                        >
                            <img src={deleteIcon} alt="delete" />
                        </div>
                    )}
                </div>
                {!!description && <div className={styles.description}>{description}</div>}
            </div>
        </div>
    )
}

export default UploadImage
