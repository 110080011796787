import React from 'react'

import loaderIcon from './i/loader.png'

import styles from './CircleSpinner.module.scss'

const CircleSpinner = () => (
    <div className={styles.circleSpinner}>
        <img className={styles.image} src={loaderIcon} alt="loader" />
    </div>
)

export default CircleSpinner
