import i18n from 'i18n'

export const FIELDS = {
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    AVATAR: 'AVATAR',
    PREVIEW_IMAGE: 'PREVIEW_IMAGE',
}

export const FIELDS_LABELS = {
    [FIELDS.FIRST_NAME]: i18n.t('Your name'),
    [FIELDS.LAST_NAME]: i18n.t('Surname'),
    [FIELDS.AVATAR]: i18n.t('Your real photo'),
    [FIELDS.PREVIEW_IMAGE]: i18n.t('Preview   '),
}
