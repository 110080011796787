export const loadImage = async url => {
    if (!url) throw new Error('url is required')

    return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => resolve(image)
        image.onerror = () => reject('Loading image error')
        image.src = url
    })
}
