import React, { useState } from 'react'

import { USER_PUBLIC_PROFILE_COVER_URLS } from 'common/constants/user'

import ChooseImageBox, { POSITIONS } from 'components/Modal/ChooseImageBox/ChooseImageBox'
import Toast from 'components/Toast/Toast'

import styles from './TopBanner.module.scss'

const TopBanner = ({ isCanManage = false, title, stepNumber, stepsCount, userPublicProfile, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div
            className={styles.topBanner}
            style={{
                backgroundImage: `url(${userPublicProfile?.headerLogoUrl || USER_PUBLIC_PROFILE_COVER_URLS[0]})`,
            }}
        >
            {stepsCount > 1 && (
                <div className={styles.counter}>
                    {stepNumber}/{stepsCount}
                </div>
            )}
            {title}

            {isCanManage && (
                <ChooseImageBox
                    position={POSITIONS.BOTTOM_RIGHT}
                    isLoading={isLoading}
                    selectedImageUrl={userPublicProfile?.headerLogoUrl || USER_PUBLIC_PROFILE_COVER_URLS[0]}
                    urlsArray={USER_PUBLIC_PROFILE_COVER_URLS}
                    onChange={async url => {
                        setIsLoading(true)
                        try {
                            await onUpdate({ headerLogoUrl: url })
                        } catch (err) {
                            console.error(err)
                            Toast('error', {})
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                />
            )}
        </div>
    )
}

export default TopBanner
