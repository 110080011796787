import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAvatarText } from 'common/utils/getAvatarText'

import { API__USER } from 'api'

import Toast from 'components/Toast/Toast'
import ChooseImageUploadBox from 'components/ChooseImageUploadBox/ChooseImageUploadBox'

import { ORGANIZATIONS_REFRESH_MEMBERS, USER__SET_DETAILS } from 'store/actions'

import styles from './Avatar.module.scss'

const Avatar = ({ onChange = () => {} }) => {
    const { user_details } = useSelector(state => state)
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    return (
        <div
            className={styles.avatar}
            style={{
                backgroundImage: user_details.avatarUrl ? `url(${user_details.avatarUrl})` : 'none',
                backgroundColor: user_details.avatarUrl ? 'transparent' : '#2990fb',
                borderColor: user_details.avatarUrl ? '#979797' : '#2990fb',
            }}
        >
            {!user_details.avatarUrl && getAvatarText(user_details)}
            <ChooseImageUploadBox
                onChange={async (type, bytes) => {
                    setIsLoading(true)
                    try {
                        const url = await API__USER.SET_USER_AVATAR(user_details.id, type, bytes)
                        const updatedUser = await API__USER.UPDATE_DETAILS(user_details.id, {
                            ...user_details,
                            login: user_details.email,
                            avatarUrl: url,
                        })
                        await dispatch(USER__SET_DETAILS(updatedUser))
                        await dispatch(ORGANIZATIONS_REFRESH_MEMBERS())
                        onChange(url)
                    } catch (err) {
                        console.error(err)
                        Toast('error', {})
                    } finally {
                        setIsLoading(false)
                    }
                }}
                isLoading={isLoading}
            />
        </div>
    )
}

export default Avatar
