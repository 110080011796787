import React, { useState } from 'react'
import InlineInput from '../../../../components/Form/InlineInput/InlineInput'
import Dropdown from './Dropdown/Dropdown'
import { FolderIcon } from './FolderIcon'
import './FolderItem.scss'

const FolderItem = ({ folder, onOpen, onRename, onMove, onDelete, isLoading = false, isCanMove }) => {
    const [isEditing, setIsEditing] = useState(false)

    const handleRename = val => {
        setIsEditing(false)
        if (val !== folder.name) onRename(folder.id, val, folder.parentId)
    }

    return (
        <div className="folder-item" onClick={() => onOpen && onOpen(folder.id)}>
            <FolderIcon isLoading={isLoading} />
            <InlineInput
                className="folder-item__name-wrapper"
                inputClassName="folder-item__name"
                value={folder.name}
                maxLength={48}
                isEditable={isEditing}
                onSave={val => handleRename(val)}
                isLoading={isLoading}
            />
            {!isLoading && (
                <div className="folder-item__actions">
                    <span className="folder-item__count">{folder.projectsCount}</span>
                    <Dropdown
                        onRename={() => setIsEditing(true)}
                        onMove={isCanMove ? () => onMove(folder.id) : null}
                        onDelete={() => onDelete(folder.id)}
                    />
                </div>
            )}
        </div>
    )
}

export default FolderItem
