import i18n from 'i18n'
import { FEATURE_TYPES } from 'common/constants/subscription'

export const getLineStyles = (value, total, isDanger) => ({
    width: `${(value * 100) / total}%`,
    backgroundColor: isDanger ? '#FF5656' : '#2990fb',
})

export const getValues = (value, total, type) => {
    const result = {
        isUnlimited: false,
        value: value,
        valueUnits: null,
        total: total,
        totalUnits: null,
    }

    if (total >= 1000000) {
        result.isUnlimited = true
        return result
    }

    if (type === FEATURE_TYPES.REVIEWS_COUNT || type === FEATURE_TYPES.PROJECTS_COUNT) {
        if (value !== 0) {
            if (value >= 1000) {
                result.value = Math.floor(value / 1000)
                if (total < 1000) result.valueUnits = i18n.t('K')
            }
        }
        if (total >= 1000) {
            result.total = Math.floor(total / 1000)
            result.totalUnits = i18n.t('K')
        }
        return result
    }

    if (type === FEATURE_TYPES.STORAGE_SIZE) {
        if (value !== 0) {
            if (value >= 1000) {
                result.value = Math.floor(value / 1000)
                if (total < 1000) result.valueUnits = i18n.t('GB')
            } else {
                if (total >= 1000) result.valueUnits = i18n.t('MB')
            }
        }
        if (total >= 1000) {
            result.total = Math.floor(total / 1000)
            result.totalUnits = i18n.t('GB')
        } else {
            result.totalUnits = i18n.t('MB')
        }

        return result
    }

    return result
}
