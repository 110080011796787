import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import tipIcon from './i/tip-lamp.svg'
import helpCenterIcon from './i/help-center-icon.svg'
import { Preview } from './Preview'
import { Tip } from './Tip'

import { TipsNavigationPanel } from './TipsNavigationPanel'
import { useBlockTips } from './useBlockTips'

import './VideoTips.scss'

const VideoTips = ({ isMultiplayerGame = false, blocks }) => {
    const { t } = useTranslation()
    const { tips, hasUnwatchedTips, selectedTipIndex, markAsWatched, switchSelectedTip } = useBlockTips(
        blocks,
        isMultiplayerGame,
    )
    const [isShowDetail, setShowDetail] = useState(false)

    useEffect(() => setShowDetail(hasUnwatchedTips), [hasUnwatchedTips])

    const openTipInNewTab = href => {
        if (!href) {
            const _selectedTip = tips[selectedTipIndex]
            markAsWatched(_selectedTip.id)
            window.open(_selectedTip.resourceLink, '_blank')?.focus()
        } else {
            window.open(href, '_blank')?.focus()
        }
    }

    const closeTipInfo = () => {
        const _selectedTip = tips[selectedTipIndex]
        markAsWatched(_selectedTip.id)
        setShowDetail(false)
    }

    const isShowNavigationPanel = tips.length > 1
    const selectedTip = tips[selectedTipIndex]

    return (
        <div className="tips">
            {!isShowDetail ? (
                <div className="tips__preview-container">
                    <Preview imgSrc={tipIcon} onClick={() => setShowDetail(true)} label={t('How to')} />
                </div>
            ) : (
                <div className="tips__info">
                    <div className="tips__info-header">
                        {t('Watch a short tutorial video')}
                        <div className="tips__info-close-button" onClick={closeTipInfo} />
                    </div>
                    <Tip tip={selectedTip} onClick={() => openTipInNewTab()} />
                    {isShowNavigationPanel && (
                        <TipsNavigationPanel
                            onPrevious={() => switchSelectedTip(selectedTipIndex - 1)}
                            onNext={() => switchSelectedTip(selectedTipIndex + 1)}
                            onSelect={switchSelectedTip}
                            tips={tips}
                            selectedTipIndex={selectedTipIndex}
                        />
                    )}
                    <div className="tips__help-center-link" onClick={() => window.open('https://help.interacty.me')}>
                        <img src={helpCenterIcon} alt="Help center" />
                        {t('Help center')}
                    </div>
                </div>
            )}
        </div>
    )
}

export default VideoTips
