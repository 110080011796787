import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MODAL_MANAGER_SET_MODAL } from 'store/actions/modalManager'

const useModalManager = () => {
    const dispatch = useDispatch()
    const { modalManager } = useSelector(state => state)

    const openModal = (name, payload) => dispatch(MODAL_MANAGER_SET_MODAL(name, payload))
    const closeModal = () => dispatch(MODAL_MANAGER_SET_MODAL())

    return { modalManager, openModal, closeModal }
}

export default useModalManager
