import React from 'react'
import { InView } from 'react-intersection-observer'
import useAudioPlayer from 'hooks/useAudioPlayer'

import LoaderIcon from 'components/LoaderIcon/LoaderIcon'

import EmptyGrid from './components/EmptyGrid'
import Item from './components/Item'

import './MediaGrid.scss'

export const TYPES = {
    UNSPLASH: 'UNSPLASH',
    PIXABAY: 'PIXABAY',
}

const MediaGrid = ({
    data,
    selectedId,
    isLoadData,
    isCanLoadData,
    isShowItemDetails,
    onUpPage,
    onDeleteImage,
    onSelectImage,
    onUnselectImage,
    isAudio,
    type = TYPES.UNSPLASH,
}) => {
    const { onPlayAudio, playedUrl } = useAudioPlayer()

    const handleOnScrollEnd = async inView => {
        if (inView && !isLoadData && isCanLoadData) {
            onUpPage()
        }
    }

    if (data.length === 0) {
        if (isLoadData) {
            return (
                <div className="media-grid">
                    <LoaderIcon />
                </div>
            )
        }
        return <EmptyGrid isAudio={isAudio} />
    }

    return (
        <div className="media-grid">
            <div className="media-grid__list">
                {data.map(
                    ({
                        id,
                        originalItemId,
                        name,
                        url = '',
                        previewUrl = '',
                        authorInfo,
                        isAudio,
                        userNickName,
                        userProfileUrl,
                    }) => {
                        const onSetSelectItem =
                            originalItemId !== selectedId
                                ? () => onSelectImage(originalItemId)
                                : () => onUnselectImage(originalItemId)
                        return (
                            <Item
                                key={id}
                                name={name}
                                author={authorInfo}
                                userNickName={userNickName}
                                userProfileUrl={userProfileUrl}
                                url={url}
                                type={type}
                                previewUrl={previewUrl}
                                isSelected={originalItemId === selectedId}
                                isShowDetails={isShowItemDetails}
                                onDeleteItem={() => onDeleteImage(originalItemId)}
                                onSetSelectItem={onSetSelectItem}
                                isAudioPreview={isAudio}
                                onPlayAudio={onPlayAudio}
                                playedUrl={playedUrl}
                            />
                        )
                    },
                )}
                <div className="media-grid__loader">{isLoadData && <LoaderIcon color={'#2990fb'} />}</div>
                <InView onChange={handleOnScrollEnd}>
                    <div />
                </InView>
            </div>
        </div>
    )
}

export default MediaGrid
