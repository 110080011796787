const Up = ({ color = '#3C3C3C' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 13C7.5 13.2761 7.72386 13.5 8 13.5C8.27614 13.5 8.5 13.2761 8.5 13L7.5 13ZM8.5 13L8.5 3L7.5 3L7.5 13L8.5 13Z"
            fill={color}
        />
        <path d="M12 6L8 3L4 6" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Up
