import React from 'react'

const Wave = ({ color, votesCount }) => {
    const range = new Array(votesCount).fill(0)

    return (
        <div className="rank-battle__wave">
            {range.map((el, i) => (
                <div key={i} className="rank-battle__wave__element" style={{ backgroundColor: color }} />
            ))}
        </div>
    )
}

export default Wave
