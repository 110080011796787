import {
    createCollector,
    createValidator,
    defined,
    dependOn,
    itemRules,
    maxLength,
    minLength,
    required,
} from 'shared/validation'
import {
    MAX_ANSWERS_IN_QUESTION,
    MIN_ANSWERS_IN_QUESTION,
    MIN_CORRECT_ANSWERS_IN_QUESTION,
    MIN_INCORRECT_ANSWERS_IN_QUESTION,
    TAB_TO_FIELD_MAP,
} from './constants'

function convertItemErrorsToMap(items, errors) {
    return new Map(
        items.map((item, index) => {
            return [item.id, errors[index]]
        }),
    )
}

const answerRules = {
    id: [required()],
    text: [dependOn(({ parent }) => (parent.isText ? required() : null)), maxLength(500)],
    imageLabel: [maxLength(40)],
    image: [dependOn(({ parent }) => (parent.isText ? required() : null))],
    isCorrect: [defined()],
}

const questionRules = {
    id: [required()],
    text: [required(), maxLength(500)],
    image: [defined()],
    imageDisclaimer: [maxLength(100)],
    answers: [
        itemRules(answerRules, convertItemErrorsToMap),
        minLength(MIN_ANSWERS_IN_QUESTION),
        maxLength(MAX_ANSWERS_IN_QUESTION),
    ],
}

export const QUESTION_RULES_FIELDS = {
    question: questionRules,
}

export const validateTabs = (tmpStructure, activeTab) => {
    const activeTabField = TAB_TO_FIELD_MAP[activeTab] || null
    const isValid = createValidator(QUESTION_RULES_FIELDS)(tmpStructure, activeTabField)
    const errors = createCollector(QUESTION_RULES_FIELDS)(tmpStructure, activeTabField)

    return {
        tmpStructure,
        errors,
        hasError: !isValid,
    }
}

export function isMinAnswerOptionLimitExceeded(answers) {
    const incorrectAnswerCount = answers.filter(el => !el.isCorrect).length
    const correctAnswerCount = answers.length - incorrectAnswerCount
    const isMinIncorrectAnswerExceeded = incorrectAnswerCount < MIN_INCORRECT_ANSWERS_IN_QUESTION
    const isMinCorrectAnswerExceeded = correctAnswerCount < MIN_CORRECT_ANSWERS_IN_QUESTION
    return isMinIncorrectAnswerExceeded || isMinCorrectAnswerExceeded
}
