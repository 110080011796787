import React from 'react'

import styles from './Hint.module.scss'

const Hint = ({ fontSize, text }) => (
    <div
        className={styles.hint}
        style={{
            fontSize,
        }}
    >
        {text}
    </div>
)

export default Hint
