import React from 'react'
import './Toggle.scss'

const Toggle = ({ label = '', value, onUpdate, isDisabled }) => {
    const onChange = () => {
        if (isDisabled) return
        onUpdate(!value)
    }

    return (
        <div className="common-toggle">
            <div className="common-toggle__slider-wrapper">
                <span className={`common-toggle__slider ${value ? 'checked' : ''}`} onClick={onChange} />
            </div>
            <p className="common-toggle__label" onClick={onChange}>
                {label.length ? label : null}
            </p>
        </div>
    )
}

export default Toggle
