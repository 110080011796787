import i18n from 'i18n'

import { DEFAULT_IMAGE_BG_BIG_URL, DEFAULT_MULTIPLAYER_BG } from 'common/constants/constants'

import { TABS, GAME_TYPES } from './constants'

export class Playground {
    gameType // <String>
    tilesCount // <Number>
    imageUrl // <null/String[URL]>
    isShowImageNumbers // <Boolean>
    isShowCover // <Boolean> Show cover
    coverHeader // <String> Cover header
    coverButtonText // <String> Cover button text

    constructor({
        gameType = GAME_TYPES.NUMBERS,
        tilesCount = 16,
        imageUrl = DEFAULT_MULTIPLAYER_BG,
        isShowImageNumbers = false,
        isShowCover = false,
        coverHeader = '',
        coverButtonText = '',
    } = {}) {
        this.gameType = gameType
        this.tilesCount = tilesCount
        this.imageUrl = imageUrl
        this.isShowImageNumbers = isShowImageNumbers
        this.isShowCover = isShowCover
        this.coverHeader = coverHeader
        this.coverButtonText = coverButtonText
    }

    static get gameType() {
        return 'gameType'
    }

    static get tilesCount() {
        return 'tilesCount'
    }

    static get imageUrl() {
        return 'imageUrl'
    }

    static get isShowImageNumbers() {
        return 'isShowImageNumbers'
    }

    static get isShowCover() {
        return 'isShowCover'
    }

    static get coverHeader() {
        return 'coverHeader'
    }

    static get coverButtonText() {
        return 'coverButtonText'
    }
}

export class Final {
    header // <String> text
    description // <String> result description
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer

    constructor({
        header = i18n.t('Congratulations!'),
        description = '',
        image = DEFAULT_IMAGE_BG_BIG_URL,
        imageDisclaimer = '',
    } = {}) {
        this.header = header
        this.description = description
        this.image = image
        this.imageDisclaimer = imageDisclaimer
    }

    static get header() {
        return 'header'
    }

    static get description() {
        return 'description'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }
}

export const BLOCK_KEYS = {
    PLAYGROUND: 'playground',
    FINAL: 'final',
}

export const BLOCK_KEYS_BY_TABS = {
    [TABS.PLAYGROUND]: BLOCK_KEYS.PLAYGROUND,
    [TABS.FINAL]: BLOCK_KEYS.FINAL,
}

export function createSlidingPuzzleBlock() {
    return {
        [BLOCK_KEYS.PLAYGROUND]: new Playground(),
        [BLOCK_KEYS.FINAL]: new Final(),
    }
}
