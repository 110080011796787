import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Toast from 'components/Toast/Toast'
import Avatar from 'components/Avatar/Avatar'

import { API__USER } from 'api'

import { isEducation } from 'common/utils/permissions'

import { createCollector, createValidator, required } from 'shared/validation'

import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormTextInput from 'components/Form/components/Helpers/FormTextInput/FormTextInput'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'

import styles from './ChangeInfoForm.module.scss'

const ChangeInfoForm = ({ userDetails, onSuccess, isPublished }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [firstName, setFirstName] = useState(userDetails.firstName || '')
    const [lastName, setLastName] = useState(userDetails.lastName || '')
    const [company, setCompany] = useState(userDetails.company || '')
    const [isDisabledButton, setIsDisabledButton] = useState(false)

    const [errors, setErrors] = useState({})

    const role = userDetails.roleInProjectDto

    useEffect(() => {
        if (
            userDetails.firstName !== firstName.trim() ||
            userDetails.lastName !== lastName.trim() ||
            (userDetails.company && userDetails.company !== company.trim()) ||
            (!userDetails.company && company.trim())
        ) {
            setIsDisabledButton(false)
        } else {
            setIsDisabledButton(true)
        }
    }, [userDetails, firstName, lastName, company])

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            if (isEducation() && isPublished) {
                const fields = {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                }

                const validationRules = {
                    firstName: [required()],
                    lastName: [required()],
                }
                const isValid = createValidator(validationRules)(fields)
                const errors = createCollector(validationRules)(fields)

                setErrors(errors)
                if (!isValid) return
            }

            const updatedUser = await API__USER.UPDATE_DETAILS(userDetails.id, {
                ...userDetails,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                company: company.trim(),
                roleInProjectDto: role,
                login: userDetails.email,
            })
            await onSuccess(updatedUser)
            Toast('success', {
                message: t('Your profile information is updated successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form>
            <FormInputs>
                <div className={styles.blocks}>
                    <div className={styles.avatarBlock}>
                        <Avatar
                            onChange={() => {
                                Toast('success', {
                                    message: t('Your profile information is updated successfully'),
                                })
                            }}
                        />
                    </div>
                    <div className={styles.inputsBlock}>
                        <FormInputBlock>
                            <FormTextInput
                                maxLength={64}
                                errorMessages={errors.firstName || []}
                                label={t('First name')}
                                value={firstName}
                                onUpdate={v => {
                                    setFirstName(v)
                                    setErrors({ ...errors, firstName: [] })
                                }}
                            />
                        </FormInputBlock>
                        <FormInputBlock>
                            <FormTextInput
                                maxLength={64}
                                errorMessages={errors.lastName || []}
                                label={t('Last name')}
                                value={lastName}
                                onUpdate={v => {
                                    setLastName(v)
                                    setErrors({ ...errors, lastName: [] })
                                }}
                            />
                        </FormInputBlock>
                    </div>
                </div>
                <FormInputBlock>
                    <FormTextInput label={t('Email')} isDisabled={true} value={userDetails.email} />
                </FormInputBlock>
                <FormInputBlock>
                    <FormTextInput label={t('Your profile')} value={role.roleTranslationDto.text} isDisabled={true} />
                </FormInputBlock>
                <FormInputBlock>
                    <FormTextInput
                        label={t('Your sector')}
                        value={role.userGroupInProjectDto.groupTranslationDto.text}
                        isDisabled={true}
                    />
                </FormInputBlock>
                <FormInputBlock>
                    <FormTextInput label={t('Company')} value={company} onUpdate={v => setCompany(v)} maxLength={64} />
                </FormInputBlock>
            </FormInputs>
            <FormButtons>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary"
                    disabled={isDisabledButton || isLoading}
                    onClick={() => onSubmit()}
                >
                    {t('Save')}
                </button>
            </FormButtons>
        </Form>
    )
}

export default ChangeInfoForm
