import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import styles from './ColorPicker.module.scss'

const ColorPicker = ({ reactQuillRef, option, onSave, onClose }) => {
    const { t } = useTranslation()

    useEffect(() => {
        if (!reactQuillRef?.current) return
        const editor = reactQuillRef.current.getEditor()
        const format = editor.getFormat()
        if (format[option]) setValue(format[option])
        else setValue('#000000')
    }, [])

    const [value, setValue] = useState()

    return (
        <Modal>
            <div className={styles.colorPicker}>
                <ChromePicker
                    width="400px"
                    className={styles.chromePicker}
                    color={value}
                    onChangeComplete={color => setValue(color.hex)}
                    disableAlpha={true}
                />
                <div className={styles.buttonBox}>
                    <button className="button" data-variant="secondary" data-size="lg" onClick={() => onClose()}>
                        {t('Cancel')}
                    </button>
                    <button className="button" data-variant="primary" data-size="lg" onClick={() => onSave(value)}>
                        {t('Save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ColorPicker
