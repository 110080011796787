import React, { useState } from 'react'

import Modal from 'components/Modal/Modal'

import Confirmation from './views/Confirmation/Confirmation'
import Reason from './views/Reason/Reason'

import { VIEWS } from './constants'

const CancelSubscriptionModal = ({ onClose, onCancel }) => {
    const [view, setView] = useState(VIEWS.CONFIRMATION)

    return (
        <Modal onClose={onClose} isShowCloseIcon>
            {view === VIEWS.CONFIRMATION && <Confirmation onClose={onClose} onContinue={() => setView(VIEWS.REASON)} />}
            {view === VIEWS.REASON && <Reason onClose={onClose} onSubmit={onCancel} />}
        </Modal>
    )
}

export default CancelSubscriptionModal
