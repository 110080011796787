import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFullUrlToSsr } from 'utils/router'

import CardsGroup from './components/CardsGroup/CardsGroup'
import Card from './components/Card/Card'

import individualsImage from './i/business/individuals.png'
import businessImage from './i/business/business.png'
import enterprisesImage from './i/business/enterprises.png'

const BusinessPlans = () => {
    const { t } = useTranslation()

    const getHref = group => `${getFullUrlToSsr()}/pricing/business?group=${group}`

    return (
        <CardsGroup>
            <Card
                imageSrc={individualsImage}
                title={t('For individuals')}
                description={t(
                    'Create engaging quizzes, online games, contests, and lead forms.\nEasy to embed on a website or blog, or share a direct link.',
                )}
                href={getHref('individuals')}
            />
            <Card
                imageSrc={businessImage}
                title={t('For business ')}
                description={t(
                    'Gamification, analytics, integrations and teamwork make it easier than ever to create professional interactive content. Choose the tariff plan that best suits your needs.',
                )}
                href={getHref('business')}
            />
            <Card
                imageSrc={enterprisesImage}
                title={t('For enterprises')}
                description={t(
                    'Gamification for organizations: from marketing campaigns to employee engagement.\nUnlimited possibilities and individual approach.',
                )}
                href={getHref('enterprise')}
            />
        </CardsGroup>
    )
}

export default BusinessPlans
