import { sanitizeUrl } from '@braintree/sanitize-url'
import smoothScroll from 'smoothscroll-polyfill'

import {
    validator,
    throttle,
    getScrollableParent,
    getElementYOffset,
    getScrollReadyElement,
    getElementInnerHeight,
    getElementPositions,
    validateConstructorParam,
    getWindowLanguage,
    getWindowLocation,
    getWindowQueryParams,
    onSendApiEvent,
    getFullUrlToSsr,
    loadFonts,
    getTranslation,
    openFullscreen,
    closeFullscreen,
    isTouchDevice,
} from './utils'

import {
    CDN_URL,
    MAX_REFRESH_SESSION_AWAITING,
    MODES,
    PROJECT_MODES,
    URL_PARAMS,
    LOAD_ELEMENT_PARAMS,
    POST_MESSAGE_METHODS,
    CONSTRUCTOR_PARAMS,
    API_EVENTS,
    TRIAL_PRODUCT_CODE,
    SCREEN_MODES,
} from './constants'

import API from './api'

import googleAnalytics from './services/googleAnalytics'
import googleTagManager from './services/googleTagManager'
import yandexMetric from './services/yandexMetric'
import webSocket from './services/webSocket'
import session from './services/session'

smoothScroll.polyfill()

/**
 * RemixLoader
 */
export class RemixLoader {
    #hash
    #mode
    #projectMode
    #isPublishMode
    #nodeElement
    #remixUrl
    #features
    #projectId
    #projectNumericId
    #projectStructure
    #initialWidth
    #initialHeight
    #lng
    #additionalTopOffset
    #onEvent
    #isSubscriptionExpired
    #subscriptionProductCode
    #isMultiplayerMode = false
    #screenMode = SCREEN_MODES.NORMAL
    #scrollableParent
    #appOrigin
    #preloader
    #paymentNotificator
    #error
    #iframe
    #eventListeners = [
        // Example
        // {
        //     target: 'window',
        //     type: 'click',
        //     func: () => {}
        //     capture: false
        // }
    ]

    #_integrations = {}
    #_webSocket = null

    #_isDestroyed = false
    #_clientId
    #_session = {
        instance: null,
        data: {
            clientId: null,
            projectId: null,
            utmCampaign: null,
            utmSource: null,
            utmMedium: null,
            utmContent: null,
            referenceTail: null,
            sourceReference: null,
        },
        createdAt: null,
        updatedAt: null,
        maxRefreshAwaiting: MAX_REFRESH_SESSION_AWAITING,
    }

    constructor({
        hash,
        mode,
        projectMode,
        nodeElement,
        remixUrl,
        features,
        projectId,
        projectNumericId,
        projectStructure,
        initialWidth,
        initialHeight,
        lng,
        additionalTopOffset,
        onEvent,
        isSubscriptionExpired,
        subscriptionProductCode,
    }) {
        this.#hash = validateConstructorParam(CONSTRUCTOR_PARAMS.HASH, hash, false,null)
        this.#mode = validateConstructorParam(CONSTRUCTOR_PARAMS.MODE, mode, false, MODES.PUBLISHED)
        this.#projectMode = validateConstructorParam(
            CONSTRUCTOR_PARAMS.PROJECT_MODE,
            projectMode,
            false,
            PROJECT_MODES.SINGLE,
        )
        this.#isPublishMode = this.#mode === MODES.PUBLISHED
        this.#nodeElement = validateConstructorParam(CONSTRUCTOR_PARAMS.NODE_ELEMENT, nodeElement, true)
        this.#remixUrl = validateConstructorParam(CONSTRUCTOR_PARAMS.REMIX_URL, remixUrl, true)
        this.#features = validateConstructorParam(CONSTRUCTOR_PARAMS.FEATURES, features, false, [])
        this.#projectId = validateConstructorParam(CONSTRUCTOR_PARAMS.PROJECT_ID, projectId, false, null)
        this.#projectNumericId = validateConstructorParam(
            CONSTRUCTOR_PARAMS.PROJECT_NUMERIC_ID,
            projectNumericId,
            false,
            null,
        )
        this.#projectStructure = validateConstructorParam(
            CONSTRUCTOR_PARAMS.PROJECT_STRUCTURE,
            projectStructure,
            false,
            null,
        )
        this.#initialWidth = validateConstructorParam(CONSTRUCTOR_PARAMS.INITIAL_WIDTH, initialWidth, false, 800)
        this.#initialHeight = validateConstructorParam(CONSTRUCTOR_PARAMS.INITIAL_HEIGHT, initialHeight, false, 600)
        this.#lng = validateConstructorParam(CONSTRUCTOR_PARAMS.LANGUAGE, lng, false, getWindowLanguage())
        this.#additionalTopOffset = validateConstructorParam(
            CONSTRUCTOR_PARAMS.ADDITIONAL_TOP_OFFSET,
            additionalTopOffset,
            false,
            0,
        )
        this.#onEvent = validateConstructorParam(CONSTRUCTOR_PARAMS.ON_EVENT, onEvent, false, null)
        this.#isSubscriptionExpired = isSubscriptionExpired
        this.#subscriptionProductCode = validateConstructorParam(
            CONSTRUCTOR_PARAMS.SUBSCRIPTION_PRODUCT_CODE,
            subscriptionProductCode,
            false,
            null,
        )
        this.#isMultiplayerMode = this.#projectMode === PROJECT_MODES.MULTIPLAYER

        this.#appOrigin = new URL(remixUrl).origin
        this.#preloader = this.#createPreloader()
        this.#paymentNotificator = this.#createPaymentNotificator()
        this.#error = this.#createError()
        this.#iframe = null
    }

    // Create iframe in container instance
    createIframe = async () => {
        loadFonts([
            'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
            'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        ])

        this.#sendApiEvent(API_EVENTS.IFRAME_INIT)
        this.#scrollableParent = getScrollableParent(this.#nodeElement)

        // Prepare styles
        if (this.#isMultiplayerMode) {
            this.#nodeElement.style.position = 'fixed'
            this.#nodeElement.style.zIndex = '999'
            this.#nodeElement.style.top = '0'
            this.#nodeElement.style.left = '0'
            this.#nodeElement.style.width = '100%'
            this.#nodeElement.style.height = '100%'
        } else {
            this.#nodeElement.style.position = 'relative'
            this.#nodeElement.style.margin = '0 auto'
            this.#nodeElement.style.maxWidth = `100%`
            this.#nodeElement.style.width = `${this.#initialWidth}px`
            this.#nodeElement.style.height = `${this.#initialHeight}px`
        }
        this.#nodeElement.innerHTML = ''
        this.#nodeElement.className = 'remix_cnt'
        this.#nodeElement.style.overflow = 'hidden'
        this.#nodeElement.style.padding = 0
        this.#nodeElement.style.boxSizing = 'border-box'

        if (this.#isPublishMode) {
            if (this.#isSubscriptionExpired) this.#nodeElement.appendChild(this.#paymentNotificator.render())

            if (this.#isSubscriptionExpired || !this.#features.includes('NO_LOGO')) {
                this.#nodeElement.appendChild(this.#preloader.render())
                if (this.#isMultiplayerMode) {
                    document.documentElement.appendChild(this.#createPoweredLabel())
                } else {
                    this.#nodeElement.appendChild(this.#createPoweredLabel())
                }
            }
        } else {
            this.#nodeElement.appendChild(this.#preloader.render())
        }

        this.#addEventListener(window, 'message', this.#iframeMessageHandler, false)

        this.#addEventListener(window,"fullscreenchange", () => {
            if (document.fullscreenElement) {
                this.#screenMode = SCREEN_MODES.FULLSCREEN
                this.#iframe.style.height = `100vh`
            } else {
                this.#screenMode = SCREEN_MODES.NORMAL
            }
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.SCREEN_MODE, this.#screenMode)
        })

        const iframe = document.createElement('iframe')
        iframe.id = 'remix-iframe'
        iframe.style.border = 0
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.overflow = 'hidden'
        iframe.setAttribute('allowFullScreen', '')
        iframe.src = this.#remixUrl

        this.#iframe = iframe
        this.#nodeElement.appendChild(iframe)

        iframe.onload = () => {
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.SCREEN_MODE, this.#screenMode)
            this.#sendApiEvent(API_EVENTS.IFRAME_ONLOAD)
            this.#sendApiEvent(API_EVENTS.REMIX_INIT)
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.INIT, {
                projectId: this.#projectId,
                projectNumericId: this.#projectNumericId,
                projectStructure: this.#projectStructure,
                lng: this.#lng,
                hash: this.#hash,
                mode: this.#mode,
                projectMode: this.#projectMode,
                features: this.#features,
                isSubscriptionExpired: this.#isSubscriptionExpired,
                subscriptionProductCode: this.#subscriptionProductCode,
                url: getWindowLocation(),
                urlParams: getWindowQueryParams(),
                isTouchDevice: isTouchDevice(),
            })
        }
    }

    destroyIframe = () => {
        this.#_isDestroyed = true
        if (this.#_webSocket) this.#_webSocket.disconnect()
        this.#removeAllEventListeners()
    }

    // [PRIVATE]
    #createSession = async (time = Date.now()) => {
        if (!this.#isPublishMode) return

        const embedUrl = window.location.href
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        const utmCampaign = urlParams.get(URL_PARAMS.UTM_CAMPAIGN)
        const utmSource = urlParams.get(URL_PARAMS.UTM_SOURCE)
        const utmMedium = urlParams.get(URL_PARAMS.UTM_MEDIUM)
        const utmContent = urlParams.get(URL_PARAMS.UTM_CONTENT)
        const referenceTail = queryString
        const sourceReference = document.referrer

        if (!this.#_session.instance) {
            this.#_session.instance = new session({
                clientId: this.#_clientId,
                projectId: this.#projectId,
                projectIdLong: this.#projectNumericId,
                utmCampaign,
                utmSource,
                utmMedium,
                utmContent,
                referenceTail,
                sourceReference,
                embedUrl,
            })
        }
        await this.#_session.instance.create()
        this.#_session.createdAt = time
        this.#_session.updatedAt = time

        this.#sendMessageToIframe(POST_MESSAGE_METHODS.SESSION_CREATED, {
            data: {
                id: await this.#_session.instance.getId(),
            },
        })
    }
    #refreshSession = async () => {
        if (!this.#isPublishMode) return

        const time = Date.now()
        if (time - this.#_session.updatedAt > this.#_session.maxRefreshAwaiting) {
            await this.#createSession(time)
        } else {
            await this.#_session.instance.refresh()
            this.#_session.updatedAt = time
        }
    }

    #sendApiEvent = (event, payload) => {
        onSendApiEvent(event, payload, {
            lng: this.#lng,
        })
    }

    #iframeMessageHandler = async ({ origin = null, data = {}, source = null }) => {
        if (!this.#iframe || this.#iframe.contentWindow !== source || origin !== this.#appOrigin) {
            return
        }

        switch (data.method) {
            case POST_MESSAGE_METHODS.INIT_ERROR: {
                this.#preloader.hideAndDestroy()
                this.#nodeElement.appendChild(this.#error.render())
                this.#sendApiEvent(API_EVENTS.ERROR)
                break
            }
            case POST_MESSAGE_METHODS.SET_SCREEN_MODE: {
                const { screenMode } = data.payload
                if (screenMode === SCREEN_MODES.NORMAL) await closeFullscreen()
                if (screenMode === SCREEN_MODES.FULLSCREEN) await openFullscreen(this.#nodeElement)
                break
            }
            case POST_MESSAGE_METHODS.INITIALIZED: {
                this.#preloader.hideAndDestroy()
                this.#setSize({
                    ...data.payload.sizes,
                    width: 'maxWidth',
                })

                if (!this.#projectStructure) {
                    this.#projectStructure = data.payload.projectStructure
                }

                this.#_clientId = data.payload.clientId

                this.#createSession()

                this.#getIframePosition(true)
                this.#addEventListener(
                    getScrollReadyElement(this.#scrollableParent),
                    'scroll',
                    throttle(() => this.#getIframePosition(true), 50),
                    false,
                )
                this.#addEventListener(
                    this.#nodeElement,
                    'scroll',
                    throttle(() => this.#getIframePosition(true), 50),
                    false,
                )

                this.#getWindowSize(true)
                this.#addEventListener(
                    window,
                    'resize',
                    throttle(() => this.#getWindowSize(true), 50),
                    false,
                )

                this.#addEventListener(window, 'keydown', evt => this.#handleKeyDown(evt, true), false)

                if (this.#isPublishMode) {
                    const integrations = this.#projectStructure.integrations
                    if (integrations) {
                        if (integrations.googleAnalytics && integrations.googleAnalytics.id) {
                            this.#_integrations.googleAnalytics = new googleAnalytics({
                                id: integrations.googleAnalytics.id,
                            })
                            this.#_integrations.googleAnalytics.init()
                        }
                        if (integrations.googleTagManager && integrations.googleTagManager.id) {
                            this.#_integrations.googleTagManager = new googleTagManager({
                                id: integrations.googleTagManager.id,
                            })
                            this.#_integrations.googleTagManager.init()
                        }
                        if (integrations.yandexMetric && integrations.yandexMetric.id) {
                            this.#_integrations.yandexMetric = new yandexMetric({
                                id: integrations.yandexMetric.id,
                            })
                            this.#_integrations.yandexMetric.init()
                        }
                    }
                }

                this.#sendApiEvent(API_EVENTS.REMIX_ONLOAD)
                break
            }
            case POST_MESSAGE_METHODS.ACTIVITY: {
                this.#refreshSession()
                this.#sendApiEvent(API_EVENTS.ACTIVITY)
                break
            }
            case POST_MESSAGE_METHODS.SET_SIZE: {
                const { sizes } = data.payload
                this.#setSize(sizes)
                this.#sendApiEvent(API_EVENTS.CHANGE_SIZE, sizes)
                this.#getIframePosition(true)
                break
            }
            case POST_MESSAGE_METHODS.SCROLL_PARENT: {
                if (validator.isValue(data.payload.top) && validator.isNumber(data.payload.top)) {
                    const yOffset = getElementYOffset(this.#scrollableParent)
                    const elementToScroll = getScrollReadyElement(this.#scrollableParent)
                    const newTopPosition =
                        this.#getIframePosition().top + yOffset + data.payload.top - this.#additionalTopOffset
                    setTimeout(() => {
                        elementToScroll.scrollTo({
                            top: newTopPosition,
                            behavior: 'smooth',
                        })
                    }, 50)
                    this.#sendApiEvent(API_EVENTS.SCROLL_PARENT, { top: newTopPosition })
                }
                break
            }
            case POST_MESSAGE_METHODS.CONNECT_WS: {
                if (!this.#isMultiplayerMode) return
                try {
                    this.#_webSocket = new webSocket({
                        onConnectEvent: () => {
                            this.#sendMessageToIframe(POST_MESSAGE_METHODS.WS_CONNECTED)
                        },
                        onDisconnectEvent: () => {
                            this.#sendMessageToIframe(POST_MESSAGE_METHODS.WS_DISCONNECTED)
                        },
                    })
                    const { gameId, masterPin = null } = data.payload

                    await this.#_webSocket.connect({ clientId: this.#_clientId, gameId, masterPin })

                    this.#_webSocket.subscribe(`/topic/games.${gameId}`, message => {
                        this.#sendMessageToIframe(POST_MESSAGE_METHODS.WS_MESSAGE, {
                            data: JSON.parse(message),
                        })
                    })
                } catch (err) {
                    console.error(err)
                }
                break
            }
            case POST_MESSAGE_METHODS.DISCONNECT_WS: {
                if (!this.#isMultiplayerMode) return
                try {
                    this.#_webSocket.disconnect()
                } catch (err) {
                    console.error(err)
                }
                break
            }
            case POST_MESSAGE_METHODS.SEND_WS_MESSAGE: {
                if (!this.#isMultiplayerMode || !this.#_webSocket) return
                try {
                    this.#_webSocket.sendMessage(data.payload.destination, {
                        ...data.payload.message,
                        clientId: this.#_clientId,
                    })
                } catch (err) {
                    console.error(err)
                }
                break
            }
            case POST_MESSAGE_METHODS.SEND_REQUEST: {
                const { requestId, httpMethod, endpoint, body, isForce = false } = data.payload
                if (this.#isPublishMode || isForce) {
                    try {
                        const options = {
                            method: httpMethod,
                            endpoint,
                        }
                        if (body) {
                            const sessionId = await this.#_session.instance.getId()
                            if (!sessionId) {
                                this.#sendMessageToIframe(POST_MESSAGE_METHODS.SEND_RESPONSE, {
                                    requestId,
                                    isSuccess: false,
                                    error: new Error("Can't find session id"),
                                })
                                return
                            }
                            options.body = {
                                ...body,
                                sessionId,
                                clientId: this.#_clientId,
                                embedUrl: window.location.href,
                                sourceReference: document.referrer,
                            }

                            if (endpoint === 'actions/common' || endpoint === 'actions/action-with-players-rank') {
                                this.#sendApiEvent(API_EVENTS.ACTION, {
                                    ...options.body,
                                    clientId: this.#_clientId,
                                })
                            }
                        }
                        const result = await API.sendRequest(options)

                        this.#sendMessageToIframe(POST_MESSAGE_METHODS.SEND_RESPONSE, {
                            requestId,
                            isSuccess: true,
                            result,
                        })
                    } catch (error) {
                        this.#sendMessageToIframe(POST_MESSAGE_METHODS.SEND_RESPONSE, {
                            requestId,
                            isSuccess: false,
                            error,
                        })
                    }
                } else {
                    this.#sendMessageToIframe(POST_MESSAGE_METHODS.SEND_RESPONSE, {
                        requestId,
                        isSuccess: false,
                        error: new Error(
                            `Failed to execute request with id "${requestId}". "${POST_MESSAGE_METHODS.SEND_RESPONSE}" available only in "${MODES.PUBLISHED}" mode or with "isForce" flag`,
                        ),
                    })
                }
                break
            }
            case POST_MESSAGE_METHODS.REDIRECT: {
                let { to } = data.payload
                if (!validator.isURL(to)) return

                if (this.#subscriptionProductCode === TRIAL_PRODUCT_CODE) {
                    to = getFullUrlToSsr(this.#lng, `/external-link`)
                    console.info(`Redirect link changed by subscription constraints`)
                }

                const targetUrl = new URL(to)
                const windowQueryParams = getWindowQueryParams()
                for (const [key, value] of Object.entries(windowQueryParams)) {
                    targetUrl.searchParams.append(key, value)
                }
                const sanitizedUrl = sanitizeUrl(targetUrl.toString())
                if (this.#isPublishMode) window.location.href = sanitizedUrl
                else window.open(sanitizedUrl, '_blank')

                this.#sendApiEvent(API_EVENTS.REDIRECT, { url: sanitizedUrl })
                break
            }
            case POST_MESSAGE_METHODS.ACTION: {
                if (!!this.#_integrations.yandexMetric) {
                    this.#_integrations.yandexMetric.sendGoal(data.payload.targetName, {
                        ...data.payload,
                        projectId: this.#projectId,
                    })
                }
                break
            }
            default:
                break
        }

        this.#sendEventToContainerInstance(data.method, data)
    }

    #addEventListener = (target, type, func, capture = false) => {
        try {
            this.#eventListeners.push({
                target,
                type,
                func,
                capture,
            })
            target.addEventListener([type], func, capture)
        } catch (err) {
            console.error(err)
        }
    }

    #removeAllEventListeners = () => {
        try {
            this.#eventListeners.forEach(el => {
                el.target.removeEventListener([el.type], el.func, el.capture)
            })
            this.#eventListeners = []
        } catch (err) {
            console.error(err)
        }
    }

    #setSize = ({ width, height, maxWidth }) => {
        try {
            if (this.#isMultiplayerMode) return
            if (validator.isValue(width) && width === 'maxWidth') {
                this.#nodeElement.style.width = '100%'
            }
            if (validator.isValue(maxWidth) && validator.isNumber(maxWidth)) {
                this.#nodeElement.style.maxWidth = `${maxWidth}px`
            }
            if (validator.isValue(height) && validator.isNumber(height)) {
                if (this.#screenMode === SCREEN_MODES.NORMAL) {
                    this.#nodeElement.style.height = `${height}px`
                    this.#iframe.style.height = '100%'
                }
            }
        } catch (err) {
            console.error(err)
        }
    }

    #createPreloader = () => {
        const html = `
        <div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: #fff; opacity: 1; display: flex; align-items: center; justify-content: center;">
            <img src='${CDN_URL}/preloader.gif' alt="preloader" style="width: 380px !important; max-width: 100% !important;" />
        </div>`

        const div = document.createElement('div')
        div.innerHTML = html.trim()
        const element = div.firstChild

        return {
            render: () => {
                return element
            },
            hideAndDestroy: () => {
                const container = element.parentNode
                if (container && container.contains(element)) {
                    container.removeChild(element)
                }
            },
        }
    }

    #createPoweredLabel = () => {
        const html = `<a href="https://interacty.me" target="_blank"><img src='${CDN_URL}/powered_by.svg' style="position: absolute; z-index: 15; bottom: 0; right: 0; width: 132px !important; max-width: 100% !important;" alt="Powered by Interacty" /></a>`

        const div = document.createElement('div')
        div.innerHTML = html.trim()
        return div.firstChild
    }

    #createPaymentNotificator = () => {
        const html =
        `<div id="block-box">
            <div id="block-info-box">
                <svg style="flex-shrink: 0;" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="60" cy="60" r="60" fill="#F2F2F2"/>
                    <rect x="18.8574" y="27.4285" width="82.2857" height="64.2857" rx="8" fill="white"/>
                    <path
                        d="M18.8574 35.4285C18.8574 31.0102 22.4391 27.4285 26.8574 27.4285H93.1431C97.5614 27.4285 101.143 31.0102 101.143 35.4285V42.857H18.8574V35.4285Z"
                        fill="#2990FB"/>
                    <circle cx="48.8566" cy="61.7143" r="2.57143" fill="#2990FB"/>
                    <circle cx="28.2863" cy="35.143" r="2.57143" fill="white"/>
                    <circle cx="36.8566" cy="35.143" r="2.57143" fill="white"/>
                    <circle cx="45.4289" cy="35.143" r="2.57143" fill="white"/>
                    <circle cx="71.1437" cy="61.7143" r="2.57143" fill="#2990FB"/>
                    <path
                        d="M51.4277 72.9999C51.4277 72.9999 54.2849 70.4285 59.9992 70.4285C65.7134 70.4285 68.5706 72.9999 68.5706 72.9999"
                        stroke="#2990FB" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <div style="margin-top: 20px; text-align: center;">
                    <h3 style="margin-bottom: 12px; font-family: 'Ubuntu', sans-serif; font-weight: 500; font-size: 20px; line-height: 20px;">${getTranslation(this.#lng, 'Oh no...')}</h3>
                    <p style="font-family: 'Roboto', sans-serif; font-size: 14px; line-height: 20px;">${getTranslation(this.#lng,'The project is temporarily disabled because the owner hasn\'t renewed the subscription.')}</p>
                </div>
            </div>
        </div>`

        const div = document.createElement('div')
        div.innerHTML = html.trim()
        const element = div.firstChild

        const blockBox = div.querySelector('#block-box')
        const blockBoxStyles = {
            position: 'absolute',
            zIndex: 10,
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(216, 216, 216, 0.4)',
            backdropFilter: 'blur(10px)',
            '-webkit-backdrop-filter': 'blur(10px)',
        }
        Object.assign(blockBox.style, blockBoxStyles)

        const blockInfoBox = div.querySelector('#block-info-box')
        const blockInfoBoxStyles = {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '40px',
            width: '380px',
            maxWidth: 'calc(100% - 40px)',
            height: 'auto',
            borderRadius: '16px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '24px',
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
            boxSizing: 'border-box',
        }
        Object.assign(blockInfoBox.style, blockInfoBoxStyles)

        return {
            render: () => {
                return element
            },
            hideAndDestroy: () => {
                const container = element.parentNode
                if (container && container.contains(element)) {
                    container.removeChild(element)
                }
            },
        }
    }

    #createError = () => {
        const html = `
        <div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: #fff; display: flex; align-items: center; justify-content: center;"
        >
            <span style="font-size: 16px; font-family: Arial, sans-serif">Oops! Some error occurred &#128532;</span>
         </div>`

        const div = document.createElement('div')
        div.innerHTML = html.trim()
        const element = div.firstChild

        return {
            render: function () {
                return element
            },
        }
    }

    #sendMessageToIframe = (method = '', payload = {}) => {
        this.#iframe.contentWindow.postMessage(
            {
                method,
                payload,
            },
            this.#appOrigin,
        )
    }

    #getIframePosition = (isForceSendToIframe = false) => {
        const position = getElementPositions(this.#scrollableParent, this.#iframe)
        const parentHeight = getElementInnerHeight(this.#scrollableParent)

        const visibleTop = position.top >= 0 ? 0 : Math.abs(position.top)

        let visibleHeight
        const topOffset = position.top, bottomOffset = parentHeight - position.bottom
        if (topOffset < 0 || bottomOffset < 0) {
            let diff = 0
            if (topOffset > 0) diff += topOffset
            if (bottomOffset > 0) diff += bottomOffset
            visibleHeight = parentHeight - diff
        } else {
            visibleHeight = position.height
        }

        if (isForceSendToIframe) {
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.IFRAME_POSITION, {
                data: {
                    ...position,
                    top: position.top - this.#additionalTopOffset,
                    windowBottom: position.top - parentHeight,
                    visible: {
                        top: visibleTop,
                        height: visibleHeight,
                    }
                },
            })
        }
        return position
    }

    #getWindowSize = isForceSendToIframe => {
        const sizes = {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
        }

        if (isForceSendToIframe) {
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.WINDOW_SIZE, {
                data: sizes,
            })
        }
        return sizes
    }

    #handleKeyDown = (evt, isForceSendToIframe) => {
        if (isForceSendToIframe) {
            this.#sendMessageToIframe(POST_MESSAGE_METHODS.KEYDOWN, {
                data: {
                    key: evt.key,
                    keyCode: evt.keyCode,
                },
            })
        }
    }

    #sendEventToContainerInstance = (name, data) => {
        if (!this.#onEvent) return
        this.#onEvent(name, data)
    }
}

window.RemixLoader = RemixLoader

/**
 * RemixLoader auto-initiator (for embedded projects)
 */
;(async () => {
    if (!window.RemixLoader) return

    const element = document.currentScript.parentElement
    if (!element) return
    if (!element.classList.contains(LOAD_ELEMENT_PARAMS.PROJECT_CLASSNAME)) return
    if (element.getAttribute(LOAD_ELEMENT_PARAMS.INITIALIZED_ATTRIBUTE_NAME)) return

    element.setAttribute(LOAD_ELEMENT_PARAMS.INITIALIZED_ATTRIBUTE_NAME, 'true')
    const hash = element.getAttribute(LOAD_ELEMENT_PARAMS.HASH_ATTRIBUTE_NAME)
    const initialWidth = element.getAttribute(CONSTRUCTOR_PARAMS.INITIAL_WIDTH)
    const initialHeight = element.getAttribute(CONSTRUCTOR_PARAMS.INITIAL_HEIGHT)
    const lng = element.getAttribute(CONSTRUCTOR_PARAMS.LANGUAGE)

    if (!hash) {
        console.error(`[RemixLoader auto-initiator] "hash" attribute is required for remix-app element`)
        return
    }

    let mode = MODES.PUBLISHED,
        features = null,
        projectId = null,
        projectNumericId = null,
        isSubscriptionExpired = false,
        projectMode = null,
        subscriptionProductCode = null

    try {
        const meta = await API.getProjectMetaInfo(hash)

        features = meta.features
        projectId = meta.projectId
        projectNumericId = meta.id
        isSubscriptionExpired = meta.subscriptionExpired
        projectMode = meta.mode
        subscriptionProductCode = meta.subscriptionProductCode
    } catch (err) {
        mode = MODES.EMERGENCY
        console.warn(
            `[RemixLoader auto-initiator] Cannot get project meta information from server, ${MODES.EMERGENCY} mode activated`,
        )
    }

    const remixApp = new window.RemixLoader({
        mode,
        projectMode,
        nodeElement: element,
        remixUrl: `${CDN_URL}/${hash}/index.html`,
        // remixUrl: `http://localhost:8090/remix.html`,
        // projectStructure: ``,
        hash,
        features,
        projectId,
        projectNumericId,
        initialWidth,
        initialHeight,
        lng: lng || null,
        isSubscriptionExpired,
        subscriptionProductCode,
    })
    await remixApp.createIframe()

    try {
        if (!window.RemixApps) window.RemixApps = []
        window.RemixApps.push(remixApp)
    } catch (err) {
        console.error(err)
    }
})()
