import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

export const REFRESH_MODES = {
    DEBOUNCE: 'debounce',
    THROTTLE: 'throttle',
}

const patchResizeCallback = (resizeCallback, refreshMode, refreshRate, refreshOptions) => {
    switch (refreshMode) {
        case REFRESH_MODES.DEBOUNCE:
            return debounce(resizeCallback, refreshRate, refreshOptions)
        case REFRESH_MODES.THROTTLE:
            return throttle(resizeCallback, refreshRate, refreshOptions)
        default:
            return resizeCallback
    }
}

function useSizeDetector({
    skipOnMount = false,
    refreshMode,
    refreshRate = 1000,
    refreshOptions,
    handleWidth = true,
    handleHeight = true,
    targetRef,
    observerOptions,
    onResize,
} = {}) {
    const skipResize = useRef(skipOnMount)

    const [size, setSize] = useState({
        width: undefined,
        height: undefined,
    })

    const [refElement, setRefElement] = useState(targetRef?.current || null)

    if (targetRef) {
        setTimeout(() => {
            if (targetRef.current !== refElement) {
                setRefElement(targetRef.current)
            }
        }, 0)
    }

    const refProxy = useMemo(
        () =>
            new Proxy(
                node => {
                    if (node !== refElement) {
                        setRefElement(node)
                    }
                },
                {
                    get(target, prop) {
                        if (prop === 'current') {
                            return refElement
                        }
                        return target[prop]
                    },
                    set(target, prop, value) {
                        if (prop === 'current') {
                            setRefElement(value)
                        } else {
                            target[prop] = value
                        }
                        return true
                    },
                },
            ),
        [refElement],
    )

    const shouldSetSize = useCallback(
        (prevSize, nextSize) => {
            if (prevSize.width === nextSize.width && prevSize.height === nextSize.height) {
                return false
            }

            if (
                (prevSize.width === nextSize.width && !handleHeight) ||
                (prevSize.height === nextSize.height && !handleWidth)
            ) {
                return false
            }

            return true
        },
        [handleWidth, handleHeight],
    )

    const resizeCallback = useCallback(
        entries => {
            if (!handleWidth && !handleHeight) return

            if (skipResize.current) {
                skipResize.current = false
                return
            }

            entries.forEach(entry => {
                const { width, height } = entry?.contentRect || {}
                setSize(prevSize => {
                    if (!shouldSetSize(prevSize, { width, height })) return prevSize
                    return { width, height }
                })
            })
        },
        [handleWidth, handleHeight, skipResize, shouldSetSize],
    )

    const resizeHandler = useCallback(patchResizeCallback(resizeCallback, refreshMode, refreshRate, refreshOptions), [
        resizeCallback,
        refreshMode,
        refreshRate,
        refreshOptions,
    ])

    useEffect(() => {
        let resizeObserver
        if (refElement) {
            resizeObserver = new window.ResizeObserver(resizeHandler)
            resizeObserver.observe(refElement, observerOptions)
        } else {
            if (size.width || size.height) {
                setSize({ width: undefined, height: undefined })
            }
        }

        return () => {
            resizeObserver?.disconnect?.()
            resizeHandler.cancel?.()
        }
    }, [resizeHandler, refElement])

    useEffect(() => {
        onResize?.(size.width, size.height)
    }, [size])

    return { ref: refProxy, ...size }
}

export default useSizeDetector
