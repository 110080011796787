import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import { FinalScreenTab } from 'components/ModalTabs/FinalScreenTab'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import Playground from './views/Playground/Playground'
import Pairs from './views/Pairs/Pairs'

import {
    INIT_STRUCTURE,
    INIT_VALIDATE_STRUCTURE,
    PAIRS_FIELDS,
    TABS,
    TABS_ARRAY,
    VALIDATE_RULES,
} from './utils/constants'
import { memoryProjectGetData, updateValidateRules, validateFields } from './utils/utils'

import './MemoryCardsModal.scss'

const MemoryCardsModal = ({ methods, data: { structure, colorTheme, id }, modalTitle }) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(cloneDeep(INIT_STRUCTURE))
    const [activeTab, setActiveTab] = useState(TABS.playground)
    const [validateErrors, setValidateErrors] = useState(cloneDeep(INIT_VALIDATE_STRUCTURE))

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useEffect(() => {
        if (Object.keys(structure).length) {
            setTmpStructure(cloneDeep(structure))
        }
    }, [structure])

    useEffect(() => {
        if (tmpStructure.playground.cardLayout && Object.keys(tmpStructure.playground.cardLayout).length) {
            const { pairList } = tmpStructure.pairs
            const [cells, rows] = tmpStructure.playground.cardLayout.value.split('x').map(x => Number(x))
            const pairsCount = (rows * cells) / 2

            if (pairList.length === 0) {
                onUpdateStructure('pairs')({
                    [PAIRS_FIELDS.pairList]: memoryProjectGetData(pairsCount - pairList.length),
                })
            } else if (pairList.length < pairsCount) {
                onUpdateStructure('pairs')({
                    [PAIRS_FIELDS.pairList]: [...pairList, ...memoryProjectGetData(pairsCount - pairList.length)],
                })
            }
        }
    }, [tmpStructure.playground.cardLayout])

    const onSave = async () => {
        try {
            const validationResult = validateStructure(activeTab)
            if (!validationResult.hasError) {
                await methods.save({ [id]: { data: { struct: tmpStructure } } })
                onClose()
            } else {
                setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const onClose = () => {
        methods.closeModal()
    }

    const onUpdateStructure = type => newFields => {
        setTmpStructure(prevState => {
            return {
                ...prevState,
                [type]: {
                    ...prevState[type],
                    ...newFields,
                },
            }
        })
    }

    const validateStructure = activeTabId => {
        const validateErr = validateFields(tmpStructure, updateValidateRules(tmpStructure, VALIDATE_RULES, activeTabId))
        setValidateErrors(validateErr)
        return validateErr
    }

    const onChangeTab = useCallback(
        tabId => {
            const validateErr = validateStructure(activeTab)
            if (!validateErr.hasError) setActiveTab(tabId)
            else setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={modalTitle} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'MEMORY_GAMES',
                }}
                onSave={onSave}
            >
                <div className="memory-modal-wrapper">
                    <div className="memory-modal-wrapper__body">
                        {activeTab === TABS.playground && (
                            <Playground
                                t={t}
                                colorTheme={colorTheme}
                                structure={tmpStructure}
                                validateErrors={validateErrors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('playground') }}
                            />
                        )}
                        {activeTab === TABS.pairs && (
                            <Pairs
                                t={t}
                                colorTheme={colorTheme}
                                structure={tmpStructure}
                                validateErrors={validateErrors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('pairs') }}
                            />
                        )}
                        {activeTab === TABS.finalScreen && (
                            <FinalScreenTab
                                structure={tmpStructure}
                                validateErrors={validateErrors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('finalScreen') }}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}

export default MemoryCardsModal
