import React, { useEffect, useState } from 'react'

import ACTIONS from 'shared/constants/actions'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextInput from 'components/Form/TextInput/TextInput'

import StopPanels, { useStopPanes } from '../../Blocks/components/Modals/components/StopPanels/StopPanels'

import { SCHEMA_KEY } from '../../schema'

import ymIcon from './i/ym.svg'

import { INFO } from './constants'
import { getInitialYandexMetricStructure } from './utils'

import styles from './YandexMetricModal.module.scss'

const YandexMetricModal = ({ onClose, data, onUpdate }) => {
    const [actions, setActions] = useState({})

    useEffect(() => {
        const validStruct = getInitialYandexMetricStructure(data.blockType)
        const formattedActions = Object.keys(validStruct).reduce(
            (acc, action) => ({ ...acc, [action]: data.value[action] || '' }),
            {},
        )
        setActions(formattedActions)
        onUpdate({
            [data.blockId]: {
                data: { [SCHEMA_KEY.yandexMetric]: formattedActions },
            },
        })
    }, [])

    const { isShowQuitAccept, onShowQuitAccept, validationWarningType, onClosePanels } = useStopPanes()

    const onSave = async () => {
        onUpdate({
            [data.blockId]: {
                data: { [SCHEMA_KEY.yandexMetric]: actions },
            },
        })
        onClose()
    }

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title="Интеграция с Яндекс Метрикой" onClose={onShowQuitAccept} />
            <Body onSave={onSave}>
                <div className={styles.customEventsModalWrapper}>
                    <div className={styles.body}>
                        <div className={styles.logo}>
                            <img src={ymIcon} alt="yandex metric" />
                        </div>
                        <div className={styles.content}>
                            <ScrollableBlock>
                                <div className={styles.scrollableContent}>
                                    <h4 className={styles.contentTitle}>События и цели</h4>
                                    <ul className={styles.tableHeadCols}>
                                        <li className={styles.tableHeadCol}>Событие</li>
                                        <li className={styles.tableHeadCol}>Идентификатор цели</li>
                                        <li className={styles.tableHeadCol}>Описание</li>
                                    </ul>
                                    <ul className={styles.tableRows}>
                                        {Object.keys(ACTIONS)
                                            .filter(item => !!Object.keys(actions).includes(item))
                                            .map(action => (
                                                <li key={action} className={styles.tableRow}>
                                                    <div className={styles.tableCol}>{INFO[action].label}</div>
                                                    <div className={styles.tableCol}>
                                                        <TextInput
                                                            fullWide
                                                            value={actions[action]}
                                                            onUpdate={v =>
                                                                setActions(prevState => ({ ...prevState, [action]: v }))
                                                            }
                                                        />
                                                    </div>
                                                    <div className={styles.tableCol}>{INFO[action].description}</div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </ScrollableBlock>
                        </div>
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}

export default YandexMetricModal
