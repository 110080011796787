import React from 'react'

const DropDownArrowIcon = props => {
    return (
        <svg
            {...props.props}
            className={`material-icons ${props.props.className}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 11L7.64645 11.3536C7.84171 11.5488 8.15829 11.5488 8.35355 11.3536L8 11ZM3.35355 5.64645C3.15829 5.45118 2.84171 5.45118 2.64645 5.64645C2.45118 5.84171 2.45118 6.15829 2.64645 6.35355L3.35355 5.64645ZM13.3536 6.35355C13.5488 6.15829 13.5488 5.84171 13.3536 5.64645C13.1583 5.45118 12.8417 5.45118 12.6464 5.64645L13.3536 6.35355ZM8.35355 10.6464L3.35355 5.64645L2.64645 6.35355L7.64645 11.3536L8.35355 10.6464ZM12.6464 5.64645L7.64645 10.6464L8.35355 11.3536L13.3536 6.35355L12.6464 5.64645Z"
                fill="#5F5F5F"
            />
        </svg>
    )
}

export default DropDownArrowIcon
