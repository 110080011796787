import { DEFAULT_IMAGE_URL, DEFAULT_IMAGE_BG_BIG_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'

export class QuizCover {
    header // <String> text
    description // <String> result description
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer
    buttonText // <String>
    isShowCover // <Boolean>

    constructor({
        header = '',
        description = '',
        image = DEFAULT_IMAGE_URL,
        imageDisclaimer = '',
        buttonText = '',
        isShowCover = true,
    } = {}) {
        this.header = header
        this.description = description
        this.image = image
        this.imageDisclaimer = imageDisclaimer
        this.buttonText = buttonText
        this.isShowCover = isShowCover
    }

    static get header() {
        return 'header'
    }

    static get description() {
        return 'description'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }

    static get buttonText() {
        return 'buttonText'
    }

    static get isShowCover() {
        return 'isShowCover'
    }
}

export class QuizResult {
    id // <String> id
    header // <String> text
    description // <String> result description
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer
    buttonText // <String> text button
    buttonLink // <String> text link

    constructor({
        header = '',
        description = '',
        image = DEFAULT_IMAGE_BG_BIG_URL,
        imageDisclaimer = '',
        buttonText = '',
        buttonLink = '',
    } = {}) {
        this.id = getUniqueId()
        this.header = header
        this.description = description
        this.image = image
        this.imageDisclaimer = imageDisclaimer
        this.buttonText = buttonText
        this.buttonLink = buttonLink
    }

    static get header() {
        return 'header'
    }

    static get description() {
        return 'description'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }

    static get buttonText() {
        return 'buttonText'
    }

    static get buttonLink() {
        return 'buttonLink'
    }
}
