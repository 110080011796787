import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import TextArea from 'components/Form/TextArea/TextArea'
import { getUniqueId } from 'common/utils/getUniqueId'

import invertColor from '../../../util/invertColor'
import { CARD_TYPES } from '../Modals/MemoryCardsModal/utils/constants'

import BlockTitle from './components/BlockTitle/BlockTitle'
import DefaultPreview from './components/Preview/DefaultPreview'
import MatchingPreview from './components/Preview/MatchingPreview'
import ColorBlock from './components/ColorBlock/ColorBlock'

import { CARD_BACKGROUND_COLOR } from './components/ColorBlock/constants'

import { TYPES, SETTINGS } from './constants'

import './EditorCardTextModal.scss'

const MAX_TEXT_LENGTH = 70

const EditorCardTextModal = ({
    type = TYPES.DEFAULT,
    text = '',
    bgColor = CARD_BACKGROUND_COLOR[0],
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const [value, setValue] = useState(text)
    const [selectedBackground, setSelectedBackground] = useState(bgColor)

    const [isCanSave, setIsCanSave] = useState(false)

    useEffect(() => {
        const length = value.trim().length
        if (length > 0 && length <= MAX_TEXT_LENGTH) setIsCanSave(true)
        else setIsCanSave(false)
    }, [value])

    const onSave = () => {
        if (!isCanSave) return
        onSubmit({
            id: getUniqueId(),
            cardType: CARD_TYPES.text,
            text: value.trim(),
            bgColor: selectedBackground,
            color: SETTINGS[type].color || invertColor(selectedBackground, true),
        })
    }

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <div className="editor-card-text">
                <h2 className="editor-card-text__header">{t('Text on card')}</h2>
                <div className="editor-card-text__body">
                    <div className="editor-card-text__body-content">
                        <div className="editor-card-text__body-content-block">
                            <BlockTitle text={t('Text on card')} length={value.length} maxLength={MAX_TEXT_LENGTH} />
                            <TextArea
                                isAutoSize={false}
                                value={value}
                                onUpdate={_value => setValue(_value)}
                                wrapperStyles={{ padding: 0 }}
                                styles={{ height: SETTINGS[type].areaHeight || 70 }}
                                maxLength={MAX_TEXT_LENGTH}
                            />
                        </div>

                        {type === TYPES.DEFAULT && (
                            <div className="editor-card-text__body-content-block">
                                <ColorBlock
                                    backgroundColor={selectedBackground}
                                    onChange={color => setSelectedBackground(color)}
                                />
                            </div>
                        )}
                    </div>

                    {type === TYPES.DEFAULT && <DefaultPreview backgroundColor={selectedBackground} text={value} />}
                    {type === TYPES.MATCHING && <MatchingPreview backgroundColor={selectedBackground} text={value} />}
                </div>
                <div className="editor-card-text__buttons">
                    <button className="button" data-variant="secondary" data-size="lg" onClick={() => onClose()}>
                        {t('Cancel')}
                    </button>
                    <button
                        className="button"
                        data-variant="primary"
                        data-size="lg"
                        disabled={!isCanSave}
                        onClick={() => onSave()}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EditorCardTextModal
