import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './StatisticPreview.module.scss'

const StatisticPreview = ({ actionsTitle, maxWidth = '100%' }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.statisticPreview} style={{ maxWidth }}>
            <div>
                <span className={styles.title}>{actionsTitle || t('Number of actions')}:</span>
                <span>0</span>
            </div>
            <div>
                <div className={styles.timer}>00:00</div>
            </div>
        </div>
    )
}

export default StatisticPreview
