import { getMultiplayerBlockInPage } from 'utils/multiplayer'

export const getConstraint = (constraints, featureType) => constraints[featureType] || null

const getMultiplayerRoundsData = (pages, maxRoundsTimeArray) => {
    const rounds = []

    pages.forEach((page, index) => {
        const multiplayerBlock = getMultiplayerBlockInPage(page)

        rounds.push({
            pageId: page.id,
            maxRoundTime: maxRoundsTimeArray[index] * 1000,
            scorable: multiplayerBlock
                ? {
                      blockId: multiplayerBlock.id,
                      blockTypeId: multiplayerBlock.t,
                  }
                : null,
        })
    })

    return rounds
}

export const getMultiplayerGameData = ({
    projectId,
    name,
    isHasLeader = false,
    playersCount,
    projectStructureJson,
    maxRoundResultTime = 10,
    maxRoundsTimeArray = [],
    isStartNow,
}) => ({
    projectId,
    name,
    isHasLeader,
    playersCount,
    maxRoundResultTime: maxRoundResultTime * 1000,
    rounds: getMultiplayerRoundsData(projectStructureJson.pages, maxRoundsTimeArray),
    isStartNow,
})
