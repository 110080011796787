import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useInitialLoading from 'hooks/useInitialLoading'
import useUserSubscriptions from "hooks/useUserSubscriptions"
import useModalManager from 'hooks/useModalManager'

import { API__USER } from 'api'

import {getConstraintLimit, isConstraintUnlimited, isEducation} from 'common/utils/permissions'
import {FEATURE_TYPES} from "common/constants/subscription"

import { USER__SET_SUBSCRIPTIONS, USER__SET_PUBLIC_PROFILE } from 'store/actions'

import {MODALS} from "components/ModalManager/constants"

const UserInitializer = ({ userId, children }) => {
    const { isReady, onReady } = useInitialLoading()
    const { constraints } = useUserSubscriptions()
    const dispatch = useDispatch()
    const { openModal } = useModalManager()

    useEffect(() => {
        if (!constraints) return
        const reviewCountConstraint = constraints[FEATURE_TYPES.REVIEWS_COUNT]
        if (!reviewCountConstraint || isConstraintUnlimited(reviewCountConstraint)) return
        if (reviewCountConstraint.spent >= getConstraintLimit(reviewCountConstraint)) {
            openModal(MODALS.VIEWS_LIMITS_MODAL)
        }
    }, [constraints])

    useEffect(() => {
        const fetch = async () => {
            await Promise.all([getUserSubscriptions(), getUserPublicProfile()])
            onReady()
        }
        fetch()
    }, [userId])

    const getUserSubscriptions = async () => {
        try {
            const response = await API__USER.GET_SUBSCRIPTIONS(userId)
            await dispatch(USER__SET_SUBSCRIPTIONS(response))
        } catch (err) {
            console.error(err)
        }
    }
    const getUserPublicProfile = async () => {
        try {
            if (!isEducation()) return
            const response = await API__USER.GET_PUBLIC_PROFILE(userId)
            await dispatch(USER__SET_PUBLIC_PROFILE(response))
        } catch (err) {
            console.error(err)
        }
    }

    if (!isReady) return null

    return children
}

export default UserInitializer
