import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import { MODE } from '../../../../constants'

import styles from './Header.module.scss'

const Header = ({ title, elementsCount, isValid, onSave, isLoading, name, onChangeName, errors, mode }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.header}>
            <div className={styles.box}>
                <div className={styles.texts}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.number}>
                        {t('Number of questions')}: {elementsCount}
                    </div>
                </div>
                {mode !== MODE.VIEW && (
                    <div className={styles.actions}>
                        <div className={classNames(styles.notice, { [styles.noticeError]: !isValid })}>
                            {isValid ? t('It’s good to go!') : t('Correct errors before proceeding')}
                        </div>
                        <button
                            className={classNames('button', styles.button)}
                            data-variant="primary"
                            data-size="lg"
                            disabled={!isValid || isLoading}
                            onClick={onSave}
                        >
                            {t('Save')}
                        </button>
                    </div>
                )}
            </div>
            {mode !== MODE.VIEW && (
                <div className={styles.name}>
                    <TextInput
                        placeholder={t('Bank name')}
                        value={name}
                        errorMessages={!!errors.name?.text && [errors.name.text]}
                        onUpdate={v => onChangeName(v)}
                        className={styles.textInput}
                        maxLength={80}
                    />
                </div>
            )}
        </div>
    )
}

export default Header
