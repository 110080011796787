import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { isHasActiveProjectSubscription } from 'common/utils/permissions'

import Upload from 'components/Form/Upload/Upload'
import Toast from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog'

import {
    PARSE_FILE,
    CREATE_BANK,
    GET_BANK_QUESTIONS,
    GET_BANKS,
    DELETE_BANK,
    UPDATE_BANK_QUESTIONS,
} from 'api/questionsBanks'

import EditPlayground from './components/EditPlayground/EditPlayground'
import BanksList from './components/BanksList/BanksList'
import Instruction from './components/Instruction/Instruction'
import { getDefaultQuestion } from './components/EditPlayground/components/Question/utils'

import { validate } from './utils'

import { MODE, TITLE } from './constants'

import plusIcon from './i/plus.svg'

import styles from './QuestionBank.module.scss'

const QuestionBank = () => {
    const { t } = useTranslation()
    const uploadRef = useRef(null)
    const history = useHistory()

    const {
        organizations: { selectedSubscription },
    } = useSelector(state => ({
        organizations: state.organizations,
    }))

    const [isLoadingList, setIsLoadingList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isShowPlayground, setIsShowPlayground] = useState(false)
    const [list, setList] = useState([])
    const [publicList, setPublicList] = useState([])
    const [mode, setMode] = useState(null)
    const [bankId, setBankId] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [errors, setErrors] = useState({})
    const [_name, setName] = useState('')
    const [_questions, setQuestions] = useState([])
    const [removedQuestionsIds, setRemovedQuestionsIds] = useState([])
    const [bankToDelete, setBankToDelete] = useState(null)
    const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState(false)

    useEffect(() => {
        getBanks()
        getPublicBanks()
    }, [])
    useEffect(() => {
        const validationResult = validate({
            name: _name,
            questions: _questions,
        })
        setErrors(validationResult.errors)
        setIsValid(validationResult.isValid)
    }, [_name, _questions])

    const getBanks = async () => {
        try {
            setIsLoadingList(true)
            const { content } = await GET_BANKS()
            setList(content)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoadingList(false)
        }
    }
    const getPublicBanks = async () => {
        try {
            const { content } = await GET_BANKS(true)
            setPublicList(content)
        } catch (err) {
            console.error(err)
        }
    }

    const onDelete = async () => {
        try {
            setIsLoading(true)
            await DELETE_BANK(bankToDelete.bankId)
            await getBanks()
            setBankToDelete(null)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }
    const onUpload = async file => {
        try {
            setIsLoading(true)
            const data = await PARSE_FILE(file[0])
            onOpenEditPlayground(t('My question bank'), data.questions, MODE.CREATE_FROM_FILE)
        } catch (err) {
            console.error(err)
            Toast('error', {
                message: t(
                    'It looks like your file structure is incorrect. Please make sure that the format of the downloaded file follows the instructions',
                ),
                time: 8000,
            })
        } finally {
            setIsLoading(false)
        }
    }
    const onSave = async () => {
        if (mode === MODE.CREATE_FROM_FILE || mode === MODE.CREATE_NEW) await onCreate()
        if (mode === MODE.EDIT) await onUpdate()
    }
    const onCreate = async () => {
        try {
            setIsLoading(true)
            await CREATE_BANK(_name.trim(), _questions)
            onCloseEditPlayground()
            await getBanks()
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onUpdate = async () => {
        try {
            setIsLoading(true)
            await UPDATE_BANK_QUESTIONS(bankId, _name.trim(), _questions, removedQuestionsIds)
            onCloseEditPlayground()
            await getBanks()
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onOpen = async (bank, isPublic = false) => {
        try {
            setIsLoading(true)
            if (selectedSubscription.expired && !isHasActiveProjectSubscription()) {
                setIsOpenUpgradeModal(true)
                return
            }
            const { content } = await GET_BANK_QUESTIONS(bank.bankId)
            onOpenEditPlayground(bank.name, content, isPublic ? MODE.VIEW : MODE.EDIT, bank.bankId)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onOpenNew = () => {
        onOpenEditPlayground(t('My question bank'), [getDefaultQuestion()], MODE.CREATE_NEW)
    }

    const onOpenEditPlayground = (name, questions, mode, bankId = null) => {
        setIsShowPlayground(true)
        setMode(mode)
        setName(name)
        setQuestions(questions)
        setBankId(bankId)
        setRemovedQuestionsIds([])
    }
    const onCloseEditPlayground = () => {
        setIsShowPlayground(false)
        setMode(mode)
        setName('')
        setQuestions([])
        setBankId(null)
        setRemovedQuestionsIds([])
    }

    return (
        <>
            <div className={styles.questionBank}>
                <div className={styles.head}>
                    <div className={styles.title}>{t('Question banks')}</div>
                    <div className={styles.buttons}>
                        <button
                            className={classNames('button', styles.createButton)}
                            data-variant="primary"
                            onClick={() => onOpenNew()}
                        >
                            {t('Create')}
                        </button>
                        <div className={styles.uploadButton}>
                            <Upload
                                className={styles.uploadButtonLabel}
                                uploadRef={uploadRef}
                                onUpload={onUpload}
                                onClick={evt => {
                                    if (selectedSubscription.expired && !isHasActiveProjectSubscription()) {
                                        evt.preventDefault()
                                        setIsOpenUpgradeModal(true)
                                    }
                                }}
                                accept=".csv,.xlsx"
                            >
                                <img src={plusIcon} alt="plus" className={styles.uploadButtonIcon} />{' '}
                                {t('Upload CSV / XLSX')}
                            </Upload>
                        </div>
                    </div>
                </div>
                <div className={styles.workplace}>
                    <div className={styles.banks}>
                        {!!publicList.length && (
                            <div className={styles.banksGroup}>
                                <h3 className={styles.banksGroupTitle}>{t('Default banks')}</h3>
                                <BanksList list={publicList} onOpen={onOpen} isPublic />
                            </div>
                        )}
                        <div className={styles.banksGroup}>
                            {!!list.length && <h3 className={styles.banksGroupTitle}>{t('Your banks')}</h3>}
                            <BanksList
                                list={list}
                                onOpen={onOpen}
                                onDelete={bank => {
                                    if (selectedSubscription.expired && !isHasActiveProjectSubscription()) {
                                        setIsOpenUpgradeModal(true)
                                        return
                                    }
                                    setBankToDelete(bank)
                                }}
                                isLoadingList={isLoadingList}
                                onUpload={() => {
                                    if (selectedSubscription.expired && !isHasActiveProjectSubscription()) {
                                        setIsOpenUpgradeModal(true)
                                        return
                                    }
                                    uploadRef.current.click()
                                }}
                                onOpenNew={() => onOpenNew()}
                            />
                        </div>
                    </div>
                    <Instruction onOpenNew={() => onOpenNew()} />
                </div>

                {isShowPlayground && (
                    <EditPlayground
                        mode={mode}
                        title={mode === MODE.VIEW ? _name : TITLE[mode]}
                        errors={errors}
                        isValid={isValid}
                        data={{ questions: _questions, name: _name }}
                        onUpdate={(_q, removed) => {
                            setQuestions(_q)
                            if (!removed) return
                            const arr = removed.filter(el => !!el.id).map(el => el.id)
                            if (arr.length) setRemovedQuestionsIds([...removedQuestionsIds, ...arr])
                        }}
                        onSave={onSave}
                        isLoading={isLoading}
                        onChangeName={v => setName(v)}
                        onClose={() => onCloseEditPlayground()}
                    />
                )}

                {bankToDelete && (
                    <ConfirmationDialog
                        isLoading={isLoading}
                        onClose={() => setBankToDelete(null)}
                        onAction={() => onDelete()}
                        data={{
                            headText: t('Are you sure you want to delete this question bank?'),
                            noteText: bankToDelete.name,
                            actionVariant: 'primary-danger',
                            actionText: t('Delete'),
                            cancelText: t('Cancel'),
                        }}
                    />
                )}
                {isOpenUpgradeModal && (
                    <UpgradeDialog
                        onClose={() => setIsOpenUpgradeModal(false)}
                        onUpgrade={() => history.push('/billing-and-payments?tab=subscription')}
                    />
                )}
            </div>
        </>
    )
}

export default QuestionBank
