import React, { useEffect, useState } from 'react'
import { API__ANALYTICS } from 'api'

import ComponentsList from '../../../../components/ComponentsList/ComponentsList'
import BlockBaseStatistic from '../../components/BlockBaseStatistic/BlockBaseStatistic'

import Statistics from './components/Statistics/Statistics'

const RankBattle = ({ projectId, blockProjection, dateRange }) => {
    const [statistic, setStatistic] = useState(null)
    const [isReady, setIsReady] = useState(false)

    const fetchData = () => {
        API__ANALYTICS.GET_RANK_BATTLE_STATISTICS({
            projectId: projectId,
            blockId: blockProjection.blockId,
            pagination: {
                from: dateRange.from,
                to: dateRange.to,
            },
        })
            .then(data => {
                setStatistic(data)
                setIsReady(true)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (dateRange) fetchData()
    }, [dateRange, blockProjection])

    return (
        <ComponentsList>
            <BlockBaseStatistic projectId={projectId} blockId={blockProjection.blockId} dateRange={dateRange} />
            {isReady && <Statistics statistic={statistic} blockProjection={blockProjection} />}
        </ComponentsList>
    )
}

export default RankBattle
