import React, { useState } from 'react'

import SlidingPuzzleModal from '../components/Modals/SlidingPuzzleModal/SlidingPuzzleModal'

import StatisticPreview from '../components/StatisticPreview/StatisticPreview'
import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

import Preview from './components/Preview/Preview'

import { MAX_WORKPLACE_WIDTH } from './components/Preview/components/Playground/constants'

import styles from './SlidingPuzzle.module.scss'

const SlidingPuzzle = ({
    struct,
    enableTimer,
    colorTheme,
    correctColor,
    isHighlightCorrect,
    fontFamily,
    onUpdate,
    id,
}) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)} className={styles.modalEditedBlock}>
            {enableTimer && <StatisticPreview maxWidth={MAX_WORKPLACE_WIDTH} />}
            <Preview
                tilesCount={struct.playground.tilesCount}
                gameType={struct.playground.gameType}
                imageUrl={struct.playground.imageUrl}
                isShowImageNumbers={struct.playground.isShowImageNumbers}
                colorTheme={colorTheme}
                correctColor={correctColor}
                isHighlightCorrect={isHighlightCorrect}
                fontFamily={fontFamily}
                isShowCover={struct.playground.isShowCover}
                coverHeader={struct.playground.coverHeader}
                coverButtonText={struct.playground.coverButtonText}
            />

            {isShowModal && (
                <SlidingPuzzleModal
                    data={{
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default SlidingPuzzle
