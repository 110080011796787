import { cloneDeep, isEmpty } from 'lodash'
import React, { createRef } from 'react'
import { withTranslation } from 'react-i18next'
import { clearObjectErrors } from 'shared/validation'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Footer from 'components/Modal/components/Footer/Footer'
import Header from 'components/Modal/components/Header/Header'
import Modal from 'components/Modal/Modal'

import { scrollContainerToElementById } from 'utils/scrollContainerToElementById'

import AddListItemButton from '../../Controls/AddListItemButton/AddListItemButton'
import SlideCard from './SlideCard'

import { collectSlidesErrors, isValidSlidesBlock, MAX_SLIDES, Slide } from './slideService'
import './SlideshowModal.scss'

class SlideshowModal extends React.Component {
    constructor(props) {
        super(props)
        let tmpStructure = cloneDeep(props.data.structure)
        this.state = {
            tmpStructure: tmpStructure,
            slidesErrors: new Map(),
        }
        this.scrollableSlideContentNodeRef = createRef()
    }

    scrollContainerToSlide = id => {
        scrollContainerToElementById(this.scrollableSlideContentNodeRef, `item_${id}`)
    }

    onSave = async () => {
        const {
            methods: { save, closeModal },
            data: { id },
        } = this.props
        const { tmpStructure } = this.state

        const isValid = isValidSlidesBlock(tmpStructure)

        if (!isValid) {
            const slidesErrors = collectSlidesErrors(tmpStructure)
            this.setState({ slidesErrors: slidesErrors.slides })
        } else await save({ [id]: { data: { struct: tmpStructure } } })

        if (isValid) closeModal()
    }

    onAddSlide = async () => {
        const newSlide = new Slide({})
        await this.setState(prevState => {
            const { tmpStructure } = prevState
            const slides = tmpStructure.slides.concat(newSlide)
            return { ...prevState, tmpStructure: { ...tmpStructure, slides } }
        })
        this.scrollContainerToSlide(newSlide.id)
    }

    onCloneSlide = async slideId => {
        let clonedSlide = null
        await this.setState(prevState => {
            const { tmpStructure } = prevState
            let sourceSlide = tmpStructure.slides.find(slide => slide.id === slideId)
            clonedSlide = new Slide(sourceSlide)
            const slides = tmpStructure.slides.concat(clonedSlide)
            return { ...prevState, tmpStructure: { ...tmpStructure, slides } }
        })
        this.scrollContainerToSlide(clonedSlide.id)
    }

    onRemoveSlide = slideId => {
        this.setState(prevState => {
            const { tmpStructure } = prevState
            const slides = tmpStructure.slides.filter(item => slideId !== item.id)
            return { ...prevState, tmpStructure: { ...tmpStructure, slides } }
        })
    }

    onUpdateSlide = (itemId, newFields) => {
        this.setState(prevState => {
            const { tmpStructure, slidesErrors } = prevState
            const clonedSlidesErrors = cloneDeep(slidesErrors)
            let slideErrors = clonedSlidesErrors.get(itemId)
            if (slideErrors) {
                clearObjectErrors(slideErrors, Object.keys(newFields))
                const slideIsValid = Object.values(slideErrors).some(val => isEmpty(val))
                if (slideIsValid) clonedSlidesErrors.delete(itemId)
            }
            return {
                ...prevState,
                tmpStructure: {
                    ...tmpStructure,
                    slides: tmpStructure.slides.map(item => (itemId === item.id ? { ...item, ...newFields } : item)),
                },
                slidesErrors: clonedSlidesErrors,
            }
        })
    }

    onChangeSlidePosition = (currentIndex, newIndex) => {
        this.setState(prevState => {
            const { tmpStructure } = prevState
            const slides = cloneDeep(tmpStructure.slides)
            const movingSlide = slides[currentIndex]
            slides[currentIndex] = slides[newIndex]
            slides[newIndex] = movingSlide
            return { ...prevState, tmpStructure: { ...tmpStructure, slides } }
        })
    }

    render() {
        const {
            tmpStructure: { slides },
            slidesErrors,
        } = this.state
        const {
            t,
            methods: { closeModal },
        } = this.props
        const isAllowedAdd = slides.length < MAX_SLIDES

        return (
            <Modal onClose={closeModal}>
                <Header title={t('Slideshow')} onClose={closeModal} />
                <div className="slideshow-modal-wrapper">
                    <div className="slideshow-modal-wrapper__body">
                        <ScrollableBlock scrollableNodeRef={this.scrollableSlideContentNodeRef}>
                            <div className="scrollbar-inner">
                                <ul>
                                    {slides.map((slide, index) => (
                                        <li key={index} id={`item_${slide.id}`}>
                                            <SlideCard
                                                slide={slide}
                                                slideError={slidesErrors && slidesErrors.get(slide.id)}
                                                index={index}
                                                count={slides.length}
                                                t={t}
                                                methods={{
                                                    onChangePosition: this.onChangeSlidePosition,
                                                    cloneSlide: this.onCloneSlide,
                                                    removeSlide: this.onRemoveSlide,
                                                    updateSlide: this.onUpdateSlide,
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                {isAllowedAdd ? (
                                    <AddListItemButton length={slides.length} onClick={this.onAddSlide} />
                                ) : null}
                            </div>
                        </ScrollableBlock>
                    </div>
                </div>
                <Footer actionText={t('Save')} cancelText={t('Cancel')} onClose={closeModal} onAction={this.onSave} />
            </Modal>
        )
    }
}

export default withTranslation('translations')(SlideshowModal)
