import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { buildProjectUrl } from 'common/utils/project'

import Button from 'components/Form/Button/Button'

import UrlBlock from './components/UrlBlock/UrlBlock'
import PinBlock from './components/PinBlock/PinBlock'
import QrBlock from './components/QrBlock/QrBlock'

import refreshIcon from './i/refresh.svg'

import styles from './Inspection.module.scss'

const Inspection = ({ project, activeGame, onGoToCreation }) => {
    const { t } = useTranslation()

    const [masterUrl, setMasterUrl] = useState('')
    const [publicUrl, setPublicUrl] = useState('')

    useEffect(() => {
        setMasterUrl(
            `${buildProjectUrl(project.customUrl, project.projectVersions[0].versionHash)}?pin=${
                activeGame.pin
            }&masterPin=${activeGame.masterPin}`,
        )
        setPublicUrl(
            `${buildProjectUrl(project.customUrl, project.projectVersions[0].versionHash)}?pin=${activeGame.pin}`,
        )
    }, [project, activeGame])

    return (
        <div className={styles.inspection}>
            <UrlBlock
                url={publicUrl}
                masterUrl={masterUrl}
                title={t('Direct link to the game event for participants')}
                isHost={!!activeGame.isHasLeader}
            />
            <div className={styles.box}>
                <PinBlock pin={activeGame.pin} />
                <QrBlock url={publicUrl} />
            </div>
            <div className={styles.buttons}>
                <Button
                    className={styles.newGameButton}
                    backgroundColor="transparent"
                    borderColor={'#2990FB'}
                    color={'#2990FB'}
                    onClick={onGoToCreation}
                >
                    <img src={refreshIcon} alt="" />
                    {t('Set up new game event')}
                </Button>
            </div>
        </div>
    )
}

export default Inspection
