import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal/Modal'
import styles from './MoveModal.module.scss'

export const ALL_PROJECTS_ID = 'null'

function MoveModal({ title, value, menuItems, onChange, onMove, isHideAllProjectsItem, onClose }) {
    const { t } = useTranslation()

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={true} closeOnEsc={true} onClose={onClose}>
            <div className={styles.moveFolderModal}>
                <div className={styles.title}>{title}</div>
                <Select
                    MenuProps={{ style: { zIndex: 99999999999999 } }}
                    className={styles.select}
                    value={value}
                    fullWidth
                    onChange={onChange}
                >
                    {!isHideAllProjectsItem && <MenuItem value={ALL_PROJECTS_ID}>{t('All projects')}</MenuItem>}
                    {menuItems}
                </Select>
                <div className={styles.buttonGroup}>
                    <button className="button" data-variant="secondary" data-size="lg" onClick={onClose}>
                        {t('Cancel')}
                    </button>
                    <button className="button" data-variant="primary" data-size="lg" disabled={!value} onClick={onMove}>
                        {t('Move')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default MoveModal
