import dayjs from 'dayjs'
import { get, has, isArray, isEmpty, isFunction, isPlainObject, set } from 'lodash'
import { partial } from 'lodash/function'
import isEmailString from 'validator/lib/isEmail'
import isInt from 'validator/lib/isInt'
import { hasValue, validateMaxLength, validateMinLength } from './validator'

const EMOJI_REGEX = /\p{Extended_Pictographic}/gu
const NOT_LATIN_CHARS_REGEX = /[^\u0000-\u007f]/

let i18n = null
export const init = i18instance => {
    i18n = i18instance
}

/**
 * Abstraction for define rule and encapsulate behavior of validation and get error
 * @param {string} name is name of rule
 * @param {Function} getErrors is function with signature ({value}) should return error message as string
 * @param {Function} validator is function with signature ({value, context}) should return boolean
 * @param {Function | null} collectError is function with signature ({value, context}) should return error object or container
 * @constructor
 */
function FieldRule(name, getErrors, validator, collectError = null) {
    this.name = name
    this.getErrorMessage = getErrors
    this.validator = validator
    this.collectError = collectError
}

export function required() {
    return new FieldRule(
        'required',
        () => {
            return i18n.t('This field is required')
        },
        ({ value }) => {
            return hasValue(value)
        },
    )
}

export function customFunction(fn, messageError) {
    return new FieldRule(
        'customFunction',
        () => {
            return messageError
        },
        data => {
            return fn(data)
        },
    )
}

export function defined() {
    return new FieldRule(
        'defined',
        () => {
            return i18n.t('This field is undefined')
        },
        ({ value, context }) => {
            return context.key in context.self && value !== undefined
        },
    )
}

export function maxLength(max) {
    return new FieldRule(
        'maxLength',
        () => {
            return i18n.t('Must be no more than {{max}} characters', { max: max })
        },
        ({ value }) => {
            return validateMaxLength(value, max)
        },
    )
}

export function minLength(min) {
    return new FieldRule(
        'minLength',
        () => {
            return i18n.t('Must be no less than {{min}} characters', { min })
        },
        ({ value }) => {
            return validateMinLength(value, min)
        },
    )
}

export function onlyLatinChars() {
    return new FieldRule(
        'onlyLatinChars',
        () => {
            return i18n.t('Can contain only Latin characters')
        },
        ({ value }) => {
            return !value.match(NOT_LATIN_CHARS_REGEX)
        },
    )
}

export function exactLength(length) {
    return new FieldRule(
        'exactLength',
        () => {
            return i18n.t('Must be {{length}} characters', { length })
        },
        ({ value }) => {
            return value.length === length
        },
    )
}

export function minUppercaseLength(min) {
    return new FieldRule(
        'minUppercaseLength',
        () => {
            return i18n.t('Must be no less than {{min}} uppercase characters', { min })
        },
        ({ value }) => {
            const _value = value.match(/[A-Z]/g)
            return !!_value && _value.length >= min
        },
    )
}

export function minLowercaseLength(min) {
    return new FieldRule(
        'minLowercaseLength',
        () => {
            return i18n.t('Must be no less than {{min}} lowercase characters', { min })
        },
        ({ value }) => {
            const _value = value.match(/[a-z]/g)
            return !!_value && _value.length >= min
        },
    )
}

export function minNumberLength(min) {
    return new FieldRule(
        'minNumberLength',
        () => {
            return i18n.t('Must be no less than {{min}} number', { min })
        },
        ({ value }) => {
            const _value = value.replace(/\D+/g, '')
            return !!_value && _value.length >= min
        },
    )
}

export function isEnum(enumObj) {
    const values = Object.values(enumObj)
    const existsEnumValue = val => values.includes(val)
    return new FieldRule(
        'isEnum',
        () => {
            return i18n.t('Must be one of the enum values: {{items}}', { items: JSON.stringify(enumObj) })
        },
        ({ value }) => {
            return existsEnumValue(value)
        },
    )
}

export function isEmail() {
    return new FieldRule(
        'isEmail',
        () => {
            return i18n.t('Must be email address')
        },
        ({ value }) => {
            if (value.match(EMOJI_REGEX)) return false
            return isEmailString(value)
        },
    )
}

export function isIntegerNumber() {
    return new FieldRule(
        'isIntegerNumber',
        () => {
            return i18n.t('Must be integer number')
        },
        ({ value }) => {
            return isInt(String(value))
        },
    )
}

export function minNumber(min) {
    return new FieldRule(
        'minNumber',
        () => {
            return i18n.t('Must be no less than {{min}}', { min })
        },
        ({ value }) => {
            return Number(value) >= min
        },
    )
}

export function maxNumber(max) {
    return new FieldRule(
        'maxNumber',
        () => {
            return i18n.t('Must be no greater than {{max}}', { max })
        },
        ({ value }) => {
            return Number(value) <= max
        },
    )
}

export function oneOf(arrayOfValues = []) {
    return new FieldRule(
        'oneOf',
        () => {
            return i18n.t('Invalid value')
        },
        ({ value }) => {
            return arrayOfValues.includes(value)
        },
    )
}

export function isFuture(offsetMin) {
    return new FieldRule(
        'isFuture',
        () => {
            return i18n.t('Date & time should be in future')
        },
        ({ value }) => {
            const now = dayjs()
            const nowWithOffset = offsetMin ? now.add(offsetMin, 'minute') : now
            return value > nowWithOffset
        },
    )
}

export function isNotHasWhiteSpaces() {
    return new FieldRule(
        'isNotHasWhiteSpaces',
        () => {
            return i18n.t('Must not contain spaces')
        },
        ({ value }) => {
            return value.indexOf(' ') === -1
        },
    )
}

export function itemRules(fieldsRules, errorConverter = null) {
    return new FieldRule(
        'itemRules',
        () => '',
        ({ value, context }) => {
            return value.every(item => isValidObject(fieldsRules, context, item))
        },
        ({ value, context }) => {
            const errors = value.map((item, index) => {
                const _context = new Context(context, context.self)
                _context.path = context.path + `.[${index}]`
                _context.key = index
                const errorItem = collectObjectErrors(fieldsRules, _context, item)
                return get(errorItem, _context.path, {})
            })
            return errorConverter ? errorConverter(value, errors) : errors
        },
    )
}

export const isEmailRules = [required(), isEmail(), onlyLatinChars()]
export const isPasswordRules = [
    required(),
    onlyLatinChars(),
    isNotHasWhiteSpaces(),
    minLength(6),
    minUppercaseLength(1),
    minLowercaseLength(1),
    minNumberLength(1),
]

/**
 *
 * @param {Function} ruleCallback function with signature {root, parent, self, key, value}
 * that should return FieldRule instance or null
 * @returns {Function}
 */
export function dependOn(ruleCallback) {
    return ruleCallback
}

function Context(context, obj) {
    if (context === null) {
        this.root = obj
        this.parent = null
        this.self = obj
        this.path = ''
        this.key = null
    } else {
        this.root = context.root
        this.parent = context.self
        this.self = obj
        this.path = context.path
        this.key = null
    }
}

export function* iterateObjByRules(ruleRoot, obj, _context = null, _fieldName = null) {
    for (const [fieldName, rules] of Object.entries(ruleRoot)) {
        const context = new Context(_context, obj)
        context.path = _context === null ? fieldName : `${context.path}.${fieldName}`
        context.key = fieldName

        const isInnerRule = isPlainObject(rules) && !isEmpty(rules)
        if (!isInnerRule && !isArray(rules))
            throw new Error(`Field value by path "${context.path}" has incorrect value should be array with rules`)

        if (_fieldName && _fieldName !== fieldName) continue

        const fieldValue = get(obj, fieldName, {})
        if (isInnerRule) {
            yield* iterateObjByRules(ruleRoot[fieldName], fieldValue, context)
        } else {
            for (const rule of rules) {
                let _rule = rule
                if (isFunction(rule)) {
                    // evaluate lazy rule
                    _rule = rule({ ...context, value: fieldValue })
                    if (_rule === null) continue
                }
                yield { value: fieldValue, context, rule: _rule }
            }
        }
    }
}

/**
 *  Return an error object with list of error messages for each field specified in the rules
 * @param {Object} fieldsRules - example: { header: [required()], description: [maxLength(100)]}
 * @param {Context | null} _context - look at Context
 * @param {Object} obj - Domain object example {header: '', description: 'correct val length'}
 * @param {string | null} fieldName - Field name which we want to validate and skip other
 * @returns {boolean} - Return false if error exists
 */
function isValidObject(fieldsRules, _context, obj, fieldName = null) {
    let isValid = true
    for (const { value, context, rule } of iterateObjByRules(fieldsRules, obj, _context, fieldName)) {
        isValid = rule.validator({ value, context })
        if (!isValid) break
    }
    return isValid
}

export function createValidator(fieldsRules) {
    return partial(isValidObject, fieldsRules, null)
}

/**
 *  Return an error object with list of error messages for each field specified in the rules
 * @param {Object} fieldsRules - example: { header: [required()], description: [maxLength(100)]}
 * @param {Context | null} _context - look at Context
 * @param {Object} obj - Domain object example {header: '', description: 'correct val length'}
 * @param {string | null} fieldName - Field name which we want to validate and skip other
 * @returns {Object} - Empty error object if all fields is valid otherwise { header: ['Missing required']}
 */
function collectObjectErrors(fieldsRules, _context, obj, fieldName = null) {
    const errorResult = {}
    for (const { value, context, rule } of iterateObjByRules(fieldsRules, obj, _context, fieldName)) {
        const errors = get(errorResult, context.path, [])

        const isValid = rule.validator({ value, context })
        const errorMessage = !isValid ? rule.getErrorMessage({ value }) : ''
        if (!isEmpty(errorMessage)) errors.push(errorMessage)
        set(errorResult, context.path, errors)

        if (rule.collectError) {
            const childErrors = rule.collectError({ value, context })
            set(errorResult, context.path, childErrors)
        }
    }
    return errorResult
}

/**
 *  Clear error messages for an error object on specific fields
 * @param {Object} error - example { header: ['Missing required', 'Too long'], description: ['Too long']}
 * @param fields - example ['header']
 *  Output side effect: error -> { header: [], description: ['Too long']}
 */
export function clearObjectErrors(error, fields) {
    if (!isEmpty(error)) for (const fieldName of fields) if (has(error, fieldName)) set(error, fieldName, [])
}

export function createCollector(fieldsRules) {
    return partial(collectObjectErrors, fieldsRules, null)
}

export function convertItemErrorsToMap(items, errors) {
    return new Map(items.map((item, index) => [item.id, errors[index]]))
}
