import React from 'react'
import { useTranslation } from 'react-i18next'

import arrowIcon from './i/arrow.svg'

import styles from './Footer.module.scss'

const Footer = ({ onSave, onNext }) => {
    const { t } = useTranslation()

    return (
        <ul className={styles.footer}>
            {!!onNext && (
                <li className={styles.footerItem}>
                    <button className="button" data-variant="primary" data-size="lg" onClick={onNext}>
                        {t('Next')}
                        <img src={arrowIcon} alt="arrow" />
                    </button>
                </li>
            )}
            <li className={styles.footerItem}>
                <button
                    className="button"
                    data-variant={!!onNext ? 'secondary' : 'primary'}
                    data-size="lg"
                    onClick={onSave}
                >
                    {t('Save')}
                </button>
            </li>
        </ul>
    )
}

export default Footer
