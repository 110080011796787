import React, { useEffect } from 'react'
import classNames from 'classnames'

import useOptimalTextFontStyles from 'hooks/useOptimalTextFontStyles'

import './Description.scss'

const Description = ({ isMobile, text, color, onUpdateFontSize, minFontSize }) => {
    const { parentRef, textRef, styles } = useOptimalTextFontStyles({
        text,
        lineHeight: '1.13',
        maxSize: 14,
        normalSize: 14,
        minSize: 8,
    })

    useEffect(() => {
        onUpdateFontSize(styles.fontSize)
    }, [styles.fontSize])

    return (
        <div ref={parentRef} className={classNames('rank-battle__description', { 'is-mobile': isMobile })}>
            <div
                ref={textRef}
                className="rank-battle__description__text"
                style={{
                    ...styles,
                    fontSize: minFontSize,
                    color,
                }}
            >
                {text}
            </div>
        </div>
    )
}

export default Description
