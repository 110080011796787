import React, { useEffect, useMemo, useRef, forwardRef, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { lock, clearBodyLocks } from 'tua-body-scroll-lock'
import useKeypress from 'react-use-keypress'

import useSizeDetector from 'hooks/useSizeDetector'

import './Modal.scss'

const modalListElement = document.getElementById('modal-list')

const Modal = forwardRef(
    (
        {
            className,
            contentClassName,
            closeOnOverlayClick = true,
            children,
            isShowCloseIcon = false,
            closeIconClassName,
            isWhiteCloseIcon = false,
            closeOnEsc = true,
            onClose = () => {
                /**/
            },
        },
        ref,
    ) => {
        const { ref: sizeDetectorRef, width, height } = useSizeDetector()

        const [mouseDownTarget, setMouseDownTarget] = useState(null)
        const [mouseUpTarget, setMouseUpTarget] = useState(null)

        const modalRef = useRef(null)
        const modalContainerRef = useRef(null)
        const el = useMemo(() => document.createElement('li'), [])
        el.classList.add('modal-item')

        useEffect(() => {
            modalListElement.appendChild(el)
            lock(modalContainerRef.current)

            return () => {
                if (modalListElement.hasChildNodes()) {
                    const _children = modalListElement.childNodes
                    _children.forEach(child => {
                        if (child === el) modalListElement.removeChild(el)
                    })
                }
                if (modalListElement.childElementCount === 0) clearBodyLocks()
            }
        }, [])

        useEffect(() => {
            if (!closeOnOverlayClick) return
            if (!mouseDownTarget || !mouseUpTarget || !modalContainerRef) return
            if (mouseDownTarget !== mouseUpTarget) {
                setMouseUpTarget(null)
                return
            }
            if (mouseUpTarget === modalContainerRef.current) {
                onClose()
            }
        }, [mouseUpTarget])

        useKeypress('Escape', () => {
            if (closeOnEsc) {
                onClose()
            }
        })

        return createPortal(
            <div className={classNames(className, 'modal-wrapper')} ref={modalRef}>
                <div
                    id="modalContainer"
                    className="modal-wrapper__container"
                    ref={containerRef => {
                        if (ref) ref.current = containerRef
                        modalContainerRef.current = containerRef
                    }}
                    onMouseDown={evt => setMouseDownTarget(evt.target)}
                    onMouseUp={evt => setMouseUpTarget(evt.target)}
                >
                    <div
                        ref={sizeDetectorRef}
                        className={classNames('modal-wrapper__content', { [contentClassName]: !!contentClassName })}
                        onClick={evt => evt.stopPropagation()}
                    >
                        {isShowCloseIcon && (
                            <div
                                className={classNames('modal-wrapper__close-icon', {
                                    'modal-wrapper__close-icon--white': isWhiteCloseIcon,
                                    [closeIconClassName]: !!closeIconClassName,
                                })}
                                data-test="close-modal"
                                onClick={onClose}
                            />
                        )}
                        {typeof children === 'function' ? children({ width, height }) : children}
                    </div>
                </div>
            </div>,
            el,
        )
    },
)

export default Modal
