import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    isDeprecatedSubscription,
    isOwner as getIsOwner,
    isTrialSubscription,
    isFreeSubscription,
} from 'common/utils/permissions'
import Modal from '../Modal'
import TrialActivatedModal from './components/TrialActivatedModal/TrialActivatedModal'
import Loader from './components/Loader/Loader'
import ForOwnerOfOrgExpirationContent from './components/Content/ForOwnerOfOrgExpirationContent'
import { API__PRODUCTS, API__USER } from 'api'
import { ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION, USER__SET_SUBSCRIPTIONS } from 'store/actions'
import ForMemberOfOrgExpirationContent from './components/Content/ForMemberOfOrgExpirationContent'

import './SubscriptionExpiredModal.scss'

export const SubscriptionExpiredModal = ({ onClose, data }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isExtended, setIsExtended] = useState(false)
    const [userTrialCount, setUserTrialCount] = useState(0)
    const dispatch = useDispatch()
    const { userDetails, selectedOrganizationId, organizationList } = useSelector(state => ({
        userDetails: state.user_details,
        selectedOrganizationId: state.organizations.selectedOrganizationId,
        organizationList: state.organizations.organizationList,
    }))

    const _isOwner = typeof data?.isOwner === 'boolean' ? data?.isOwner : getIsOwner()

    useEffect(() => {
        if (!_isOwner) return

        setIsLoading(true)
        API__USER.GET_TRIAL_COUNT(userDetails.id)
            .then(response => {
                setUserTrialCount(response.count)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [selectedOrganizationId])

    const onExtendTrialPeriod = async () => {
        setIsLoading(true)
        try {
            await API__PRODUCTS.ACTIVATE_TRIAL(selectedOrganizationId)
            dispatch(ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION())
            setIsExtended(true)
        } finally {
            setIsLoading(false)
        }
    }

    const onUseEducationFree = async () => {
        setIsLoading(true)
        try {
            await API__PRODUCTS.ACTIVATE_EDUCATION_FREE(selectedOrganizationId)
            await dispatch(ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION())
            await dispatch(USER__SET_SUBSCRIPTIONS(await API__USER.GET_SUBSCRIPTIONS(userDetails.id)))
            onClose()
        } finally {
            setIsLoading(false)
        }
    }

    const getViewOfContent = () => {
        if (isLoading) return null

        if (isExtended) return <TrialActivatedModal onClose={onClose} />
        else if (!_isOwner) {
            const selectedOrganization = organizationList.find(org => org.id === selectedOrganizationId)
            return <ForMemberOfOrgExpirationContent orgName={selectedOrganization.name} onClose={onClose} />
        }

        return (
            <ForOwnerOfOrgExpirationContent
                trialUsesCount={userTrialCount}
                isTrial={isTrialSubscription()}
                isFreeSubscription={isFreeSubscription()}
                isDeprecatedSubscription={isDeprecatedSubscription()}
                onExtend={() => onExtendTrialPeriod()}
                onUseEducationFree={() => onUseEducationFree()}
            />
        )
    }

    if (!userDetails.roleInProjectDto) return null

    return (
        <Modal onClose={() => onClose()} closeOnOverlayClick={false} isShowCloseIcon={!isLoading}>
            <div className="subscription-expired-modal">
                <Loader isShow={isLoading} />
                {getViewOfContent()}
            </div>
        </Modal>
    )
}
