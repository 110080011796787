import { DEFAULT_IMAGE_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'
import { map } from 'lodash'

import { QuizCover, QuizResult } from '../quizModels'

import { MIN_ANSWERS_IN_QUESTION, MIN_CORRECT_ANSWERS_IN_QUESTION, MIN_QUESTIONS, MIN_RESULTS } from './constants'
import { calculateResultDistribution } from './utilities'

export class Answer {
    id // <String> id
    text // <String> text
    description // <String> description
    image // <null/String[URL]> image
    imageLabel // <String> image label
    imageDescription // <String> image description
    isCorrect // <Boolean> is correct

    constructor({
        text = '',
        description = '',
        image = DEFAULT_IMAGE_URL,
        imageLabel = '',
        imageDescription = '',
        isCorrect = false,
    } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.description = description
        this.image = image
        this.imageLabel = imageLabel
        this.imageDescription = imageDescription
        this.isCorrect = isCorrect
    }

    static get text() {
        return 'text'
    }

    static get description() {
        return 'description'
    }

    static get image() {
        return 'image'
    }

    static get imageLabel() {
        return 'imageLabel'
    }

    static get imageDescription() {
        return 'imageDescription'
    }

    static get isCorrect() {
        return 'isCorrect'
    }
}

export class Question {
    id // <String> id
    text // <String> text
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer
    answers // <Array> Answers
    isText // <Boolean> is text question

    constructor({ text = '', image = DEFAULT_IMAGE_URL, imageDisclaimer = '', answers = [], isText = true } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.image = image
        this.imageDisclaimer = imageDisclaimer
        if (answers.length < MIN_ANSWERS_IN_QUESTION)
            throw new Error(`Min count answers in question: ${MIN_ANSWERS_IN_QUESTION}`)
        if (answers.filter(ans => ans.isCorrect).length > 1)
            throw new Error('Question can only have one correct answer')
        this.answers = answers.map(answer => new Answer(answer))
        this.isText = isText
    }

    static get text() {
        return 'text'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }

    static get answers() {
        return 'answers'
    }

    static get isText() {
        return 'isText'
    }
}

export function createQuestion() {
    const answers = []
    for (let i = 0; i < MIN_ANSWERS_IN_QUESTION; i++) {
        const answer = new Answer()
        if (MIN_CORRECT_ANSWERS_IN_QUESTION - i > 0) answer.isCorrect = true
        answers.push(answer)
    }
    return new Question({ answers })
}

export function createTriviaBlock() {
    const questions = map(Array.from({ length: MIN_QUESTIONS }), () => createQuestion())
    const results = map(Array.from({ length: MIN_RESULTS }), () => new QuizResult())
    const maxNumberOfOptions = questions.length + 1
    const numberOfResults = results.length
    const resultDistribution = calculateResultDistribution(maxNumberOfOptions, numberOfResults)
    return {
        cover: new QuizCover(),
        questionBank: { bankId: null, questionsCount: 5 },
        questions,
        results,
        resultDistribution,
    }
}
