import i18n from 'i18n'

import coverIcon from 'components/Modal/components/Body/components/SideNav/i/cover.svg'
import cardsIcon from 'components/Modal/components/Body/components/SideNav/i/cards.svg'

import { DEFAULT_VALUES } from 'components/Form/RadioPair/constants'

export const TABS = {
    PLAYGROUND: 1,
    CARDS: 2,
}

export const TABS_ARRAY = [
    {
        id: TABS.PLAYGROUND,
        label: i18n.t('Layout '),
        icon: coverIcon,
    },
    {
        id: TABS.CARDS,
        label: i18n.t('Cards'),
        icon: cardsIcon,
    },
]

export const AVAILABLE_CARDS_COUNT = [2, 3, 4]

export const DEFAULT_CARDS_COUNT = 2

export const CARD_TYPES = DEFAULT_VALUES
export const DEFAULT_CARD_TYPE = DEFAULT_VALUES.IMAGE

export const MAX_CARD_TEXT_LENGTH = 40
export const MAX_CARD_DESCRIPTION_LENGTH = 60

export const DEFAULT_IMAGE_URL =
    'https://backend-dev-bucket.s3.eu-central-1.amazonaws.com/public/default/rank-battle-square.png'
