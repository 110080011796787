import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import EMAILS from 'common/constants/emails'

import alertIcon from './i/alert.svg'

import styles from './PayoutsDisabled.module.scss'

const PayoutsDisabled = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.payoutsDisabled}>
            <div className={styles.alert}>
                <div className={styles.imageBox}>
                    <img src={alertIcon} alt="" />
                </div>
            </div>
            <div className={styles.title}>{t('Payouts disabled')}</div>
            <p className={styles.description}>
                <Trans
                    i18nKey="Stripe Connect is not configured. To receive payments, set up Stripe Connect in your Account settings. <lnk>Read more</lnk>"
                    components={{
                        lnk: <a className={styles.link} href="/" target="_blank" style={{ display: 'none' }} />,
                    }}
                />
            </p>
            <div className={styles.buttonBox}>
                <Link className="button" data-variant="primary" data-size="lg" to="/settings">
                    {t('Open account settings')}
                </Link>
            </div>
            <p className={styles.contact}>
                <Trans
                    i18nKey="Need help? <lnk>Contact us</lnk>"
                    components={{
                        lnk: <a className={styles.link} href={`mailto:${EMAILS.INTERACTY_SUPPORT}`} target="_blank" />,
                    }}
                />
            </p>
        </div>
    )
}

export default PayoutsDisabled
