import React from 'react'
import classNames from 'classnames'

const Tabs = ({ activeTabCode, tabs, commonProjectStatistics, changeActiveTab }) => {
    return (
        <div className="tabs">
            {tabs.map(tab => (
                <div
                    key={tab.key}
                    className={classNames('tab', { 'is-active': activeTabCode === tab.key })}
                    data-test={tab.dataTestValue}
                    onClick={() => changeActiveTab(tab.key)}
                >
                    <div className="label">{tab.label}</div>
                    <div className="count">{tab.formatValue(commonProjectStatistics[tab.responseKey])}</div>
                </div>
            ))}
        </div>
    )
}

export default Tabs
