import React from 'react'
import ReactResponsiveModal from 'react-responsive-modal'

import { IconCross } from '../../svg'

const Modal = ({ settings, styles, data, classes, events, children }) => {
    return (
        <ReactResponsiveModal
            open={data.open}
            onClose={events.onCloseModal}
            onExited={events.onExited}
            center
            closeOnOverlayClick={settings.overlayClose}
            styles={{
                modal: {
                    maxWidth: data.maxWidth ? data.maxWidth : '100%',
                    ...styles,
                },
            }}
            focusTrapped={false}
            classNames={{
                overlay: `modal-overlay${classes && classes.overlay ? ' ' + classes.overlay : ''}`,
                modal: `modal-content${classes && classes.modal ? ' ' + classes.modal : ''}`,
                closeButton: `modal-close${classes && classes.closeButton ? ' ' + classes.closeButton : ''}`,
                closeIcon: `modal-close-icon${classes && classes.closeIcon ? ' ' + classes.closeIcon : ''}`,
            }}
        >
            <div
                className={`modal-close--custom${
                    classes && classes.closeIconCustom ? ' ' + classes.closeIconCustom : ''
                }`}
                onClick={events.onCloseModal}
            >
                <IconCross />
            </div>
            {children}
        </ReactResponsiveModal>
    )
}

export default Modal
