import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import styles from './Table.module.scss'

const Table = ({ features }) => {
    const { t } = useTranslation()
    const {
        user_subscription: {
            product: { featureProducts },
        },
    } = useSelector(state => state)
    const [items, setItems] = useState([])

    useEffect(() => {
        let result = []
        for (const [featureName, featureDetails] of Object.entries(features)) {
            if (!featureDetails.getControl) continue
            const feature = featureProducts.find(item => item.featureType === featureName)
            if (!featureDetails.isFake && !feature) continue
            result.push({
                featureName,
                label: featureDetails.label,
                value: featureDetails.getControl(feature),
            })
        }
        setItems(result)
    }, [features, featureProducts])

    return (
        <div className={styles.table}>
            <h2 className={styles.title}>{t('Tariff plan features')}</h2>
            <ul className={styles.list}>
                {items.map(item => (
                    <li key={item.featureName} className={styles.item}>
                        <div className={classNames(styles.itemColumn, styles.itemLabel)}>{item.label}</div>
                        <div className={classNames(styles.itemColumn, styles.itemValue)}>{item.value}</div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Table
