import React from 'react'

import useRouter from 'hooks/useRouter'

import { getLink } from '../utils'
import { ACTIONS } from '../constants'

const useAction = () => {
    const { queryParams, historyPush } = useRouter()

    const getAction = () => {
        const { action } = queryParams
        if (!action) return ACTIONS.AUTHENTICATION
        if (ACTIONS[action.toUpperCase()]) return ACTIONS[action.toUpperCase()]
        historyPush(getLink(ACTIONS.AUTHENTICATION))
        return ACTIONS.AUTHENTICATION
    }

    return {
        action: getAction(),
        onChangeAction: action => historyPush(getLink(action)),
    }
}

export default useAction
