import i18n from 'i18next'

export const VIEWS = {
    CREATION: 'CREATION',
    INSPECTION: 'INSPECTION',
}

export const VIEWS_TITLE = {
    [VIEWS.CREATION]: i18n.t('Set up game event'),
    [VIEWS.INSPECTION]: i18n.t('Game event'),
}

export const GAME_STATUSES = {
    NEW: 'NEW',
    WAITING_PLAYERS: 'WAITING_PLAYERS',
    PREPARING: 'PREPARING',
    IN_PROGRESS: 'IN_PROGRESS',
    SHOWING_SCORE: 'SHOWING_SCORE',
    COMPLETED: 'COMPLETED',
    CREATION_ERROR: 'CREATION_ERROR',
    WAITING_PLAYER_RESULTS: 'WAITING_PLAYER_RESULTS',
}

export const ACTIVE_GAME_STATUSES = [
    GAME_STATUSES.WAITING_PLAYERS,
    GAME_STATUSES.PREPARING,
    GAME_STATUSES.IN_PROGRESS,
    GAME_STATUSES.WAITING_PLAYER_RESULTS,
    GAME_STATUSES.SHOWING_SCORE,
]
