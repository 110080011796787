import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'

import { getBlocksTranslatedValues } from './utils/utils'
import { isJson } from 'utils/validator'
import { getExcelFromJSON, getJSONFromExcel } from 'common/utils/excelService'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'
import TextArea from 'components/Form/TextArea/TextArea'
import Upload from 'components/Form/Upload/Upload'

import './CustomTranslationsModal.scss'

const initialJSON = {
    preferredLanguage: [],
    locales: {},
}
const CustomTranslationsModal = ({ data, onClose, onUpdate }) => {
    const {
        app: { customTranslations },
    } = data

    const { t } = useTranslation()
    const [jsonValue, setJsonValue] = useState(() => {
        if (customTranslations) {
            return JSON.parse(customTranslations)
        }
        return initialJSON
    })
    const [inputValue, setInputValue] = useState('')
    const [isShowInputField, setIsShowInputField] = useState(false)
    const [errors, setErrors] = useState([])

    const parseTexts = () => {
        const locales = getBlocksTranslatedValues(data)
        setJsonValue({ ...initialJSON, ...jsonValue, locales })
    }

    const uploadExcel = async files => {
        const locales = await getJSONFromExcel(files[0])
        setJsonValue({ ...initialJSON, ...jsonValue, locales })
    }

    const getExcel = () => {
        getExcelFromJSON(jsonValue.locales)
    }

    const onSave = () => {
        onUpdate({ customTranslations: JSON.stringify(jsonValue) })
        onClose()
    }

    const onShowInputField = () => {
        setInputValue(JSON.stringify(jsonValue))
        setIsShowInputField(true)
    }

    const onSaveInputField = () => {
        if (!isJson(inputValue)) {
            setErrors(['invalid JSON'])
            return
        } else {
            setErrors([])
        }
        const json = JSON.parse(inputValue)
        setJsonValue(json)
        setIsShowInputField(false)
    }

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false}>
            <Header title={t('Translations')} isCloseIcon={false} />
            <div className="custom-translations-modal">
                <div className="custom-translations-modal__header-block">
                    <div className="custom-translations-modal__buttons">
                        {!isShowInputField && (
                            <>
                                <button
                                    className="button"
                                    data-variant="primary"
                                    data-size="lg"
                                    data-test="dialog-save"
                                    onClick={() => onShowInputField()}
                                >
                                    {t('Edit JSON')}
                                </button>
                                <button
                                    className="button"
                                    data-variant="primary"
                                    data-size="lg"
                                    data-test="dialog-save"
                                    onClick={parseTexts}
                                >
                                    {t('Parse texts')}
                                </button>
                                <button
                                    className="button"
                                    data-variant="primary"
                                    data-size="lg"
                                    data-test="dialog-save"
                                    onClick={getExcel}
                                >
                                    {t('Export Excel')}
                                </button>
                                <Upload
                                    className="custom-translations-modal__upload"
                                    variant="primary"
                                    size="lg"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onUpload={uploadExcel}
                                >
                                    {t('Upload Excel')}
                                </Upload>
                            </>
                        )}
                    </div>
                    <div className="custom-translations-modal__info">
                        <div className="custom-translations-modal__info-image" />
                        <a
                            href="https://help.interacty.me/en/content/how-can-i-add-different-languages-into-my-project"
                            target="_blank"
                            className="custom-translations-modal__info-text"
                        >
                            {t('Read the instructions')}
                        </a>
                    </div>
                </div>
                <div className="custom-translations-modal__json-block">
                    {isShowInputField ? (
                        <div>
                            <TextArea
                                label={t('Json  input editor')}
                                value={inputValue}
                                onUpdate={_value => setInputValue(_value)}
                                errorMessages={errors}
                            />
                            <button
                                className="button"
                                data-variant="primary"
                                data-size="lg"
                                data-test="dialog-save"
                                onClick={() => onSaveInputField()}
                            >
                                Save
                            </button>
                        </div>
                    ) : (
                        <div className="custom-translations-modal__json-block-viewer">
                            <ReactJson
                                src={jsonValue}
                                name={t('JSON')}
                                iconStyle="square"
                                displayDataTypes={false}
                                displayObjectSize={false}
                                enableClipboard={false}
                                quotesOnKeys={false}
                                onAdd={val => setJsonValue(val.updated_src)}
                                onEdit={val => setJsonValue(val.updated_src)}
                                onDelete={val => setJsonValue(val.updated_src)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Footer
                actionText={t('Save')}
                cancelText={t('Cancel')}
                onAction={onSave}
                isActionDisabled={isShowInputField}
                onClose={onClose}
            />
        </Modal>
    )
}

export default CustomTranslationsModal
