import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import {
    createCollector,
    createValidator,
    exactLength,
    required,
    isPasswordRules,
    isEmailRules,
} from 'shared/validation'

import { API__USER } from 'api'

import Toast from 'components/Toast/Toast'
import CodeInput from 'components/Form/CodeInput/CodeInput'

import { ACTIONS } from '../constants'
import useAction from '../hooks/useAction'

import Authorization from './Authorization'
import Body from './components/Body/Body'
import Inputs from './components/Inputs/Inputs'
import Button from './components/Button/Button'
import TextControl from './components/Inputs/components/Controls/Text/Text'

import styles from './styles.module.scss'

const STEPS = {
    STEP_1: 'STEP_1',
    STEP_2: 'STEP_2',
    STEP_3: 'STEP_3',
}

const Step1 = ({ onSuccess, onChangeAction }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const _email = email.trim()
            const fields = {
                email: _email,
            }
            const validationRules = {
                email: isEmailRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__USER.GET_RESTORE_PASSWORD_CODE(_email)
            onSuccess(_email)
        } catch (err) {
            console.error(err)
            if (err.response) {
                const {
                    response: { status, data },
                } = err

                if (status === 404) {
                    if (data.kind.code === 3) {
                        if (data.message === 'User not found') {
                            setErrors(prevState => ({
                                ...prevState,
                                email: [t('User not found')],
                            }))
                            return
                        }
                    }
                }

                if (status === 400) {
                    if (data.kind.code === 21) {
                        setErrors(prevState => ({
                            ...prevState,
                            email: [t('User is deleted')],
                        }))
                        return
                    }
                }
            }
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Body
            authClassName={styles.formTopOffset}
            title={t('No worries!')}
            description={t('Enter your email address and we’ll send you a secret code to reset your password')}
            isShowSocial={false}
            onSubmit={() => onSubmit()}
        >
            <Inputs>
                <TextControl
                    label={t('Email address')}
                    value={email}
                    onUpdate={v => {
                        setEmail(v)
                        setErrors({ ...errors, email: [] })
                    }}
                    errorMessages={errors.email || []}
                />
            </Inputs>
            <Button text={t('Back to login page')} onClickText={() => onChangeAction(ACTIONS.AUTHENTICATION)}>
                <button
                    data-test="signup-submit"
                    className="button"
                    data-size="lg"
                    data-variant="primary"
                    disabled={isLoading}
                >
                    {t('Send me a code')}
                </button>
            </Button>
        </Body>
    )
}
const Step2 = ({ email, onSuccess, onChangeAction }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const fields = {
                code,
                password,
            }
            const validationRules = {
                code: [required(), exactLength(6)],
                password: isPasswordRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__USER.RESTORE_PASSWORD({
                email: email.trim(),
                password,
                code,
            })
            onSuccess()
        } catch (err) {
            console.error(err)
            if (err.response) {
                const {
                    response: { status, data },
                } = err

                if (status === 400) {
                    if (data.exception_data?.verificationCode) {
                        setErrors(prevState => ({
                            ...prevState,
                            code: [t('Invalid format')],
                        }))
                        return
                    }
                    if (!data.exception_data && !data.message) {
                        setErrors(prevState => ({
                            ...prevState,
                            code: [t('Invalid code')],
                        }))
                        return
                    }
                    if (data.kind.code === 5) {
                        if (data.message === 'New password is not valid') {
                            setErrors(prevState => ({
                                ...prevState,
                                password: [t('Password is not valid')],
                            }))
                            return
                        }
                        if (data.message === 'New password matches with old password') {
                            setErrors(prevState => ({
                                ...prevState,
                                password: [t('New password must be different from the current')],
                            }))
                            return
                        }
                    }
                }
            }
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Body
            authClassName={styles.formTopOffset}
            title={t('Check your email!')}
            description={
                <Trans
                    i18nKey="We just emailed a secret code to <bold>{{email}}</bold>. Please check your inbox and set new password."
                    values={{ email }}
                    components={{
                        bold: <span />,
                    }}
                />
            }
            isShowSocial={false}
            onSubmit={() => onSubmit()}
        >
            <Inputs>
                <CodeInput
                    label={t('Secret code from email')}
                    value={code}
                    onUpdate={v => {
                        setCode(v)
                        setErrors({ ...errors, code: [] })
                    }}
                    errorMessages={errors.code || []}
                />
                <TextControl
                    label={t('Password')}
                    value={password}
                    isSecure={true}
                    onUpdate={v => {
                        setPassword(v)
                        setErrors({ ...errors, password: [] })
                    }}
                    errorMessages={errors.password || []}
                />
            </Inputs>
            <Button text={t('Back to login page')} onClickText={() => onChangeAction(ACTIONS.AUTHENTICATION)}>
                <button
                    data-test="signup-submit"
                    className="button"
                    data-size="lg"
                    data-variant="primary"
                    disabled={isLoading}
                >
                    {t('Set new password')}
                </button>
            </Button>
        </Body>
    )
}

const PasswordRecovery = () => {
    const { t } = useTranslation()
    const { onChangeAction } = useAction()

    const [step, setStep] = useState(STEPS.STEP_1)
    const [userEmail, setUserEmail] = useState('')

    return (
        <>
            <Helmet>
                <title>{t('Password recovery')} | Interacty</title>
            </Helmet>
            {step === STEPS.STEP_1 && (
                <Step1
                    onChangeAction={onChangeAction}
                    onSuccess={email => {
                        setUserEmail(email)
                        setStep(STEPS.STEP_2)
                    }}
                />
            )}
            {step === STEPS.STEP_2 && (
                <Step2 email={userEmail} onChangeAction={onChangeAction} onSuccess={() => setStep(STEPS.STEP_3)} />
            )}
            {step === STEPS.STEP_3 && (
                <Authorization
                    isHideForgotPassword
                    title={t('Success!')}
                    description={t("Now log in with your new password and you're there!")}
                />
            )}
        </>
    )
}

export default PasswordRecovery
