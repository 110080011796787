import React, { useEffect, useState, useRef } from 'react'

import useResize from 'hooks/useResize'

import { getTextFontSize } from './utils'

import styles from './BlockText.module.scss'

const LINE_HEIGHT = '1.4'
const FONT_WEIGHT = '700'

const BlockText = ({ width, height, text, textColor, fontFamily }) => {
    const blockRef = useRef(null)
    const [blockWidth, blockHeight] = useResize(blockRef)

    const [fontSize, setFontSize] = useState(null)

    useEffect(() => {
        if (!text || width <= 0 || height <= 0) return
        updateFontSize()
    }, [blockWidth, blockHeight, width, height, text, fontFamily])

    const updateFontSize = () => {
        setFontSize(
            getTextFontSize({
                width: height,
                height: width,
                text,
                fontFamily: `"${fontFamily}", sans-serif`,
                lineHeight: LINE_HEIGHT,
                fontWeight: FONT_WEIGHT,
                minSize: 6,
                normalSize: 28,
                maxSize: 34,
            }),
        )
    }

    return (
        <div
            ref={blockRef}
            className={styles.sectionContentText}
            style={{
                fontSize,
                color: textColor,
                fontFamily: `"${fontFamily}", sans-serif`,
                lineHeight: LINE_HEIGHT,
                fontWeight: FONT_WEIGHT,
            }}
        >
            {text}
        </div>
    )
}

export default BlockText
