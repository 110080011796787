import React from 'react'
import classNames from 'classnames'

import styles from './Title.module.scss'

const Title = ({ isCentered, children }) => (
    <div className={classNames(styles.title, { [styles.isCenteredTitle]: isCentered })}>{children}</div>
)

export default Title
