import { useEffect, useRef, useState } from 'react'

import { getOptimalTextFontSize } from './utils'

import useResize from '../useResize'

const useOptimalTextFontSize = ({
    fontFamily = 'Roboto',
    lineHeight = '1.1',
    fontWeight = 'normal',
    minSize = 6,
    normalSize = 36,
    maxSize = 48,
    text = '',
}) => {
    const parentRef = useRef()
    const textRef = useRef()

    const [parentWidth, parentHeight] = useResize(parentRef)
    const [textWidth, textHeight] = useResize(textRef)

    const [fontSize, setFontSize] = useState(0)

    useEffect(() => {
        const newFontSize = getOptimalTextFontSize({
            parentRef,
            text,
            fontFamily: `"${fontFamily}", sans-serif`,
            lineHeight,
            fontWeight,
            minSize,
            normalSize,
            maxSize,
        })
        setFontSize(newFontSize)
    }, [parentWidth, parentHeight, textWidth, textHeight, text, fontFamily, parentRef.current])

    return {
        parentRef,
        textRef,
        sizes: {
            parentWidth,
            parentHeight,
            textWidth,
            textHeight,
        },
        styles: {
            fontSize,
            lineHeight,
            fontWeight,
            fontFamily: `"${fontFamily}", sans-serif`,
        },
    }
}

export default useOptimalTextFontSize
