import React from 'react'

import styles from './PresetItem.module.scss'

const MAX_DIGITS = 3

const PresetItem = ({ label, value, units, onChange }) => (
    <li className={styles.presetItem}>
        <div className={styles.label}>{label}</div>
        <div className={styles.settings}>
            <input
                className={styles.settingsInput}
                type="number"
                value={value}
                onChange={evt => {
                    const v = evt.target.value
                    const vDigits = String(v).split('').length
                    if (vDigits > MAX_DIGITS) return
                    onChange(v)
                }}
                onBlur={() => {
                    if (!value) onChange(5)
                    if (value < 5) onChange(5)
                    if (value > 600) onChange(600)
                }}
            />
            <div className={styles.units}>{units}</div>
        </div>
    </li>
)

export default PresetItem
