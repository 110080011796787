import Toast from 'components/Toast/Toast'
import { cloneDeep, isEqual } from 'lodash'
import React from 'react'
import { withTranslation } from 'react-i18next'

import Footer from 'components/Modal/components/Footer/Footer'
import Header from 'components/Modal/components/Header/Header'
import StopPanel from 'components/Modal/components/StopPanel/StopPanel'
import Modal from 'components/Modal/Modal'

import iconGirl from '../i/girl.svg'

import { TRIVIA_TABS } from './constants'

import { validateTabs } from './utilities'
import QuestionView from './views/QuestionView'

import './QuestionModal.scss'

class QuestionModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ready: true,
            saving: false,
            activeTab: TRIVIA_TABS.questionView,
            showStopPanel: false,
            errors: {},
            quitAcceptIsOpen: false,
            tmpStructure: cloneDeep(this.props.data.structure),
        }
    }

    onSave = async () => {
        const { tmpStructure } = this.state
        const validationResult = validateTabs(tmpStructure)
        if (validationResult.hasError) {
            this.setState({
                showStopPanel: true,
                errors: validationResult.errors,
            })
            return
        }

        const {
            methods,
            data: { id },
        } = this.props

        this.setState({ saving: true })
        try {
            await methods.save({
                [id]: {
                    data: {
                        struct: validationResult.tmpStructure,
                    },
                },
            })
        } catch (err) {
            console.error(err)

            Toast('error', {})
        } finally {
            this.setState({ saving: false })
        }
        methods.closeModal()
    }

    onClose = async () => {
        const {
            methods: { closeModal },
            data: { structure },
        } = this.props
        const { tmpStructure } = this.state
        // TODO add normalizations
        if (!isEqual(structure, tmpStructure)) {
            await this.setState({ quitAcceptIsOpen: true })
        } else {
            closeModal()
        }
    }

    onUpdateStructure = (newFields, callback) => {
        this.setState(
            prevState => ({
                ...prevState,
                tmpStructure: {
                    ...prevState.tmpStructure,
                    ...newFields,
                },
            }),
            callback,
        )
    }

    render() {
        const { saving, ready, tmpStructure, activeTab, showStopPanel, errors, quitAcceptIsOpen } = this.state
        const { methods, t } = this.props

        let tabContent = null
        switch (activeTab) {
            case TRIVIA_TABS.questionView: {
                tabContent = (
                    <QuestionView
                        tmpStructure={tmpStructure}
                        errors={errors}
                        onUpdateStructure={this.onUpdateStructure}
                    />
                )
                break
            }
        }

        return (
            <>
                {ready && (
                    <Modal closeOnOverlayClick={false} closeOnEsc={false}>
                        <Header title={t('Question options')} onClose={() => this.onClose()} />
                        <div className="question-modal-wrapper">{tabContent}</div>
                        <Footer
                            actionText={t('Save')}
                            cancelText={t('Cancel')}
                            onClose={() => this.onClose()}
                            onAction={() => this.onSave()}
                        />

                        {showStopPanel && (
                            <StopPanel
                                t={t}
                                headText={t('Oh! Need more information')}
                                descriptionText={t(
                                    'Please fill all required fields for the question to work correctly.',
                                )}
                                image={iconGirl}
                                onBack={() => this.setState({ showStopPanel: false })}
                                isShowQuit={false}
                            />
                        )}
                        {quitAcceptIsOpen && (
                            <StopPanel
                                t={t}
                                isLoading={saving}
                                onBack={() => this.setState({ quitAcceptIsOpen: false })}
                                onQuit={methods.closeModal}
                            />
                        )}
                    </Modal>
                )}
            </>
        )
    }
}

export default withTranslation('translations')(QuestionModal)
