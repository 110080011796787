import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheckMark } from 'svg'

import UploadImage from 'components/Form/UploadImage/UploadImage'
import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import { MAX_ANSWERS_IN_QUESTION, MIN_ANSWERS_IN_QUESTION } from '../constants'
import { Answer as AnswerModel, Question as QuestionModel } from '../triviaService'
import AddListItemButton from '../../../Controls/AddListItemButton/AddListItemButton'
import { isMinAnswerOptionLimitExceeded } from '../utilities'

const Question = ({
    errors,
    question,
    qIndex,
    onSetAnswerCorrect,
    onUpdateQuestion,
    onUpdateQuestionAnswer,
    onAddAnswer,
    onRemoveAnswer,
}) => {
    const { t } = useTranslation()

    const isRemovable = answerId => {
        const isMinAnswerExceeded = question.answers.length === MIN_ANSWERS_IN_QUESTION
        const filteredAnswers = question.answers.filter(el => el.id !== answerId)
        const isExceeded = isMinAnswerOptionLimitExceeded(filteredAnswers)
        return !(isMinAnswerExceeded || isExceeded)
    }

    const isClickable = (answerId, isCorrect) => {
        let conditionFunc = a => (a.id === answerId ? { ...a, isCorrect } : a)
        if (question.answers.length <= 2)
            conditionFunc = a => (a.id === answerId ? { ...a, isCorrect } : { ...a, isCorrect: !isCorrect })
        const filteredAnswers = question.answers.map(conditionFunc)
        const isExceeded = isMinAnswerOptionLimitExceeded(filteredAnswers)
        return !isExceeded
    }

    return (
        <div>
            <div className="content">
                <ul className="q-content">
                    <TextArea
                        isRequired
                        label={t('Question text')}
                        errorMessages={errors.questions?.get(question.id)?.text}
                        value={question.text}
                        onUpdate={value => onUpdateQuestion(question.id, { [QuestionModel.text]: value })}
                    />
                    <ElementsGroup>
                        <UploadImage
                            label={t('Question image')}
                            value={question.image}
                            onUpdate={value => onUpdateQuestion(question.id, { [QuestionModel.image]: value })}
                        />
                        {question.image && (
                            <TextInput
                                label={t('Image disclaimer')}
                                isOptional
                                value={question.imageDisclaimer}
                                onUpdate={value =>
                                    onUpdateQuestion(question.id, { [QuestionModel.imageDisclaimer]: value })
                                }
                            />
                        )}
                    </ElementsGroup>
                    <div className="block--checkbox">
                        <ul>
                            <li
                                className={question.isText ? 'is-active' : ''}
                                onClick={() => onUpdateQuestion(question.id, { [QuestionModel.isText]: true })}
                            >
                                <div className="img" data-image="text" />
                                Text
                            </li>
                            <li
                                className={!question.isText ? 'is-active' : ''}
                                onClick={() => onUpdateQuestion(question.id, { [QuestionModel.isText]: false })}
                            >
                                <div className="img" data-image="images" />
                                {t('Images')}
                            </li>
                        </ul>
                    </div>
                </ul>
                {question.answers.length && (
                    <ul className="a-content">
                        {question.answers.map((answer, aIndex) => (
                            <li key={answer.id} id={`scroll-element-${qIndex}.${aIndex}`}>
                                <div className="input-block">
                                    <div className="label label--centered">
                                        <span className="text" data-required={question.isText}>
                                            {t('Answer')} {aIndex + 1}
                                        </span>
                                        <ul className="group">
                                            <li>
                                                <div
                                                    className={`checkbox-block ${
                                                        !isClickable(answer.id, !answer.isCorrect) ? 'is-disabled' : ''
                                                    }`}
                                                    onClick={() =>
                                                        onSetAnswerCorrect(question.id, answer.id, !answer.isCorrect)
                                                    }
                                                >
                                                    <div
                                                        className={`checkbox-box ${
                                                            answer.isCorrect ? 'is-checked' : ''
                                                        }`}
                                                    >
                                                        <IconCheckMark />
                                                    </div>
                                                    <p>{t('Correct answer')}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {question.isText ? (
                                        <TextArea
                                            rows={1}
                                            isRequired
                                            errorMessages={
                                                errors.questions?.get(question.id)?.answers?.get(answer.id)?.text
                                            }
                                            value={answer.text}
                                            onUpdate={value =>
                                                onUpdateQuestionAnswer(question.id, answer.id, {
                                                    [AnswerModel.text]: value,
                                                })
                                            }
                                        />
                                    ) : (
                                        <ElementsGroup>
                                            <UploadImage
                                                value={answer.image}
                                                onUpdate={value =>
                                                    onUpdateQuestionAnswer(question.id, answer.id, {
                                                        [AnswerModel.image]: value,
                                                    })
                                                }
                                                isDisabledDelete
                                            />
                                            <TextInput
                                                value={answer.imageLabel}
                                                errorMessages={
                                                    errors.questions?.get(question.id)?.answers?.get(answer.id)
                                                        ?.imageLabel
                                                }
                                                onUpdate={value =>
                                                    onUpdateQuestionAnswer(question.id, answer.id, {
                                                        [AnswerModel.imageLabel]: value,
                                                    })
                                                }
                                            />
                                        </ElementsGroup>
                                    )}
                                    <ul className="actions">
                                        {isRemovable(answer.id) && (
                                            <li
                                                data-icon="remove"
                                                title={t('Remove')}
                                                onClick={() => onRemoveAnswer(question.id, answer.id)}
                                            />
                                        )}
                                    </ul>
                                </div>
                                {question.isText ? (
                                    <TextArea
                                        label={t('Description')}
                                        rows={1}
                                        value={answer.description}
                                        onUpdate={value =>
                                            onUpdateQuestionAnswer(question.id, answer.id, {
                                                [AnswerModel.description]: value,
                                            })
                                        }
                                    />
                                ) : (
                                    <TextArea
                                        label={t('Description')}
                                        rows={1}
                                        value={answer.imageDescription}
                                        onUpdate={value =>
                                            onUpdateQuestionAnswer(question.id, answer.id, {
                                                [AnswerModel.imageDescription]: value,
                                            })
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                )}
                {question.answers.length < MAX_ANSWERS_IN_QUESTION && (
                    <AddListItemButton
                        title={t('Add answer')}
                        length={question.answers.length}
                        onClick={() => onAddAnswer(question.id)}
                    />
                )}
            </div>
        </div>
    )
}

export default Question
