import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getUint8Array } from 'utils/file'

import { UPLOAD_PUBLIC_TEMPLATE_PREVIEW_IMAGE, UPLOAD_PUBLIC_TEMPLATE_GALLERY_IMAGE } from 'api/media'

import Toast from 'components/Toast/Toast'
import { SUPPORT_IMAGE_TYPES } from 'components/AssetManager/utils/constants'

import { MAX_FILE_SIZE } from './constants'

import styles from './InputFile.module.scss'

const InputFile = forwardRef(({ onChangeLoading, onChange, projectId, isGalleryImage }, ref) => {
    const { t } = useTranslation()

    return (
        <input
            ref={ref}
            className={styles.input}
            type="file"
            onChange={async ({ target: { files } }) => {
                if (!files.length) return
                onChangeLoading(true)
                try {
                    const file = files[0]
                    if (file.size > MAX_FILE_SIZE) {
                        Toast('error', {
                            title: t('File size is too large'),
                            message: t('Max file size {{number}} {{units}}', {
                                number: MAX_FILE_SIZE / 1024 / 1024,
                                units: 'Mb',
                            }),
                        })
                        return
                    }
                    const uint8Array = await getUint8Array(file)
                    let _url
                    if (isGalleryImage) {
                        _url = await UPLOAD_PUBLIC_TEMPLATE_GALLERY_IMAGE(file.type, uint8Array, projectId)
                    } else {
                        _url = await UPLOAD_PUBLIC_TEMPLATE_PREVIEW_IMAGE(file.type, uint8Array, projectId)
                    }
                    await onChange(_url)
                } catch (err) {
                    console.error(err)
                    Toast('error', {})
                } finally {
                    onChangeLoading(false)
                }
            }}
            value={undefined}
            accept={Array.from(SUPPORT_IMAGE_TYPES).join(',')}
        />
    )
})

export default InputFile
