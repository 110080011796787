const Clone = ({ color = '#3C3C3C' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="9.5" y1="7.5" x2="9.5" y2="11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <line x1="11.5" y1="9.5" x2="7.5" y2="9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <rect
            x="4.5"
            y="4.5"
            width="10"
            height="10"
            rx="1.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H10C10.8284 1.5 11.5 2.17157 11.5 3V4.5H6C5.17157 4.5 4.5 5.17157 4.5 6V11.5H3C2.17157 11.5 1.5 10.8284 1.5 10V3Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Clone
