import React from 'react'
import ZoomMap from '../components/ZoomMap/ZoomMap'

/**
 * Zoom Map with visible buttons
 */
class InteractiveImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            blockData: { t },
        } = this.props
        return (
            <div className={`block __${t}`}>
                <ZoomMap {...this.props} />
            </div>
        )
    }
}

export default InteractiveImage
