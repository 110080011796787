import React from 'react'
import { useTranslation } from 'react-i18next'

import LoaderEllipsis from 'components/LoaderEllipsis/LoaderEllipsis'

import styles from './Loader.module.scss'

const Loader = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.loader} title={t('Loading')}>
            <LoaderEllipsis />
        </div>
    )
}

export default Loader
