import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './setupTests.js'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Bowser from 'bowser'
import { createBrowserHistory } from 'history'

import i18n from './i18n'
import store from './store'
import { init as validatorInit } from 'shared/validation'
import * as serviceWorker from './serviceWorker'

import ErrorLogging from './common/services/ErrorLogging'
import AnalyticsMonitor from './common/services/AnalyticsMonitor'
import App from './App'

const browser = Bowser.getParser(window.navigator.userAgent)
window.ua = browser.parsedResult
const isValidBrowser = browser.satisfies({
    windows: {
        ie: '>12',
    },
})

const history = createBrowserHistory()
ErrorLogging.init(history)
validatorInit(i18n)
AnalyticsMonitor.init()

render(
    <>
        {isValidBrowser ||
            (isValidBrowser === undefined && (
                <Provider store={store}>
                    <App history={history} />
                </Provider>
            ))}
    </>,
    document.getElementById('root'),
)

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store
}

serviceWorker.unregister()
