import React from 'react'

import { SECTION_TYPE } from '../../constants'

import BlockText from './components/BlockText/BlockText'
import BlockImage from './components/BlockImage/BlockImage'

import styles from './SectionContent.module.scss'

const SectionContent = ({ height, bottom, width, sectionType, section, textColor, fontFamily }) => (
    <div
        className={styles.sectionContent}
        style={{
            height,
            bottom,
            width,
        }}
    >
        {sectionType === SECTION_TYPE.TEXT && (
            <BlockText
                width={width}
                height={height}
                text={section.text}
                textColor={textColor}
                fontFamily={fontFamily}
            />
        )}
        {sectionType === SECTION_TYPE.IMAGE && <BlockImage height={height} imageUrl={section.imageUrl} />}
    </div>
)

export default SectionContent
