import React from 'react'

import styles from './Tooltip.module.scss'

const Tooltip = ({
    data: {
        point: { data },
    },
    yLabel,
    xLabel,
}) => (
    <ul className={styles.tooltip}>
        <li className={styles.item}>
            {yLabel}:&nbsp;<span>{data.yFormatted}</span>
        </li>
        <li className={styles.item}>
            {xLabel}:&nbsp;<span>{data.xFormatted}</span>
        </li>
    </ul>
)

export default Tooltip
