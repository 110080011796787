import React, { useState } from 'react'

import QuestionModal from '../components/Modals/QuestionModal/QuestionModal'
import QuestionView from '../components/QuestionView/QuestionView'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const Question = ({ blockData: { struct, colorTheme, id }, pagesData, onUpdate }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <QuestionView question={struct.question} />

            {isShowModal && (
                <QuestionModal
                    data={{
                        pages: pagesData,
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    settings={{ max_width: 960, overlayClose: false }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Question
