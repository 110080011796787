import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import useOptimalTextFontStyles from 'hooks/useOptimalTextFontStyles'

import { CARD_TYPES, COLORS_KEYS } from '../../../../constants'

import LikeButton from './components/LikeButton/LikeButton'
import Description from './components/Description/Description'

import './Votes.scss'

const Votes = ({
    isMobile,
    isLeading,
    votesCount,
    fontFamily,
    description,
    isNoDescription,
    cardType,
    onUpdateDescriptionFontSize,
    minDescriptionFontSize,
    colors,
}) => {
    const { t } = useTranslation()

    const { parentRef, textRef, sizes, styles } = useOptimalTextFontStyles({
        fontFamily,
        text: t('Votes:').toUpperCase(),
        normalSize: 18,
        maxSize: 24,
        minSize: 12,
        fontWeight: 300,
        lineHeight: 1,
    })

    return (
        <div
            className={classNames('rank-battle__votes', {
                'rank-battle__votes--is-text-card': cardType === CARD_TYPES.TEXT,
                'is-mobile': isMobile,
            })}
            style={{
                '--gradientColor': colors[COLORS_KEYS.CARD_BACKGROUND],
                '--mobileBorderColor': colors[COLORS_KEYS.VOTES_MOBILE_BORDER],
            }}
        >
            <div
                className={classNames('rank-battle__votes__button-box', {
                    'rank-battle__votes__button-box--is-no-description': isNoDescription,
                    'is-mobile': isMobile,
                })}
            >
                <LikeButton votesCount={votesCount || 0} color={colors[COLORS_KEYS.VOTES_LIKE_ICON]} />
            </div>
            {!isNoDescription && (
                <Description
                    isMobile={isMobile}
                    fontFamily={fontFamily}
                    color={colors[COLORS_KEYS.VOTES_TEXT]}
                    isLeading={isLeading}
                    text={description}
                    onUpdateFontSize={onUpdateDescriptionFontSize}
                    minFontSize={minDescriptionFontSize}
                />
            )}
            <div
                ref={parentRef}
                className={classNames('rank-battle__votes__inner', { 'is-mobile': isMobile })}
                style={{
                    fontFamily: `"${fontFamily}", sans-serif`,
                    color: colors[COLORS_KEYS.VOTES_TEXT],
                    lineHeight: 1,
                }}
            >
                <div
                    ref={textRef}
                    style={styles}
                    className={classNames('rank-battle__votes-label', { 'is-mobile': isMobile })}
                >
                    {t('Votes:')}
                </div>
                <div className={classNames('rank-battle__votes__count-wrapper', { 'is-mobile': isMobile })}>
                    <span
                        key={votesCount}
                        className={classNames('rank-battle__votes__count', { 'is-mobile': isMobile })}
                    >
                        {votesCount}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Votes
