import i18n from 'i18n'

export const COLUMNS = {
    DATE: 'DATE',
    PRODUCT_NAME: 'PRODUCT_NAME',
    TYPE: 'TYPE',
    TOTAL: 'TOTAL',
    ACTIONS: 'ACTIONS',
    INVOICE: 'INVOICE',
}

export const COLUMNS_DATA = {
    [COLUMNS.DATE]: {
        label: i18n.t('Date'),
        width: '10%',
    },
    [COLUMNS.PRODUCT_NAME]: {
        label: i18n.t('Product name'),
        width: '30%',
    },
    [COLUMNS.TYPE]: {
        label: i18n.t('Type'),
        width: '10%',
    },
    [COLUMNS.TOTAL]: {
        label: i18n.t('Total'),
        width: '10%',
    },
    [COLUMNS.ACTIONS]: {
        label: i18n.t('Actions'),
        width: '25%',
    },
    [COLUMNS.INVOICE]: {
        label: i18n.t('Invoice'),
        width: '15%',
    },
}
