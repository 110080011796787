import React from 'react'
import Pagination from 'components/Pagination'
import { useTranslation } from 'react-i18next'
import ProjectListItem from '../ProjectListItem/ProjectListItem'
import ProjectStatusesFilter, { ALL_PROJECT_FILTER_ITEM } from '../ProjectStatusesFilter/ProjectStatusesFilter'
import EmptyProjectList from './EmptyProjectList/EmptyProjectList'
import StubProjectList from './StubProjectList'
import styles from './ProjectList.module.scss'

export const ProjectList = ({
    isLoading,
    projects,
    isProjectCloning,
    onOpenModal,
    onEditProject,
    onOpenStartGameModal,
    onOpenShareModal,
    onDuplicateProject,
    onChangeProjectVisibility,
    onCopyToOrganization,
    onPaginationClick,
    onRenameProject,
    onCreateProject,
    onChangeFilter,
    onMoveProject,
}) => {
    const { t } = useTranslation()

    const renderList = () => {
        if (isLoading) return <StubProjectList />
        if (projects.content.length === 0)
            return (
                <EmptyProjectList
                    hasAppliedFilter={projects.status !== ALL_PROJECT_FILTER_ITEM}
                    onCreateProject={onCreateProject}
                />
            )
        else
            return (
                <>
                    <ul>
                        {projects.content.map(item => (
                            <ProjectListItem
                                key={item.id}
                                project={item}
                                isProjectCloning={isProjectCloning}
                                onEditProject={onEditProject}
                                onDuplicateProject={onDuplicateProject}
                                onChangeProjectVisibility={onChangeProjectVisibility}
                                onCopyToOrganization={onCopyToOrganization}
                                onOpenModal={onOpenModal}
                                onOpenStartGameModal={onOpenStartGameModal}
                                onOpenShareModal={onOpenShareModal}
                                onRenameProject={onRenameProject}
                                onMoveProject={onMoveProject}
                            />
                        ))}
                    </ul>
                    <div className={`pagination-wrapper${projects.totalPages > 1 ? ' is-visible' : ''}`}>
                        <Pagination
                            settings={{ disableInitCallback: true, marginPages: 1, pageRange: 4 }}
                            data={{
                                currentPage: projects.page,
                                totalPages: projects.totalPages,
                            }}
                            events={{ handleClick: onPaginationClick() }}
                        />
                    </div>
                </>
            )
    }

    return (
        <div>
            <div className={styles.controlPanel}>
                <span className={styles.title}>{t('Projects')}</span>
                <ProjectStatusesFilter selected={projects.status} onChange={onChangeFilter} />
            </div>
            <div className={styles.projectListArea}>{renderList()}</div>
        </div>
    )
}
