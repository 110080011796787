import { Client } from '@stomp/stompjs'

import { MULTIPLAYER_WS_URL } from '../constants'

export default class WebSocket {
    #client
    #isConnected = false
    #onConnectEvent
    #onDisconnectEvent

    constructor({ onConnectEvent, onDisconnectEvent }) {
        this.#onConnectEvent = onConnectEvent
        this.#onDisconnectEvent = onDisconnectEvent
    }

    // Public methods
    connect = async ({ clientId, gameId, masterPin }) =>
        new Promise(resolve => {
            const client = new Client({
                brokerURL: `${MULTIPLAYER_WS_URL}?clientId=${clientId}&projectIdPin=${gameId}${
                    masterPin ? `&masterPin=${masterPin}` : ''
                }`,
                onConnect: () => {
                    this.#client = client
                    this.#isConnected = true
                    this.#onConnectEvent()
                    resolve()
                },
                onStompError: frame => {
                    console.error({
                        message: frame.headers['message'],
                        details: frame.body,
                    })
                    this.disconnect()
                    this.#isConnected = false
                    this.#onDisconnectEvent()
                    resolve()
                },
                onDisconnect: () => {
                    this.#isConnected = false
                    this.#onDisconnectEvent()
                },
                onWebSocketError: () => {
                    this.disconnect()
                    this.#isConnected = false
                    this.#onDisconnectEvent()
                },
                reconnectDelay: 2000,
            })
            client.activate()
        })

    disconnect = () => {
        if (!this.#isConnected) return
        this.#client.deactivate()
    }

    sendMessage = (destination, body) => {
        if (!this.#isConnected) return
        this.#client.publish({
            destination,
            body: JSON.stringify(body),
        })
    }

    subscribe = (destination, onMessageCallback) => {
        if (!this.#isConnected) return
        return this.#client.subscribe(destination, msg => onMessageCallback(msg.body))
    }

    unsubscribe = subscription => {
        if (!this.#isConnected) return
        subscription.unsubscribe()
    }
}
