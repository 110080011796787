function isInt(value) {
    const x = parseFloat(value)
    return !isNaN(value) && (x | 0) === x
}

export const checkAdminAuth = email => {
    const splitted = email.split(':')
    if (splitted.length !== 2) return false
    if (!splitted[0].length) return false
    if (!splitted[1].length) return false
    if (!isInt(splitted[1])) return false
    return {
        login: splitted[0],
        userId: splitted[1],
    }
}
