import React, { useContext, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { API__MAILCHIMP } from 'api'
import useOAuthPopup from '../OAuth/useOAuthPopup'
import useDialog from '../Modal/NotificationDialog/useDialog'
import { FIELD_KEYS } from 'pages/Editor/Tabs/Editor/Modals/LeadFormModal/constants'
import { dialogInfoEmailIsRequiredField, dialogInfoMailchimpDisabled, MAILCHIMP_ID, popupTitle } from './constants'
import { MailChimpContext } from './MailChimpContext'
import { CONDITIONS, hasPermission } from '../../common/utils/permissions'

export function useMailChimpManager({ fields }) {
    const canUseMailchimp = hasPermission(CONDITIONS.CAN_USE_INTEGRATIONS)
    const show = useDialog()

    const {
        setIsOpen,
        organizationId,
        projectId,
        blockId,
        authUrl,
        redirectUrl,
        setLeadFormFields,
        connector,
        setConnector,
        isAuthorized,
        setIsAuthorized,
    } = useContext(MailChimpContext)

    useEffect(() => {
        if (canUseMailchimp)
            API__MAILCHIMP.GET_ENABLED_INTEGRATIONS(organizationId, projectId).then(integrations => {
                const isMailChimpConnected = integrations.includes(MAILCHIMP_ID)
                setIsAuthorized(isMailChimpConnected)
            })
    }, [organizationId])

    useEffect(() => {
        if (canUseMailchimp)
            API__MAILCHIMP.GET_CONNECTOR(organizationId, projectId, blockId)
                .then(_connector => {
                    setConnector(_connector)
                })
                .catch(err => (err.response.status === 404 ? null : err))
    }, [organizationId, projectId, blockId])

    const onAuthCompletedSuccessfully = code => {
        API__MAILCHIMP.CREATE_CONFIG(organizationId, code, redirectUrl, projectId).then(() => {
            setIsAuthorized(true)
            setLeadFormFields(fields)
            setIsOpen(true)
        })
    }

    const { startAuth } = useOAuthPopup(popupTitle, authUrl, redirectUrl, onAuthCompletedSuccessfully)

    const openManager = () => {
        const hasRequiredEmailField = fields.find(field => field.key === FIELD_KEYS.EMAIL)
        if (!hasRequiredEmailField) {
            show(dialogInfoEmailIsRequiredField)
            return
        }
        if (!isAuthorized) startAuth()
        else {
            setLeadFormFields(fields)
            setIsOpen(true)
        }
    }

    const switchConnector = ({ notify = false }) => {
        if (!isAuthorized || isEmpty(connector)) return

        const hasRequiredEmailField = fields.find(field => field.key === FIELD_KEYS.EMAIL)
        if (connector.disabled && !hasRequiredEmailField) {
            show(dialogInfoEmailIsRequiredField)
            return
        }

        API__MAILCHIMP.SWITCH_CONNECTOR(connector.connectorId, organizationId, projectId).then(_connector => {
            setConnector(_connector)
            if (notify) {
                show(dialogInfoMailchimpDisabled)
            }
        })
    }

    const isEnabled = !isEmpty(connector) && !connector.disabled && isAuthorized
    return { openManager, switchConnector, isEnabled, canUseMailchimp }
}
