import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextArea from 'components/Form/TextArea/TextArea'

import ActionsDropdown, { TYPES } from '../../components/ActionsDropdown/ActionsDropdown'

import './Page.scss'

const Page = ({ page, selectedPage, index, arrayLength, methods }) => {
    const { t } = useTranslation()
    const [name, setName] = useState(() => (page ? page.name : ''))
    const [isShowInput, setIsShowInput] = useState(false)

    const onClickRename = () => {
        setIsShowInput(true)
    }

    const onApplyRename = () => {
        const newName = name.trim()
        methods.onRenamePage(page.id, newName)
        setName(newName)
        setIsShowInput(false)
    }

    return (
        <div
            className={classNames('internal-pages__page', { select: selectedPage && selectedPage.id === page.id })}
            onClick={() => methods.onSelectPage(page)}
        >
            <div className="internal-pages__page-label">
                {isShowInput ? (
                    <>
                        <div className="internal-pages__page-input-wrapper" onClick={onApplyRename} />
                        <TextArea
                            className="internal-pages__page-input"
                            value={name}
                            onUpdate={val => setName(val)}
                            rows={2}
                            maxLength={18}
                            autoFocus
                            onFocus={evt => evt.target.select()}
                        />
                    </>
                ) : (
                    t(page.name)
                )}
            </div>

            <div className={classNames('internal-pages__page-img', { 'is-has-blocks': !!page.blocks.length })} />

            <ActionsDropdown
                index={index}
                type={TYPES.PAGE}
                arrayLength={arrayLength}
                methods={{
                    onClickRename,
                    onRemove: () => methods.onRemove(page.id),
                    onClone: () => methods.onClone(page.id),
                    onChangePosition: methods.onChangePosition,
                }}
            />
        </div>
    )
}

export default Page
