import React from 'react'

import './Radio.scss'

const Radio = ({ label = '', value, options = [], onUpdate, isDisabled }) => {
    const onChange = item => {
        if (!isDisabled) {
            try {
                onUpdate(item.value)
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <div className="common-radio">
            {label.length ? <p className="common-radio__label">{label}</p> : null}
            <div className="common-radio__content">
                {options.map(item => (
                    <div
                        key={item.value}
                        className={`common-radio__radio-option ${value === item.value ? 'is-checked' : ''}`}
                        onClick={() => onChange(item)}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Radio
