const Remove = ({ color = '#3C3C3C' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5 3.5H12.5V13C12.5 13.8284 11.8284 14.5 11 14.5H5C4.17157 14.5 3.5 13.8284 3.5 13V3.5Z"
            stroke={color}
        />
        <path
            d="M5.5 2C5.5 1.72386 5.72386 1.5 6 1.5H10C10.2761 1.5 10.5 1.72386 10.5 2V3.5H5.5V2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line x1="2.5" y1="3.5" x2="13.5" y2="3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <line x1="6.5" y1="6.5" x2="6.5" y2="11.5" stroke={color} strokeLinecap="round" />
        <line x1="9.5" y1="6.5" x2="9.5" y2="11.5" stroke={color} strokeLinecap="round" />
    </svg>
)

export default Remove
