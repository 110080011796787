import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Toast from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { API__USER } from 'api'

import styles from './StripeConnect.module.scss'
import iconStopHand from '../AccountDelete/i/stopHand.svg'

const StripeConnect = ({ userId, payoutProfile, onRefreshUserPublicProfileData }) => {
    const { t } = useTranslation()

    const [isOpenedRemoveDialog, setIsOpenedRemoveDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className={styles.stripeConnect}>
            <div className={styles.buttonBlock}>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary"
                    disabled={isLoading}
                    onClick={async () => {
                        try {
                            setIsLoading(true)
                            if (payoutProfile) {
                                const { onboardingLink } = await API__USER.GET_STRIPE_ACCOUNT_UPDATE_LINK(userId)
                                window.location.href = onboardingLink
                            } else {
                                const { onboardingLink } = await API__USER.GET_STRIPE_ACCOUNT_CREATE_LINK(userId)
                                window.location.href = onboardingLink
                            }
                        } catch (err) {
                            console.error(err)
                            Toast('error', {})
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                >
                    {payoutProfile ? t('Update Account') : t('Connect Account')}
                </button>
                {payoutProfile && (
                    <>
                        {payoutProfile?.payoutsEnabled && (
                            <button
                                className="button"
                                data-size="md"
                                data-variant="primary"
                                disabled={isLoading}
                                onClick={async () => {
                                    try {
                                        setIsLoading(true)
                                        const { loginLink } = await API__USER.GET_STRIPE_ACCOUNT_LOGIN_LINK(userId)
                                        window.location.href = loginLink
                                    } catch (err) {
                                        console.error(err)
                                        Toast('error', {})
                                    } finally {
                                        setIsLoading(false)
                                    }
                                }}
                            >
                                {t('View Dashboard')}
                            </button>
                        )}
                        <button
                            className="button"
                            data-size="md"
                            data-variant="primary-danger"
                            disabled={isLoading}
                            onClick={() => setIsOpenedRemoveDialog(true)}
                        >
                            {t('Remove Account')}
                        </button>
                    </>
                )}
            </div>

            {isOpenedRemoveDialog && (
                <ConfirmationDialog
                    isLoading={isLoading}
                    onClose={() => setIsOpenedRemoveDialog(false)}
                    onAction={async () => {
                        try {
                            setIsLoading(true)
                            await API__USER.DELETE_STRIPE_ACCOUNT(userId)
                            await onRefreshUserPublicProfileData()
                            setIsOpenedRemoveDialog(false)
                        } catch (err) {
                            console.error(err)
                            Toast('error', {})
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                    data={{
                        headText: t('Wait!'),
                        image: iconStopHand,
                        noteText: (
                            <>
                                {t('You are about to delete your Stripe account.')}
                                <br />
                                <Trans
                                    i18nKey="This action <tag>cannot be undone</tag>."
                                    components={{
                                        tag: <span style={{ color: '#fd8d8d' }} />,
                                    }}
                                />
                            </>
                        ),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </div>
    )
}

export default StripeConnect
