import React from 'react'
import { useTranslation } from 'react-i18next'

import Description from '../../../../components/Description/Description'

import Block from './components/Block/Block'

import styles from './AgeSelector.module.scss'

const AgeSelector = ({ tags, selectedTags, onUpdateSelectedTags }) => {
    const { t } = useTranslation()

    const onHandleTagClick = clickedTag => {
        if (selectedTags.some(tag => tag.name === clickedTag.name)) {
            onUpdateSelectedTags(selectedTags.filter(tag => tag.name !== clickedTag.name))
        } else {
            onUpdateSelectedTags([...selectedTags, clickedTag])
        }
    }

    return (
        <div className={styles.ageSelector}>
            <Description
                width="50%"
                title={t('What is the average age of your students?')}
                subTitle={t(
                    "Depending on your choice, we will be able to offer more children's or more adult educational materials.",
                )}
            />
            <ul className={styles.list}>
                {tags.map(({ parentTag }) => (
                    <Block
                        key={parentTag.id}
                        isActive={selectedTags.some(tag => tag.name === parentTag.name)}
                        onTagClick={() => onHandleTagClick(parentTag)}
                        text={parentTag.formattedName}
                    />
                ))}
            </ul>
        </div>
    )
}

export default AgeSelector
