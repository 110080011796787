export const getAvatarText = userDetails => {
    let result = userDetails.email.charAt(0).toUpperCase()
    if (userDetails.firstName && userDetails.firstName.length) {
        result = userDetails.firstName.charAt(0).toUpperCase()
    }
    if (userDetails.lastName && userDetails.lastName.length) {
        result = result + userDetails.lastName.charAt(0).toUpperCase()
    }
    return result
}
