import React, { useState } from 'react'
import WebhooksManagerModal from './components/WebhooksManagerModal/WebhooksManagerModal'
import { useSelector } from 'react-redux'

let WebhooksContext
const { Provider } = (WebhooksContext = React.createContext())

const WebhooksProvider = ({ children, projectId, blockId }) => {
    const organizationId = useSelector(state => state.organizations.selectedOrganizationId)
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Provider
            value={{
                projectId,
                blockId,
                setIsOpen,
                isOpen,
                organizationId,
            }}
        >
            {isOpen && (
                <WebhooksManagerModal
                    onClose={() => setIsOpen(false)}
                    organizationId={organizationId}
                    projectId={projectId}
                    blockId={blockId}
                />
            )}
            {children}
        </Provider>
    )
}

export { WebhooksContext, WebhooksProvider }
