import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Select, { VARIANT } from 'components/Form/Select/Select'

import countryDataset from 'datasets/country.json'
import languageDataset from 'datasets/language.json'

import Tags from 'components/Form/Tags/Tags'
import FillMoreInfoModal from 'components/Modal/FillMoreInfoModal/FillMoreInfoModal'
import { FIELDS } from 'components/Modal/FillMoreInfoModal/constants'
import Avatar from 'components/Avatar/Avatar'
import Toast from 'components/Toast/Toast'

import { API__USER } from 'api'

import { createCollector, createValidator, required } from 'shared/validation'

import { USER_PUBLIC_PROFILE_COVER_URLS } from 'common/constants/user'

import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormTextInput from 'components/Form/components/Helpers/FormTextInput/FormTextInput'
import FormTextArea from 'components/Form/components/Helpers/FormTextArea/FormTextArea'
import FormCombinedBlocks from 'components/Form/components/Helpers/FormCombinedBlocks/FormCombinedBlocks'
import FormCombinedBlock from 'components/Form/components/Helpers/FormCombinedBlock/FormCombinedBlock'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'

import { getInitialStates } from './utils'

import styles from './ActivationPublicPage.module.scss'

const ActivationPublicPage = ({
    userDetails,
    userPublicProfile,
    onUpdateUserPublicProfile,
    onUpdateUser,
    onActivate,
}) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [initialStates, setInitialStates] = useState(() => getInitialStates(userPublicProfile))
    useEffect(() => setInitialStates(getInitialStates(userPublicProfile)), [userPublicProfile])

    const [publicProfileTags, setPublicProfileTags] = useState([])

    const [selectedTags, setSelectedTags] = useState(initialStates.selectedTags)

    const [firstName, setFirstName] = useState(userDetails.firstName || '')
    const [lastName, setLastName] = useState(userDetails.lastName || '')

    const [title, setTitle] = useState(initialStates.title)
    const [description, setDescription] = useState(initialStates.description)
    const [country, setCountry] = useState(initialStates.country)
    const [language, setLanguage] = useState(initialStates.language)

    const [errors, setErrors] = useState({})

    const [isOpenedFillProfileModal, setIsOpenedFillProfileModal] = useState(false)

    useEffect(() => {
        API__USER.GET_AUTHORS_TAGS()
            .then(({ content }) => setPublicProfileTags(content))
            .catch(err => {
                console.error(err)
            })
    }, [])

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const fields = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                country,
                language,
                title: title.trim(),
                description: description.trim(),
            }

            const validationRules = {
                firstName: [required()],
                lastName: [required()],
                country: [required()],
                language: [required()],
                title: [required()],
                description: [required()],
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            delete fields.country
            fields.countryCode = country.value
            delete fields.language
            fields.languageCode = language.value

            if (!userDetails.avatarUrl) {
                setIsOpenedFillProfileModal(true)
                return
            }

            if (userDetails.firstName !== firstName || userDetails.lastName !== lastName) {
                await onUpdateUser({ firstName, lastName })
            }

            await onUpdateUserPublicProfile({
                ...fields,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                avatarUrl: userDetails.avatarUrl,
                email: userDetails.email,
                headerLogoUrl: userPublicProfile?.headerLogoUrl || USER_PUBLIC_PROFILE_COVER_URLS[0],
                tagIds: selectedTags,
            })
            const updatedProfile = await API__USER.CHANGE_PROFILE_ACTIVATION(userDetails.id, true)
            onActivate(updatedProfile)
            Toast('success', {
                message: t('Your public page is activated successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Form>
                <FormInputs>
                    <FormCombinedBlocks>
                        <div className={styles.avatarBlock}>
                            <Avatar />
                        </div>
                        <div className={styles.inputsBlock}>
                            <FormInputBlock>
                                <FormTextInput
                                    maxLength={64}
                                    errorMessages={errors.firstName || []}
                                    label={t('First name')}
                                    value={firstName}
                                    onUpdate={v => {
                                        setFirstName(v)
                                        setErrors({ ...errors, firstName: [] })
                                    }}
                                />
                            </FormInputBlock>
                            <FormInputBlock>
                                <FormTextInput
                                    maxLength={64}
                                    errorMessages={errors.lastName || []}
                                    label={t('Last name')}
                                    value={lastName}
                                    onUpdate={v => {
                                        setLastName(v)
                                        setErrors({ ...errors, lastName: [] })
                                    }}
                                />
                            </FormInputBlock>
                        </div>
                    </FormCombinedBlocks>
                    <FormCombinedBlocks>
                        <FormCombinedBlock>
                            <Select
                                errorMessages={errors.country || []}
                                labelClassName={styles.label}
                                label={t('Country')}
                                placeholder={t('Select')}
                                value={country}
                                isSearchable
                                variant={VARIANT.increased}
                                onUpdate={val => {
                                    setCountry(val)
                                    setErrors({ ...errors, country: [] })
                                }}
                                options={countryDataset.map(item => ({
                                    value: item.code,
                                    label: item.name,
                                }))}
                                fullWide
                            />
                        </FormCombinedBlock>
                        <FormCombinedBlock>
                            <Select
                                errorMessages={errors.language || []}
                                labelClassName={styles.label}
                                label={t('Language')}
                                placeholder={t('Select')}
                                value={language}
                                isSearchable
                                variant={VARIANT.increased}
                                onUpdate={val => {
                                    setLanguage(val)
                                    setErrors({ ...errors, language: [] })
                                }}
                                options={languageDataset.map(item => ({
                                    value: item.code,
                                    label: item.label,
                                }))}
                                fullWide
                            />
                        </FormCombinedBlock>
                    </FormCombinedBlocks>
                    <FormInputBlock>
                        <FormTextInput
                            label={t('Where do you teach / work')}
                            value={title}
                            onUpdate={v => {
                                setTitle(v)
                                setErrors({ ...errors, title: [] })
                            }}
                            maxLength={200}
                            errorMessages={errors.title || []}
                        />
                    </FormInputBlock>
                    <FormInputBlock>
                        <FormTextArea
                            label={t('About me')}
                            value={description}
                            onUpdate={value => {
                                setDescription(value)
                                setErrors({ ...errors, description: [] })
                            }}
                            maxLength={600}
                            placeholder={t('Tell us about your teacher experience')}
                            errorMessages={errors.description || []}
                        />
                    </FormInputBlock>
                    {!!publicProfileTags.length && (
                        <FormInputBlock>
                            <Tags
                                labelClassName={styles.label}
                                title={t('Select subjects that describe your content')}
                                selected={selectedTags}
                                maxLength={5}
                                list={publicProfileTags.map(el => ({ value: el.id, label: el.name }))}
                                onSelect={tag => {
                                    if (selectedTags.includes(tag.value)) {
                                        setSelectedTags(selectedTags.filter(el => el !== tag.value))
                                        return
                                    }
                                    setSelectedTags([...selectedTags, tag.value])
                                }}
                            />
                        </FormInputBlock>
                    )}
                </FormInputs>
                <FormButtons>
                    <button
                        className="button"
                        data-size="lg"
                        data-variant="primary"
                        disabled={isLoading}
                        onClick={() => onSubmit()}
                    >
                        {t('Activate and continue')}
                    </button>
                </FormButtons>
            </Form>
            {isOpenedFillProfileModal && (
                <FillMoreInfoModal
                    fields={[FIELDS.AVATAR]}
                    fieldsToFill={[userDetails.avatarUrl && FIELDS.AVATAR]}
                    onClose={() => setIsOpenedFillProfileModal(false)}
                />
            )}
        </>
    )
}

export default ActivationPublicPage
