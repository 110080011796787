import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import COOKIES from 'common/constants/cookies'

import { DEFAULT_LOCALE, SUPPORTED_LOCALES_DETAILS } from './languages'

const supportedLngs = SUPPORTED_LOCALES_DETAILS.map(({ code }) => code)
const resources = SUPPORTED_LOCALES_DETAILS.reduce(
    (acc, { code, translations }) => ({ ...acc, [code]: { translations } }),
    {},
)

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: DEFAULT_LOCALE,
        supportedLngs,
        preload: true,
        interpolation: {
            escapeValue: false,
        },
        cleanCode: true,
        ns: ['translations'],
        defaultNS: ['translations'],
        resources,
        nsSeparator: false,
        keySeparator: false,
        detection: {
            order: ['cookie', 'navigator'],
            lookupCookie: COOKIES.LOCALE,
            caches: [],
        },
        returnEmptyString: false,
    })

export default i18n
