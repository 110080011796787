import React from 'react'
import { CHART_CODES } from '../constants'

import Wrapper from './Wrapper/Wrapper'

const Views = ({ projectId, dateRange, t }) => (
    <Wrapper
        projectId={projectId}
        code={CHART_CODES.VIEWS}
        description={t('Number of views of the project')}
        isInteger={true}
        dateRange={dateRange}
    />
)

export default Views
