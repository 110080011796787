import React, { useState } from 'react'
import Helmet from 'react-helmet'

import languages, { findLanguageByCode } from '../i18n/languages'

const defaultTitleLabel = ' | Interacty'
const defaultKeywords = [
    'interacty',
    'interact',
    'create interactive content',
    'quiz maker',
    'create quiz for free',
    'generate leads',
    'segment audience',
    'create online quizzes',
    'create lead generation quiz',
    'interactive content',
    'quizzes',
    'create photogallery',
    'quizzes for marketers',
    'engage',
    'engagement',
    'analytics',
    'statistics',
    'integrations',
    'interactive formats',
    'lead generation',
    'online storytelling tools',
    'engaging content creation platform',
    'tool for marketers',
]

function SEO({
    title = null,
    description = null,
    thumbnail = null,
    thumbnailType = null,
    thumbnailWidth = null,
    thumbnailHeight = null,
    addTitleLabel = true,
    titleLabel = defaultTitleLabel,
    keywords = defaultKeywords,
    locale = null,
    siteName = 'Interacty',
    url = process.env.REACT_APP_STATIC_URL,
    type = 'website',
}) {
    const [language] = useState(() => findLanguageByCode(locale))

    return (
        <Helmet htmlAttributes={{ lang: locale || 'en' }}>
            {title ? <title>{`${title}${addTitleLabel ? titleLabel : ''}`}</title> : null}
            {title ? <meta property="og:title" content={`${title}${addTitleLabel ? titleLabel : ''}`} /> : null}
            {description ? <meta name="description" content={description} /> : null}
            {description ? <meta property="og:description" content={description} /> : null}
            {keywords.length ? <meta name="keywords" content={keywords.join(', ')} /> : null}
            {thumbnail ? <meta property="og:image" content={thumbnail} /> : null}
            {thumbnailType ? <meta property="og:image:type" content={thumbnailType} /> : null}
            {thumbnailWidth ? <meta property="og:image:width" content={thumbnailWidth} /> : null}
            {thumbnailHeight ? <meta property="og:image:height" content={thumbnailHeight} /> : null}
            {locale ? <meta property="og:locale" content={language.fullCode} /> : null}
            {locale
                ? languages
                      .filter(el => el.code !== locale && el.is_enabled)
                      .map(lng => <meta property="og:locale:alternate" content={lng.fullCode} key={lng.fullCode} />)
                : null}
            {siteName ? <meta property="og:site_name" content={siteName} /> : null}
            {siteName ? <meta property="og:url" content={url} /> : null}
            {siteName ? <meta property="og:type" content={type} /> : null}
        </Helmet>
    )
}

export default SEO
