import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import styles from './FinalPreview.module.scss'

const FinalPreview = ({ imageUrl, header, description, imageDisclaimer, buttonText, buttonUrl }) => {
    const { t } = useTranslation()
    const [isEmpty, setIsEmpty] = useState(false)

    useEffect(() => {
        setIsEmpty(!imageUrl && !header && !description && !(imageUrl && imageDisclaimer) && !(buttonText && buttonUrl))
    }, [imageUrl, header, description, imageDisclaimer, buttonText, buttonUrl])

    return (
        <ScrollableBlock className={styles.finalPreview}>
            <div className={styles.main}>
                <h4 className={styles.title}>{t('Approximate preview')}</h4>
                {isEmpty ? (
                    <div className={styles.noData}>{t('You have not entered any data yet')}</div>
                ) : (
                    <div className={styles.content}>
                        {!!imageUrl && (
                            <div className={styles.contentImage} style={{ backgroundImage: `url(${imageUrl})` }} />
                        )}
                        {!!header && <div className={styles.contentHeader}>{header}</div>}
                        {!!description && <div className={styles.contentDescription}>{description}</div>}
                        {!!imageDisclaimer && <div className={styles.contentImageDisclaimer}>{imageDisclaimer}</div>}
                    </div>
                )}
            </div>
        </ScrollableBlock>
    )
}

export default FinalPreview
