import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'
import TextInput from 'components/Form/TextInput/TextInput'

import { API__FOLDERS, HTTP_STATUS } from 'api'

import styles from './CreateFolderModal.module.scss'

const CreateFolderModal = ({ onAddedFolder, onClose }) => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const { selectedOrganizationId, selectedFolderId } = useSelector(state => ({
        selectedOrganizationId: state.organizations.selectedOrganizationId,
        selectedFolderId: state.organizations.selectedFolderId,
    }))

    const handleUpdateFolderName = val => {
        setName(val)
        setError('')
    }

    const handleCreateFolder = () => {
        API__FOLDERS.CREATE_FOLDER({
            organizationId: selectedOrganizationId,
            parentId: selectedFolderId,
            name,
        })
            .then(() => {
                onAddedFolder()
                onClose()
            })
            .catch(err => {
                if (err.response && err.response.status === HTTP_STATUS.Conflict)
                    setError(t('A folder with that name already exists'))
            })
    }

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={true} closeOnEsc={true} onClose={onClose}>
            <div className={styles.createFolderModal}>
                <div className={styles.title}>{t('Create folder')}</div>
                <TextInput
                    className={styles.input}
                    fullWide
                    value={name}
                    placeholder={t('New name')}
                    maxLength={64}
                    onUpdate={val => handleUpdateFolderName(val)}
                    errorMessages={error ? [error] : []}
                />
                <div className={styles.buttonGroup}>
                    <button className="button" data-variant="secondary" data-size="lg" onClick={onClose}>
                        {t('Cancel')}
                    </button>
                    <button
                        className="button"
                        data-variant="primary"
                        data-size="lg"
                        disabled={!name.trim().length}
                        onClick={handleCreateFolder}
                    >
                        {t('Create')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateFolderModal
