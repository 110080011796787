import React, { useContext, useEffect, useState } from 'react'
import { API__WEBHOOKS } from 'api'
import { useTranslation } from 'react-i18next'
import { WebhooksContext } from './WebhooksContext'
import { CONDITIONS, hasPermission } from 'common/utils/permissions'
import Toast from 'components/Toast/Toast'

export function useWebhooksManager() {
    const { t } = useTranslation()
    const canUseWebhooks = hasPermission(CONDITIONS.CAN_USE_INTEGRATIONS)
    const [webhooks, setWebhooks] = useState([])

    const { setIsOpen, isOpen, organizationId, projectId, blockId } = useContext(WebhooksContext)
    useEffect(() => {
        if (canUseWebhooks && !isOpen) {
            API__WEBHOOKS.GET_WEBHOOKS_BY_BLOCK({ organizationId, blockId, projectId })
                .then(_webhooks => {
                    setWebhooks(_webhooks)
                })
                .catch(error => {
                    Toast('error', {
                        message: t(error.response.data.message),
                    })
                    console.error(error)
                })
        }
    }, [organizationId, projectId, blockId, isOpen])

    const openManager = () => {
        setIsOpen(true)
    }

    const isEnabled = webhooks.some(webhook => !webhook.disabled)

    return { openManager, isEnabled, canUseWebhooks }
}
