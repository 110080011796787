import React from 'react'

import styles from './ActivityItem.module.scss'

const ActivityItem = ({ icon, value, label }) => (
    <li className={styles.activityItem}>
        <div className={styles.icon}>
            <img src={icon} alt="" />
        </div>
        <div className={styles.value}>{value}</div>
        <div className={styles.label}>{label}</div>
    </li>
)

export default ActivityItem
