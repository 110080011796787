import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EDUCATION_GROUP_ID } from 'common/constants/ProductConstants'
import { ROLES } from 'common/constants/user'

const useUser = () => {
    const { user_details } = useSelector(state => state)

    const [role, setRole] = useState(null)

    useEffect(() => {
        if (!user_details?.roleInProjectDto) {
            setRole(null)
            return
        }

        if (user_details.roleInProjectDto.userGroupInProjectDto.customId === EDUCATION_GROUP_ID) {
            setRole(ROLES.EDUCATION)
        } else {
            setRole(ROLES.BUSINESS)
        }
    }, [user_details])

    return {
        id: user_details.id,
        role,
    }
}

export default useUser
