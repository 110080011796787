export const USER_PUBLIC_PROFILE_STATUSES = {
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
}

export const USER_PUBLIC_PROFILE_COVER_URLS = [
    `${process.env.REACT_APP_S3_BUCKET_URL}/public_profile/1.png`,
    `${process.env.REACT_APP_S3_BUCKET_URL}/public_profile/2.png`,
    `${process.env.REACT_APP_S3_BUCKET_URL}/public_profile/3.png`,
    `${process.env.REACT_APP_S3_BUCKET_URL}/public_profile/4.png`,
    `${process.env.REACT_APP_S3_BUCKET_URL}/public_profile/5.png`,
]

export const ROLES = {
    BUSINESS: 'BUSINESS',
    EDUCATION: 'EDUCATION',
}
