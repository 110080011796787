import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import rainImage from './i/rain.png'

import styles from './Confirmation.module.scss'

const Confirmation = ({ onClose, onContinue }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.confirmation}>
            <div className={styles.image}>
                <img src={rainImage} alt="sad man" />
            </div>
            <div className={styles.title}>{t('Disable auto-renewal?')}</div>
            <div className={styles.description}>
                {t('Once your subscription expires, you will no longer have access to paid Interacty features.')}
            </div>
            <div className={styles.buttons}>
                <button
                    className={classNames('button', styles.button)}
                    data-size="md"
                    data-variant="secondary"
                    onClick={() => onClose()}
                >
                    {t('Cancel')}
                </button>
                <button
                    className={classNames('button', styles.button)}
                    data-size="md"
                    data-variant="primary-danger"
                    onClick={() => onContinue()}
                >
                    {t('Continue')}
                </button>
            </div>
        </div>
    )
}

export default Confirmation
