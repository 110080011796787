import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import { FIELDS, FIELDS_LABELS } from './constants'

import checkIcon from './i/check.svg'
import crossIcon from './i/cross.svg'

import styles from './FillMoreInfoModal.module.scss'

const FillMoreInfoModal = ({ fieldsToFill = [], fields = Object.values(FIELDS), title, subTitle, onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal onClose={onClose}>
            <div className={styles.fillYourProfile}>
                <div className={styles.title}>{title || t('Fill in your profile information')}</div>
                <div className={styles.subtitle}>
                    {subTitle || t('To make your profile public, specify in the settings:')}
                </div>
                <ul className={styles.list}>
                    {fields.map(field => (
                        <li key={field} className={styles.item}>
                            <img
                                className={styles.itemIcon}
                                src={fieldsToFill.includes(field) ? checkIcon : crossIcon}
                                alt=""
                            />
                            {FIELDS_LABELS[field]}
                        </li>
                    ))}
                </ul>
                <button className="button" data-size="lg" data-variant="primary" onClick={onClose}>
                    {t('All clear')}
                </button>
            </div>
        </Modal>
    )
}

export default FillMoreInfoModal
