import React, { useEffect, useState, useMemo } from 'react'
import { map } from 'lodash'
import classNames from 'classnames'
import ReactResizeDetector from 'react-resize-detector'

import { imgLoader } from 'common/services/imageLoader'
import { CARD_PROPORTIONS } from 'components/Form/CardProportions/constants'

import invertColor from '../../../../../../util/invertColor'

import { CARD_LAYOUT_TEMPLATE } from '../../utils/constants'
import { calculateCardSideSize } from '../../utils/utils'

import './MemoryPlaygroundPreview.scss'

const MemoryPlaygroundPreview = ({
    data: {
        cardBackImage,
        cardLayout,
        cardProportions,
        isShowCover,
        coverHeader,
        coverButtonText,
        colorTheme,
        numberedCardBacks,
        numberedCardBacksType,
    } = {},
    isModal,
}) => {
    const [width, setWidth] = useState(0)
    const [currentProportions, setCurrentProportions] = useState(CARD_PROPORTIONS[0].proportionHeight)
    const [playgroundSettings, setPlaygroundSettings] = useState(() => {
        const [cellCount, rowCount] = CARD_LAYOUT_TEMPLATE[0].value.split('x').map(x => Number(x))
        return {
            rowCount,
            cellCount,
        }
    })

    useEffect(() => {
        if (cardLayout && Object.keys(cardLayout).length) {
            try {
                const [cellCount, rowCount] = cardLayout.value.split('x').map(x => Number(x))

                setPlaygroundSettings({
                    rowCount,
                    cellCount,
                })
            } catch (e) {}
        }
    }, [cardLayout])

    useEffect(() => {
        if (cardProportions) {
            const _currentProportions = CARD_PROPORTIONS.find(proportions => cardProportions === proportions.value)
            setCurrentProportions(_currentProportions ? _currentProportions.proportionHeight : 1)
        }
    }, [cardProportions])

    const onResizeContainer = _width => setWidth(_width)

    const renderedRows = useMemo(() => {
        const rowHeight = `${calculateCardSideSize(width, playgroundSettings.cellCount, currentProportions)}px`
        const cellWidth = `${calculateCardSideSize(width, playgroundSettings.cellCount)}px`
        return map(new Array(playgroundSettings.rowCount), (row, rI) => (
            <div style={{ height: rowHeight }} key={rI} className="memory-card-preview__row">
                {map(new Array(playgroundSettings.cellCount), (cell, cI) => (
                    <div
                        style={{
                            width: cellWidth,
                            backgroundImage: cardBackImage ? `url(${imgLoader(cardBackImage)})` : '',
                        }}
                        key={cI}
                        className={classNames('memory-card-preview__cell', {
                            'number-option-1': numberedCardBacks && numberedCardBacksType?.value === 'option_1',
                            'number-option-2': numberedCardBacks && numberedCardBacksType?.value === 'option_2',
                        })}
                    />
                ))}
            </div>
        ))
    }, [playgroundSettings, currentProportions, width, cardBackImage, numberedCardBacks, numberedCardBacksType])

    return (
        <ReactResizeDetector refreshMode="debounce" refreshRate={0} handleWidth onResize={onResizeContainer}>
            <div className="memory-playground-preview">
                <div className="memory-playground-preview__rows-wrapper">{renderedRows}</div>
                {isShowCover && (
                    <div className="memory-playground-preview__cover-wrapper">
                        <div className={`memory-playground-preview__cover ${isModal ? 'is-modal' : ''}`}>
                            <div className="memory-playground-preview__cover-header">{coverHeader}</div>
                            <button
                                className="memory-playground-preview__cover-btn"
                                style={{
                                    color: invertColor(colorTheme, true),
                                    backgroundColor: colorTheme,
                                }}
                            >
                                {coverButtonText ? coverButtonText : ''}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </ReactResizeDetector>
    )
}

export default MemoryPlaygroundPreview
