import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import YoutubeModal from 'components/Modal/YoutubeModal/YoutubeModal'

import Description from '../../../../components/Description/Description'

import videoCoverImage from './i/videoCover.png'
import playIcon from './i/play.svg'

import styles from './Contacts.module.scss'

const Contacts = () => {
    const { t } = useTranslation()
    const [isShowVideoModal, setIsShowVideoModal] = useState(false)

    return (
        <div className={styles.contacts}>
            <Description
                title={t('Watch our short tutorial on how to create your first project')}
                subTitle={t('Our Help Center and YouTube channel are also at your service:')}
                isAddContacts
                width={340}
            />
            <div>
                <div className={styles.imageBox} onClick={() => setIsShowVideoModal(true)}>
                    <img src={videoCoverImage} alt="cover" />
                    <div className={styles.button}>
                        <img src={playIcon} alt="play" />
                    </div>
                </div>
            </div>
            {isShowVideoModal && (
                <YoutubeModal
                    title={t('How to build your first project')}
                    onClose={() => setIsShowVideoModal(false)}
                    videoId="4cJQp5THF94"
                />
            )}
        </div>
    )
}

export default Contacts
