import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'

import Switch from 'components/Form/Switch/Switch'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import InlineInput from 'components/Form/InlineInput/InlineInput'
import { IconDelete, IconRename, ShareArrowIcon, TripleDotsIcon } from 'svg/index.js'

import styles from './WebhooksMappingBlock.module.scss'

function WebhooksMappingBlock({ item, onSendTestData, onRemove, onSwitchWebhook, onUpdateWebhookUrl }) {
    const { t } = useTranslation()
    const [isEditableUrl, setIsEditableUrl] = useState(false)

    const onSaveUrl = val => {
        if (item.url !== val) {
            onUpdateWebhookUrl(val, item.id)
        }
        setIsEditableUrl(false)
    }

    const dropdownMenu = useMemo(() => {
        const actionMenuItems = [
            <p className={styles.dropdownItem} onClick={() => setIsEditableUrl(true)}>
                <span className={styles.dropdownIcon}>
                    <IconRename />
                </span>
                {t('Edit URL')}
            </p>,
            <p className={styles.dropdownItem} onClick={() => onSendTestData(item.id)}>
                <span className={styles.dropdownIcon}>
                    <ShareArrowIcon />
                </span>
                {t('Send test data')}
            </p>,
            <p className={styles.dropdownItem} onClick={() => onRemove(item.id)}>
                <span className={styles.dropdownIcon}>
                    <IconDelete />
                </span>
                {t('Delete')}
            </p>,
        ]

        return (
            <div>
                <DropdownMenu
                    className={classNames(styles.dropdown, 'us-n')}
                    placement={'bottomRight'}
                    actionHtml={<TripleDotsIcon />}
                    items={actionMenuItems}
                />
            </div>
        )
    }, [onSendTestData, onRemove])

    return (
        <div className={styles.group}>
            <InlineInput
                inputClassName={styles.input}
                value={item.url}
                maxLength={128}
                isEditable={isEditableUrl}
                onSave={onSaveUrl}
            />
            <div className={styles.groupActions}>
                <Tooltip title={t('Enable/Disable Webhook')} arrow disableInteractive enterDelay={500}>
                    <span>
                        <Switch checked={!item.disabled} onChange={() => onSwitchWebhook(item.id)} />
                    </span>
                </Tooltip>
                {dropdownMenu}
            </div>
        </div>
    )
}

export default WebhooksMappingBlock
