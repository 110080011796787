import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './HelpBox.module.scss'

const HelpBox = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.helpBox}>
            <p className={styles.text}>{t("Couldn't find a suitable tariff or need help?")}</p>
            <a href="/" className={styles.link}>
                {t('Contact us')}
            </a>
        </div>
    )
}

export default HelpBox
