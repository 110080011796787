import React, {useEffect, useState} from 'react'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

import Toast from 'components/Toast/Toast'

import { HIDDEN_ROLE_IDS } from "./constants"

import './Roles.scss'

const Roles = ({
    selectedGroup,
    backButtonText = 'Back',
    actionButtonText = 'Go!',
    onBack = () => {},
    onSelect = () => {},
    isCanBack,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const [roles, setRoles] = useState([])

    const [selectedRole, setSelectedRole] = useState(null)

    useEffect(() => {
        setRoles(selectedGroup.userRoleInProjectDtos.filter(role => !HIDDEN_ROLE_IDS.includes(role.id)))
    }, [selectedGroup])

    const onAction = async () => {
        setIsLoading(true)
        try {
            const group = cloneDeep(selectedGroup)
            delete group.userRoleInProjectDtos

            await onSelect({
                group,
                role: selectedRole,
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="user-role-modal__roles">
            <ul>
                {roles.map(role => (
                    <li
                        className={classNames({ 'is-selected': selectedRole && selectedRole.id === role.id })}
                        key={role.id}
                        onClick={() => setSelectedRole(role)}
                        data-test="role-item"
                    >
                        <span className="point"></span>
                        {role.roleTranslationDto.text}
                    </li>
                ))}
            </ul>
            <div className="buttons">
                {isCanBack && <span onClick={() => onBack()}>{backButtonText}</span>}
                <button
                    className="confirmation-dialog__button button"
                    data-size="lg"
                    disabled={!selectedRole || isLoading}
                    data-variant="primary"
                    onClick={() => onAction()}
                    data-test="apply-role"
                >
                    {actionButtonText}
                </button>
            </div>
        </div>
    )
}

export default Roles
