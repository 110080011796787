import React from 'react'
import classNames from 'classnames'

import markIcon from './i/mark.svg'

import styles from './Block.module.scss'

const Block = ({ isActive, onTagClick, text }) => (
    <li className={styles.block} onClick={onTagClick}>
        <div className={classNames(styles.checkbox, { [styles.isActiveCheckbox]: isActive })}>
            <img className={styles.icon} src={markIcon} alt="" />
        </div>
        <p className={styles.text}>{text}</p>
    </li>
)

export default Block
