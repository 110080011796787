export const getCardsGroupStyles = pairsCount => {
    const styles = {
        padding: 8,
        margin: 4,
    }
    if (pairsCount <= 4) {
        styles.padding = 14
        styles.margin = 6
    }
    return styles
}
