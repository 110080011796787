import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import Button from 'components/Form/Button/Button'

import image from './i/image.png'

import styles from './MultiplayerWelcome.module.scss'

const MultiplayerWelcome = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal closeOnEsc closeOnOverlayClick onClose={onClose} isShowCloseIcon>
            <div className={styles.multiplayerWelcome}>
                <div className={styles.imageBox}>
                    <img src={image} alt="" />
                </div>
                <div className={styles.title}>{t('Real-time game event')}</div>
                <p className={styles.description}>
                    {t(
                        'This is a game with or without a leader, in which all players connect simultaneously, and scoring occurs after each level and at the end of the game. This type of game is unsuitable for embedding on the site but is great for an event or a party.',
                    )}
                </p>
                <p className={styles.note}>
                    <Trans
                        i18nKey="Read how to create the perfect game for an event in our <lnk>Help Center</lnk>."
                        components={{
                            lnk: (
                                <a
                                    className={styles.noteLink}
                                    target="_blank"
                                    href="https://help.interacty.me/en/content/online-game-event"
                                />
                            ),
                        }}
                    />
                </p>
                <div className={styles.buttonBox}>
                    <Button onClick={onClose} className={styles.button}>
                        {t('All clear!')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default MultiplayerWelcome
