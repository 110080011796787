import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'

import styles from './InvalidWordsModal.module.scss'

const InvalidWordsModal = ({ words = [], onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} isShowCloseIcon onClose={onClose}>
            <div className={styles.invalidWordsModal}>
                <div className={styles.title}>{t('Some words cannot be added to the crossword puzzle.')}</div>
                <p className={styles.subtitle}>{t('There are not enough intersecting letters to add the words:')}</p>
                <ul className={styles.list}>
                    {words.map((word, i) => (
                        <li key={i} className={styles.item}>
                            {word}
                        </li>
                    ))}
                </ul>
                <div className={styles.note}>
                    <div className={styles.noteTitle}>{t('What you can do:')}</div>
                    <ul className={styles.noteList}>
                        <li className={styles.noteItem}>- {t('remove the above words')}</li>
                        <li className={styles.noteItem}>- {t('add more words to the crossword puzzle')}</li>
                        <li className={styles.noteItem}>
                            - {t('change words so that more intersecting letters appear')}
                        </li>
                    </ul>
                    <a
                        className={styles.readMoreLink}
                        href="https://help.interacty.me/en/content/creating-your-first-digital-crossword-a-step-by-step-guide"
                        target="_blank"
                    >
                        {t('Read more')}
                    </a>
                </div>
                <button
                    className={classNames('button', styles.button)}
                    data-variant="primary"
                    data-size="lg"
                    onClick={onClose}
                >
                    {t('Ok')}
                </button>
            </div>
        </Modal>
    )
}

export default InvalidWordsModal
