import { MAIN_SERVER } from './instance'

export const GET_FOLDERS = (organizationId, parentId) =>
    MAIN_SERVER({
        method: 'get',
        url: '/folders/',
        params: { organizationId, parentId, sort: 'name,asc', size: 100 },
    })

export const CREATE_FOLDER = ({ organizationId, parentId, name }) =>
    MAIN_SERVER({
        method: 'post',
        url: '/folders/',
        data: {
            organizationId,
            name: name.trim(),
            parentId,
        },
    })

export const UPDATE_FOLDER = ({ id, name, parentId }) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/folders/${id}`,
        data: {
            name: name ? name.trim() : name,
            parentId,
        },
    })

export const DELETE_FOLDER = (id, organizationId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/folders/${id}?organizationId=${organizationId}`,
    })
