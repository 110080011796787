import React from 'react'
import classnames from 'classnames'

import imageLoader from './i/preloader.gif'

import styles from './Loader.module.scss'

const Loader = ({ text = '', forModal = false, className }) => (
    <div className={classnames(styles.loader, { [styles.isModal]: forModal, [className]: !!className })}>
        <img src={imageLoader} alt="" />
        {text && <p>{text}</p>}
    </div>
)

export default Loader
