import React from 'react'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'

import styles from './ChooseImageModal.module.scss'

const ChooseImageModal = ({ title, selectedImageUrl, onClose, onSelect, urlsArray }) => (
    <Modal onClose={onClose} isShowCloseIcon>
        <div className={styles.chooseImageModal}>
            <h4 className={styles.title}>{title}</h4>
            <ul className={styles.list}>
                {urlsArray.map(url => (
                    <li
                        key={url}
                        className={classNames(styles.item, { [styles.isSelectedItem]: url === selectedImageUrl })}
                        onClick={() => onSelect(url)}
                        style={{ backgroundImage: `url('${url}')` }}
                    />
                ))}
            </ul>
        </div>
    </Modal>
)

export default ChooseImageModal
