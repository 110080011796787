import { useTranslation } from 'react-i18next'

import { getDate } from 'utils/dateTime'

import styles from './PayoutText.module.scss'

const PayoutText = ({ sum, date }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.payoutText}>
            <div className={styles.main}>
                {t('Future payouts')}
                <span className={styles.sum}>${sum}</span>
            </div>
            {!!date && <p className={styles.date}>{t('Expected {{date}}', { date: getDate(date) })}</p>}
        </div>
    )
}

export default PayoutText
