import { createCollector, createValidator, defined, itemRules, maxLength, required, minLength } from 'shared/validation'

import { BLOCK_KEYS, Item as ItemModel, Final as FinalModal } from './crosswordService'
import { MAX_ANSWER_LENGTH } from './constants'

function convertItemErrorsToMap(items, errors) {
    return new Map(items.map((item, index) => [item.id, errors[index]]))
}

const CROSSWORD_VALIDATION_RULES_FIELDS = {
    [BLOCK_KEYS.ITEMS]: [
        itemRules(
            {
                [ItemModel.id]: [required()],
                [ItemModel.question]: [required(), maxLength(500)],
                [ItemModel.answer]: [required(), minLength(2), maxLength(MAX_ANSWER_LENGTH)],
                [ItemModel.startX]: [defined()],
                [ItemModel.startY]: [defined()],
                [ItemModel.orientation]: [defined()],
                [ItemModel.isIncluded]: [defined()],
                [ItemModel.position]: [defined()],
            },
            convertItemErrorsToMap,
        ),
    ],
    [BLOCK_KEYS.FINAL]: {
        [FinalModal.header]: [required(), maxLength(500)],
        [FinalModal.description]: [maxLength(5000)],
        [FinalModal.image]: [defined()],
        [FinalModal.imageDisclaimer]: [maxLength(100)],
    },
}

export const validateTabs = (tmpStructure, activeTabKey = null) => {
    const isValid = createValidator(CROSSWORD_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)
    const errors = createCollector(CROSSWORD_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)

    return {
        tmpStructure,
        errors,
        isHasError: !isValid,
    }
}
