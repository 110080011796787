import React, { useState } from 'react'

import RankBattleModalModal from '../components/Modals/RankBattleModal/RankBattleModal'
import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

import Preview from './components/Preview/Preview'

const RankBattle = ({ struct, colorTheme, fontFamily, onUpdate, id }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <Preview struct={struct} colorTheme={colorTheme} fontFamily={fontFamily} />
            {isShowModal && (
                <RankBattleModalModal
                    data={{
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default RankBattle
