import React from 'react'
import playIcon from './i/play.svg'

export const Tip = ({ tip, onClick }) => {
    return (
        <div className="tips__tip" onClick={onClick}>
            <img className="tips__tip-video" src={tip.previewPicture} alt="Link on video" />
            <img className="tips__tip-play-button" src={playIcon} alt="Play button" />
            <img className="tips__tip-cover" src={tip.coverPicture} alt="Link on video" />
        </div>
    )
}
