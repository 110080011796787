import classNames from 'classnames'

import styles from './ChooseBlock.module.scss'

const ChooseBlock = ({ icon, title, description, isSelected, onSelect }) => (
    <li className={classNames(styles.chooseBlock, { [styles.isSelectedChooseBlock]: isSelected })} onClick={onSelect}>
        <img src={icon} alt="" />
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
    </li>
)

export default ChooseBlock
