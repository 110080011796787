import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import LinearProgress from '@mui/material/LinearProgress'

import { TipBulb } from 'svg'

import { mapWheelStatistic } from './utils'

import styles from './Statistics.module.scss'

const Statistics = ({ statistic, blockProjection }) => {
    const { t } = useTranslation()
    const [_statistic, _setStatistic] = useState(null)

    useEffect(() => {
        const mappedStat = mapWheelStatistic(statistic, blockProjection)
        _setStatistic(mappedStat)
    }, [statistic, blockProjection])

    const getBlockTitle = section =>
        `${section.resultHeader} ${!!section.resultDescription && ` | ${section.resultDescription}`}`

    return (
        <div className={styles.statistics}>
            <div className={styles.statisticsHeader}>
                <h2 className={styles.statisticsHeaderTitle}>{t('Wheel results')}</h2>
                {!blockProjection.isShowLeadForm && (
                    <div className={styles.statisticsHeaderTip}>
                        <TipBulb />
                        <p className={styles.statisticsHeaderTipNote}>
                            {t('Add a lead form to your wheel to collect leads and see the results of each user')}
                        </p>
                    </div>
                )}
            </div>
            <div className={styles.statisticsMain}>
                <div className={classNames(styles.statisticsMainBlockWrapper, styles.statisticsMainBlockHeader)}>
                    <p className={styles.statisticsMainBlockTitle}>{t('Results')}</p>
                    <div className={styles.statisticsMainBlockValues}>{t('Users')}</div>
                </div>
                <ul className={styles.statisticsBlockList}>
                    {_statistic?.sections.map(sectionStat => (
                        <li key={sectionStat.id} className={styles.statisticsBlockListItem}>
                            <div className={styles.statisticsMainBlockWrapper}>
                                <p className={styles.statisticsMainBlockTitle} title={getBlockTitle(sectionStat)}>
                                    {getBlockTitle(sectionStat)}
                                </p>
                                <div className={styles.statisticsMainBlockValues}>
                                    {sectionStat.users}
                                    {!!sectionStat.percent && <span>({sectionStat.percent}%)</span>}
                                </div>
                            </div>
                            <LinearProgress
                                className={styles.statisticsMainLinearProgress}
                                classes={{ bar: styles.statisticsMainLinearProgressBar }}
                                variant="determinate"
                                value={sectionStat.percent}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Statistics
