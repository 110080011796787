import React from 'react'

import useInitialLoading from 'hooks/useInitialLoading'

const withInitialLoading = BaseComponent => {
    return function EnhancedComponent(props) {
        const { isReady, onReady } = useInitialLoading()

        return <BaseComponent {...props} isReady={isReady} onReady={onReady} />
    }
}

export default withInitialLoading
