import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getUniqueId } from 'common/utils/getUniqueId'

import { ADD_LOADING_PROCESS, REMOVE_LOADING_PROCESS } from '../store/actions'

const useInitialLoading = () => {
    const dispatch = useDispatch()
    const [isReady, setIsReady] = useState(false)
    const [processId] = useState(() => getUniqueId())

    const onReady = () => setIsReady(true)

    useEffect(() => {
        return () => dispatch(REMOVE_LOADING_PROCESS(processId))
    }, [])

    useEffect(() => {
        if (!isReady) dispatch(ADD_LOADING_PROCESS(processId))
        else dispatch(REMOVE_LOADING_PROCESS(processId))
    }, [isReady])

    return { isReady, onReady }
}

export default useInitialLoading
