import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './HiddenGroup.module.scss'

const HiddenGroup = ({ t, hiddenText = t('More options'), visibleText = t('Less options'), children }) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <div className={styles.hiddenGroup}>
            <div
                className={classNames({
                    [styles.toggle]: true,
                    [styles.isVisibleToggle]: isVisible,
                })}
                onClick={() => setIsVisible(!isVisible)}
            >
                {isVisible ? visibleText : hiddenText}
            </div>
            {isVisible && <div className={styles.content}>{children}</div>}
        </div>
    )
}

export default withTranslation()(HiddenGroup)
