export const SERVICE__SET_NAV_MENU_WIDTH = value => {
    return {
        type: 'SERVICE__SET_NAV_MENU_WIDTH',
        payload: value,
    }
}

export const SERVICE__STYLES__SET_HEADER_SIZES = obj => {
    return {
        type: 'SERVICE__STYLES__SET_HEADER_SIZES',
        payload: {
            width: obj.width,
            height: obj.height,
        },
    }
}

export const SERVICE__STYLES__SET_WINDOW_SIZES = obj => {
    return {
        type: 'SERVICE__STYLES__SET_WINDOW_SIZES',
        payload: {
            width: obj.width,
            height: obj.height,
        },
    }
}
