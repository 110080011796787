import { uniqueId } from 'lodash'

import { API__MEDIA } from 'api'
import {
    ASSET_TABS,
    UPLOAD_STATUS,
    SUPPORT_AUDIO_TYPES,
    SUPPORT_IMAGE_TYPES,
} from 'components/AssetManager/utils/constants'
import {
    mapAudioList,
    mapAudioToAssetFormat,
    mapImageToAssetFormat,
    mapMyImages,
    mapUnsplashImages,
    mapPixabayImages,
} from 'components/AssetManager/utils/utils.js'
import Toast from 'components/Toast/Toast'

export const ASSET_MANAGER__MANAGER_SET_TAB = tab => ({
    type: 'ASSET_MANAGER__MANAGER_SET_TAB',
    payload: {
        tab,
    },
})

export const ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID = id => ({
    type: 'ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID',
    payload: {
        id,
    },
})

export const ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID = id => (dispatch, getState) => {
    const selectedItemId = getState().assetManager.library.selectedItemId

    if (id === selectedItemId) dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(null))
}

export const ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST = isLoadList => ({
    type: 'ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST',
    payload: {
        isLoadList,
    },
})

export const ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR = isLoadError => ({
    type: 'ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR',
    payload: {
        isLoadError,
    },
})

export const ASSET_MANAGER__LIBRARY__RESET_LIBRARY = () => ({
    type: 'ASSET_MANAGER__LIBRARY__RESET_LIBRARY',
    payload: {},
})

export const ASSET_MANAGER__LIBRARY__UP_LIST_PAGE = () => ({
    type: 'ASSET_MANAGER__LIBRARY__UP_LIST_PAGE',
    payload: {},
})

export const ASSET_MANAGER__LIBRARY__SET_LIST = list => ({
    type: 'ASSET_MANAGER__LIBRARY__SET_LIST',
    payload: {
        list,
    },
})

export const ASSET_MANAGER__LIBRARY__SET_SEARCH = search => ({
    type: 'ASSET_MANAGER__LIBRARY__SET_SEARCH',
    payload: {
        search,
    },
})

// MY LIBRARY

export const ASSET_MANAGER__MY_LIBRARY__INIT = () => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())

    dispatch(
        ASSET_MANAGER__MY_LIBRARY__LOAD_IMAGES(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}

export const ASSET_MANAGER__MY_LIBRARY__UP_IMAGES_PAGE = () => async (dispatch, getState) => {
    const { content, last } = getState().assetManager.library.list

    if (!last) {
        await dispatch(ASSET_MANAGER__LIBRARY__UP_LIST_PAGE())

        dispatch(
            ASSET_MANAGER__MY_LIBRARY__LOAD_IMAGES(list => {
                dispatch(ASSET_MANAGER__LIBRARY__SET_LIST({ ...list, content: [...content, ...list.content] }))
            }),
        )
    }
}

export const ASSET_MANAGER__MY_LIBRARY__LOAD_IMAGES = callback => async (dispatch, getState) => {
    const {
        assetManager: { library },
        organizations: { selectedOrganizationId },
    } = getState()
    const {
        pageable: { pageSize, pageNumber },
    } = library.list

    const pageSizeWithOffset = pageSize + library.offset

    dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(true))

    API__MEDIA.GET_IMAGES(selectedOrganizationId, pageSizeWithOffset, pageNumber)
        .then(list => {
            const mappedList = mapMyImages(list)
            callback(mappedList)
        })
        .catch(error => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR(true))
            Toast('error', error)
        })
        .finally(() => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(false))
        })
}

const ASSET_MANAGER__MY_LIBRARY__UPLOAD_IMAGE = ({ fileName, contentType, bytes, uploadRequestParams }) => async (
    dispatch,
    getState,
) => {
    const { managerTab } = getState().assetManager

    const response = await API__MEDIA.UPLOAD_IMAGE({ fileName, contentType, bytes: await bytes, uploadRequestParams })
    const item = mapImageToAssetFormat(response)

    if (managerTab === ASSET_TABS.TAB_MY_LIBRARY) {
        dispatch({
            type: 'ASSET_MANAGER__LIBRARY__UPLOAD_ITEM',
            payload: {
                item,
            },
        })
    } else {
        dispatch(ASSET_MANAGER__MANAGER_SET_TAB(ASSET_TABS.TAB_MY_LIBRARY))
    }

    return response
}

export const ASSET_MANAGER__MY_LIBRARY__DELETE_IMAGE = id => async dispatch => {
    await API__MEDIA.DELETE_IMAGE(id)

    dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))

    dispatch({
        type: 'ASSET_MANAGER__LIBRARY__DELETE_ITEM',
        payload: {
            id,
        },
    })
}

export const ASSET_MANAGER__UNSPLASH_LIBRARY__INIT = () => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())

    dispatch(
        ASSET_MANAGER__UNSPLASH_LIBRARY__LOAD_IMAGES(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}
export const ASSET_MANAGER__PIXABAY_LIBRARY__INIT = () => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())

    dispatch(
        ASSET_MANAGER__PIXABAY_LIBRARY__LOAD_IMAGES(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}

export const ASSET_MANAGER__UNSPLASH_LIBRARY__UP_IMAGES_PAGE = () => async (dispatch, getState) => {
    const { content, last } = getState().assetManager.library.list

    if (!last) {
        await dispatch(ASSET_MANAGER__LIBRARY__UP_LIST_PAGE())

        dispatch(
            ASSET_MANAGER__UNSPLASH_LIBRARY__LOAD_IMAGES(list => {
                dispatch(ASSET_MANAGER__LIBRARY__SET_LIST({ ...list, content: [...content, ...list.content] }))
            }),
        )
    }
}
export const ASSET_MANAGER__PIXABAY_LIBRARY__UP_IMAGES_PAGE = () => async (dispatch, getState) => {
    const { content, last } = getState().assetManager.library.list

    if (!last) {
        await dispatch(ASSET_MANAGER__LIBRARY__UP_LIST_PAGE())

        dispatch(
            ASSET_MANAGER__PIXABAY_LIBRARY__LOAD_IMAGES(list => {
                dispatch(ASSET_MANAGER__LIBRARY__SET_LIST({ ...list, content: [...content, ...list.content] }))
            }),
        )
    }
}

export const ASSET_MANAGER__UNSPLASH_LIBRARY__SEARCH = search => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())
    await dispatch(ASSET_MANAGER__LIBRARY__SET_SEARCH(search))
    dispatch(
        ASSET_MANAGER__UNSPLASH_LIBRARY__LOAD_IMAGES(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}
export const ASSET_MANAGER__PIXABAY_LIBRARY__SEARCH = search => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())
    await dispatch(ASSET_MANAGER__LIBRARY__SET_SEARCH(search))
    dispatch(
        ASSET_MANAGER__PIXABAY_LIBRARY__LOAD_IMAGES(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}

export const ASSET_MANAGER__UNSPLASH_LIBRARY__LOAD_IMAGES = callback => async (dispatch, getState) => {
    const {
        assetManager: { library },
    } = getState()
    const {
        pageable: { pageSize, pageNumber },
    } = library.list

    dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(true))

    API__MEDIA.GET_UNSPLASH_IMAGES(pageSize, pageNumber, library.search)
        .then(list => {
            const mappedList = mapUnsplashImages(list)
            callback(mappedList)
        })
        .catch(error => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR(true))
            Toast('error', error)
        })
        .finally(() => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(false))
        })
}
export const ASSET_MANAGER__PIXABAY_LIBRARY__LOAD_IMAGES = callback => async (dispatch, getState) => {
    const {
        assetManager: { library },
    } = getState()
    const {
        pageable: { pageNumber },
    } = library.list

    dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(true))

    API__MEDIA.GET_PIXABAY_IMAGES(50, pageNumber, library.search)
        .then(list => {
            const mappedList = mapPixabayImages(list)
            callback(mappedList)
        })
        .catch(error => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR(true))
            Toast('error', error)
        })
        .finally(() => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(false))
        })
}

export const ASSET_MANAGER__AUDIO_LIBRARY__INIT = () => async dispatch => {
    await dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())

    dispatch(
        ASSET_MANAGER__AUDIO_LIBRARY__LOAD_AUDIO_LIST(list => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_LIST(list))
        }),
    )
}

export const ASSET_MANAGER__AUDIO_LIBRARY__UP_AUDIO_PAGE = () => async (dispatch, getState) => {
    const { content, last } = getState().assetManager.library.list

    if (!last) {
        await dispatch(ASSET_MANAGER__LIBRARY__UP_LIST_PAGE())

        dispatch(
            ASSET_MANAGER__AUDIO_LIBRARY__LOAD_AUDIO_LIST(list => {
                dispatch(ASSET_MANAGER__LIBRARY__SET_LIST({ ...list, content: [...content, ...list.content] }))
            }),
        )
    }
}

export const ASSET_MANAGER__AUDIO_LIBRARY__LOAD_AUDIO_LIST = callback => async (dispatch, getState) => {
    const {
        assetManager: { library },
        organizations: { selectedOrganizationId },
    } = getState()
    const {
        pageable: { pageSize, pageNumber },
    } = library.list

    const pageSizeWithOffset = pageSize + library.offset

    dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(true))

    API__MEDIA.GET_AUDIO_LIST(selectedOrganizationId, pageSizeWithOffset, pageNumber)
        .then(list => {
            const mappedList = mapAudioList(list)
            callback(mappedList)
        })
        .catch(error => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR(true))
            Toast('error', error)
        })
        .finally(() => {
            dispatch(ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST(false))
        })
}

const ASSET_MANAGER__AUDIO_LIBRARY__UPLOAD_AUDIO = ({
    fileName,
    contentType,
    bytes,
    uploadRequestParams = {},
}) => async (dispatch, getState) => {
    const { managerTab } = getState().assetManager

    const item = await API__MEDIA.UPLOAD_AUDIO({ fileName, contentType, bytes: await bytes, uploadRequestParams })

    if (managerTab === ASSET_TABS.TAB_AUDIO) {
        dispatch({
            type: 'ASSET_MANAGER__LIBRARY__UPLOAD_ITEM',
            payload: {
                item: mapAudioToAssetFormat(item),
            },
        })
    } else {
        dispatch(ASSET_MANAGER__MANAGER_SET_TAB(ASSET_TABS.TAB_AUDIO))
    }
}

export const ASSET_MANAGER__AUDIO_LIBRARY__DELETE_AUDIO = id => async dispatch => {
    await API__MEDIA.DELETE_AUDIO(id)

    dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))

    dispatch({
        type: 'ASSET_MANAGER__LIBRARY__DELETE_ITEM',
        payload: {
            id,
        },
    })
}

// UPLOAD MEDIA

export const ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO = id => ({
    type: 'ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO',
    payload: {
        id,
    },
})

export const ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO = (id, info) => ({
    type: 'ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO',
    payload: {
        id,
        info,
    },
})

export const ASSET_MANAGER__ADD_UPLOAD_MEDIA_INFO = info => async dispatch => {
    const id = uniqueId()
    dispatch(ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO(id, info))
    await new Promise(resolve => setTimeout(resolve, 15000))
    dispatch(ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO(id))
}

export const ASSET_MANAGER__ADD_UPLOAD_MEDIA = ({ file, name, uploadRequestParams = {} }) => async dispatch => {
    return new Promise((resolve, reject) => {
        const fileName = name || file.name
        const id = uniqueId()
        const reader = new FileReader()

        reader.addEventListener('loadstart', ev =>
            dispatch(
                ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO(id, {
                    total: ev.total,
                    name: fileName,
                    status: UPLOAD_STATUS.prepare,
                }),
            ),
        )

        reader.addEventListener('load', async ev => {
            try {
                let resolveData = null
                if (SUPPORT_IMAGE_TYPES.has(file.type)) {
                    resolveData = await dispatch(
                        ASSET_MANAGER__MY_LIBRARY__UPLOAD_IMAGE({
                            fileName,
                            contentType: file.type,
                            bytes: reader.result,
                            uploadRequestParams,
                        }),
                    )
                } else if (SUPPORT_AUDIO_TYPES.has(file.type)) {
                    resolveData = await dispatch(
                        ASSET_MANAGER__AUDIO_LIBRARY__UPLOAD_AUDIO({
                            fileName,
                            contentType: file.type,
                            bytes: reader.result,
                            uploadRequestParams,
                        }),
                    )
                }
                resolve(resolveData)

                dispatch(
                    ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO(id, {
                        total: ev.total,
                        name: fileName,
                        status: UPLOAD_STATUS.ok,
                    }),
                )
                await new Promise(resolve => setTimeout(resolve, 3000))
            } catch (err) {
                if (err.response && err.response.data && err.response.data.message) {
                    dispatch(
                        ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO(id, {
                            total: ev.total,
                            name: fileName,
                            status: 'error',
                            message: err.response.data.message,
                        }),
                    )
                }

                await new Promise(resolve => setTimeout(resolve, 15000))
            } finally {
                dispatch(ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO(id))
            }
        })

        reader.addEventListener('error', ev => {
            dispatch(
                ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO(id, {
                    total: ev.total || 0,
                    name: fileName,
                    status: UPLOAD_STATUS.error,
                }),
            )
            reject('Upload error')
        })

        reader.readAsArrayBuffer(file)
    })
}
