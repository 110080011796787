import React from 'react'
import { InView } from 'react-intersection-observer'

import { TreasureHuntTileEditor } from './TreasureHuntTileEditor'

import './TreasureHuntTileEditorList.scss'

export const TreasureHuntTileEditorList = ({
    tiles,
    pages,
    tilesErrors,
    titleOfItem,
    onSetFocusToSection,
    updateTile,
}) => {
    const handleOnScrollHighlightVisibleItem = async (inView, entry) => {
        if (inView) await onSetFocusToSection(entry.target)
    }

    return (
        <div className="tile-editor-list">
            <ul className="tile-editor-list__items">
                {tiles.map((tile, index) => (
                    <InView
                        className="tile-editor-list__item"
                        as="li"
                        key={index}
                        id={`item_${tile.id}`}
                        threshold={0.1}
                        onChange={handleOnScrollHighlightVisibleItem}
                    >
                        <div className="tile-editor-list__item-header">
                            <div className="tile-editor-list__header-title">
                                {titleOfItem} {index + 1}
                            </div>
                        </div>
                        <TreasureHuntTileEditor
                            pages={pages}
                            tile={tile}
                            tileError={tilesErrors && tilesErrors[index]}
                            updateTile={updateTile}
                        />
                    </InView>
                ))}
            </ul>
        </div>
    )
}
