import React from 'react'
import { useTranslation } from 'react-i18next'
import accounting from 'accounting'

import i18n from 'i18n'

import { FEATURE_TYPES } from 'common/constants/subscription'

const NumberControl = ({ feature }) => {
    const { t } = useTranslation()

    const getFormattedValue = () => {
        const { productOptionValue, featureType } = feature

        if (featureType === FEATURE_TYPES.STORAGE_SIZE) {
            if (productOptionValue < 1000) return `${productOptionValue} ${i18n.t('MB')}`
            else return `${accounting.formatNumber(productOptionValue / 1000, { thousand: ',' })} ${i18n.t('GB')}`
        }

        if (productOptionValue === -1) return t('Unlimited')
        if (productOptionValue >= 1000000) return t('Unlimited')
        return accounting.formatNumber(productOptionValue, { thousand: ',' })
    }

    return <div>{getFormattedValue()}</div>
}

export default NumberControl
