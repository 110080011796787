import React from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import Question from '../components/Question'
import { Answer, Question as QuestionModel } from '../questionService'

function QuestionView({ tmpStructure, errors, onUpdateStructure }) {
    const handleAddAnswer = () => {
        const newAnswer = new Answer()
        const newAnswers = tmpStructure.question.answers.concat(newAnswer)
        handleUpdateQuestion({ [QuestionModel.answers]: newAnswers })
    }

    const handleRemoveAnswer = answerId => {
        const newAnswers = tmpStructure.question.answers.filter(a => answerId !== a.id)
        handleUpdateQuestion({ [QuestionModel.answers]: newAnswers })
    }

    const handleSetAnswerCorrect = async (answerId, isCorrect) => {
        let conditionFunc = a => (a.id === answerId ? { ...a, isCorrect } : a)
        if (tmpStructure.question.answers.length <= 2)
            conditionFunc = a => (a.id === answerId ? { ...a, isCorrect } : { ...a, isCorrect: !isCorrect })
        const answers = tmpStructure.question.answers.map(conditionFunc)
        handleUpdateQuestion({ [QuestionModel.answers]: answers })
    }

    const handleUpdateQuestion = newFields => {
        onUpdateStructure({
            question: { ...tmpStructure.question, ...newFields },
        })
    }
    const handleUpdateQuestionAnswer = (answerId, newFields) => {
        const answers = tmpStructure.question.answers.map(a => (a.id === answerId ? { ...a, ...newFields } : a))
        handleUpdateQuestion({ [QuestionModel.answers]: answers })
    }

    return (
        <>
            <div className="body">
                <div className="tab-wrapper tab-wrapper--2">
                    <div className="workplace">
                        <ScrollableBlock>
                            <div className="scrollbar-inner">
                                <Question
                                    question={tmpStructure.question}
                                    errors={errors}
                                    onSetAnswerCorrect={handleSetAnswerCorrect}
                                    onUpdateQuestion={handleUpdateQuestion}
                                    onUpdateQuestionAnswer={handleUpdateQuestionAnswer}
                                    onAddAnswer={handleAddAnswer}
                                    onRemoveAnswer={handleRemoveAnswer}
                                />
                            </div>
                        </ScrollableBlock>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionView
