import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import TimePresetModal from './components/TimePresetModal/TimePresetModal'

import editSvg from './i/edit.svg'

import styles from './RoundsTime.module.scss'

const RoundsTime = ({ presets, onSave }) => {
    const { t } = useTranslation()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [label, setLabel] = useState('')

    useEffect(() => {
        if (presets.every(v => v === presets[0])) {
            const v = presets[0]
            if (v % 60 === 0) setLabel(`${v / 60} ${t('min')}`)
            else {
                if (v < 60) setLabel(`${v} ${t('sec')}`)
                else {
                    const min = Math.floor(v / 60)
                    setLabel(`${min} ${t('min')} ${v % 60} ${t('sec')}`)
                }
            }
            return
        }
        setLabel('mixed')
    }, [presets])

    return (
        <div className={styles.roundsTime}>
            <Trans
                i18nKey="Time to complete each level <lnk><txt>{{label}}</txt> <icon/></lnk>"
                values={{ label }}
                components={{
                    txt: <span className={styles.lnkText} />,
                    lnk: <span className={styles.lnk} onClick={() => setIsOpenModal(true)} />,
                    icon: <img className={styles.icon} src={editSvg} />,
                }}
            />

            {isOpenModal && <TimePresetModal presets={presets} onClose={() => setIsOpenModal(false)} onSave={onSave} />}
        </div>
    )
}

export default RoundsTime
