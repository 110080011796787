import { convertHexToHsl, convertHexToRgb, convertHslToHex, invertColor } from 'utils/colors'

import { COLOR_SCHEME } from './constants'

const getSectionFillingArray = (sectionsCount, { var_1, var_2, var_3 }) =>
    ({
        2: [var_1, var_3],
        3: [var_1, var_3, var_2],
        4: [var_3, var_1, var_3, var_1],
        5: [var_1, var_2, var_3, var_1, var_2],
        6: [var_2, var_3, var_1, var_2, var_3, var_1],
        7: [var_1, var_3, var_2, var_1, var_3, var_1, var_2],
        8: [var_2, var_3, var_1, var_3, var_2, var_3, var_1, var_3],
        9: [var_1, var_2, var_3, var_1, var_3, var_2, var_1, var_3, var_2],
        10: [var_1, var_2, var_3, var_1, var_2, var_3, var_1, var_2, var_3, var_2],
        11: [var_1, var_2, var_3, var_1, var_2, var_3, var_1, var_2, var_3, var_1, var_3],
        12: [var_1, var_2, var_3, var_1, var_2, var_3, var_1, var_2, var_3, var_1, var_2, var_3],
    }[sectionsCount])

export const getSectionColors = (colorTheme, sectionsCount, index, colorScheme) => {
    const textColor = invertColor(colorTheme)
    let backgroundColor = colorTheme

    if (colorScheme === COLOR_SCHEME.ANALOGOUS) {
        const hslColorTheme = convertHexToHsl(colorTheme)
        const angle = getSectionFillingArray(sectionsCount, {
            var_1: 0,
            var_2: -30,
            var_3: 30,
        })[index]
        backgroundColor = convertHslToHex(hslColorTheme.h + angle, hslColorTheme.s, hslColorTheme.l)
    }
    if (colorScheme === COLOR_SCHEME.MONOCHROMATIC) {
        const rgbColorTheme = convertHexToRgb(colorTheme)
        backgroundColor = getSectionFillingArray(sectionsCount, {
            var_1: `rgba(${rgbColorTheme.r}, ${rgbColorTheme.g}, ${rgbColorTheme.b}, 1)`,
            var_2: `rgba(${rgbColorTheme.r}, ${rgbColorTheme.g}, ${rgbColorTheme.b}, 0.5)`,
            var_3: `rgba(${rgbColorTheme.r}, ${rgbColorTheme.g}, ${rgbColorTheme.b}, 0.75)`,
        })[index]
    }
    if (colorScheme === COLOR_SCHEME.TRIADIC) {
        const hslColorTheme = convertHexToHsl(colorTheme)
        const angle = getSectionFillingArray(sectionsCount, {
            var_1: 0,
            var_2: -120,
            var_3: 120,
        })[index]
        backgroundColor = convertHslToHex(hslColorTheme.h + angle, hslColorTheme.s, hslColorTheme.l)
    }
    if (colorScheme === COLOR_SCHEME.SPLIT_COMPLEMENTARY) {
        const hslColorTheme = convertHexToHsl(colorTheme)
        const angle = getSectionFillingArray(sectionsCount, {
            var_1: 0,
            var_2: -150,
            var_3: 150,
        })[index]
        backgroundColor = convertHslToHex(hslColorTheme.h + angle, hslColorTheme.s, hslColorTheme.l)
    }

    return {
        backgroundColor,
        textColor,
    }
}
