import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import useResize from 'hooks/useResize'

import Text from '../components/Text/Text'
import Button from '../components/QuillForms/Button/Button'

import styles from './Cover.module.scss'

const Cover = ({
    blockData: {
        id,
        t,
        imageProportions,
        imageUrl,
        darkenBackground,
        isShowLogotype,
        logotypePosition,
        text,
        logotypeUrl,
        logotypeScale,
        isShowButton,
        buttonPosition,
        buttonText,
        buttonBackgroundColor,
        buttonBorderRadius,
    },
    onUpdate,
}) => {
    const coverRef = useRef()

    const [width] = useResize(coverRef)

    const [heightCoeff, setHeightCoeff] = useState(0)

    useEffect(() => {
        const splittedCoeff = imageProportions.split('|')
        setHeightCoeff(splittedCoeff[1] / splittedCoeff[0])
    }, [imageProportions])

    return (
        <div ref={coverRef} className={classNames('block', `__${t}`, styles.cover)}>
            <div
                className={styles.background}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    '--overlayColor': `rgba(0,0,0,${darkenBackground / 100})`,
                }}
            />
            <div
                className={styles.content}
                style={{
                    minHeight: (width < 800 ? 800 : width) * heightCoeff,
                    justifyContent: isShowLogotype ? 'space-between' : 'center',
                }}
            >
                {isShowLogotype && (
                    <div className={styles.contentLogotype} style={{ textAlign: logotypePosition }}>
                        <img
                            src={logotypeUrl}
                            alt="logo"
                            style={{
                                width: `${logotypeScale}%`,
                            }}
                        />
                    </div>
                )}
                <div className={styles.contentMain}>
                    <p className={styles.contentMainText}>
                        <Text id={id} text={text} propName="text" onUpdate={onUpdate} />
                    </p>
                    {isShowButton && (
                        <div className={styles.contentMainButton} style={{ textAlign: buttonPosition }}>
                            <div style={{ display: 'inline-block' }}>
                                <Button
                                    blockId={id}
                                    text={buttonText}
                                    backgroundColor={buttonBackgroundColor}
                                    borderRadius={buttonBorderRadius}
                                    onUpdateText={onUpdate}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {isShowLogotype && <div className={styles.contentFakeFooter} />}
            </div>
        </div>
    )
}

export default Cover
