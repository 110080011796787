import React from 'react'

import styles from './Waves.module.scss'

const Waves = ({ columns }) => (
    <ul className={styles.columns}>
        {columns.map((height, index) => (
            <li key={index} className={styles.column} style={{ minHeight: height + 'px', opacity: 0.008 * height }} />
        ))}
    </ul>
)

export default Waves
