import React from 'react'
import _ from 'lodash'

export const provider = (_provider, props = {}) => [_provider, props]

const ProviderComposer = ({ providers = [], children }) => {
    if (_.isEmpty(providers)) return children

    return (
        <>
            {providers.reduceRight((acc, [Provider, props]) => {
                return <Provider {...props}>{acc}</Provider>
            }, children)}
        </>
    )
}

export default ProviderComposer
