import React from 'react'
import { useTranslation } from 'react-i18next'
import accounting from 'accounting'

const thresholdStatus = 90

const Progress = ({ title, date, units, value, total, formattedTextResult }) => {
    const { t } = useTranslation()
    const filledPercentage = (value * 100) / total
    let status = filledPercentage < thresholdStatus ? 'normal' : 'danger'

    const getTextProgress = () => {
        const formattedValue = accounting.formatNumber(value, { thousand: ' ' })
        const formatTotal = accounting.formatNumber(total, { thousand: ' ' })
        const formattedString = `${formattedValue} ${t('of')} ${formatTotal}`
        return units ? formattedString + `\u00A0${units}` : formattedString
    }

    const resultOfProgressText = formattedTextResult || getTextProgress()

    return (
        <div className="progress">
            <div className="head">
                <div className="left">
                    <span className="title">{title}</span>
                    {date ? <p>{date}</p> : null}
                </div>
                <div className="right">{resultOfProgressText}</div>
            </div>
            <div className="line">
                <span
                    style={{ width: `${filledPercentage}%` }}
                    data-status={status}
                    className={value === 0 ? 'prevent-min-width' : ''}
                />
            </div>
            <div className="mobile">{resultOfProgressText}</div>
        </div>
    )
}

export default Progress
