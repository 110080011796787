import React from 'react'
import { useTranslation } from 'react-i18next'

import CircleSpinner from 'components/CircleSpinner/CircleSpinner'

import timerIcon from './i/timer.svg'

import styles from './PrintProcessingBody.module.scss'

const PrintProcessingBody = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.printProcessingBody}>
            <div className={styles.left}>
                <div className={styles.title}>{t('Generating PDF...')}</div>
                <div className={styles.time}>
                    <img className={styles.timeIcon} src={timerIcon} alt="timer" />
                    {t('Usually it takes about a minute')}
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.note}>
                    {t("You can close this window.\nWe'll let you know when the file is ready.")}
                </div>
                <div className={styles.loader}>
                    <CircleSpinner />
                </div>
            </div>
        </div>
    )
}

export default PrintProcessingBody
