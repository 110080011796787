import { MAIN_SERVER } from './instance'

export const GET_ENABLED_INTEGRATIONS = async (orgId, projectId) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/configs`,
        params: {
            orgId,
            projectId,
        },
    })

export const CREATE_CONFIG = async (organizationId, code, redirectUrl, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/mailchimp/config`,
        data: {
            organizationId,
            code,
            redirectUrl,
            projectId,
        },
    })

export const DELETE_CONFIG = async (organizationId, projectId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/integrations/mailchimp/config`,
        data: {
            organizationId,
            projectId,
        },
    })

export const GET_MAILCHIMP_LISTS = async (organizationId, projectId) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/mailchimp/lists`,
        params: {
            organizationId,
            projectId,
        },
    })

export const GET_FIELDS_BY_LIST = async (organizationId, listId, projectId) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/mailchimp/fields`,
        params: {
            organizationId,
            listId,
            projectId,
        },
    })

export const GET_CONNECTOR = async (organizationId, projectId, blockId) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/mailchimp/connectors`,
        params: { organizationId, projectId, blockId },
    })

export const CREATE_CONNECTOR = async ({ organizationId, projectId, blockId, listId, mappings }) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/mailchimp/connectors`,
        data: {
            organizationId,
            projectId,
            blockId,
            listId,
            mappings,
        },
    })

export const SWITCH_CONNECTOR = async (id, organizationId, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/mailchimp/connectors/${id}/switch`,
        data: {
            organizationId,
            projectId,
        },
    })

export const TEST_CONTACT = async (organizationId, listId, fields, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/mailchimp/contacts`,
        data: {
            email: 'john@interacty.me',
            organizationId,
            listId,
            fields,
            projectId,
        },
    })
