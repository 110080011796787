export const CARD_PROPORTIONS = [
    {
        value: '1:1',
        label: '1:1',
        className: 'card-proportions__square',
        proportionHeight: 1,
    },
    {
        value: '5:4',
        label: '5:4',
        className: 'card-proportions__horizontal-rectangle',
        proportionHeight: 0.8,
    },
    {
        value: '4:5',
        label: '4:5',
        className: 'card-proportions__vertical-rectangle',
        proportionHeight: 1.2,
    },
]
