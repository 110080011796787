import React, { useEffect } from 'react'
import classNames from 'classnames'

import useOptimalTextFontStyles from 'hooks/useOptimalTextFontStyles'

import { CARD_TYPES, COLORS_KEYS } from '../../../../constants'

import { CARD_PROPORTIONS_HEIGHT } from './constants'

import './Main.scss'

const Main = ({
    isMobile,
    minFontSize,
    fontFamily,
    cardType,
    colors,
    cardProportions,
    imageUrl,
    text,
    onUpdateFontSize,
}) => {
    const { parentRef, textRef, sizes, styles } = useOptimalTextFontStyles({ fontFamily, text })

    useEffect(() => {
        onUpdateFontSize(styles.fontSize)
    }, [styles.fontSize])

    return (
        <div className={classNames('rank-battle__main', { 'is-mobile': isMobile })}>
            <div
                className="rank-battle__main__inner"
                style={{ paddingBottom: `${100 * CARD_PROPORTIONS_HEIGHT[cardProportions]}%` }}
            >
                {cardType === CARD_TYPES.IMAGE && (
                    <div className="rank-battle__main__image-wrapper">
                        <img className="rank-battle__main__image" src={imageUrl} alt="Card image" draggable="false" />
                    </div>
                )}
                {cardType === CARD_TYPES.TEXT && (
                    <div className={classNames('rank-battle__main__text-wrapper', { 'is-mobile': isMobile })}>
                        <div className="rank-battle__main__text-inner">
                            <div ref={parentRef} className="rank-battle__main__text-block">
                                <span
                                    ref={textRef}
                                    className="rank-battle__main__text"
                                    style={{
                                        ...styles,
                                        fontSize: minFontSize,
                                        color: colors[COLORS_KEYS.MAIN_TEXT],
                                        '--height': `${sizes.textHeight}px`,
                                    }}
                                >
                                    {text}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Main
