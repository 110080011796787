import React from 'react'
import { useTranslation } from 'react-i18next'

import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import HiddenGroup from 'components/Form/HiddenGroup/HiddenGroup'
import TextArea from 'components/Form/TextArea/TextArea'
import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'

import TextInputWithPageSelection from '../../../../../components/TextInputWithPageSelection/TextInputWithPageSelection'
import { QuizResult as ResultModel } from '../../quizModels'

function Result({ pages, errors, result, onUpdateResult }) {
    const { t } = useTranslation()

    return (
        <ul className="content">
            <TextInput
                isRequired
                label={t('Header')}
                errorMessages={errors.results?.get(result.id)?.header}
                value={result.header}
                onUpdate={value => onUpdateResult(result.id, { [ResultModel.header]: value })}
            />
            <TextArea
                label={t('Description')}
                errorMessages={errors.results?.get(result.id)?.description}
                value={result.description}
                onUpdate={value => onUpdateResult(result.id, { [ResultModel.description]: value })}
            />
            <UploadImage
                label={t('Result image')}
                value={result.image}
                onUpdate={value => onUpdateResult(result.id, { [ResultModel.image]: value })}
            />
            <HiddenGroup>
                {result.image && (
                    <TextInput
                        label={t('Image disclaimer')}
                        isOptional
                        errorMessages={errors.results?.get(result.id)?.imageDisclaimer}
                        value={result.imageDisclaimer}
                        onUpdate={value => onUpdateResult(result.id, { [ResultModel.imageDisclaimer]: value })}
                    />
                )}
                <ElementsGroup isEquallyWidth>
                    <TextInput
                        label={t('Button text')}
                        value={result.buttonText}
                        onUpdate={value => onUpdateResult(result.id, { [ResultModel.buttonText]: value })}
                    />
                    <TextInputWithPageSelection
                        pages={pages}
                        value={result.buttonLink}
                        onUpdate={value => onUpdateResult(result.id, { [ResultModel.buttonLink]: value })}
                    />
                </ElementsGroup>
            </HiddenGroup>
        </ul>
    )
}

export default Result
