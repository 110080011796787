import ACTIONS from 'shared/constants/actions'

export const INFO = {
    [ACTIONS.GAME_OPENED]: {
        label: 'Игра открыта',
        description: 'Игра загрузилась и видна пользователю, пользователь еще не совершил никаких действий.',
    },
    [ACTIONS.GAME_STARTED]: {
        label: 'Игра началась',
        description:
            'Пользователь нажал на кнопку «Старт», сделал первый ход в игре, проголосовал или ввел первое слово в кроссворде.',
    },
    [ACTIONS.LEAD_FORM_SUBMITTED]: {
        label: 'Лид-форма отправлена',
        description: 'Пользователь заполнил все поля лид формы и нажал «отправить».',
    },
    [ACTIONS.GAME_COMPLETED]: {
        label: 'Игра завершилась',
        description: 'Пользователь видит финальный экран о прохождении игры.',
    },
    [ACTIONS.TIME_IS_UP]: {
        label: 'Время вышло',
        description: 'Отдельный тип события: когда время игры по таймеру истекает, показывается специальный экран.',
    },
    [ACTIONS.CTA_CLICK]: {
        label: 'Клик на CTA-кнопку',
        description: 'Клик по CTA-кнопке на финальном экране игры',
    },
    [ACTIONS.GAME_RESTARTED]: {
        label: 'Рестарт игры',
        description: 'Клик по кнопке «Рестарт»',
    },
    [ACTIONS.SEC_IN_GAME_5]: {
        label: '5 секунд в игре',
        description: '5 секунд прошло с момента Game Started',
    },
    [ACTIONS.SEC_IN_GAME_10]: {
        label: '10 секунд в игре',
        description: '10 секунд прошло с момента Game Started',
    },
}
