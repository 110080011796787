import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'

import useUserPublicProfile from 'hooks/useUserPublicProfile'

import { API__USER } from 'api'

import {
    ORGANIZATIONS_REFRESH_MEMBERS,
    ORGANIZATIONS_SET_ME_AS_SELECTED_MEMBER,
    USER__SET_DETAILS,
    USER__SET_PUBLIC_PROFILE,
} from 'store/actions'

import { STEPS, STEPS_TITLE } from './constants'

import TopBanner from '../../components/TemplateModal/TopBanner/TopBanner'
import ContentTitle from '../../components/TemplateModal/ContentTitle/ContentTitle'

import ActivationPublicPage from './views/ActivationPublicPage/ActivationPublicPage'
import SetUpProduct from './views/SetUpProduct/SetUpProduct'

import styles from './CreateTemplateModal.module.scss'

const CreateTemplateModal = ({ data: { project }, onClose, onSuccess, onLoadProjects }) => {
    const dispatch = useDispatch()
    const { organizations, user_details } = useSelector(state => state)

    const { userPublicProfile, isPublishedUserPublicProfile } = useUserPublicProfile()
    const [step, setStep] = useState(isPublishedUserPublicProfile ? STEPS.SET_UP_PRODUCT : STEPS.ACTIVATION_PUBLIC_PAGE)
    const [stepsCount] = useState(isPublishedUserPublicProfile ? 1 : 2)

    const onUpdateUser = async (updatedData = {}) => {
        const updatedUser = await API__USER.UPDATE_DETAILS(user_details.id, {
            ...user_details,
            login: user_details.email,
            ...updatedData,
        })
        dispatch(USER__SET_DETAILS(updatedUser))
        await dispatch(ORGANIZATIONS_REFRESH_MEMBERS())

        if (user_details.id === organizations.selectedMember.userId) dispatch(ORGANIZATIONS_SET_ME_AS_SELECTED_MEMBER())
    }
    const onUpdateUserPublicProfile = async (updatedData = {}) => {
        let data = {}
        if (userPublicProfile) data = { ...userPublicProfile }
        await API__USER.UPDATE_PUBLIC_PROFILE(user_details.id, {
            ...data,
            ...updatedData,
        })
        dispatch(USER__SET_PUBLIC_PROFILE({ ...data, ...updatedData }))
    }

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose} isWhiteCloseIcon>
            <div className={styles.createTemplateModal}>
                <TopBanner
                    title={STEPS_TITLE[step]}
                    stepNumber={step === STEPS.ACTIVATION_PUBLIC_PAGE ? 1 : 2}
                    stepsCount={stepsCount}
                    isCanManage={step === STEPS.ACTIVATION_PUBLIC_PAGE}
                    userPublicProfile={userPublicProfile}
                    onUpdate={data => onUpdateUserPublicProfile(data)}
                />
                <div className={styles.content}>
                    <ContentTitle step={step} STEPS={STEPS} />
                    {step === STEPS.ACTIVATION_PUBLIC_PAGE && (
                        <ActivationPublicPage
                            userDetails={user_details}
                            userPublicProfile={userPublicProfile}
                            onUpdateUserPublicProfile={data => onUpdateUserPublicProfile(data)}
                            onUpdateUser={data => onUpdateUser(data)}
                            onActivate={profile => {
                                dispatch(USER__SET_PUBLIC_PROFILE(profile))
                                setStep(STEPS.SET_UP_PRODUCT)
                            }}
                        />
                    )}
                    {step === STEPS.SET_UP_PRODUCT && (
                        <SetUpProduct
                            userDetails={user_details}
                            project={project}
                            onSuccess={onSuccess}
                            onLoadProjects={onLoadProjects}
                        />
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default CreateTemplateModal
