import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import IconCsv from './i/csv.svg'
import DateRangePicker from '../../../../components/DateRangePicker'
import Dropdown from '../../../../components/Dropdown'

import './DateRangePanel.scss'

import { PERIOD_TYPES, PERIOD_INTERVALS } from './constants'

const EMPTY_OPTION = { name: '-', value: null }

const DateRangePanel = ({
    listPeriodType = Object.values(PERIOD_TYPES),
    initialPeriodType = PERIOD_TYPES[PERIOD_INTERVALS['30_DAYS']],
    onChangeRange,
    isEnableCSV = false,
    onExportCSV,
    isBlockHaveRating,
    isCanStartNewRating,
    ratingList,
    ratingPeriod,
    onStartNewRating,
    isLoading,
}) => {
    const { t } = useTranslation()
    const [periodType, setPeriodType] = useState(initialPeriodType)
    const [ratingPeriodType, setRatingPeriodType] = useState(null)
    const [range, setRange] = useState({ from: null, to: null })

    const onSendChange = value => {
        const timeRange = value.getTimesRange()
        setRange(timeRange)
        onChangeRange(timeRange, value)
    }

    useEffect(() => {
        if (ratingPeriodType?.value) {
            onSendChange(ratingPeriodType)
        } else if (periodType?.value) {
            onSendChange(periodType)
        }
    }, [periodType, ratingPeriodType])

    useEffect(() => {
        if (ratingPeriod) {
            setRatingPeriodType(ratingPeriod)
            setPeriodType(EMPTY_OPTION)
        } else {
            setPeriodType(initialPeriodType)
            setRatingPeriodType(EMPTY_OPTION)
        }
    }, [ratingPeriod])

    const onSetTimePeriod = v => {
        setPeriodType(v)
        setRatingPeriodType(EMPTY_OPTION)
    }
    const onSetRatingPeriod = v => {
        setRatingPeriodType(v)
        setPeriodType(EMPTY_OPTION)
    }

    return (
        <div className="statistic-header">
            <div className="statistic-header__block">
                {!isBlockHaveRating && (
                    <Dropdown active={periodType} list={listPeriodType} onChange={v => onSetTimePeriod(v)} />
                )}
                {isBlockHaveRating && (
                    <Dropdown active={ratingPeriodType} list={ratingList} onChange={v => onSetRatingPeriod(v)} />
                )}
                {periodType.value === 'Custom' && (
                    <DateRangePicker
                        startDate={range.from}
                        endDate={range.to}
                        onChangeRange={v => {
                            const formatted = {
                                from: moment(v.from).startOf('day').valueOf(),
                                to: moment(v.to).endOf('day').valueOf(),
                            }
                            setRange(formatted)
                            onChangeRange(formatted, periodType)
                        }}
                    />
                )}
            </div>
            <div className="statistic-header__block">
                {isEnableCSV && (
                    <div className="export" onClick={() => onExportCSV()}>
                        {t('Export CSV')}
                        <img src={IconCsv} alt="" />
                    </div>
                )}
                {isCanStartNewRating && (
                    <button
                        className="button"
                        data-size="md"
                        data-variant="primary"
                        data-test="start-new-rating"
                        disabled={isLoading}
                        onClick={() => onStartNewRating()}
                    >
                        {t('Start a new rating')}
                    </button>
                )}
            </div>
        </div>
    )
}

export default DateRangePanel
