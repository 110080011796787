import React, { useState, useEffect } from 'react'

const WRAPPER_BORDER_WIDTH_COEFFICIENT = 7.042253521126761
const BOX_BORDER_WIDTH_COEFFICIENT = 2.992957746478873
const CEIL_BORDER_WIDTH_COEFFICIENT = 0.528169014084507

const useSizes = (boxWidth, tilesCount) => {
    const [wrapperBorderWidth, setWrapperBorderWidth] = useState(0)
    const [boxBorderWidth, setBoxBorderWidth] = useState(0)
    const [ceilBorderWidth, setCeilBorderWidth] = useState(0)
    const [tileSize, setTileSize] = useState(0)
    const [fontSize, setFontSize] = useState(0)

    useEffect(() => {
        const newWrapperBorderWidth = Math.round((boxWidth / 100) * WRAPPER_BORDER_WIDTH_COEFFICIENT)
        const containerWidth = boxWidth - newWrapperBorderWidth * 2
        const newCeilBorderWidth = Math.round((containerWidth / 100) * CEIL_BORDER_WIDTH_COEFFICIENT)
        const newBoxBorderWidth = Math.round((containerWidth / 100) * BOX_BORDER_WIDTH_COEFFICIENT)
        const size = (containerWidth - newBoxBorderWidth * 2) / Math.sqrt(tilesCount)
        setWrapperBorderWidth(newWrapperBorderWidth)
        setBoxBorderWidth(newBoxBorderWidth)
        setCeilBorderWidth(newCeilBorderWidth)
        setTileSize(size)
        setFontSize(Math.round((size - newCeilBorderWidth * 2) / 2))
    }, [boxWidth, tilesCount])

    return {
        boxBorderWidth,
        ceilBorderWidth,
        wrapperBorderWidth,
        tileSize,
        fontSize,
    }
}

export default useSizes
