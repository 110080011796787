import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import classNames from 'classnames'

import './Select.scss'

export const VARIANT = {
    normal: 'normal',
    increased: 'increased',
    big: 'big',
}

const getControlHeight = variant => {
    if (variant === VARIANT.big) return 44
    if (variant === VARIANT.increased) return 36
    return 32
}
const getSingleValueFontSize = variant => {
    if (variant === VARIANT.big) return 16
    if (variant === VARIANT.increased) return 16
    return 14
}
const getOptionFontSize = variant => {
    if (variant === VARIANT.big) return 16
    if (variant === VARIANT.increased) return 16
    return 14
}

const styles = {
    control: (provided, { isDisabled, isFocused, selectProps: { variant, isError } }) => ({
        height: getControlHeight(variant),
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: isFocused ? '#69B1FC' : isError ? '#ff5656' : '#979797',
        backgroundColor: isDisabled ? 'hsl(0, 0%, 95%)' : '#fff',
        boxShadow: isFocused ? 'inset 0 0 0 1px #69b1fc' : 'none',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        cursor: 'pointer',
    }),
    valueContainer: () => ({
        padding: 0,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
    }),
    singleValue: (provided, { selectProps: { variant } }) => ({
        fontFamily: "'Roboto', sans-serif",
        fontSize: getSingleValueFontSize(variant),
        lineHeight: '20px',
        color: '#3C3C3C',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    indicatorsContainer: () => ({
        flexShrink: 0,
        padding: '0 5px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: () => ({
        color: '#979797',
    }),
    placeholder: (provided, { selectProps: { variant } }) => ({
        fontFamily: "'Roboto', sans-serif",
        color: '#979797',
        fontSize: getSingleValueFontSize(variant),
    }),
    menuList: provided => ({
        ...provided,
        maxHeight: 300,
    }),
    menu: provided => ({
        ...provided,
        backgroundColor: '#fff',
        boxShadow: '0 2px 20px -4px rgba(0,0,0,.25)',
        borderRadius: '4px',
        top: '100%',
        left: 0,
        margin: '4px 0 0 0',
    }),
    menuPortal: base => ({ ...base, zIndex: 99999999999999 }),
    option: (provided, { selectProps: { variant }, isSelected }) => {
        const styles = {
            ...provided,
            cursor: 'pointer',
            textAlign: 'left',
            backgroundColor: '#fff',
            padding: '8px 7px',
            fontFamily: "'Roboto', sans-serif",
            fontSize: getOptionFontSize(variant),
            color: '#3C3C3C',
            '&:hover': {
                backgroundColor: '#eee',
            },
        }

        if (isSelected) {
            styles.pointerEvents = 'none'
            styles.backgroundColor = '#69B1FC'
            styles.color = '#fff'
        }

        return styles
    },
    input: provided => ({
        ...provided,
        fontSize: 16,
        fontFamily: "'Roboto', sans-serif",
        color: '#3C3C3C',
    }),
}

const Select = ({
    label = '',
    value,
    options = [],
    onUpdate,
    isDisabled,
    fullWide = false,
    isClearable = false,
    placeholder = '',
    variant = VARIANT.normal,
    isSearchable = false,
    className,
    labelClassName,
    errorMessages = [],
    isModal = false,
}) => {
    const [_value, _setValue] = useState(value)
    const [_options, _setOptions] = useState(value)

    useEffect(() => {
        _setValue(value)
    }, [value])

    useEffect(() => {
        _setOptions(options)
    }, [options])

    const onChange = value => {
        if (!isDisabled) {
            _setValue(value)
            try {
                onUpdate(value)
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <div className={classNames('common-select', { 'full-width': fullWide, [className]: !!className })}>
            {!!label && (
                <p className={classNames('common-select__label', { [labelClassName]: !!labelClassName })}>{label}</p>
            )}
            <ReactSelect
                placeholder={placeholder}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                menuPortalTarget={isModal ? document.getElementById('modalContainer') : document.body}
                menuPosition="absolute"
                options={_options}
                value={_value}
                styles={styles}
                onChange={val => onChange(val)}
                variant={variant}
                isError={!!errorMessages.length}
            />
            {!!errorMessages.length && <p className="common-select__error">{errorMessages[0]}</p>}
        </div>
    )
}

export default Select
