const getElementSizes = element => {
    const clientRect = element.getBoundingClientRect()
    return {
        width: clientRect.width,
        height: clientRect.height,
    }
}

const isFit = (parentWidth, parentHeight, contentWidth, contentHeight) =>
    contentWidth <= parentWidth && contentHeight <= parentHeight

export const getOptimalTextFontSize = ({
    parentRef,
    text,
    fontFamily,
    lineHeight,
    fontWeight,
    minSize,
    normalSize,
    maxSize,
}) => {
    if (!parentRef?.current) return `${normalSize}px`
    const { width, height } = getElementSizes(parentRef.current)

    const box = document.createElement('div')
    try {
        box.style.width = `${width}px`
        box.style.height = `${height}px`

        const content = document.createElement('div')
        content.style.textAlign = 'center'
        content.style.width = 'fit-content'
        content.style.height = 'fit-content'
        content.style.lineHeight = lineHeight
        content.style.fontFamily = fontFamily
        content.style.fontWeight = fontWeight
        content.style.fontSize = `${maxSize}px`
        content.innerHTML = text

        box.appendChild(content)

        document.body.appendChild(box)

        let contentSizes = getElementSizes(content)

        // Try maxSize
        if (isFit(width, height, contentSizes.width, contentSizes.height)) {
            return maxSize
        }

        // Try normalSize
        content.style.fontSize = `${normalSize}px`
        contentSizes = getElementSizes(content)
        if (isFit(width, height, contentSizes.width, contentSizes.height)) {
            return normalSize
        }

        // Find optimal size
        for (let fz = normalSize - 1; fz > minSize; fz--) {
            content.style.fontSize = `${fz}px`
            contentSizes = getElementSizes(content)
            if (isFit(width, height, contentSizes.width, contentSizes.height)) {
                return fz
            }
        }

        // Return minSize
        return minSize
    } catch (err) {
        console.error(err)
        return normalSize
    } finally {
        box.remove()
    }
}
