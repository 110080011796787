import React from 'react'
import { useTranslation } from 'react-i18next'

import EMAILS from 'common/constants/emails'

import CodeBox from '../../components/CodeBox'

import './EmbedCode.scss'

const EmbedCode = ({ embedCode, iframeCode }) => {
    const { t } = useTranslation()
    return (
        <div className="embed-code-tab">
            <p className="embed-code-tab__title">{t('Copy and paste the code below into your website or LMS')}</p>
            <div className="embed-code-tab__content">
                <CodeBox title={t('Install embed code')} value={embedCode} rows={3} />
                <CodeBox
                    title={t('or use alternative iframe code')}
                    value={iframeCode}
                    rows={5}
                    isVisbile={!!iframeCode}
                />
            </div>
            <div className="embed-code-tab__contact">
                {t('Have problems?')} <a href={`mailto:${EMAILS.INTERACTY_SUPPORT}`}>{t('Contact us')}</a>
            </div>
        </div>
    )
}

export default EmbedCode
