import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './Card.module.scss'

const Card = ({ imageSrc, title, description, href }) => {
    const { t } = useTranslation()

    return (
        <li className={styles.card}>
            <a className={styles.cardContent} href={href}>
                <div className={classNames(styles.title, styles.mobileTitle)}>{title}</div>
                <div className={styles.imageContainer}>
                    <img src={imageSrc} alt="" />
                </div>
                <div className={styles.textsContainer}>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.description}>{description}</p>
                    <p className={styles.link}>{t('Read more')}</p>
                </div>
                <div className={styles.button} />
            </a>
        </li>
    )
}

export default Card
