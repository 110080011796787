import React from 'react'

export const Triangle = ({ color = 'white' }) => (
    <svg width="98" height="74" viewBox="0 0 98 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2061_2519)">
            <g filter="url(#filter0_d_2061_2519)">
                <path
                    d="M55.2716 56.9225C52.0959 61.0258 45.9042 61.0258 42.7284 56.9225L11.6757 16.7992C7.63831 11.5825 11.3537 4 17.9473 4L80.0527 4.00001C86.6463 4.00001 90.3617 11.5825 86.3243 16.7992L55.2716 56.9225Z"
                    fill={color}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3794 13.1536L47.4321 53.2769C48.226 54.3027 49.774 54.3027 50.5679 53.2769L81.6207 13.1536C82.63 11.8494 81.7012 9.95376 80.0527 9.95376L17.9473 9.95376C16.2989 9.95376 15.37 11.8494 16.3794 13.1536ZM42.7284 56.9225C45.9042 61.0258 52.0959 61.0258 55.2716 56.9225L86.3243 16.7992C90.3617 11.5825 86.6463 4.00001 80.0527 4.00001L17.9473 4C11.3537 4 7.63831 11.5825 11.6757 16.7992L42.7284 56.9225Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2061_2519"
                x="-2"
                y="0"
                width="102"
                height="80"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="6" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2061_2519" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2061_2519" result="shape" />
            </filter>
            <clipPath id="clip0_2061_2519">
                <rect width="98" height="74" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
