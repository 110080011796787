import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import storage from 'common/utils/storage'

import MobileTongue from '../components/MobileTongue/MobileTongue'
import { DIRECTIONS } from '../components/MobileTongue/constants'
import PanelHeader from '../components/PanelHeader'

import AddInternalPageModal from '../../Modals/AddInternalPageModal/AddInternalPageModal'

import Page from './components/Page'

import './InternalPages.scss'

const InternalPages = ({
    pages,
    selectedPage,
    onSelectPage,
    onAddPage,
    onRenamePage,
    onClonePage,
    onDeletePage,
    onChangePosition,
}) => {
    const { t } = useTranslation()
    const [isOpenAddInternalPageModal, setIsOpenAddInternalPageModal] = useState(false)
    const [clonedPageId, setClonedPageId] = useState(null)
    const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState(false)
    const [deletedPageId, setDeletedPageId] = useState(null)
    const panelScrollRef = useRef()
    const [isMobileOpened, setIsOpenedMobile] = useState(false)

    const onClickAddInternalPageButton = () => {
        setClonedPageId(null)
        if (pages.length === 1) {
            onOpenAddInternalPageModal()
            return
        }
        onAddPage()
    }
    const onClickClonePage = id => {
        if (pages.length === 1) {
            setClonedPageId(id)
            onOpenAddInternalPageModal()
            return
        }
        onClonePage(id)
    }

    const onOpenAddInternalPageModal = () => {
        const isSkipModal = !!storage.getValue('DONT_SHOW_HOW_ADD_INTERNAL_PAGE')
        if (isSkipModal) addPage()
        else setIsOpenAddInternalPageModal(true)
    }

    const onOpenDeleteConfirmationDialog = id => {
        setDeletedPageId(id)
        setIsOpenConfirmationDialog(true)
    }

    const deletePage = () => {
        onDeletePage(deletedPageId)
        setIsOpenConfirmationDialog(false)
    }
    const addPage = () => {
        if (clonedPageId) onClonePage(clonedPageId)
        else onAddPage()
    }

    return (
        <div className={classNames('internal-pages', { 'internal-pages--opened': isMobileOpened })}>
            <MobileTongue
                isOpened={isMobileOpened}
                onChange={value => setIsOpenedMobile(value)}
                direction={DIRECTIONS.LEFT}
                closeText={t('Close pages')}
                openText={t('Open pages')}
            />
            <PanelHeader className="internal-pages__title" blockName={t('Pages')} scrollRef={panelScrollRef} />
            <ScrollableBlock styles={{ height: 'calc(100% - 56px)', width: '100%' }} scrollableNodeRef={panelScrollRef}>
                <div className="internal-pages__page-list">
                    {pages.map((page, index) => (
                        <Page
                            key={page.id}
                            page={page}
                            selectedPage={selectedPage}
                            index={index}
                            arrayLength={pages.length}
                            methods={{
                                onSelectPage,
                                onRenamePage,
                                onRemove: onOpenDeleteConfirmationDialog,
                                onClone: onClickClonePage,
                                onChangePosition,
                            }}
                        />
                    ))}
                </div>
                <div className="internal-pages__add-button" onClick={onClickAddInternalPageButton}>
                    {t('Add page')}
                </div>
            </ScrollableBlock>
            {isOpenConfirmationDialog && (
                <ConfirmationDialog
                    onClose={() => setIsOpenConfirmationDialog(false)}
                    onAction={deletePage}
                    data={{
                        headText: t('Are you sure you want to delete this page?'),
                        noteText: t('All blocks on this page will also be deleted.'),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}

            <AddInternalPageModal
                isOpen={isOpenAddInternalPageModal}
                onAddPage={addPage}
                onClose={() => setIsOpenAddInternalPageModal(false)}
            />
        </div>
    )
}

export default InternalPages
