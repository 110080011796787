import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { getFullUrlToSsr } from 'utils/router'

import { ACTIONS } from '../../../../constants'

import useAction from '../../../../hooks/useAction'

import logoIcon from './i/logo.svg'

import styles from './ActionSwitcher.module.scss'

const ActionSwitcher = () => {
    const { t } = useTranslation()
    const { action, onChangeAction } = useAction()

    return (
        <div className={styles.actionSwitcher}>
            <div className={styles.desktopView}>
                <div className={styles.text}>
                    {action === ACTIONS.REGISTRATION ? t('Already have an account?') : t('Don’t have an account?')}
                </div>
                <button
                    className={classNames('button', styles.button)}
                    data-size="lg"
                    data-variant="primary-light"
                    onClick={() =>
                        onChangeAction(action === ACTIONS.REGISTRATION ? ACTIONS.AUTHENTICATION : ACTIONS.REGISTRATION)
                    }
                >
                    {action === ACTIONS.REGISTRATION ? t('Sign in') : t('Get started')}
                </button>
            </div>
            <div className={styles.mobileView}>
                <a href={getFullUrlToSsr()}>
                    <img className={styles.icon} src={logoIcon} alt="" />
                </a>
                <button
                    className="button"
                    data-size="md"
                    data-variant="secondary-white"
                    onClick={() =>
                        onChangeAction(action === ACTIONS.REGISTRATION ? ACTIONS.AUTHENTICATION : ACTIONS.REGISTRATION)
                    }
                >
                    {action === ACTIONS.REGISTRATION ? t('Sign in') : t('Get started')}
                </button>
            </div>
        </div>
    )
}

export default ActionSwitcher
