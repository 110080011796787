import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import { API__DOMAINS, HTTP_STATUS } from 'api'
import TextInput from '../../../../components/Form/TextInput/TextInput'
import { IconCopy, IconDomain } from '../../../../svg'
import './ManageCustomDomainModal.scss'

function isDomain(value) {
    return /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/.test(
        value.toLowerCase(),
    )
}

const ManageCustomDomainModal = ({ projectId, onClose }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const [domains, setDomains] = useState([])
    const [removingDomainId, setRemovingDomainId] = useState(null)

    useEffect(() => {
        API__DOMAINS.GET_DOMAINS(projectId).then(domainList => {
            setDomains(domainList)
        })
    }, [projectId, setDomains])

    const handleCloseModal = () => {
        onClose()
        setValue('')
    }

    const handleAddDomain = () => {
        const domain = value.trim()
        if (!isDomain(domain)) {
            setError(t('Must be correct domain address. For example: example.me'))
            return
        }
        API__DOMAINS.ADD_DOMAIN(projectId, domain)
            .then(newDomain => {
                const refreshedDomains = [...domains, newDomain]
                setDomains(refreshedDomains)
                setValue('')
            })
            .catch(err => {
                if (err.response && err.response.status === HTTP_STATUS.Conflict)
                    setError(t('This domain is already taken'))
            })
    }
    const handleRemoving = (id, evt) => {
        evt.preventDefault()
        setRemovingDomainId(id)
    }

    const handleRemoveDomain = id => {
        API__DOMAINS.DELETE_DOMAIN(projectId, id)
            .then(() => {
                const filterDomains = domains.filter(item => item.id !== id)
                setDomains(filterDomains)
            })
            .finally(() => {
                setRemovingDomainId(null)
            })
    }

    const handleCopyDns = val => {
        copy(val)
        Toast('success', {
            title: t('Dns name copied!'),
            time: 2000,
        })
    }

    const handleOpenDomain = val => {
        window.open(`https://${val}`, '_blank')
    }

    function handleUpdateDomainValue(val) {
        setValue(val)
        setError('')
    }

    return (
        <Modal isShowCloseIcon onClose={handleCloseModal}>
            <div className="manage-custom-domain-modal">
                <h1 className="manage-custom-domain-modal__title">{t('Manage custom domains')}</h1>
                <div className="manage-custom-domain-modal__instruction">
                    <Trans
                        i18nKey="After you add a domain, you need to point your DNS provider at the DNS target provided by Interacty. Please read <helpLink>full instruction</helpLink>."
                        components={{
                            helpLink: (
                                <a href="https://help.interacty.me/content/custom-domain-cname" target="_blank" />
                            ),
                        }}
                    />
                </div>
                <div className="manage-custom-domain-modal__invite-block">
                    <TextInput
                        className="manage-custom-domain-modal__invite-block-input"
                        controlType="url"
                        fullWide
                        value={value}
                        placeholder={t('Enter Domain')}
                        onUpdate={val => handleUpdateDomainValue(val)}
                        errorMessages={error ? [error] : []}
                    />
                    <button
                        className="manage-custom-domain-modal__invite-block-button button"
                        data-variant="primary"
                        disabled={!value.length}
                        data-size="lg"
                        onClick={handleAddDomain}
                    >
                        {t('Add')}
                    </button>
                </div>

                <ul className="manage-custom-domain-modal__domain-list">
                    {domains.map(domain => {
                        return (
                            <li className="manage-custom-domain-modal__domain" key={domain.id}>
                                <div className="manage-custom-domain-modal__domain-info">
                                    <div className="manage-custom-domain-modal__domain-info-url">
                                        <IconDomain color="#2990FB" />
                                        <p onClick={() => handleOpenDomain(domain.name)}>{domain.name}</p>
                                    </div>
                                    <div className="manage-custom-domain-modal__domain-info-hint">
                                        {t(
                                            'Copy this record to your DNS settings, selecting CNAME as the record type:',
                                        )}
                                    </div>
                                    <div
                                        className="manage-custom-domain-modal__domain-info-dns"
                                        onClick={() => handleCopyDns(domain.cname)}
                                    >
                                        <p>{domain.cname}</p>
                                        <IconCopy />
                                    </div>
                                </div>
                                <div
                                    className="manage-custom-domain-modal__domain-action-remove"
                                    onClick={evt => handleRemoving(domain.id, evt)}
                                >
                                    {t('Remove')}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            {removingDomainId && (
                <ConfirmationDialog
                    onClose={() => setRemovingDomainId(null)}
                    onAction={() => handleRemoveDomain(removingDomainId)}
                    data={{
                        headText: t('Remove this domain?'),
                        noteText: t('After that, your project will not be available at this domain name'),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </Modal>
    )
}

export default ManageCustomDomainModal
