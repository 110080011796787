import {
    createCollector,
    createValidator,
    defined,
    dependOn,
    itemRules,
    maxLength,
    minLength,
    required,
    isIntegerNumber,
    minNumber,
    maxNumber,
    oneOf,
} from 'shared/validation'
import {
    MIN_SECTIONS_COUNT,
    MAX_SECTIONS_COUNT,
    SECTION_TYPES,
    MIN_ANIMATION_DURATION,
    MAX_ANIMATION_DURATION,
    SECTION_WIN_PROBABILITY,
} from './constants'

import { BLOCK_KEYS, Playground as PlaygroundModel, Section as SectionModel } from './wheelSpinService'

function convertItemErrorsToMap(items, errors) {
    return new Map(items.map((item, index) => [item.id, errors[index]]))
}

const WHEEL_SPIN_VALIDATION_RULES_FIELDS = {
    [BLOCK_KEYS.PLAYGROUND]: {
        [PlaygroundModel.sectionsCount]: [required(), minNumber(MIN_SECTIONS_COUNT), maxNumber(MAX_SECTIONS_COUNT)],
        [PlaygroundModel.sectionType]: [required(), oneOf(Object.values(SECTION_TYPES))],
        [PlaygroundModel.animationDuration]: [
            required(),
            isIntegerNumber(),
            minNumber(MIN_ANIMATION_DURATION),
            maxNumber(MAX_ANIMATION_DURATION),
        ],
        [PlaygroundModel.spinImageUrl]: [required()],
        [PlaygroundModel.isShowCover]: [defined()],
        [PlaygroundModel.coverHeader]: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(400),
        ],
        [PlaygroundModel.coverButtonText]: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(20),
        ],
    },
    [BLOCK_KEYS.SECTIONS]: [
        itemRules(
            {
                [SectionModel.id]: [required()],
                [SectionModel.text]: [
                    dependOn(({ parent }) => {
                        return parent[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType] === SECTION_TYPES.TEXT
                            ? required()
                            : null
                    }),
                    minLength(1),
                    maxLength(50),
                ],
                [SectionModel.imageUrl]: [
                    dependOn(({ parent }) => {
                        return parent[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType] === SECTION_TYPES.IMAGE
                            ? required()
                            : null
                    }),
                ],
                [SectionModel.probabilityOfWinning]: [required(), oneOf(Object.values(SECTION_WIN_PROBABILITY))],
                [SectionModel.resultHeader]: [required(), maxLength(500)],
                [SectionModel.resultDescription]: [maxLength(5000)],
                [SectionModel.resultImageUrl]: [defined()],
                [SectionModel.resultImageDisclaimer]: [maxLength(100)],
                [SectionModel.resultButtonText]: [defined()],
                [SectionModel.resultButtonLink]: [defined()],
            },
            convertItemErrorsToMap,
        ),
    ],
}

export const validateTabs = (tmpStructure, activeTabKey = null) => {
    const isValid = createValidator(WHEEL_SPIN_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)
    const errors = createCollector(WHEEL_SPIN_VALIDATION_RULES_FIELDS)(tmpStructure, activeTabKey)

    return {
        tmpStructure,
        errors,
        isHasError: !isValid,
    }
}
