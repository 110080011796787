import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { filterDataAttr } from 'common/utils/attributes'

import './TextInput.scss'

const TextInput = ({
    label = '',
    value,
    onUpdate,
    controlType = 'text',
    errorMessages = [],
    placeholder = '',
    isRequired,
    isDisabled,
    isFocused,
    isOptional,
    isReadOnly = false,
    isClickable = false,
    description,
    onClick = () => {},
    fullWide = false,
    maxLength,
    className,
    labelClassName,
    inputClassName,
    action,
    autoFocus,
    min,
    max,
    onKeyDown = () => {},
    onPaste = () => {},
    ...rest
}) => {
    const { t } = useTranslation()

    const onChange = evt => {
        try {
            if (isDisabled) return

            const val = evt.target.value
            if (maxLength && maxLength < val.length) {
                onUpdate(val.slice(0, maxLength))
                return
            }

            onUpdate(val)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <div
            className={classNames('common-text-input', {
                'common-text-input__disabled': isDisabled,
                'full-wide': fullWide,
                [className]: !!className,
            })}
        >
            {!!label.length && (
                <p
                    className={classNames('common-text-input__label', {
                        'common-text-input__required': isRequired,
                        'common-text-input__optional': isOptional,
                        [labelClassName]: !!labelClassName,
                    })}
                    data-description={description}
                    data-optional={`(${t('optional')})`}
                    title={label}
                >
                    <span className="common-text-input__label-text">{label}</span>
                    {!!maxLength && (
                        <span className="common-text-input__maxLength">
                            {value.length}/{maxLength}
                        </span>
                    )}
                </p>
            )}
            <div className="common-text-input__input-container">
                <input
                    autoFocus={autoFocus}
                    disabled={isDisabled}
                    readOnly={isReadOnly}
                    placeholder={placeholder}
                    className={classNames('common-text-input__input-text', {
                        'is-error': !!errorMessages.length,
                        'is-focused': isFocused,
                        'is-clickable': isClickable,
                        [inputClassName]: !!inputClassName,
                    })}
                    type={controlType}
                    value={value}
                    title={value}
                    onChange={onChange}
                    onClick={onClick}
                    min={min}
                    max={max}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    {...filterDataAttr(rest)}
                />
                {!!action && action}
            </div>
            {!!errorMessages.length && <p className="common-text-input__error">{errorMessages[0]}</p>}
        </div>
    )
}

export default TextInput
