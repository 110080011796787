import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { API__ANALYTICS } from 'api'

import i18n from 'i18n'

import { formattedTime } from 'utils/formatter'

import Tabs from './components/Tabs/Tabs'
import Views from './views/Views'
import Users from './views/Users'
import AverageAttentionTime from './views/AverageAttentionTime'

import './Overview.scss'

const TABS = {
    VIEWS: {
        key: 'VIEWS',
        responseKey: 'views',
        formatValue: v => v,
        label: i18n.t('Views'),
        component: Views,
        dataTestValue: 'select-tab-views',
    },
    USERS: {
        key: 'USERS',
        responseKey: 'users',
        formatValue: v => v,
        label: i18n.t('Users'),
        component: Users,
        dataTestValue: 'select-tab-users',
    },
    AVERAGE_ATTENTION_TIME: {
        key: 'AVERAGE_ATTENTION_TIME',
        responseKey: 'averageAttentionTime',
        formatValue: v => formattedTime(v),
        label: i18n.t('Average attention time'),
        component: AverageAttentionTime,
        dataTestValue: 'select-tab-avg-time',
    },
}

const Overview = ({ projectId, dateRange }) => {
    const { t } = useTranslation()

    const [activeTabCode, setActiveTabCode] = useState(TABS.VIEWS.key)
    const [projectStatistics, setProjectStatistics] = useState(null)

    useEffect(() => {
        API__ANALYTICS.GET_PROJECT_STATISTICS({
            projectId,
            from: dateRange.from,
            to: dateRange.to,
        }).then(data => {
            setProjectStatistics(data)
        })
    }, [dateRange])

    const Component = TABS[activeTabCode].component

    return (
        <div className="chart-card">
            <h1>{t('Overview')}</h1>
            {projectStatistics && (
                <>
                    <Tabs
                        activeTabCode={activeTabCode}
                        tabs={Object.values(TABS)}
                        changeActiveTab={key => setActiveTabCode(key)}
                        commonProjectStatistics={projectStatistics.commonStatistic}
                    />
                    <Component projectId={projectId} t={t} dateRange={dateRange} />
                </>
            )}
        </div>
    )
}

export default Overview
