import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { prettifyNumber } from '../../../../utils'

import styles from './BlockSummary.module.scss'

const BlockSummary = ({ statistic }) => {
    const { t } = useTranslation()
    const [started, setStarted] = useState(0)
    const [completed, setCompleted] = useState(0)
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        if (!statistic) return

        setCompleted(statistic.completed)

        if (statistic.completed > statistic.started) {
            setPercent(100)
            setStarted(statistic.completed)
        } else {
            setPercent(statistic.completedPercent)
            setStarted(statistic.started)
        }
    }, [statistic])

    if (!statistic) return null

    return (
        <div className={styles.blockSummary}>
            <h1 className={styles.blockSummaryTitle}>{t('Summary')}</h1>
            <div className={styles.blockSummaryContent}>
                <div className={styles.blockSummaryItem}>
                    <p className={styles.blockSummaryItemTitle}>{t('Started ')}</p>
                    <p className={styles.blockSummaryItemCount}>{prettifyNumber(started)}</p>
                </div>
                <div className={styles.blockSummaryItem}>
                    <p className={styles.blockSummaryItemTitle}>{t('Completed')}</p>
                    <p className={styles.blockSummaryItemCount}>
                        {prettifyNumber(completed)}
                        <span className={styles.blockSummaryItemPostfix}>{percent}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlockSummary
