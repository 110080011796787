import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import imagePreview from './i/preview.png'

import styles from './Instruction.module.scss'

const Instruction = ({ onOpenNew }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.instruction}>
            <article className={styles.article}>
                <div className={styles.title}>{t('What is a Question bank?')}</div>
                <div className={styles.text}>
                    <Trans
                        i18nKey="The Question bank allows you to create trivia quizzes, in which the required number of questions is randomly selected from a pre-created list. <lnk>Read more</lnk>"
                        components={{
                            lnk: (
                                <a
                                    href="https://help.interacty.me/content/how-to-create-a-question-bank-for-your-quiz"
                                    target="_blank"
                                    style={{ color: '#2990FB', textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </div>
            </article>
            <article className={styles.article}>
                <div className={styles.title}>{t('How to create a Question bank?')}</div>
                <div className={styles.text}>
                    <Trans
                        i18nKey='To create a question bank, click "<lnk>Create</lnk>" button or download and edit one of the templates: <lnkCSV>template.csv</lnkCSV> or <lnkXLSX>template.xlsx</lnkXLSX>, and then upload the finished file on this page.'
                        components={{
                            lnk: (
                                <span
                                    style={{ color: '#2990FB', textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => onOpenNew()}
                                />
                            ),
                            lnkCSV: (
                                <a
                                    href="/template.csv"
                                    target="_blank"
                                    style={{ color: '#2990FB', textDecoration: 'none' }}
                                />
                            ),
                            lnkXLSX: (
                                <a
                                    href="/template.xlsx"
                                    target="_blank"
                                    style={{ color: '#2990FB', textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </div>
            </article>
            <article className={styles.article}>
                <div className={styles.text}>
                    {t('For the quizzes to work correctly, the file must contain:')}
                    <ul>
                        <li>{t('question titles')}</li>
                        <li>{t('answer options (up to 10)')}</li>
                        <li>
                            {t(
                                'the sequence number of the correct answer (there may be several of them, in which case their numbers are separated by the symbol " | ")',
                            )}
                        </li>
                    </ul>
                </div>
            </article>
            <article className={styles.article}>
                <div className={styles.text}>
                    {t(
                        'To edit an already uploaded bank, click on it. Use the question bank in the Trivia quiz editor on the Questions tab.',
                    )}
                </div>
            </article>
            <article className={styles.article}>
                <img className={styles.image} src={imagePreview} alt="" />
            </article>
        </div>
    )
}

export default Instruction
