import React, { useEffect, useState } from 'react'

import LoaderEllipsis from 'components/LoaderEllipsis/LoaderEllipsis'

import TopBar from './components/TopBar/TopBar'

import InterestsSelector from './views/InterestsSelector/InterestsSelector'
import AgeSelector from './views/AgeSelector/AgeSelector'
import WhatIs from './views/WhatIs/WhatIs'
import Contacts from './views/Contacts/Contacts'

import { STEPS_ENUM, INTERESTS_STEP_PARENT_TAGS_NAMES, AGE_STEP_PARENT_TAGS_NAMES } from './constants'

import styles from './ChooseInterests.module.scss'

const ChooseInterests = ({ tags, onClose, onSelect }) => {
    const [isLoading, setIsLoading] = useState(false)

    const [step, setStep] = useState(STEPS_ENUM.INTERESTS_SELECTOR)
    const [isCanGoNext, setIsCanGoNext] = useState(false)

    const [interestsTags, setInterestsTags] = useState([])
    const [selectedInterestsTags, setSelectedInterestsTags] = useState([])

    const [ageTags, setAgeTags] = useState([])
    const [selectedAgeTags, setSelectedAgeTags] = useState([])

    useEffect(() => {
        const _interestsTags = []
        INTERESTS_STEP_PARENT_TAGS_NAMES.forEach(tagName => {
            const tag = tags.find(tag => tag.parentTag.name === tagName && !!tag.childrenTags.length)
            if (tag) _interestsTags.push(tag)
        })
        setInterestsTags(_interestsTags)

        const _ageTags = []
        AGE_STEP_PARENT_TAGS_NAMES.forEach(tagName => {
            const tag = tags.find(tag => tag.parentTag.name === tagName)
            if (tag) _ageTags.push(tag)
        })
        setAgeTags(_ageTags)
    }, [tags])

    useEffect(() => {
        if (step === STEPS_ENUM.INTERESTS_SELECTOR) {
            setIsCanGoNext(!!selectedInterestsTags.length)
            return
        }
        if (step === STEPS_ENUM.AGE_SELECTOR) {
            setIsCanGoNext(!!selectedAgeTags.length)
            return
        }
        if (step === STEPS_ENUM.WHAT_IS) {
            setIsCanGoNext(true)
            return
        }
        if (step === STEPS_ENUM.CONTACTS) {
            setIsCanGoNext(true)
            return
        }
        setIsCanGoNext(false)
    }, [step, selectedInterestsTags, selectedAgeTags])

    const onHandleNext = async () => {
        try {
            setIsLoading(true)

            if (step === STEPS_ENUM.INTERESTS_SELECTOR) {
                const selectedTagsNames = selectedInterestsTags.map(tag => tag.name)
                const parentTags = []
                for (const tag of tags) {
                    if (!tag.childrenTags.length) continue
                    if (tag.childrenTags.some(tag => selectedTagsNames.includes(tag.name)))
                        parentTags.push(tag.parentTag)
                }
                setSelectedInterestsTags([...selectedInterestsTags, ...parentTags])
                setStep(STEPS_ENUM.AGE_SELECTOR)
            }
            if (step === STEPS_ENUM.AGE_SELECTOR) {
                await onSelect([...selectedInterestsTags, ...selectedAgeTags])
                setStep(STEPS_ENUM.WHAT_IS)
            }
            if (step === STEPS_ENUM.WHAT_IS) {
                setStep(STEPS_ENUM.CONTACTS)
            }
            if (step === STEPS_ENUM.CONTACTS) {
                onClose()
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.chooseInterests}>
            <TopBar
                currentStep={step}
                stepsCount={Object.keys(STEPS_ENUM).length}
                onNext={() => onHandleNext()}
                isCanGoNext={!isLoading && isCanGoNext}
            />
            <div className={styles.content}>
                {isLoading ? (
                    <div className={styles.loader}>
                        <LoaderEllipsis />
                    </div>
                ) : (
                    <>
                        {step === STEPS_ENUM.INTERESTS_SELECTOR && (
                            <InterestsSelector
                                tags={interestsTags}
                                selectedTags={selectedInterestsTags}
                                onUpdateSelectedTags={data => setSelectedInterestsTags(data)}
                            />
                        )}
                        {step === STEPS_ENUM.AGE_SELECTOR && (
                            <AgeSelector
                                tags={ageTags}
                                selectedTags={selectedAgeTags}
                                onUpdateSelectedTags={data => setSelectedAgeTags(data)}
                            />
                        )}
                        {step === STEPS_ENUM.WHAT_IS && <WhatIs />}
                        {step === STEPS_ENUM.CONTACTS && <Contacts />}
                    </>
                )}
            </div>
        </div>
    )
}

export default ChooseInterests
