import React from 'react'
import classNames from 'classnames'

import styles from './Marks.module.scss'

const Marks = ({ data = [] }) => (
    <ul className={styles.marks}>
        {data.map((mark, i) => (
            <li
                key={`${mark.text}_${i}`}
                className={classNames(styles.mark, {
                    [styles.isFilledMark]: mark.isFilled,
                    [styles.isReversedMark]: mark.isReversed,
                })}
            >
                <div
                    className={classNames(styles.icon, { [styles.isFilledIcon]: !!mark.iconBackground })}
                    style={{ backgroundColor: mark.iconBackground || 'transparent' }}
                >
                    <img src={mark.iconUrl} alt="" />
                </div>
                <p className={styles.text}>{mark.text}</p>
            </li>
        ))}
    </ul>
)

export default Marks
