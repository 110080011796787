import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEducation } from 'common/utils/permissions.js'

import gamificationDemoImage from '../i/gamification_editor_right_panel_demo.png'
import privacyDemoImage from '../i/privacy_editor_right_panel_demo.png'

const DemoContent = ({ isPrivacy }) => {
    const { t } = useTranslation()

    if (isPrivacy) {
        return (
            <div className="demo-with-modal__tooltip-content">
                <h3>{t('Privacy')}</h3>
                <p>
                    {isEducation()
                        ? t(
                              'Restrict access to your project by specifying access codes or allowing access only to students with specific email addresses.',
                          )
                        : t(
                              'Restrict access to your project by specifying access codes or allowing access only to users with specific email addresses.',
                          )}
                </p>
                <img src={privacyDemoImage} alt="" width={180} height={180} />
                <p>
                    {isEducation()
                        ? t('Privacy is available for the Intermediate and Advanced plans.')
                        : t('Privacy is available for PRO+ plan and above, as well as for one-time projects.')}
                </p>
            </div>
        )
    }

    return (
        <div className="demo-with-modal__tooltip-content">
            <h3>{t('Gamification')}</h3>
            <p>
                {isEducation()
                    ? t(
                          'Arrange a competition among students! Turn on scoring, set the number of attempts and display the top winners.',
                      )
                    : t(
                          'Arrange a real competition! Turn on scoring, set the number of attempts and display the top winners.',
                      )}
            </p>
            <img src={gamificationDemoImage} alt="" width={180} height={180} />
            <p>
                {isEducation()
                    ? t('Gamification is available for the Intermediate and Advanced plans.')
                    : t('Gamification is available for the PRO+ plan and above, as well as for one-time projects.')}
            </p>
        </div>
    )
}

export default DemoContent
