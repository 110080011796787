import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { IconArrowDown } from '../svg'

export default function Dropdown({ active, list, isError, view, onChange = v => console.log(v) }) {
    const [isOpen, setIsOpen] = useState(false)

    const openDropdown = () => setIsOpen(true)
    const closeDropdown = () => setIsOpen(false)

    const dropdownClassName = `dropdown${isOpen ? ' is-open' : ''}${view ? ' ' + view : ''}${
        isError ? ' is-error' : ''
    }`
    const onClickDropdown = isOpen ? closeDropdown : openDropdown
    return (
        <div className={dropdownClassName} onClick={onClickDropdown}>
            <OutsideClickHandler onOutsideClick={closeDropdown}>
                <span className={`${active?.placeholder ? 'is-placeholder' : ''}`}>{active?.name}</span>
                <IconArrowDown />
                <ul className="dropdown-list">
                    {list.map(el => {
                        const onItemClick = () => {
                            onChange(el)
                        }
                        return (
                            <li key={el.value} onClick={onItemClick}>
                                {el.name}
                            </li>
                        )
                    })}
                </ul>
            </OutsideClickHandler>
        </div>
    )
}
