import i18n from 'i18n'

import { DEFAULT_IMAGE_BG_BIG_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'

import { TABS, MIN_ITEMS_COUNT } from './constants'

export class Item {
    id // <String> id
    question // <String>
    answer // <String>
    startX // <Number>
    startY // <Number>
    orientation // <String>
    isIncluded // <Boolean>
    position // <Number>

    constructor({
        question = '',
        answer = '',
        startX = null,
        startY = null,
        orientation = null,
        isIncluded = false,
        position = null,
    } = {}) {
        this.id = getUniqueId()
        this.question = question
        this.answer = answer
        this.startX = startX
        this.startY = startY
        this.orientation = orientation
        this.isIncluded = isIncluded
        this.position = position
    }

    static get id() {
        return 'id'
    }

    static get question() {
        return 'question'
    }

    static get answer() {
        return 'answer'
    }

    static get startX() {
        return 'startX'
    }

    static get startY() {
        return 'startY'
    }

    static get orientation() {
        return 'orientation'
    }

    static get isIncluded() {
        return 'isIncluded'
    }

    static get position() {
        return 'position'
    }
}

export class Final {
    header // <String> text
    description // <String> result description
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer

    constructor({
        header = i18n.t('Congratulations!'),
        description = '',
        image = DEFAULT_IMAGE_BG_BIG_URL,
        imageDisclaimer = '',
    } = {}) {
        this.header = header
        this.description = description
        this.image = image
        this.imageDisclaimer = imageDisclaimer
    }

    static get header() {
        return 'header'
    }

    static get description() {
        return 'description'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }
}

export const BLOCK_KEYS = {
    ITEMS: 'items',
    FINAL: 'final',
}

export const BLOCK_KEYS_BY_TABS = {
    [TABS.ITEMS]: BLOCK_KEYS.ITEMS,
    [TABS.FINAL]: BLOCK_KEYS.FINAL,
}

export function createCrosswordBlock() {
    return {
        [BLOCK_KEYS.ITEMS]: [...Array(MIN_ITEMS_COUNT).keys()].map(() => new Item()),
        [BLOCK_KEYS.FINAL]: new Final(),
    }
}
