import React from 'react'
import ReactCodeInput from 'react-code-input'
import classNames from 'classnames'

import styles from './CodeInput.module.scss'

const CodeInput = ({ label = '', value, onUpdate, errorMessages = [], isDisabled = false, fieldsCount = 6 }) => (
    <div className={styles.codeInput}>
        {label.length && (
            <p className={styles.label} title={label}>
                {label}
            </p>
        )}
        <div className={styles.inputContainer}>
            <ReactCodeInput
                name="code"
                inputMode="numeric"
                disabled={isDisabled}
                className={classNames(styles.inputBlock, { [styles.inputBlockError]: !!errorMessages.length })}
                inputStyle={{
                    width: `calc(${100 / fieldsCount}%)`,
                }}
                value={value}
                onChange={v => onUpdate(v)}
                type="text"
                fields={fieldsCount}
            />
        </div>
        {!!errorMessages.length && <p className={styles.error}>{errorMessages[0]}</p>}
    </div>
)

export default CodeInput
