import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { MAX_ITEMS_COUNT } from '../../../../constants'

import crossIcon from './i/cross.svg'

import styles from './AddButton.module.scss'

const AddButton = ({ itemsCount, onAdd, className }) => {
    const { t } = useTranslation()

    if (itemsCount >= MAX_ITEMS_COUNT) return null

    return (
        <div className={classNames(styles.addButtonContainer, { [className]: !!className })}>
            <div className={styles.addButton} onClick={onAdd}>
                <img src={crossIcon} alt="icon" />
                {t('Add word')}
            </div>
        </div>
    )
}

export default AddButton
