import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'

import Select from 'components/Form/Select/Select'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import HiddenGroup from 'components/Form/HiddenGroup/HiddenGroup'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import ImageSelector from 'components/Form/ImageSelector/ImageSelector'

import TextInputWithPageSelection from '../../../../../../../../../../components/TextInputWithPageSelection/TextInputWithPageSelection'
import ListActionBar from '../../../../../../../../Controls/ListActionBar/ListActionBar'

import { SECTION_TYPES } from '../../../../../../constants'

import { Section as SectionModel } from '../../../../../../wheelSpinService'

import { SECTION_WIN_PROBABILITY_DATA } from './constants'
import { getProbabilityOfWinningPercentage } from './utils'

import styles from './Section.module.scss'

const Section = ({
    errors,
    sectionsCount,
    sectionType,
    section,
    handleSectionVisible,
    index,
    onUpdateSection,
    onUpdateSectionsPosition,
    probabilityOfWinningFactorSum,
}) => {
    const { t } = useTranslation()

    const [probabilityOfWinning, setProbabilityOfWinning] = useState(null)
    const [probabilityOfWinningOptions, setProbabilityOfWinningOptions] = useState([])

    useEffect(() => {
        const selectedSectionData = SECTION_WIN_PROBABILITY_DATA[section[SectionModel.probabilityOfWinning]]
        setProbabilityOfWinning({
            value: selectedSectionData.value,
            label: selectedSectionData.getLabel(
                getProbabilityOfWinningPercentage(probabilityOfWinningFactorSum, selectedSectionData.factor),
            ),
        })
        setProbabilityOfWinningOptions(
            Object.values(SECTION_WIN_PROBABILITY_DATA).map(section => ({
                value: section.value,
                label: section.getLabel(
                    getProbabilityOfWinningPercentage(
                        probabilityOfWinningFactorSum - selectedSectionData.factor + section.factor,
                        section.factor,
                    ),
                ),
            })),
        )
    }, [probabilityOfWinningFactorSum])

    return (
        <InView
            className={styles.section}
            as="li"
            key={section.id}
            onChange={handleSectionVisible}
            id={`item_${section.id}`}
            threshold={0.1}
        >
            <div className={styles.sectionHead}>
                <h2 className={styles.sectionTitle}>
                    {t('Sector')} {index + 1}
                </h2>
                <ListActionBar
                    isDisabledAdd
                    isDisabledRemove
                    index={index}
                    length={sectionsCount}
                    onChangePosition={onUpdateSectionsPosition}
                />
            </div>
            <div className={styles.content}>
                {sectionType === SECTION_TYPES.IMAGE && (
                    <ImageSelector
                        imageUrl={section[SectionModel.imageUrl]}
                        onUpdate={v => onUpdateSection({ [SectionModel.imageUrl]: v })}
                    />
                )}
                <div className={styles.mainContent}>
                    <div className={styles.flexBlock}>
                        {sectionType === SECTION_TYPES.TEXT && (
                            <TextInput
                                className={styles.sectionNameControl}
                                label={t('Sector name on the wheel')}
                                errorMessages={errors?.[SectionModel.text]}
                                isRequired
                                value={section[SectionModel.text]}
                                onUpdate={v => onUpdateSection({ [SectionModel.text]: v })}
                            />
                        )}
                        <Select
                            className={styles.sectionWinProbabilityControl}
                            label={t('Probability of winning')}
                            errorMessages={errors?.[SectionModel.probabilityOfWinning]}
                            value={probabilityOfWinning}
                            onUpdate={option => onUpdateSection({ [SectionModel.probabilityOfWinning]: option.value })}
                            options={probabilityOfWinningOptions}
                        />
                    </div>
                    <br />
                    <TextInput
                        label={t('Result page header')}
                        isRequired
                        errorMessages={errors?.[SectionModel.resultHeader]}
                        value={section[SectionModel.resultHeader]}
                        onUpdate={v => onUpdateSection({ [SectionModel.resultHeader]: v })}
                    />
                    <br />
                    <TextArea
                        label={t('Result page description')}
                        errorMessages={errors?.[SectionModel.resultDescription]}
                        value={section[SectionModel.resultDescription]}
                        onUpdate={v => onUpdateSection({ [SectionModel.resultDescription]: v })}
                    />
                    <br />
                    <UploadImage
                        label={t('Result image')}
                        errorMessages={errors?.[SectionModel.resultImageUrl]}
                        value={section[SectionModel.resultImageUrl]}
                        onUpdate={v => onUpdateSection({ [SectionModel.resultImageUrl]: v })}
                    />
                    <HiddenGroup>
                        {!!section[SectionModel.resultImageUrl] && (
                            <TextInput
                                label={t('Image disclaimer')}
                                isOptional
                                errorMessages={errors?.[SectionModel.resultImageDisclaimer]}
                                value={section[SectionModel.resultImageDisclaimer]}
                                onUpdate={v => onUpdateSection({ [SectionModel.resultImageDisclaimer]: v })}
                            />
                        )}
                        <ElementsGroup isEquallyWidth>
                            <TextInput
                                label={t('Button text')}
                                errorMessages={errors?.[SectionModel.resultButtonText]}
                                value={section[SectionModel.resultButtonText]}
                                onUpdate={v => onUpdateSection({ [SectionModel.resultButtonText]: v })}
                            />
                            <TextInputWithPageSelection
                                errorMessages={errors?.[SectionModel.resultButtonLink]}
                                value={section[SectionModel.resultButtonLink]}
                                onUpdate={v => onUpdateSection({ [SectionModel.resultButtonLink]: v })}
                            />
                        </ElementsGroup>
                    </HiddenGroup>
                </div>
            </div>
        </InView>
    )
}

export default Section
