import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import { IconPdf } from 'svg'

import { isEducation } from 'common/utils/permissions'

import styles from './PrintBody.module.scss'

const PrintBody = ({ onGetProjectPdf }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.printBody}>
            <div className={styles.text}>
                {isEducation() ? (
                    <Trans
                        i18nKey="Download your project as a PDF <span>with interactive elements</span> and print for use in class or for sale"
                        components={{
                            span: <span className={styles.coloredText} />,
                        }}
                    />
                ) : (
                    <Trans
                        i18nKey="Download your project as a PDF <span>with interactive elements</span>"
                        components={{
                            span: <span className={styles.coloredText} />,
                        }}
                    />
                )}
            </div>
            <button className={classNames('button', styles.button)} data-size="lg" onClick={() => onGetProjectPdf()}>
                <span className={styles.buttonIcon}>
                    <IconPdf />
                </span>
                {t('Convert to PDF')}
            </button>
        </div>
    )
}

export default PrintBody
