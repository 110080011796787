import React, { useState, useEffect } from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import { BLOCK_KEYS, Playground as PlaygroundModel, Section as SectionModel } from '../../../../wheelSpinService'
import { SECTION_WIN_PROBABILITY_FACTORS } from '../../../../constants'

import Section from './components/Section/Section'

import styles from './SectionsList.module.scss'

const SectionsList = ({
    errors,
    structure,
    onSetFocusToSection,
    scrollableContentNodeRef,
    onUpdateSection,
    onUpdateSectionsPosition,
}) => {
    const [probabilityOfWinningFactorSum, setProbabilityOfWinningFactorSum] = useState(0)

    useEffect(() => {
        setProbabilityOfWinningFactorSum(
            structure[BLOCK_KEYS.SECTIONS].reduce(
                (acc, v) => (acc += SECTION_WIN_PROBABILITY_FACTORS[v[SectionModel.probabilityOfWinning]]),
                0,
            ),
        )
    }, [structure[BLOCK_KEYS.SECTIONS]])

    const handleSectionVisible = (inView, entry) => {
        if (inView) onSetFocusToSection(entry.target)
    }

    return (
        <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
            <ul className={styles.sectionsList}>
                {structure[BLOCK_KEYS.SECTIONS].map((section, index) => (
                    <Section
                        key={section.id}
                        errors={errors?.get(section.id)}
                        probabilityOfWinningFactorSum={probabilityOfWinningFactorSum}
                        sectionType={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionType]}
                        sectionsCount={structure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.sectionsCount]}
                        handleSectionVisible={handleSectionVisible}
                        section={section}
                        index={index}
                        onUpdateSection={data => onUpdateSection(index, section.id, data)}
                        onUpdateSectionsPosition={onUpdateSectionsPosition}
                    />
                ))}
            </ul>
        </ScrollableBlock>
    )
}

export default SectionsList
