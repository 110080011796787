import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from 'components/Form/Image'

import invertColor from '../../../../../../util/invertColor'

import { getAgreementData } from './utils'

import './Preview.scss'

const AgreementBlock = ({ url, text }) => (
    <a rel="noopener noreferrer" target="_blank" href={url}>
        {text}
    </a>
)

const Preview = ({
    backgroundColor,
    color,
    fontFamily,
    fields = [],
    logoImage,
    headerText = '',
    descriptionText = '',
    buttonText = '',
    linkPrivacyPolicyLink = '',
    additionalNotes = '',
}) => {
    const { t } = useTranslation()

    const getAgreementBlock = () => {
        const linksArray = linkPrivacyPolicyLink.split('|').filter(link => !!link)

        if (linksArray.length === 1) {
            const agreementData = getAgreementData(linksArray[0], t('Privacy Policy'))
            return <AgreementBlock url={agreementData.url} text={agreementData.text} />
        }

        return linksArray.map((link, index) => {
            const agreementData = getAgreementData(link)

            return (
                <>
                    <AgreementBlock key={index} url={agreementData.url} text={t(agreementData.text)} />
                    {index !== linksArray.length - 1 ? ', ' : null}
                </>
            )
        })
    }

    return (
        <div className="lead-form-form-review" style={{ backgroundColor }}>
            {!!logoImage ? <Image className="logo-image" src={logoImage} alt="logo" /> : null}
            {!!headerText.length && (
                <div className="header" style={{ fontFamily: `"${fontFamily || 'Roboto'}", sans-serif`, color }}>
                    {headerText}
                </div>
            )}
            {!!descriptionText.length && (
                <div className="description" style={{ fontFamily: `"${fontFamily || 'Roboto'}", sans-serif`, color }}>
                    {descriptionText}
                </div>
            )}
            <ul className="fields">
                {fields.map(field => (
                    <li key={field.key} className={field.type}>
                        <p style={{ color }}>{t(field.label)}</p>
                        <div />
                    </li>
                ))}
            </ul>
            {!!linkPrivacyPolicyLink.length && (
                <div className="privacy-wrapper">
                    <div className="privacy">
                        <span />
                        &nbsp;<div style={{ display: 'inline', color }}>{t('Agree with')}</div>&nbsp;
                        {getAgreementBlock()}
                    </div>
                </div>
            )}
            {!!additionalNotes && <div className="additional-notes">{additionalNotes}</div>}
            {!!buttonText.length && (
                <div className="button-wrapper">
                    <button
                        style={{
                            backgroundColor: color,
                            color: backgroundColor,
                            fontFamily: `"${fontFamily || 'Roboto'}", sans-serif`,
                        }}
                    >
                        {buttonText}
                    </button>
                </div>
            )}
        </div>
    )
}

export default Preview
