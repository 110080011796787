import React from 'react'

import UploadImage from '../UploadImage/UploadImage'

import AddButton from './components/AddButton/AddButton'

import { MAX_IMAGE_COUNT } from './constants'

import arrowUpIcon from './i/arrowUp.svg'
import arrowDownIcon from './i/arrowDown.svg'
import deleteIcon from './i/delete.svg'

import styles from './ImagesGallery.module.scss'

const ImagesGallery = ({ projectId, label, images = [], onAddImage, onChange, onChangeSort, onRemove }) => (
    <div className={styles.imagesGallery}>
        <div className={styles.label}>{label}</div>
        <ul className={styles.list}>
            {images.map((image, i) => (
                <li key={image} className={styles.item}>
                    <UploadImage
                        projectId={projectId}
                        isGalleryImage
                        url={image}
                        onChange={url => onChange(image, url)}
                        className={styles.uploadImage}
                        isCanDelete={false}
                    />
                    <ul className={styles.actions}>
                        {i !== 0 && (
                            <li className={styles.action} onClick={() => onChangeSort(i, i - 1)}>
                                <img src={arrowUpIcon} alt="" />
                            </li>
                        )}
                        {i !== images.length - 1 && (
                            <li className={styles.action} onClick={() => onChangeSort(i, i + 1)}>
                                <img src={arrowDownIcon} alt="" />
                            </li>
                        )}
                        <li className={styles.action} onClick={() => onRemove(image)}>
                            <img src={deleteIcon} alt="" />
                        </li>
                    </ul>
                </li>
            ))}
        </ul>
        {images.length < MAX_IMAGE_COUNT && <AddButton onAdd={url => onAddImage(url)} projectId={projectId} />}
    </div>
)

export default ImagesGallery
