import React from 'react'

import List from './List/List'

import styles from './Column.module.scss'

const Column = ({ title, fontFamily, items }) => (
    <li className={styles.column}>
        <h3 className={styles.title} style={{ fontFamily }}>
            {title}
        </h3>
        <List items={items} fontFamily={fontFamily} />
    </li>
)

export default Column
