import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

const GoogleAnalyticsBlock = ({ settings = { id: null }, isChecked, updateIntegration }) => {
    const { t } = useTranslation()
    const [id, setId] = useState(settings.id)
    const [error, setError] = useState('')

    const changeValue = evt => {
        setId(evt.target.value)
        setError('')
    }

    const onSave = () => {
        if (/^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/i.test(id.toString())) {
            updateIntegration(true, { id: id })
        } else {
            setError(t('Invalid format'))
        }
    }

    const onDisable = () => {
        updateIntegration(false, { id: null })
    }

    const isDisabledSave = !id || isEqual(settings.id, id)

    return (
        <div className="google-analytics-block">
            <div className="content__title">{t('Google Analytics')}</div>

            <div className={`input-box ${error.length ? 'is-error' : ''}`}>
                <div className="title">{t('ID')}</div>
                <input
                    autoComplete="off"
                    type="text"
                    value={id}
                    placeholder="UA-111111111-1"
                    onChange={evt => changeValue(evt)}
                />
                <p className="msg msg--error">{error}</p>
            </div>

            <div className="content__sub-title">{t('How to set up a quiz funnel in Google Analytics:')}</div>
            <ul>
                <li>
                    1.{' '}
                    <Trans
                        i18nKey="Follow this <0>link</0>"
                        components={[
                            <a
                                href="https://analytics.google.com/analytics/web/provision/#/provision"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ,
                            </a>,
                        ]}
                    />
                </li>
                <li>2. {t('Select a project and click Create. A composite goal will be created automatically.')}</li>
                <li>3. {t('You can edit the target to fit your needs, remove unnecessary steps.')}</li>
                <li>4. {t('Publish your project after the change')}</li>
            </ul>

            <div className="btns">
                <button
                    className="button"
                    data-size="md"
                    disabled={isDisabledSave}
                    onClick={onSave}
                    data-variant="primary"
                >
                    {t('Save')}
                </button>
                {isChecked ? (
                    <button className="button" data-size="md" data-variant="primary-danger" onClick={onDisable}>
                        {t('Disable')}
                    </button>
                ) : null}
            </div>
        </div>
    )
}

export default GoogleAnalyticsBlock
