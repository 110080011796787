import React from 'react'
import classNames from 'classnames'

import styles from './Tags.module.scss'

const Tags = ({ title, selected, maxLength, list, labelClassName, onSelect }) => {
    if (!list || !list.length) return null
    return (
        <div className={styles.tags}>
            {!!title && <div className={classNames(styles.title, { [labelClassName]: !!labelClassName })}>{title}</div>}
            <ul className={styles.tagList}>
                {list.map(el => (
                    <li
                        key={el.value}
                        onClick={() => onSelect(el)}
                        className={classNames(styles.tag, {
                            [styles.isSelectedTag]: selected.includes(el.value),
                            [styles.isDisabledTag]: !selected.includes(el.value) && maxLength <= selected.length,
                        })}
                    >
                        {el.label}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Tags
