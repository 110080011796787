import React from 'react'
import './AddLIstItemButton.scss'
import { withTranslation } from 'react-i18next'

const AddListItemButton = ({ title, length, onClick, i18n }) => {
    return (
        <div
            className={`add-list-item-button with-icon${!length ? ' no-offset' : ''}`}
            data-icon="add"
            onClick={onClick}
            data-test="add-new-item"
        >
            {title || i18n.t('Add new')}
        </div>
    )
}
export default withTranslation('translations')(AddListItemButton)
