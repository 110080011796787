import CURRENCIES from 'common/constants/currencies'

export const getFormattedPrice = (price, currency) => {
    if (currency === CURRENCIES.USD) return `${getCurrencySymbol(CURRENCIES.USD)}${price.toFixed(2)}`
    else if (currency === CURRENCIES.RUB) return `${price.toFixed(2)} ${getCurrencySymbol(CURRENCIES.RUB)}`
    return `${price.toFixed(2)} ${currency}`
}

export const getCurrencySymbol = currency => {
    if (currency === CURRENCIES.USD) return '$'
    if (currency === CURRENCIES.RUB) return '₽'
    return null
}
