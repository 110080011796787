import React, { useEffect, useState } from 'react'

import { API__ANALYTICS } from 'api'

import ComponentsList from '../../../../components/ComponentsList/ComponentsList'

import LeadFormTable from '../../components/LeadFormTable/LeadFormTable'
import QuizStatistics from '../../components/QuizStatistics/QuizStatistics'
import BlockSummary from '../../components/BlockSummary/BlockSummary'

const TriviaQuiz = ({ projectId, blockProjection, dateRange, periodInterval }) => {
    const [statistic, setStatistic] = useState(null)
    const [isReady, setIsReady] = useState(false)

    const fetchData = () => {
        API__ANALYTICS.GET_QUIZ_STATISTICS({
            projectId,
            blockId: blockProjection.blockId,
            pagination: {
                from: dateRange.from,
                to: dateRange.to,
            },
        }).then(data => {
            setStatistic(data)
            setIsReady(true)
        })
    }

    useEffect(() => {
        if (dateRange) fetchData()
    }, [dateRange, blockProjection])

    return (
        <ComponentsList>
            {isReady && <BlockSummary statistic={statistic} />}
            <LeadFormTable
                projectId={projectId}
                blockProjection={blockProjection}
                dateRange={dateRange}
                periodInterval={periodInterval}
            />
            {isReady && <QuizStatistics statistic={statistic} blockProjection={blockProjection} />}
        </ComponentsList>
    )
}

export default TriviaQuiz
