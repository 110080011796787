import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ContentTitle.module.scss'

const ContentTitle = ({ step, STEPS }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.title}>
            {!step && t('Customize your product description, upload a preview, and select terms of use.')}
            {STEPS && (
                <>
                    {step === STEPS.ACTIVATION_PUBLIC_PAGE &&
                        t(
                            'Public page allows you to share your content with Interacty community.\nProvided information will be visible to other users.',
                        )}
                    {step === STEPS.SET_UP_PRODUCT &&
                        t('Customize your product description, upload a preview, and select terms of use.')}
                </>
            )}
        </div>
    )
}

export default ContentTitle
