import React, { useState, useEffect, useRef, useCallback } from 'react'
import { ChromePicker } from 'react-color'

import './ColorPicker.scss'

const ColorPicker = ({ value, onUpdate }) => {
    const pickerRef = useRef()

    const [currentValue, setCurrentValue] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        setCurrentValue(value)
        setInputValue(value || '')
    }, [value])

    const openPicker = () => {
        setIsShow(true)
    }

    const onDocClick = useCallback(event => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setIsShow(false)
        }
    }, [])

    useEffect(() => {
        if (isShow) document.addEventListener('click', onDocClick)
        return () => {
            document.removeEventListener('click', onDocClick)
        }
    }, [onDocClick, isShow])

    const handleChangeComplete = color => {
        setCurrentValue(color.hex)
        onUpdate(color.hex)
    }

    const getHex = value => {
        const strVal = String(value)
        const hex = strVal[0] === '#' ? strVal : `#${strVal}`
        if (hex.match(/^#([0-9a-f]{3}){1,2}$/i)) return hex.toLowerCase()
        else return null
    }

    const onInputChange = evt => {
        setInputValue(evt.target.value)
        const hex = getHex(evt.target.value)
        if (hex) handleChangeComplete({ hex })
    }

    return (
        <div className="color-picker">
            <div className="color-picker__edit-box">
                <div
                    className="color-picker__edit-box-preview"
                    style={{ backgroundColor: currentValue }}
                    onClick={() => openPicker()}
                />
                <div className="color-picker__edit-box-input-container" onClick={() => openPicker()}>
                    <input value={inputValue} spellCheck="false" onChange={onInputChange} />
                </div>
            </div>
            {isShow && (
                <div ref={pickerRef} className="color-picker__select-box">
                    <ChromePicker
                        width="auto"
                        color={currentValue}
                        onChangeComplete={handleChangeComplete}
                        disableAlpha={true}
                    />
                </div>
            )}
        </div>
    )
}

export default ColorPicker
