import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import './Sidebar.scss'

const List = ({ items, className, activePathname }) => (
    <ul className={className}>
        {items.map(item => (
            <Item key={item.path} path={item.path} label={item.label} activePathname={activePathname} />
        ))}
    </ul>
)
const Item = ({ path, label, activePathname }) => (
    <li data-test="nav-stats-item">
        <Link className={path === activePathname ? 'is-active' : ''} to={path}>
            {label}
        </Link>
    </li>
)

const Sidebar = ({ menuItems, activePathname }) => {
    const { t } = useTranslation()
    return (
        <aside className="project-analytics-sidebar">
            <nav>
                <List items={menuItems.common} className="common-items" activePathname={activePathname} />
                {menuItems.blocks.length ? (
                    <>
                        <h3 className="blocks-items-title">{t('Interactive blocks')}</h3>
                        <List items={menuItems.blocks} className="blocks-items" activePathname={activePathname} />
                    </>
                ) : null}
            </nav>
        </aside>
    )
}

export default Sidebar
