import React from 'react'

import Item from './components/Item/Item'

import styles from './List.module.scss'

const List = ({ items, fontFamily }) => (
    <ul className={styles.list}>
        {items
            .sort((a, b) => a.position - b.position)
            .map(item => (
                <Item key={item.id} fontFamily={fontFamily} item={item} />
            ))}
    </ul>
)

export default List
