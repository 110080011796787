import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './Item.module.scss'

const Item = ({ icon, onClick, text }) => {
    const [isHovered, setIsHovered] = useState(false)

    const Icon = icon

    return (
        <div
            className={classNames(styles.item, { [styles.isHoveredItem]: isHovered })}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={styles.itemIcon}>
                <Icon color={isHovered ? '#2990fb' : '#3c3c3c'} />
            </div>
            <span className={styles.itemText}>{text}</span>
        </div>
    )
}

export default Item
