import i18n from 'i18n'

import playgroundIcon from 'components/Modal/components/Body/components/SideNav/i/slidingPuzzle.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const TABS = {
    PLAYGROUND: 1,
    FINAL: 2,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Playground'),
        icon: playgroundIcon,
    },
    {
        id: 2,
        label: i18n.t('Final screen'),
        icon: resultsIcon,
    },
]

export const GAME_TYPES = {
    NUMBERS: 'NUMBERS',
    IMAGE: 'IMAGE',
}

export const GAME_TYPES_OPTIONS = [
    {
        value: GAME_TYPES.IMAGE,
        label: i18n.t('Image'),
    },
    {
        value: GAME_TYPES.NUMBERS,
        label: i18n.t('Numbers'),
    },
]

export const AVAILABLE_TILES_COUNT = [4, 9, 16, 25, 36]
