import mixpanel from 'mixpanel-browser'
import { isLocalhost } from '../utils/environment'

const isEnabled = !!process.env.REACT_APP_MIX_PANEL_TOKEN

function init() {
    if (!isEnabled) return
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: isLocalhost })
}

function identify(userId, email = '') {
    if (!isEnabled) return
    mixpanel.identify(userId)
    mixpanel.people.set_once('First Login Date', new Date())
    if (email) mixpanel.people.set({ Email: email })
}

function opt_out_tracking() {
    if (!isEnabled) return
    mixpanel.opt_out_tracking()
}

function register(payload) {
    if (!isEnabled) return
    mixpanel.register(payload)
}

function track(eventName, payload) {
    if (!isEnabled) return
    mixpanel.track(eventName, payload)
}

export default {
    init,
    identify,
    register,
    track,
    opt_out_tracking,
}
