import { TEMPLATE_TAGS } from 'common/constants/templates'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { API__TEMPLATES } from 'api'
import { EditPencilIcon, OpenEyeIcon } from 'svg/index'
import { getFullUrlToSsr } from 'utils/router'
import Image from 'components/Form/Image'

import { ROLES } from 'common/constants/user'

import useUser from 'hooks/useUser'

import './TemplateCardsBlock.scss'

const TemplateCardsBlock = ({ onCreateProject }) => {
    const { t } = useTranslation()
    const [templates, setTemplates] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { role } = useUser()

    const getTemplates = tag => {
        setIsLoading(true)
        API__TEMPLATES.GET_TEMPLATES({
            sort: 'orderNumber,asc',
            page: 0,
            size: 7,
            tags: tag,
        })
            .then(response => setTemplates(response.content))
            .catch(err => {
                console.error(err)
                console.error(err.response?.data?.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!role) return
        if (role === ROLES.EDUCATION) {
            getTemplates(TEMPLATE_TAGS.education)
        } else {
            getTemplates(TEMPLATE_TAGS.business)
        }
    }, [role])

    if (isLoading) {
        return (
            <div className="templates-cards-block is-loading">
                <h4 className="templates-cards-block__title" />
                <div className="templates-cards-block__templates">
                    {new Array(4).fill(0).map((_val, index) => (
                        <div className="templates-cards-block__templates-template" key={index}>
                            <div className="templates-cards-block__templates-template-image" />
                            <p className="templates-cards-block__templates-template-title" />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        !!templates.length && (
            <div className="templates-cards-block">
                <div className="templates-cards-block__wrapper">
                    <h4 className="templates-cards-block__title">{t('Templates for you')}</h4>
                    <div className="templates-cards-block__templates">
                        {templates.map(template => (
                            <div className="templates-cards-block__template" key={template.id}>
                                <div className="templates-cards-block__template-image-wrapper">
                                    <div className="templates-cards-block__template-buttons">
                                        <a
                                            href={getFullUrlToSsr(`/templates/${template.id}`)}
                                            className="templates-cards-block__template-button"
                                            data-variant="secondary"
                                            target="_blank"
                                        >
                                            <OpenEyeIcon color="#979797" />
                                            {t('Preview')}
                                        </a>
                                        <button
                                            className="templates-cards-block__template-button"
                                            data-variant="primary"
                                            onClick={() => onCreateProject(template.id)}
                                        >
                                            <EditPencilIcon color="#fff" />
                                            {t('Edit')}
                                        </button>
                                    </div>
                                    <Image
                                        className="templates-cards-block__template-image"
                                        width={208}
                                        height={156}
                                        src={template.imageUrl}
                                    />
                                </div>
                                <p className="templates-cards-block__template-title">{template.name}</p>
                            </div>
                        ))}
                        <a
                            href={getFullUrlToSsr('/template-gallery')}
                            className="templates-cards-block__see-all-templates"
                        >
                            {t('See all \ntemplates')}
                            <span className="templates-cards-block__see-all-templates-button" />
                        </a>
                    </div>
                </div>
            </div>
        )
    )
}

export default TemplateCardsBlock
