import React, { useState, createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LoaderIcon from '../../../../LoaderIcon/LoaderIcon'
import { getFileNameAndExt } from '../../../utils/utils'

import { IconSelect, IconUnselect, IconDetails, IconTrash, IconZoom, IconPlay, IconPause } from 'svg'

import Preview from './Preview'

import { TYPES } from '../MediaGrid'

import './Item.scss'

const Item = ({
    name,
    author,
    url,
    previewUrl,
    onDeleteItem,
    onSetSelectItem,
    isSelected,
    isShowDetails,
    isAudioPreview,
    onPlayAudio,
    playedUrl,
    userNickName,
    userProfileUrl,
    type,
}) => {
    const { t } = useTranslation()
    const [timeoutId, setTimeoutId] = useState()
    const [isAudioPlay, setIsAudioPlay] = useState(() => {
        return playedUrl === url
    })
    const [isLoad, setIsLoad] = useState(false)
    const [previewPopupVisible, setPreviewPopupVisible] = useState(false)
    const [detailsPopupVisible, setDetailsPopupVisible] = useState(false)

    const [shortName] = useState(() => {
        if (name) {
            const { filename, ext } = getFileNameAndExt(name)
            return filename.length > 35 ? `${filename.substring(0, 35)}...${ext}` : name
        }
        return null
    })
    const [fileType] = useState(() => {
        if (name) {
            const { ext } = getFileNameAndExt(name)
            return ext
        }
        return null
    })
    const targetRef = createRef()

    useEffect(() => {
        if (playedUrl === url) {
            setIsAudioPlay(true)
        } else {
            setIsAudioPlay(false)
        }
    }, [playedUrl])

    const deleteItem = () => {
        setIsLoad(true)
        onDeleteItem()
    }

    const onShowPreview = () => {
        const _timeoutId = setTimeout(() => {
            setPreviewPopupVisible(true)
        }, 250)
        setTimeoutId(_timeoutId)
    }

    const onHidePreview = () => {
        clearTimeout(timeoutId)
        setPreviewPopupVisible(false)
    }

    return (
        <div ref={targetRef} className={`media-item ${isLoad ? 'disabled' : ''}`}>
            <div
                className={`media-item__box ${isSelected ? 'media-item__box_active' : ''}`}
                onClick={onSetSelectItem}
                onMouseLeave={() => setDetailsPopupVisible(false)}
            >
                <div className="media-item__actions">
                    <div className="media-item__actions_left">
                        <div className="media-item__action media-item__action_select">
                            {isSelected ? <IconSelect /> : <IconUnselect />}
                        </div>
                    </div>
                    <div className="media-item__actions_right" onClick={ev => ev.stopPropagation()}>
                        {isAudioPreview ? (
                            <div
                                className="media-item__action media-item__action_audio_preview"
                                onClick={() => onPlayAudio(url)}
                            >
                                {isAudioPlay ? <IconPause /> : <IconPlay />}
                            </div>
                        ) : (
                            <div
                                className="media-item__action media-item__action_preview"
                                onMouseEnter={onShowPreview}
                                onMouseLeave={onHidePreview}
                            >
                                <IconZoom />
                            </div>
                        )}
                        {isShowDetails && (
                            <div
                                className="media-item__action media-item__action_details"
                                onClick={() => setDetailsPopupVisible(!detailsPopupVisible)}
                            >
                                <IconDetails />
                                {detailsPopupVisible && (
                                    <div className="media-item__details-box" onClick={ev => ev.stopPropagation()}>
                                        <div className="media-item__details">
                                            <div onClick={deleteItem}>
                                                <IconTrash />
                                                <span>{t('Delete')}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="media-item__image-box">
                    {isAudioPreview ? (
                        <div className="media-item__file-icon" data-type={fileType} />
                    ) : (
                        <img className="media-item__image" src={url} alt="image" />
                    )}
                    {type === TYPES.PIXABAY && (
                        <p className="media-item__author">
                            <span>
                                by&nbsp;
                                <a href={userProfileUrl} target="_blank">
                                    {userNickName}
                                </a>
                            </span>
                        </p>
                    )}
                    {type === TYPES.UNSPLASH && author && (
                        <p className="media-item__author">
                            <span>
                                by&nbsp;
                                <a href={`https://unsplash.com/@${author.username}`} target="_blank">
                                    {author.name}
                                </a>
                            </span>
                        </p>
                    )}
                </div>
                {previewPopupVisible && (
                    <Preview
                        url={previewUrl}
                        targetRef={targetRef}
                        author={author}
                        note={
                            type === TYPES.PIXABAY
                                ? t('Photo by {{name}} on Pixabay', { name: userNickName })
                                : author
                                ? t('Photo by {{name}} on Unsplash', { name: author.name })
                                : null
                        }
                    />
                )}
                {isLoad && <LoaderIcon />}
            </div>
            <p>{shortName}</p>
        </div>
    )
}

export default Item
