import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './HeadMark.module.scss'

const HeadMark = ({ isActive }) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.headMark, { [styles.isActiveHeadMark]: isActive })}>
            {isActive ? t('Live  ') : t('Inactive ')}
        </div>
    )
}

export default HeadMark
