import React from 'react'

export default ({ color = 'transparent' }) => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H7C7.27614 0.5 7.5 0.723858 7.5 1V5.5H1C0.723858 5.5 0.5 5.27614 0.5 5V1Z"
            stroke={color}
        />
        <path
            d="M0.5 9C0.5 8.72386 0.723858 8.5 1 8.5H7.5V13C7.5 13.2761 7.27614 13.5 7 13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V9Z"
            stroke={color}
        />
        <path
            d="M10.5 1C10.5 0.723858 10.7239 0.5 11 0.5H17C17.2761 0.5 17.5 0.723858 17.5 1V5C17.5 5.27614 17.2761 5.5 17 5.5H10.5V1Z"
            stroke={color}
        />
        <path
            d="M10.5 8.5H17C17.2761 8.5 17.5 8.72386 17.5 9V13C17.5 13.2761 17.2761 13.5 17 13.5H11C10.7239 13.5 10.5 13.2761 10.5 13V8.5Z"
            stroke={color}
        />
    </svg>
)
