import React, { useEffect, useRef, useState } from 'react'
import { lock, clearBodyLocks } from 'tua-body-scroll-lock'
import classNames from 'classnames'
import { Trans } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import { useAssetManager } from 'components/AssetManager'
import { ASSET_CONTENT_TYPE, ASSET_TABS } from 'components/AssetManager/utils/constants'

import { getUniqueId } from 'common/utils/getUniqueId'
import BLOCK from 'common/constants/BlockTypes/BlocksEnum'

import i18n from 'i18n'

import { getMultiplayerBlockInPage } from 'utils/multiplayer'

import { INTERACTIVE_BLOCKS, OTHERS_BLOCKS, STATIC_BLOCKS } from '../../schema'

import PanelHeader from '../components/PanelHeader'
import TemplatesPanel from '../Templates/TemplatesPanel'

import { getBlockStyles } from './utils'

import questionIcon from './i/question.svg'

const SINGLE_BLOCKS = [
    {
        label: i18n.t('Static'),
        blocks: STATIC_BLOCKS,
    },
    {
        label: i18n.t('Interactive'),
        blocks: INTERACTIVE_BLOCKS.filter(block => !block.isOnlyMultiplayerBlock),
    },
    {
        label: i18n.t('Others'),
        blocks: OTHERS_BLOCKS,
    },
]

const MULTIPLAYER_BLOCKS = [
    {
        label: i18n.t('Static'),
        blocks: STATIC_BLOCKS,
    },
    {
        onCheckIsHidden: ({ isPageHasMultiplayerBlock }) => !!isPageHasMultiplayerBlock,
        label: i18n.t('Game event'),
        description: (
            <Trans
                i18nKey="Use these blocks for a live game event <icon/>"
                components={{
                    icon: (
                        <img
                            className="description-icon"
                            src={questionIcon}
                            alt=""
                            onClick={() =>
                                window.open('https://help.interacty.me/en/content/online-game-event', '_blank')
                            }
                        />
                    ),
                }}
            />
        ),
        // betaMarkColor: '#785CC7',
        blocks: INTERACTIVE_BLOCKS.filter(block => block.isMultiplayerBlock && !block.isHideFromMultiplayer),
    },
    {
        label: i18n.t('Interactive'),
        description: i18n.t('These blocks do not count in real-time games'),
        blocks: INTERACTIVE_BLOCKS.filter(block => !block.isMultiplayerBlock && !block.isHideFromMultiplayer),
    },
    {
        label: i18n.t('Others'),
        blocks: OTHERS_BLOCKS.filter(block => !block.isHideFromMultiplayer),
    },
]

const BlocksPanel = ({ open, onAdd, insertBeforeId, onClose, selectedPage, isMultiplayerGame }) => {
    const wrapperRef = useRef()
    const panelScrollRef = useRef()
    const { chooseAsset } = useAssetManager(true)

    const getBlocks = () => (isMultiplayerGame ? MULTIPLAYER_BLOCKS : SINGLE_BLOCKS)

    const [templatesState, setTemplatesState] = useState({
        isOpen: false,
        selectedBlockMeta: null,
    })
    const [blocks, setBlocks] = useState(() => getBlocks())
    const [isPageHasMultiplayerBlock, setIsPageHasMultiplayerBlock] = useState(false)

    useEffect(() => {
        if (open) lock(panelScrollRef.current)
        else clearBodyLocks()
    }, [open])

    useEffect(() => {
        setIsPageHasMultiplayerBlock(!!getMultiplayerBlockInPage(selectedPage))
    }, [selectedPage])

    useEffect(() => {
        setBlocks(getBlocks())
    }, [isMultiplayerGame])

    const showTemplates = async blockMeta => {
        setTemplatesState({
            isOpen: true,
            selectedBlockMeta: blockMeta,
        })
    }

    const chooseTemplate = async (blockMeta, blockStructure) => {
        const blockType = blockMeta.t
        if (blockType === BLOCK.image || blockType === BLOCK.logo || blockType === BLOCK.audio) {
            const blockId = getUniqueId()
            const settings =
                blockMeta.contentType === ASSET_CONTENT_TYPE.audio
                    ? { tab: ASSET_TABS.TAB_AUDIO, managerTabs: [ASSET_TABS.TAB_AUDIO, ASSET_TABS.TAB_FREE_AUDIO] }
                    : undefined
            chooseAsset(
                null,
                data =>
                    onAdd(blockType, insertBeforeId, { url: data.src, author: data.author, name: data.name }, blockId),
                settings,
            )
        } else onAdd(blockType, insertBeforeId, blockStructure)

        setTemplatesState({
            isOpen: false,
            selectedBlockMeta: null,
        })
    }

    const chooseAction = async blockMeta => {
        if (blockMeta.isHasTemplates) showTemplates(blockMeta)
        else chooseTemplate(blockMeta, {})
    }

    const onClosePanel = () => {
        setTemplatesState({
            isOpen: false,
            selectedBlockMeta: null,
        })
        onClose()
    }

    return (
        <>
            <div className={`editor-panel editor-panel--blocks${open ? ' is-opened' : ''}`} ref={wrapperRef}>
                <PanelHeader blockName={i18n.t('All blocks')} scrollRef={panelScrollRef} />
                <div className="close" onClick={onClosePanel} />
                <ScrollableBlock className="blocks-content" scrollableNodeRef={panelScrollRef}>
                    <ul className="list">
                        {blocks.map((group, i) => {
                            if (
                                group.hasOwnProperty('onCheckIsHidden') &&
                                group.onCheckIsHidden({ isPageHasMultiplayerBlock })
                            ) {
                                return null
                            }

                            return (
                                <li key={i}>
                                    {group.label && (
                                        <p className="label">
                                            <span className="label-text">{group.label}</span>
                                            {group.betaMarkColor && (
                                                <span
                                                    className="label-mark"
                                                    style={{ backgroundColor: group.betaMarkColor }}
                                                >
                                                    beta
                                                </span>
                                            )}
                                        </p>
                                    )}
                                    {group.description && <p className="description">{group.description}</p>}
                                    {!!group.blocks.length && (
                                        <ul className="inner-list">
                                            {group.blocks.map(block => (
                                                <li
                                                    key={block.t}
                                                    className={classNames({
                                                        'editor-panel--blocks--has-active-icon': block.iconActive,
                                                        'can-collapse': block.isHasTemplates,
                                                    })}
                                                    style={getBlockStyles(block, templatesState)}
                                                    onClick={() => chooseAction(block)}
                                                    data-test={`choose-block-${block.t}`}
                                                >
                                                    {group.betaMarkColor && (
                                                        <div
                                                            className="mark"
                                                            style={{ backgroundColor: group.betaMarkColor }}
                                                        />
                                                    )}
                                                    {block.icon && (
                                                        <div className="img">
                                                            <img src={block.icon} alt="icon" />
                                                        </div>
                                                    )}
                                                    {block.iconActive && (
                                                        <div className="img img--active">
                                                            <img src={block.iconActive} alt="icon" />
                                                        </div>
                                                    )}
                                                    <p>
                                                        {block.label}
                                                        {!!block.hint && <span>{block.hint}</span>}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </ScrollableBlock>

                {open && templatesState.isOpen && (
                    <TemplatesPanel
                        blockType={templatesState.selectedBlockMeta.t}
                        title={templatesState.selectedBlockMeta.templatesTitle}
                        onAdd={blockStruct => chooseTemplate(templatesState.selectedBlockMeta, blockStruct)}
                    />
                )}
            </div>
            {open && <div className="editor-panel-overlay" onClick={onClosePanel} />}
        </>
    )
}

export default BlocksPanel
