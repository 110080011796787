import API from '../api'

export default class Session {
    #session = null

    #isCreating = false
    #isRefreshing = false

    #clientId
    #projectId
    #projectIdLong
    #utmCampaign
    #utmSource
    #utmMedium
    #utmContent
    #referenceTail
    #sourceReference
    #embedUrl

    constructor({
        clientId,
        projectId,
        projectIdLong,
        utmCampaign,
        utmSource,
        utmMedium,
        utmContent,
        referenceTail,
        sourceReference,
        embedUrl,
    }) {
        this.#clientId = clientId
        this.#projectId = projectId
        this.#projectIdLong = projectIdLong
        this.#utmCampaign = utmCampaign
        this.#utmSource = utmSource
        this.#utmMedium = utmMedium
        this.#utmContent = utmContent
        this.#referenceTail = referenceTail
        this.#sourceReference = sourceReference
        this.#embedUrl = embedUrl
    }

    create = async () => {
        if (this.#isCreating) return
        this.#isCreating = true
        this.#session = await API.createSession(this.#clientId, this.#projectId, this.#projectIdLong, {
            utmCampaign: this.#utmCampaign,
            utmSource: this.#utmSource,
            utmMedium: this.#utmMedium,
            utmContent: this.#utmContent,
            referenceTail: this.#referenceTail,
            sourceReference: this.#sourceReference,
            embedUrl: this.#embedUrl,
        })
        this.#isCreating = false
    }

    refresh = async () => {
        if (!this.#session || this.#isRefreshing) return
        this.#isRefreshing = true
        await API.refreshSession(this.#clientId, this.#session.id)
        this.#isRefreshing = false
    }

    getId = async () => {
        if (!this.#session) {
            await new Promise(r => setTimeout(r, 3000))
            if (!this.#session) return null
        }
        return this.#session.id
    }
}
