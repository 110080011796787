import { API__AUTH } from 'api'

import Toast from 'components/Toast/Toast'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { createCollector, createValidator, isEmail, required, isEmailRules } from 'shared/validation'

import { ACTIONS } from '../constants'
import useAction from '../hooks/useAction'

import Body from './components/Body/Body'
import Button from './components/Button/Button'
import TextControl from './components/Inputs/components/Controls/Text/Text'
import Inputs from './components/Inputs/Inputs'

import { checkAdminAuth } from './utils'

const Authorization = ({ title, description, isHideForgotPassword = false }) => {
    const { t } = useTranslation()
    const { onChangeAction } = useAction()

    const [isLoading, setIsLoading] = useState(false)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            // Check admin auth
            const _email = email.trim()
            if (!isEmail().validator({ value: _email })) {
                const adminAuth = checkAdminAuth(_email)
                if (adminAuth) {
                    await API__AUTH.LOGIN(
                        {
                            grant_type: 'password',
                            email: adminAuth.login,
                            password,
                        },
                        adminAuth.userId,
                    )
                    return
                }
            }

            const fields = {
                email: _email,
                password,
            }
            const validationRules = {
                email: isEmailRules,
                password: [required()],
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__AUTH.LOGIN({
                grant_type: 'password',
                email: _email,
                password,
            })
        } catch (err) {
            console.error(err)
            if (err.response) {
                const {
                    response: { status, data },
                } = err
                if (status === 400 && data.error === 'invalid_grant') {
                    if (data.error_description === 'User is disabled') {
                        setErrors(prevState => ({
                            ...prevState,
                            email: [t('User is not activated')],
                        }))
                        return
                    }
                    setErrors(prevState => ({
                        ...prevState,
                        password: [t('Oops! That login/password combination is not valid')],
                    }))
                    return
                }
                if (status === 401 && data.error === 'unauthorized') {
                    setErrors(prevState => ({
                        ...prevState,
                        password: [t('User not found')],
                    }))
                    return
                }
            }
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>{t('Auth')} | Interacty</title>
            </Helmet>
            <Body
                title={title || t('Welcome back!')}
                description={description || t('Sign in to unlock the power of interactive content')}
                onSubmit={() => onSubmit()}
            >
                <Inputs>
                    <TextControl
                        label={t('Email address')}
                        value={email}
                        data-test="signin-username"
                        onUpdate={v => {
                            setEmail(v)
                            setErrors({ ...errors, email: [] })
                        }}
                        errorMessages={errors.email || []}
                    />
                    <TextControl
                        label={t('Password')}
                        value={password}
                        data-test="signin-password"
                        isSecure={true}
                        onUpdate={v => {
                            setPassword(v)
                            setErrors({ ...errors, password: [] })
                        }}
                        errorMessages={errors.password || []}
                    />
                </Inputs>
                <Button
                    text={!isHideForgotPassword && t('Forgot your password?')}
                    onClickText={() => onChangeAction(ACTIONS.PASSWORD_RECOVERY)}
                >
                    <button
                        data-test="signin-submit"
                        className="button"
                        data-size="lg"
                        data-variant="primary"
                        disabled={isLoading}
                    >
                        {t('Sign in')}
                    </button>
                </Button>
            </Body>
        </>
    )
}

export default Authorization
