import React from 'react'

import ComponentsList from '../../../../components/ComponentsList/ComponentsList'

import BlockBaseStatistic from '../../components/BlockBaseStatistic/BlockBaseStatistic'
import RatingTable from '../../components/RatingTable/RatingTable'
import LeadFormTable from '../../components/LeadFormTable/LeadFormTable'

const SlidingPuzzle = ({ projectId, blockProjection, dateRange, periodInterval }) => (
    <ComponentsList>
        <BlockBaseStatistic projectId={projectId} blockId={blockProjection.blockId} dateRange={dateRange} />
        <LeadFormTable
            projectId={projectId}
            blockProjection={blockProjection}
            dateRange={dateRange}
            periodInterval={periodInterval}
        />
        <RatingTable
            projectId={projectId}
            blockProjection={blockProjection}
            periodInterval={periodInterval}
            dateRange={dateRange}
        />
    </ComponentsList>
)

export default SlidingPuzzle
