import React from 'react'

const Item = ({ label, labelButtonText, onLabelButtonClick = () => {}, value }) => (
    <li className="project-block__statistic-item">
        <div className="project-block__statistic-label">
            {label}
            {!!labelButtonText && (
                <div className="project-block__statistic-label__btn" onClick={onLabelButtonClick}>
                    {labelButtonText}
                </div>
            )}
        </div>
        <div className="project-block__statistic-value">{value}</div>
    </li>
)

export default Item
