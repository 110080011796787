import { cloneDeep } from 'lodash'

export const getPlaygroundTopStyles = pairsCount => {
    const styles = {
        margin: '-6px auto 28px',
    }
    if (pairsCount <= 4) {
        styles.margin = '-10px -10px 22px -10px'
    }
    if (pairsCount >= 9 && pairsCount <= 10) {
        styles.maxWidth = 470
    }
    if (pairsCount >= 11) {
        styles.maxWidth = 564
    }
    return styles
}

export const getPlaygroundTopItemStyles = pairsCount => {
    const styles = {
        margin: 6,
    }
    if (pairsCount <= 4) {
        styles.margin = 10
    }
    return styles
}

export const getPlaygroundDropzoneStyles = pairsCount => {
    const styles = {
        margin: 0,
    }
    if (pairsCount <= 4) {
        styles.margin = -6
    }
    return styles
}

export const shuffleArray = arr => cloneDeep(arr).sort(() => Math.round(Math.random() * 100) - 50)
