import React, { useEffect, useState, useMemo, useCallback } from 'react'
import classNames from 'classnames'

import { CARD_PROPORTIONS } from './constants'

import './CardProportions.scss'

const CardProportions = ({ label = '', value, onUpdate }) => {
    const [_value, _setValue] = useState(value)

    useEffect(() => {
        _setValue(value)
    }, [value])

    const onChange = useCallback(
        val => {
            _setValue(val)
            onUpdate(val)
        },
        [onUpdate],
    )

    const proportions = useMemo(
        () =>
            CARD_PROPORTIONS.map((proportion, i) => (
                <div
                    className={classNames('card-proportions__proportion', {
                        [proportion.className]: !!proportion.className,
                        'card-proportions__proportion-is-selected': _value === proportion.value,
                    })}
                    key={i}
                    value={proportion.value}
                    onClick={() => onChange(proportion.value)}
                >
                    {proportion.label}
                </div>
            )),
        [_value, onChange],
    )

    return (
        <div className="card-proportions">
            {!!label.length && <p className="card-proportions__label">{label}</p>}
            <div className="card-proportions__content-wrapper">{proportions}</div>
        </div>
    )
}

export default CardProportions
