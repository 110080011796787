import { DEFAULT_IMAGE_URL } from '../../../../../../../../../common/constants/constants.js'
import { getUniqueId } from '../../../../../../../../../common/utils/getUniqueId'

export class Answer {
    id // <String> id
    text // <String> text
    image // <null/String[URL]> image
    imageLabel // <String> image label
    links // <Array> weight links

    constructor({ text = '', image = DEFAULT_IMAGE_URL, imageLabel = '', links = [] } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.image = image
        this.imageLabel = imageLabel
        this.links = links
    }

    static get text() {
        return 'text'
    }

    static get image() {
        return 'image'
    }

    static get imageLabel() {
        return 'imageLabel'
    }

    static get links() {
        return 'links'
    }
}

export class Question {
    id // <String> id
    text // <String> text
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer
    answers // <Array> Answers
    isText // <Boolean> is text question

    constructor({
        text = '',
        image = DEFAULT_IMAGE_URL,
        imageDisclaimer = '',
        answers = [new Answer(), new Answer()],
        isText = true,
    } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.image = image
        this.imageDisclaimer = imageDisclaimer
        this.answers = answers.map(answer => new Answer(answer))
        this.isText = isText
    }

    static get text() {
        return 'text'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }

    static get answers() {
        return 'answers'
    }

    static get isText() {
        return 'isText'
    }
}
