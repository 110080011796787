import React, { useMemo, useState } from 'react'
import Hint from './Hint'

let HintContext
const { Provider } = (HintContext = React.createContext())

const HintProvider = ({ children }) => {
    const [hints, setHints] = useState([])

    const removeHint = () => {
        const [, ...rest] = hints
        setHints(rest)
    }

    const renderedHint = useMemo(() => {
        if (hints.length > 0) {
            const { isShow, placement, hintName, parentRef, text, ...rest } = hints[0]
            return (
                <Hint
                    placement={placement}
                    hintName={hintName}
                    parentRef={parentRef}
                    text={text}
                    onCloseHint={removeHint}
                    {...rest}
                />
            )
        }
    }, [hints, removeHint])

    return (
        <Provider value={{ hints, setHints }}>
            {renderedHint}
            {children}
        </Provider>
    )
}

export { HintContext, HintProvider }
