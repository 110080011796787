import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Image from '../Image'

import { useAssetManager } from '../../AssetManager'

import './UploadImage.scss'

const UploadImage = ({ label = '', value, onUpdate, isDisabled, isDisabledDelete, className }) => {
    const { t } = useTranslation()
    const { chooseAsset, deleteAsset } = useAssetManager()

    return (
        <div
            className={classNames('common-upload-image-group', { 'is-disabled': isDisabled, [className]: !!className })}
        >
            {!!label.length && (
                <p className="common-upload-image-group__label" title={label}>
                    {label}
                </p>
            )}
            <div className="common-upload-image-group__content">
                {!!value && <Image className="common-upload-image-group__img-preview" src={value} />}
                <button className="common-upload-image-group__contr-btn" onClick={() => chooseAsset(value, onUpdate)}>
                    {value ? t('Change') : t('Upload')}
                </button>
                {value && !isDisabledDelete && (
                    <button
                        className="common-upload-image-group__delete-btn"
                        onClick={() => deleteAsset(value, onUpdate)}
                    />
                )}
            </div>
        </div>
    )
}

export default UploadImage
