import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

import { IconSearch } from 'svg'

import './Search.scss'

const Search = ({ onSearchValueChange }) => {
    const { t } = useTranslation()

    const onDelayedSearch = useCallback(
        debounce(value => onSearchValueChange(value), 300),
        [],
    )

    return (
        <div className="asset-manager-search">
            <div className="asset-manager-search__icon">
                <IconSearch />
            </div>
            <input
                type="text"
                placeholder={t('Search')}
                maxLength={100}
                onChange={ev => onDelayedSearch(ev.target.value)}
            />
        </div>
    )
}

export default Search
