import { cloneDeep } from 'lodash'

import { createCollector, createValidator } from 'shared/validation'

import { FIELD_TYPES } from 'common/constants/fields'
import { difference } from 'common/utils/operationForSet'

import i18n from 'i18n'

import {
    DEFAULT_FORM_FIELDS,
    EMBEDDED_VALIDATE_RULES_FIELDS,
    FIELD_KEYS,
    FIELD_LABELS,
    INDEPENDENT_VALIDATE_RULES_FIELDS,
    MAX_CUSTOM_FIELDS,
    MODE,
    TABS_ENUM,
    TAB_TO_FIELD_MAP,
} from './constants'

export const selectCustomFields = fields => fields.filter(field => field.key.includes(FIELD_KEYS.CUSTOM_FIELD))

export function getInitialValueByType(type) {
    switch (type) {
        case FIELD_TYPES.TEXT_AREA:
        case FIELD_TYPES.TEXT_INPUT:
        case FIELD_TYPES.URL:
        case FIELD_TYPES.PHONE_NUMBER:
        case FIELD_TYPES.EMAIL:
            return ''
        case FIELD_TYPES.CHECKBOX:
        case FIELD_TYPES.CONSENT:
            return false
        default:
            throw new Error('Undefined field type: ' + type)
    }
}

const createField = ({ key, label, isRequired = true, type }) => ({
    key: key,
    label: label,
    isRequired: isRequired,
    initialValue: getInitialValueByType(type),
    type: type,
})

export const createDefaultField = key =>
    createField({
        key: key,
        label: FIELD_LABELS[key],
        type: DEFAULT_FORM_FIELDS[key].type,
    })

const sourceNumbers = new Set(Array.from({ length: MAX_CUSTOM_FIELDS }, (_, i) => i + 1))
function findFreeSlotOfNumbers(customFields) {
    const busyNumbers = new Set()
    for (const field of customFields) {
        const fieldNumber = parseInt(field.key.substring(FIELD_KEYS.CUSTOM_FIELD.length))
        busyNumbers.add(fieldNumber)
    }
    return difference(sourceNumbers, busyNumbers)
}

export function createCustomField(customFields) {
    const freeNumbers = findFreeSlotOfNumbers(customFields)
    const key = FIELD_KEYS.CUSTOM_FIELD + freeNumbers.values().next().value
    return createField({
        key: key,
        label: i18n.t('New field'),
        type: FIELD_TYPES.TEXT_INPUT,
    })
}

export const getInitialLeadFormStructureForIndependentMode = () => ({
    isHasFinalScreen: true,
    form: {
        fields: [
            createDefaultField(FIELD_KEYS.FIRST_NAME),
            createDefaultField(FIELD_KEYS.LAST_NAME),
            createDefaultField(FIELD_KEYS.EMAIL),
            createDefaultField(FIELD_KEYS.PHONE_NUMBER),
        ],
        headerText: i18n.t('Form'),
        descriptionText: i18n.t('Please fill in the form fields'),
        linkPrivacyPolicyLink: '',
        buttonText: i18n.t('Submit'),
        logoImage: '',
        additionalNotes: '',
    },
    final: {
        headerText: i18n.t('Thank you') + '!',
        descriptionText: '',
        imageSrc: '',
        imageDisclaimerText: '',
    },
})

export const getInitialLeadFormStructureForEmbeddedMode = () => ({
    isHasFinalScreen: false,
    form: {
        fields: [createDefaultField(FIELD_KEYS.FIRST_NAME), createDefaultField(FIELD_KEYS.EMAIL)],
        headerText: i18n.t('Form'),
        descriptionText: i18n.t('Please fill in the form fields'),
        linkPrivacyPolicyLink: '',
        buttonText: i18n.t('Submit'),
        logoImage: '',
    },
})

export const SERVICE_FUNC_BY_MODE = {
    [MODE.INDEPENDENT]: {
        isValidFields: createValidator(INDEPENDENT_VALIDATE_RULES_FIELDS),
        collectFieldsErrors: createCollector(INDEPENDENT_VALIDATE_RULES_FIELDS),
        preFormat: tmpStructure => {
            const _tmpStructure = cloneDeep(tmpStructure)
            for (const [key, value] of Object.entries(_tmpStructure[TAB_TO_FIELD_MAP[TABS_ENUM.form]])) {
                if (typeof value === 'string') _tmpStructure[TAB_TO_FIELD_MAP[TABS_ENUM.form]][key] = value.trim()
            }
            return _tmpStructure
        },
    },
    [MODE.EMBEDDED]: {
        isValidFields: createValidator(EMBEDDED_VALIDATE_RULES_FIELDS),
        collectFieldsErrors: createCollector(EMBEDDED_VALIDATE_RULES_FIELDS),
    },
}

export const validateTabs = (tmpStructure, mode, activeTab = null) => {
    const functions = SERVICE_FUNC_BY_MODE[mode]

    let structure = cloneDeep(tmpStructure)
    if (!!functions.preFormat) structure = functions.preFormat(tmpStructure)

    return {
        tmpStructure: structure,
        errors: functions.collectFieldsErrors(structure, activeTab),
        isHasError: !functions.isValidFields(structure, activeTab),
    }
}
