import React from 'react'
import { useTranslation } from 'react-i18next'

import { filterDataAttr } from 'common/utils/attributes'

import styles from './EditBlockButton.module.scss'

const EditBlockButton = ({ onClick = () => {}, ...rest }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.editBlockButton} onClick={onClick} {...filterDataAttr(rest)}>
            {t('Edit')}
        </div>
    )
}

export default EditBlockButton
