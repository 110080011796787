export const STEPS_ENUM = {
    INTERESTS_SELECTOR: 1,
    AGE_SELECTOR: 2,
    WHAT_IS: 3,
    CONTACTS: 4,
}

export const INTERESTS_STEP_PARENT_TAGS_NAMES = [
    'languages',
    'exams',
    'maths',
    'science',
    'arts_and_humanities',
    'social_sciences',
    'movies',
    'driving_test',
    'computer_skills',
    'hobbies',
    'sport',
    'travel',
    'beauty',
    'other',
]

export const AGE_STEP_PARENT_TAGS_NAMES = ['age_4-6', 'age_7-9', 'age_10-12', 'age_13-17', 'age_18+']
