import React, { useState } from 'react'

import { pickColor } from 'utils/colors'

import LeadFormModal from '../../Modals/LeadFormModal/LeadFormModal'
import Preview from '../../Modals/LeadFormModal/views/Form/components/Preview/Preview'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

import styles from './LeadForm.module.scss'

const LeadForm = ({ struct, backgroundColor = '#fff', fontFamily, onUpdate, id }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            {struct.form && (
                <div className={styles.preview}>
                    <Preview
                        backgroundColor={backgroundColor}
                        color={pickColor(backgroundColor, '#3C3C3C', '#fff')}
                        fontFamily={fontFamily}
                        fields={struct.form.fields}
                        logoImage={struct.form.logoImage}
                        headerText={struct.form.headerText}
                        descriptionText={struct.form.descriptionText}
                        buttonText={struct.form.buttonText}
                        linkPrivacyPolicyLink={struct.form.linkPrivacyPolicyLink}
                        additionalNotes={struct.form.additionalNotes}
                    />
                </div>
            )}

            {isShowModal && (
                <LeadFormModal
                    data={{
                        structure: struct,
                        backgroundColor,
                        fontFamily,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default LeadForm
