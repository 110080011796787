import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFullUrlToSsr } from 'utils/router'

import logoIcon from './i/logo.svg'

import styles from './Aside.module.scss'

const Aside = () => {
    const { t } = useTranslation()

    return (
        <aside className={styles.aside}>
            <div className={styles.box}>
                <a className={styles.link} href={getFullUrlToSsr()}>
                    <img className={styles.icon} src={logoIcon} alt="" />
                </a>
                <p className={styles.text}>{t('Create interactive content in minutes')}</p>
            </div>
        </aside>
    )
}

export default Aside
