import React from 'react'
import classNames from 'classnames'

import styles from './ModeCard.module.scss'

const ModeCard = ({ isDisabled, isSelected, title, description, image, onSelect }) => (
    <li
        className={classNames(styles.modeCard, {
            [styles.isSelectedModeCard]: isSelected,
            [styles.isDisabledModeCard]: isDisabled,
        })}
        style={{ backgroundImage: `url(${image})` }}
        onClick={onSelect}
    >
        <div className={styles.mark} />
        <div className={styles.texts}>
            <div className={styles.title}>{title}</div>
            <p className={styles.description}>{description}</p>
        </div>
    </li>
)

export default ModeCard
