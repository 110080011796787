import React from 'react'

import useModalManager from 'hooks/useModalManager'

import ViewsLimits from './LimitsModal/components/ViewsLimits'
import StorageLimit from './LimitsModal/components/StorageLimit'
import ProjectsLimits from './LimitsModal/components/ProjectsLimits'
import PdfGeneration from './PdfGeneration/PdfGeneration'

import { MODALS } from './constants'

const ModalManager = () => {
    const { modalManager, closeModal } = useModalManager()

    return (
        <>
            {modalManager.name === MODALS.VIEWS_LIMITS_MODAL && <ViewsLimits onClose={closeModal} />}
            {modalManager.name === MODALS.STORAGE_LIMITS_MODAL && <StorageLimit onClose={closeModal} />}
            {modalManager.name === MODALS.PROJECTS_LIMITS_MODAL && <ProjectsLimits onClose={closeModal} />}
            {modalManager.name === MODALS.PDF_GENERATION && (
                <PdfGeneration payload={modalManager.payload} onClose={closeModal} />
            )}
        </>
    )
}

export default ModalManager
