import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import languages from 'i18n/languages'
import { isEducation } from 'common/utils/permissions'
import { getFullUrlToSsr } from 'utils/router'
import { isEuNorwayVersion } from 'utils/env'
import { COMMON__SET_LANGUAGE } from 'store/actions'

import { IconLogoFull } from '../svg'

const SsrLinkItem = ({ prefix, path, label }) => (
    <li>
        <a href={prefix ? prefix + path : getFullUrlToSsr(path)} target="_blank">
            {label}
        </a>
    </li>
)
const ExternalLinkItem = ({ href, label }) => (
    <li>
        <a href={href} target="_blank">
            {label}
        </a>
    </li>
)

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClick__change_language = item => async evt => {
        evt.preventDefault()
        try {
            if (item.is_enabled) {
                const { P__COMMON__SET_LANGUAGE } = this.props
                await P__COMMON__SET_LANGUAGE({ code: item.code })
                document.location.reload()
            }
        } catch (err) {
            console.error(err)
        }
    }

    render() {
        const { i18n, t } = this.props

        const isEdu = isEducation()

        return (
            <footer id="footer">
                <div className="main">
                    <div className="wrap-box">
                        <ul className="links">
                            <li>
                                <div className="title">{t('Platform')}</div>
                                <ul>
                                    <SsrLinkItem label={t('Template gallery')} path="/template-gallery" />
                                    {!isEuNorwayVersion() && (
                                        <SsrLinkItem
                                            label={t('Pricing for education')}
                                            path="/pricing/education?group=individuals"
                                        />
                                    )}
                                    {!isEuNorwayVersion() && (
                                        <SsrLinkItem
                                            label={t('Pricing for business')}
                                            path="/pricing/business?group=individuals"
                                        />
                                    )}
                                    {!isEuNorwayVersion() && isEdu && (
                                        <SsrLinkItem label={t('Community')} path="/community" />
                                    )}
                                    {!isEuNorwayVersion() && (
                                        <SsrLinkItem label={t('How it works')} path="/how-it-works" />
                                    )}
                                    {!isEuNorwayVersion() && <SsrLinkItem label={t('About')} path="/about" />}
                                    {!isEuNorwayVersion() && <SsrLinkItem label={t('Contacts')} path="/contacts" />}
                                    {!isEuNorwayVersion() && <SsrLinkItem label={t('Investors')} path="/investors" />}
                                    {!isEuNorwayVersion() && (
                                        <SsrLinkItem
                                            label={t('For teachers')}
                                            path="/products/memory-games-for-teachers"
                                        />
                                    )}
                                    {!isEuNorwayVersion() && (
                                        <SsrLinkItem
                                            label={t('For business')}
                                            path="/products/memory-games-for-business"
                                        />
                                    )}
                                    <ExternalLinkItem href="https://help.interacty.me" label={t('Help center')} />
                                    <ExternalLinkItem
                                        href="https://www.youtube.com/channel/UCsXVopApOxKt0b9JIkkhZog/videos"
                                        label={t('Youtube channel')}
                                    />
                                    {!isEuNorwayVersion() && (
                                        <ExternalLinkItem
                                            href="https://interacty.frill.co/roadmap"
                                            label={t('Roadmap')}
                                        />
                                    )}
                                    {!isEuNorwayVersion() && (
                                        <ExternalLinkItem
                                            href="https://interacty.frill.co/b/y0g48n0e/feature-ideas"
                                            label={t('Request feature')}
                                        />
                                    )}
                                </ul>
                            </li>
                            <li>
                                <div className="title">{t('Products')}</div>
                                <ul>
                                    <SsrLinkItem label={t('Live Game Events')} path="/products/live-game-events" />
                                    <SsrLinkItem
                                        label={t('Interactive worksheets')}
                                        path="/products/interactive-worksheets"
                                    />
                                    <SsrLinkItem
                                        label={t('Landing page builder')}
                                        path="products/landing-page-builder"
                                    />
                                    <SsrLinkItem label={t('Trivia Quiz')} path="/products/trivia-quiz" />
                                    <SsrLinkItem label={t('Personality Quiz')} path="/products/personality-quiz" />
                                    <SsrLinkItem label={t('Memory Games')} path="/products/memory-games" />
                                    <SsrLinkItem label={t('Match Up')} path="/products/match-up" />
                                    <SsrLinkItem label={t('Rank Battle')} path="/products/rank-battle" />
                                    <SsrLinkItem label={t('Spin the Wheel')} path="/products/spin-the-wheel" />
                                    <SsrLinkItem label={t('Crossword Puzzle')} path="/products/crossword-puzzle" />
                                    <SsrLinkItem label={t('Sliding Puzzle')} path="/products/sliding-puzzle" />
                                    <SsrLinkItem label={t('Then/Now')} path="/products/then-now" />
                                    <SsrLinkItem label={t('Interactive Image')} path="/products/interactive-image" />
                                    <SsrLinkItem label={t('Fortune cookies')} path="/products/fortune-cookies" />
                                    <SsrLinkItem label={t('Timeline')} path="/products/timeline" />
                                    <SsrLinkItem label={t('Find One Pair')} path="/products/find-pair" />
                                    <SsrLinkItem label={t('Hidden objects')} path="/products/hidden-objects" />
                                    <SsrLinkItem label={t('Slideshow')} path="/products/slideshow" />
                                    <SsrLinkItem label={t('Lead Form')} path="/products/lead-form" />
                                    <SsrLinkItem label={t('Flip Cards')} path="/products/flip-cards" />
                                    <SsrLinkItem label={t('Puzzle')} path="/products/puzzle" />
                                    <SsrLinkItem label={t('Horoscope')} path="/products/horoscope" />
                                    <SsrLinkItem label={t('Treasure Hunt')} path="/products/treasure-hunt" />
                                    <SsrLinkItem label={t('Rank Battle')} path="/products/rank-battle" />
                                    <SsrLinkItem label={t('Quotes')} path="/products/quotes" />
                                </ul>
                            </li>
                            <li>
                                <div className="title">{t('Languages')}</div>
                                <ul>
                                    {languages
                                        .filter(el => el.is_visible)
                                        .map(el => {
                                            return (
                                                <li key={el.code}>
                                                    <span
                                                        className={`${i18n.language === el.code ? 'is-active ' : ''}${
                                                            el.is_enabled ? '' : 'is-disabled '
                                                        }`}
                                                        onClick={this.handleClick__change_language(el)}
                                                    >
                                                        {el.label}
                                                    </span>
                                                </li>
                                            )
                                        })}
                                </ul>
                            </li>
                            <li>
                                <div className="title">{t('Terms of service')}</div>
                                <ul>
                                    <SsrLinkItem label={t('Terms of use')} path="/terms-of-use" />
                                    <SsrLinkItem label={t('Privacy Policy')} path="/privacy-policy" />
                                    <SsrLinkItem label={t('Cookie Policy')} path="/cookie-policy" />
                                    <SsrLinkItem
                                        label={t('Data processing agreement')}
                                        path="/data-processing-agreement"
                                    />
                                    <SsrLinkItem label={t('Partners technical FAQ')} path="/partners-technical-faq" />
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="down container-fluid">
                    <IconLogoFull />
                    <p>
                        © Interacty 2024<span>&nbsp;|&nbsp;</span>v1.0.1
                    </p>
                </div>
            </footer>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    P__COMMON__SET_LANGUAGE: value => dispatch(COMMON__SET_LANGUAGE(value)),
})

export default compose(connect(null, mapDispatchToProps), withTranslation('translations'))(Footer)
