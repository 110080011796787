import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import eyeIcon from './i/eye.svg'

import styles from './PreviewBlock.module.scss'

const PreviewBlock = ({}) => {
    const { project } = useContext(ProjectContext)
    const { t } = useTranslation()

    return (
        <div className={styles.previewBlock}>
            <Trans
                i18nKey="You can try the game in action in the <lnk><icon/> Preview mode</lnk>"
                components={{
                    icon: <img className={styles.icon} src={eyeIcon} alt="eye" />,
                    lnk: (
                        <Link
                            to={`/editor/${project.projectId}/preview`}
                            className={styles.link}
                            title={t('Preview')}
                        />
                    ),
                }}
            />
        </div>
    )
}

export default PreviewBlock
