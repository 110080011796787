import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import DatePicker from './DatePicker'
import './style.scss'

const DateRangePicker = ({ onChangeRange, startDate, endDate, t }) => {
    const [from, setFrom] = useState(startDate)
    const [to, setTo] = useState(endDate)

    const onApply = () => onChangeRange({ from, to })

    useEffect(() => {
        setFrom(startDate)
    }, [startDate])

    useEffect(() => {
        setTo(endDate)
    }, [endDate])

    const setFromDate = date => {
        if (date > to) {
            setTo(date)
        }
        setFrom(date)
    }

    return (
        <div className="date-range-picker-container">
            <DatePicker selected={from} onChange={setFromDate} maxDate={new Date()} />
            <span>-</span>
            <DatePicker selected={to} onChange={setTo} minDate={from} maxDate={new Date()} />
            <button onClick={onApply} className="button" data-variant="primary" data-size="md">
                {t('Apply')}
            </button>
        </div>
    )
}

export default withTranslation('translations')(DateRangePicker)
