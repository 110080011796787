const Rectangle = ({ isActive = false }) => (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1517_253" fill="white">
            <rect width="28" height="22" rx="1" />
        </mask>
        <rect
            width="28"
            height="22"
            rx="1"
            stroke={isActive ? '#2990FB' : '#787878'}
            strokeWidth="2"
            strokeLinejoin="round"
            mask="url(#path-1-inside-1_1517_253)"
        />
    </svg>
)

export default Rectangle
