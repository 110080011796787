import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import { TILES_FIELDS } from '../../utils'
import NavigationListBar from '../../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../../Controls/NavigationListBar/hooks/useNavigation'

import { TreasureHuntTileEditorList } from '../../components/TreasureHuntTileEditor'
import './Tiles.scss'

export const Tiles = ({ structure, pages, validateErrors, colorTheme, methods: { updateStructure } }) => {
    const { t } = useTranslation()
    const [dataSet, setDataSet] = useState({})

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    useEffect(() => {
        setDataSet(structure.tiles.tileList)
    }, [structure.tiles.tileList])

    useEffect(() => {
        if (dataSet.length) {
            updateStructure({ [TILES_FIELDS.tileList]: dataSet })
        }
    }, [dataSet])

    const updateTile = (tileId, fields) => {
        setDataSet(dataSet.map(item => (item.id === tileId ? { ...item, ...fields } : item)))
    }

    return (
        <>
            {dataSet.length > 0 && (
                <div className="tiles-view">
                    <aside className="tiles-view__navigation">
                        <NavigationListBar
                            titleOfItem={t('Quest')}
                            focusedItemId={focusedElementId}
                            items={dataSet}
                            iconAccessor="overlaySrc"
                            colorTheme={colorTheme}
                            maxLength={dataSet.length}
                            onClickItem={scrollContainerToElement}
                        />
                    </aside>
                    <div className="tiles-view__body">
                        <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                            <div className="scrollbar-inner">
                                <TreasureHuntTileEditorList
                                    pages={pages}
                                    tiles={dataSet}
                                    titleOfItem={t('Quest')}
                                    tilesErrors={validateErrors && validateErrors.tileList}
                                    onSetFocusToSection={onSetFocusedElementId}
                                    updateTile={updateTile}
                                />
                            </div>
                        </ScrollableBlock>
                    </div>
                </div>
            )}
        </>
    )
}
