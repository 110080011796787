import React from 'react'
import { withTranslation } from 'react-i18next'

import { API__PROJECTS } from 'api'

import { hasPermission, CONDITIONS } from 'common/utils/permissions'

import Tooltip from 'components/Tooltip/Tooltip'
import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast'
import Header from 'components/Modal/components/Header/Header'
import Footer from 'components/Modal/components/Footer/Footer'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import { ASSET_TABS } from 'components/AssetManager/utils/constants'
import { AssetManagerContext } from 'components/AssetManager/AssetManagerContext'

import iconInfo from './i/info.svg'

import './SharingSettingsModal.scss'

class SharingSettingsModal extends React.Component {
    static contextType = AssetManagerContext

    constructor(props) {
        super(props)
        this.state = {
            saving: false,

            title: this.props.project.title,
            description: this.props.project.description,
            imageUrl: this.props.project.imageUrl,
            customImageSelected: this.props.project.customImageSelected,
            faviconUrl: this.props.project.faviconUrl,
            optimizeForLargeDisplays: this.props.project.optimizeForLargeDisplays,

            newImageId: null,
            defaultPreviewImageUrl: null,
        }
    }

    async componentDidMount() {
        const imageUrl = await API__PROJECTS.GET_DEFAULT_PREVIEW_IMAGE()
        this.setState({ defaultPreviewImageUrl: imageUrl })
    }

    onPlaceImage = value => {
        this.setState({
            newImageId: value.id,
            imageUrl: value.src,
            customImageSelected: true,
        })
    }

    onRestoreImage = async () => {
        const { defaultPreviewImageUrl } = this.state
        await this.setState({
            newImageId: null,
            imageUrl: defaultPreviewImageUrl,
            customImageSelected: false,
        })
    }

    onChange = (target, value) => {
        this.setState({
            [target]: value,
        })
    }

    onSave = async () => {
        try {
            const { project, onSave, onClose, onChangeProject } = this.props
            const {
                newImageId,
                customImageSelected,
                title,
                description,
                faviconUrl,
                optimizeForLargeDisplays,
            } = this.state

            await this.setState({ saving: true })

            if (newImageId) {
                await API__PROJECTS.SET_PROJECT_PREVIEW_IMAGE(project.projectId, newImageId)
            } else {
                if (project.customImageSelected && !customImageSelected) {
                    await API__PROJECTS.SET_DEFAULT_PREVIEW_IMAGE(project.projectId)
                }
            }

            await onChangeProject('customImageSelected', customImageSelected)
            await onChangeProject('title', title)
            await onChangeProject('description', description)
            await onChangeProject('faviconUrl', faviconUrl)
            await onChangeProject('optimizeForLargeDisplays', optimizeForLargeDisplays)

            await onSave()

            await onClose()

            Toast('success', { time: 2000 })
        } catch (err) {
            console.error(err)
            Toast('error', {})

            await this.setState({
                saving: false,
            })
        }
    }

    render() {
        const {
            saving,
            title,
            description,
            imageUrl,
            customImageSelected,
            faviconUrl,
            optimizeForLargeDisplays,
        } = this.state
        const { onClose, i18n } = this.props

        return (
            <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose}>
                <Header title={i18n.t('Project sharing options')} onClose={onClose} />
                <div className="sharing-settings-modal">
                    <div className="sharing-settings-modal__group">
                        <div
                            className="sharing-settings-modal__favicon-preview"
                            style={{ backgroundImage: `url(${faviconUrl})` }}
                        />
                        <p
                            className="sharing-settings-modal__favicon-change-button"
                            onClick={() => {
                                this.context.setPlaceItem({
                                    callback: data => this.onChange('faviconUrl', data.src),
                                })
                                this.context.setSetting({
                                    tab: ASSET_TABS.TAB_MY_LIBRARY,
                                    managerTabs: [ASSET_TABS.TAB_MY_LIBRARY],
                                })
                                this.context.setIsOpen(true)
                            }}
                        >
                            {i18n.t('Change favicon')}
                        </p>
                    </div>
                    <div className="sharing-settings-modal__group">
                        <div className="sharing-settings-modal__preview">
                            <div className="window">
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage: `url(${imageUrl})`,
                                    }}
                                />
                                <div className="text">
                                    <div className={`title${!title.length ? ' title-empty' : ''}`}>{title}</div>
                                    <div className={`description${!description.length ? ' description-empty' : ''}`}>
                                        {description}
                                    </div>
                                </div>
                            </div>
                            <div className="upload">
                                <button
                                    className="button"
                                    data-size="md"
                                    data-variant="primary"
                                    onClick={() => {
                                        this.context.setPlaceItem({ callback: data => this.onPlaceImage(data) })
                                        this.context.setSetting({
                                            tab: ASSET_TABS.TAB_MY_LIBRARY,
                                            managerTabs: [ASSET_TABS.TAB_MY_LIBRARY],
                                        })
                                        this.context.setIsOpen(true)
                                    }}
                                >
                                    {i18n.t('Change image')}
                                </button>
                                {customImageSelected ? (
                                    <p onClick={() => this.onRestoreImage()}>{i18n.t('Restore default image')}</p>
                                ) : null}
                            </div>
                            <div className="note">
                                {i18n.t('Recommended resolution: {{horizontal}} x {{vertical}} pixels.', {
                                    horizontal: '1200',
                                    vertical: '630',
                                })}
                                <br />
                                {i18n.t('Up to {{value}} Mb', { value: '10' })}
                            </div>
                        </div>
                        <div className="sharing-settings-modal__info">
                            <ul className="inputs-block">
                                <li>
                                    <div className="title">
                                        {i18n.t('Title')}
                                        <span>{title.length}/40</span>
                                    </div>
                                    <div className="input-box">
                                        <input
                                            autoComplete="off"
                                            maxLength={40}
                                            type="text"
                                            value={title}
                                            onChange={evt => this.onChange('title', evt.target.value)}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="title">
                                        {i18n.t('Description')}
                                        <span>{description.length}/100</span>
                                    </div>
                                    <div className="textarea-box">
                                        <textarea
                                            rows="3"
                                            value={description}
                                            maxLength={100}
                                            onChange={evt => this.onChange('description', evt.target.value)}
                                        ></textarea>
                                    </div>
                                </li>
                                <li>
                                    {hasPermission(CONDITIONS.CAN_USE_WHITE_LABEL) && (
                                        <div className="optimize">
                                            <Checkbox
                                                value={optimizeForLargeDisplays}
                                                onUpdate={v => this.onChange('optimizeForLargeDisplays', v)}
                                                label={
                                                    <>
                                                        {i18n.t('Optimize for large displays')}{' '}
                                                        <Tooltip
                                                            tooltipText={i18n.t(
                                                                'This setting removes the margin between the top of the screen and the project for optimal full-screen display',
                                                            )}
                                                        />
                                                    </>
                                                }
                                            />
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer
                    actionText={i18n.t('Save')}
                    cancelText={i18n.t('Cancel')}
                    isActionDisabled={saving}
                    noteIcon={iconInfo}
                    noteText={i18n.t('Please note that social networks and other services may cache images.')}
                    onAction={this.onSave}
                    onClose={onClose}
                />
            </Modal>
        )
    }
}

export default withTranslation('translations')(SharingSettingsModal)
