import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Image from 'components/Form/Image.jsx'

import EditWebhooks from 'pages/Editor/Tabs/Integrations/i/edit_webhooks.png'
import popupMch from 'pages/Editor/Tabs/Integrations/i/popup-mch-webhook.png'

const WebhooksBlock = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className="content__title">{t('Collecting leads with Webhooks')}</div>

            <div className="content__sub-title">
                {t('To transfer data to your Webhooks account, follow these steps:')}
            </div>
            <div className="content__sub-title">
                <p>1. {t('Open your project in the editor')}</p>
                <p>
                    2.{' '}
                    {t('Select a block with the ability to collect data (for example, a lead form) and click "Edit"')}
                </p>
            </div>
            <Image className="content__image" src={popupMch} width={440} height={256} />
            <div className="content__sub-title">
                <p>3. {t('Click on the "Set up" button next to the Webhooks logo')}</p>
            </div>
            <div className="content__sub-title">4. {t('Add and manage your Webhooks')}</div>
            <Image className="content__image" src={EditWebhooks} width={440} height={256} />
            <div className="content__sub-title">{t('Everything is ready for data collection!')}</div>
            <div className="content__sub-title">
                <Trans
                    i18nKey="For more information click <lnk>here</lnk>"
                    components={{
                        lnk: (
                            <a
                                target="_blank"
                                href={'https://help.interacty.me/content/how-to-set-up-a-webhook-for-your-lead-form'}
                            />
                        ),
                    }}
                />
            </div>
        </>
    )
}

export default WebhooksBlock
