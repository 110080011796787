import { useEffect } from 'react'
import ReactGTM from 'react-gtm-module'

const id = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID

const useGoogleTagManager = () => {
    useEffect(() => {
        if (!id) return
        console.info('"Google Tag Manager" initialization')

        ReactGTM.initialize({
            gtmId: id,
        })
    }, [])
}

export default useGoogleTagManager
