import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import Image from 'components/Form/Image'

import image1 from '../i/ym_image1.png'
import image2 from '../i/ym_image2.png'

const YandexMetricBlock = ({ settings = { id: null }, isChecked, updateIntegration }) => {
    const { t } = useTranslation()
    const [id, setId] = useState(settings.id)
    const [error, setError] = useState('')

    const changeValue = evt => {
        setId(evt.target.value)
        setError('')
    }

    const onSave = () => {
        updateIntegration(true, { id: id })
    }

    const onDisable = () => {
        updateIntegration(false, { id: null })
    }

    const isDisabledSave = !id || isEqual(settings.id, id)

    return (
        <div className="google-analytics-block">
            <div className="content__title">{t('Integrations with Yandex Metric')}</div>
            <div
                className="content__sub-title"
                style={{
                    marginTop: -20,
                    marginBottom: 40,
                }}
            >
                {t('Using Yandex Metric, you can track user actions in games and interactive blocks.')}
            </div>

            <div className="content__sub-title">
                <p>1. {t('Specify counter ID')}</p>
            </div>

            <div className={`input-box ${error.length ? 'is-error' : ''}`}>
                <div className="title">{t('Counter ID')}</div>
                <input autoComplete="off" type="text" value={id} placeholder="" onChange={evt => changeValue(evt)} />
                <p className="msg msg--error">{error}</p>
            </div>
            <div className="content__sub-title">
                <p>2. {t('Save the integration settings and open your project in the editor.')}</p>
                <p>
                    3.{' '}
                    {t(
                        'Select the interactive block for which you plan to collect analytics. In the list of settings in the right panel, find “Yandex Metric” and click “Configure”.',
                    )}
                </p>
            </div>
            <Image className="content__image" src={image1} />
            <div className="content__sub-title">
                <p>4. {t('Provide a target ID for each event')}</p>
            </div>
            <Image className="content__image" src={image2} />
            <div className="content__sub-title">
                <p>5. {t('Republish the project')}</p>
            </div>
            <div className="content__sub-title">
                <p>{t('Everything is ready to collect analytics!')}</p>
            </div>

            <div className="btns">
                <button
                    className="button"
                    data-size="md"
                    disabled={isDisabledSave}
                    onClick={onSave}
                    data-variant="primary"
                >
                    {t('Save')}
                </button>
                {isChecked && (
                    <button className="button" data-size="md" data-variant="primary-danger" onClick={onDisable}>
                        {t('Disable')}
                    </button>
                )}
            </div>
        </div>
    )
}

export default YandexMetricBlock
