import React from 'react'
import classNames from 'classnames'

import Select, { VARIANT } from 'components/Form/Select/Select'

import styles from './PriceItem.module.scss'

const PriceItem = ({ title, description, priceValue, prices, isActive, isCanDisable, onChange }) => (
    <li className={styles.priceItem}>
        <div className={styles.top}>
            <div
                className={classNames(styles.topBox, { [styles.isDisabledTopBox]: !isCanDisable })}
                onClick={() => onChange({ isActive: !isActive })}
            >
                <div className={classNames(styles.box, { [styles.isActiveBox]: isActive })} />
                <p className={styles.title} onClick={() => onChange({ isActive: !isActive })}>
                    {title}
                </p>
            </div>
        </div>
        {isActive && (
            <div className={styles.price}>
                <div className={styles.select}>
                    <Select
                        value={{ value: priceValue, label: `$${priceValue}` }}
                        variant={VARIANT.big}
                        onUpdate={({ value }) => onChange({ priceValue: value })}
                        options={prices
                            .filter(price => price !== priceValue)
                            .map(price => ({ value: price, label: `$${price}` }))}
                        fullWide
                        isModal
                    />
                </div>
                <p className={styles.description}>{description}</p>
            </div>
        )}
    </li>
)

export default PriceItem
