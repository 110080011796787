export default class GoogleTagManager {
    #id

    constructor({ id }) {
        this.#id = id
    }

    initHeadScript = () => {
        const scriptElement = document.createElement('script')

        scriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${this.#id}');`

        document.head.insertBefore(scriptElement, document.head.childNodes[0])
    }

    initBodyNoScript = () => {
        const noScriptElement = document.createElement('noscript')

        noScriptElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.#id}"
            height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

        document.body.insertBefore(noScriptElement, document.body.childNodes[0])
    }

    // Public methods
    init = () => {
        try {
            this.initHeadScript()
            this.initBodyNoScript()
            console.log('"Google Tag Manager" initialization')
        } catch (err) {
            console.error(err)
        }
    }
}
