const Down = ({ color = '#3C3C3C' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.5 3C8.5 2.72386 8.27614 2.5 8 2.5C7.72386 2.5 7.5 2.72386 7.5 3L8.5 3ZM7.5 3L7.5 13L8.5 13L8.5 3L7.5 3Z"
            fill={color}
        />
        <path d="M4 10L8 13L12 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default Down
