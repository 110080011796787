import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useInitialLoading from 'hooks/useInitialLoading'

import { ORGANIZATIONS__INIT } from 'store/actions'

const OrganizationInitializer = ({ userId, children }) => {
    const { isReady, onReady } = useInitialLoading()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetch = async () => {
            await dispatch(ORGANIZATIONS__INIT(userId))
            onReady()
        }
        fetch()
    }, [userId])

    if (!isReady) return null

    return children
}

export default OrganizationInitializer
