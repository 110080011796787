import React, { useState, useContext } from 'react'
import { withTranslation } from 'react-i18next'

import TextInput from 'components/Form/TextInput/TextInput'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import SelectInternalPageModal from '../../Modals/SelectInternalPageModal/SelectInternalPageModal'

import styles from './TextInputWithPageSelection.module.scss'

const TextInputWithPageSelection = ({ t, label = t('Link'), value, onUpdate }) => {
    const [isModalActive, setIsModalActive] = useState(false)
    const { isMultiplayerGame, project } = useContext(ProjectContext)

    return (
        <div className={styles.textInputWithPageSelection}>
            {isModalActive && (
                <SelectInternalPageModal
                    data={{ pages: project.projectStructureJson.pages }}
                    onClose={() => setIsModalActive(false)}
                    customUpdate={value => onUpdate(value)}
                />
            )}
            {!isMultiplayerGame && (
                <div className={styles.openText} onClick={() => setIsModalActive(true)}>
                    {t('Internal page')}
                </div>
            )}
            <TextInput label={label} value={value} onUpdate={value => onUpdate(value)} />
        </div>
    )
}

export default withTranslation()(TextInputWithPageSelection)
