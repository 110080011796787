export const REMIX_SUPPORTED_FONTS = [
    'Roboto',
    'Roboto Condensed',
    'Open Sans',
    'Open Sans Condensed',
    'Lato',
    'Montserrat',
    'Oswald',
    'Merriweather',
    'Ubuntu',
    'Lobster',
    'Pacifico',
    'Vollkorn',
    'Cuprum',
    'Alegreya Sans',
    'Russo One',
    'Playfair Display',
    'Playfair Display SC',
    'Alice',
    'Press Start 2P',
    'Bad Script',
    'Yeseva One',
    'Marmelad',
    'Rubik Mono One',
    'Raleway',
    'Roboto Slab',
    'Lora',
    'Seymour One',
    'Cormorant SC',
    'Literata',
    'Spectral',
    'Alegreya',
    'EB Garamond',
    'Bitter',
    'PT Serif',
    'Noto Sans',
    'Manrope',
    'Unbounded',
    'Arimo',
    'Jost',
    'Overpass',
    'Comfortaa',
    'Caveat',
    'Inter Tight',
    'Amatic SC',
    'Tinos',
    'Prata',
    'Philosopher',
    'Geologica',
    'Tenor Sans',
    'Advent Pro',
    'Poiret One',
    'Jura',
    'PT Sans Narrow',
    'Fira Sans',
    'Mulish',
    'Nunito Sans',
    'Inter',
    'Old Standard TT',
    'Ruda',
    'Tenor Sans',
    'Source Code Pro',
    'Source Sans 3',
    'League Gothic',
    'Belleza',
    'Lancelot',
    'Schoolbell',
    'Grandstander',
    'Chewy',
    'Barriecito',
    'Arapey',
    'Libre Bodoni',
    'Cinzel Decorative',
    'Fredoka',
    'Poppins',
    'Arapey',
    'Outfit',
    'Schoolbell',
    'Satisfy',
    'Sanchez',
    'Saira Condensed',
    'Rye',
    'Rosario',
    'Ribeye',
    'Reggae One',
    'Red Hat Display',
    'Rakkas',
    'Radley',
    'Racing Sans One',
    'Quicksand',
    'Quattrocento',
    'Quando',
    'Squada One',
    'Prata',
    'Podkova',
    'Shantell Sans',
    'Signika Negative',
    'Sirin Stencil',
    'Six Caps',
    'Sniglet',
    'Solway',
    'Source Serif 4',
    'Space Mono',
    'Special Elite',
    'Squada One',
    'Staatliches',
    'Tomorrow',
    'Trocchi',
    'Trochut',
]