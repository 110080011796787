import React, { useState } from 'react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import Checkbox from 'components/Form/Checkbox/Checkbox.jsx'

import Modal from '../Modal'

import './ConfirmationDialog.scss'

const ConfirmationDialog = ({
    className,
    i18n,
    data: {
        headText = i18n.t('Please confirm'),
        image = null,
        noteText,
        actionVariant = 'primary',
        actionText = i18n.t('Accept'),
        cancelText = i18n.t('Cancel'),
        showCancelBtn = true,
        checkboxText,
        confirmText,
    },
    isLoading = false,
    closeOnEsc = true,
    closeOnOverlayClick = true,
    onAction,
    onClose,
}) => {
    const [checkbox, setCheckbox] = useState(false)
    return (
        <Modal closeOnEsc={closeOnEsc} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
            <div className={classNames('confirmation-dialog', className)}>
                <div className="confirmation-dialog__close" onClick={() => onClose()} />
                <h2 className="confirmation-dialog__head">{headText}</h2>
                {image ? (
                    <div className="confirmation-dialog__image">
                        <img src={image} alt="" />
                    </div>
                ) : null}
                {noteText && <div className="confirmation-dialog__note">{noteText}</div>}
                {checkboxText && (
                    <div className="confirmation-dialog__checkbox">
                        <Checkbox label={checkboxText} value={checkbox} onUpdate={setCheckbox} />
                    </div>
                )}
                {confirmText && <div className="confirmation-dialog__confirm">{confirmText}</div>}
                <div className="confirmation-dialog__buttons">
                    <button
                        className="confirmation-dialog__button button"
                        data-size="md"
                        disabled={isLoading || (checkboxText && !checkbox)}
                        data-variant={actionVariant}
                        onClick={() => onAction()}
                        data-test="confirmation-dialog-action-button"
                    >
                        {actionText}
                    </button>
                    {showCancelBtn ? (
                        <button
                            className="confirmation-dialog__button button"
                            data-size="md"
                            data-variant="secondary"
                            onClick={() => onClose()}
                        >
                            {cancelText}
                        </button>
                    ) : null}
                </div>
            </div>
        </Modal>
    )
}

export default withTranslation('translations')(ConfirmationDialog)
