import React from 'react'
import classNames from 'classnames'

import Menu from './components/Menu/Menu'

import styles from './BankItem.module.scss'

const BankItem = ({ bank, onDelete, onOpen, isPublic }) => (
    <li
        key={bank.bankId}
        className={classNames(styles.bank, { [styles.publicBank]: isPublic })}
        onClick={() => {
            if (!isPublic) return
            onOpen(bank, true)
        }}
    >
        {bank.name}
        {!isPublic && <Menu onDelete={() => onDelete(bank)} onOpen={() => onOpen(bank)} />}
    </li>
)

export default BankItem
