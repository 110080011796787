import Text from './Blocks/Text/Text'
import Image from './Blocks/Image/Image'
import EmbedProject from './Blocks/EmbedProject/EmbedProject'
import YoutubeVideo from './Blocks/YoutubeVideo/YoutubeVideo'
import Button from './Blocks/Button/Button'
import FlipCards from './Blocks/FlipCards/FlipCards'
import MemoryCards from './Blocks/Memory/Memory'
import ThenNow from './Blocks/ThenNow/ThenNow'
import Timeline from './Blocks/Timeline/Timeline'
import TriviaQuiz from './Blocks/TriviaQuiz/TriviaQuiz'
import InteractiveImage from './Blocks/InteractiveImage/InteractiveImage'
import HiddenObjects from './Blocks/HiddenObjects/HiddenObjects'
import LeadForm from './Blocks/LeadForm/LeadForm'
import FindPair from './Blocks/FindPair/FindPair'
import Slideshow from './Blocks/Slideshow/Slideshow'
import Cards from './Blocks/Cards/Cards'
import PersonalityQuiz from './Blocks/PersonalityQuiz/PersonalityQuiz'
import Matching from './Blocks/Matching/Matching'
import Puzzle from './Blocks/Puzzle/Puzzle'
import TreasureHunt from './Blocks/TreasureHunt/TreasureHunt'
import Logo from './Blocks/Logo/Logo'
import Audio from './Blocks/Audio/Audio'
import EmbedIframe from './Blocks/EmbedIframe/EmbedIframe'
import Question from './Blocks/Question/Question'
import WheelSpin from './Blocks/WheelSpin/WheelSpin'
import Crossword from './Blocks/Crossword/Crossword'
import SlidingPuzzle from './Blocks/SlidingPuzzle/SlidingPuzzle'
import RankBattle from './Blocks/RankBattle/RankBattle'
import Cover from './Blocks/Cover/Cover'
import TextAndImage from './Blocks/TextAndImage/TextAndImage'

import BLOCK from 'common/constants/BlockTypes/BlocksEnum'

export const DEFAULT_ARTBOARD_BACKGROUND_COLOR = '#fff'

export const PROJECT_DATA_TYPES = {
    app: 'app',
    blocks: 'blocks',
}

export const BlockComponents = {
    [BLOCK.text]: Text,
    [BLOCK.image]: Image,
    [BLOCK.embedInteractyProject]: EmbedProject,
    [BLOCK.flipCards]: FlipCards,
    [BLOCK.youtubeVideo]: YoutubeVideo,
    [BLOCK.button]: Button,
    [BLOCK.interactiveImage]: InteractiveImage,
    [BLOCK.hiddenObjects]: HiddenObjects,
    [BLOCK.quiz]: TriviaQuiz,
    [BLOCK.thenNow]: ThenNow,
    [BLOCK.memoryCards]: MemoryCards,
    [BLOCK.timeline]: Timeline,
    [BLOCK.cookies]: Cards,
    [BLOCK.horoscope]: Cards,
    [BLOCK.personalityQuiz]: PersonalityQuiz,
    [BLOCK.leadForm]: LeadForm,
    [BLOCK.findPair]: FindPair,
    [BLOCK.slideshow]: Slideshow,
    [BLOCK.puzzle]: Puzzle,
    [BLOCK.treasureHunt]: TreasureHunt,
    [BLOCK.logo]: Logo,
    [BLOCK.matching]: Matching,
    [BLOCK.audio]: Audio,
    [BLOCK.embedIframe]: EmbedIframe,
    [BLOCK.question]: Question,
    [BLOCK.wheelSpin]: WheelSpin,
    [BLOCK.crossword]: Crossword,
    [BLOCK.slidingPuzzle]: SlidingPuzzle,
    [BLOCK.rankBattle]: RankBattle,
    [BLOCK.cover]: Cover,
    [BLOCK.textAndImage]: TextAndImage,
}
