import i18n from 'i18n'

import { DEFAULT_IMAGE_URL, DEFAULT_IMAGE_BG_BIG_URL, DEFAULT_WHEEL_SPIN_IMAGE_URL } from 'common/constants/constants'

import { getUniqueId } from 'common/utils/getUniqueId'

import {
    DEFAULT_SECTION_TYPE,
    SECTION_WIN_PROBABILITY,
    DEFAULT_SECTIONS_COUNT,
    DEFAULT_ANIMATION_DURATION,
    TABS,
} from './constants'

export class Playground {
    sectionsCount // <Number> Section count
    sectionType // <String> Section type
    animationDuration // <Number> Animation duration
    spinImageUrl // <String> Spin image URL // DEFAULT_WHEEL_SPIN_IMAGE_URL
    isShowCover // <Boolean> Show cover
    coverHeader // <String> Cover header
    coverButtonText // <String> Cover button text

    constructor({
        sectionsCount = DEFAULT_SECTIONS_COUNT,
        sectionType = DEFAULT_SECTION_TYPE,
        animationDuration = DEFAULT_ANIMATION_DURATION,
        spinImageUrl = DEFAULT_WHEEL_SPIN_IMAGE_URL,
        isShowCover = false,
        coverHeader = '',
        coverButtonText = '',
    } = {}) {
        this.sectionsCount = sectionsCount
        this.sectionType = sectionType
        this.animationDuration = animationDuration
        this.spinImageUrl = spinImageUrl
        this.isShowCover = isShowCover
        this.coverHeader = coverHeader
        this.coverButtonText = coverButtonText
    }

    static get sectionsCount() {
        return 'sectionsCount'
    }

    static get sectionType() {
        return 'sectionType'
    }

    static get animationDuration() {
        return 'animationDuration'
    }

    static get spinImageUrl() {
        return 'spinImageUrl'
    }

    static get isShowCover() {
        return 'isShowCover'
    }

    static get coverHeader() {
        return 'coverHeader'
    }

    static get coverButtonText() {
        return 'coverButtonText'
    }
}

export class Section {
    id // <String> ID
    text // <String> Text
    imageUrl // <String> Image URL
    probabilityOfWinning // <String> Probability of winning
    resultHeader // <String> Result page header
    resultDescription // <String> Result page description
    resultImageUrl // <String> Result image URL
    resultImageDisclaimer // <String> Result image disclaimer
    resultButtonText // <String> Result button text
    resultButtonLink // <String> Result button link

    constructor({
        number,
        imageUrl = DEFAULT_IMAGE_URL,
        probabilityOfWinning = SECTION_WIN_PROBABILITY.NORMAL,
        resultHeader = i18n.t('Congratulations!'),
        resultDescription = '',
        resultImageUrl = DEFAULT_IMAGE_BG_BIG_URL,
        resultImageDisclaimer = '',
        resultButtonText = '',
        resultButtonLink = '',
    } = {}) {
        this.id = getUniqueId()
        this.text = i18n.t('Sector') + (number ? ` ${number}` : '')
        this.imageUrl = imageUrl
        this.probabilityOfWinning = probabilityOfWinning
        this.resultHeader = resultHeader
        this.resultDescription = resultDescription
        this.resultImageUrl = resultImageUrl
        this.resultImageDisclaimer = resultImageDisclaimer
        this.resultButtonText = resultButtonText
        this.resultButtonLink = resultButtonLink
    }

    static get id() {
        return 'id'
    }

    static get text() {
        return 'text'
    }

    static get imageUrl() {
        return 'imageUrl'
    }

    static get probabilityOfWinning() {
        return 'probabilityOfWinning'
    }

    static get resultHeader() {
        return 'resultHeader'
    }

    static get resultDescription() {
        return 'resultDescription'
    }

    static get resultImageUrl() {
        return 'resultImageUrl'
    }

    static get resultImageDisclaimer() {
        return 'resultImageDisclaimer'
    }

    static get resultButtonText() {
        return 'resultButtonText'
    }

    static get resultButtonLink() {
        return 'resultButtonLink'
    }
}

export const BLOCK_KEYS = {
    PLAYGROUND: 'playground',
    SECTIONS: 'sections',
}

export const BLOCK_KEYS_BY_TABS = {
    [TABS.PLAYGROUND]: BLOCK_KEYS.PLAYGROUND,
    [TABS.SECTIONS]: BLOCK_KEYS.SECTIONS,
}

export function createSections(count = 1, fromNumber = 1) {
    return [...Array(count).keys()].map(index => new Section({ number: index + fromNumber }))
}

export function createWheelSpinBlock() {
    return {
        [BLOCK_KEYS.PLAYGROUND]: new Playground(),
        [BLOCK_KEYS.SECTIONS]: [...Array(DEFAULT_SECTIONS_COUNT).keys()]
            .map(i => i + 1)
            .map(number => new Section({ number })),
    }
}
