import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from './StatusIndicator.module.scss'

function StatusIndicator({ isConnected, hideLed = false }) {
    const { t } = useTranslation()
    const status = isConnected ? t('active') : t('inactive')
    return (
        <div className={styles.indicator}>
            {!hideLed && <div className={classNames(styles.indicatorLight, { [styles.isConnected]: isConnected })} />}
            <p className={styles.indicatorStatus}>{t('Status')}</p>
            <p className={classNames(styles.statusText, { [styles.isConnected]: isConnected })}>{': ' + status}</p>
        </div>
    )
}

export default StatusIndicator
