export const getUint8Array = async (file, allowEmpty = false) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ({ target: { result } }) => {
            if (!allowEmpty && !result.byteLength) {
                reject('File cannot be empty')
                return
            }
            resolve(new Uint8Array(result))
        }
        reader.onerror = () => reject('Reading file error')
        reader.readAsArrayBuffer(file)
    })

export const getFileDataURL = async file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ({ target }) => resolve(target.result)
        reader.onerror = () => reject('Reading file error')
        reader.readAsDataURL(file)
    })

export const getFileDataURLFromUrl = url =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('get', url)
        xhr.responseType = 'blob'
        xhr.onload = function () {
            const reader = new FileReader()
            reader.onload = ({ target }) => resolve(target.result)
            reader.onerror = () => reject('Reading file error')
            reader.readAsDataURL(xhr.response)
        }
        xhr.send()
        xhr.onerror = () => reject('Fetching file error')
    })
