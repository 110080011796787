import { API_URL } from './constants'
import { httpRequest } from './utils'

const sendRequest = async ({ method = 'get', endpoint, body }) => {
    const options = {
        method: method.toUpperCase(),
        headers: {
            'Content-Type': 'application/json',
        },
    }
    if (body) options.body = JSON.stringify(body)

    const response = await httpRequest(`${API_URL}/api/${endpoint}`, options)

    const text = await response.text()

    return text ? JSON.parse(text) : {}
}

const createSession = async (
    clientId,
    projectId,
    projectIdLong,
    { utmCampaign, utmSource, utmMedium, utmContent, referenceTail, sourceReference, embedUrl },
) => {
    const response = await httpRequest(`${API_URL}/api/sessions`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Client-Key': clientId,
        },
        body: JSON.stringify({
            id: null,
            projectId,
            projectIdLong,
            utmCampaign: utmCampaign ? utmCampaign.slice(0, 128) : null,
            utmSource: utmSource ? utmSource.slice(0, 128) : null,
            utmMedium: utmMedium ? utmMedium.slice(0, 128) : null,
            utmContent: utmContent ? utmContent.slice(0, 128) : null,
            referenceTail: referenceTail ? referenceTail.slice(1, 513) : null,
            sourceReference: sourceReference ? sourceReference.slice(0, 512) : null,
            embedUrl: embedUrl ? embedUrl.slice(0, 1024) : null,
        }),
        timeout: 10000,
    })
    return await response.json()
}

const refreshSession = async (clientId, sessionId) => {
    if (!clientId || !sessionId) return null

    await httpRequest(`${API_URL}/api/sessions/${sessionId}/refresh`, {
        method: 'PATCH',
        headers: {
            'Client-Key': clientId,
        },
        timeout: 7000,
    })
}

const getProjectMetaInfo = async hash => {
    const response = await httpRequest(`${API_URL}/api/projects/${hash}/meta`, {
        timeout: 4000,
    })
    return await response.json()
}

const sendProjectReadPercent = async ({ projectNumericId, sessionId, value }) => {
    if (!projectNumericId || !sessionId) return

    await httpRequest(`${API_URL}/api/actions/common`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            actionTypeName: 'READ_PERCENTAGE',
            sessionId,
            projectId: projectNumericId,
            blockId: null,
            fields: {
                percent: value,
            },
        }),
        timeout: 3000,
    })
}

export default {
    sendRequest,
    createSession,
    refreshSession,
    getProjectMetaInfo,
    sendProjectReadPercent,
}
