import i18n from 'i18n'

import { getDate, getShortDate } from 'utils/dateTime'

import IconSubscriptionExpired from 'svg/iconSubscriptionExpired.svg'
import IconActiveUntil from 'svg/iconActiveUntil.svg'
import IconDisabledRecurring from 'svg/iconDisabledRecurring.svg'
import IconWaitingSubscription from 'svg/iconWaitingSubscription.svg'

import { BILLING_CYCLE, BILLING_CYCLE_TYPE } from './constants'

export function convertToStatus(expired, isWaiting) {
    if (isWaiting) return i18n.t('Upgraded')
    return expired ? i18n.t('Expired') : i18n.t('Active')
}

export function getSubscriptionIcon(recurringEnabled, expired, isWaiting) {
    if (isWaiting) return IconWaitingSubscription
    if (expired) return IconSubscriptionExpired
    return recurringEnabled ? IconActiveUntil : IconDisabledRecurring
}

export function formatExpirationDate(billingCycleValue, expirationDate) {
    return billingCycleValue === BILLING_CYCLE.year ? getDate(expirationDate) : getShortDate(expirationDate)
}

export function isExpired(expirationDate) {
    return expirationDate < Date.now()
}

export function formatBillingCycle(billingCycleType, billingCycleValue) {
    if (billingCycleType === BILLING_CYCLE_TYPE.day) return i18n.t('Daily')
    if (billingCycleType === BILLING_CYCLE_TYPE.month) {
        if (billingCycleValue === 12) return i18n.t('Yearly')
        if (billingCycleValue === 1) return i18n.t('Monthly')
        if (billingCycleValue === -1) return i18n.t('N/A')
        return i18n.t('{{count}} months', { count: billingCycleValue })
    }
    return ''
}
