import React, { createRef, useState } from 'react'
import { isEqual } from 'lodash'

import './Preview.scss'

const PADDING = {
    TOP: 8,
    RIGHT: 8,
    BOTTOM: 42,
    LEFT: 8,
}

const MARGIN = {
    TOP: 6,
    RIGHT: 6,
    BOTTOM: 6,
    LEFT: 6,
}

const CONTAINER_SCROLLBAR_WIDTH = 32

const Preview = ({ url, targetRef, note }) => {
    const previewRef = createRef()
    const imageBoxRef = createRef()
    const [resolution, setResolution] = useState(void 0)

    const onLoad = ev => {
        const target = targetRef.current
        const preview = previewRef.current
        const imageBox = imageBoxRef.current
        const image = ev.target

        const targetRect = target.getBoundingClientRect()
        const containerRect = target.parentElement.getBoundingClientRect()

        const top = targetRect.top - containerRect.top
        const left = targetRect.left - containerRect.left
        const bottom = containerRect.bottom - targetRect.bottom
        const right = containerRect.right - CONTAINER_SCROLLBAR_WIDTH - targetRect.right

        const rects = Object.entries({
            topLeft: { width: left + targetRect.width, height: top - MARGIN.BOTTOM },
            top: { width: containerRect.width - CONTAINER_SCROLLBAR_WIDTH, height: top - MARGIN.BOTTOM },
            topRight: { width: right + targetRect.width, height: top - MARGIN.BOTTOM },
            rightTop: { width: right - MARGIN.LEFT, height: top + targetRect.height },
            right: { width: right - MARGIN.LEFT, height: containerRect.height },
            rightBottom: { width: right - MARGIN.LEFT, height: bottom + targetRect.height },
            bottomLeft: { width: left + targetRect.width, height: bottom - MARGIN.TOP },
            bottom: { width: containerRect.width - CONTAINER_SCROLLBAR_WIDTH, height: bottom - MARGIN.TOP },
            bottomRight: { width: right + targetRect.width, height: bottom - MARGIN.TOP },
            leftTop: { width: left - MARGIN.RIGHT, height: top + targetRect.height },
            left: { width: left - MARGIN.RIGHT, height: containerRect.height },
            leftBottom: { width: left - MARGIN.RIGHT, height: bottom + targetRect.height },
        })
            .map(([key, rect]) => [
                key,
                {
                    width: Math.min(rect.width, containerRect.width),
                    height: Math.min(rect.height, containerRect.height),
                },
            ])
            .reduce((obj, [key, value]) => {
                obj[key] = value
                return obj
            }, {})

        const { naturalWidth, naturalHeight } = image

        setResolution({ width: naturalWidth, height: naturalHeight })

        const reduceRectKeyAndScale = () => {
            const [_rectKey, _scale] = Object.entries(rects)
                .map(([key, { width, height }]) => {
                    const widthScale = (width - PADDING.LEFT - PADDING.RIGHT) / naturalWidth
                    const heightScale = (height - PADDING.TOP - PADDING.BOTTOM) / naturalHeight

                    return [key, widthScale < heightScale ? widthScale : heightScale]
                })
                .reduce((max, curr) => (max[1] >= curr[1] ? max : curr))

            const [similar] = Object.entries(rects)
                .filter(([key, rect]) => isEqual(rects[_rectKey], rect))
                .filter(([key]) => key !== _rectKey)

            return [similar ? similar[0] : _rectKey, _scale < 1 ? _scale : 1]
        }

        const [rectKey, scale] = reduceRectKeyAndScale()

        // console.error(rectKey, rects[rectKey])

        const imageWidth = naturalWidth * scale * 0.995
        const imageHeight = naturalHeight * scale * 0.995
        imageBox.style.width = `${imageWidth}px`
        imageBox.style.height = `${imageHeight}px`

        const previewWidth = imageWidth + PADDING.LEFT + PADDING.RIGHT
        const previewHeight = imageHeight + PADDING.TOP + PADDING.BOTTOM

        const verticalCenterOffset = (containerRect.height - previewHeight) / 2 - top
        const horizontalCenterOffset = (containerRect.width - previewWidth) / 2 - left

        const aligns = {
            topLeft: { bottom: '100%', right: '0', marginBottom: '6px' },
            top: { bottom: '100%', left: `${horizontalCenterOffset}px`, marginBottom: '6px' },
            topRight: { bottom: '100%', left: 0, marginBottom: '6px' },
            rightTop: { bottom: '0', left: '100%', marginLeft: '6px' },
            right: { top: `${verticalCenterOffset}px`, left: '100%', marginLeft: '6px' },
            rightBottom: { top: '0', left: '100%', marginLeft: '6px' },
            bottomLeft: { top: '100%', right: '0', marginTop: '6px' },
            bottom: { top: '100%', left: `${horizontalCenterOffset}px`, marginTop: '6px' },
            bottomRight: { top: '100%', left: 0, marginTop: '6px' },
            leftTop: { bottom: '0', right: '100%', marginRight: '6px' },
            left: { top: `${verticalCenterOffset}px`, right: '100%', marginRight: '6px' },
            leftBottom: { top: '0', right: '100%', marginRight: '6px' },
        }

        for (const [style, value] of Object.entries(aligns[rectKey])) {
            preview.style[style] = value
        }

        preview.style.display = 'block'
    }

    return (
        <div ref={previewRef} className="asset-preview" style={{ display: 'none' }}>
            <div ref={imageBoxRef}>
                <img alt="preview" src={url} onLoad={onLoad} style={{ width: 'inherit', height: 'inherit' }} />
            </div>
            <div className="asset-preview__description">
                {note ? (
                    <p className="asset-preview__author">
                        <span>{note}</span>
                    </p>
                ) : (
                    <p>{resolution !== void 0 && `${resolution.width} x ${resolution.height}`}</p>
                )}
            </div>
        </div>
    )
}

export default Preview
