const chars = [
    `\b`,
    `\f`,
    `\n`,
    `\r`,
    `\t`,
    `\``,
    `'`,
    `"`,
    `<`,
    `>`,
    '\\\\',
    '\u0000',
    '\u0001',
    '\u0002',
    '\u0003',
    '\u000b',
]

const forAllStringProperties = (obj, fn) => {
    if (obj) {
        Object.keys(obj).forEach(k => {
            if (typeof obj[k] === 'string') {
                obj[k] = fn(obj[k])
            } else if (typeof obj[k] === 'object') {
                forAllStringProperties(obj[k], fn)
            }
        })
    }
}

/**
 * Recursive encode strings in object
 * @param {object} obj
 */
export const encodeStringsInObject = obj => {
    forAllStringProperties(obj, str => {
        chars.forEach(char => {
            const reg = new RegExp(char, 'g')
            str = str.replace(reg, `U+${char.charCodeAt(0)};`)
        })
        return str
    })
    return obj
}

/**
 * Recursive decode strings in object
 * @param {object} obj
 */
export const decodeStringsInObject = obj => {
    forAllStringProperties(obj, str => {
        chars.forEach(char => {
            const reg = new RegExp(`U\\+${char.charCodeAt(0)};`, 'g')
            if (char === '\\\\') {
                str = str.replace(reg, '\\')
            } else {
                str = str.replace(reg, char)
            }
        })
        return str
    })
    return obj
}

export const decodeString = str => {
    chars.forEach(char => {
        const reg = new RegExp(`U\\+${char.charCodeAt(0)};`, 'g')
        if (char === '\\\\') {
            str = str.replace(reg, '\\')
        } else {
            str = str.replace(reg, char)
        }
    })
    return str
}
