import React from 'react'
import classNames from 'classnames'

import { CARD_OFFSET, COLORS_KEYS } from '../../constants'

import './Card.scss'

const Card = ({ colors, isMobile, children }) => (
    <li
        className={classNames('rank-battle__card', { 'is-mobile': isMobile })}
        style={{
            backgroundColor: colors[COLORS_KEYS.CARD_BACKGROUND],
            '--borderColor': colors[COLORS_KEYS.CARD_LEADER_BORDER],
            margin: CARD_OFFSET,
        }}
    >
        {children}
    </li>
)

export default Card
