import React from 'react'

import Wave from './components/Wave/Wave'

import LikeIcon from './svg/Like'

import './LikeButton.scss'

const LikeButton = ({ color, votesCount }) => (
    <div className="rank-battle__like-button">
        <Wave color={color} votesCount={votesCount} />
        <div className="rank-battle__like-button__icon-wrapper">
            <LikeIcon className="rank-battle__like-button__icon" color={color} />
        </div>
    </div>
)

export default LikeButton
