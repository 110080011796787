import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ORIENTATIONS } from '../../../../../../../components/Modals/CrosswordModal/constants'

import Column from './components/Column/Column'

import styles from './Questions.module.scss'

const Questions = ({ items, fontFamily }) => {
    const { t } = useTranslation()
    const [downQuestions, setDownQuestion] = useState([])
    const [acrossQuestions, setAcrossQuestion] = useState([])

    useEffect(() => {
        setDownQuestion(items.filter(result => result.orientation === ORIENTATIONS.DOWN))
        setAcrossQuestion(items.filter(result => result.orientation === ORIENTATIONS.ACROSS))
    }, [items])

    if (!downQuestions.length && !acrossQuestions.length) return null

    return (
        <ul className={styles.questions}>
            {!!downQuestions.length && <Column title={t('Down')} fontFamily={fontFamily} items={downQuestions} />}
            {!!acrossQuestions.length && <Column title={t('Across')} fontFamily={fontFamily} items={acrossQuestions} />}
        </ul>
    )
}

export default Questions
