import React from 'react'
import { withTranslation } from 'react-i18next'

import icon_Video from 'svg/video.svg'

class YoutubeVideo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            blockData: { t },
            i18n,
        } = this.props
        return (
            <div className={`block __${t}`}>
                <img className="ico" src={icon_Video}></img>
                <p>{i18n.t('Video is displayed in preview mode or after publishing')}</p>
            </div>
        )
    }
}

export default withTranslation('translations')(YoutubeVideo)
