import { useState, useEffect } from 'react'

import { isIdenticalHex, pickColor } from 'utils/colors'

import { OPTIMAL_CARD_WIDTH, CARD_OFFSET, CARD_TYPES, COLORS_KEYS } from '../constants'

const useCards = ({ cardsIds, cardType, colorTheme, containerWidth }) => {
    const [cardsMaxWidth, setCardsMaxWidth] = useState(0)
    const [cardsInRowCount, setCardsInRowCount] = useState(0)
    const [colors, setColors] = useState({
        [COLORS_KEYS.CARD_BACKGROUND]: colorTheme,
        [COLORS_KEYS.CARD_LEADER_BORDER]: colorTheme,
        [COLORS_KEYS.MAIN_TEXT]: colorTheme,
        [COLORS_KEYS.MAIN_TEXT_HIGHLIGHT]: colorTheme,
        [COLORS_KEYS.VOTES_MOBILE_BORDER]: colorTheme,
        [COLORS_KEYS.VOTES_TEXT]: colorTheme,
        [COLORS_KEYS.VOTES_LIKE_ICON]: colorTheme,
        [COLORS_KEYS.VOTES_CHECK_ICON]: colorTheme,
    })

    useEffect(() => {
        const newColors = {
            [COLORS_KEYS.CARD_BACKGROUND]: colorTheme,
        }

        // Card
        if (cardType === CARD_TYPES.TEXT) {
            newColors[COLORS_KEYS.CARD_LEADER_BORDER] = pickColor(colorTheme, '#3C3C3C', '#fff')
        } else {
            if (isIdenticalHex(colorTheme, '#fff')) {
                newColors[COLORS_KEYS.CARD_LEADER_BORDER] = '#3C3C3C'
            } else {
                newColors[COLORS_KEYS.CARD_LEADER_BORDER] = colorTheme
            }
        }

        // Main
        newColors[COLORS_KEYS.MAIN_TEXT] = pickColor(colorTheme, '#3C3C3C', '#fff')
        if (isIdenticalHex(colorTheme, '#fff')) {
            newColors[COLORS_KEYS.MAIN_TEXT_HIGHLIGHT] = '#3C3C3C'
        } else {
            newColors[COLORS_KEYS.MAIN_TEXT_HIGHLIGHT] = '#fff'
        }

        // Votes
        if (cardType === CARD_TYPES.TEXT) {
            newColors[COLORS_KEYS.VOTES_MOBILE_BORDER] = pickColor(colorTheme, '#3C3C3C', '#fff')
        }
        newColors[COLORS_KEYS.VOTES_LIKE_ICON] = pickColor(colorTheme, '#3C3C3C', colorTheme)
        newColors[COLORS_KEYS.VOTES_CHECK_ICON] = pickColor(colorTheme, '#3C3C3C', '#fff')
        newColors[COLORS_KEYS.VOTES_TEXT] = pickColor(colorTheme, '#3C3C3C', '#fff')

        setColors(prev => ({ ...prev, ...newColors }))
    }, [colorTheme, cardType, cardsInRowCount])

    useEffect(() => {
        const cardsCount = cardsIds.length

        if (containerWidth < OPTIMAL_CARD_WIDTH * 2 + CARD_OFFSET * 2) {
            setCardsMaxWidth('unset')
            setCardsInRowCount(1)
            return
        }

        if (cardsCount === 4) {
            if (containerWidth > OPTIMAL_CARD_WIDTH * 4 + CARD_OFFSET * 2 * 3 + CARD_OFFSET * 2) {
                setCardsMaxWidth(OPTIMAL_CARD_WIDTH * 4 + CARD_OFFSET * 2 * 3 + CARD_OFFSET * 2)
                setCardsInRowCount(4)
            } else {
                setCardsMaxWidth(OPTIMAL_CARD_WIDTH * 2 + CARD_OFFSET * 2 + CARD_OFFSET * 2)
                setCardsInRowCount(2)
            }
            return
        }

        if (cardsCount === 3) {
            if (containerWidth > OPTIMAL_CARD_WIDTH * 3 + CARD_OFFSET * 2 * 2 + CARD_OFFSET * 2) {
                setCardsMaxWidth(OPTIMAL_CARD_WIDTH * 3 + CARD_OFFSET * 2 * 2 + CARD_OFFSET * 2)
                setCardsInRowCount(3)
            } else {
                setCardsMaxWidth(containerWidth + CARD_OFFSET * 2)
                setCardsInRowCount(3)
            }
            return
        }

        if (cardsCount === 2) {
            setCardsMaxWidth(OPTIMAL_CARD_WIDTH * 2 + CARD_OFFSET * 2 + CARD_OFFSET * 2)
            setCardsInRowCount(2)
            return
        }

        setCardsMaxWidth(containerWidth)
        setCardsInRowCount(1)
    }, [cardsIds.length, containerWidth])

    return {
        cardsInRowCount,
        cardsMaxWidth,
        colors,
    }
}

export default useCards
