import React, { useState, useRef, useEffect } from 'react'
import autosize from 'autosize'
import classNames from 'classnames'

import { filterDataAttr } from 'common/utils/attributes'

import './TextArea.scss'

const TextArea = ({
    className = '',
    label = '',
    value,
    onUpdate,
    rows = 4,
    errorMessages = [],
    maxLength,
    isRequired,
    isDisabled,
    isReadOnly = false,
    wrapLines = true,
    autoFocus,
    onFocus = () => {
        /**/
    },
    styles = {},
    wrapperStyles = {},
    isAutoSize = true,
    labelClassName,
    placeholder,
    ...rest
}) => {
    const [_value, _setValue] = useState(value)
    const textAreaRef = useRef(null)

    useEffect(() => {
        if (isAutoSize && textAreaRef.current) autosize(textAreaRef.current)
    }, [])

    useEffect(() => {
        _setValue(value)
    }, [value])

    const onChange = evt => {
        if (!isDisabled) {
            _setValue(evt.target.value)
            try {
                onUpdate(evt.target.value)
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <div
            className={`common-text-area ${isDisabled ? 'common-text-area__disabled' : ''} ${className}`}
            style={wrapperStyles}
        >
            {!!label.length && (
                <p
                    className={classNames('common-text-area__label', {
                        'common-text-area__required': isRequired,
                        [labelClassName]: !!labelClassName,
                    })}
                    title={label}
                >
                    <span className="common-text-area__label-text">{label}</span>
                    {!!maxLength && (
                        <span className="common-text-area__maxLength">
                            {_value.length}/{maxLength}
                        </span>
                    )}
                </p>
            )}
            <textarea
                style={styles}
                autoFocus={autoFocus}
                ref={textAreaRef}
                className={`common-text-area__text-area ${errorMessages.length ? 'is-error' : ''}`}
                rows={rows}
                value={_value}
                onChange={onChange}
                maxLength={maxLength}
                disabled={isDisabled}
                readOnly={isReadOnly}
                onFocus={onFocus}
                wrap={wrapLines ? 'soft' : 'off'}
                placeholder={placeholder}
                {...filterDataAttr(rest)}
            />
            {errorMessages.length
                ? errorMessages.map((message, i) => (
                      <p key={i} className="common-text-area__error">
                          {message}
                      </p>
                  ))
                : null}
        </div>
    )
}

export default TextArea
