import React from 'react'

import './Footer.scss'

const Footer = ({ actionText, cancelText, noteIcon, noteText, onAction, onClose, isProcessing = false }) => {
    return (
        <div className="modal-footer">
            <div className="modal-footer__note">
                {noteIcon ? (
                    <div className="icon">
                        <img src={noteIcon} alt="" />
                    </div>
                ) : null}
                {noteText && <p>{noteText}</p>}
            </div>
            <div className="modal-footer__actions">
                {onClose && cancelText && (
                    <button
                        className="button"
                        disabled={isProcessing}
                        data-variant="secondary"
                        data-size="lg"
                        onClick={() => onClose()}
                    >
                        {cancelText}
                    </button>
                )}
                {onAction && actionText && (
                    <button
                        className="button"
                        disabled={isProcessing}
                        data-variant="primary"
                        data-size="lg"
                        data-test="dialog-save"
                        onClick={() => onAction()}
                    >
                        {actionText}
                    </button>
                )}
            </div>
        </div>
    )
}

export default Footer
