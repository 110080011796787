import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import EyeSVG from './svg/Eye'
import EyeClosedSVG from './svg/EyeClosed'

import styles from './PasswordInput.module.scss'

const EyeBox = ({ t, isOpened, onChange }) => (
    <div
        className={styles.eyeBox}
        onClick={() => onChange()}
        title={isOpened ? t('Hide password') : t('Show password')}
    >
        {isOpened ? <EyeSVG /> : <EyeClosedSVG />}
    </div>
)

const PasswordInput = ({ ...props }) => {
    const { t } = useTranslation()
    const [isShowSymbols, setIsShowSymbols] = useState(false)

    return (
        <TextInput
            {...props}
            controlType={isShowSymbols ? 'text' : 'password'}
            className={classNames(styles.input, { [props.className]: !!props?.className })}
            action={<EyeBox t={t} isOpened={isShowSymbols} onChange={() => setIsShowSymbols(!isShowSymbols)} />}
        />
    )
}

export default PasswordInput
