import { DEFAULT_IMAGE_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'

export class TreasureHuntTile {
    id // <String> id
    overlaySrc // <null/String[URL]> image
    color // <null/String[HEX]> color
    headerImgSrc // <null/String[URL]> image
    quest // <String> text
    questDescription // <String> text
    password // <String> text
    buttonText // <String> text
    buttonLink // <String> text

    constructor({
        quest = 'Quest',
        questDescription = '',
        overlaySrc = null,
        color = null,
        headerImgSrc = DEFAULT_IMAGE_URL,
        password = 'password',
        buttonText = 'Open',
        buttonLink = '',
    } = {}) {
        this.id = getUniqueId()
        this.overlaySrc = overlaySrc
        this.color = color
        this.headerImgSrc = headerImgSrc
        this.quest = quest
        this.questDescription = questDescription
        this.password = password
        this.buttonText = buttonText
        this.buttonLink = buttonLink
    }

    static get quest() {
        return 'quest'
    }

    static get questDescription() {
        return 'questDescription'
    }

    static get password() {
        return 'password'
    }

    static get buttonText() {
        return 'buttonText'
    }

    static get buttonLink() {
        return 'buttonLink'
    }

    static get overlaySrc() {
        return 'overlaySrc'
    }

    static get color() {
        return 'color'
    }

    static get headerImgSrc() {
        return 'headerImgSrc'
    }
}
