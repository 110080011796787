import {
    createCollector,
    createValidator,
    defined,
    dependOn,
    itemRules,
    maxLength,
    minLength,
    required,
    isIntegerNumber,
    minNumber,
} from 'shared/validation'
import {
    MAX_ANSWERS_IN_QUESTION,
    MAX_QUESTIONS,
    MIN_ANSWERS_IN_QUESTION,
    MIN_CORRECT_ANSWERS_IN_QUESTION,
    MIN_INCORRECT_ANSWERS_IN_QUESTION,
    MIN_QUESTIONS,
    MIN_RESULTS,
    TAB_TO_FIELD_MAP,
} from './constants'

function convertItemErrorsToMap(items, errors) {
    return new Map(
        items.map((item, index) => {
            return [item.id, errors[index]]
        }),
    )
}

const answerRules = {
    id: [required()],
    text: [dependOn(({ parent }) => (parent.isText ? required() : null)), maxLength(500)],
    imageLabel: [maxLength(40)],
    image: [dependOn(({ parent }) => (parent.isText ? required() : null))],
    imageDescription: [defined()],
    isCorrect: [defined()],
}

const questionRules = {
    id: [required()],
    text: [required(), maxLength(500)],
    image: [defined()],
    imageDisclaimer: [maxLength(100)],
    answers: [
        itemRules(answerRules, convertItemErrorsToMap),
        minLength(MIN_ANSWERS_IN_QUESTION),
        maxLength(MAX_ANSWERS_IN_QUESTION),
    ],
}

const resultRules = {
    id: [required()],
    header: [required(), maxLength(500)],
    description: [maxLength(5000)],
    image: [defined()],
    imageDisclaimer: [maxLength(100)],
    buttonText: [],
    buttonLink: [],
}

const TRIVIA_RULES_FIELDS = {
    cover: {
        isShowCover: [defined()],
        header: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(400),
        ],
        description: [maxLength(500)],
        buttonText: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
            maxLength(20),
        ],
        image: [defined()],
        imageDisclaimer: [maxLength(100)],
    },
    questionBank: {
        questionsCount: [
            dependOn(({ self }) => (self.bankId ? required() : null)),
            dependOn(({ self }) => (self.bankId ? isIntegerNumber() : null)),
            dependOn(({ self }) => (self.bankId ? minNumber(1) : null)),
        ],
    },
    questions: [
        dependOn(({ root }) => (!root.questionBank?.bankId ? itemRules(questionRules, convertItemErrorsToMap) : null)),
        dependOn(({ root }) => (!root.questionBank?.bankId ? minLength(MIN_QUESTIONS) : null)),
        dependOn(({ root }) => (!root.questionBank?.bankId ? maxLength(MAX_QUESTIONS) : null)),
    ],
    results: [itemRules(resultRules, convertItemErrorsToMap), minLength(MIN_RESULTS)],
}

export const validateTabs = (tmpStructure, activeTab) => {
    const activeTabField = TAB_TO_FIELD_MAP[activeTab] || null
    const isValid = createValidator(TRIVIA_RULES_FIELDS)(tmpStructure, activeTabField)
    const errors = createCollector(TRIVIA_RULES_FIELDS)(tmpStructure, activeTabField)

    return {
        tmpStructure,
        errors,
        hasError: !isValid,
    }
}

export function calculateResultDistribution(maxNumberOfOptions, numberOfResults) {
    const result = []
    if (maxNumberOfOptions % numberOfResults === 0) {
        const step = maxNumberOfOptions / numberOfResults
        let from = 0
        for (let i = 0; i < numberOfResults; i++) {
            if (i <= maxNumberOfOptions - 1) {
                const to = i !== numberOfResults - 1 ? from + step - 1 : maxNumberOfOptions - 1
                result.push({
                    isVisible: true,
                    from: from,
                    to: to,
                })
                from = to + 1
            } else {
                result.push({
                    isVisible: false,
                })
            }
        }
    } else {
        let checkChain = true
        let step = Math.ceil(maxNumberOfOptions / numberOfResults)
        let from = 0
        for (let i = 0; i < numberOfResults; i++) {
            if (i <= maxNumberOfOptions - 1) {
                if (checkChain && (maxNumberOfOptions - i) % numberOfResults === 0) {
                    step = (maxNumberOfOptions - i) / numberOfResults
                    checkChain = false
                }
                const to = from + step - 1
                result.push({
                    isVisible: true,
                    from: from,
                    to: to,
                })
                from = to + 1
            } else {
                result.push({
                    isVisible: false,
                })
            }
        }
    }
    return result
}

export function isMinAnswerOptionLimitExceeded(answers) {
    const incorrectAnswerCount = answers.filter(el => !el.isCorrect).length
    const correctAnswerCount = answers.length - incorrectAnswerCount
    const isMinIncorrectAnswerExceeded = incorrectAnswerCount < MIN_INCORRECT_ANSWERS_IN_QUESTION
    const isMinCorrectAnswerExceeded = correctAnswerCount < MIN_CORRECT_ANSWERS_IN_QUESTION
    return isMinIncorrectAnswerExceeded || isMinCorrectAnswerExceeded
}
