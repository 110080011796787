import React from 'react'

import styles from './Table.module.scss'

const Table = ({ data, columns }) => {
    return (
        <div className={styles.table}>
            <ul className={styles.header}>
                {columns.map(column => (
                    <li className={styles.headerColumn} style={{ width: `${column.width}%` }}>
                        {column.label}
                    </li>
                ))}
            </ul>
            <ul className={styles.data}>
                {data.map(item => (
                    <li className={styles.dataRow}>
                        <ul className={styles.dataColumn}>
                            {columns.map(column => (
                                <li className={styles.dataColumnValue} style={{ width: `${column.width}%` }}>
                                    <div className={styles.mobileTitle}>{column.label}</div>
                                    {column.component(item)}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Table
