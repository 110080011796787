import React from 'react'
import 'react-quill/dist/quill.snow.css'
import Text from '../components/Text/Text'
import Image from 'components/Form/Image'

class Timeline extends React.Component {
    constructor(props) {
        super(props)
    }

    updateBlockData = (propName, propValue) => {
        this.props.onUpdate({
            [this.props.id]: {
                data: { [propName]: propValue },
            },
        })
    }

    handleTitleChange = event => {
        this.updateBlockData(event.target.name, event.target.value)
    }

    render() {
        const { wP_bg, isTransparentBackground, markTitle, markSubtitle, imageUrl, imageDescription } = this.props

        return (
            <div
                className="block timeline-block"
                style={{ backgroundColor: isTransparentBackground ? 'transparent' : wP_bg }}
            >
                <div className="timeline-block__mark">
                    <input
                        className="timeline-block__mark-title-input"
                        type="text"
                        name={'markTitle'}
                        value={markTitle}
                        onChange={this.handleTitleChange}
                    />
                    <input
                        className="timeline-block__mark-subtitle-input"
                        type="text"
                        name={'markSubtitle'}
                        value={markSubtitle}
                        onChange={this.handleTitleChange}
                    />
                </div>
                <div className="timeline-block__content">
                    {!!imageUrl && (
                        <div className="timeline-block__image-with-description">
                            <div className="timeline-block__image-container">
                                <Image className="timeline-block__image" src={imageUrl} />
                            </div>
                            {!!imageDescription && (
                                <div className="timeline-block__image-description">{imageDescription}</div>
                            )}
                        </div>
                    )}
                    <div className="timeline-block__text">
                        <Text {...this.props}></Text>
                    </div>
                </div>
            </div>
        )
    }
}

export default Timeline
