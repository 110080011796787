import React, { useState, useRef, useEffect } from 'react'
import autosize from 'autosize'

import Tooltip from 'components/Tooltip/Tooltip'

import { isValue } from 'utils/validator'

const TextArea = ({ value, tooltipText, schema, onUpdate, id, name, payload }) => {
    const textAreaRef = useRef(null)

    const [_value, setValue] = useState(value || '')
    const [additionalParams, setAdditionalParams] = useState({})

    useEffect(() => {
        setValue(value)
    }, [value])

    useEffect(() => {
        autosize(textAreaRef.current)
    }, [])

    useEffect(() => {
        const newAdditionalParams = {}
        if (isValue(schema.maxLength)) newAdditionalParams.maxLength = schema.maxLength
        setAdditionalParams(newAdditionalParams)
    }, [schema.maxLength])

    const onChange = evt => {
        setValue(evt.target.value)
        onUpdate(id, { [name]: evt.target.value }, payload)
    }

    return (
        <div className="control-box">
            {!!schema.label?.length && (
                <p className="label">
                    {schema.label}
                    {<Tooltip tooltipText={tooltipText} />}
                </p>
            )}
            <div className="content textarea">
                <textarea ref={textAreaRef} rows={5} value={_value} onChange={onChange} {...additionalParams} />
            </div>
        </div>
    )
}

export default TextArea
