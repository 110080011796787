import React from 'react'

import './LoaderIcon.scss'

const LoaderIcon = ({ color = '#797979' }) => (
    <div className="loader-modal__wrapper">
        <div className="lds-ellipsis">
            <div style={{ backgroundColor: color }} />
            <div style={{ backgroundColor: color }} />
            <div style={{ backgroundColor: color }} />
            <div style={{ backgroundColor: color }} />
        </div>
    </div>
)

export default LoaderIcon
