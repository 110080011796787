import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import Search from '../../components/Search'
import PlaceBar from '../../components/PlaceBar'
import MediaGrid from '../../components/MediaGrid/MediaGrid'

import './MyLibrary.scss'

import {
    ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID,
    ASSET_MANAGER__LIBRARY__SET_SEARCH,
    ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID,
    ASSET_MANAGER__MY_LIBRARY__DELETE_IMAGE,
    ASSET_MANAGER__MY_LIBRARY__UP_IMAGES_PAGE,
} from 'store/actions/asset_manager'

const MyLibrary = ({ onPlaceItem }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { list, selectedItemId, search, isLoadList, isLoadError } = useSelector(state => state.assetManager.library)
    const filteredList = useMemo(() => list.content.filter(({ name }) => name.includes(search)), [list, search])

    const placeImage = useCallback(() => {
        const [{ normalUrl = '', authorInfo, id } = {}] = filteredList.filter(
            item => item.originalItemId === selectedItemId,
        )

        onPlaceItem({ src: normalUrl, id, author: authorInfo }, false)
        dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(null))
    }, [onPlaceItem, filteredList, selectedItemId])

    return (
        <div className="my-library">
            <div className="my-library__header">
                <h1>{t('My library')}</h1>
            </div>
            <Search onSearchValueChange={value => dispatch(ASSET_MANAGER__LIBRARY__SET_SEARCH(value))} />
            <MediaGrid
                data={filteredList}
                selectedId={selectedItemId}
                isLoadData={isLoadList}
                isCanLoadData={!list.last && !isLoadError}
                isShowItemDetails
                onUpPage={() => dispatch(ASSET_MANAGER__MY_LIBRARY__UP_IMAGES_PAGE())}
                onDeleteImage={id => dispatch(ASSET_MANAGER__MY_LIBRARY__DELETE_IMAGE(id))}
                onSelectImage={id => dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(id))}
                onUnselectImage={id => dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))}
            />
            {!!selectedItemId && <PlaceBar onClick={placeImage} />}
        </div>
    )
}

export default MyLibrary
