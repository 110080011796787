import React from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextInput from 'components/Form/TextInput/TextInput'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import Select from 'components/Form/Select/Select'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import CardProportions from 'components/Form/CardProportions/CardProportions'

import MemoryPlaygroundPreview from '../../components/MemoryPlaygroundPreview/MemoryPlaygroundPreview'

import { CARD_LAYOUT_TEMPLATE, PLAYGROUND_FIELDS } from '../../utils/constants'

import './Playground.scss'

const Playground = ({ structure, colorTheme, t, validateErrors, methods: { updateStructure } }) => {
    return (
        <div className="memory-modal-playground">
            <div className="memory-modal-playground__workplace-wrapper">
                <ScrollableBlock>
                    <div className="memory-modal-playground__workplace">
                        <h3 className="memory-modal-playground__workplace-title">{t('Playground')}</h3>
                        <div className="memory-modal-playground__workplace-content">
                            <div className="memory-modal-playground__layout-row">
                                <Select
                                    label={t('Layout')}
                                    value={structure.playground.cardLayout}
                                    onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.cardLayout]: value })}
                                    options={CARD_LAYOUT_TEMPLATE}
                                />
                                <UploadImage
                                    label={t('Playing card back')}
                                    isDisabledDelete
                                    value={structure.playground.cardBackImage}
                                    onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.cardBackImage]: value })}
                                />
                            </div>
                            <CardProportions
                                label={t('Card proportions')}
                                value={structure.playground.cardProportions}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.cardProportions]: value })}
                            />
                            <Checkbox
                                label={t('Show cover')}
                                value={structure.playground.isShowCover}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.isShowCover]: value })}
                            />
                            {structure.playground.isShowCover && (
                                <>
                                    <TextInput
                                        label={t('Header')}
                                        isRequired
                                        value={structure.playground.coverHeader}
                                        errorMessages={validateErrors.playground.coverHeader}
                                        onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.coverHeader]: value })}
                                    />
                                    <TextInput
                                        label={t('Button text')}
                                        isRequired
                                        value={structure.playground.coverButtonText}
                                        errorMessages={validateErrors.playground.coverButtonText}
                                        onUpdate={value =>
                                            updateStructure({ [PLAYGROUND_FIELDS.coverButtonText]: value })
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className="memory-modal-playground__preview-wrapper">
                <div className="memory-modal-playground__preview">
                    <p className="memory-modal-playground__preview-title">{t('Approximate preview')}</p>
                    <MemoryPlaygroundPreview
                        data={{
                            colorTheme: colorTheme,
                            cardLayout: structure.playground.cardLayout,
                            cardBackImage: structure.playground.cardBackImage,
                            cardProportions: structure.playground.cardProportions,
                            isShowCover: structure.playground.isShowCover,
                            coverHeader: structure.playground.coverHeader,
                            coverButtonText: structure.playground.coverButtonText,
                        }}
                        isModal
                    />
                </div>
            </div>
        </div>
    )
}

export default Playground
