import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SubscriptionExpiredModal } from 'components/Modal/TrialEndedModal/SubscriptionExpiredModal'
import BaseDialog from 'components/Modal/BaseDialog/BaseDialog'
import EducationTransferringToNewFreePlanModal from 'components/Modal/TrialExtensionModal/EducationTransferringToNewFreePlanModal'
import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog'

import { isEducation } from 'common/utils/permissions'

import { getFullUrlToSsr } from 'utils/router'

import CreateFolderModal from './CreateFolderModal/CreateFolderModal'
import DeleteProjectModal from './DeleteProjectModal/DeleteProjectModal'
import DeleteTemplateModal from './DeleteTemplateModal/DeleteTemplateModal'
import ManageCustomDomainModal from './ManageCustomDomainModal/ManageCustomDomainModal'
import MoveFolderModal from './MoveFolderModal/MoveFolderModal'
import MoveProjectToFolderModal from './MoveProjectToFolderModal/MoveProjectToFolderModal'
import ShareProjectModal from './ShareProjectModal/ShareProjectModal'
import ManageTeamModal from './ManageTeamModal/ManageTeamModal'
import InvitationSentByEmailModal from './InvitationSentByEmailModal/InvitationSentByEmailModal'
import DecisionOrganizationInviteModal from './DecisionOrganizationInviteModal/DecisionOrganizationInviteModal'
import UpgradeProjectTariff from './UpgradeProjectTariff/UpgradeProjectTariff'
import UpgradeSuccessful from './UpgradeSuccessful/UpgradeSuccessful'
import CreateTemplateModal from './CreateTemplateModal/CreateTemplateModal'
import EditTemplateModal from './EditTemplateModal/EditTemplateModal'
import ManageMultiplayerGameModal from './ManageMultiplayerGame/ManageMultiplayerGame'

import arrowIcon from './i/arrow.svg'

export const MODAL_TYPES = {
    manageCustomDomains: 'manageCustomDomains',
    deleteProjectModal: 'deleteProjectModal',
    deleteTemplateModal: 'deleteTemplateModal',
    subscriptionExpired: 'subscriptionExpired',
    educationModal: 'educationModal',
    publishModal: 'publishModal',
    shareModal: 'shareModal',
    manageTeamModal: 'manageTeamModal',
    invitationSentByEmailModal: 'invitationSentByEmailModal',
    decisionOrganizationInviteModal: 'decisionOrganizationInviteModal',
    upgradeDialog: 'upgradeDialog',
    createFolder: 'createFolder',
    moveFolder: 'moveFolder',
    moveProjectToFolder: 'moveProjectToFolder',
    upgradeProjectTariff: 'upgradeProjectTariff',
    upgradeSuccessful: 'upgradeSuccessful',
    createTemplateModal: 'createTemplateModal',
    successCreateTemplateModal: 'successCreateTemplateModal',
    editTemplateModal: 'editTemplateModal',
    successEditTemplateModal: 'successEditTemplateModal',
    manageMultiplayerGameModal: 'manageMultiplayerGameModal',
}
const ModalController = ({
    openedModals = [],
    onCloseModal,
    onDeleteProject,
    onDeleteTemplate,
    onOpenModal,
    onLoadProjects,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { user_details } = useSelector(state => state)

    return Array.isArray(openedModals)
        ? openedModals.map((modal = {}) => {
              switch (modal.type) {
                  case MODAL_TYPES.deleteProjectModal: {
                      return (
                          <DeleteProjectModal
                              key={modal.type}
                              data={modal.payload}
                              onCloseModal={() => onCloseModal(MODAL_TYPES.deleteProjectModal)}
                              onDeleteProject={onDeleteProject}
                          />
                      )
                  }
                  case MODAL_TYPES.deleteTemplateModal: {
                      return (
                          <DeleteTemplateModal
                              key={modal.type}
                              data={modal.payload}
                              onCloseModal={() => onCloseModal(MODAL_TYPES.deleteTemplateModal)}
                              onDeleteTemplate={onDeleteTemplate}
                          />
                      )
                  }
                  case MODAL_TYPES.subscriptionExpired: {
                      return (
                          <SubscriptionExpiredModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.subscriptionExpired)}
                              data={modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.educationModal: {
                      return (
                          <EducationTransferringToNewFreePlanModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.educationModal)}
                          />
                      )
                  }
                  case MODAL_TYPES.publishModal: {
                      return (
                          <ShareProjectModal
                              key={modal.type}
                              isPublished
                              title={t('Your project has been published!')}
                              data={modal.payload}
                              onClose={() => onCloseModal(MODAL_TYPES.publishModal)}
                              onOpenCreateTemplateModal={project =>
                                  onOpenModal(MODAL_TYPES.createTemplateModal, project)
                              }
                          />
                      )
                  }
                  case MODAL_TYPES.shareModal: {
                      return (
                          <ShareProjectModal
                              key={modal.type}
                              title={
                                  isEducation()
                                      ? t('Share, print or embed your project')
                                      : t('Share or embed your project')
                              }
                              data={modal.payload}
                              onClose={() => onCloseModal(MODAL_TYPES.shareModal)}
                              onOpenCreateTemplateModal={project =>
                                  onOpenModal(MODAL_TYPES.createTemplateModal, project)
                              }
                          />
                      )
                  }
                  case MODAL_TYPES.createTemplateModal: {
                      return (
                          <CreateTemplateModal
                              key={modal.type}
                              data={modal.payload}
                              onLoadProjects={onLoadProjects}
                              onClose={() => onCloseModal(MODAL_TYPES.createTemplateModal)}
                              onSuccess={() => {
                                  onCloseModal(MODAL_TYPES.createTemplateModal)
                                  onOpenModal(MODAL_TYPES.successCreateTemplateModal)
                              }}
                          />
                      )
                  }
                  case MODAL_TYPES.successCreateTemplateModal: {
                      return (
                          <BaseDialog
                              key={modal.type}
                              title={t('Your template is published in Interacty gallery')}
                              onClose={() => onCloseModal(MODAL_TYPES.successCreateTemplateModal)}
                              bottomText={
                                  <Trans
                                      i18nKey="The project will appear on the <lnkCommunityPage>Community page</lnkCommunityPage> after approval by the moderators. <lnkReadMore>Read more</lnkReadMore>"
                                      components={{
                                          lnkCommunityPage: <a href={getFullUrlToSsr('/community')} target="_blank" />,
                                          lnkReadMore: (
                                              <a
                                                  href="https://help.interacty.me/en/content/how-do-templates-get-on-the-community-page"
                                                  target="_blank"
                                              />
                                          ),
                                      }}
                                  />
                              }
                          >
                              <button
                                  className="button"
                                  data-size="md"
                                  data-variant="primary"
                                  onClick={() => window.open(getFullUrlToSsr(`/authors/${user_details.id}`), '_blank')}
                              >
                                  {t('View on my public page')}
                                  <img src={arrowIcon} alt="arrow" style={{ marginLeft: 10 }} />
                              </button>
                          </BaseDialog>
                      )
                  }
                  case MODAL_TYPES.editTemplateModal: {
                      return (
                          <EditTemplateModal
                              key={modal.type}
                              data={modal.payload}
                              onLoadProjects={onLoadProjects}
                              onClose={() => onCloseModal(MODAL_TYPES.editTemplateModal)}
                              onSuccess={() => {
                                  onCloseModal(MODAL_TYPES.editTemplateModal)
                                  onOpenModal(MODAL_TYPES.successEditTemplateModal)
                              }}
                          />
                      )
                  }
                  case MODAL_TYPES.successEditTemplateModal: {
                      return (
                          <BaseDialog
                              key={modal.type}
                              title={t('Your template is updated in Interacty gallery')}
                              onClose={() => onCloseModal(MODAL_TYPES.successEditTemplateModal)}
                          >
                              <button
                                  className="button"
                                  data-size="md"
                                  data-variant="primary"
                                  onClick={() => window.open(getFullUrlToSsr(`/authors/${user_details.id}`), '_blank')}
                              >
                                  {t('View on my public page')}
                                  <img src={arrowIcon} alt="arrow" style={{ marginLeft: 10 }} />
                              </button>
                          </BaseDialog>
                      )
                  }
                  case MODAL_TYPES.manageTeamModal: {
                      return (
                          <ManageTeamModal
                              key={modal.type}
                              onOpenModal={onOpenModal}
                              onClose={() => onCloseModal(MODAL_TYPES.manageTeamModal)}
                          />
                      )
                  }
                  case MODAL_TYPES.invitationSentByEmailModal: {
                      return (
                          <InvitationSentByEmailModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.invitationSentByEmailModal)}
                          />
                      )
                  }
                  case MODAL_TYPES.decisionOrganizationInviteModal: {
                      return (
                          <DecisionOrganizationInviteModal
                              key={modal.type}
                              data={modal.payload}
                              onClose={() => onCloseModal(MODAL_TYPES.decisionOrganizationInviteModal)}
                          />
                      )
                  }
                  case MODAL_TYPES.upgradeDialog: {
                      return (
                          <UpgradeDialog
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.upgradeDialog)}
                              onUpgrade={() => history.push('/billing-and-payments?tab=subscription')}
                              data={modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.manageCustomDomains: {
                      return (
                          <ManageCustomDomainModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.manageCustomDomains)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.createFolder: {
                      return (
                          <CreateFolderModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.createFolder)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.moveFolder: {
                      return (
                          <MoveFolderModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.moveFolder)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.moveProjectToFolder: {
                      return (
                          <MoveProjectToFolderModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.moveProjectToFolder)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.upgradeProjectTariff: {
                      return (
                          <UpgradeProjectTariff
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.upgradeProjectTariff)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.upgradeSuccessful: {
                      return (
                          <UpgradeSuccessful
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.upgradeSuccessful)}
                              {...modal.payload}
                          />
                      )
                  }
                  case MODAL_TYPES.manageMultiplayerGameModal: {
                      return (
                          <ManageMultiplayerGameModal
                              key={modal.type}
                              onClose={() => onCloseModal(MODAL_TYPES.manageMultiplayerGameModal)}
                              {...modal.payload}
                          />
                      )
                  }

                  default: {
                      return null
                  }
              }
          })
        : null
}

export default ModalController
