import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import { FinalScreenTab } from 'components/ModalTabs/FinalScreenTab'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import { Playground } from './views'
import { INIT_STRUCTURE, TABS, TABS_ARRAY, validateTabs } from './utils'

import './PuzzleModal.scss'

export const PuzzleModal = ({ methods, data: { structure, colorTheme, id } }) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(cloneDeep(INIT_STRUCTURE))
    const [activeTab, setActiveTab] = useState(TABS.playground)
    const [tabsValidationResult, setTabValidationResult] = useState({
        isCurrentTab: false,
        errors: { playground: {}, finalScreen: {} },
    })

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useEffect(() => {
        if (Object.keys(structure).length) {
            setTmpStructure(cloneDeep(structure))
        }
    }, [structure])

    const onSave = async () => {
        const validationResult = validateTabs(tmpStructure)

        if (!validationResult.hasError) {
            await methods.save({
                [id]: {
                    data: {
                        struct: validationResult.tmpStructure,
                    },
                },
            })
            onClose()
        } else {
            setTabValidationResult({ isCurrentTab: false, errors: validationResult.errors })
            setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
        }
    }

    const onClose = () => {
        methods.closeModal()
    }

    const onUpdateStructure = type => newFields => {
        setTmpStructure(prevState => {
            return {
                ...prevState,
                [type]: {
                    ...prevState[type],
                    ...newFields,
                },
            }
        })
    }

    const onChangeTab = useCallback(
        tabId => {
            const validationResult = validateTabs(tmpStructure, activeTab)
            if (validationResult.activeTabHasError) {
                setTabValidationResult({
                    isCurrentTab: validationResult.activeTabHasError,
                    errors: { ...tabsValidationResult.errors, ...validationResult.errors },
                })
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            } else {
                setTabValidationResult({
                    isCurrentTab: validationResult.activeTabHasError,
                    errors: { ...tabsValidationResult.errors, ...validationResult.errors },
                })
                setActiveTab(tabId)
            }
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Puzzle Options')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'PUZZLE',
                }}
                onSave={onSave}
            >
                <div className="puzzle-modal-wrapper">
                    <div className="puzzle-modal-wrapper__body">
                        {activeTab === TABS.playground && (
                            <Playground
                                structure={tmpStructure}
                                colorTheme={colorTheme}
                                validateErrors={tabsValidationResult.errors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('playground') }}
                            />
                        )}
                        {activeTab === TABS.finalScreen && (
                            <FinalScreenTab
                                structure={tmpStructure}
                                validateErrors={tabsValidationResult.errors}
                                methods={{ ...methods, updateStructure: onUpdateStructure('finalScreen') }}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}
