import { cloneDeep } from 'lodash'
import { SIZE_IMAGES } from 'components/AssetManager/utils/constants.js'

export const mapListOnStatus = list =>
    list.reduce((acc, [id, info]) => {
        if (!acc[info.status]) {
            acc[info.status] = [info]
        } else {
            acc[info.status].push(info)
        }
        return acc
    }, {})

export const defineMeasure = num => {
    const len = num.toString().length

    if (len <= 6) {
        return Math.trunc(num / 1024) + ' KB'
    } else if (len <= 8) {
        return (num / 1024 / 1024).toFixed(1) + ' MB'
    }

    return null
}

export const getFileNameAndExt = value => {
    const parts = value.split('.')

    if (parts.length >= 2) {
        const ext = parts.pop()
        const filename = parts.join()

        return { filename, ext }
    }

    return { filename: value, ext: '' }
}

export const mapMyImages = images => {
    const newImages = cloneDeep(images)

    newImages.content = images.content.map(item => {
        return { ...item, url: item.previewUrl, originalItemId: item.originalImageId }
    })

    return newImages
}

export const mapUnsplashImages = unsplashImages => {
    const newUnsplashImages = cloneDeep(unsplashImages)

    newUnsplashImages.content = unsplashImages.content.map(item => ({
        id: item.id,
        originalItemId: item.id,
        name: '',
        authorInfo: item.user,
        url: item.urls.thumb,
        previewUrl: item.urls.regular,
        urls: item.urls,
        user: item.user,
    }))

    return newUnsplashImages
}

export const mapPixabayImages = pixabayImages => {
    const newPixabayImages = cloneDeep(pixabayImages)

    newPixabayImages.content = pixabayImages.content.map(item => ({
        id: item.id,
        originalItemId: item.id,
        name: '',
        authorInfo: item.user,
        url: item.previewUrl,
        previewUrl: item.imageUrl,
        user: item.user,
        userNickName: item.user,
        userProfileUrl: item.userProfileUrl,
    }))

    return newPixabayImages
}

export const mapAudioList = audioList => {
    const newAudioList = cloneDeep(audioList)

    newAudioList.content = newAudioList.content.map(item => mapAudioToAssetFormat(item))

    return newAudioList
}

export const mapAudioToAssetFormat = audio => ({
    ...audio,
    originalItemId: audio.id,
    authorInfo: audio.user,
    normalUrl: audio.url,
    isAudio: true,
})

export const mapImageToAssetFormat = image => {
    const smallItem = image.imageSizes.find(_item => _item.imageSize === SIZE_IMAGES.SMALL)
    const normalItem = image.imageSizes.find(_item => _item.imageSize === SIZE_IMAGES.NORMAL)

    return {
        ...normalItem,
        originalItemId: image.id,
        normalUrl: normalItem.url,
        previewUrl: smallItem ? smallItem.url : normalItem.url,
        url: smallItem ? smallItem.url : normalItem.url,
    }
}
