import { MenuItem } from '@mui/material'
import { API__PROJECTS } from 'api'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconFolder } from '../../../../svg'
import MoveModal, { ALL_PROJECTS_ID } from '../MoveModal/MoveModal'
import styles from '../MoveModal/MoveModal.module.scss'

const MoveProjectToFolderModal = ({ movingProjectId, folders, onUpdateList, onClose }) => {
    const { t } = useTranslation()
    const [targetFolderId, setTargetFolderId] = useState(ALL_PROJECTS_ID)

    const handleMove = () => {
        const folderId = targetFolderId === ALL_PROJECTS_ID ? null : targetFolderId
        API__PROJECTS.MOVE_PROJECT_TO_FOLDER(movingProjectId, folderId).then(() => {
            onUpdateList(folderId)
            onClose()
        })
    }

    return (
        <MoveModal
            title={t('Move project to')}
            value={targetFolderId}
            onChange={evt => setTargetFolderId(evt.target.value)}
            menuItems={folders.map(folder => (
                <MenuItem key={folder.id} value={folder.id}>
                    <IconFolder />
                    <span className={styles.selectItem}>{folder.name}</span>
                </MenuItem>
            ))}
            onMove={handleMove}
            onClose={onClose}
        />
    )
}

export default MoveProjectToFolderModal
