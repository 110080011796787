import linkedinIcon from 'svg/LinkedIn_icon.svg'
import twitterIcon from 'svg/twitter_icon.svg'
import facebookIcon from 'svg/facebook_icon.svg'

import i18n from 'i18n'

export const SHARE_MODAL_TABS = {
    main: 'main',
    embedCode: 'embedCode',
    qrCode: 'qrCode',
}

export const SOCIAL_BLOCKS = {
    facebook: {
        url: 'https://www.facebook.com/sharer.php?u=%URL%',
        icon: facebookIcon,
        alt: 'facebook share',
        text: i18n.t('Post to Facebook'),
    },
    linkedin: {
        url: 'https://www.linkedin.com/shareArticle?mini=true&url=%URL%',
        icon: linkedinIcon,
        alt: 'linkedin share',
        text: i18n.t('Post on LinkedIn'),
    },
    twitter: {
        url: 'https://twitter.com/share?url=%URL%',
        icon: twitterIcon,
        alt: 'twitter share',
        text: i18n.t('Tweet'),
    },
}
