import { flatten, maxBy, range } from 'lodash'
import { getTimeStep } from '../../../../../../../../../../utils/dateTime'

export const prepareTimeData = (data, from, to, dateProp = 'x', valueProp = 'y') => {
    const timeStep = getTimeStep(from, to) * 1000
    const steps = (to - from) / timeStep

    const timeIntervalPoints = range(0, steps + 1).map((v, i) => [from + i * timeStep, from + (i + 1) * timeStep])

    return data.map(datum => ({
        ...datum,
        data: timeIntervalPoints.map(([min, max]) => {
            const value = datum.data
                .filter(v => {
                    const date = v[dateProp]

                    return date > min && date < max
                })
                .reduce((sum, v) => sum + v[valueProp], 0)

            return {
                [dateProp]: min,
                [valueProp]: value,
            }
        }),
    }))
}

export const getGridXValues = ({ timeStep, steps, from, to }) => {
    return range(from + timeStep * (steps % 2), to + timeStep, Math.ceil(steps / 10) * timeStep)
}
export const getGridYValues = (data, integer) => {
    const STEPS = 10

    const values = flatten(data.map(v => v.data))
    const maxY = maxBy(values.map(({ y }) => (integer ? Math.ceil(y) : y)))
    const step = Math.ceil(maxY / STEPS)

    return range(0, step * STEPS, step)
}

export const getFormat = timeStep => {
    if (timeStep <= 60 * 60 * 1000) {
        return 'HH:MM'
    }
    return 'DD.MM'
}
