import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { API__CONTENT } from 'api'

import useInitialLoading from 'hooks/useInitialLoading'

import Table from './components/Table/Table'

import styles from './MyPurchases.module.scss'

const MyPurchases = () => {
    const { t } = useTranslation()
    const { isReady, onReady } = useInitialLoading()
    const { user_details } = useSelector(state => state)

    const [data, setData] = useState([])

    useEffect(() => {
        API__CONTENT.GET_PURCHASES(user_details.id)
            .then(({ content }) => {
                setData(content)
                onReady()
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <>
            <Helmet>
                <title>{t('My purchases')} | Interacty</title>
            </Helmet>
            {isReady && (
                <div className={styles.myPurchases}>
                    <div className={styles.title}>{t('My purchases')}</div>
                    {data.length ? (
                        <Table userId={user_details.id} data={data} />
                    ) : (
                        <div className={styles.empty}>{t("You don't have any purchases yet")}</div>
                    )}
                </div>
            )}
        </>
    )
}

export default MyPurchases
