import React from 'react'
import 'react-quill/dist/quill.snow.css'
import Text from '../components/Text/Text'

/**
 * CTA button
 */
class Button extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            html: props.text,
            focused: false,
        }
    }

    render() {
        const {
            blockData: { t, text, id, brad, bc },
            onUpdate,
        } = this.props
        const st = {
            borderRadius: brad + 'px',
            backgroundColor: bc,
        }
        return (
            <div className={`block __${t}`}>
                <div className="rbtn" style={st}>
                    <Text id={id} text={text} propName={'text'} onUpdate={onUpdate} isHideLink={true} />
                </div>
            </div>
        )
    }
}

export default Button
