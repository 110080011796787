import React from 'react'
import SimpleBar from 'simplebar-react'

const ScrollableBlock = ({
    autoHide = false,
    maxHeight = '100%',
    styles = {},
    scrollableNodeRef,
    className,
    children,
}) => (
    <SimpleBar
        autoHide={autoHide}
        style={{ maxHeight, ...styles }}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        className={className}
    >
        {children}
    </SimpleBar>
)

export default ScrollableBlock
