import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { API__AUTH } from 'api'

import useRouter from 'hooks/useRouter'

import Auth from 'pages/Auth/Auth.page'
import OAuth from 'pages/Auth/OAuth.page'

import { ACTIONS } from 'pages/Auth/constants'

const AuthRoute = () => {
    const { queryParams } = useRouter()

    const [isRedirect, setIsRedirect] = useState(false)
    const [isAuth, userDetails] = useSelector(state => [!!state.user_details, state.user_details])

    useEffect(() => {
        if (!isAuth) return
        const { action, login } = queryParams

        if (action?.toUpperCase() === ACTIONS.APPSUMO_COMPLETE_REGISTRATION && login !== userDetails.login) {
            API__AUTH.LOGOUT()
            return
        }

        setIsRedirect(true)
    }, [isAuth])

    if (isRedirect) return <Redirect to={{ pathname: '/my-projects' }} />

    return (
        <>
            <Route path="/auth" component={Auth} />
            <Route path="/oauth2/redirect" component={OAuth} />
        </>
    )
}

export default AuthRoute
