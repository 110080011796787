import React from 'react'
import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import HiddenGroup from 'components/Form/HiddenGroup/HiddenGroup'
import { useTranslation } from 'react-i18next'

import TextInputWithPageSelection from '../../../../../../components/TextInputWithPageSelection/TextInputWithPageSelection'

import ListActionBar from '../../../../Controls/ListActionBar/ListActionBar'
import { QuizResult as ResultModel } from '../../../quizModels'

import './Result.scss'

export const Result = ({
    validationResults = {},
    resultDistributionText,
    result,
    index,
    listLength,
    minResults,
    maxResults,
    pages,
    methods,
}) => {
    const { t } = useTranslation()

    return (
        <div className="result">
            <h3 className="result__title">
                <span className="result__title-content">
                    {t('Result')} {index + 1}
                    <span className="result__title-content-note">{resultDistributionText}</span>
                </span>
                <ListActionBar
                    index={index}
                    length={listLength}
                    minLength={minResults}
                    maxLength={maxResults}
                    onChangePosition={methods.onChangeResultPosition}
                    onClone={() => methods.onCloneResult(result.id)}
                    onRemove={() => methods.onRemoveResult(result.id)}
                />
            </h3>
            <div className="result__body">
                <TextInput
                    isRequired
                    label={t('Header')}
                    errorMessages={validationResults.header}
                    value={result.header}
                    onUpdate={value => methods.onUpdateResult(result.id, { [ResultModel.header]: value })}
                />
                <TextArea
                    label={t('Description')}
                    errorMessages={validationResults.description}
                    value={result.description}
                    onUpdate={value => methods.onUpdateResult(result.id, { [ResultModel.description]: value })}
                />
                <UploadImage
                    label={t('Result image')}
                    value={result.image}
                    onUpdate={value => methods.onUpdateResult(result.id, { [ResultModel.image]: value })}
                />
                <HiddenGroup>
                    {result.image && (
                        <TextInput
                            label={t('Image disclaimer')}
                            isOptional
                            errorMessages={validationResults.imageDisclaimer}
                            value={result.imageDisclaimer}
                            onUpdate={value =>
                                methods.onUpdateResult(result.id, { [ResultModel.imageDisclaimer]: value })
                            }
                        />
                    )}
                    <ElementsGroup isEquallyWidth>
                        <TextInput
                            label={t('Button text')}
                            value={result.buttonText}
                            onUpdate={value => methods.onUpdateResult(result.id, { [ResultModel.buttonText]: value })}
                        />
                        <TextInputWithPageSelection
                            pages={pages}
                            value={result.buttonLink}
                            onUpdate={value => methods.onUpdateResult(result.id, { [ResultModel.buttonLink]: value })}
                        />
                    </ElementsGroup>
                </HiddenGroup>
            </div>
        </div>
    )
}

export default Result
