import React, { useState, useEffect } from 'react'

const useWindowResize = () => {
    const [clientWidth, setClientWidth] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [scrollWidth, setScrollWidth] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0)

    const handleResize = evt => {
        setWidth(evt.target.innerWidth)
        setHeight(evt.target.innerHeight)
    }

    useEffect(() => {
        setClientWidth(document.body.clientWidth)
    }, [document.body.clientWidth])
    useEffect(() => {
        setClientHeight(document.body.clientHeight)
    }, [document.body.clientHeight])

    useEffect(() => {
        setScrollWidth(document.documentElement.scrollWidth)
    }, [document.documentElement.scrollWidth])
    useEffect(() => {
        setScrollHeight(document.documentElement.scrollHeight)
    }, [document.documentElement.scrollHeight])

    useEffect(() => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return { clientWidth, clientHeight, width, height, scrollWidth, scrollHeight }
}

export default useWindowResize
