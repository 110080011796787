import React from 'react'
import { imgLoader } from 'common/services/imageLoader'

const Image = ({ className, src, alt, style, width, height, onClick }) => {
    return (
        <img
            className={className}
            src={imgLoader(src)}
            alt={alt}
            style={style}
            onClick={onClick}
            width={width}
            height={height}
        />
    )
}

export default Image
