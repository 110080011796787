import i18n from 'i18n'

export const PERIOD_INTERVALS = {
    '1_DAY': '1',
    '7_DAYS': '7',
    '30_DAYS': '30',
    ALL_TIME: 'ALL_TIME',
    CUSTOM: 'Custom',
}

export const PERIOD_INTERVALS_MS = {
    '1_DAY': 86400000,
    '7_DAYS': 604800000,
    '30_DAYS': 2592000000,
}

export const PERIOD_TYPES = {
    [PERIOD_INTERVALS['1_DAY']]: {
        name: i18n.t('{{number}} day', { number: 1 }),
        value: PERIOD_INTERVALS['1_DAY'],
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS['1_DAY'] }),
    },
    [PERIOD_INTERVALS['7_DAYS']]: {
        name: i18n.t('{{number}} days', { number: 7 }),
        value: PERIOD_INTERVALS['7_DAYS'],
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS['7_DAYS'] }),
    },
    [PERIOD_INTERVALS['30_DAYS']]: {
        name: i18n.t('{{number}} days', { number: 30 }),
        value: PERIOD_INTERVALS['30_DAYS'],
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS['30_DAYS'] }),
    },
    [PERIOD_INTERVALS['ALL_TIME']]: {
        name: i18n.t('All this time'),
        value: PERIOD_INTERVALS['ALL_TIME'],
        getTimesRange: () => ({ to: Date.now(), from: 1546300800000 }),
    },
    [PERIOD_INTERVALS.CUSTOM]: {
        name: i18n.t('Custom'),
        value: PERIOD_INTERVALS.CUSTOM,
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS['30_DAYS'] }),
    },
}
