import React from 'react'

import styles from './Block.module.scss'

const Block = ({ image, title, article }) => (
    <div className={styles.block}>
        {image && <img className={styles.image} src={image} alt="" />}
        <div className={styles.texts}>
            {!!title && <div className={styles.title}>{title}</div>}
            <div className={styles.article}>{article}</div>
        </div>
    </div>
)

export default Block
