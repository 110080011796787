import React, { useState } from 'react'
import { MenuItem } from '@mui/material'
import MuiMenu from '@mui/material/Menu'
import { useTranslation } from 'react-i18next'

import dotsIcon from './i/dots.svg'

import styles from './Menu.module.scss'

export const ACTIONS = {
    edit: 'EDIT',
    delete: 'DELETE',
}

const Menu = ({ onOpen, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const isOpened = Boolean(anchorEl)
    const { t } = useTranslation()

    return (
        <div className={styles.menu}>
            <div className={styles.dots} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <img src={dotsIcon} alt="" />
            </div>
            <MuiMenu
                open={isOpened}
                anchorEl={anchorEl}
                classes={{
                    select: styles.dropdown,
                }}
                variant="standard"
                autoWidth
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    value={ACTIONS.edit}
                    onClick={() => {
                        setAnchorEl(null)
                        onOpen()
                    }}
                >
                    {t('Edit')}
                </MenuItem>
                <MenuItem
                    value={ACTIONS.delete}
                    onClick={() => {
                        setAnchorEl(null)
                        onDelete()
                    }}
                >
                    {t('Delete')}
                </MenuItem>
            </MuiMenu>
        </div>
    )
}

export default Menu
