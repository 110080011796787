import React from 'react'
import 'react-quill/dist/quill.snow.css'

import Text from '../components/Text/Text'

class Block1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            html: props.text,
            focused: false,
        }
    }

    render() {
        const {
            blockData: { t, wP_bg, isTransparentBackground },
        } = this.props

        return (
            <div
                className={`block __${t}`}
                style={{ backgroundColor: isTransparentBackground ? 'transparent' : wP_bg }}
            >
                <Text {...this.props}></Text>
            </div>
        )
    }
}

export default Block1
