import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

const GoogleTagManagerBlock = ({ settings = { id: null }, isChecked, updateIntegration }) => {
    const { t } = useTranslation()
    const [id, setId] = useState(settings.id)
    const [error, setError] = useState('')

    const changeValue = evt => {
        setId(evt.target.value)
        setError('')
    }

    const onSave = () => {
        if (/^(GTM)-[a-zA-Z0-9-]+$/i.test(id.toString())) {
            updateIntegration(true, { id: id })
        } else {
            setError(t('Invalid format'))
        }
    }

    const onDisable = () => {
        updateIntegration(false, { id: null })
    }

    const isDisabledSave = !id || isEqual(settings.id, id)

    return (
        <div className="google-analytics-block">
            <div className="content__title">{t('Google Tag Manager')}</div>

            <div className={`input-box ${error.length ? 'is-error' : ''}`}>
                <div className="title">{t('GTM ID')}</div>
                <input
                    autoComplete="off"
                    type="text"
                    value={id}
                    placeholder="GTM-XXXXXX"
                    onChange={evt => changeValue(evt)}
                />
                <p className="msg msg--error">{error}</p>
            </div>

            <div className="content__sub-title">{t('How to setup Google Tag Manager:')}</div>
            <ul>
                <li>
                    1. {t('Find your container ID, formatted as GTM-XXXXXX in Google Tag Manager admin panel')}{' '}
                    <Trans
                        i18nKey="<0>https://tagmanager.google.com/</0>"
                        components={[
                            <a href="https://tagmanager.google.com/" target="_blank" rel="noopener noreferrer">
                                ,
                            </a>,
                        ]}
                    />
                </li>
                <li>2. {t('Paste ID in the input and click “Save”.')}</li>
                <li>
                    3. {t('We use officially recommended embed code.')}{' '}
                    <Trans
                        i18nKey="For more details see <0>{{link}}</0>"
                        components={[
                            <a
                                href="https://developers.google.com/tag-platform/tag-manager/web"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ,
                            </a>,
                        ]}
                        values={{ link: 'https://developers.google.com/tag-platform/tag-manager/web' }}
                    />
                </li>
                <li>4. {t('Publish your project after the change')}</li>
            </ul>

            <div className="btns">
                <button
                    className="button"
                    data-size="md"
                    disabled={isDisabledSave}
                    onClick={onSave}
                    data-variant="primary"
                >
                    {t('Save')}
                </button>
                {isChecked ? (
                    <button className="button" data-size="md" data-variant="primary-danger" onClick={onDisable}>
                        {t('Disable')}
                    </button>
                ) : null}
            </div>
        </div>
    )
}

export default GoogleTagManagerBlock
