import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DataGrid from 'components/Form/DataGrid/DataGrid'

import { API__ANALYTICS } from 'api'

import { downloadCSV } from 'common/utils/files'

import { generateNameCSVFile } from '../../../../utils'
import { PERIOD_INTERVALS } from '../../../../components/DateRangePanel/constants'
import IconCsv from '../../../../components/DateRangePanel/i/csv.svg'

import { getColumns, getCustomFieldsMap } from './columns'
import LeadInfoModal from './components/LeadInfoModal'

import './LeadFormTable.scss'

const LeadFormTable = ({ projectId, blockProjection, periodInterval, dateRange, isSeparateBlock = false }) => {
    const { t } = useTranslation()

    const [fields] = useState(() => blockProjection?.metadata?.leadFormFields)
    const [isShowModal, setIsShowModal] = useState(false)
    const [selectSessionId, setSelectSessionId] = useState(null)
    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)

    const fetchData = async (page = 0) => {
        setIsLoading(true)
        const result = await API__ANALYTICS.GET_BLOCK_LEAD_FORM(projectId, blockProjection.blockId, {
            size: 20,
            page,
            from: dateRange.from,
            to: dateRange.to,
            sort: 'created,desc',
        })
        setData(result)
        setIsLoading(false)
        setIsReady(true)
    }

    useEffect(() => {
        if (dateRange) fetchData()
    }, [dateRange])

    const onExportCSV = async () => {
        const content = await API__ANALYTICS.GET_BLOCK_LEAD_FORM_CSV(projectId, {
            blockId: blockProjection.blockId,
            from: dateRange.from,
            to: dateRange.to,
            customFieldsMap: getCustomFieldsMap(fields),
        })
        const fileName = generateNameCSVFile(t('Lead form'), dateRange.from, dateRange.to)
        downloadCSV(content, fileName)
    }

    const onSelectColumn = value => {
        setSelectSessionId(value)
        setIsShowModal(true)
    }

    const isHideView = !isSeparateBlock && !data?.content?.length && periodInterval === PERIOD_INTERVALS.ALL_TIME

    if (isHideView) return null

    return (
        <div className="lead-form-analytics">
            <div className="lead-form-analytics__header">
                {!isSeparateBlock && <h2>{t('Lead form')}</h2>}
                <div className="lead-form-analytics__export" onClick={() => onExportCSV()}>
                    {t('Export CSV')}
                    <img src={IconCsv} alt="" />
                </div>
            </div>
            {isReady && (
                <DataGrid
                    columns={getColumns(fields, onSelectColumn)}
                    isLoading={isLoading}
                    pagination={data}
                    onPageChange={page => fetchData(page)}
                />
            )}
            {isShowModal && (
                <LeadInfoModal
                    blockProjection={blockProjection}
                    projectId={projectId}
                    sessionId={selectSessionId}
                    onClose={() => setIsShowModal(false)}
                    dateRange={dateRange}
                />
            )}
        </div>
    )
}

export default LeadFormTable
