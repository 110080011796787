import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAssetManager } from 'components/AssetManager'

import photoIcon from './i/photo.svg'

import styles from './ImageSelector.module.scss'

const ImageSelector = ({ imageUrl, borderRadius = '50%', onUpdate }) => {
    const { t } = useTranslation()
    const { chooseAsset } = useAssetManager()

    return (
        <div className={styles.imageSelector}>
            <h3 className={styles.title}>{t('Image')}</h3>
            <div
                className={styles.image}
                onClick={() => chooseAsset(imageUrl, onUpdate)}
                style={{ backgroundImage: `url(${imageUrl})`, borderRadius }}
            />
            <div className={styles.button} onClick={() => chooseAsset(imageUrl, onUpdate)}>
                <img src={photoIcon} alt="photo" />
            </div>
        </div>
    )
}

export default ImageSelector
