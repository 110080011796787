import React, { useState } from 'react'

import CardsBlockPreview from '../components/Modals/CardsModal/CardsBlockPreview'
import CardsModal from '../components/Modals/CardsModal/CardsModal'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const Cards = ({
    blockData,
    pagesData,
    colorTheme,
    fontFamily,
    backgroundImage,
    isTransparentBackground,
    onUpdate,
    id,
}) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <CardsBlockPreview
                isTransparentBackground={isTransparentBackground}
                cards={blockData.struct.cards}
                fontFamily={fontFamily}
                colorTheme={colorTheme}
                backgroundImage={backgroundImage}
            />

            {isShowModal && (
                <CardsModal
                    data={{
                        pages: pagesData,
                        structure: blockData.struct,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Cards
