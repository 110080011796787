import React from 'react'

export function CreateFolderIcon() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.5 6.68V26C27.5 26.2761 27.2761 26.5 27 26.5H1C0.723858 26.5 0.5 26.2761 0.5 26V2C0.5 1.72386 0.723858 1.5 1 1.5H8.2093C8.37799 1.5 8.53531 1.58506 8.62768 1.72622L11.0981 5.50134C11.3752 5.92481 11.8471 6.18 12.3532 6.18H27C27.2761 6.18 27.5 6.40386 27.5 6.68Z"
                stroke="#2990FB"
            />
            <line x1="14.5" y1="11" x2="14.5" y2="20" stroke="#2990FB" />
            <line x1="19" y1="15.5" x2="10" y2="15.5" stroke="#2990FB" />
        </svg>
    )
}
