import React from 'react'

import styles from './ElementsGroup.module.scss'

const ElementsGroup = ({ isEquallyWidth = false, children }) => {
    if (!children) return null
    const components = Array.isArray(children) ? children.filter(component => !!component) : [children]

    const itemStyles = {}
    if (isEquallyWidth) {
        itemStyles.width = `${100 / components.length}%`
    }

    return (
        <ul className={styles.elementsGroup}>
            {components.map((component, i) => (
                <li key={i} className={styles.elementItem} style={itemStyles}>
                    {component}
                </li>
            ))}
        </ul>
    )
}

export default ElementsGroup
