import React from 'react'

import styles from './Board.module.scss'

const Board = ({ tilesCount, tileSize, borderWidth, children }) => (
    <div
        className={styles.board}
        style={{
            '--tileSize': `${tileSize}px`,
            '--tilesCount': Math.sqrt(tilesCount),
            borderWidth,
        }}
    >
        {children}
    </div>
)

export default Board
