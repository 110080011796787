const EyeClosed = () => (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 1C19 4.31371 14.9706 7 10 7C5.02944 7 1 4.31371 1 1" stroke="#979797" strokeLinecap="round" />
        <path d="M6.43591 9.50017L7.19075 6.7666" stroke="#979797" strokeLinecap="round" />
        <path d="M1.5 7C2.01404 6.39706 2.61707 5.68975 3.20513 5" stroke="#979797" strokeLinecap="round" />
        <path d="M14.0641 9.50007L13.3013 6.73755" stroke="#979797" strokeLinecap="round" />
        <path d="M19 7.00001L17.1385 4.81665" stroke="#979797" strokeLinecap="round" />
    </svg>
)

export default EyeClosed
