import React, { useState, useCallback } from 'react'
import NotificationDialog from './NotificationDialog'

let DialogContext
const { Provider } = (DialogContext = React.createContext())

const DialogProvider = ({ children }) => {
    const [options, setOptions] = useState({ title: '', description: '' })
    const [isOpen, setIsOpen] = useState(false)

    const show = useCallback((_options = {}) => {
        setOptions({ ..._options })
        setIsOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    return (
        <>
            <Provider value={show}>{children}</Provider>
            <NotificationDialog
                open={isOpen}
                title={options.title}
                description={options.description}
                onClose={handleClose}
            />
        </>
    )
}

export { DialogContext, DialogProvider }
