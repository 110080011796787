import i18n from 'i18next'

export const MAILCHIMP_ID = 'MAILCHIMP'

export const popupTitle = 'Mailchimp integrations'

export const dialogInfoEmailIsRequiredField = {
    title: i18n.t('Please enable the email field first'),
    description: i18n.t('For integration with Mailchimp, the Email field is required.'),
}

export const dialogInfoMailchimpDisabled = {
    title: i18n.t('Mailchimp integration disabled'),
    description: i18n.t('For integration with Mailchimp, the Email field is required.'),
}
