import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import useResize from 'hooks/useResize'

import Card from './components/Card/Card'
import Votes from './components/Card/components/Votes/Votes'
import Main from './components/Card/components/Main/Main'

import useCards from './hooks/useCards'

import { BORDER_OFFSET, CARD_OFFSET } from './constants'

import './Playground.scss'

const Playground = ({ cards, cardType, cardProportions, fontFamily, colorTheme }) => {
    const containerRef = useRef()
    const [containerWidth] = useResize(containerRef)

    const [mainFontSizes, setMainFontSizes] = useState({})
    const [descriptionFontSizes, setDescriptionFontSizes] = useState({})
    const [isMobile, setIsMobile] = useState(false)

    const { cardsInRowCount, cardsMaxWidth, colors } = useCards({
        cardsIds: cards.map(card => card.id),
        cardType,
        colorTheme,
        containerWidth,
    })

    useEffect(() => {
        setIsMobile(containerWidth < 784)
    }, [containerWidth])

    return (
        <div className="rank-battle__playground" ref={containerRef} style={{ padding: BORDER_OFFSET }}>
            <ul
                className={classNames('rank-battle__cards', { 'is-mobile': isMobile })}
                style={{
                    maxWidth: cardsMaxWidth,
                    '--cardsInRowCount': cardsInRowCount,
                    margin: `-${CARD_OFFSET}px`,
                }}
            >
                {cards.map(card => (
                    <Card colors={colors} isMobile={isMobile}>
                        <Main
                            isMobile={isMobile}
                            minFontSize={Math.min(...Object.values(mainFontSizes))}
                            colors={colors}
                            fontFamily={fontFamily}
                            text={card.text}
                            imageUrl={card.imageUrl}
                            cardType={cardType}
                            cardProportions={cardProportions}
                            onUpdateFontSize={value => {
                                setMainFontSizes(prevState => ({
                                    ...prevState,
                                    [card.id]: value,
                                }))
                            }}
                        />
                        <Votes
                            isMobile={isMobile}
                            colors={colors}
                            isNoDescription={cards.every(card => !card.description)}
                            votesCount={0}
                            fontFamily={fontFamily}
                            description={card.description}
                            cardType={cardType}
                            onUpdateDescriptionFontSize={value => {
                                setDescriptionFontSizes(prevState => ({
                                    ...prevState,
                                    [card.id]: value,
                                }))
                            }}
                            minDescriptionFontSize={Math.min(...Object.values(descriptionFontSizes))}
                        />
                    </Card>
                ))}
            </ul>
        </div>
    )
}

export default Playground
