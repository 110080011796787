import { DEFAULT_IMAGE_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'
import { MIN_ANSWERS_IN_QUESTION } from './constants'

export class Answer {
    id // <String> id
    text // <String> text
    image // <null/String[URL]> image
    imageLabel // <String> image label
    isCorrect // <Boolean> is correct

    constructor({ text = '', image = DEFAULT_IMAGE_URL, imageLabel = '', isCorrect = false } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.image = image
        this.imageLabel = imageLabel
        this.isCorrect = isCorrect
    }

    static get text() {
        return 'text'
    }

    static get image() {
        return 'image'
    }

    static get imageLabel() {
        return 'imageLabel'
    }

    static get isCorrect() {
        return 'isCorrect'
    }
}

export class Question {
    id // <String> id
    text // <String> text
    image // <null/String[URL]> image
    imageDisclaimer // <String> image disclaimer
    answers // <Array> Answers
    isText // <Boolean> is text question

    constructor({ text = '', image = DEFAULT_IMAGE_URL, imageDisclaimer = '', answers = [], isText = true } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.image = image
        this.imageDisclaimer = imageDisclaimer
        if (answers.length < MIN_ANSWERS_IN_QUESTION)
            throw new Error(`Min count answers in question: ${MIN_ANSWERS_IN_QUESTION}`)
        if (answers.filter(ans => ans.isCorrect).length > 1)
            throw new Error('Question can only have one correct answer')
        this.answers = answers
        this.isText = isText
    }

    static get text() {
        return 'text'
    }

    static get image() {
        return 'image'
    }

    static get imageDisclaimer() {
        return 'imageDisclaimer'
    }

    static get answers() {
        return 'answers'
    }

    static get isText() {
        return 'isText'
    }
}
