import { Grid } from '@mui/material'
import classnames from 'classnames'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import iconStopHand from './i/stopHand.svg'

import { CreateFolderIcon } from './CreateFolderIcon'
import FolderItem from './FolderItem'
import StubFolderList from './StubFolderList'

import './FolderList.scss'

const FolderList = ({
    isLoading,
    folders,
    path,
    onCreateFolder,
    onRenameFolder,
    onOpenFolder,
    onMoveFolder,
    onDeleteFolder,
}) => {
    const { t } = useTranslation()
    const [removingFolder, setRemovingFolder] = useState(null)

    const handleConfirmedRemove = () => {
        onDeleteFolder(removingFolder.id)
        setRemovingFolder(null)
    }

    return (
        <div className="folder-list">
            <div className="folder-list__control-panel">
                <span className="folder-list__title">{t('Folders')}</span>
            </div>
            <div className="folder-list__grid">
                <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 1, sm: 4, md: 12, lg: 12 }}>
                    {isLoading ? (
                        <StubFolderList />
                    ) : (
                        <>
                            {folders.map(folder => (
                                <Grid item xs={1} sm={2} md={4} lg={3} key={folder.id}>
                                    <FolderItem
                                        isCanMove={path.length > 1 || folders.length > 1}
                                        folder={folder}
                                        onOpen={onOpenFolder}
                                        onRename={onRenameFolder}
                                        onMove={onMoveFolder}
                                        onDelete={() => setRemovingFolder(folder)}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={1} sm={2} md={4} lg={3} key="add-button-key">
                                <div
                                    className={classnames('folder-item', 'folder-list__create-button')}
                                    onClick={onCreateFolder}
                                >
                                    <CreateFolderIcon />
                                    <span className="folder-item__name">{t('Create folder')}</span>
                                </div>
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
            {removingFolder && (
                <ConfirmationDialog
                    className="folder-list__delete-confirm"
                    onClose={() => setRemovingFolder(null)}
                    onAction={handleConfirmedRemove}
                    data={{
                        headText: t('Wait!'),
                        image: iconStopHand,
                        noteText: (
                            <>
                                <Trans
                                    i18nKey='You are about to delete the folder <b>"{{folderName}}"</b> and all nested projects.'
                                    values={{ folderName: removingFolder.name }}
                                    components={{ b: <b /> }}
                                />
                                <br />
                                <Trans
                                    i18nKey="This action <tag>cannot be undone</tag>."
                                    components={{
                                        tag: <span style={{ color: '#fd8d8d' }} />,
                                    }}
                                />
                            </>
                        ),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                        checkboxText: t('I understand that all projects in this folder will be deleted'),
                    }}
                />
            )}
        </div>
    )
}

export default FolderList
