import React, { useState } from 'react'

import { BlockComponents } from '../constants'

import InlineControls from './components/Controls/Inline/Inline'

import { getIsMultiplayerBlock } from 'utils/multiplayer'

const BlocksController = ({ pages, isMultiplayerGame, block, appData, isSelected, isLast, isFirst, methods }) => {
    const [Component] = useState(() => BlockComponents[block.t])
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div
            className={`block-control${isSelected ? ' __selected' : ''}`}
            onClick={evt => {
                evt.stopPropagation()
                methods.onBlockSelect(block)
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="selected-border" />
            <Component
                {...block} // TODO пока оставил чтобы не ломалось, но надо перенести логику в блоках > 9 на "blockData.<KEY>"
                blockData={block}
                appData={appData}
                pagesData={pages}
                onUpdate={_data => methods.changeBlocks(_data)}
                requestArrayElementControls={methods.requestArrayElementControls}
                selected={isSelected}
            />
            <div className="plus" onClick={() => methods.onOpenBlockPane(block.id)} />
            <InlineControls
                isHideClone={isMultiplayerGame && getIsMultiplayerBlock(block)}
                isVisible={isSelected || isHovered}
                firstElement={isFirst}
                lastElement={isLast}
                onAction={methods.onInlineControlAction}
                blockId={block.id}
            />
        </div>
    )
}

export default BlocksController
