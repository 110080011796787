import React from 'react'
import { useTranslation } from 'react-i18next'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { IconRename, TripleDotsIcon, IconDelete, IconMove } from 'svg'

import './Dropdown.scss'

const Dropdown = ({ onRename, onMove, onDelete }) => {
    const { t } = useTranslation()

    const actionMenuItems = [
        <p className="folders-dropdown__menu-item" onClick={onRename}>
            <span className="folders-dropdown__menu-icon">
                <IconRename />
            </span>
            {t('Rename')}
        </p>,
        onMove && (
            <p className="folders-dropdown__menu-item" onClick={onMove}>
                <span className="folders-dropdown__menu-icon">
                    <IconMove />
                </span>
                {t('Move')}
            </p>
        ),
        <p className="folders-dropdown__menu-item" onClick={onDelete}>
            <span className="folders-dropdown__menu-icon">
                <IconDelete />
            </span>
            {t('Delete')}
        </p>,
    ]

    return (
        <div onClick={evt => evt.stopPropagation()}>
            <DropdownMenu className="folders-dropdown__menu" actionHtml={<TripleDotsIcon />} items={actionMenuItems} />
        </div>
    )
}

export default Dropdown
