import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { API__PRODUCTS } from 'api'

import { ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION } from 'store/actions'

import womanNotebook from 'svg/womanNotebook.svg'

import Modal from '../Modal'

import './TrialExtensionModal.scss'

const EducationTransferringToNewFreePlanModal = ({ onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { selectedOrganizationId } = useSelector(state => ({
        selectedOrganizationId: state.organizations.selectedOrganizationId,
    }))

    const onClick = async () => {
        await API__PRODUCTS.ACTIVATE_EDUCATION_FREE(selectedOrganizationId)
        await dispatch(ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION())
        onClose()
    }

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => onClose()} isShowCloseIcon={false}>
            <div className="trial-success-modal-education">
                <img src={womanNotebook} alt="" />
                <p className="subscription-title">
                    <b>{t('You are transferred to the free Education plan')}</b>
                </p>
                <p className="subscription-text">
                    {t('We have created special free plan for teachers, students and education programs.')}
                    &nbsp;
                    <b>{t('You will be automatically transferred from Free to Education Free.')}</b>
                    &nbsp;
                    {t('If you wish, you can change your tariff plan.')}
                </p>
                <div className="button-container">
                    <button className="ok-button" onClick={onClick}>
                        {t("Ok, let's get started!")}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EducationTransferringToNewFreePlanModal
