import React from 'react'

import loader from './i/loader.gif'

import './Loader.scss'

const Loader = ({ isShow }) => {
    if (!isShow) return null

    return (
        <div className="trial-ended-modal__loader">
            <img src={loader} alt="" />
        </div>
    )
}

export default Loader
