import React from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import iconStopHand from './i/stop-hand.svg'

import styles from './StopPanel.module.scss'

const StopPanel = ({
    t,
    onBack,
    onQuit,
    isLoading = false,
    image = iconStopHand,
    headText = t('Are you sure that you want to quit?'),
    descriptionText = t('The changes you made will not be saved'),
    backText = t('Back'),
    quitText = t('Quit'),
    isShowQuit = true,
}) => (
    <div className={styles.modalStop}>
        <div className={styles.modalOverlay} onClick={() => onBack()} />
        <div className={styles.modalContent}>
            <div className={styles.note}>
                {image && (
                    <div className={styles.imageBox}>
                        <img className={styles.image} src={image} alt="" />
                    </div>
                )}
                <div className={styles.textBox}>
                    <h4 className={styles.textTitle}>{headText}</h4>
                    <p className={styles.textContent}>{descriptionText}</p>
                </div>
            </div>
            <div className={styles.buttons}>
                <button
                    className={classNames('button', styles.button)}
                    data-variant="secondary"
                    data-size="lg"
                    onClick={() => onBack()}
                >
                    {backText}
                </button>
                {isShowQuit && (
                    <button
                        className={classNames('button', styles.button)}
                        data-variant="primary"
                        data-size="lg"
                        disabled={isLoading}
                        onClick={() => onQuit()}
                    >
                        {quitText}
                    </button>
                )}
            </div>
        </div>
    </div>
)

export default withTranslation('translations')(StopPanel)
