import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
    ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID,
    ASSET_MANAGER__LIBRARY__SET_SEARCH,
    ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID,
    ASSET_MANAGER__AUDIO_LIBRARY__DELETE_AUDIO,
    ASSET_MANAGER__AUDIO_LIBRARY__UP_AUDIO_PAGE,
} from 'store/actions/asset_manager'

import Search from '../../components/Search'
import PlaceBar from '../../components/PlaceBar'
import MediaGrid from '../../components/MediaGrid/MediaGrid.js'

import './AudioLibrary.scss'

const AudioLibrary = ({ onPlaceItem }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { list, selectedItemId, search, isLoadList, isLoadError } = useSelector(state => state.assetManager.library)
    const filteredList = useMemo(
        () => list.content.filter(({ name }) => name.toLowerCase().includes(search.trim().toLowerCase())),
        [list, search],
    )

    const placeItem = useCallback(() => {
        const [{ normalUrl = '', authorInfo, id, name } = {}] = filteredList.filter(item => item.id === selectedItemId)

        onPlaceItem({ src: normalUrl, id, author: authorInfo, name }, false)
        dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(null))
    }, [onPlaceItem, filteredList, selectedItemId])

    return (
        <div className="audio-library">
            <div className="audio-library__header">
                <h1>{t('Audio library')}</h1>
            </div>
            <Search onSearchValueChange={value => dispatch(ASSET_MANAGER__LIBRARY__SET_SEARCH(value))} />
            <MediaGrid
                isAudio
                data={filteredList}
                selectedId={selectedItemId}
                isLoadData={isLoadList}
                isCanLoadData={!list.last && !isLoadError}
                isShowItemDetails
                onUpPage={() => dispatch(ASSET_MANAGER__AUDIO_LIBRARY__UP_AUDIO_PAGE())}
                onDeleteImage={id => dispatch(ASSET_MANAGER__AUDIO_LIBRARY__DELETE_AUDIO(id))}
                onSelectImage={id => dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(id))}
                onUnselectImage={id => dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))}
            />
            {!!selectedItemId && <PlaceBar onClick={placeItem} isAudio />}
        </div>
    )
}

export default AudioLibrary
