import { Trans, useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import Modal from 'components/Modal/Modal'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import iconStopHand from 'images/icons/stopHand.svg'

import i18n from 'i18n'

import { API__PROJECTS } from 'api'

import Item from './components/Item/Item'

import { ITEMS, INTERVAL_OPTIONS } from './constants'

import styles from './RemoveDataModal.module.scss'

const getOptionByDaysCount = daysCount => ({
    value: daysCount,
    label: i18n.t('every {{count}} days', { count: daysCount }),
})

const RemoveDataModal = ({ projectId, data, onClose, onUpdate }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [activeItem, setActiveItem] = useState(!!data ? ITEMS.ENABLED : ITEMS.DISABLED)
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
    const [removingPeriodInDays, setRemovingPeriodInDays] = useState(
        !!data ? getOptionByDaysCount(data.removingPeriodInDays) : getOptionByDaysCount(INTERVAL_OPTIONS[1]),
    )

    useEffect(() => {
        if (isLoading) {
            setIsButtonDisabled(true)
            return
        }
        if (activeItem === ITEMS.DISABLED && !data) {
            setIsButtonDisabled(true)
            return
        }
        if (activeItem === ITEMS.ENABLED && !!data && data.removingPeriodInDays === removingPeriodInDays.value) {
            setIsButtonDisabled(true)
            return
        }
        setIsButtonDisabled(false)
    }, [isLoading, data, activeItem, removingPeriodInDays])

    const onSetRemovingPeriod = async () => {
        try {
            setIsLoading(true)
            await API__PROJECTS.SET_PROJECT_STATISTIC_REMOVE_INTERVAL(projectId, removingPeriodInDays.value)
            await onUpdate()
            onClose()
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }
    const onUpdateRemovingPeriod = async () => {
        try {
            setIsLoading(true)
            await API__PROJECTS.UPDATE_PROJECT_STATISTIC_REMOVE_INTERVAL(
                projectId,
                data.taskId,
                removingPeriodInDays.value,
            )
            await onUpdate()
            onClose()
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }
    const onDeleteRemovingPeriod = async () => {
        try {
            setIsLoading(true)
            await API__PROJECTS.DELETE_PROJECT_STATISTIC_REMOVE_INTERVAL(projectId, data.taskId)
            await onUpdate()
            onClose()
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }
    const onDeleteImmediately = async () => {
        try {
            setIsLoading(true)
            await API__PROJECTS.DELETE_PROJECT_STATISTIC(projectId)
            await onUpdate()
            onClose()
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal onClose={onClose} isShowCloseIcon>
            <div className={styles.removeIntervalModal}>
                <h3 className={styles.title}>{t('Deleting user data')}</h3>
                <ul className={styles.list}>
                    <Item
                        isActive={activeItem === ITEMS.DISABLED}
                        label={t('Disabled')}
                        onSelect={() => setActiveItem(ITEMS.DISABLED)}
                    />
                    <Item
                        isActive={activeItem === ITEMS.ENABLED}
                        label={t('Delete automatically')}
                        isNeedConfirm
                        selectData={{
                            value: removingPeriodInDays,
                            options: INTERVAL_OPTIONS.map(daysCount => getOptionByDaysCount(daysCount)),
                            onChange: value => setRemovingPeriodInDays(value),
                        }}
                        onSelect={() => setActiveItem(ITEMS.ENABLED)}
                    />
                    <Item
                        isActive={activeItem === ITEMS.IMMEDIATELY}
                        label={t('Delete immediately')}
                        isNeedConfirm
                        onSelect={() => setActiveItem(ITEMS.IMMEDIATELY)}
                    />
                </ul>
                <ul className={styles.buttons}>
                    <button className="button" data-variant="secondary" data-size="lg" onClick={() => onClose()}>
                        {t('Cancel')}
                    </button>
                    <button
                        className="button"
                        data-variant="primary"
                        disabled={isButtonDisabled}
                        data-size="lg"
                        onClick={() =>
                            activeItem === ITEMS.DISABLED ? onDeleteRemovingPeriod() : setIsShowConfirmDialog(true)
                        }
                    >
                        {activeItem === ITEMS.DISABLED ? t('Confirm') : t('Next')}
                    </button>
                </ul>
            </div>

            {isShowConfirmDialog && (
                <ConfirmationDialog
                    onClose={() => setIsShowConfirmDialog(false)}
                    isLoading={isLoading}
                    onAction={() => {
                        if (activeItem === ITEMS.ENABLED) {
                            if (!!data) onUpdateRemovingPeriod()
                            else onSetRemovingPeriod()
                        } else {
                            onDeleteImmediately()
                        }
                    }}
                    data={{
                        headText: t('Wait!'),
                        image: iconStopHand,
                        noteText: (
                            <>
                                {activeItem === ITEMS.ENABLED ? (
                                    <Trans
                                        i18nKey="You have set up automatic data deletion.<br/>Data will be permanently deleted <b>every {{count}} days.</b>"
                                        values={{ count: removingPeriodInDays.value }}
                                        components={{ b: <b /> }}
                                    />
                                ) : (
                                    <Trans
                                        i18nKey="All user data associated with this project will be permanently deleted.<br/>This action <tag>cannot be undone.</tag>"
                                        components={{
                                            tag: <span style={{ color: '#fd8d8d' }} />,
                                        }}
                                    />
                                )}
                            </>
                        ),
                        confirmText:
                            activeItem === ITEMS.ENABLED
                                ? t('I understand that deleted data cannot be recovered')
                                : t('I understand that all user data will be deleted'),
                        actionVariant: 'primary-danger',
                        actionText: t('Confirm'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </Modal>
    )
}

export default RemoveDataModal
