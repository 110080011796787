import i18n from 'i18n'

import ImageSVG from './svg/Image'
import TextSVG from './svg/Text'

export const DEFAULT_VALUES = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
}

export const DEFAULT_ITEMS = [
    {
        icon: ImageSVG,
        value: DEFAULT_VALUES.IMAGE,
        label: i18n.t('Image'),
    },
    {
        icon: TextSVG,
        value: DEFAULT_VALUES.TEXT,
        label: i18n.t('Text'),
    },
]
