import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import styles from './EditorModal.module.scss'

const EditorModal = ({ title, isCanSave, onSave, onCancel, children }) => {
    const { t } = useTranslation()

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <div className={styles.modalContent}>
                {!!title && <div className={styles.title}>{title}</div>}
                <div className={styles.content}>{children}</div>
                <div className={styles.buttons}>
                    <button className="button" data-variant="secondary" data-size="lg" onClick={onCancel}>
                        {t('Cancel')}
                    </button>
                    <button
                        disabled={!isCanSave}
                        className="button"
                        data-variant="primary"
                        data-size="lg"
                        onClick={() => {
                            try {
                                onSave()
                            } catch (err) {
                                console.error(err)
                            }
                        }}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default EditorModal
