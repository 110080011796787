import React from 'react'
import classNames from 'classnames'

import icon_up from '../../../../i/up.svg'
import icon_down from '../../../../i/down.svg'
import icon_clone from '../../../../i/clone.svg'
import icon_delete from '../../../../i/delete.svg'

import styles from './Inline.module.scss'

const InlineControls = ({ onAction, blockId, firstElement, lastElement, isVisible, isHideClone }) => {
    const onClick = action => {
        onAction({ action, blockId })
    }

    return (
        <ul className={classNames(styles.inlineControls, { [styles.isVisibleInlineControls]: isVisible })}>
            {!firstElement && (
                <li className={styles.control} onClick={() => onClick('up')} title="Move up">
                    <img src={icon_up} alt="up" />
                </li>
            )}
            {!lastElement && (
                <li className={styles.control} onClick={() => onClick('down')} title="Move down">
                    <img src={icon_down} alt="down" />
                </li>
            )}
            {!isHideClone && (
                <li className={styles.control} onClick={() => onClick('clone')} title="Clone">
                    <img src={icon_clone} alt="clone" />
                </li>
            )}
            <li className={styles.control} onClick={() => onClick('delete')} title="Remove" data-test="delete-block">
                <img src={icon_delete} alt="remove" />
            </li>
        </ul>
    )
}

export default InlineControls
