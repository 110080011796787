import { MAIN_SERVER } from './instance'

export const CREATE_ACTION = ({ projectId, blockId, actionName }) =>
    MAIN_SERVER({
        method: 'post',
        url: `/actions/user-action`,
        data: {
            actionTypeName: actionName,
            projectId,
            blockId,
        },
    })
