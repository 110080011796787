export const convertHexToRgb = hex => {
    const _hex = getFormattedHex(hex)
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex)
    if (!result) return null
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    }
}

export const convertHexToHsl = hex => {
    const _hex = getFormattedHex(hex)
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex)
    let r = parseInt(result[1], 16),
        g = parseInt(result[2], 16),
        b = parseInt(result[3], 16)

    r /= 255
    g /= 255
    b /= 255

    const max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    let h,
        s,
        l = (max + min) / 2

    if (max === min) {
        h = s = 0
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        h /= 6
    }

    return {
        h: Math.floor(h * 360),
        s: Math.floor(s * 100),
        l: Math.floor(l * 100),
    }
}

export const convertHslToHex = (h, s, l) => {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = n => {
        const k = (n + h / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0')
    }
    return `#${f(0)}${f(8)}${f(4)}`
}

const padZero = (str, length = 2) => {
    const zeros = new Array(length).join('0')
    return (zeros + str).slice(-length)
}
export const invertColor = (hex, isNotBW = false) => {
    const _hex = getFormattedHex(hex)
    let r = parseInt(_hex.slice(0, 2), 16),
        g = parseInt(_hex.slice(2, 4), 16),
        b = parseInt(_hex.slice(4, 6), 16)

    if (!isNotBW) return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000' : '#fff'

    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)
    return '#' + padZero(r) + padZero(g) + padZero(b)
}

export const getFormattedHex = hex => {
    let _hex = hex
    if (_hex.indexOf('#') === 0) _hex = _hex.slice(1)
    if (_hex.length === 3) _hex = _hex[0] + _hex[0] + _hex[1] + _hex[1] + _hex[2] + _hex[2]
    if (_hex.length !== 6) throw new Error('Invalid HEX color.')

    return _hex.toLowerCase()
}

export const isIdenticalHex = (hex1, hex2) => getFormattedHex(hex1) === getFormattedHex(hex2)

export const pickColor = (initialHex, darkHex, lightHex) => {
    if (!darkHex) throw new Error('Invalid darkHex')
    if (!lightHex) throw new Error('Invalid lightHex')

    const _initialHex = getFormattedHex(initialHex)

    let r = parseInt(_initialHex.slice(0, 2), 16),
        g = parseInt(_initialHex.slice(2, 4), 16),
        b = parseInt(_initialHex.slice(4, 6), 16)

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkHex : lightHex
}
