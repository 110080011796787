import React from 'react'
import { useTranslation } from 'react-i18next'

import BaseModal from './BaseModal/BaseModal'

const StorageLimit = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <BaseModal
            title={t('Account storage is full.')}
            description={t('Upgrade your tariff plan or free up space by removing unnecessary files.')}
            onClose={onClose}
        />
    )
}

export default StorageLimit
