import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import { isEmail } from 'utils/validator'

import Image from 'components/Form/Image'

import statisticImage from '../i/statistic.png'

const EmailBlock = ({ settings = { email: null }, isChecked, updateIntegration }) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState(settings.email)
    const [error, setError] = useState('')

    const changeValue = evt => {
        setEmail(evt.target.value)
        setError('')
    }

    const onSave = () => {
        if (isEmail(email)) {
            updateIntegration(true, { email })
        } else {
            setError(t('Invalid format'))
        }
    }

    const onDisable = () => {
        updateIntegration(false, { email: null })
    }

    const isDisabledSave = !email || isEqual(settings.email, email)

    return (
        <>
            <div className="content__title">{t('Email notifications')}</div>

            <div className="content__prompt">
                <div className="content__title">{t('Lead collection')}</div>
                <div className="content__sub-title">
                    {t(
                        'You can view statistics and export leads in CSV format at any time in the project statistics section.',
                    )}
                </div>
                <Image src={statisticImage} />
            </div>

            <div className="content__sub-title">
                {t('If you want to receive notifications about new leads, please enter your email in the field below.')}
            </div>

            <div className={`input-box ${error.length ? 'is-error' : ''}`}>
                <div className="title">{t('Email')}</div>
                <input autoComplete="off" type="text" value={email} onChange={evt => changeValue(evt)} />
                <p className="msg msg--error">{error}</p>
            </div>

            <div className="btns">
                <button
                    className="button"
                    data-size="md"
                    disabled={isDisabledSave}
                    onClick={onSave}
                    data-variant="primary"
                >
                    {t('Save')}
                </button>
                {isChecked ? (
                    <button className="button" data-size="md" data-variant="primary-danger" onClick={onDisable}>
                        {t('Disable')}
                    </button>
                ) : null}
            </div>
        </>
    )
}

export default EmailBlock
