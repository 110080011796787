import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { createCollector, createValidator, isPasswordRules } from 'shared/validation'

import Toast from 'components/Toast/Toast'

import { API__USER } from 'api'

import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormPasswordInput from 'components/Form/components/Helpers/FormPasswordInput/FormPasswordInput'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'

const SetPasswordForm = ({ userDetails, onSuccess = () => {} }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const [errors, setErrors] = useState({})

    const [isDisabledButton, setIsDisabledButton] = useState(false)

    useEffect(() => {
        if (password.length && password2.length) {
            setIsDisabledButton(false)
        } else {
            setIsDisabledButton(true)
        }
    }, [password, password2])

    const onSubmit = async () => {
        setIsLoading(true)
        setErrors({})

        try {
            if (password !== password2) {
                setErrors({
                    password: [t('Passwords must match')],
                    password2: [t('Passwords must match')],
                })
                return
            }

            const _password = password.trim()
            const fields = {
                password: _password,
            }
            const validationRules = {
                password: isPasswordRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__USER.CHANGE_PASSWORD(userDetails.id, {
                oldPassword: null,
                newPassword: _password,
            })

            setPassword('')
            setPassword2('')

            await onSuccess()
            Toast('success', {
                message: t('Your password is set successfully'),
            })
        } catch (err) {
            console.error(err)

            if (err.response) {
                const {
                    response: { status, data },
                } = err
                if (status === 400) {
                    if (data.message === 'New password is not valid') {
                        setErrors({
                            newPassword: [t('Password is not valid')],
                        })
                        return
                    }
                }
            }

            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form>
            <FormInputs>
                <FormInputBlock>
                    <FormPasswordInput
                        label={t('Password')}
                        value={password}
                        controlType="password"
                        errorMessages={errors.password || []}
                        onUpdate={v => {
                            setPassword(v)
                            setErrors({ ...errors, password: [] })
                        }}
                    />
                </FormInputBlock>
                <FormInputBlock>
                    <FormPasswordInput
                        label={t('Retype password')}
                        value={password2}
                        controlType="password"
                        errorMessages={errors.password2 || []}
                        onUpdate={v => {
                            setPassword2(v)
                            setErrors({ ...errors, password2: [] })
                        }}
                    />
                </FormInputBlock>
            </FormInputs>
            <FormButtons>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary"
                    disabled={isDisabledButton || isLoading}
                    onClick={() => onSubmit()}
                >
                    {t('Save')}
                </button>
            </FormButtons>
        </Form>
    )
}

export default SetPasswordForm
