import React from 'react'
import classNames from 'classnames'

import './Header.scss'

const Header = ({
    title,
    classes = [],
    onClose = () => {
        /**/
    },
    isCloseIcon = true,
    className,
    classNameTitle,
    classNameClose,
}) => (
    <div className={classNames('modal-header', classes.join(' '), { [className]: !!className })}>
        {title && (
            <div className={classNames('modal-header__title', { [classNameTitle]: !!classNameTitle })} title={title}>
                {title}
            </div>
        )}
        {!!isCloseIcon && (
            <div
                className={classNames('modal-header__close', { [classNameClose]: !!classNameClose })}
                onClick={() => onClose()}
            />
        )}
    </div>
)

export default Header
