import React from 'react'

import Text from '../../../components/Text/Text'

import styles from './Button.module.scss'

const Button = ({ blockId, backgroundColor, borderRadius, text, onUpdateText, propName = 'buttonText' }) => (
    <div className={styles.button} style={{ backgroundColor, borderRadius }}>
        <Text id={blockId} text={text} propName={propName} onUpdate={onUpdateText} isHideLink={true} />
    </div>
)

export default Button
