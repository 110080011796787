import { DEFAULT_IMAGE_BG_BIG_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'
import { createCollector, createValidator, itemRules, maxLength, minLength, required } from 'shared/validation'

export const MIN_CARDS = 1
export const MAX_CARDS = 60

export class Card {
    id
    coverImage
    disclaimer
    header
    description
    illustrationImage
    buttonText
    buttonLink

    constructor({
        coverImage = DEFAULT_IMAGE_BG_BIG_URL,
        disclaimer = '',
        header = '',
        description = '',
        illustrationImage = undefined,
        buttonText = '',
        buttonLink = '',
    }) {
        this.id = getUniqueId()
        this.coverImage = coverImage
        this.disclaimer = disclaimer
        this.header = header
        this.description = description
        this.illustrationImage = illustrationImage
        this.buttonText = buttonText
        this.buttonLink = buttonLink
    }

    static get coverImage() {
        return 'coverImage'
    }

    static get disclaimer() {
        return 'disclaimer'
    }

    static get header() {
        return 'header'
    }

    static get description() {
        return 'description'
    }

    static get illustrationImage() {
        return 'illustrationImage'
    }

    static get buttonText() {
        return 'buttonText'
    }

    static get buttonLink() {
        return 'buttonLink'
    }
}

function convertCardsErrorsToMap(cards, errors) {
    return new Map(
        cards.map((card, index) => {
            return [card.id, errors[index]]
        }),
    )
}

const cardRules = {
    header: [required(), maxLength(100)],
    coverImage: [required()],
}

const CardsBlockRules = {
    cards: [minLength(MIN_CARDS), maxLength(MAX_CARDS), itemRules(cardRules, convertCardsErrorsToMap)],
}

export const isValidCardsBlock = createValidator(CardsBlockRules)

export const collectCardsErrors = createCollector(CardsBlockRules)
