import React from 'react'
import ProjectListItem from '../ProjectListItem/ProjectListItem'
import PROJECT_TYPES from 'common/constants/ProjectTypes'
import { PROJECT_STATUSES } from 'common/constants/projects'

function createStubs(status) {
    const name = 'Stub Project Name'
    return [
        {
            id: -1,
            name: name,
            projectStatus: status,
            author: { email: '' },
            isRemoved: false,
            projectType: { projectType: PROJECT_TYPES.blockArticle },
        },
        {
            id: -2,
            name: name,
            projectStatus: status,
            author: { email: '' },
            isRemoved: false,
            projectType: { projectType: PROJECT_TYPES.blockArticle },
        },
        {
            id: -3,
            name: name,
            projectStatus: status,
            author: { email: '' },
            isRemoved: false,
            projectType: { projectType: PROJECT_TYPES.blockArticle },
        },
    ]
}

function StubProjectList() {
    const projectsStub = createStubs(PROJECT_STATUSES.DRAFT)
    return projectsStub.map(item => <ProjectListItem key={item.id} project={item} isLoading />)
}

export default StubProjectList
