import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'

import EditorModal from './components/EditorModal/EditorModal'

import styles from './Wrapper.module.scss'

const Wrapper = ({ schema, tooltipText, onSave, onCancel, isCanSave, text, fallbackText, children }) => {
    const [isOpenedModal, setIsOpenedModal] = useState(false)
    const { t } = useTranslation()

    return (
        <div className="control-box">
            {!!schema.label?.length && (
                <p className="label">
                    {schema.label}
                    {<Tooltip tooltipText={tooltipText} />}
                </p>
            )}
            <div className={styles.codeEditorBox}>
                <div className={classNames(styles.content, { [styles.isCodeContent]: !!text })}>
                    {text || fallbackText}
                </div>
                <div className={styles.button} onClick={() => setIsOpenedModal(true)}>
                    {!!text ? t('Edit') : t('Open editor')}
                </div>
            </div>
            {isOpenedModal && (
                <EditorModal
                    title={!!schema.label?.length && schema.label}
                    onSave={() => {
                        onSave()
                        setIsOpenedModal(false)
                    }}
                    onCancel={() => {
                        onCancel()
                        setIsOpenedModal(false)
                    }}
                    isCanSave={isCanSave}
                >
                    {children}
                </EditorModal>
            )}
        </div>
    )
}

export default Wrapper
