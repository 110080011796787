import React from 'react'
import classNames from 'classnames'

import Loader from 'components/Loader/Loader'

import styles from './Card.module.scss'

const Card = ({ title, isHalf = false, badge, control, info, text, isLoading, select, children }) => (
    <div className={classNames(styles.card, { [styles.isHalfCard]: isHalf })}>
        <div className={styles.head}>
            <div className={styles.leftHead}>
                <h2 className={styles.title}>
                    {title}
                    {!!badge && badge}
                </h2>
                {!!info && <div className={styles.info}>{info}</div>}
            </div>
            <div className={styles.rightHead}>
                {!!control && <div className={styles.control}>{control}</div>}
                {!!text && text}
            </div>
        </div>
        {!!select && select}
        {isLoading ? (
            <div className={styles.loader}>
                <Loader />
            </div>
        ) : (
            children
        )}
    </div>
)

export default Card
