import React from 'react'
import { throttle } from 'lodash'
import Image from 'components/Form/Image'
import { imgLoader } from 'common/services/imageLoader'
import { DEFAULT_IMAGE_BG_WIDE_URL } from 'common/constants/constants.js'

import './ZoomMap.scss'

function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min)
}

function normPos(c) {
    if (c === undefined) {
        c = getRandomArbitrary(0, 100)
    }
    if (c < 0) c = 0
    else if (c > 100) c = 100
    return c
}

function normalizePins(pins = [], count) {
    const pp = [...pins]
    if (count > 100) count = 100
    while (pp.length < count) {
        pp.push({
            ...pp[0],
            l: undefined,
            t: undefined,
            h: '',
            d: '',
            i: '',
            blink: '',
        })
    }
    return pp.map(p => {
        return {
            ...p,
            l: normPos(p.l),
            t: normPos(p.t),
        }
    })
}

function toPercent(widthPx, containerWidth) {
    if (typeof widthPx === 'number' && containerWidth >= 0) {
        return (widthPx / containerWidth) * 100
    }
    return undefined
}

const PROP = ['h', 'd', 'i', 'blink', 'btext']

class ZoomMap extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const pins = normalizePins(state.pins, props.count)

        if (props.pins) {
            // эти PROP меняются вне компонента ZoomMap, на боковой панели
            // переносим их сюда
            // так как позже из этого компонента будет вызван onUpdate целиком с массивом pins
            // поэтому надо содержать этот массив в акуальном состоянии
            for (let i = 0; i < props.pins.length && i < state.pins.length; i++) {
                PROP.forEach(p => {
                    pins[i][p] = props.pins[i][p]
                })
            }
        }
        return { pins }
    }

    constructor(props) {
        super(props)
        this.state = {
            pins: normalizePins(this.props.pins, this.props.count),
            selectedPinIndex: undefined,
        }
        this.thisRef = React.createRef()
        this.onPinClick = this.onPinClick.bind(this)
        // dragging/resizing
        this.isDragging = false
        this.mouseDownPinIndex = undefined
        this.mouseStartPosition = null
        this.draggedPinStartPosition = {}
        this.containerWidth = undefined
        this.containerHeight = undefined
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onWindowMouseMove = this.onWindowMouseMove.bind(this)
        this.onWindowMouseUp = this.onWindowMouseUp.bind(this)
        this.lastDataUpdate = null
    }

    onMouseDown(e) {
        this.isDragging = false
        this.mouseDownPinIndex = parseInt(e.target.getAttribute('dataindex'))
        this.setState({
            selectedPinIndex: this.mouseDownPinIndex,
        })
    }

    onWindowMouseMove(e) {
        if (this.mouseDownPinIndex >= 0) {
            if (!this.isDragging) {
                this.isDragging = true
                const rect = this.thisRef.current.getBoundingClientRect()
                this.containerWidth = rect.width
                this.containerHeight = rect.height
                this.draggedPinStartPosition = {
                    left: parseInt(this.state.pins[this.mouseDownPinIndex].l),
                    top: parseInt(this.state.pins[this.mouseDownPinIndex].t),
                }
                this.mouseStartPosition = {
                    left: e.clientX, //toPercent(e.clientX, this.props.containerWidth),
                    top: e.clientY,
                }
            }
            const dx = toPercent(e.clientX - this.mouseStartPosition.left, this.containerWidth)
            const dy = toPercent(e.clientY - this.mouseStartPosition.top, this.containerHeight)
            this.state.pins[this.mouseDownPinIndex].l = Number(this.draggedPinStartPosition.left + dx).toFixed(2)
            this.state.pins[this.mouseDownPinIndex].t = Number(this.draggedPinStartPosition.top + dy).toFixed(2)
            this.setState({
                pins: [...this.state.pins],
            })
        }
    }

    onWindowMouseUp() {
        if (this.mouseDownPinIndex >= 0) {
            this.isDragging = false
            this.mouseDownPinIndex = undefined
        } else {
            this.setState({
                selectedPinIndex: undefined,
            })
        }
    }

    componentDidMount() {
        this.sendUpdate()
        if (this.thisRef.current) {
            this.thisRef.current.addEventListener('mousemove', throttle(this.onWindowMouseMove, 25))
            this.thisRef.current.addEventListener('mouseup', throttle(this.onWindowMouseUp, 25))
        }
    }
    componentDidUpdate() {
        if (!this.isDragging) {
            this.sendUpdate()
        }
    }

    sendUpdate() {
        const d = { data: { ['pins']: this.state.pins } },
            j = JSON.stringify(d)

        if (j !== this.lastDataUpdate) {
            this.lastDataUpdate = j
            this.props.onUpdate({
                [this.props.id]: d,
            })
        }
    }

    componentWillUnmount() {
        if (this.thisRef.current) {
            this.thisRef.current.removeEventListener('mousemove', throttle(this.onWindowMouseMove, 25))
            this.thisRef.current.removeEventListener('mouseup', throttle(this.onWindowMouseUp, 25))
        }
    }

    onPinClick(evt) {
        const { requestArrayElementControls, id } = this.props

        requestArrayElementControls(id, 'pins', evt.target.getAttribute('dataindex'))
        evt.preventDefault()
        evt.stopPropagation()
    }

    render() {
        const { bimg, height, psize, pimg, count, pcl } = this.props
        const { pins, selectedPinIndex } = this.state
        const s = {
            height: `${height}px`,
        }
        return (
            <div className="z_cnt" ref={this.thisRef} style={s}>
                {pins.slice(0, count).map((p, i) => {
                    const selected = i === selectedPinIndex
                    const ps = {
                        width: `${psize}px`,
                        height: `${psize}px`,
                        left: `${p.l}%`,
                        top: `${p.t}%`,
                        backgroundColor: pcl,
                        transform: `translate(-${psize / 2}px, -${psize / 2}px)`,
                    }
                    if (pimg) {
                        ps.backgroundImage = `url(${imgLoader(pimg)})`
                        ps.backgroundColor = 'transparent'
                        ps.borderRadius = 0
                        ps.border = 'none'
                        ps.boxShadow = 'none'
                    }
                    return (
                        <div
                            key={i}
                            dataindex={i}
                            className={`z_pin${i === selectedPinIndex ? ' __sel' : ''}`}
                            style={ps}
                            onClick={this.onPinClick}
                            onMouseDown={this.onMouseDown}
                            onMouseUp={this.onMouseUp}
                        >
                            {selected && <div className="s _1"></div>}
                            {selected && <div className="s _2"></div>}
                            {selected && <div className="s _3"></div>}
                            {selected && <div className="s _4"></div>}
                        </div>
                    )
                })}
                <Image src={bimg || DEFAULT_IMAGE_BG_WIDE_URL} />
            </div>
        )
    }
}

export default ZoomMap
