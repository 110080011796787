export const PROJECT_STATUSES = {
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT',
    TEMPLATE: 'TEMPLATE',
}
export const MULTIPLAYER_GAME_STATUSES = {
    NEW: 'NEW',
    WAITING_PLAYERS: 'WAITING_PLAYERS',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    CREATION_ERROR: 'CREATION_ERROR',
}
