import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { IconPlay } from 'svg/index.js'

import audioFile from '../../../../../../i/audio_file.svg'
import { CARD_TYPES } from '../../../../../components/Modals/MemoryCardsModal/utils/constants'

import iconArrow from './i/arrow.svg'

import { getClassSizeCardText, getCardSize } from './utils'

import styles from './CardBox.module.scss'

const CardBox = ({
    isEmpty = false,
    pairsCount,
    width = 0,
    bgColor,
    color,
    cardType,
    text,
    imageSrc,
    isStatic,
    colorTheme,
}) => {
    const [_styles, setStyles] = useState({})

    useEffect(() => {
        const newStyles = {
            width: getCardSize(pairsCount, width),
            height: getCardSize(pairsCount, width),
        }
        if (isEmpty) {
            newStyles.borderStyle = 'dashed'
            newStyles.backgroundImage = `url(${iconArrow})`
            newStyles.backgroundSize = '35%'
        } else {
            if (cardType === CARD_TYPES.text) {
                newStyles.color = color
                if (bgColor) newStyles.backgroundColor = bgColor
            }
            if (cardType === CARD_TYPES.image) {
                newStyles.backgroundImage = `url(${imageSrc})`
            }
            if (cardType === CARD_TYPES.audio) {
                newStyles.backgroundImage = `url("${audioFile}")`
                newStyles.backgroundPosition = 'center'
                newStyles.backgroundSize = '70%'
                newStyles.backgroundRepeat = 'no-repeat'
                newStyles.backgroundColor = colorTheme
            }
        }
        if (isStatic) {
            newStyles.border = 0
            if (cardType === CARD_TYPES.text) {
                newStyles.justifyContent = 'left'
            }
        }
        setStyles(newStyles)
    }, [cardType, imageSrc, bgColor, isEmpty, width, pairsCount, isStatic, colorTheme])

    return (
        <div className={styles.cardBox} style={_styles}>
            {cardType === CARD_TYPES.text && (
                <span className={classNames(styles.cardText, styles[getClassSizeCardText(text.length)])}>{text}</span>
            )}
            {cardType === CARD_TYPES.audio && (
                <div className={styles.cardAudio}>
                    <IconPlay />
                </div>
            )}
        </div>
    )
}

export default CardBox
