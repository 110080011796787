import React from 'react'
import { useTranslation } from 'react-i18next'
import Switch from '../../../Form/Switch/Switch'
import StatusIndicator from '../../../StatusIndicator/StatusIndicator'

import icon from '../../i/webhooks_icon.png'

import styles from './WebhooksIntegrations.module.scss'

const WebhooksIntegrations = ({ isEnabled, openManager }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.WebhooksIntegrations}>
            <div className={styles.group}>
                <img src={icon} alt="webhooks" width={44} height={44} />
                <p className={styles.name}>Webhooks</p>
            </div>
            <div className={styles.group}>
                <Switch checked={isEnabled} />
                <StatusIndicator hideLed={true} isConnected={isEnabled} />
                <button onClick={openManager}>{t('Set up')}</button>
            </div>
        </div>
    )
}

export default WebhooksIntegrations
