import i18n from 'i18n'

export const SELLING_TYPES = {
    SELL_AS_INTERACTY_TEMPLATE: 'SELL_AS_INTERACTY_TEMPLATE',
    SELL_FOR_TEACHERS: 'SELL_FOR_TEACHERS',
    SELL_FOR_STUDENTS: 'SELL_FOR_STUDENTS',
}

export const SELLING_TYPES_DATA = {
    [SELLING_TYPES.SELL_AS_INTERACTY_TEMPLATE]: {
        title: i18n.t('Sell as Interacty template'),
        description: i18n.t(
            'charges when somebody clones template on Interacty, they can create own projects using your template',
        ),
        defaultPriceValue: 5,
        prices: [2, 5, 8, 10, 15],
    },
    [SELLING_TYPES.SELL_FOR_TEACHERS]: {
        title: i18n.t('Sell for teachers'),
        description: i18n.t('teacher may use your content for professional needs (not pass to other teachers)'),
        defaultPriceValue: 2,
        prices: [2, 5, 8, 10, 15],
    },
    [SELLING_TYPES.SELL_FOR_STUDENTS]: {
        title: i18n.t('Sell for students'),
        description: i18n.t('student purchase an access to your content'),
        defaultPriceValue: 2,
        prices: [2, 5, 8, 10, 15],
    },
}

export const SUBJECTS = [
    {
        value: 'ART_AND_MUSIC',
        label: 'Arts & Music',
    },
    {
        value: 'ENGLISH_LANGUAGE_ARTS',
        label: 'English Language Arts',
    },
    {
        value: 'WORLD_LANGUAGE',
        label: 'World Language',
    },
    {
        value: 'MATH',
        label: 'Math',
    },
    {
        value: 'SCIENCE',
        label: 'Science',
    },
    {
        value: 'SOCIAL_STUDIES_HISTORY',
        label: 'Social Studies - History',
    },
    {
        value: 'SPECIALTY',
        label: 'Specialty',
    },
    {
        value: 'HOLIDAYS_SEASONAL',
        label: 'Holidays / Seasonal',
    },
]

export const GRADE = [
    {
        value: 'PREK',
        label: 'PreK',
    },
    {
        value: 'K',
        label: 'K',
    },
    {
        value: '1ST',
        label: '1st',
    },
    {
        value: '2ND',
        label: '2nd',
    },
    {
        value: '3RD',
        label: '3rd',
    },
    {
        value: '4TH',
        label: '4th',
    },
    {
        value: '5TH',
        label: '5th',
    },
    {
        value: '6TH',
        label: '6th',
    },
    {
        value: '7TH',
        label: '7th',
    },
    {
        value: '8TH',
        label: '8th',
    },
    {
        value: '9TH',
        label: '9th',
    },
    {
        value: '10TH',
        label: '10th',
    },
    {
        value: '11TH',
        label: '11th',
    },
    {
        value: '12TH',
        label: '12th',
    },
    {
        value: 'NOT_GRADE_SPECIFIC',
        label: 'Not Grade Specific',
    },
    {
        value: 'HIGHER_EDUCATION',
        label: 'Higher Education',
    },
    {
        value: 'ADULT_EDUCATION',
        label: 'Adult Education',
    },
    {
        value: 'HOMESCHOOL',
        label: 'Homeschool',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
]
