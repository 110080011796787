import React from 'react'
import { useTranslation } from 'react-i18next'

import i18n from 'i18n'
import { getFullUrlToSsr } from 'utils/router'

import CheckMarkControl from './components/Controls/CheckMark/CheckMark'
import NumberControl from './components/Controls/Number/Number'

import Table from './components/Table/Table'
import Banner from './components/Banner/Banner'

import { FEATURE_TYPES } from 'common/constants/subscription'

const FEATURES = {
    [FEATURE_TYPES.ALL_PROJECT_TYPES]: {
        isFake: true,
        label: i18n.t('All Interactive project types'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.WEBSITES]: {
        label: i18n.t('Web sites'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.TEAM]: {
        label: i18n.t('Team members'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.LEAD_CONTACTS]: {
        label: i18n.t('Lead contacts / month'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.REVIEWS_COUNT]: {
        label: i18n.t('Pageviews / month'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.STORAGE_SIZE]: {
        label: i18n.t('Storage Size'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.PREMIUM_TEMPLATES_COUNT]: {
        label: i18n.t('Premium templates / month'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.MULTIPLAYER_PLAYERS]: {
        label: i18n.t('Synchronous player limit in game events'),
        getControl: feature => <NumberControl feature={feature} />,
    },
    [FEATURE_TYPES.GAMIFICATION]: {
        label: i18n.t('Gamification (leaderboard and more)'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.CUSTOM_PROJECT_URL]: {
        label: i18n.t('Custom project ID'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.INTEGRATIONS]: {
        label: i18n.t('Integrations'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.CUSTOM_DOMAINS]: {
        label: i18n.t('Custom Domains'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.FULL_STATISTICS]: {
        label: i18n.t('Advanced statistics'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.NO_LOGO]: {
        label: i18n.t('White label'),
        getControl: () => <CheckMarkControl />,
    },
    [FEATURE_TYPES.CUSTOM_CSS_STYLES]: {
        label: i18n.t('Custom CSS styles'),
        getControl: () => <CheckMarkControl />,
    },
}

const AppSumo = () => {
    const { t } = useTranslation()

    return (
        <>
            <Table features={FEATURES} />
            <Banner
                title={t('All special plans and features')}
                description={t('Compare the features of all our special lifetime plans')}
                buttonText={t('All lifetime plans')}
                buttonLink={getFullUrlToSsr('/pricing/appsumo')}
            />
        </>
    )
}

export default AppSumo
