import React from 'react'

export const Preview = ({ onClick, imgSrc, label }) => {
    return (
        <div className="tips__preview" onClick={onClick}>
            <div className="tips__preview-icon">
                <img className="tips__preview-icon-img" src={imgSrc} alt="Lamp" />
            </div>
            <div className="tips__preview-text">{label}</div>
        </div>
    )
}
