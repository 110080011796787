import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'

import { GET_BANKS } from 'api/questionsBanks'

import searchIcon from './i/search.svg'

import { TABS } from './constants'

import styles from './ChooseQuestionBankModal.module.scss'

const ChooseQuestionBankModal = ({ onSelect, onClose }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [selected, setSelected] = useState(null)
    const [nameFilter, setNameFilter] = useState('')
    const [activeTab, setActiveTab] = useState(TABS.ALL)

    useEffect(() => {
        setSelected(null)
        setIsLoading(true)

        if (activeTab === TABS.ALL) {
            Promise.all([GET_BANKS(true), GET_BANKS(false)])
                .then(([systemBanks, userBanks]) => {
                    setList([...userBanks.content, ...systemBanks.content])
                })
                .catch(err => console.error(err))
                .finally(() => setIsLoading(false))
        } else {
            GET_BANKS(activeTab === TABS.SYSTEM)
                .then(({ content }) => {
                    setList(content)
                })
                .catch(err => console.error(err))
                .finally(() => setIsLoading(false))
        }
    }, [activeTab])

    useEffect(() => {
        setFilteredList(list.filter(bank => bank.name.toLowerCase().match(new RegExp(nameFilter.toLowerCase(), 'g'))))
    }, [list, nameFilter])

    const onClick = bank => {
        if (!selected) setSelected(bank)
        else {
            if (selected.bankId === bank.bankId) setSelected(null)
            else setSelected(bank)
        }
    }

    return (
        <Modal onClose={() => onClose()}>
            <div className={styles.chooseQuestionBank}>
                <Header title={t('Question banks')} onClose={() => onClose()} />
                <div className={styles.body}>
                    <ScrollableBlock>
                        <div className={styles.bodyContent}>
                            <div className={styles.head}>
                                <p className={styles.title}>{t('Select question list')}</p>
                                <ul className={styles.tabs}>
                                    <li
                                        className={classNames(styles.tab, {
                                            [styles.activeTab]: activeTab === TABS.ALL,
                                        })}
                                        onClick={() => setActiveTab(TABS.ALL)}
                                    >
                                        {t('All')}
                                    </li>
                                    <li
                                        className={classNames(styles.tab, {
                                            [styles.activeTab]: activeTab === TABS.SYSTEM,
                                        })}
                                        onClick={() => setActiveTab(TABS.SYSTEM)}
                                    >
                                        {t('System')}
                                    </li>
                                    <li
                                        className={classNames(styles.tab, {
                                            [styles.activeTab]: activeTab === TABS.MY,
                                        })}
                                        onClick={() => setActiveTab(TABS.MY)}
                                    >
                                        {t('Created by me')}
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.search}>
                                <img className={styles.searchIcon} src={searchIcon} alt="search" />
                                <input
                                    className={styles.searchInput}
                                    type="text"
                                    placeholder={t('Search')}
                                    value={nameFilter}
                                    onChange={evt => setNameFilter(evt.target.value)}
                                />
                            </div>
                            <ul className={classNames(styles.banksList, { [styles.isLoadingBanksList]: isLoading })}>
                                {filteredList.map(bank => (
                                    <li
                                        key={bank.bankId}
                                        className={classNames(styles.bank, {
                                            [styles.selectedBank]: selected?.bankId === bank.bankId,
                                        })}
                                        onClick={() => onClick(bank)}
                                    >
                                        {bank.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </ScrollableBlock>
                    <div className={classNames(styles.selectBlock, { [styles.activeSelectBlock]: !!selected })}>
                        <button
                            className="button"
                            data-size="lg"
                            data-variant="primary"
                            onClick={() => onSelect(selected)}
                        >
                            {t('Use question list')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ChooseQuestionBankModal
