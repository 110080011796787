import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFullUrlToSsr } from 'utils/router'

import CardsGroup from './components/CardsGroup/CardsGroup'
import Card from './components/Card/Card'

import individualsImage from './i/education/individuals.png'
import organizationsImage from './i/education/organizations.png'

const EducationPlans = () => {
    const { t } = useTranslation()

    const getHref = group => `${getFullUrlToSsr()}/pricing/education?group=${group}`

    return (
        <CardsGroup>
            <Card
                imageSrc={individualsImage}
                title={t('For individuals')}
                description={t(
                    'Create engaging learning materials for the 21st century. Quizzes, learning games, timelines and worksheets will help your students achieve excellent results.',
                )}
                href={getHref('individuals')}
            />
            <Card
                imageSrc={organizationsImage}
                title={t('For organizations')}
                description={t(
                    'For teams from schools, universities and online learning organizations.\nCo-create and use interactive learning materials within the same organization.',
                )}
                href={getHref('organization')}
            />
        </CardsGroup>
    )
}

export default EducationPlans
