import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from './SelectOrganization.module.scss'

const SelectOrganization = ({ onSelect, onCancel = () => {}, title, description }) => {
    const { t } = useTranslation()
    const {
        organizations: { organizationList },
    } = useSelector(state => state)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOrgId, setSelectedOrgId] = useState()
    const [isShowList, setIsShowList] = useState(false)

    useEffect(() => {
        if (organizationList.length === 1) {
            onSelect(organizationList[0].id)
            return
        }

        setIsShowList(true)
    }, [])

    if (!isShowList) return null

    return (
        <div className={styles.selectOrganizationWrapper}>
            <div className={styles.selectOrganization}>
                <h1 className={styles.selectOrganizationTitle}>{title || t('Creating a new project...')}</h1>
                <h2 className={styles.selectOrganizationDescription}>
                    {description || t('Select the organization where the project will be hosted.')}
                </h2>
                <ul className={styles.selectOrganizationList}>
                    {organizationList.map(organization => (
                        <li
                            className={classNames(styles.selectOrganizationItem, {
                                [styles.selectOrganizationItemSelected]: organization.id === selectedOrgId,
                            })}
                            key={organization.id}
                            onClick={() => setSelectedOrgId(organization.id)}
                        >
                            <div className={styles.selectOrganizationItemImage}>
                                {organization.logoURL ? (
                                    <img src={organization.logoURL} alt="logo of team" />
                                ) : (
                                    <span>{organization.name.charAt(0).toUpperCase()}</span>
                                )}
                            </div>
                            {organization.name}
                        </li>
                    ))}
                </ul>
                <div className={styles.selectOrganizationButtons}>
                    <button className="button" data-size="lg" data-variant="secondary" onClick={() => onCancel()}>
                        {t('Cancel')}
                    </button>
                    <button
                        className="button"
                        data-size="lg"
                        data-variant="primary"
                        disabled={!selectedOrgId || isLoading}
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await onSelect(selectedOrgId)
                            } catch (err) {
                                console.error(err)
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                    >
                        {t('Continue')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SelectOrganization
