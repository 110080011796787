import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { mapRankBattleStatistic } from './utils'
import { CARD_TYPES } from './constants'

import styles from './Statistics.module.scss'

const Statistics = ({ statistic, blockProjection }) => {
    const { t } = useTranslation()
    const [_statistic, _setStatistic] = useState(null)

    useEffect(() => {
        const mappedStat = mapRankBattleStatistic(statistic, blockProjection)
        _setStatistic(mappedStat)
    }, [statistic, blockProjection])

    return (
        <div className={styles.statistics}>
            <div className={styles.statisticsMain}>
                <div className={classNames(styles.statisticsMainBlockWrapper, styles.statisticsMainBlockHeader)}>
                    <p className={styles.statisticsMainBlockTitle}>{t('Cards')}</p>
                    <div className={styles.statisticsMainBlockValues}>{t('Votes')}</div>
                </div>
                <ul className={styles.statisticsBlockList}>
                    {_statistic?.cards.map(card => (
                        <li key={card.id} className={styles.statisticsBlockListItem}>
                            <div className={styles.statisticsMainBlockWrapper}>
                                <p
                                    className={styles.statisticsMainBlockTitle}
                                    title={card.type === CARD_TYPES.TEXT ? card.text : t('Card')}
                                >
                                    {card.type === CARD_TYPES.IMAGE && (
                                        <div
                                            className={styles.statisticsMainBlockImage}
                                            style={{
                                                backgroundImage: `url(${card.imageUrl})`,
                                            }}
                                        />
                                    )}
                                    {card.type === CARD_TYPES.TEXT && card.text}
                                </p>
                                <div className={styles.statisticsMainBlockValues}>{card.votesCount}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Statistics
