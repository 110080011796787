import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import imageMockup from './i/mockup.png'

import { isEducation } from 'common/utils/permissions.js'

import styles from './Upgrade.module.scss'

const Upgrade = () => {
    const { t } = useTranslation()

    const [text] = useState(
        isEducation()
            ? t(
                  'The Intermediate and Advanced tariff plans allows you to create leaderboards and collect data about players and their results.',
              )
            : t(
                  'The PRO + tariff plan allows you to create leaderboards and collect data about players and their results.',
              ),
    )
    const [buttonText] = useState(isEducation() ? t('Upgrade to Intermediate') : t('Upgrade to PRO +'))

    return (
        <div className={styles.upgrade}>
            <p className={styles.note}>
                {t('Leaderboard hasn’t been activated. Use "Gamification" toggle in the Editor')}
            </p>
            <div className={styles.title}>{t('Did you know?')}</div>
            <div className={styles.text}>
                {text}{' '}
                {t('When the Gamification functionality is enabled, a table of players will appear in this section.')}
            </div>
            <div className={styles.upgradeButton}>
                <Link
                    to="/billing-and-payments?tab=subscription"
                    className="button-style"
                    data-size="md"
                    data-variant="primary"
                >
                    {buttonText}
                </Link>
            </div>
            <div className={styles.image}>
                <img src={imageMockup} alt="mockup" />
            </div>
        </div>
    )
}

export default Upgrade
