import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import photoIcon from './i/photo.svg'

import ChooseImageModal from './components/ChooseImageModal/ChooseImageModal'

import styles from './ChooseImageBox.module.scss'

export const POSITIONS = {
    TOP_RIGHT: 'TOP_RIGHT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
}

const ChooseImageBox = ({
    title,
    selectedImageUrl,
    onChange,
    isLoading,
    urlsArray,
    position = POSITIONS.TOP_RIGHT,
}) => {
    const { t } = useTranslation()

    const [isOpenedModal, setIsOpenedModal] = useState(false)

    return (
        <>
            <div
                className={classNames(styles.chooseImageBox, {
                    [styles.isDisabled]: isLoading,
                    [styles.isTopRightPosition]: position === POSITIONS.TOP_RIGHT,
                    [styles.isBottomRightPosition]: position === POSITIONS.BOTTOM_RIGHT,
                })}
                onClick={() => setIsOpenedModal(true)}
                title={t('Change cover')}
            >
                <img src={photoIcon} alt="photo" />
            </div>
            {isOpenedModal && (
                <ChooseImageModal
                    title={title || t('Change cover')}
                    selectedImageUrl={selectedImageUrl}
                    onSelect={url => {
                        onChange(url)
                        setIsOpenedModal(false)
                    }}
                    urlsArray={urlsArray}
                    onClose={() => setIsOpenedModal(false)}
                />
            )}
        </>
    )
}

export default ChooseImageBox
