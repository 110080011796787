import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'

import { IconCopy } from 'svg'

import { downloadFile } from 'common/utils/files'

import EMAILS from 'common/constants/emails'

import './QrCode.scss'

const QrCode = ({ projectUrl }) => {
    const { t } = useTranslation()
    const ref = useRef()

    const downloadQrCode = () => {
        if (ref && ref.current) {
            const qrCodeURL = ref.current.children[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
            downloadFile(qrCodeURL, 'QR_Code.png')
        }
    }
    return (
        <div className="qr-code-tab">
            <div className="qr-code-tab__content">
                <div ref={ref}>
                    <QRCode value={projectUrl} size={250} includeMargin />
                </div>

                <button
                    className="button qr-code-tab__download-button"
                    data-variant="primary-light"
                    data-size="lg"
                    onClick={downloadQrCode}
                >
                    <span className="share-project-tab__url-zone-button-icon">
                        <IconCopy />
                    </span>
                    {t('Download QR code')}
                </button>
            </div>
            <div className="qr-code-tab__contact">
                {t('Have problems?')} <a href={`mailto:${EMAILS.INTERACTY_SUPPORT}`}>{t('Contact us')}</a>
            </div>
        </div>
    )
}

export default QrCode
