import React from 'react'
import classNames from 'classnames'

import styles from './PricesList.module.scss'

const PricesList = ({ isDisabled, isDarken, children }) => (
    <ul className={classNames(styles.pricesList, { [styles.isDisabledPricesList]: isDisabled || isDarken })}>
        {children}
    </ul>
)

export default PricesList
