import React from 'react'

import iconCheckMark from './i/checkmark.svg'

import styles from './CheckMark.module.scss'

const CheckMarkControl = () => (
    <div className={styles.checkMark}>
        <img src={iconCheckMark} alt="" />
    </div>
)

export default CheckMarkControl
