import React from 'react'
import { useTranslation } from 'react-i18next'

import { getAvatarText } from 'common/utils/getAvatarText.js'
import { ALL_PROJECTS_MEMBER } from '../../../../utils/constants.js'

import './UserList.scss'

const UserList = ({ selectedMember, memberList, onSelectMember, currentUserId }) => {
    const { t } = useTranslation()
    const allProjectCount = memberList.reduce((count, member) => count + member.projectsCount, 0)
    return (
        <ul className="organization-panel__user-list">
            <li
                className={`organization-panel__user ${selectedMember.id === null ? 'selected' : ''}`}
                onClick={() => onSelectMember(ALL_PROJECTS_MEMBER)}
            >
                <p className="organization-panel__user-title">{t(ALL_PROJECTS_MEMBER.name)}</p>
                <p className="organization-panel__user-projects">{allProjectCount}</p>
            </li>
            {memberList.map(member => {
                const isCurrentUser = member.userId === currentUserId
                return (
                    <li
                        className={`organization-panel__user ${selectedMember.id === member.id ? 'selected' : ''}`}
                        key={member.userId}
                        onClick={() => onSelectMember(member)}
                    >
                        <div
                            className="organization-panel__user-icon"
                            style={{
                                backgroundColor: member.avatarUrl ? '#fff' : '#2990fb',
                                backgroundImage: member.avatarUrl ? `url(${member.avatarUrl})` : 'none',
                            }}
                        >
                            {!member.avatarUrl && getAvatarText(member)}
                        </div>
                        <p className="organization-panel__user-title" title={member.name}>
                            {member.name}
                            {isCurrentUser && <span className="organization-panel__user-postfix">{t('(You)')}</span>}
                        </p>
                        <p className="organization-panel__user-projects">{member.projectsCount}</p>
                    </li>
                )
            })}
        </ul>
    )
}

export default UserList
