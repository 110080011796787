import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import icon from './i/mailchimp-large.png'
import { FIELD_KEYS } from 'pages/Editor/Tabs/Editor/Modals/LeadFormModal/constants'
import { generateColumns, generateRows } from './utilities'
import Select, { VARIANT } from '../Form/Select/Select'
import './MailChimpMappingView.scss'

function MailchimpMappingView({
    lists,
    selectedListId,
    leadFormFields,
    mailchimpFields,
    fieldMap,
    onSelectList,
    onFieldSelected,
}) {
    const { t } = useTranslation()
    const selectedList = lists.find(item => item.value === selectedListId)

    const dropDownOptions = useMemo(() => {
        const mappedTags = Object.values(fieldMap)
        return mailchimpFields
            .filter(field => !mappedTags.includes(field.tag))
            .map(field => {
                return { value: field.tag, label: field.name }
            })
    }, [mailchimpFields, fieldMap])

    const mailchimpMap = useMemo(() => {
        return new Map(mailchimpFields.map(f => [f.tag, f.name]))
    }, [mailchimpFields])

    const dropDownColumnCellRender = params => {
        if (params.id === FIELD_KEYS.EMAIL) return null

        return (
            <Select
                placeholder={t('Select')}
                value={params.value}
                isDisabled={selectedListId === null}
                onUpdate={val => onFieldSelected(val, params.id)}
                options={dropDownOptions}
                fullWide
            />
        )
    }

    const columns = generateColumns(dropDownColumnCellRender, 'columnHeader')
    const rows = generateRows(leadFormFields, fieldMap, mailchimpMap)

    return (
        <div className="MailchimpMappingView">
            <aside className="aside">
                <img src={icon} alt="mailchimp" />
            </aside>
            <div className="main">
                <Select
                    value={selectedList}
                    options={lists}
                    fullWide
                    placeholder={t('Proceed with list')}
                    onUpdate={onSelectList}
                    variant={VARIANT.big}
                />
                <div>
                    <h1 className="tableName">{t('Map fields')}</h1>
                    <DataGrid
                        className="MuiDataGrid-root"
                        headerHeight={44}
                        rowHeight={44}
                        getRowClassName={() => 'tableRow'}
                        rows={rows}
                        columns={columns}
                        autoHeight={true}
                        hideFooter={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default MailchimpMappingView
