export const SUBSCRIPTION_TYPES = {
    GLOBAL: 'GLOBAL',
    PROJECT: 'PROJECT',
}

export const SUBSCRIPTION_BILLING_TYPES = {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
}

export const FEATURE_TYPES = {
    ALL_PROJECT_TYPES: 'ALL_PROJECT_TYPES',
    WEBSITES: 'WEBSITES',
    TEAM: 'TEAM',
    LEAD_CONTACTS: 'LEAD_CONTACTS',
    REVIEWS_COUNT: 'REVIEWS_COUNT',
    STORAGE_SIZE: 'STORAGE_SIZE',
    PREMIUM_TEMPLATES_COUNT: 'PREMIUM_TEMPLATES_COUNT',
    GAMIFICATION: 'GAMIFICATION',
    CUSTOM_PROJECT_URL: 'CUSTOM_PROJECT_URL',
    INTEGRATIONS: 'INTEGRATIONS',
    CUSTOM_DOMAINS: 'CUSTOM_DOMAINS',
    FULL_STATISTICS: 'FULL_STATISTICS',
    NO_LOGO: 'NO_LOGO',
    PROJECTS_COUNT: 'PROJECTS_COUNT',
    MULTIPLAYER_PLAYERS: 'MULTIPLAYER_PLAYERS',
    CUSTOM_CSS_STYLES: 'CUSTOM_CSS_STYLES',
}
