import React from 'react'
import { useTranslation } from 'react-i18next'

import BaseModal from './BaseModal/BaseModal'

const ViewsLimits = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <BaseModal
            title={t('You have reached the limit of project views per month.')}
            description={t('Upgrade your tariff plan to get more views.')}
            onClose={onClose}
        />
    )
}

export default ViewsLimits
