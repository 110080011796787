import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'
import Loader from 'components/Loader/Loader'

import { API__USER } from 'api'

import FollowerItem from './FollowerItem/FollowerItem'

import styles from './FollowingModal.module.scss'

const FollowingModal = ({ onClose, onFollow, onUnfollow }) => {
    const { t } = useTranslation()
    const { user_details } = useSelector(state => state)
    const [isReady, setIsReady] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        API__USER.GET_USER_PUBLIC_PROFILE_FOLLOWERS(user_details.id, { size: 1000, page: 0 })
            .then(({ content }) => setData(content))
            .catch(err => console.error(err))
            .finally(() => setIsReady(true))
    }, [])

    return (
        <Modal isShowCloseIcon onClose={onClose}>
            <div className={styles.followingModal}>
                <h2 className={styles.title}>{t('Following')}</h2>
                {isReady ? (
                    <ul className={styles.list}>
                        {data.map(item => (
                            <FollowerItem data={item} onFollow={onFollow} onUnfollow={onUnfollow} />
                        ))}
                    </ul>
                ) : (
                    <Loader />
                )}
            </div>
        </Modal>
    )
}

export default FollowingModal
