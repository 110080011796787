import React, { useState } from 'react'

import MatchingModal from '../components/Modals/MatchingModal/MatchingModal'

import Preview from './components/Preview/Preview'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'
import StatisticPreview from '../components/StatisticPreview/StatisticPreview'

const Matching = ({ struct, colorTheme, shuffleCards, onUpdate, id, enableTimer }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            {enableTimer && <StatisticPreview />}
            <Preview
                data={{
                    colorTheme,
                    shuffleCards,
                    pairList: struct.pairs.pairList,
                }}
            />
            {isShowModal && (
                <MatchingModal
                    data={{
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Matching
