import CURRENCIES from 'common/constants/currencies'

import { MAIN_SERVER } from './instance'

export const ACTIVATE_EDUCATION_FREE = orgId =>
    MAIN_SERVER({
        method: 'post',
        url: '/subscriptions/assign-education-free',
        data: { organizationId: orgId },
    })

export const ACTIVATE_TRIAL = orgId =>
    MAIN_SERVER({
        method: 'post',
        url: '/subscriptions/activate-trial',
        data: { organizationId: orgId },
    })

export const GET_ONE_TIME_PRODUCTS = (currency = CURRENCIES.USD) =>
    MAIN_SERVER({
        method: 'get',
        url: '/products/one-project',
        params: {
            currency,
        },
    })
