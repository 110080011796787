import { useContext, useEffect, useState } from 'react'
import { isHintShown } from 'utils/hintsManager'

import { HintContext } from './HintContext'

const useHint = (hintsProps = []) => {
    const { hints: contextHints, setHints: setContextHints } = useContext(HintContext)
    const [hintNames] = useState(() => hintsProps.map(hint => hint.hintName))

    useEffect(() => {
        if (Array.isArray(hintsProps)) {
            const checkedHints = hintsProps.filter(props => props.isShow && !isHintShown(props.hintName))
            setContextHints([...contextHints, ...checkedHints])
        }

        return () => {
            if (Array.isArray(hintNames)) {
                const newHints = contextHints.filter(props => hintNames.includes(props.hintName))
                setContextHints(newHints)
            }
        }
    }, [])
}

export default useHint
