import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import StopPanel from 'components/Modal/components/StopPanel/StopPanel'

import girlImage from './i/girl.svg'

export const VALIDATION_WARNING_TYPES = {
    ACTIVE_TAB: 'ACTIVE_TAB',
    COMMON: 'COMMON',
}

export const useStopPanes = () => {
    const [isShowQuitAccept, setIsShowQuitAccept] = useState(false)
    const [validationWarningType, setValidationWarningType] = useState(null)

    const onClosePanels = () => {
        setIsShowQuitAccept(false)
        setValidationWarningType(null)
    }
    const onShowQuitAccept = () => {
        setIsShowQuitAccept(true)
    }

    return {
        validationWarningType,
        setValidationWarningType,
        isShowQuitAccept,
        onShowQuitAccept,
        onClosePanels,
    }
}

const StopPanels = ({ isShowQuitAccept = false, validationWarningType = null, onBack, onQuit }) => {
    const { t } = useTranslation()

    return (
        <>
            {isShowQuitAccept && <StopPanel onBack={onBack} onQuit={onQuit} />}
            {validationWarningType === VALIDATION_WARNING_TYPES.ACTIVE_TAB && (
                <StopPanel
                    headText={t('Oh! Need more information')}
                    descriptionText={t('Please fill in all required fields on this tab correctly')}
                    image={girlImage}
                    onBack={onBack}
                    isShowQuit={false}
                />
            )}
            {validationWarningType === VALIDATION_WARNING_TYPES.COMMON && (
                <StopPanel
                    headText={t('Oh, no!')}
                    descriptionText={t('Please fill all required fields correctly')}
                    image={girlImage}
                    onBack={onBack}
                    isShowQuit={false}
                />
            )}
        </>
    )
}

export default StopPanels
