import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Toast from 'components/Toast/Toast'
import { PRODUCT_LIST_FREE } from 'common/constants/ProductConstants'
import { API__USER } from 'api'
import { USER__SET_SUBSCRIPTION, USER__SET_SUBSCRIPTIONS } from 'store/actions'

import useUserSubscriptions from 'hooks/useUserSubscriptions'

import { SUBSCRIPTION_TYPES } from 'common/constants/subscription'

import { isExpired } from '../../../../utils'

import Item from './components/Item/Item'
import TrialOverBanner from './components/TrialOverBanner/TrialOverBanner'

import { isYearlySubscription, isMonthlySubscription } from './utils'

const SubscriptionsList = () => {
    const { user_details: userDetails, user_subscription: userSubscription } = useSelector(state => state)
    const { t } = useTranslation()
    const { subscriptions } = useUserSubscriptions()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const currentProductCode = userSubscription?.product?.productCode
    const isDeprecatedFreePlan = currentProductCode === PRODUCT_LIST_FREE.Free
    const isTrial = currentProductCode === PRODUCT_LIST_FREE.Trial

    const preparedSubscriptions = subscriptions
        ? subscriptions.filter(
              sub =>
                  (!isExpired(sub.expirationDate) &&
                      ![PRODUCT_LIST_FREE.Free, PRODUCT_LIST_FREE.EducationFree].includes(sub.product.productCode)) ||
                  sub.selected,
          )
        : []
    const hasSeveralActiveSubs = preparedSubscriptions.length > 1

    const subscriptionsList = preparedSubscriptions.filter(
        sub =>
            (hasSeveralActiveSubs && sub.product.productCode !== PRODUCT_LIST_FREE.Trial) ||
            (!hasSeveralActiveSubs && sub.product.productCode === PRODUCT_LIST_FREE.Trial) ||
            sub.selected,
    )

    const selectedSubscription = subscriptionsList.find(
        subscription => subscription.type === SUBSCRIPTION_TYPES.GLOBAL && subscription.selected,
    )
    const waitingSubscriptionsList = subscriptionsList
        .filter(subscription => {
            if (subscription.type !== SUBSCRIPTION_TYPES.GLOBAL) return false
            if (subscription.selected) return false
            if (subscription.status !== 'ACTIVE') return false
            if (subscription.expired) return false
            if (selectedSubscription) {
                if (isYearlySubscription(selectedSubscription)) {
                    if (selectedSubscription.product.weight > subscription.product.weight) return false
                }
                if (isMonthlySubscription(selectedSubscription)) {
                    if (selectedSubscription.product.billingCycleValue >= subscription.product.billingCycleValue)
                        return false
                }
            }

            return true
        })
        .sort((a, b) => a.started - b.started)
    const projectSubscriptionsList = subscriptionsList
        .filter(subscription => subscription.type === SUBSCRIPTION_TYPES.PROJECT && subscription.selected)
        .sort((a, b) => a.started - b.started)

    const updateRecurringOption = async (subscription, value, payload) => {
        try {
            setIsLoading(true)
            await API__USER.UPDATE_CURRENT_SUBSCRIPTION(subscription.id, {
                ...subscription,
                recurringEnabled: value,
            })
            Toast('success', { message: value ? t('Auto-renewal enabled successfully') : t('Auto-renewal disabled') })
            dispatch(USER__SET_SUBSCRIPTION(await API__USER.GET_SELECTED_SUBSCRIPTION(userDetails.id)))
            dispatch(USER__SET_SUBSCRIPTIONS(await API__USER.GET_SUBSCRIPTIONS(userDetails.id)))

            try {
                if (value || !payload) return
                const { reason, comment } = payload
                await API__USER.SEND_SUBSCRIPTION_CANCELLATION_REASON(subscription.id, reason.id, reason.code, comment)
            } catch (err) {
                console.error(err)
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="subscriptions-list">
            {(userSubscription.expired && isTrial) || isDeprecatedFreePlan ? (
                <TrialOverBanner />
            ) : (
                <>
                    {!!selectedSubscription && (
                        <div className="subscriptions__state">
                            <Item
                                key={selectedSubscription.id}
                                subscription={selectedSubscription}
                                onUpdateRecurringOption={updateRecurringOption}
                                isLoading={isLoading}
                            />
                        </div>
                    )}
                    {!!waitingSubscriptionsList.length && (
                        <div className="subscriptions__state subscriptions__state--waiting">
                            {waitingSubscriptionsList.map(subscription => (
                                <Item
                                    key={subscription.id}
                                    subscription={subscription}
                                    onUpdateRecurringOption={updateRecurringOption}
                                    isLoading={isLoading}
                                    isWaiting
                                />
                            ))}
                        </div>
                    )}
                </>
            )}
            {!!projectSubscriptionsList.length && (
                <div className="subscriptions__state subscriptions__state--projects">
                    {projectSubscriptionsList.map(subscription => (
                        <Item
                            key={subscription.id}
                            isProjectSubscription
                            subscription={subscription}
                            onUpdateRecurringOption={updateRecurringOption}
                            isLoading={isLoading}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default SubscriptionsList
