import React, { useState } from 'react'
import MailChimpManagerModal from './MailChimpManagerModal'
import { useSelector } from 'react-redux'

let MailChimpContext
const { Provider } = (MailChimpContext = React.createContext())

const MailChimpProvider = ({ children, projectId, blockId }) => {
    const authUrl = process.env.REACT_APP_MAILCHIMP_AUTH_URL
    // INFO for local development need to set this url into mailchimp account setting as http://127.0.0.1:3000/
    // Please see confluence for access to mailchimp
    const redirectUrl = process.env.REACT_APP_STATIC_URL
    const organizationId = useSelector(state => state.organizations.selectedOrganizationId)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [leadFormFields, setLeadFormFields] = useState([])
    const [connector, setConnector] = useState({})

    return (
        <Provider
            value={{
                projectId,
                blockId,
                setIsOpen,
                organizationId,
                authUrl,
                redirectUrl,
                setLeadFormFields,
                connector,
                setConnector,
                isAuthorized,
                setIsAuthorized,
            }}
        >
            {isOpen && (
                <MailChimpManagerModal
                    onClose={() => setIsOpen(false)}
                    organizationId={organizationId}
                    projectId={projectId}
                    blockId={blockId}
                    leadFormFields={leadFormFields}
                    setIsAuthorized={setIsAuthorized}
                    connector={connector}
                    setConnector={setConnector}
                />
            )}
            {children}
        </Provider>
    )
}

export { MailChimpContext, MailChimpProvider }
