import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import DataGrid from 'components/Form/DataGrid/DataGrid'

import LeadInfoModal from '../../../LeadFormTable/components/LeadInfoModal'

import { getColumns } from './columns'

const RatingData = ({
    projectId,
    blockProjection,
    isHasRatingData,
    isLoading,
    isSelectedActiveRating,
    startDate,
    endDate,
    selectedRating,
    countParticipant,
    participantsPage,
    onPageChange,
    t,
}) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [selectSessionId, setSelectSessionId] = useState(null)
    if (!isHasRatingData) {
        return <div className="leader-board-panel__empty-rating">{t('No records yet')}</div>
    }
    const onSelectColumn = value => {
        setSelectSessionId(value)
        setIsShowModal(true)
    }
    return (
        <div className="leader-board-panel__rating-detail-box">
            <div className="leader-board-panel__rating-info">
                <div>
                    <span className={`leader-board-panel__rating-status ${isSelectedActiveRating ? 'is-active' : ''}`}>
                        {isSelectedActiveRating ? t('Active') : t('Closed')}:
                    </span>
                    &nbsp;
                    {isSelectedActiveRating
                        ? t('the rating is calculated from {{startDate}}', { startDate })
                        : `${startDate} - ${endDate}`}
                </div>
                <div>{t('Participants: {{countParticipant}}', { countParticipant })} </div>
            </div>
            <DataGrid
                columns={getColumns(onSelectColumn)}
                isLoading={isLoading}
                pagination={participantsPage}
                onPageChange={onPageChange}
            />
            {isShowModal && (
                <LeadInfoModal
                    blockProjection={blockProjection}
                    projectId={projectId}
                    sessionId={selectSessionId}
                    onClose={() => setIsShowModal(false)}
                    isRating
                    dateRange={{ from: selectedRating.from, to: selectedRating.to }}
                />
            )}
        </div>
    )
}

export default withTranslation('translations')(RatingData)
