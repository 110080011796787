import React from 'react'
import ReactRangeSlider from 'react-range-slider-input'
import classNames from 'classnames'

import { MODES } from '../../constants'

import styles from './RangeSlider.module.scss'

const STEP = 1
const MIN_VALUE = 1

const RangeSlider = ({ mode, value, onChange, max = 10, title, noteText }) => (
    <div className={classNames(styles.rangeSlider, { [styles.isAutoModeRangeSlider]: mode === MODES.AUTO })}>
        <div className={styles.title}>{title}</div>
        <div className={styles.sliderBox}>
            <ReactRangeSlider
                className="range-slider-single"
                min={MIN_VALUE}
                max={max}
                step={STEP}
                value={[MIN_VALUE, value]}
                onInput={v => onChange(v[1])}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
            />
            <div className={styles.countBox}>
                <input
                    className={styles.countBoxInput}
                    type="number"
                    min={MIN_VALUE}
                    max={max}
                    value={value}
                    onChange={evt => {
                        const v = evt.target.value
                        const vDigits = String(v).split('').length
                        const maxDigits = String(max).split('').length
                        if (vDigits > maxDigits) return
                        onChange(v)
                    }}
                    onBlur={() => {
                        if (value < MIN_VALUE) onChange(MIN_VALUE)
                        if (value > max) onChange(max)
                    }}
                />
                <div className={styles.countBoxTotal}>/{max}</div>
            </div>
        </div>
        <p className={styles.note}>{noteText}</p>
    </div>
)

export default RangeSlider
