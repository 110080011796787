import React, { useRef, useState } from 'react'

import { scrollContainerToElementById } from 'utils/scrollContainerToElementById'

const useNavigation = (idPrefix = null) => {
    const scrollableContentNodeRef = useRef()

    const [focusedElementId, setFocusedElementId] = useState(null)

    const scrollContainerToElement = elementId => {
        scrollContainerToElementById(scrollableContentNodeRef, `${idPrefix}${elementId}`)
    }

    const onSetFocusedElementId = element => {
        let id = element.id
        if (idPrefix && id.includes(idPrefix)) id = id.replace(idPrefix, '')
        if (id !== focusedElementId) setFocusedElementId(id)
    }

    return {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    }
}

export default useNavigation
