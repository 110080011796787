import React from 'react'
import classNames from 'classnames'

import CardBox from '../CardBox/CardBox'

import { getCardsGroupStyles } from './utils'

import styles from './CardsGroup.module.scss'

const CardsGroup = ({ pairsCount, width, children }) => (
    <ul
        className={classNames(styles.cardsGroup, { [styles.cardsGroupMobile]: width < 516 })}
        style={getCardsGroupStyles(pairsCount)}
    >
        <li className={styles.card}>
            <CardBox isEmpty pairsCount={pairsCount} width={width} />
        </li>
        <li className={styles.card}>{children}</li>
    </ul>
)

export default CardsGroup
