import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { getTileStyles } from './utils'

import styles from './Tile.module.scss'

const Tile = ({
    tile,
    tilesCount,
    gameType,
    imageInfo,
    tileSize,
    fontSize,
    fontFamily,
    isHighlightCorrect,
    isShowImageNumbers = false,
    correctColor,
    colorTheme,
    ceilBorderWidth,
    boxBorderWidth,
    containerWidth,
}) => {
    const [_styles, setStyles] = useState({})

    useEffect(() => {
        setStyles(
            getTileStyles({
                gameType,
                imageInfo,
                tileSize,
                fontSize,
                tile,
                isHighlightCorrect,
                isShowImageNumbers,
                tilesCount,
                containerWidth,
                numberOfCells: Math.sqrt(tilesCount),
                position: {
                    x: tile.positionX,
                    y: tile.positionY,
                },
                correctColor,
                fontFamily,
                colorTheme,
                ceilBorderWidth,
                boxBorderWidth,
            }),
        )
    }, [
        tileSize,
        fontSize,
        tile,
        imageInfo,
        gameType,
        isShowImageNumbers,
        tilesCount,
        correctColor,
        colorTheme,
        fontFamily,
        ceilBorderWidth,
        boxBorderWidth,
        containerWidth,
        isHighlightCorrect,
    ])

    return (
        <div
            style={_styles.wrapperStyles}
            className={classNames(styles.tile, {
                [styles.isHiddenTile]: tile.value === tilesCount,
            })}
        >
            <div style={_styles.innerStyles} className={styles.inner}>
                {tile.value !== tilesCount && <div className={styles.number}>{tile.value}</div>}
            </div>
        </div>
    )
}

export default Tile
