import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ServiceCommission.module.scss'

const ServiceCommission = ({ commissionSum }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.serviceCommission}>
            <div className={styles.label}>{t('Service commission')}</div>
            <div className={styles.value}>-${commissionSum}</div>
        </div>
    )
}

export default ServiceCommission
