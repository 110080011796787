import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './Controls.module.scss'

const Controls = ({ isRecording, stopRecord, startRecord }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.controls}>
            {isRecording ? (
                <div className={classNames(styles.control, styles.controlStop)} onClick={stopRecord} />
            ) : (
                <div className={classNames(styles.control, styles.controlStart)} onClick={startRecord} />
            )}
            <p className={styles.controlNote}>
                {isRecording ? t('Click to stop recording') : t('Click to start recording')}
            </p>
        </div>
    )
}

export default Controls
