import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import Select from 'components/Form/Select/Select'
import Radio from 'components/Form/Radio/Radio'
import CardProportions from 'components/Form/CardProportions/CardProportions'

import { LAYOUT_TEMPLATE, PLAYGROUND_FIELDS, TEXT_POSITION_OPTIONS } from '../../utils'
import { TreasureHuntPreview } from '../../components/TreasureHuntPreview'

import './Playground.scss'

export const Playground = ({ structure, colorTheme, methods: { updateStructure } }) => {
    const { t } = useTranslation()

    return (
        <div className="treasure-hunt-modal-playground">
            <div className="treasure-hunt-modal-playground__workplace-wrapper">
                <ScrollableBlock>
                    <div className="treasure-hunt-modal-playground__workplace">
                        <h3 className="treasure-hunt-modal-playground__workplace-title">{t('Playground')}</h3>
                        <div className="treasure-hunt-modal-playground__workplace-content">
                            <div className="treasure-hunt-modal-playground__layout-row">
                                <Select
                                    label={t('Layout')}
                                    value={structure.playground.layout}
                                    onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.layout]: value })}
                                    options={LAYOUT_TEMPLATE}
                                />
                            </div>
                            <CardProportions
                                label={t('Card proportions')}
                                value={structure.playground.cardProportions}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.cardProportions]: value })}
                            />
                            <br />
                            <Checkbox
                                label={t('Show quest name on the cover')}
                                value={structure.playground.isShowQuestCover}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.isShowQuestCover]: value })}
                            />
                            <br />
                            <Radio
                                label={t('Text and button position')}
                                value={structure.playground.coverPosition}
                                options={TEXT_POSITION_OPTIONS}
                                onUpdate={value => updateStructure({ [PLAYGROUND_FIELDS.coverPosition]: value })}
                            />
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className="treasure-hunt-modal-playground__preview-wrapper">
                <ScrollableBlock>
                    <div className="treasure-hunt-modal-playground__preview">
                        <p className="treasure-hunt-modal-playground__preview-title">{t('Approximate preview')}</p>
                        <TreasureHuntPreview
                            colorTheme={colorTheme}
                            tiles={structure.tiles.tileList}
                            playground={structure.playground}
                            isModal={true}
                        />
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}
