import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './ListActionBar.module.scss'

const ListActionBar = ({
    index,
    length,
    maxLength = 999,
    minLength = 1,
    onChangePosition,
    onClone,
    onRemove,
    upMinIndex = 0,
    className = '',
    isShowLabel,
    isDisabledUp,
    isDisabledDown,
    isDisabledRemove,
    isDisabledAdd,
}) => {
    const { t } = useTranslation()

    const isAllowedUp = !isDisabledUp && index > upMinIndex && length > 1
    const isAllowedDown = !isDisabledDown && index < length - 1 && length > 1
    const isAllowedRemove = !isDisabledRemove && length > minLength
    const isAllowedAdd = !isDisabledAdd && length < maxLength

    return (
        <ul className={classNames(styles.listActionBar, { [className]: !!className })}>
            {isAllowedUp && (
                <li
                    className={styles.action}
                    data-icon="up"
                    title={t('Move up')}
                    onClick={() => onChangePosition(index, index - 1)}
                >
                    {isShowLabel && t('Move up')}
                </li>
            )}
            {isAllowedDown && (
                <li
                    className={styles.action}
                    data-icon="down"
                    title={t('Move down')}
                    onClick={() => onChangePosition(index, index + 1)}
                >
                    {isShowLabel && t('Move down')}
                </li>
            )}
            {isAllowedAdd && (
                <li className={styles.action} data-icon="clone" title={t('Clone')} onClick={() => onClone(index)}>
                    {isShowLabel && t('Clone')}
                </li>
            )}
            {isAllowedRemove && (
                <li className={styles.action} data-icon="remove" title={t('Remove')} onClick={() => onRemove(index)}>
                    {isShowLabel && t('Delete')}
                </li>
            )}
        </ul>
    )
}

export default ListActionBar
