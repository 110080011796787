import React from 'react'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'
import AddListItemButton from '../../../Controls/AddListItemButton/AddListItemButton'
import ListActionBar from '../../../Controls/ListActionBar/ListActionBar'
import Result from './Result'
import { MIN_RESULTS } from '../constants'

const ResultList = ({
    tmpStructure,
    resultDistribution,
    errors,
    pages,
    onAddResult,
    onCloneResult,
    onRemoveResult,
    onUpdateResult,
    onChangePosition,
    onSetFocusToResult,
    getQuestionsCount,
}) => {
    const { t } = useTranslation()

    const canResultVisible = i => {
        const distribution = resultDistribution[i]
        return !!(distribution && distribution.isVisible)
    }

    const getResultDistributionText = i => {
        const distribution = resultDistribution[i]
        if (distribution && distribution.isVisible)
            return distribution.from !== distribution.to
                ? t('({{from}}-{{to}} correct answers)', {
                      from: distribution.from,
                      to: distribution.to,
                  })
                : t('({{from}} correct answers)', { from: distribution.from })
        else return t('This result will never be displayed')
    }

    const handleOnScrollHighlightVisibleItem = async (inView, entry) => {
        if (inView) await onSetFocusToResult(entry.target)
    }

    const maxResult = getQuestionsCount(tmpStructure) + 1
    const isLimitExceeded = maxResult === tmpStructure.results.length
    const isFullClass = isLimitExceeded ? 'is-full' : ''
    return (
        <>
            {tmpStructure.results.length ? (
                <ul className={`list ${isFullClass}`}>
                    {tmpStructure.results.map((result, rIndex) => {
                        return (
                            <InView
                                as="li"
                                key={result.id}
                                className={canResultVisible(rIndex) ? '' : 'not-visible'}
                                onChange={handleOnScrollHighlightVisibleItem}
                                id={`item_${result.id}`}
                                threshold={0.1}
                            >
                                <h3 className="scroll-target tab-title" data-scroll={rIndex}>
                                    <span>
                                        {t('Result')} {rIndex + 1}
                                        <span className="note">{getResultDistributionText(rIndex)}</span>
                                    </span>
                                    <div className="control">
                                        <ListActionBar
                                            index={rIndex}
                                            length={tmpStructure.results.length}
                                            minLength={MIN_RESULTS}
                                            maxLength={maxResult}
                                            onChangePosition={onChangePosition}
                                            onClone={() => onCloneResult(result.id)}
                                            onRemove={() => onRemoveResult(result.id)}
                                        />
                                    </div>
                                </h3>
                                <Result
                                    key={result.id}
                                    result={result}
                                    errors={errors}
                                    pages={pages}
                                    onUpdateResult={onUpdateResult}
                                />
                            </InView>
                        )
                    })}
                </ul>
            ) : null}
            {!isLimitExceeded && (
                <AddListItemButton title={t('Add result')} length={tmpStructure.results.length} onClick={onAddResult} />
            )}
        </>
    )
}

export default ResultList
