import React, { useState, useEffect } from 'react'

import { TreasureHuntModal } from '../components/Modals/TreasureHuntModal'
import { TreasureHuntPreview } from '../components/Modals/TreasureHuntModal/components/TreasureHuntPreview'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const TreasureHunt = ({ struct, onUpdate, id, colorTheme, pagesData }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    useEffect(() => {
        if (struct.tiles.tileList.length > 0) {
            struct.tiles.tileList = struct.tiles.tileList.map(tile => {
                if (!tile.overlaySrc) {
                    tile.color = colorTheme
                }
                return tile
            })
        }
    }, [colorTheme])

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <TreasureHuntPreview
                colorTheme={colorTheme}
                tiles={struct.tiles ? struct.tiles.tileList : []}
                playground={struct.playground}
            />
            {isShowModal && (
                <TreasureHuntModal
                    data={{
                        structure: struct,
                        pages: pagesData,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default TreasureHunt
