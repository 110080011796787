import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

const useRouter = () => {
    const history = useHistory()
    const { search, pathname } = useLocation()

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

    return {
        pathname,
        historyPush: data => history.push(data),
        queryParams,
        addQueryParam: (key, value) => {
            history.push({
                pathname,
                search: qs.stringify({ ...queryParams, [key]: value }),
            })
        },
        removeQueryParam: (...keys) => {
            history.push({
                pathname,
                search: qs.stringify({
                    ...queryParams,
                    ...keys.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
                }),
            })
        },
    }
}

export default useRouter
