import { MAIN_SERVER } from './instance'

export const CREATE_WEBHOOK = async ({ projectId, blockId, url }) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/webhook/config`,
        data: {
            projectId,
            blockId,
            url,
        },
    })

export const GET_WEBHOOKS_BY_PROJECT = async ({ organizationId, projectId }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/webhook/config`,
        params: { organizationId, projectId },
    })

export const GET_WEBHOOKS_BY_BLOCK = async ({ organizationId, blockId, projectId }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/integrations/webhook/config`,
        params: { organizationId, blockId, projectId },
    })

export const SEND_TEST_DATA = async (organizationId, webhookId, projectId) =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/webhook/test`,
        data: {
            organizationId,
            configId: webhookId,
            projectId,
        },
    })

export const SWITCH_WEBHOOK_STATUS = async webhookId =>
    MAIN_SERVER({
        method: 'post',
        url: `/integrations/webhook/config/${webhookId}/switch`,
    })

export const DELETE_WEBHOOK = async (organizationId, webhookId) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/integrations/webhook/config/${webhookId}`,
        params: {
            organizationId,
        },
    })

export const UPDATE_WEBHOOK_URL = async (url, webhookId) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/integrations/webhook/config/${webhookId}`,
        data: {
            url,
        },
    })

export const DELETE_BLOCKS_WEBHOOKS = async ({ organizationId, blockIds, projectId }) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/integrations/webhook/config-by-project-and-block`,
        data: { organizationId, blockIds, projectId },
    })
