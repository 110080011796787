import React, { useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import Playground from './views/Playground/Playground'
import Final from './views/Final/Final'

import { TABS, TABS_ARRAY } from './constants'
import { createSlidingPuzzleBlock, BLOCK_KEYS, BLOCK_KEYS_BY_TABS } from './slidingPuzzleService'
import { validateTabs } from './validation'

import styles from './SlidingPuzzleModal.module.scss'

const SlidingPuzzleModal = ({
    methods,
    data: { structure, colorTheme, id, correctColor, isHighlightCorrect, fontFamily },
}) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(
        Object.keys(structure).length === 0 ? createSlidingPuzzleBlock() : cloneDeep(structure),
    )
    const [activeTab, setActiveTab] = useState(TABS.PLAYGROUND)
    const [errors, setErrors] = useState({})

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    const onSave = async () => {
        try {
            const validationResult = validateTabs(tmpStructure)
            if (!validationResult.isHasError) {
                await methods.save({ [id]: { data: { struct: tmpStructure } } })
                methods.closeModal()
            } else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const clearErrors = (err, data, blockKey) => {
        if (!err) return
        const _errors = {}
        Object.keys(data).forEach(key => (_errors[key] = []))
        setErrors(prevState => ({
            ...prevState,
            [blockKey]: {
                ...prevState[blockKey],
                ..._errors,
            },
        }))
    }

    const onUpdatePlayground = data => {
        clearErrors(errors[BLOCK_KEYS.PLAYGROUND], data, BLOCK_KEYS.PLAYGROUND)
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.PLAYGROUND]: {
                ...prevState[BLOCK_KEYS.PLAYGROUND],
                ...data,
            },
        }))
    }
    const onUpdateFinal = data => {
        clearErrors(errors[BLOCK_KEYS.FINAL], data, BLOCK_KEYS.FINAL)
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.FINAL]: {
                ...prevState[BLOCK_KEYS.FINAL],
                ...data,
            },
        }))
    }

    const onChangeTab = useCallback(
        tabId => {
            const validationResult = validateTabs(tmpStructure, BLOCK_KEYS_BY_TABS[activeTab])
            if (!validationResult.isHasError) setActiveTab(tabId)
            else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            }
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Sliding Puzzle')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'SLIDING_PUZZLE',
                }}
                onSave={onSave}
            >
                <div className={styles.crosswordModal}>
                    <div className={styles.body}>
                        {activeTab === TABS.PLAYGROUND && (
                            <Playground
                                errors={errors?.[BLOCK_KEYS.PLAYGROUND]}
                                colorTheme={colorTheme}
                                structure={tmpStructure[BLOCK_KEYS.PLAYGROUND]}
                                onUpdate={onUpdatePlayground}
                                correctColor={correctColor}
                                isHighlightCorrect={isHighlightCorrect}
                                fontFamily={fontFamily}
                            />
                        )}
                        {activeTab === TABS.FINAL && (
                            <Final
                                errors={errors?.[BLOCK_KEYS.FINAL]}
                                colorTheme={colorTheme}
                                structure={tmpStructure[BLOCK_KEYS.FINAL]}
                                onUpdate={onUpdateFinal}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={() => methods.closeModal()}
            />
        </Modal>
    )
}

export default SlidingPuzzleModal
