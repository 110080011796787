import React, { useState, useEffect } from 'react'

import { calculateTan } from 'utils/numbers'

import { MAX_WHEEL_DIAMETER, SECTION_TYPE } from '../constants'

const ARROW_TOP_COEFFICIENT = 1.8

const useWheelParams = (containerWidth, sectionType, degsPerSection, sectionsCount) => {
    const [wheelDiameter, setWheelDiameter] = useState(0)
    const [wheelRadius, setWheelRadius] = useState(0)
    const [wheelInnerDiameter, setWheelInnerDiameter] = useState(0)
    const [wheelInnerRadius, setWheelInnerRadius] = useState(0)
    const [borderWidth, setBorderWidth] = useState(0)
    const [pinSize, setPinSize] = useState(0)
    const [arrowHeight, setArrowHeight] = useState(0)
    const [arrowTop, setArrowTop] = useState(0)
    const [contentWidth, setContentWidth] = useState(0)
    const [contentHeight, setContentHeight] = useState(0)
    const [contentBottomGap, setContentBottomGap] = useState(0)
    const [contentTopGap] = useState(0)

    useEffect(() => {
        setArrowTop(Math.round(borderWidth - arrowHeight / ARROW_TOP_COEFFICIENT))
    }, [borderWidth, arrowHeight])

    useEffect(() => {
        setContentHeight(
            wheelInnerRadius -
                (pinSize / 2 + contentBottomGap) -
                (arrowHeight - arrowHeight / ARROW_TOP_COEFFICIENT + contentTopGap),
        )
    }, [wheelInnerRadius, pinSize, contentBottomGap, arrowHeight, contentTopGap])

    useEffect(() => {
        if (sectionsCount === 2) {
            setContentWidth(wheelInnerRadius / 2)
        } else {
            setContentWidth((pinSize / 2 + contentBottomGap) * calculateTan(degsPerSection / 2) * 2)
        }
    }, [degsPerSection, pinSize, contentBottomGap, sectionsCount, wheelInnerRadius])

    useEffect(() => {
        setWheelInnerDiameter(wheelDiameter - borderWidth * 2)
    }, [wheelDiameter, borderWidth])
    useEffect(() => {
        setWheelRadius(wheelDiameter / 2)
        setPinSize(Math.round(wheelDiameter / 6.32))
        setArrowHeight(Math.round(wheelDiameter / 9.73))
    }, [wheelDiameter])
    useEffect(() => {
        setWheelInnerRadius(wheelInnerDiameter / 2)
    }, [wheelInnerDiameter])

    useEffect(() => {
        if (sectionsCount === 2) {
            setContentBottomGap(wheelInnerRadius / 6)
        } else {
            if (sectionType === SECTION_TYPE.TEXT) setContentBottomGap(wheelInnerRadius / 10)
            if (sectionType === SECTION_TYPE.IMAGE) setContentBottomGap(wheelInnerRadius / 3)
        }
    }, [wheelInnerRadius, sectionType, sectionsCount])

    useEffect(() => {
        setWheelDiameter(containerWidth > MAX_WHEEL_DIAMETER ? MAX_WHEEL_DIAMETER : containerWidth)

        if (containerWidth >= 760) {
            setBorderWidth(14)
        }
        if (containerWidth < 760) {
            setBorderWidth(8)
        }
        if (containerWidth <= 540) {
            setBorderWidth(6)
        }
    }, [containerWidth])

    return {
        wheelDiameter,
        wheelRadius,
        wheelInnerDiameter,
        wheelInnerRadius,

        borderWidth,

        pinSize,

        arrowHeight,
        arrowTop,

        contentWidth,
        contentHeight,
        contentBottomGap,
        contentTopGap,
    }
}

export default useWheelParams
