const Edit = ({ color = '#3C3C3C' }) => (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.92582 0.646447C9.73056 0.451184 9.41397 0.451184 9.21871 0.646447L1.36157 8.50359C1.2678 8.59736 1.21512 8.72453 1.21512 8.85714L1.21512 10.2857C1.21512 10.4183 1.2678 10.5455 1.36157 10.6393C1.45534 10.733 1.58251 10.7857 1.71512 10.7857L3.14369 10.7857C3.2763 10.7857 3.40348 10.733 3.49725 10.6393L11.3544 2.78212C11.5497 2.58686 11.5497 2.27028 11.3544 2.07502L9.92582 0.646447Z"
            stroke={color}
            strokeLinejoin="round"
        />
        <rect
            x="9.57227"
            y="0.292893"
            width="3.0203"
            height="3.0203"
            transform="rotate(45 9.57227 0.292893)"
            stroke={color}
            strokeLinejoin="round"
        />
    </svg>
)

export default Edit
