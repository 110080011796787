import React from 'react'

import TextInput from 'components/Form/TextInput/TextInput'

import styles from './FormTextInput.module.scss'

const FormTextInput = ({ ...props }) => (
    <TextInput {...props} inputClassName={styles.input} labelClassName={styles.label} />
)

export default FormTextInput
