import React, { useState } from 'react'

import SlideshowModal from '../components/Modals/SlideshowModal/SlideshowModal'
import SlideshowPreview from '../components/Modals/SlideshowModal/SlideshowPreview'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const Slideshow = ({ blockData: { struct, colorTheme, id, textColor, isTransparentBackground }, onUpdate }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <SlideshowPreview
                slides={struct.slides}
                colorTheme={colorTheme}
                textColor={textColor}
                isTransparentBackground={isTransparentBackground}
            />
            {isShowModal && (
                <SlideshowModal
                    data={{
                        structure: struct,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Slideshow
