import i18n from 'i18n'

export const STEPS = {
    ACTIVATION_PUBLIC_PAGE: 'ACTIVATION_PUBLIC_PAGE',
    SET_UP_PRODUCT: 'SET_UP_PRODUCT',
}

export const STEPS_TITLE = {
    [STEPS.ACTIVATION_PUBLIC_PAGE]: i18n.t('Set up your public page'),
    [STEPS.SET_UP_PRODUCT]: i18n.t('Set up your product'),
}
