import store from 'store'

import { MAIN_SERVER } from './instance'

export const PARSE_FILE = async file => {
    const {
        organizations: { selectedOrganizationId },
        user_details: { id },
    } = store.getState()

    const formData = new FormData()
    formData.append('file', file)

    return MAIN_SERVER.post('/questions-banks/parse', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            organizationId: selectedOrganizationId,
        },
        data: {
            userId: id,
        },
    })
}

export const CREATE_BANK = async (name, questions) => {
    const {
        organizations: { selectedOrganizationId },
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'post',
        url: `/questions-banks`,
        data: {
            organizationId: selectedOrganizationId,
            name,
            questions,
            userId: id,
        },
    })
}

export const DELETE_BANK = async bankId => {
    const {
        organizations: { selectedOrganizationId },
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'delete',
        url: `/questions-banks/${bankId}`,
        params: { organizationId: selectedOrganizationId },
        data: {
            userId: id,
        },
    })
}

export const GET_BANK_QUESTIONS = async bankId => {
    const {
        organizations: { selectedOrganizationId },
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'get',
        url: `/questions-banks/${bankId}/questions`,
        params: { userId: id, organizationId: selectedOrganizationId, size: 1000, sort: 'orderNumber,asc' },
    })
}

export const GET_BANK = async bankId => {
    const {
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'get',
        url: `/questions-banks/${bankId}`,
        params: { userId: id },
    })
}

export const GET_BANKS = async (isPublic = false) => {
    const {
        organizations: { selectedOrganizationId },
        user_details: { id },
    } = store.getState()

    return MAIN_SERVER({
        method: 'get',
        url: `/questions-banks${isPublic ? '/public' : ''}`,
        params: { userId: id, organizationId: selectedOrganizationId, size: 1000, sort: 'name,asc' },
    })
}

export const UPDATE_BANK_QUESTIONS = (bankId, name, questions, removedQuestionsIds) =>
    MAIN_SERVER({
        method: 'patch',
        url: `/questions-banks/${bankId}`,
        data: {
            bankId,
            name,
            questions,
            removedQuestionsIds,
        },
    })
