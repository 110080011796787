import React from 'react'
import { useTranslation } from 'react-i18next'

import Description from '../../../../components/Description/Description'

import Block from './components/Block/Block'

import styles from './InterestsSelector.module.scss'

const InterestsSelector = ({ tags, selectedTags, onUpdateSelectedTags }) => {
    const { t } = useTranslation()

    const onHandleTagClick = clickedTag => {
        if (selectedTags.some(tag => tag.name === clickedTag.name)) {
            onUpdateSelectedTags(selectedTags.filter(tag => tag.name !== clickedTag.name))
        } else {
            onUpdateSelectedTags([...selectedTags, clickedTag])
        }
    }

    return (
        <div className={styles.interestsSelector}>
            <Description
                className={styles.description}
                title={t('Please indicate your interests')}
                subTitle={t('This will help us offer you optimal game mechanics and templates')}
            />

            <ul className={styles.list}>
                {tags.map(item => (
                    <Block
                        key={item.parentTag.id}
                        item={item}
                        onTagClick={onHandleTagClick}
                        selectedTags={selectedTags}
                    />
                ))}
            </ul>
        </div>
    )
}

export default InterestsSelector
