import React, { useEffect, useState } from 'react'

import { API__ANALYTICS } from 'api'

import BlockSummary from "../BlockSummary/BlockSummary"

const BlockBaseStatistic = ({ projectId, blockId, dateRange }) => {
    const [statistic, setStatistic] = useState(null)

    useEffect(() => {
        if (!dateRange) return
        API__ANALYTICS.GET_BLOCK_BASE_STATISTICS(projectId, blockId, { dateRange })
            .then(data => {
                setStatistic(data)
            })
            .catch(err => console.error(err))
    }, [dateRange])

    return <BlockSummary statistic={statistic} />
}

export default BlockBaseStatistic
