import React from 'react'
import arrow_previous_icon from './i/arrow-previous.svg'
import arrow_next_icon from './i/arrow-next.svg'

export const TipsNavigationPanel = ({ tips, selectedTipIndex, onNext, onPrevious, onSelect }) => {
    return (
        <div className="tips__navigation-panel">
            <img
                className="tips__navigation-panel-button"
                src={arrow_previous_icon}
                alt="Previous tip"
                onClick={onPrevious}
            />
            <div className="tips__navigation-panel-navigation">
                {tips.map((_, index) => {
                    return (
                        <div
                            key={index}
                            className={`tips__navigation-panel-navigation-dot ${
                                index === selectedTipIndex ? 'active' : ''
                            }`}
                            onClick={() => onSelect(index)}
                        />
                    )
                })}
            </div>
            <img className="tips__navigation-panel-button" src={arrow_next_icon} alt="Next tip" onClick={onNext} />
        </div>
    )
}
