import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './CompactBox.module.scss'

const CompactBox = ({ width, icon, title, text, additionalBlock, readMoreLink, onClick }) => {
    const { t } = useTranslation()

    return (
        <div
            className={classNames(styles.compactBox, { [styles.clickableCompactBox]: !!onClick })}
            style={{ width }}
            onClick={() => (onClick ? onClick() : {})}
        >
            <div className={styles.iconBox}>
                <img className={styles.icon} src={icon} alt="icon" />
            </div>
            <div className={styles.textBox}>
                <div className={styles.textBoxLeft}>
                    <div className={styles.title}>{title}</div>
                    {!!text && <p className={styles.text}>{text}</p>}
                    {!!readMoreLink && (
                        <a
                            onClick={evt => evt.stopPropagation()}
                            className={styles.readMoreLink}
                            href={readMoreLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Read more')}
                        </a>
                    )}
                </div>
                {!!additionalBlock && <div className={styles.textBoxRight}>{additionalBlock}</div>}
            </div>
        </div>
    )
}

export default CompactBox
