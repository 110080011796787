import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Modal from 'components/Modal/Modal'
import Checkbox from 'components/Form/Checkbox/Checkbox'

import storage from 'common/utils/storage'

import { PRODUCT_LIST_FREE } from 'common/constants/ProductConstants'

import featuresImage from './i/features.png'

import styles from './HowTrialWorksModal.module.scss'

export const STORAGE_KEY = 'DONT_SHOW_HOW_TRIAL_WORKS'

const HowTrialWorksModal = () => {
    const { t } = useTranslation()
    const { organizations, projectSubscription } = useSelector(state => state)

    const [isVisible, setIsVisible] = useState(false)
    const [isDontShowAgainMark, setIsDontShowAgainMark] = useState(false)

    useEffect(() => {
        if (projectSubscription && !projectSubscription.expired) return

        const productCode = organizations.selectedSubscription.product.productCode
        const isTrial = productCode === PRODUCT_LIST_FREE.Trial
        if (!isTrial) return
        if (storage.getValue(STORAGE_KEY)) return
        setIsVisible(true)
    }, [])

    const onClose = () => {
        if (isDontShowAgainMark) storage.setValue(STORAGE_KEY, 'true')
        setIsVisible(false)
    }

    if (!isVisible) return null

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <div className={styles.howTrialWorksModal}>
                <img className={styles.image} src={featuresImage} alt="image" />
                <div className={styles.content}>
                    <h4 className={styles.title}>{t('Before we continue...')}</h4>
                    <h5 className={styles.subTitle}>{t('How does trial mode work?')}</h5>
                    <p className={styles.text}>
                        {t(
                            'In trial mode, you can create, publish and test any game mechanics with the functionality of the PRO+ tariff plan. However, please note that the ability to use external links in projects is only available by subscription.',
                        )}
                    </p>
                    <button className="button" data-size="lg" data-variant="primary" onClick={onClose}>
                        {t("Ok, let's continue!")}
                    </button>
                    <Checkbox
                        className={styles.checkbox}
                        label={t("Don't show again")}
                        value={isDontShowAgainMark}
                        onUpdate={value => setIsDontShowAgainMark(value)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default HowTrialWorksModal
