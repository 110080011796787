const Plus = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_487)">
            <path
                d="M15.8334 10.8334H10.8334V15.8334H9.16669V10.8334H4.16669V9.16669H9.16669V4.16669H10.8334V9.16669H15.8334V10.8334Z"
                fill="#D8D8D8"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_487">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default Plus
