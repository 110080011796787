import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import settingsIcon from './i/settings.svg'

import styles from './QuestionBankPanel.module.scss'

const QuestionBankPanel = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.questionBankPanel}>
            <Link to="/question-bank">
                <img className={styles.icon} src={settingsIcon} alt="settings" />
            </Link>
            <h4 className={styles.title}>{t('Question banks')}</h4>
            <div className={styles.description}>{t('Set up question lists for use in tests and quizzes')}</div>
        </div>
    )
}

export default QuestionBankPanel
