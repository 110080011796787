import React from 'react'

import ComponentsList from '../../../../components/ComponentsList/ComponentsList'

import LeadFormTable from '../../components/LeadFormTable/LeadFormTable'

const Cookies = ({ projectId, blockProjection, dateRange, periodInterval }) => {
    return (
        <ComponentsList>
            <LeadFormTable
                projectId={projectId}
                blockProjection={blockProjection}
                dateRange={dateRange}
                periodInterval={periodInterval}
            />
        </ComponentsList>
    )
}

export default Cookies
