import React from 'react'

import { AUDIO_PLAYER_CONTROLS } from 'common/constants/constants'
import {
    IconPlay,
    IconStepRewind,
    IconVolume,
    TripleDotsIcon,
    IconMute,
    IconNextAudio,
    IconPreviousAudio,
    IconRepeatAudio,
    IconRepeatOffAudio,
    IconStepForward,
} from 'svg'

import './Audio.scss'

const Audio = ({
    blockData: { t, url, isLoop, isMuted, progressJumpSteps, firstLineBarControls, secondLineBarControls },
}) => {
    const renderUIModule = (comp, key) => {
        switch (comp) {
            case AUDIO_PLAYER_CONTROLS.CURRENT_TIME:
                return (
                    <div key={key} className="audio_time audio_current-time">
                        --:--
                    </div>
                )
            case AUDIO_PLAYER_CONTROLS.CURRENT_LEFT_TIME:
                return (
                    <div key={key} className="audio_time audio_current-left-time">
                        --:--
                    </div>
                )
            case AUDIO_PLAYER_CONTROLS.DURATION:
                return (
                    <div key={key} className="audio_time audio_total-time">
                        --:--
                    </div>
                )
            case AUDIO_PLAYER_CONTROLS.PROGRESS_BAR:
                return (
                    <div key={key} className="audio_progress-container">
                        <div className="audio_progress-bar audio_progress-bar-show-download">
                            <div className="audio_progress-indicator" style={{ left: '0%' }} />
                            <div className="audio_progress-filled" style={{ width: '0%' }} />
                        </div>
                    </div>
                )
            case AUDIO_PLAYER_CONTROLS.PLAY: {
                return (
                    <button key={key} className="audio_button" type="button">
                        <IconPlay />
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.PREVIOUS: {
                return (
                    <button
                        key={key}
                        className="audio_button"
                        data-skip-value={progressJumpSteps.backward}
                        type="button"
                    >
                        <IconPreviousAudio />
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.NEXT: {
                return (
                    <button key={key} className="audio_button" type="button">
                        <IconNextAudio />
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.REWIND: {
                return (
                    <button key={key} className="audio_button" type="button">
                        <IconStepRewind skipValue={progressJumpSteps.backward} />
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.FAST_FORWARD: {
                return (
                    <button key={key} className="audio_button" type="button">
                        <IconStepForward skipValue={progressJumpSteps.forward} />
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.LOOP: {
                return (
                    <button key={key} className="audio_button" type="button">
                        {isLoop ? <IconRepeatAudio /> : <IconRepeatOffAudio />}
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.MUTE: {
                return (
                    <button key={key} className="audio_button" title="Mute/On volume" type="button">
                        {isMuted ? <IconMute /> : <IconVolume />}
                    </button>
                )
            }
            case AUDIO_PLAYER_CONTROLS.VOLUME_BAR: {
                return (
                    <div key={key} className="audio_volume-container">
                        <button className="audio_button" title="Mute/On volume" type="button">
                            {isMuted ? <IconMute /> : <IconVolume />}
                        </button>
                        <div className="audio_volume-bar-area">
                            <div className="audio_volume-bar">
                                <div className="audio_volume-indicator" style={{ left: '0%' }} />
                                <div className="audio_volume-filled" style={{ width: '0%' }} />
                            </div>
                        </div>
                    </div>
                )
            }
            case AUDIO_PLAYER_CONTROLS.SPEED: {
                return (
                    <div key={key} className="audio_speed_menu">
                        1X
                    </div>
                )
            }
            case AUDIO_PLAYER_CONTROLS.MENU: {
                return (
                    <div key={key} className="audio_menu">
                        <TripleDotsIcon color={'#000'} />
                    </div>
                )
            }
            default:
                return null
        }
    }

    const renderUIModules = modules => {
        return modules.map((comp, i) => renderUIModule(comp, i))
    }

    return (
        <div className={`block __${t} ${!url ? '__empty' : ''}`}>
            <div className="audio_container ">
                <div className="audio_controls-wrapper">
                    <div className="audio_main">{renderUIModules(firstLineBarControls)}</div>
                    <div className="audio_main">{renderUIModules(secondLineBarControls)}</div>
                </div>
            </div>
        </div>
    )
}

export default Audio
