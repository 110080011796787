import React from 'react'
import { useTranslation } from 'react-i18next'

import Description from '../../../../components/Description/Description'

import Block from './components/Block/Block'

import image1 from './i/image1.png'
import image2 from './i/image2.png'
import image3 from './i/image3.png'

import styles from './WhatIs.module.scss'

const WhatIs = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.whatIs}>
            <Description title={t('What is Interacty?')} subTitle={t('A few basic things to know before you start')} />
            <div className={styles.blocks}>
                <Block
                    image={image1}
                    title={t('What Interacty is?')}
                    article={t(
                        'Here you can create interactive modules for education and share them with students and colleagues.\nOur mission: give all educators the ability to create interactive content!',
                    )}
                />
                <Block
                    image={image2}
                    title={t('How do I share my projects with students?')}
                    article={t(
                        'Students don’t need to create an account on Interacty. You may copy a public project link, embed the project into your LMS, or give them QR code.',
                    )}
                />
                <Block
                    image={image3}
                    title={t('How to create an organization and classrooms?')}
                    article={t(
                        'By default you already have one organization. Feel free to invite colleagues, rename it. We don’t have Classrooms yet, but it’s coming soon.',
                    )}
                />
            </div>
        </div>
    )
}

export default WhatIs
