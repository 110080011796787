import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactResizeDetector from 'react-resize-detector'

import { DEFAULT_IMAGE_BG_WIDE_URL } from 'common/constants/constants'
import { imgLoader } from 'common/services/imageLoader'

import Text from '../components/Text/Text'

const FlipCards = ({
    blockData: { frontColor, frontSrc, backColor, backSrc, frontText, backText, id, t },
    onUpdate,
    imageProportions,
}) => {
    const i18n = useTranslation()

    const [isFlipped, setIsFlipped] = useState(false)
    const [width, setWidth] = useState(800)

    const imgPrp = imageProportions.value.split('|')

    const frontSt = {
        backgroundColor: frontColor,
        backgroundImage: `url(${imgLoader(frontSrc)})`,
    }
    if (!frontColor && !frontSrc) frontSt.backgroundImage = `url(${DEFAULT_IMAGE_BG_WIDE_URL})`

    const backSt = {
        backgroundColor: backColor,
        backgroundImage: `url(${imgLoader(backSrc)})`,
    }
    if (!backColor && !backSrc) backSt.backgroundImage = `url(${DEFAULT_IMAGE_BG_WIDE_URL})`

    return (
        <div className={`block __${t}`} style={{ height: Math.round((width * +imgPrp[1]) / +imgPrp[0]) + 'px' }}>
            <ReactResizeDetector refreshMode="debounce" refreshRate={0} handleWidth onResize={w => setWidth(w)} />
            <div className={`flip_inner ${isFlipped ? '__flipped' : ''}`}>
                <div className="flip_side" style={frontSt}>
                    {frontText && (
                        <div className="text-area-cnt">
                            <div className="text-area">
                                <Text id={id} text={frontText} propName={'frontText'} onUpdate={onUpdate}></Text>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flip_side __back" style={backSt}>
                    {backText && (
                        <div className="text-area-cnt">
                            <div className="text-area">
                                <Text id={id} text={backText} propName={'backText'} onUpdate={onUpdate}></Text>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flip_switch" onClick={() => setIsFlipped(!isFlipped)}>
                <p className={!isFlipped ? 'active' : ''}>{i18n.t(' Front')}</p>
                <p className={isFlipped ? 'active' : ''}>{i18n.t(' Back')}</p>
            </div>
        </div>
    )
}

export default FlipCards
