import { cloneDeep } from 'lodash'

export const mapRankBattleStatistic = (statistic, blockProjection) => {
    const mappedProjection = cloneDeep(blockProjection.metadata)

    mappedProjection.cards = mappedProjection.cards.map(card => {
        const option = statistic.options.find(option => option.id === card.id)
        return {
            ...card,
            votesCount: option ? option.count : 0,
        }
    })

    return mappedProjection
}
