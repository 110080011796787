import i18n from 'i18n'

import { required } from 'shared/validation'

import pairsIcon from 'components/Modal/components/Body/components/SideNav/i/pairs.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const MAX_PAIRS_COUNT = 24

export const TABS = {
    pairs: 'pairs',
    finalScreen: 'finalScreen',
}

export const TABS_ARRAY = [
    {
        id: TABS.pairs,
        label: i18n.t('Pairs'),
        icon: pairsIcon,
    },
    {
        id: TABS.finalScreen,
        label: i18n.t('Final screen'),
        isHideFromMultiplayer: true,
        icon: resultsIcon,
    },
]

export const PAIRS_FIELDS = {
    isLargeCards: 'isLargeCards',
    pairList: 'pairList',
}

export const INIT_STRUCTURE = {
    pairs: {
        isLargeCards: false,
        pairList: [],
    },
    finalScreen: {
        header: '',
        description: '',
        imageSrc: '',
        imageDisclaimer: '',
    },
}

export const VALIDATE_RULES = {
    finalScreen: {
        header: [required()],
    },
}
