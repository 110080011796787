import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TooltipWrapper } from 'components/Tooltip/Tooltip'

import { PROJECT_STATUSES } from 'common/constants/projects'

import { getDate, getMoment } from 'utils/dateTime'

import { IconWarningTriangle } from 'svg'

import './ProjectUpdateInfo.scss'

const ProjectUpdateInfo = ({ project, isLoading }) => {
    const { t } = useTranslation()

    const isPublished = project.projectStatus === PROJECT_STATUSES.PUBLISHED

    return (
        <div className="project-block__updated-view-info">
            {isPublished ? (
                <>
                    {getMoment(project.updated).isValid() && (
                        <div className={classNames({ 'is-loading': isLoading })}>
                            {t('Last modified')}: {getDate(project.updated)}
                            {project.updated > project.published && (
                                <TooltipWrapper tooltipText={t('Project has unpublished changes')}>
                                    <div className="triangle-icon">
                                        <IconWarningTriangle />
                                    </div>
                                </TooltipWrapper>
                            )}
                        </div>
                    )}
                    {getMoment(project.published).isValid() && (
                        <div className={classNames({ 'is-loading': isLoading })}>
                            {t('Published')}: {getDate(project.published)}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {getMoment(project.updated).isValid() && (
                        <div className={classNames({ 'is-loading': isLoading })}>
                            {t('Last modified')}: {getDate(project.updated)}
                        </div>
                    )}
                    {getMoment(project.created).isValid() && (
                        <div className={classNames({ 'is-loading': isLoading })}>
                            {t('Created')}: {getDate(project.created)}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ProjectUpdateInfo
