import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ component: Component, location, ...rest }) => {
    const isAuth = useSelector(state => !!state.user_details)

    if (!isAuth) return <Redirect to={{ pathname: '/auth', state: { from: location } }} />

    return <Route {...rest} render={props => <Component {...props} />} />
}

export default ProtectedRoute
