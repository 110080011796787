import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import InlineInput from 'components/Form/InlineInput/InlineInput'
import Dropdown from '../Dropdown/Dropdown'

import './OrgItem.scss'

export const OrgItem = ({
    organization,
    currentUserId,
    onSelect,
    onUpdateOrganization,
    onLeaveFromOrganization,
    onOpenModalManageTeam,
}) => {
    const { t } = useTranslation()
    const [isEditableName, setIsEditableName] = useState(false)

    const onSaveRename = val => {
        if (organization.name !== val) onUpdateOrganization(organization, { name: val })
        setIsEditableName(false)
    }

    return (
        <>
            <Dropdown
                organization={organization}
                currentUserId={currentUserId}
                onLeaveFromOrganization={onLeaveFromOrganization}
                onUpdateOrganization={onUpdateOrganization}
                setIsEditableName={setIsEditableName}
                onOpenModalManageTeam={onOpenModalManageTeam}
            />
            <div className="organization-panel__space-header" onClick={() => onSelect(organization)}>
                <div className="organization-panel__space-header-icon">
                    {organization.logoURL ? (
                        <img src={organization.logoURL} alt="logo of team" />
                    ) : (
                        organization.name.charAt(0).toUpperCase()
                    )}
                </div>
                <div className="organization-panel__space-header-label">
                    <h3 className="organization-panel__space-header-type">{t('Team')}</h3>
                    <div className="organization-panel__space-header-name">
                        <InlineInput
                            inputClassName="organization-rename-input"
                            value={organization.name}
                            maxLength={128}
                            isEditable={isEditableName}
                            onSave={onSaveRename}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
