import React from 'react'

import styles from './WideBox.module.scss'

const WideBox = ({ image, title, isNewLabel = false, body }) => (
    <div className={styles.wideBox}>
        <div className={styles.imageBox}>
            <img className={styles.image} src={image} alt="image" />
        </div>
        <div className={styles.content}>
            {!!title && (
                <div className={styles.title}>
                    {title}
                    {isNewLabel && <span className={styles.newLabel}>NEW</span>}
                </div>
            )}
            <div className={styles.body}>{body}</div>
        </div>
    </div>
)

export default WideBox
