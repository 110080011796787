import React from 'react'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'

class Checkbox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    changeValue = value => {
        const { onUpdate, name, id } = this.props

        onUpdate(id, { [name]: value })
    }

    render() {
        const {
            tooltipText,
            tooltipCallback,
            value,
            disabled,
            schema: { label = '' },
        } = this.props

        return (
            <div className={classNames('control-box', { disabled: disabled })}>
                <div className="content checkbox">
                    <div
                        className={classNames('checkbox-block', { 'is-checked': !!value })}
                        onClick={() => this.changeValue(!value)}
                    />
                    <p onClick={() => this.changeValue(!value)}>
                        {label}
                        {<Tooltip tooltipText={tooltipText} onClick={tooltipCallback} />}
                    </p>
                </div>
            </div>
        )
    }
}

export default Checkbox
