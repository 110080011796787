import BLOCK from './BlockTypes/BlocksEnum'

const GAMIFICATION_ACTION_TYPES = {
    [BLOCK.memoryCards]: {
        reset: 'RESET_MEMORY_INTERACTION_ACTION',
        finish: 'FINISH_MEMORY_INTERACTION_ACTION',
    },
    [BLOCK.hiddenObjects]: {
        reset: 'RESET_HIDDEN_OBJECTS_INTERACTION_ACTION',
        finish: 'FINISH_HIDDEN_OBJECTS_INTERACTION_ACTION',
    },
    [BLOCK.findPair]: {
        reset: 'RESET_FIND_PAIR_INTERACTION_ACTION',
        finish: 'FINISH_FIND_PAIR_INTERACTION_ACTION',
    },
    [BLOCK.puzzle]: {
        reset: 'RESET_PUZZLE_INTERACTION_ACTION',
        finish: 'FINISH_PUZZLE_INTERACTION_ACTION',
    },
    [BLOCK.matching]: {
        reset: 'RESET_MATCHING_INTERACTION_ACTION',
        finish: 'FINISH_MATCHING_INTERACTION_ACTION',
    },
    [BLOCK.crossword]: {
        reset: 'RESET_CROSSWORD_INTERACTION_ACTION',
        finish: 'FINISH_CROSSWORD_INTERACTION_ACTION',
    },
    [BLOCK.slidingPuzzle]: {
        reset: 'RESET_SLIDING_PUZZLE_INTERACTION_ACTION',
        finish: 'FINISH_SLIDING_PUZZLE_INTERACTION_ACTION',
    },
}

Object.freeze(GAMIFICATION_ACTION_TYPES)

export default GAMIFICATION_ACTION_TYPES
