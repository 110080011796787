import i18n from 'i18n/index.js'

export const RATING_TITLE = 'Leader Board'
export const mapGameRatingsToDateRangePanel = gameRatings => {
    return gameRatings.map(rating => mapRatingToDateRange(rating))
}

export const mapRatingToDateRange = rating => {
    return {
        name: `${i18n.t(RATING_TITLE)} ${rating.number}`,
        value: `${RATING_TITLE}_${rating.number}`,
        getTimesRange: () => ({
            to: rating.to,
            from: rating.from,
        }),
    }
}
