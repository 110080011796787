import { cloneDeep } from 'lodash'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Toggle from 'components/Form/Toggle/Toggle'
import ChooseQuestionBankModal from 'components/Modal/ChooseQuestionBankModal/ChooseQuestionBankModal'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import NavigationListBar from '../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../Controls/NavigationListBar/hooks/useNavigation'

import QuestionList from '../components/QuestionList'
import QuestionBankSettings from '../components/QuestionBankSettings/QuestionBankSettings'

import { MAX_QUESTIONS } from '../constants'

import { Answer, createQuestion, Question as QuestionModel } from '../triviaService'

function QuestionView({ tmpStructure, errors, onUpdateStructure, colorTheme }) {
    const { t } = useTranslation()
    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    const [isShowQuestionBankModal, setIsShowQuestionBankModal] = useState(false)
    const { isMultiplayerGame } = useContext(ProjectContext)

    const handleAddQuestion = () => {
        const newQuestion = createQuestion()
        onUpdateStructure({
            questions: tmpStructure.questions.concat(newQuestion),
        })
    }

    const handleCloneQuestion = id => {
        const sourceQuestion = tmpStructure.questions.find(q => q.id === id)
        const clonedQuestions = new QuestionModel(sourceQuestion)
        onUpdateStructure({
            questions: tmpStructure.questions.concat(clonedQuestions),
        })
    }

    const handleRemoveQuestion = id => {
        onUpdateStructure({
            questions: tmpStructure.questions.filter(item => id !== item.id),
        })
    }

    const handleChangeQuestionPosition = async (currentIndex, newIndex) => {
        const questions = cloneDeep(tmpStructure.questions)
        const movingItem = questions[currentIndex]
        questions[currentIndex] = questions[newIndex]
        questions[newIndex] = movingItem
        onUpdateStructure({ questions })
    }

    const handleAddAnswer = questionId => {
        const source = tmpStructure.questions.find(q => q.id === questionId)
        const newAnswer = new Answer()
        const newAnswers = source.answers.concat(newAnswer)
        handleUpdateQuestion(questionId, { [QuestionModel.answers]: newAnswers })
    }

    const handleRemoveAnswer = (questionId, aId) => {
        const source = tmpStructure.questions.find(q => q.id === questionId)
        const newAnswers = source.answers.filter(a => aId !== a.id)
        handleUpdateQuestion(questionId, { [QuestionModel.answers]: newAnswers })
    }

    const handleSetAnswerCorrect = async (questionId, aId, isCorrect) => {
        const source = tmpStructure.questions.find(q => q.id === questionId)
        let conditionFunc = a => (a.id === aId ? { ...a, isCorrect } : a)
        if (source.answers.length <= 2)
            conditionFunc = a => (a.id === aId ? { ...a, isCorrect } : { ...a, isCorrect: !isCorrect })
        const answers = source.answers.map(conditionFunc)
        handleUpdateQuestion(questionId, { [QuestionModel.answers]: answers })
    }

    const handleUpdateQuestion = (id, newFields) => {
        onUpdateStructure({
            questions: tmpStructure.questions.map(q => (q.id === id ? { ...q, ...newFields } : q)),
        })
    }
    const handleUpdateQuestionAnswer = (questionId, answerId, newFields) => {
        const sourceQuestion = tmpStructure.questions.find(q => q.id === questionId)
        const answers = sourceQuestion.answers.map(a => (a.id === answerId ? { ...a, ...newFields } : a))
        handleUpdateQuestion(questionId, { [QuestionModel.answers]: answers })
    }

    return (
        <>
            {isShowQuestionBankModal && (
                <ChooseQuestionBankModal
                    onClose={() => setIsShowQuestionBankModal(false)}
                    onSelect={bank => {
                        setIsShowQuestionBankModal(false)
                        onUpdateStructure({
                            questionBank: {
                                ...tmpStructure.questionBank,
                                bankId: bank.bankId,
                                questionsCount: '5',
                            },
                        })
                    }}
                />
            )}
            <aside>
                {!isMultiplayerGame && (
                    <div className="bank-switcher">
                        <Toggle
                            value={!!tmpStructure.questionBank?.bankId}
                            onUpdate={() => {
                                if (!tmpStructure.questionBank?.bankId) setIsShowQuestionBankModal(true)
                                else {
                                    onUpdateStructure({
                                        questionBank: {
                                            ...tmpStructure.questionBank,
                                            bankId: null,
                                        },
                                    })
                                }
                            }}
                        />
                        <p>{t('Use questions from question bank')}</p>
                    </div>
                )}
                {!tmpStructure.questionBank?.bankId && (
                    <NavigationListBar
                        colorTheme={colorTheme}
                        heightSubtract={isMultiplayerGame ? 0 : '80px'}
                        titleOfItem={t('Question')}
                        focusedItemId={focusedElementId}
                        items={tmpStructure.questions}
                        iconAccessor={'image'}
                        maxLength={MAX_QUESTIONS}
                        onClickItem={scrollContainerToElement}
                        onClickAddNew={handleAddQuestion}
                    />
                )}
            </aside>
            <div className="body">
                <div className="tab-wrapper tab-wrapper--2">
                    <div className="workplace">
                        <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                            <div className="scrollbar-inner">
                                {!tmpStructure.questionBank?.bankId ? (
                                    <QuestionList
                                        tmpStructure={tmpStructure}
                                        errors={errors}
                                        onChangePositionQuestion={handleChangeQuestionPosition}
                                        onUpdateQuestion={handleUpdateQuestion}
                                        onUpdateQuestionAnswer={handleUpdateQuestionAnswer}
                                        onAddQuestion={handleAddQuestion}
                                        onCloneQuestion={handleCloneQuestion}
                                        onRemoveQuestion={handleRemoveQuestion}
                                        onSetFocusToQuestion={onSetFocusedElementId}
                                        onSetAnswerCorrect={handleSetAnswerCorrect}
                                        onAddAnswer={handleAddAnswer}
                                        onRemoveAnswer={handleRemoveAnswer}
                                    />
                                ) : (
                                    <QuestionBankSettings
                                        questionBank={tmpStructure.questionBank}
                                        onUpdateStructure={onUpdateStructure}
                                        errors={errors}
                                        onChangeBank={() => setIsShowQuestionBankModal(true)}
                                    />
                                )}
                            </div>
                        </ScrollableBlock>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionView
