import React, { useEffect, useState, useRef } from 'react'

import useResize from 'hooks/useResize'
import { loadImage } from 'utils/image'

import CoverPreview from '../../../../../components/Modals/components/CoverPreview/CoverPreview'

import useSizes from './hooks/useSizes'

import Board from './components/Board/Board'
import Tile from './components/Tile/Tile'

import { PuzzleBoard } from './puzzleBoardService'

import { MAX_WORKPLACE_WIDTH } from './constants'

import styles from './Playground.module.scss'

const Playground = ({
    isModal,
    tilesCount,
    colorTheme,
    correctColor,
    isHighlightCorrect,
    gameType,
    isShowImageNumbers,
    imageUrl,
    fontFamily,
    isShowCover,
    coverHeader,
    coverButtonText,
}) => {
    const workplaceRef = useRef(null)
    const [tiles, setTiles] = useState([])
    const [imageInfo, setImageInfo] = useState(null)

    const [containerWidth, , boxWidth] = useResize(workplaceRef)

    const { boxBorderWidth, ceilBorderWidth, wrapperBorderWidth, tileSize, fontSize } = useSizes(boxWidth, tilesCount)

    useEffect(() => {
        if (!imageUrl) return
        loadImage(imageUrl)
            .then(data => {
                setImageInfo({ width: data.width, height: data.height, url: imageUrl })
            })
            .catch(err => console.error(err))
    }, [imageUrl])

    const onReset = () => {
        const board = new PuzzleBoard(Math.sqrt(tilesCount))
        setTiles(board.generate())
    }

    useEffect(() => onReset(), [tilesCount])

    return (
        <div className={styles.playground}>
            {isShowCover && (
                <CoverPreview
                    isModal={isModal}
                    colorTheme={colorTheme}
                    headerText={coverHeader}
                    buttonText={coverButtonText}
                />
            )}
            <div
                ref={workplaceRef}
                className={styles.workplace}
                style={{
                    maxWidth: MAX_WORKPLACE_WIDTH,
                    padding: wrapperBorderWidth,
                    backgroundColor: colorTheme,
                }}
            >
                <Board tilesCount={tilesCount} tileSize={tileSize} borderWidth={boxBorderWidth}>
                    {tiles.map((tile, i) => (
                        <Tile
                            key={i}
                            tile={tile}
                            containerWidth={containerWidth}
                            tilesCount={tilesCount}
                            gameType={gameType}
                            imageInfo={imageInfo}
                            tileSize={tileSize}
                            fontSize={fontSize}
                            fontFamily={fontFamily}
                            isHighlightCorrect={isHighlightCorrect}
                            isShowImageNumbers={isShowImageNumbers}
                            correctColor={correctColor}
                            colorTheme={colorTheme}
                            ceilBorderWidth={ceilBorderWidth}
                            boxBorderWidth={boxBorderWidth}
                        />
                    ))}
                </Board>
            </div>
        </div>
    )
}

export default Playground
