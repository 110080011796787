import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import WorldMap from './components/WorldMap/WorldMap'
import CountryList from './components/CountryList/CountryList'

import { API__ANALYTICS } from 'api'

import { hasPermission } from 'common/utils/permissions'
import ANALYTICS_TARGETS from 'common/constants/AnalyticsTargets'

import './GeoData.scss'

const GeoData = ({ projectId, isPreview = false, dateRange }) => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [isFullStatistics] = useState(() => hasPermission('CAN_USE_FULL_STATISTICS'))

    useEffect(() => {
        if (!isFullStatistics) return
        API__ANALYTICS.GET_PROJECT_STATISTICS_MAP_DATA({
            projectId,
            target: ANALYTICS_TARGETS.UNIQUE_USERS,
            from: dateRange.from,
            to: dateRange.to,
        }).then(res => setData(res))
    }, [dateRange])

    return (
        <div className="map-card">
            <h1>{t('Geography')}</h1>
            <div className="description">{t('Shows the number of users in different countries')}</div>
            <WorldMap data={data} isLocked={!isFullStatistics} />
            {isFullStatistics && !isPreview && data.length !== 0 && (
                <CountryList mapData={data} projectId={projectId} dateRange={dateRange} />
            )}
        </div>
    )
}

export default GeoData
