import React from 'react'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import deleteIcon from './i/delete.svg'

import styles from './InputBox.module.scss'

const InputBox = ({ value, letter, removeTitle, onChange, isShowRemove = true, onRemove, error, isReadonly }) => (
    <div className={styles.inputBox}>
        {!!letter && <div className={styles.letter}>{letter}</div>}
        <TextInput
            value={value}
            onUpdate={v => onChange(v)}
            className={styles.textInput}
            isReadOnly={isReadonly}
            inputClassName={classNames({ [styles.inputWithLetter]: letter, [styles.isError]: error })}
        />
        <div
            className={classNames(styles.remove, { [styles.isHideRemove]: !isShowRemove })}
            title={removeTitle}
            onClick={() => onRemove()}
        >
            <img src={deleteIcon} alt="remove" />
        </div>
        {error && <div className={styles.error}>{error}</div>}
    </div>
)

export default InputBox
