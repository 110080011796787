import BLOCK from 'common/constants/BlockTypes/BlocksEnum'
import storage from 'common/utils/storage'

import interactiveImageCoverImage from './i/previews/interactiveImage-cover.png'
import interactiveImageLiveImage from './i/previews/interactiveImage-live.gif'
import memoryCoverImage from './i/previews/memory-cover.png'
import memoryLiveImage from './i/previews/memory-live.gif'
import personalityCoverImage from './i/previews/personality-cover.png'
import personalityLiveImage from './i/previews/personality-live.gif'
import thenNowCoverImage from './i/previews/thenNow-cover.png'
import thenNowLiveImage from './i/previews/thenNow-live.gif'
import timelineCoverImage from './i/previews/timeline-cover.png'
import timelineLiveImage from './i/previews/timeline-live.gif'
import triviaCoverImage from './i/previews/trivia-cover.png'
import triviaLiveImage from './i/previews/trivia-live.gif'
import multiplayerCoverImage from './i/previews/multiplayer-cover.png'
import multiplayerLiveImage from './i/previews/multiplayer-live.gif'
import treasureHuntCoverImage from './i/previews/treasureHunt-cover.png'
import treasureHuntLiveImage from './i/previews/treasureHunt-live.gif'
import wheelSpinCoverImage from './i/previews/wheelSpin-cover.png'
import wheelSpinLiveImage from './i/previews/wheelSpin-live.gif'
import slideshowCoverImage from './i/previews/slideshow-cover.png'
import slideshowLiveImage from './i/previews/slideshow-live.gif'
import puzzleCoverImage from './i/previews/puzzle-cover.png'
import puzzleLiveImage from './i/previews/puzzle-live.gif'
import matchingCoverImage from './i/previews/matching-cover.png'
import matchingLiveImage from './i/previews/matching-live.gif'
import leadFormCoverImage from './i/previews/leadForm-cover.png'
import leadFormLiveImage from './i/previews/leadForm-live.gif'
import horoscopeCoverImage from './i/previews/horoscope-cover.png'
import horoscopeLiveImage from './i/previews/horoscope-live.gif'
import hiddenObjectsCoverImage from './i/previews/hiddenObjects-cover.png'
import hiddenObjectsLiveImage from './i/previews/hiddenObjects-live.gif'
import cookiesCoverImage from './i/previews/cookies-cover.png'
import cookiesLiveImage from './i/previews/cookies-live.gif'
import flipCardCoverImage from './i/previews/flipCard-cover.png'
import flipCardLiveImage from './i/previews/flipCard-live.gif'
import findPairCoverImage from './i/previews/findPair-cover.png'
import findPairLiveImage from './i/previews/findPair-live.gif'
import crosswordCoverImage from './i/previews/crossword-cover.png'
import crosswordLiveImage from './i/previews/crossword-live.gif'
import slidingPuzzleCoverImage from './i/previews/slidingPuzzle-cover.png'
import slidingPuzzleLiveImage from './i/previews/slidingPuzzle-live.gif'
import rankBattleCoverImage from './i/previews/rankBattle-cover.png'
import rankBattleLiveImage from './i/previews/rankBattle-live.gif'

const keyStore = 'watchedTips'

export class Tip {
    id
    coverPicture
    previewPicture
    resourceLink
    blockType
    onFilter

    constructor(id, coverPicture, previewPicture, resourceLink, blockType, onFilter = () => true) {
        this.id = id
        this.coverPicture = coverPicture
        this.previewPicture = previewPicture
        this.resourceLink = resourceLink
        this.blockType = blockType || null
        this.onFilter = onFilter
    }
}

export const TIP_IDS = {
    MULTIPLAYER: 'MULTIPLAYER',
    MEMORY: 'MEMORY',
    INTERACTIVE_IMAGE: 'INTERACTIVE_IMAGE',
    PERSONALITY: 'PERSONALITY',
    THEN_NOW: 'THEN_NOW',
    TIMELINE: 'TIMELINE',
    TRIVIA: 'TRIVIA',
    TREASURE_HUNT: 'TREASURE_HUNT',
    WHEEL_SPIN: 'WHEEL_SPIN',
    SLIDESHOW: 'SLIDESHOW',
    PUZZLE: 'PUZZLE',
    MATCHING: 'MATCHING',
    LEAD_FORM: 'LEAD_FORM',
    HOROSCOPE: 'HOROSCOPE',
    HIDDEN_OBJECTS: 'HIDDEN_OBJECTS',
    COOKIES: 'COOKIES',
    FLIP_CARD: 'FLIP_CARD',
    FIND_PAIR: 'FIND_PAIR',
    CROSSWORD: 'CROSSWORD',
    SLIDING_PUZZLE: 'SLIDING_PUZZLE',
    RANK_BATTLE: 'RANK_BATTLE',
}

const tips = [
    new Tip(
        TIP_IDS.MULTIPLAYER,
        multiplayerCoverImage,
        multiplayerLiveImage,
        'https://youtu.be/2YpexUSWQPA',
        null,
        ({ isMultiplayerGame }) => isMultiplayerGame,
    ),
    new Tip(TIP_IDS.MEMORY, memoryCoverImage, memoryLiveImage, 'https://youtu.be/zCyM2_Sx6dQ', BLOCK.memoryCards),
    new Tip(
        TIP_IDS.INTERACTIVE_IMAGE,
        interactiveImageCoverImage,
        interactiveImageLiveImage,
        'https://youtu.be/UydBxBeqIGI',
        BLOCK.interactiveImage,
    ),
    new Tip(
        TIP_IDS.PERSONALITY,
        personalityCoverImage,
        personalityLiveImage,
        'https://youtu.be/OKkbkjedQ2I',
        BLOCK.personalityQuiz,
    ),
    new Tip(TIP_IDS.THEN_NOW, thenNowCoverImage, thenNowLiveImage, 'https://youtu.be/UmFBLxsQkVI', BLOCK.thenNow),
    new Tip(TIP_IDS.TIMELINE, timelineCoverImage, timelineLiveImage, 'https://youtu.be/tiN1Utc9FME', BLOCK.timeline),
    new Tip(TIP_IDS.TRIVIA, triviaCoverImage, triviaLiveImage, 'https://youtu.be/x0CN9S10Qsw', BLOCK.quiz),
    new Tip(
        TIP_IDS.TREASURE_HUNT,
        treasureHuntCoverImage,
        treasureHuntLiveImage,
        'https://youtu.be/DxLH5ECKlsk',
        BLOCK.treasureHunt,
    ),
    new Tip(
        TIP_IDS.WHEEL_SPIN,
        wheelSpinCoverImage,
        wheelSpinLiveImage,
        'https://youtu.be/Y3peLcQmDIU',
        BLOCK.wheelSpin,
    ),
    new Tip(
        TIP_IDS.SLIDESHOW,
        slideshowCoverImage,
        slideshowLiveImage,
        'https://youtu.be/5q7UL2qjF3s',
        BLOCK.slideshow,
    ),
    new Tip(TIP_IDS.PUZZLE, puzzleCoverImage, puzzleLiveImage, 'https://youtu.be/dZIDTIqeuUE', BLOCK.puzzle),
    new Tip(TIP_IDS.MATCHING, matchingCoverImage, matchingLiveImage, 'https://youtu.be/cARShte8XMQ', BLOCK.matching),
    new Tip(TIP_IDS.LEAD_FORM, leadFormCoverImage, leadFormLiveImage, 'https://youtu.be/AnpMLYFLrik', BLOCK.leadForm),
    new Tip(
        TIP_IDS.HOROSCOPE,
        horoscopeCoverImage,
        horoscopeLiveImage,
        'https://youtu.be/GPI-gsw3OkM',
        BLOCK.horoscope,
    ),
    new Tip(
        TIP_IDS.HIDDEN_OBJECTS,
        hiddenObjectsCoverImage,
        hiddenObjectsLiveImage,
        'https://youtu.be/wAiE5aDTXD0',
        BLOCK.hiddenObjects,
    ),
    new Tip(TIP_IDS.COOKIES, cookiesCoverImage, cookiesLiveImage, 'https://youtu.be/Hy57ZvHUNrU', BLOCK.cookies),
    new Tip(TIP_IDS.FLIP_CARD, flipCardCoverImage, flipCardLiveImage, 'https://youtu.be/Q2jM-a4YQbg', BLOCK.flipCards),
    new Tip(TIP_IDS.FIND_PAIR, findPairCoverImage, findPairLiveImage, 'https://youtu.be/AwlSaDwJtqo', BLOCK.findPair),
    new Tip(
        TIP_IDS.CROSSWORD,
        crosswordCoverImage,
        crosswordLiveImage,
        'https://youtu.be/iUVl-f5eAc4',
        BLOCK.crossword,
    ),
    new Tip(
        TIP_IDS.SLIDING_PUZZLE,
        slidingPuzzleCoverImage,
        slidingPuzzleLiveImage,
        'https://youtu.be/-fzYVvO52rQ',
        BLOCK.slidingPuzzle,
    ),
    new Tip(
        TIP_IDS.RANK_BATTLE,
        rankBattleCoverImage,
        rankBattleLiveImage,
        'https://youtu.be/G_u3WJ4eqgY',
        BLOCK.rankBattle,
    ),
]

export function getTips({ isMultiplayerGame = false }) {
    return tips.filter(tip => tip.onFilter({ isMultiplayerGame }))
}

export function getWatchedTipsMap() {
    return storage.getValue(keyStore, Object) || {}
}

export function putWatchedTipsMap(watchedTips) {
    storage.setValue(keyStore, watchedTips)
}
