import React from 'react'

import Tooltip from 'components/Tooltip/Tooltip'

class ArrayWithModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            tooltipText,
            id,
            value,
            schema: { label = '', payload },
            schemaName,
            openModalByName,
        } = this.props

        const info = payload.values[value.length ? 'isFilled' : 'isEmpty']

        return (
            <>
                <div className="control-box flexed">
                    <p className="label">
                        {label}
                        {<Tooltip tooltipText={tooltipText} />}
                    </p>
                    <div
                        onClick={() =>
                            openModalByName(payload.modalName, {
                                passwordList: value,
                                blockId: id,
                                fieldName: schemaName,
                            })
                        }
                    >
                        {info.icon ? <img src={info.icon} alt="icon" /> : null}
                        {info.label || null}
                    </div>
                </div>
            </>
        )
    }
}

export default ArrayWithModal
