import { decodeString } from './objectStringsAction'

export function downloadFile(href, fileName) {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = href
    hiddenElement.target = '_blank'
    hiddenElement.download = fileName
    hiddenElement.click()
    hiddenElement.remove()
}

export function downloadCSV(content, fileName) {
    const decodedContent = decodeString(content)
    const href = `data:text/csv;charset=utf-8,${encodeURIComponent(decodedContent)}`
    downloadFile(href, fileName)
}
