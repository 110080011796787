const ACTIONS = {
    PROJECT_OPENED: 'PROJECT_OPENED',
    GAME_OPENED: 'GAME_OPENED',
    GAME_STARTED: 'GAME_STARTED',
    LEAD_FORM_SUBMITTED: 'LEAD_FORM_SUBMITTED',
    GAME_COMPLETED: 'GAME_COMPLETED',
    TIME_IS_UP: 'TIME_IS_UP',
    CTA_CLICK: 'CTA_CLICK',
    GAME_RESTARTED: 'GAME_RESTARTED',
    SEC_IN_GAME_5: 'SEC_IN_GAME_5',
    SEC_IN_GAME_10: 'SEC_IN_GAME_10',
}

export default ACTIONS
