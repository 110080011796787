const FIXED_TOP_OFFSET_FOR_SCROLL = 36

export const scrollContainerToElementById = (nodeRef, elementId) => {
    try {
        const element = nodeRef.current.querySelector(`#${elementId}`)
        if (!element) return
        setTimeout(() => {
            nodeRef.current.scrollTo({
                top: element.offsetTop - FIXED_TOP_OFFSET_FOR_SCROLL,
                left: 0,
                behavior: 'smooth',
            })
        })
    } catch (err) {
        console.error(err)
    }
}
