import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({ settings, data, events }) => {
    return (
        <ReactPaginate
            previousLabel={'PREV'}
            nextLabel={'NEXT'}
            breakLabel={'...'}
            breakClassName={'break'}
            disableInitialCallback={settings.disableInitCallback}
            forcePage={data.currentPage}
            pageCount={data.totalPages}
            marginPagesDisplayed={settings.marginPages}
            pageRangeDisplayed={settings.pageRange}
            onPageChange={events.handleClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'is-active'}
        />
    )
}

export default Pagination
