import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'

import { FINAL_KEYS } from '../../constants'

import Preview from './components/Preview/Preview'

import './Final.scss'

const Final = ({ structure, validateErrors, methods: { updateStructure } }) => {
    const { t } = useTranslation()

    return (
        <div className="lead-form__final">
            <div className="lead-form__final__workplace-wrapper">
                <ScrollableBlock>
                    <div className="lead-form__final__workplace">
                        <h3 className="lead-form__final__workplace-title">{t('Thank you')}</h3>
                        <div className="lead-form__final_workplace-content">
                            <TextInput
                                label={t('Header')}
                                isRequired
                                value={structure.final.headerText}
                                errorMessages={validateErrors.headerText}
                                onUpdate={value => updateStructure({ [FINAL_KEYS.headerText]: value })}
                            />
                            <TextArea
                                label={t('Description')}
                                value={structure.final.descriptionText}
                                errorMessages={validateErrors.descriptionText}
                                onUpdate={value => updateStructure({ [FINAL_KEYS.descriptionText]: value })}
                            />
                            <ElementsGroup>
                                <UploadImage
                                    label={t('Image')}
                                    value={structure.final.imageSrc}
                                    onUpdate={value => updateStructure({ [FINAL_KEYS.imageSrc]: value })}
                                />
                                {!!structure.final.imageSrc && (
                                    <TextInput
                                        label={t('Image disclaimer')}
                                        value={structure.final.imageDisclaimerText}
                                        errorMessages={validateErrors.imageDisclaimerText}
                                        onUpdate={value => updateStructure({ [FINAL_KEYS.imageDisclaimerText]: value })}
                                    />
                                )}
                            </ElementsGroup>
                        </div>
                    </div>
                </ScrollableBlock>
            </div>
            <div className="lead-form__final__preview-wrapper">
                <ScrollableBlock>
                    <div className="lead-form__final__preview">
                        <p className="lead-form__final__preview-title">{t('Approximate preview')}</p>
                        <Preview
                            headerText={structure.final.headerText}
                            descriptionText={structure.final.descriptionText}
                            imageSrc={structure.final.imageSrc}
                            imageDisclaimerText={structure.final.imageDisclaimerText}
                        />
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}

export default Final
