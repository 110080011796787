import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import PlusSVG from './svg/Plus'
import MinusSVG from './svg/Minus'

import styles from './Block.module.scss'

const Block = ({ item, selectedTags, onTagClick }) => {
    const { t } = useTranslation()
    const [isCollapsed, setIsCollapsed] = useState(false)

    return (
        <li className={styles.block}>
            <div
                className={classNames(styles.head, { [styles.isCollapsedHead]: isCollapsed })}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <div className={styles.headLeft}>
                    <div className={styles.iconBox}>
                        {!!item.parentTag.iconUrl && (
                            <img className={styles.icon} src={item.parentTag.iconUrl} alt="" />
                        )}
                    </div>
                    <p className={styles.text}>{item.parentTag.formattedName}</p>
                </div>
                <div className={styles.headRight}>{isCollapsed ? <MinusSVG /> : <PlusSVG />}</div>
            </div>
            <div className={classNames(styles.dropdown, { [styles.isActiveDropdown]: isCollapsed })}>
                <div className={styles.dropdownNote}>{t('Please select at least one:')}</div>
                <ul className={styles.tagsList}>
                    {item.childrenTags
                        .sort((a, b) => {
                            if (a.formattedName < b.formattedName) return -1
                            if (a.formattedName > b.formattedName) return 1
                            return 0
                        })
                        .map(childrenTag => (
                            <li
                                key={childrenTag.id}
                                className={classNames(styles.tagItem, {
                                    [styles.isSelectedTagItem]: selectedTags.some(tag => tag.name === childrenTag.name),
                                })}
                                onClick={() => onTagClick(childrenTag, item.parentTag)}
                            >
                                {!!childrenTag.iconUrl && (
                                    <div className={styles.tagIcon}>
                                        <img src={childrenTag.iconUrl} alt="" />
                                    </div>
                                )}
                                <p className={styles.tagText}>{childrenTag.formattedName}</p>
                            </li>
                        ))}
                </ul>
            </div>
        </li>
    )
}

export default Block
