import { DEFAULT_IMAGE_BG_BIG_URL, DEFAULT_IMAGE_BG_BIG_BRIGHT_URL } from 'common/constants/constants'
import { getUniqueId } from 'common/utils/getUniqueId'
import { CARD_BACKGROUND_COLOR, CARD_TYPES } from './constants'

export class MemoryCard {
    id // <String> id
    src // <null/String[URL]> image
    cardType // <String> card type
    text // <String> text
    bgColor // <String> background color card

    constructor({
        text = '',
        src = DEFAULT_IMAGE_BG_BIG_URL,
        bgColor = CARD_BACKGROUND_COLOR[0],
        cardType = CARD_TYPES.image,
    } = {}) {
        this.id = getUniqueId()
        this.text = text
        this.src = src
        this.bgColor = bgColor
        this.cardType = cardType
    }

    static get text() {
        return 'text'
    }

    static get src() {
        return 'src'
    }

    static get bgColor() {
        return 'bgColor'
    }

    static get cardType() {
        return 'cardType'
    }
}

export class MemoryPair {
    id // <String> id
    description // <String> description
    firstImage // <MemoryCard> first card
    secondImage // <MemoryCard> second card

    constructor({
        description = '',
        firstImage = new MemoryCard({ src: DEFAULT_IMAGE_BG_BIG_URL }),
        secondImage = new MemoryCard({ src: DEFAULT_IMAGE_BG_BIG_BRIGHT_URL }),
    } = {}) {
        this.id = getUniqueId()
        this.description = description
        this.firstImage = firstImage
        this.secondImage = secondImage
    }

    static get description() {
        return 'description'
    }

    static get firstImage() {
        return 'firstImage'
    }

    static get secondImage() {
        return 'secondImage'
    }
}
