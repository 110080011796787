import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"

import Modal from 'components/Modal/Modal'
import TextInput from 'components/Form/TextInput/TextInput'
import Button from "components/Form/Button/Button"
import Toast from 'components/Toast/Toast'

import { GET_CHAT_COMPLETIONS } from "api/openai"

import { createCollector, createValidator, required, minNumber, maxNumber } from 'shared/validation'

import { MIN_ITEMS_COUNT, MAX_AI_ITEMS_COUNT } from "../../../../../../constants"

import crossIcon from './i/cross.svg'

import { getPrompt } from "./utils"

import styles from './AiGenerationModal.module.scss'

const AiGenerationModal = ({ onClose, onAccept }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [wordsCount, setWordsCount] = useState(5)
    const [theme, setTheme] = useState('')

    const [isDisabled, setIsDisabled] = useState(false)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setIsDisabled(isLoading || !wordsCount || !theme)
    }, [isLoading, !wordsCount, !theme])

    const onSubmit = async evt => {
        try {
            if (evt) evt.preventDefault()

            setIsLoading(true)

            const fields = {
                wordsCount,
                theme: theme.trim(),
            }
            const validationRules = {
                wordsCount: [required(), minNumber(MIN_ITEMS_COUNT), maxNumber(MAX_AI_ITEMS_COUNT)],
                theme: [required()],
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            const prompt = getPrompt(wordsCount, theme)

            const response = await GET_CHAT_COMPLETIONS(prompt)

            onAccept(JSON.parse(response))
            onClose()
        } catch (err) {
            console.error(err)
            Toast('error', {
                message: t('We are currently developing this functionality, so there may be some glitches. Please try again.'),
                time: 6000,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={true} onClose={onClose}>
            <div className={styles.aiGenerationModal}>
                <form noValidate onSubmit={onSubmit}>
                    <div onClick={onClose} className={styles.cross}><img src={crossIcon} alt=""/></div>
                    <h3 className={styles.title}>{t('AI Generator')}</h3>
                    <div className={styles.inputs}>
                        <TextInput
                            label={t('Words count')}
                            controlType="number"
                            min={MIN_ITEMS_COUNT}
                            max={MAX_AI_ITEMS_COUNT}
                            errorMessages={errors.wordsCount || []}
                            value={wordsCount}
                            onUpdate={v => {
                                setWordsCount(v)
                                setErrors({...errors, wordsCount: []})
                            }}
                        />
                        <br/>
                        <TextInput
                            label={t('Theme')}
                            errorMessages={errors.theme || []}
                            placeholder={t('Example: A Christmas traditions for fourth-grade children')}
                            value={theme}
                            onUpdate={v => {
                                setTheme(v)
                                setErrors({...errors, theme: []})
                            }}
                        />
                    </div>
                    <p className={styles.note}>{t('Note: Your existing data will be overwritten!')}</p>
                    <div className={styles.buttons}>
                        <Button
                            className={styles.button} o
                            onClick={onSubmit}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {t('Generate')}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
)
}

export default AiGenerationModal