import React from 'react'
import { useTranslation } from 'react-i18next'

import WomanNotebook from './i/womanNotebook.svg'

const NoSubscription = () => {
    const { t } = useTranslation()
    return (
        <div className="billing-history__no-subscription">
            <img src={WomanNotebook} />
            <p>{t('You have no subscriptions')}</p>
            <button className="button" data-variant="primary" data-size="lg">
                <a href="/billing-and-payments">{t('Subscribe')}</a>
            </button>
        </div>
    )
}

export default NoSubscription
