import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'
import { IconCopy, OpenInNewWindow } from 'svg'

import styles from './ShareUrlBody.module.scss'

const ShareUrlBody = ({ projectUrl, onCopyProjectUrl }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.shareUrlBody}>
            <div className={styles.inputBox}>
                <TextInput value={projectUrl} isReadOnly className={styles.input} />
            </div>
            <button
                className={classNames('button', styles.button)}
                data-variant="primary-light"
                data-size="lg"
                onClick={() => onCopyProjectUrl()}
            >
                <span className={styles.buttonIcon}>
                    <IconCopy />
                </span>
                {t('Copy')}
            </button>
            <a
                href={projectUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('button', styles.button)}
                data-size="lg"
                data-variant="primary"
            >
                <span className={styles.buttonIcon}>
                    <OpenInNewWindow color="#fff" />
                </span>
                {t('View project')}
            </a>
        </div>
    )
}

export default ShareUrlBody
