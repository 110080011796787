const HINT_KEY = 'HINTS'

const getItem = () => {
    try {
        const hints = JSON.parse(localStorage.getItem(HINT_KEY))
        return hints && Array.isArray(hints) ? hints : []
    } catch (err) {
        return []
    }
}

export const isHintShown = hintName => {
    const hints = getItem()
    return hints.includes(hintName)
}

export const markHintIsShown = hintName => {
    const hints = getItem()
    localStorage.setItem(HINT_KEY, JSON.stringify([...hints, hintName]))
}
