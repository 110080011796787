import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddButton from '../AddButton/AddButton'

import AiGenerationModal from "./components/AiGenerationModal/AiGenerationModal"
import AiButton from "./components/AiButton/AiButton"

import styles from './Header.module.scss'

const Header = ({ itemsCount, onAdd, onAccept }) => {
    const { t } = useTranslation()
    const [isOpenAiModal, setIsOpenAiModal] = useState(false)

    return (
        <div className={styles.header}>
            <p className={styles.title}>{t('Words and clues')}</p>
            <div className={styles.buttons}>
                <AddButton itemsCount={itemsCount} onAdd={onAdd}/>
                <AiButton onClick={() => setIsOpenAiModal(true)} />
            </div>

            {isOpenAiModal && (
                <AiGenerationModal
                    onClose={() => setIsOpenAiModal(false)}
                    onAccept={data => onAccept(data)}
                />
            )}
        </div>
    )
}

export default Header
