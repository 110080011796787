import { cloneDeep } from 'lodash'

import store from 'store'
import countryDataset from 'datasets/country.json'
import languageDataset from 'datasets/language.json'

import { LOCALES } from 'i18n/languages'

export const getCountryByCode = code => {
    if (!code) return null
    const el = countryDataset.find(el => el.code === code)
    if (!el) return null
    return {
        value: el.code,
        label: el.name,
    }
}

export const getLanguageByCode = code => {
    if (!code) return null
    const el = languageDataset.find(el => el.code === code)
    if (!el) return null
    return {
        value: el.code,
        label: el.label,
    }
}

export const delay = ms => new Promise(r => setTimeout(r, ms))

export const isRussianClientCountry = () => {
    const { clientCountry } = store.getState()
    if (!clientCountry) return true
    return clientCountry === LOCALES.RU.toUpperCase()
}
export const isBlockedMeta = () => isRussianClientCountry()
export const isBlockedPixabay = () => isRussianClientCountry()

export const moveInArray = (arr, fromIndex, toIndex) => {
    const array = cloneDeep(arr)
    const element = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, element)
    return array
}

export const compareArrays = (arr1, arr2) => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    if (set1.size !== set2.size) return false

    for (const item of set1) {
        if (!set2.has(item)) return false
    }

    return true
}