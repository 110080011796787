import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import styles from './TabContent.module.scss'

const TabContent = ({ header, scrollableNodeRef, isHideHeader = false, className, children }) => {
    const { t } = useTranslation()

    return (
        <ScrollableBlock scrollableNodeRef={scrollableNodeRef} className={styles.tabContent}>
            <div className={classNames(styles.main, { [className]: !!className })}>
                {!isHideHeader && <h3 className={styles.header}>{header || t('Final screen')}</h3>}
                <div className={styles.content}>{children}</div>
            </div>
        </ScrollableBlock>
    )
}

export default TabContent
