import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import iconAttention from './i/attention.svg'
import iconStopHand from './i/stopHand.svg'

import Toast from 'components/Toast/Toast'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { API__USER } from 'api'

import { AUTH__LOGOUT } from 'store/actions'

import styles from './AccountDelete.module.scss'

const AccountDelete = ({ userDetails }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [isOpenedDialog, setIsOpenedDialog] = useState(false)

    const dispatch = useDispatch()

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            await API__USER.DELETE(userDetails.id)
            dispatch(AUTH__LOGOUT())

            Toast('success', {
                message: t('Your account is removed successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.accountDelete}>
            <div className={styles.side}>
                <img className={styles.icon} src={iconAttention} alt="" />
                <p className={styles.text}>{t('All project will be deleted too')}</p>
            </div>
            <div className={styles.buttonBlock}>
                <button
                    className="button"
                    data-size="md"
                    data-variant="primary-danger"
                    onClick={() => setIsOpenedDialog(true)}
                >
                    {t('Delete account')}
                </button>
            </div>

            {isOpenedDialog && (
                <ConfirmationDialog
                    isLoading={isLoading}
                    onClose={() => setIsOpenedDialog(false)}
                    onAction={() => onSubmit()}
                    data={{
                        headText: t('Wait!'),
                        image: iconStopHand,
                        noteText: (
                            <>
                                {t('You are about to delete your account.')}
                                <br />
                                <Trans
                                    i18nKey="This action <tag>cannot be undone</tag>."
                                    components={{
                                        tag: <span style={{ color: '#fd8d8d' }} />,
                                    }}
                                />
                            </>
                        ),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
        </div>
    )
}

export default AccountDelete
