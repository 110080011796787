import { cloneDeep } from 'lodash'
import { getColumns } from './layouts/Blocks/components/RatingTable/components/RatingData/columns'

export const generateNameCSVFile = (label, from, to) => {
    const fromDate = new Date(from)
    const toDate = to ? new Date(to) : new Date()

    const dates = {
        from: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
        to: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
    }
    return `${label} (${dates.from} - ${dates.to})_${Date.now()}.csv`
}

export const prettifyNumber = (num = 0) => {
    const n = num.toString()
    const separator = ' '
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator)
}

export const mapQuizStatistic = (statistic, blockProjection) => {
    if (statistic.blockId !== blockProjection.blockId) {
        return null
    }

    const mappedProjection = cloneDeep(blockProjection.metadata)

    mappedProjection.results = mappedProjection.results.map(result => {
        let resultStat = statistic.quizResults.find(_resultStat => _resultStat.resultId === result.id)
        if (!resultStat) {
            resultStat = {
                percent: 0,
                users: 0,
            }
        }
        return {
            ...result,
            ...resultStat,
        }
    })

    mappedProjection.questions = mappedProjection.questions.map(question => {
        let questionStat = statistic.questionsStatistic.find(_questionStat => _questionStat.questionId === question.id)
        if (!questionStat) {
            questionStat = {
                started: 0,
                answered: 0,
                answers: [],
            }
        }

        const mappedAnswers = question.answers.map(answer => {
            let answerStat = questionStat.answers.find(_answerStat => _answerStat.responseOptionId === answer.id)
            if (!answerStat) {
                answerStat = {
                    percent: 0,
                    users: 0,
                }
            }
            return {
                ...answer,
                ...answerStat,
            }
        })

        return {
            ...question,
            ...questionStat,
            answers: mappedAnswers,
        }
    })

    return mappedProjection
}

export const mapLeadInfo = (leadInfo, blockProjection) => {
    const mappedLeadInfo = cloneDeep(leadInfo)

    mappedLeadInfo.fields = blockProjection.metadata.leadFormFields.map(_metaField => {
        const field = mappedLeadInfo.fields.find(_field => _field.key === _metaField.key)
        return { ...field, ..._metaField }
    })

    if (mappedLeadInfo.gamificationData) {
        mappedLeadInfo.gamificationDataArray = getColumns().map(column => ({
            ...column,
            value: mappedLeadInfo.gamificationData[column.field],
        }))
    }

    if (mappedLeadInfo.quizzes) {
        mappedLeadInfo.quizzes = mappedLeadInfo.quizzes.map(quiz => {
            if (blockProjection.blockId === quiz.blockId) {
                const mappedQuiz = cloneDeep(quiz)

                mappedQuiz.quizResults = mappedQuiz.quizResults.map(resultId =>
                    blockProjection.metadata.results.find(resultMeta => resultMeta.id === resultId),
                )
                mappedQuiz.quizAnswers = mappedQuiz.quizAnswers.map(({ questionId, answers }) => {
                    const questionMeta = blockProjection.metadata.questions.find(
                        _questionMeta => _questionMeta.id === questionId,
                    )

                    const mappedAnswers = answers.map(answerId =>
                        questionMeta.answers.find(answerMeta => answerMeta.id === answerId),
                    )

                    return {
                        ...questionMeta,
                        answers: mappedAnswers,
                    }
                })

                return mappedQuiz
            }
            return null
        })
    }

    if (mappedLeadInfo.wheels) {
        mappedLeadInfo.wheels = mappedLeadInfo.wheels.map(wheel => {
            if (blockProjection.blockId === wheel.blockId) {
                const mappedWheel = cloneDeep(wheel)

                mappedWheel.wheelSections = mappedWheel.wheelSections.map(sectionId => {
                    return blockProjection.metadata.sections.find(sectionMeta => sectionMeta.id === sectionId)
                })

                return mappedWheel
            }
            return null
        })
    }

    return mappedLeadInfo
}
