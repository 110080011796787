import React, { useEffect, useState } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import Header from 'components/Header/Header'
import Footer from 'components/Footer'
import NewUserModal from 'components/Modal/NewUserModal/NewUserModal'
import TrialExtensionModal from 'components/Modal/TrialExtensionModal/TrialExtensionModal'
import useAnalyticsMonitor from 'components/useAnalyticsMonitor'
import { AssetManagerProvider } from 'components/AssetManager/AssetManagerContext'

import OrganizationInitializer from 'initializers/OrganizationInitializer'
import UserInitializer from 'initializers/UserInitializer'

import AccountSettings from 'pages/AccountSettings/AccountSettings.page'
import BillingAndPayments from 'pages/BillingAndPayments/BillingAndPayments.page'
import MyProjects from 'pages/MyProjects/MyProjects.page'
import ProjectAnalytics from 'pages/ProjectAnalytics/ProjectAnalytics.page'
import Editor from 'pages/Editor/Editor.page'
import QuestionBank from 'pages/QuestionBank/QuestionBank.page'
import PublicPageStatistic from 'pages/PublicPageStatistic/PublicPageStatistic.page'
import MyPurchases from 'pages/MyPurchases/MyPurchases.page'

import ProtectedRoute from 'routes/ProtectedRoute'

import AdminPanel from './components/AdminPanel/AdminPanel'

const MainLayout = () => {
    useAnalyticsMonitor()
    const location = useLocation()
    const { user_details } = useSelector(state => state)

    const [isOpenedNewUserModal, setIsOpenedNewUserModal] = useState(user_details && !user_details.roleInProjectDto)
    const [isOpenedTrialModal, setIsOpenedTrialModal] = useState(false)

    useEffect(() => {
        setIsOpenedNewUserModal(user_details && !user_details.roleInProjectDto)
    }, [])

    const isShowFooterAndHeader = !location.pathname.includes('/editor')

    return (
        <UserInitializer userId={user_details.id}>
            <OrganizationInitializer userId={user_details.id}>
                <AssetManagerProvider>
                    <div className={classNames('main', { 'is-editor-main': !isShowFooterAndHeader })}>
                        {isShowFooterAndHeader && <Header />}
                        <main>
                            <Switch>
                                <ProtectedRoute path="/editor/:projectId?" component={Editor} />
                                <ProtectedRoute exact path="/settings" component={AccountSettings} />
                                <ProtectedRoute exact path="/billing-and-payments" component={BillingAndPayments} />
                                <ProtectedRoute exact path="/my-projects" component={MyProjects} />
                                <ProtectedRoute path="/my-projects/:id/analytics" component={ProjectAnalytics} />
                                <ProtectedRoute path="/question-bank" component={QuestionBank} />
                                <ProtectedRoute path="/public-page-statistic" component={PublicPageStatistic} />
                                <ProtectedRoute path="/my-purchases" component={MyPurchases} />

                                <Redirect from="*" to="/my-projects" />
                            </Switch>
                        </main>
                        {isShowFooterAndHeader && <Footer />}

                        {isOpenedNewUserModal && (
                            <NewUserModal
                                onClose={isShowTrialActivatedModal => {
                                    setIsOpenedNewUserModal(false)
                                    if (isShowTrialActivatedModal) setIsOpenedTrialModal(true)
                                }}
                            />
                        )}
                        {isOpenedTrialModal && <TrialExtensionModal onClose={() => setIsOpenedTrialModal(false)} />}
                    </div>
                    {user_details.adminAuth && <AdminPanel />}
                </AssetManagerProvider>
            </OrganizationInitializer>
        </UserInitializer>
    )
}

export default MainLayout
