import React, { useState } from 'react'

import { DEFAULT_IMAGE_BG_WIDE_URL } from 'common/constants/constants'

import { PuzzlePlaygroundPreview } from '../components/Modals/PuzzleModal/components'
import StatisticPreview from '../components/StatisticPreview/StatisticPreview'
import { PuzzleModal } from '../components/Modals/PuzzleModal'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const Puzzle = ({ struct, enableTimer, onUpdate, id, colorTheme }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            {enableTimer && <StatisticPreview />}
            <PuzzlePlaygroundPreview
                colorTheme={colorTheme}
                image={struct.playground ? struct.playground.image : DEFAULT_IMAGE_BG_WIDE_URL}
                layout={struct.playground ? struct.playground.layout : { value: 4 }}
                isShowCover={struct.playground ? struct.playground.isShowCover : false}
                coverHeader={struct.playground ? struct.playground.coverHeader : null}
                coverButtonText={struct.playground ? struct.playground.coverButtonText : null}
                height={400}
            />
            {isShowModal && (
                <PuzzleModal
                    data={{
                        structure: struct,
                        colorTheme,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default Puzzle
