import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import InteractiveBlockWrapper from './components/InteractiveBlockWrapper/InteractiveBlockWrapper'
import { viewsComponents, viewsTitle } from './constants'

const BlocksLayout = ({ projectProjection }) => {
    const { projectId, blockId } = useParams()
    const { projections } = projectProjection

    const [blockProjection, setBlockProjection] = useState(() => {
        return projections.find(projection => projection.blockId === blockId)
    })
    const [dateRange, setDateRange] = useState(null)
    const [periodInterval, setPeriodInterval] = useState(null)

    useEffect(() => {
        setBlockProjection(projections.find(projection => projection.blockId === blockId))
    }, [blockId, projectProjection])

    if (!blockProjection || !viewsComponents.hasOwnProperty(blockProjection.blockTypeId)) {
        return <Redirect to={`/my-projects/${projectId}/analytics`} />
    }

    const ViewComponent = viewsComponents[blockProjection.blockTypeId]
    const viewTitle = viewsTitle[blockProjection.blockTypeId]

    return (
        <InteractiveBlockWrapper
            title={viewTitle}
            setDateRange={setDateRange}
            setPeriodInterval={setPeriodInterval}
            projectId={projectProjection.projectId}
            authorId={projectProjection.authorId}
            blockId={blockProjection.blockId}
            blockTypeId={blockProjection.blockTypeId}
        >
            <ViewComponent
                key={blockProjection.blockId}
                projectId={projectProjection.projectId}
                blockProjection={blockProjection}
                dateRange={dateRange}
                periodInterval={periodInterval}
            />
        </InteractiveBlockWrapper>
    )
}

export default BlocksLayout
