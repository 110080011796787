import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import LinearProgress from '@mui/material/LinearProgress'
import { TipBulb } from 'svg/index.js'

import { mapQuizStatistic } from '../../../../utils'

import styles from './QuizStatistics.module.scss'

const QuizStatistics = ({ statistic, blockProjection }) => {
    const { t } = useTranslation()
    const [_statistic, _setStatistic] = useState(null)

    useEffect(() => {
        const mappedStat = mapQuizStatistic(statistic, blockProjection)
        _setStatistic(mappedStat)
    }, [statistic, blockProjection])

    return (
        <div className={styles.quizStatistics}>
            <div className={styles.quizStatisticsWrapper}>
                <div className={styles.quizStatisticsHeader}>
                    <div>
                        <h2 className={styles.quizStatisticsTitle}>{t('Quiz results')}</h2>
                        <p className={styles.quizStatisticsQuizHeader}>{blockProjection.blockHeader}</p>
                    </div>
                    {!blockProjection.isShowLeadForm && (
                        <div className={styles.quizStatisticsTip}>
                            <TipBulb />
                            <p>{t('Add a lead form to your quiz to collect leads and see the results of each user')}</p>
                        </div>
                    )}
                </div>
                <div className={styles.quizStatisticsBlock}>
                    <div className={classNames(styles.quizStatisticsBlockWrapper, styles.quizStatisticsBlockHeader)}>
                        <p className={styles.quizStatisticsBlockTitle}>{t('Results')}</p>
                        <div className={styles.quizStatisticsBlockValues}>{t('Users')}</div>
                    </div>
                    <ul className={styles.quizStatisticsBlockList}>
                        {_statistic?.results.map(resultStat => (
                            <li key={resultStat.id} className={styles.quizStatisticsBlockItem}>
                                <div className={styles.quizStatisticsBlockWrapper}>
                                    <p
                                        className={styles.quizStatisticsBlockTitle}
                                        title={`${resultStat.header}${resultStat.description ? ' | ' : ''}${
                                            resultStat.description
                                        }`}
                                    >
                                        {resultStat.header}
                                        {resultStat.description ? ` | ${resultStat.description}` : null}
                                    </p>
                                    <div className={styles.quizStatisticsBlockValues}>
                                        {resultStat.users}
                                        {!!resultStat.percent && <span>({resultStat.percent}%)</span>}
                                    </div>
                                </div>
                                <LinearProgress
                                    className={styles.quizStatisticsLinearProgress}
                                    classes={{ bar: styles.quizStatisticsLinearProgressBar }}
                                    variant="determinate"
                                    value={resultStat.percent}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className={styles.quizStatisticsWrapper}>
                <h2 className={styles.quizStatisticsTitle}>{t('Question answer statistics')}</h2>
                {_statistic?.questions
                    .filter(item => !!item.answered)
                    .map((questionStat, index) => (
                        <div key={questionStat.id} className={styles.quizStatisticsBlock}>
                            <div
                                className={classNames(
                                    styles.quizStatisticsBlockWrapper,
                                    styles.quizStatisticsBlockHeader,
                                )}
                            >
                                <p className={styles.quizStatisticsBlockTitle}>
                                    {t('Question')} {index + 1}. {questionStat.text}
                                </p>
                                <div className={styles.quizStatisticsBlockValues}>{t('Users')}</div>
                            </div>
                            <ul className={styles.quizStatisticsBlockList}>
                                {questionStat?.answers.map(answerStat => (
                                    <li key={answerStat.id} className={styles.quizStatisticsBlockItem}>
                                        <div className={styles.quizStatisticsBlockWrapper}>
                                            <p
                                                className={classNames(styles.quizStatisticsBlockTitle, {
                                                    [styles.quizStatisticsBlockTitleCorrect]: answerStat.isCorrect,
                                                })}
                                            >
                                                {answerStat.text}
                                            </p>
                                            <div className={styles.quizStatisticsBlockValues}>
                                                {answerStat.users}
                                                {!!answerStat.percent && <span>({answerStat.percent}%)</span>}
                                            </div>
                                        </div>
                                        <LinearProgress
                                            className={styles.quizStatisticsLinearProgress}
                                            classes={{ bar: styles.quizStatisticsLinearProgressBar }}
                                            variant="determinate"
                                            value={answerStat.percent}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default QuizStatistics
