import React from 'react'

import Playground from './components/Playground/Playground'

import styles from './Preview.module.scss'

const Preview = ({ struct, colorTheme, fontFamily }) => (
    <div className={styles.preview}>
        <Playground
            cards={struct.cards}
            cardType={struct.playground.cardType}
            cardProportions={struct.playground.cardProportions}
            fontFamily={fontFamily}
            colorTheme={colorTheme}
        />
    </div>
)

export default Preview
