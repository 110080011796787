import React, { useState, useMemo, useEffect } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import CardBox from './components/CardBox/CardBox'
import CardsGroup from './components/CardsGroup/CardsGroup'

import { getPlaygroundTopStyles, getPlaygroundDropzoneStyles, getPlaygroundTopItemStyles, shuffleArray } from './utils'

import styles from './Preview.module.scss'

const Preview = ({ data: { pairList = [], colorTheme, shuffleCards = true } = {} }) => {
    const [width, setWidth] = useState(0)
    const [prevShuffleCards, setPrevShuffleCards] = useState(shuffleCards)
    const [prevPairList, setPrevPairList] = useState([])
    const [actionPairs, setActionPairs] = useState([])
    const [staticPairs, setStaticPairs] = useState([])

    useEffect(() => {
        if (!isEqual(pairList, prevPairList)) {
            setPrevPairList(pairList)
            setActionPairs(shuffleArray(pairList))
            if (shuffleCards) setStaticPairs(shuffleArray(pairList))
            else setStaticPairs(pairList)
        } else {
            if (prevShuffleCards !== shuffleCards) {
                setPrevShuffleCards(shuffleCards)
                if (shuffleCards) setStaticPairs(shuffleArray(pairList))
                else setStaticPairs(pairList)
            }
        }
    }, [pairList, shuffleCards])

    const onResizeContainer = _width => setWidth(_width)

    const renderedRows = useMemo(
        () => (
            <div className={classNames(styles.playground, { [styles.playgroundMobile]: width < 516 })}>
                <ul
                    className={classNames(styles.playgroundGroup, {
                        [styles.playgroundActionGroupMobile]: width < 516,
                    })}
                    style={getPlaygroundTopStyles(pairList.length)}
                >
                    {actionPairs.map(item => (
                        <li
                            key={item.id}
                            className={classNames({ [styles.playgroundActionGroupItemMobile]: width < 516 })}
                            style={getPlaygroundTopItemStyles(pairList.length)}
                        >
                            <CardBox
                                colorTheme={colorTheme}
                                width={width}
                                pairsCount={pairList.length}
                                cardType={item.firstImage.cardType}
                                imageSrc={item.firstImage.src}
                                text={item.firstImage.text}
                                bgColor={item.firstImage.bgColor}
                                color={item.firstImage.color}
                            />
                        </li>
                    ))}
                </ul>
                <div
                    className={classNames(styles.playgroundGroup, {
                        [styles.playgroundStaticGroupMobile]: width < 516,
                    })}
                    style={getPlaygroundDropzoneStyles(pairList.length)}
                >
                    {staticPairs.map(item => (
                        <CardsGroup key={item.id} pairsCount={pairList.length} width={width}>
                            <CardBox
                                colorTheme={colorTheme}
                                isStatic
                                width={width}
                                pairsCount={pairList.length}
                                cardType={item.secondImage.cardType}
                                imageSrc={item.secondImage.src}
                                text={item.secondImage.text}
                                bgColor={item.secondImage.bgColor}
                                color={item.secondImage.color}
                            />
                        </CardsGroup>
                    ))}
                </div>
            </div>
        ),
        [width, actionPairs, staticPairs, colorTheme],
    )

    return (
        <ReactResizeDetector refreshMode="debounce" refreshRate={0} handleWidth onResize={onResizeContainer}>
            <div className={styles.preview}>
                <div className={styles.rowsWrapper}>{renderedRows}</div>
            </div>
        </ReactResizeDetector>
    )
}

export default Preview
