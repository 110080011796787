import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Toast from 'components/Toast/Toast'

import { createCollector, createValidator, isPasswordRules } from 'shared/validation'

import useRouter from 'hooks/useRouter'

import { API__AUTH } from 'api'

import Body from './components/Body/Body'
import Inputs from './components/Inputs/Inputs'
import Button from './components/Button/Button'
import TextControl from './components/Inputs/components/Controls/Text/Text'

import useAction from '../hooks/useAction'
import { ACTIONS } from '../constants'

import styles from './styles.module.scss'

const AppSumoCompleteRegistration = () => {
    const { t } = useTranslation()
    const { onChangeAction } = useAction()
    const {
        queryParams: { login, activation_code },
    } = useRouter()

    const [isLoading, setIsLoading] = useState(false)

    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (!login || !activation_code) onChangeAction(ACTIONS.AUTHENTICATION)
    }, [])

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            const fields = {
                password,
            }
            const validationRules = {
                password: isPasswordRules,
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            await API__AUTH.APP_SUMO_COMPLETE_REGISTRATION({
                login,
                activationCode: activation_code,
                password,
            })

            await API__AUTH.LOGIN({
                grant_type: 'password',
                email: login,
                password,
            })
        } catch (err) {
            console.error(err)
            if (err.response) {
                const {
                    response: { status, data },
                } = err

                if (status === 404) {
                    if (data.kind.code === 3) {
                        if (data.message === 'App Sumo user not found') {
                            setErrors(prevState => ({
                                ...prevState,
                                password: [t('User not found')],
                            }))
                            return
                        }
                    }
                }
                if (status === 422) {
                    if (data.kind.code === 23) {
                        if (data.message === 'Request validation failed') {
                            setErrors(prevState => ({
                                ...prevState,
                                password: [t('Password is not valid')],
                            }))
                            return
                        }
                    }
                }
            }
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>{t('Complete registration')} | Interacty</title>
            </Helmet>
            <Body
                isShowSocial={false}
                authClassName={styles.formTopOffset}
                title={t('Welcome, {{name}}!', { name: 'Sumo-ling' })}
                description={t('Set your password to complete registration')}
                onSubmit={() => onSubmit()}
            >
                <Inputs>
                    <TextControl
                        label={t('Email address')}
                        value={login}
                        isDisabled
                        errorMessages={errors.email || []}
                    />
                    <TextControl
                        label={t('Password')}
                        value={password}
                        isSecure={true}
                        onUpdate={v => {
                            setPassword(v)
                            setErrors({ ...errors, password: [] })
                        }}
                        errorMessages={errors.password || []}
                    />
                </Inputs>
                <Button>
                    <button
                        data-test="signin-submit"
                        className="button"
                        data-size="lg"
                        data-variant="primary"
                        disabled={isLoading}
                    >
                        {t('Submit')}
                    </button>
                </Button>
            </Body>
        </>
    )
}

export default AppSumoCompleteRegistration
