import React from 'react'

import styles from './ComponentsList.module.scss'

const ComponentsList = ({ children }) => {
    if (!children) return null
    const components = Array.isArray(children) ? children.filter(component => !!component) : [children]

    return (
        <ul>
            {components.map((component, i) => (
                <li key={i} className={styles.component}>
                    {component}
                </li>
            ))}
        </ul>
    )
}

export default ComponentsList
