import i18n from '../../i18n'

import getBlocksFromStructure from '../../utils/getBlocksFromStructure'

import BLOCK from '../../common/constants/BlockTypes/BlocksEnum'
import { isBlockArticleType } from 'common/utils/isBlockArticleType'
import { BLOCK_DICTIONARY } from '../Editor/Tabs/Editor/schema'

const SUPPORTED_BLOCKS_IDS = [
    BLOCK.quiz,
    BLOCK.memoryCards,
    BLOCK.findPair,
    BLOCK.hiddenObjects,
    BLOCK.leadForm,
    BLOCK.personalityQuiz,
    BLOCK.puzzle,
    BLOCK.treasureHunt,
    BLOCK.cookies,
    BLOCK.horoscope,
    BLOCK.matching,
    BLOCK.wheelSpin,
    BLOCK.crossword,
    BLOCK.slidingPuzzle,
    BLOCK.rankBattle,
]

function createMenuItem(path, label, blockTypeId = null) {
    const result = {
        path,
        label,
    }

    if (blockTypeId) result.blockTypeId = blockTypeId

    return result
}

const getBlockLabel = (blocks, block, totalLength) => {
    if (totalLength < 2) return BLOCK_DICTIONARY[block.blockTypeId].label

    const count = blocks.filter(item => item.blockTypeId === block.blockTypeId).length

    return `${BLOCK_DICTIONARY[block.blockTypeId].label} ${count + 1}`
}

export function getMenuItemsByProject(baseUrl, { projectType, projectStructureJson }) {
    const result = {
        common: [],
        blocks: [],
    }

    result.common.push(
        createMenuItem(baseUrl, i18n.t('Dashboard')),
        createMenuItem(`${baseUrl}/geography`, i18n.t('Geography')),
    )

    if (!isBlockArticleType(projectType.projectType)) return result

    const allBlocks = getBlocksFromStructure(projectStructureJson)
    allBlocks.forEach(block => {
        if (SUPPORTED_BLOCKS_IDS.includes(block.t)) {
            const sameTypeBlocks = allBlocks.filter(item => item.t === block.t)
            result.blocks.push(
                createMenuItem(
                    `${baseUrl}/blocks/${block.id}`,
                    getBlockLabel(result.blocks, block, sameTypeBlocks.length),
                    block.t,
                ),
            )
        }
    })

    return result
}

export function getMenuItemsByProjectProjection(baseUrl, projectProjection) {
    const result = {
        common: [],
        blocks: [],
    }

    result.common.push(
        createMenuItem(baseUrl, i18n.t('Dashboard')),
        createMenuItem(`${baseUrl}/geography`, i18n.t('Geography')),
    )

    projectProjection.forEach(block => {
        if (SUPPORTED_BLOCKS_IDS.includes(block.blockTypeId)) {
            const sameTypeBlocks = projectProjection.filter(item => item.blockTypeId === block.blockTypeId)
            result.blocks.push(
                createMenuItem(
                    `${baseUrl}/blocks/${block.blockId}`,
                    getBlockLabel(result.blocks, block, sameTypeBlocks.length),
                    block.blockTypeId,
                ),
            )
        }
    })

    return result
}
