import React, { useState } from 'react'
import { cloneDeep, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { clearObjectErrors } from 'shared/validation'

import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import Modal from 'components/Modal/Modal'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import CardsView from './views/CardsView'

import { Card, collectCardsErrors, isValidCardsBlock } from './cardService'

import './CardsModal.scss'

const CardsModal = ({ data, methods }) => {
    const { t } = useTranslation()

    const [tmpStructure, setTmpStructure] = useState(() => cloneDeep(data.structure))
    const [cardsErrors, setCardsErrors] = useState(new Map())

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const onSave = async () => {
        const isValid = isValidCardsBlock(tmpStructure)
        if (isValid) {
            await methods.save({ [data.id]: { data: { struct: tmpStructure } } })
            methods.closeModal()
        } else {
            const cardsErrors = collectCardsErrors(tmpStructure)
            setCardsErrors(cardsErrors.cards)
            setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
        }
    }

    const onClose = () => {
        methods.closeModal()
    }

    const onAddCard = () => {
        const card = new Card({})
        setTmpStructure(prevState => ({
            ...prevState,
            cards: [...prevState.cards, card],
        }))
    }
    const onCloneCard = cardId => {
        const sourceCard = tmpStructure.cards.find(card => card.id === cardId)
        const clonedCard = new Card(sourceCard)
        setTmpStructure(prevState => ({
            ...prevState,
            cards: [...prevState.cards, clonedCard],
        }))
    }
    const onUpdateCard = (itemId, newFields) => {
        const _cardsErrors = cloneDeep(cardsErrors)
        const cardErrors = _cardsErrors.get(itemId)
        if (cardErrors) {
            clearObjectErrors(cardErrors, Object.keys(newFields))
            const cardIsValid = Object.values(cardErrors).some(val => isEmpty(val))
            if (cardIsValid) cardsErrors.delete(itemId)
        }

        setTmpStructure(prevState => ({
            ...prevState,
            cards: prevState.cards.map(item => (itemId === item.id ? { ...item, ...newFields } : item)),
        }))
        setCardsErrors(_cardsErrors)
    }
    const onRemoveCard = cardId => {
        setTmpStructure(prevState => ({
            ...prevState,
            cards: prevState.cards.filter(item => cardId !== item.id),
        }))
    }
    const onChangeCardPosition = (currentIndex, newIndex) => {
        const cards = cloneDeep(tmpStructure.cards)
        const movingCard = cards[currentIndex]
        cards[currentIndex] = cards[newIndex]
        cards[newIndex] = movingCard
        setTmpStructure(prevState => ({
            ...prevState,
            cards,
        }))
    }

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Card options')} onClose={onShowQuitAccept} />
            <Body onSave={onSave}>
                <div className="card-dialog">
                    <CardsView
                        pages={data.pages}
                        cards={tmpStructure.cards}
                        errors={cardsErrors}
                        methods={{
                            addNewCard: onAddCard,
                            cloneCard: onCloneCard,
                            updateCard: onUpdateCard,
                            removeCard: onRemoveCard,
                            changeCardPosition: onChangeCardPosition,
                        }}
                    />
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}

export default CardsModal
