import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const WIDGET_ID = 103000002303

const useFreshdesk = language => {
    const userDetails = useSelector(state => state.user_details)

    useEffect(() => {
        if (!document) return
        if (!userDetails) return
        window.fwSettings = { widget_id: WIDGET_ID, locale: language }
        if ('function' != typeof window.FreshworksWidget) {
            let n = function () {
                n.q.push(arguments)
            }
            n.q = []
            window.FreshworksWidget = n
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://euc-widget.freshworks.com/widgets/${WIDGET_ID}.js`
        script.async = true
        script.defer = true
        document.body.appendChild(script)
    }, [userDetails])
}

export default useFreshdesk
