import React from 'react'

import TextInput from 'components/Form/TextInput/TextInput.jsx'
import TextArea from 'components/Form/TextArea/TextArea.jsx'
import UploadImage from 'components/Form/UploadImage/UploadImage.jsx'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'

import AddListItemButton from '../../../../Controls/AddListItemButton/AddListItemButton.jsx'
import ListActionBar from '../../../../Controls/ListActionBar/ListActionBar.jsx'
import { Question as QuestionModel, Answer as AnswerModel } from '../../utils/models.js'

import Answer from '../Answer/Answer.jsx'

import './Question.scss'

const Question = ({
    validationResults = { answers: [] },
    question,
    qIndex,
    results,
    listLength,
    maxAnswers,
    minAnswers,
    maxQuestions,
    selectedElements,
    methods,
    t,
}) => {
    const isAllowedAddAnswer = question.answers.length < maxAnswers
    const isAllowedRemoveAnswer = question.answers.length > minAnswers

    const onRemoveAnswer = id => {
        methods.onUpdateQuestion(question.id, {
            [QuestionModel.answers]: question.answers.filter(item => id !== item.id),
        })
    }

    const onAddAnswer = () => {
        methods.onUpdateQuestion(question.id, {
            [QuestionModel.answers]: question.answers.concat(new AnswerModel()),
        })
    }
    const onUpdateAnswer = (id, newFields) => {
        methods.onUpdateQuestion(question.id, {
            [QuestionModel.answers]: question.answers.map(answer =>
                answer.id === id ? { ...answer, ...newFields } : answer,
            ),
        })
    }

    /*todo надо вынести и сделать универсальной логикой для разных блоков*/
    const onOpenLinkBar = answer => {
        methods.onOpenLinkBar({
            questionId: question.id,
            answerId: answer.id,
        })
    }

    return (
        <div className="question-personality">
            <h3 className="question-personality__title">
                <span className="question-personality__title-content">
                    {t('Question')} {qIndex + 1}
                </span>
                <ListActionBar
                    index={qIndex}
                    length={listLength}
                    maxLength={maxQuestions}
                    onChangePosition={methods.onChangeQuestionPosition}
                    onClone={() => methods.onCloneQuestion(question.id)}
                    onRemove={() => methods.onRemoveQuestion(question.id)}
                />
            </h3>
            <div className="question-personality__body">
                <div className="question-personality__content">
                    <TextArea
                        isRequired
                        label={t('Question text')}
                        value={question.text}
                        errorMessages={validationResults.text}
                        onUpdate={value => methods.onUpdateQuestion(question.id, { [QuestionModel.text]: value })}
                    />
                    <ElementsGroup>
                        <UploadImage
                            label={t('Question image')}
                            value={question.image}
                            onUpdate={value => methods.onUpdateQuestion(question.id, { [QuestionModel.image]: value })}
                        />
                        {question.image && (
                            <div className="question-personality__content-block-input">
                                <TextInput
                                    label={t('Image disclaimer')}
                                    isOptional
                                    errorMessages={validationResults.imageDisclaimer}
                                    value={question.imageDisclaimer}
                                    onUpdate={value =>
                                        methods.onUpdateQuestion(question.id, {
                                            [QuestionModel.imageDisclaimer]: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </ElementsGroup>
                </div>
                <div className="question-personality__answer-type-select">
                    <div
                        className={`question-personality__answer-type-select-item ${
                            question.isText ? 'is-active' : ''
                        }`}
                        onClick={() => methods.onUpdateQuestion(question.id, { [QuestionModel.isText]: true })}
                    >
                        <div className="question-personality__answer-type-select-item-img" data-image="text" />
                        {t('Text')}
                    </div>
                    <div
                        className={`question-personality__answer-type-select-item ${
                            !question.isText ? 'is-active' : ''
                        }`}
                        onClick={() => methods.onUpdateQuestion(question.id, { [QuestionModel.isText]: false })}
                    >
                        <div className="question-personality__answer-type-select-item-img" data-image="images" />
                        {t('Images')}
                    </div>
                </div>
                <div className="question-personality__answer-list">
                    {question.answers.map((answer, aIndex) => (
                        <Answer
                            key={answer.id}
                            answer={answer}
                            results={results}
                            aIndex={aIndex}
                            isText={question.isText}
                            isSelected={selectedElements && selectedElements.answerId === answer.id}
                            validationResults={validationResults.answers[aIndex]}
                            isAllowedRemoveAnswer={isAllowedRemoveAnswer}
                            methods={{
                                onOpenLinkBar,
                                onUpdateAnswer,
                                onRemoveAnswer,
                            }}
                            t={t}
                        />
                    ))}
                    {isAllowedAddAnswer && (
                        <AddListItemButton
                            title={t('Add answer')}
                            length={question.answers.length}
                            onClick={onAddAnswer}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Question
