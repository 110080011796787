import React from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import Card from './components/Card/Card'

import styles from './CardsList.module.scss'

const CardsList = ({
    errors,
    cardType,
    structure,
    onSetFocusToSection,
    scrollableContentNodeRef,
    onUpdateCard,
    onUpdateCardsPosition,
}) => {
    const handleSectionVisible = (inView, entry) => {
        if (inView) onSetFocusToSection(entry.target)
    }

    return (
        <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
            <ul className={styles.sectionsList}>
                {structure.map((card, index) => (
                    <Card
                        key={card.id}
                        errors={errors?.get(card.id)}
                        handleSectionVisible={handleSectionVisible}
                        cardType={cardType}
                        card={card}
                        cardsCount={structure.length}
                        index={index}
                        onUpdateCard={data => onUpdateCard(index, card.id, data)}
                        onUpdateCardsPosition={onUpdateCardsPosition}
                    />
                ))}
            </ul>
        </ScrollableBlock>
    )
}

export default CardsList
