import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import loaderImage from './i/preloader.gif'

import styles from './MainPreloader.module.scss'

const MainPreloader = () => {
    const { t } = useTranslation()
    const { loadingProcesses } = useSelector(state => state)

    if (!loadingProcesses.length) return null

    return (
        <div className={styles.mainPreloader} title={t('Loading')}>
            <img className={styles.image} src={loaderImage} alt="" />
        </div>
    )
}

export default MainPreloader
