import React from 'react'
import { useTranslation } from 'react-i18next'

import BaseModal from './BaseModal/BaseModal'

const ProjectsLimits = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <BaseModal
            title={t('You have reached the limit of projects.')}
            description={t('Upgrade your plan or delete drafts and unnecessary projects.')}
            onClose={onClose}
        />
    )
}

export default ProjectsLimits
