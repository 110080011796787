import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveLine } from '@nivo/line'

import { getDateTime, getTimeStep, FORMATS } from 'utils/dateTime'

import Tooltip from './components/Tooltip/Tooltip'

import { getGridXValues, getGridYValues, prepareTimeData, getFormat } from './utils'

const Chart = ({ data, from, to, axisLeftFormat, integer = false, tooltipYLabel }) => {
    const { t } = useTranslation()

    const [formattedData, setFormattedData] = useState([])
    const [timeStep, setTimeStep] = useState(0)
    const [gridXValues, setGridXValues] = useState([])
    const [gridYValues, setGridYValues] = useState([])

    useEffect(() => {
        const _formattedData = prepareTimeData(data, from, to)
        const _timeStep = getTimeStep(from, to) * 1000
        const _gridXValues = getGridXValues({
            timeStep: _timeStep,
            steps: Math.ceil((to - from) / _timeStep),
            from,
            to,
        })
        const _gridYValues = integer ? getGridYValues(_formattedData, integer) : void 0

        setFormattedData(_formattedData)
        setTimeStep(_timeStep)
        setGridXValues(_gridXValues)
        setGridYValues(_gridYValues)
    }, [data, from, to])

    return (
        <ResponsiveLine
            data={formattedData}
            margin={{ top: 10, right: 20, bottom: 40, left: 40 }}
            curve="monotoneX"
            enableArea={true}
            colors={'#2990fb'}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                x: 0,
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 15,
                tickValues: gridXValues,
                legendPosition: 'middle',
                format: v => getDateTime(v, getFormat(timeStep)),
            }}
            gridYValues={gridYValues}
            axisLeft={{
                x: 0,
                orient: 'left',
                tickSize: 0,
                tickPadding: 10,
                legendPosition: 'end',
                tickValues: gridYValues,
                format: axisLeftFormat,
            }}
            areaOpacity={0.3}
            animate={true}
            motionStiffness={100}
            motionDamping={21}
            pointSize={8}
            pointColor={'#2990fb'}
            pointBorderWidth={2}
            useMesh
            xFormat={v => getDateTime(v, FORMATS.date)}
            yFormat={v => axisLeftFormat(v)}
            tooltip={data => <Tooltip data={data} yLabel={tooltipYLabel} xLabel={t('Date')} />}
        />
    )
}

export default Chart
