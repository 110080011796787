import { MAIN_SERVER } from './instance'

export const GET_DOMAINS = async projectId => {
    return MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/domains`,
    })
}

export const ADD_DOMAIN = async (projectId, domain) =>
    MAIN_SERVER({
        method: 'post',
        url: `/projects/${projectId}/domains`,
        data: { name: domain },
    })

export const DELETE_DOMAIN = async (projectId, id) =>
    MAIN_SERVER({
        method: 'delete',
        url: `/projects/${projectId}/domains/${id}`,
    })
