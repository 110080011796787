import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import './Checkbox.scss'

const Checkbox = ({ label = '', value, onUpdate, isRequired, isDisabled, className, checkboxClassName }) => {
    const [_value, _setValue] = useState(value)

    useEffect(() => {
        _setValue(value)
    }, [value])

    const onChange = () => {
        if (!isDisabled) {
            _setValue(!_value)
            try {
                onUpdate(!_value)
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <div
            className={classNames('common-checkbox', {
                'common-checkbox__disabled': isDisabled,
                [className]: !!className,
            })}
        >
            <div className="common-checkbox__content">
                <div
                    className={classNames('common-checkbox__checkbox', {
                        'common-checkbox__checkbox-checked': _value,
                        [checkboxClassName]: !!checkboxClassName,
                    })}
                    onClick={() => onChange()}
                />
                {!!label && (
                    <p
                        className={classNames('common-checkbox__label', { 'common-checkbox__required': isRequired })}
                        onClick={() => onChange()}
                    >
                        {label}
                    </p>
                )}
            </div>
        </div>
    )
}

export default Checkbox
