import { VALIDATE_TYPES } from 'common/utils/validator'

import i18n from 'i18n'

import cardsIcon from 'components/Modal/components/Body/components/SideNav/i/cards.svg'
import pairs2Icon from 'components/Modal/components/Body/components/SideNav/i/pairs2.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const TABS = {
    playground: 1,
    pairs: 2,
    finalScreen: 3,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Playground'),
        icon: cardsIcon,
    },
    {
        id: 2,
        label: i18n.t('Pairs'),
        icon: pairs2Icon,
    },
    {
        id: 3,
        label: i18n.t('Final screen'),
        isHideFromMultiplayer: true,
        icon: resultsIcon,
    },
]

export const CARD_LAYOUT_TEMPLATE = [
    {
        value: '4x4',
        label: '4x4',
    },
    {
        value: '4x2',
        label: '4x2',
    },
    {
        value: '4x3',
        label: '4x3',
    },
    {
        value: '5x2',
        label: '5x2',
    },
    {
        value: '5x4',
        label: '5x4',
    },
    {
        value: '6x4',
        label: '6x4',
    },
    {
        value: '6x5',
        label: '6x5',
    },
    {
        value: '6x6',
        label: '6x6',
    },
    {
        value: '6x7',
        label: '6x7',
    },
    {
        value: '6x8',
        label: '6x8',
    },
]

export const PLAYGROUND_FIELDS = {
    cardLayout: 'cardLayout',
    cardBackImage: 'cardBackImage',
    cardProportions: 'cardProportions',
    isShowCover: 'isShowCover',
    coverHeader: 'coverHeader',
    coverButtonText: 'coverButtonText',
}

export const PAIRS_FIELDS = {
    isShowFeedback: 'isShowFeedback',
    pairList: 'pairList',
}

export const INIT_STRUCTURE = {
    playground: {
        cardLayout: null,
        cardBackImage: '',
        cardProportions: '',
        isShowCover: false,
        coverHeader: '',
        coverButtonText: '',
    },
    pairs: {
        isShowFeedback: false,
        pairList: [],
    },
    finalScreen: {
        header: '',
        description: '',
        imageSrc: '',
        imageDisclaimer: '',
    },
}

export const INIT_VALIDATE_STRUCTURE = {
    hasError: false,
    playground: {},
    pairs: {},
    finalScreen: {},
}

export const VALIDATE_RULES = {
    playground: {
        cardLayout: [VALIDATE_TYPES.required],
        cardBackImage: [VALIDATE_TYPES.required],
        cardProportions: [VALIDATE_TYPES.required],
        coverHeader: [VALIDATE_TYPES.required],
        coverButtonText: [VALIDATE_TYPES.required],
    },
    pairs: {},
    finalScreen: {
        header: [VALIDATE_TYPES.required],
    },
}

export const VALIDATE_MESSAGES = {
    [VALIDATE_TYPES.required]: 'This field is required',
}

export const CARD_BACKGROUND_COLOR = ['#FFFFFF', '#69B1FC', '#65BB5A', '#FF5656', '#FFAA2C', '#86469C', '#000000']

export const CARD_TYPES = {
    image: 'image',
    audio: 'audio',
    text: 'text',
}
