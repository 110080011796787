import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { SUPPORT_IMAGE_TYPES } from 'components/AssetManager/utils/constants'
import Toast from 'components/Toast/Toast'

import { getUint8Array } from 'utils/file'

import photoIcon from './i/photo.svg'

import styles from './ChooseImageUploadBox.module.scss'

const ChooseImageUploadBox = ({ title, onChange, isLoading }) => {
    const { t } = useTranslation()

    const uploadRef = useRef(null)

    return (
        <>
            <input
                ref={uploadRef}
                className={styles.input}
                type="file"
                onChange={async ({ target }) => {
                    if (!target.files.length) return
                    try {
                        const file = target.files[0]
                        const uint8Array = await getUint8Array(file)
                        await onChange(file.type, uint8Array)
                    } catch (err) {
                        console.error(err)
                        Toast('error', {})
                    }
                }}
                value={undefined}
                accept={Array.from(SUPPORT_IMAGE_TYPES).join(',')}
            />
            <div
                className={classNames(styles.chooseImageBox, { [styles.isDisabled]: isLoading })}
                onClick={() => uploadRef.current.click()}
                title={title || t('Change avatar')}
            >
                <img src={photoIcon} alt="image" />
            </div>
        </>
    )
}

export default ChooseImageUploadBox
