import { set } from 'lodash'

import { iterateObjByRules } from 'shared/validation'

import { partial } from 'lodash/function'

import { hasValue } from './validator'

export const getterValue = () => ({ value }) => (hasValue(value) ? value : null)

export const arrayItemRules = fieldsRules => ({ value, context }) => {
    return value.map(item => getFieldsFromObject(fieldsRules, context, item))
}

export const blocksGetter = blocksRules => ({ value, context }) => {
    return value.map(block => {
        if (blocksRules[block.t]) {
            return getFieldsFromObject(blocksRules[block.t], context, block)
        }
        return null
    })
}

export function getFieldsFromObject(fieldsRules, _context, obj, fieldName = null) {
    const newObj = {}
    for (const { context, rule: ruleValue } of iterateObjByRules(fieldsRules, obj, _context, fieldName)) {
        set(newObj, context.path, ruleValue)
    }
    return newObj
}

export function createFieldsGetter(fieldsRules) {
    return partial(getFieldsFromObject, fieldsRules, null)
}
