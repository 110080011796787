import React, { useEffect, useState } from 'react'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Toast from 'components/Toast/Toast'
import Loader from 'components/Loader/Loader'

import { API__PROJECTS } from 'api'

import Creation from './views/Creation/Creation'
import Inspection from './views/Inspection/Inspection'

import { VIEWS, VIEWS_TITLE, ACTIVE_GAME_STATUSES } from './constants'

import styles from './ManageMultiplayerGame.module.scss'

function ManageMultiplayerGame({ project: { id }, onClose }) {
    const [view, setView] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [project, setProject] = useState(null)
    const [activeGame, setActiveGame] = useState(null)

    useEffect(() => {
        API__PROJECTS.GET_PROJECT(id)
            .then(data => {
                setProject(data)
            })
            .catch(err => {
                console.error(err)
                Toast('error', {})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (!project) return
        const _activeGame = project.games.find(game => ACTIVE_GAME_STATUSES.includes(game.status))
        if (_activeGame) setActiveGame(_activeGame)
        setView(_activeGame ? VIEWS.INSPECTION : VIEWS.CREATION)
    }, [project])

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose}>
            <div className={styles.manageMultiplayerGame}>
                <Header title={VIEWS_TITLE[view]} onClose={onClose} />
                <div className={styles.modalContent}>
                    {isLoading ? (
                        <Loader className={styles.loader} />
                    ) : (
                        <>
                            {view === VIEWS.CREATION && (
                                <Creation project={project} onContinue={newProject => setProject(newProject)} />
                            )}
                            {view === VIEWS.INSPECTION && (
                                <Inspection
                                    project={project}
                                    activeGame={activeGame}
                                    onGoToCreation={() => setView(VIEWS.CREATION)}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ManageMultiplayerGame
