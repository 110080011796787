import { createCollector, createValidator } from 'shared/validation'

import { colNumberToSize, TAB_TO_FIELD_MAP, VALIDATE_RULES_FIELDS } from './constants'
import { TreasureHuntTile } from './models'

export const isValidFields = createValidator(VALIDATE_RULES_FIELDS)
export const collectFieldsErrors = createCollector(VALIDATE_RULES_FIELDS)

export const validateTabs = (tmpStructure, tabsValidationResult, activeTab) => {
    const activeTabField = TAB_TO_FIELD_MAP[activeTab] || null
    const isValid = isValidFields(tmpStructure, activeTabField)
    const errors = collectFieldsErrors(tmpStructure, activeTabField)

    return {
        tmpStructure,
        errors,
        activeTabHasError: activeTabField && !isValid,
        hasError: !isValid,
    }
}

export const treasureHuntProjectGetData = (tilesCount, colorTheme) => {
    const tilesData = []
    for (let i = 0; i < tilesCount; i++) {
        tilesData.push(new TreasureHuntTile({ color: colorTheme, quest: `Quest ${i + 1}` }))
    }
    return tilesData
}

export const getSize = colsNumber => {
    return colNumberToSize[colsNumber]
}
