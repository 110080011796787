import { createCollector, createValidator } from 'shared/validation'
import { TAB_TO_FIELD_MAP, VALIDATE_RULES_FIELDS } from './constants'

export const isValidFields = createValidator(VALIDATE_RULES_FIELDS)
export const collectFieldsErrors = createCollector(VALIDATE_RULES_FIELDS)

export const validateTabs = (tmpStructure, activeTab) => {
    let activeTabField = TAB_TO_FIELD_MAP[activeTab] || null
    const isValid = isValidFields(tmpStructure, activeTabField)
    const errors = collectFieldsErrors(tmpStructure, activeTabField)

    return {
        tmpStructure,
        errors,
        activeTabHasError: activeTabField && !isValid,
        hasError: !isValid,
    }
}
