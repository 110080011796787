import React, { useRef, useCallback, useState } from 'react'
import { useCodeMirror } from '@uiw/react-codemirror'
import { css } from '@codemirror/lang-css'
import { useTranslation } from 'react-i18next'

import { cssBeautify } from 'utils/beautify'

import Wrapper from '../components/Wrapper/Wrapper'

const extensions = [css()]

const CSS = ({ value, tooltipText, schema, onUpdate, id, name, payload }) => {
    const { t } = useTranslation()
    const editor = useRef()
    const [_value, setValue] = useState(() => cssBeautify(value))
    const { setContainer } = useCodeMirror({
        container: editor.current,
        extensions,
        value: _value,
        minHeight: '200px',
        onChange: v => setValue(v),
    })

    const onSave = () => {
        onUpdate(id, { [name]: cssBeautify(_value) }, payload)
    }
    const onCancel = () => {
        setValue(value)
    }

    const onRefChange = useCallback(
        node => {
            if (node) {
                setValue(cssBeautify(value))
                setContainer(node)
            }
        },
        [value],
    )

    return (
        <Wrapper
            schema={schema}
            tooltipText={tooltipText}
            onSave={onSave}
            onCancel={onCancel}
            isCanSave
            text={value}
            fallbackText={t("Use styles to fine-tune your project's design")}
        >
            <div ref={onRefChange} />
        </Wrapper>
    )
}

export default CSS
