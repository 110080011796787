import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import arrowIcon from './i/arrow.svg'

import styles from './TopBar.module.scss'

const TopBar = ({ currentStep, stepsCount, onNext, isCanGoNext = false }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.topBar}>
            <div className={styles.stepNumber}>
                {currentStep}/{stepsCount}
            </div>
            <ul className={styles.stepLines}>
                {Array(stepsCount)
                    .fill('')
                    .map((_, index) => (
                        <li
                            key={index}
                            className={classNames(styles.stepLine, {
                                [styles.isActiveStepLine]: index + 1 <= currentStep,
                            })}
                        ></li>
                    ))}
            </ul>
            <button
                className="button"
                data-variant="primary"
                disabled={!isCanGoNext}
                data-size="md"
                onClick={() => onNext()}
            >
                {currentStep === stepsCount ? t('Let’s start!') : t('Next')}
                <img className={styles.buttonArrow} src={arrowIcon} alt="arrow" />
            </button>
        </div>
    )
}

export default TopBar
