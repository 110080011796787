import React from 'react'
import classNames from 'classnames'

import Social from './components/Social/Social'

import styles from './Body.module.scss'

const Body = ({
    onSubmit = () => {},
    image,
    title,
    description,
    isForm = true,
    isShowSocial = true,
    children,
    authClassName,
}) => (
    <div className={styles.body}>
        {!!image && (
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>
        )}
        {!!title && <h2 className={styles.title}>{title}</h2>}
        {!!description && <h3 className={styles.description}>{description}</h3>}
        {isForm ? (
            <div className={classNames(styles.auth, { [authClassName]: !!authClassName })}>
                {isShowSocial && <Social />}
                <form
                    className={styles.form}
                    onSubmit={evt => {
                        evt.preventDefault()
                        onSubmit()
                    }}
                >
                    {children}
                </form>
            </div>
        ) : (
            children
        )}
    </div>
)

export default Body
