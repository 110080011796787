import React, { useEffect, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import { decodeStringsInObject } from 'common/utils/objectStringsAction'

import { API__TEMPLATES } from 'api'

import imagePreloader from './i/preloader.gif'

import { REQUEST_PARAMS } from './constants'

import './TemplatesPanel.scss'

const TemplatesPanel = ({
    blockType,
    title = null,
    onAdd = () => {
        /**/
    },
}) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [templatesState, setTemplatesState] = useState({
        lastResponse: null,
        list: [],
    })

    useEffect(() => {
        setIsLoading(true)
        setTemplatesState({
            lastResponse: null,
            list: [],
        })
        API__TEMPLATES.GET_BLOCK_TEMPLATES(blockType, REQUEST_PARAMS)
            .then(response => {
                setTemplatesState({
                    lastResponse: response,
                    list: response.content,
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [blockType])

    const loadMore = async () => {
        const { lastResponse } = templatesState
        setIsLoading(true)
        API__TEMPLATES.GET_BLOCK_TEMPLATES(blockType, {
            ...REQUEST_PARAMS,
            page: lastResponse.number + 1,
        })
            .then(response => {
                setTemplatesState(prevState => ({
                    ...prevState,
                    lastResponse: response,
                    list: [...prevState.list, ...response.content],
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const canLoadMore = !templatesState.lastResponse?.last

    return (
        <div className="templates">
            <ScrollableBlock>
                <div className="templates__content">
                    {!!title && <p className="title">{title}</p>}
                    {templatesState.list.length ? (
                        <>
                            <ul className="templates__list">
                                {templatesState.list.map(template => (
                                    <li
                                        key={template.id}
                                        onClick={() => onAdd(decodeStringsInObject(JSON.parse(template.structureJson)))}
                                    >
                                        <div className="preview">
                                            <img src={template.imageUrl} alt="image" />
                                        </div>
                                        <div className="label">
                                            <span className="name">{template.name}</span>
                                            {!!template.description && (
                                                <span className="description">{template.description}</span>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {canLoadMore && !isLoading && (
                                <VisibilitySensor onChange={isVisible => isVisible && loadMore()}>
                                    <div className="visibility-checker" />
                                </VisibilitySensor>
                            )}
                        </>
                    ) : (
                        !isLoading && <div className="empty">{t('Templates not found')}</div>
                    )}
                    {isLoading && (
                        <div className="loader">
                            <img src={imagePreloader} alt="" />
                        </div>
                    )}
                </div>
            </ScrollableBlock>
        </div>
    )
}

export default TemplatesPanel
