import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import girlWithLetterImage from './i/girlWithLetter.svg'

import './InvitationSentByEmailModal.scss'

const InvitationSentByEmailModal = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={true} closeOnEsc={true} onClose={onClose}>
            <div className="invitation-sent-by-email-modal">
                <div className="invitation-sent-by-email-modal__image">
                    <img src={girlWithLetterImage} alt="" />
                </div>
                <div className="invitation-sent-by-email-modal__title">
                    {t('The invitation has been sent by email.')}
                </div>
                <div className="invitation-sent-by-email-modal__description">
                    {t(
                        "If the user doesn't see the email, make sure it's not in the spam folder and the address is correct.",
                    )}
                </div>
                <div className="invitation-sent-by-email-modal__button">
                    <button
                        className="manage-team-modal__invite-block-button button"
                        data-variant="primary"
                        data-size="lg"
                        onClick={onClose}
                    >
                        {t('Ok')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default InvitationSentByEmailModal
