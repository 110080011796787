import React, { useState } from 'react'

import GeoData from '../../components/GeoData/GeoData'
import DateRangePanel from '../../components/DateRangePanel/DateRangePanel'
import ComponentsList from '../../components/ComponentsList/ComponentsList'

const GeographyLayout = ({ projectId }) => {
    const [dateRange, setDateRange] = useState(null)

    return (
        <>
            <DateRangePanel onChangeRange={v => setDateRange(v)} />
            {dateRange && (
                <ComponentsList>
                    <GeoData projectId={projectId} dateRange={dateRange} />
                </ComponentsList>
            )}
        </>
    )
}

export default GeographyLayout
