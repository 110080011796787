import React from 'react'
import { useTranslation } from 'react-i18next'

import TextInput from 'components/Form/TextInput/TextInput'

import { KEYBOARD_KEYS } from 'common/constants/keyboard'

import { Item as ItemModel } from '../../../../crosswordService'
import { MIN_ITEMS_COUNT, MAX_ANSWER_LENGTH } from '../../../../constants'

import removeIcon from '../../../../../i/remove.svg'

import { isValidKeyCode } from './utils'

import styles from './Item.module.scss'

const Item = ({ index, item, errors, onUpdate, onRemove, itemsCount }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.item} id={`item_${item.id}`}>
            <div className={styles.answerContainer}>
                <TextInput
                    label={`${t('Word')} ${index + 1}`}
                    onKeyDown={evt => {
                        const { keyCode, key } = evt
                        if (!isValidKeyCode(keyCode, key)) evt.preventDefault()
                    }}
                    labelClassName={styles.answerLabel}
                    errorMessages={errors?.[ItemModel.answer]}
                    value={item[ItemModel.answer]}
                    maxLength={MAX_ANSWER_LENGTH}
                    onPaste={evt => {
                        evt.preventDefault()
                        let result = item[ItemModel.answer]
                        const clipboardText = evt.clipboardData.getData('Text')
                        clipboardText.split('').forEach(char => {
                            if (char.toLowerCase() !== char.toUpperCase() || char === KEYBOARD_KEYS.DASH) result += char
                        })
                        if (result.length > MAX_ANSWER_LENGTH) result = result.slice(0, MAX_ANSWER_LENGTH)
                        onUpdate({ [ItemModel.answer]: result })
                    }}
                    onUpdate={v => {
                        if (v.endsWith('. ')) return
                        onUpdate({ [ItemModel.answer]: v })
                    }}
                />
            </div>
            <div className={styles.questionContainer}>
                <TextInput
                    label={t('Clue')}
                    errorMessages={errors?.[ItemModel.question]}
                    value={item[ItemModel.question]}
                    onUpdate={v => onUpdate({ [ItemModel.question]: v })}
                />
            </div>
            {itemsCount > MIN_ITEMS_COUNT && (
                <div className={styles.removeContainer} onClick={onRemove} title={t('Remove')}>
                    <img src={removeIcon} alt="remove" />
                </div>
            )}
        </div>
    )
}

export default Item
