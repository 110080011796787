import React from 'react'

import Playground from './components/Playground/Playground'

import styles from './Preview.module.scss'

const Preview = ({
    tilesCount,
    gameType,
    imageUrl,
    colorTheme,
    correctColor,
    isHighlightCorrect,
    isShowImageNumbers,
    fontFamily,
    isShowCover,
    coverHeader,
    coverButtonText,
    isModal = false,
}) => (
    <div className={styles.preview}>
        <Playground
            isModal={isModal}
            tilesCount={tilesCount}
            colorTheme={colorTheme}
            correctColor={correctColor}
            isHighlightCorrect={isHighlightCorrect}
            gameType={gameType}
            isShowImageNumbers={isShowImageNumbers}
            imageUrl={imageUrl}
            fontFamily={fontFamily}
            isShowCover={isShowCover}
            coverHeader={coverHeader}
            coverButtonText={coverButtonText}
        />
    </div>
)

export default Preview
