import React from 'react'

import Select, { VARIANT } from 'components/Form/Select/Select'

import styles from './DateSelect.module.scss'

const DateSelect = ({ options = [], value = null, onChange }) => (
    <div className={styles.dateSelect}>
        <Select value={value} variant={VARIANT.increased} onUpdate={v => onChange(v)} options={options} />
    </div>
)

export default DateSelect
