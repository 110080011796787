import React from 'react'
import Image from 'components/Form/Image'
import './StartScreen.scss'

function StartScreen({ struct, backgroundColor }) {
    return (
        <div className="start-screen-preview">
            {struct.cover.image ? <Image className="image" src={struct.cover.image} /> : null}
            <div className={`box${!struct.cover.image ? ' no-image' : ''}`}>
                <div className="head">{struct.cover.header}</div>
                {struct.cover.description.length ? <div className="description">{struct.cover.description}</div> : null}
                <div className="btn-wrap">
                    <div className="btn" style={{ backgroundColor: backgroundColor }}>
                        {struct.cover.buttonText}
                    </div>
                </div>
                {struct.cover.imageDisclaimer.length ? (
                    <div className="image-disclaimer">{struct.cover.imageDisclaimer}</div>
                ) : null}
            </div>
        </div>
    )
}

export default StartScreen
