import React from 'react'

import styles from './SpinButton.module.scss'

const SpinButton = ({ imageUrl, size }) => (
    <div
        className={styles.spinButton}
        style={{
            backgroundImage: `url(${imageUrl})`,
            width: size,
            height: size,
            left: `calc(50% - ${size / 2}px)`,
            top: `calc(50% - ${size / 2}px)`,
        }}
    />
)

export default SpinButton
