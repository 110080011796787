import React from 'react'
import { useTranslation } from 'react-i18next'

import girlIcon from './i/girl.svg'

import styles from './NoFollowing.module.scss'

const NoFollowing = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.noFollowers}>
            <img src={girlIcon} alt="girl" />
            <div className={styles.text}>{t("You don't follow anyone")}</div>
        </div>
    )
}

export default NoFollowing
