import React from 'react'

import ActionSwitcher from './components/ActionSwitcher/ActionSwitcher'

import styles from './Content.module.scss'

const Content = ({ children }) => (
    <section className={styles.content}>
        <ActionSwitcher />
        <div className={styles.box}>{children}</div>
    </section>
)

export default Content
