export const TRIVIA_TABS = {
    questionView: 1,
}

export const TAB_TO_FIELD_MAP = {
    [TRIVIA_TABS.questionView]: 'question',
}

export const MIN_ANSWERS_IN_QUESTION = 2
export const MAX_ANSWERS_IN_QUESTION = 10
export const MIN_CORRECT_ANSWERS_IN_QUESTION = 1
export const MIN_INCORRECT_ANSWERS_IN_QUESTION = 1
