import React from 'react'
import { InView } from 'react-intersection-observer'
import AddListItemButton from '../../../Controls/AddListItemButton/AddListItemButton'
import ListActionBar from '../../../Controls/ListActionBar/ListActionBar'
import { MAX_CARDS } from '../cardService'
import CardEditor from './CardEditor'
import './CardEditorList.scss'

const CardEditorList = ({ pages, cards, cardsErrors, titleOfItem, onSetFocusedElementId, methods }) => {
    const isAllowedAdd = cards.length < MAX_CARDS

    const handleOnScrollHighlightVisibleItem = async (inView, entry) => {
        if (inView) await onSetFocusedElementId(entry.target)
    }

    return (
        <div className="card-editor-list">
            <ul className="card-editor-list__items">
                {cards.map((card, index) => {
                    return (
                        <InView
                            className="card-editor-list__item"
                            as="li"
                            key={index}
                            id={`item_${card.id}`}
                            threshold={0.1}
                            onChange={handleOnScrollHighlightVisibleItem}
                        >
                            <div className="card-editor-list__item-header">
                                <div className="card-editor-list__header-title">
                                    {titleOfItem} {index + 1}
                                </div>
                                <div className="card-editor-list__header-bar">
                                    <ListActionBar
                                        index={index}
                                        length={cards.length}
                                        maxLength={MAX_CARDS}
                                        onChangePosition={methods.changeCardPosition}
                                        onClone={() => methods.cloneCard(card.id)}
                                        onRemove={() => methods.removeCard(card.id)}
                                    />
                                </div>
                            </div>
                            <CardEditor
                                pages={pages}
                                card={card}
                                cardError={cardsErrors && cardsErrors.get(card.id)}
                                methods={methods}
                            />
                        </InView>
                    )
                })}
            </ul>
            {isAllowedAdd ? <AddListItemButton length={cards.length} onClick={methods.addNewCard} /> : null}
        </div>
    )
}

export default CardEditorList
