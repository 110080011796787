import { defined, dependOn, required } from 'shared/validation'

import i18n from 'i18n'

import playgroundIcon from 'components/Modal/components/Body/components/SideNav/i/playground.svg'
import resultsIcon from 'components/Modal/components/Body/components/SideNav/i/results.svg'

export const TABS = {
    playground: 1,
    finalScreen: 2,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Playground'),
        icon: playgroundIcon,
    },
    {
        id: 2,
        label: i18n.t('Final screen'),
        isHideFromMultiplayer: true,
        icon: resultsIcon,
    },
]

export const LAYOUT_TEMPLATE = [
    {
        value: 2,
        label: '2x2',
    },
    {
        value: 4,
        label: '4x4',
    },
    {
        value: 6,
        label: '6x6',
    },
]

export const PLAYGROUND_FIELDS = {
    layout: 'layout',
    image: 'image',
    isShowCover: 'isShowCover',
    coverHeader: 'coverHeader',
    coverButtonText: 'coverButtonText',
}

export const INIT_STRUCTURE = {
    playground: {
        layout: { value: 2, label: '2x2' },
        image: '',
        isShowCover: false,
        coverHeader: '',
        coverButtonText: '',
    },
    finalScreen: {
        header: '',
        description: '',
        imageSrc: '',
        imageDisclaimer: '',
    },
}

export const TAB_TO_FIELD_MAP = {
    [TABS.playground]: 'playground',
    [TABS.finalScreen]: 'finalScreen',
}

export const VALIDATE_RULES_FIELDS = {
    playground: {
        layout: [required()],
        image: [defined()],
        coverHeader: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
        ],
        coverButtonText: [
            dependOn(({ self }) => {
                return self.isShowCover ? required() : null
            }),
        ],
    },
    finalScreen: {
        header: [required()],
    },
}

export const DROPZONE = 'dropzone'
export const DRAGZONE = 'dragzone'

export const PLAYGROUND_PREVIEW_TABLE_DRAGZONE_CLASS = 'puzzle-block-horizontal__table'
export const PLAYGROUND_PREVIEW_TABLE_DROPZONE_CLASS = 'puzzle-block-horizontal__grid'
