import React from 'react'

import DesktopLayout from './components/DesktopLayout/DesktopLayout'
import MobileLayout from './components/MobileLayout/MobileLayout'

import imageDesktopDefault from './i/default--desktop.png'
import imageMobileDefault from './i/default--mobile.png'
import loadingPreviewDesktop from './i/loadingPreviewDesktop.svg'
import loadingPreviewMobile from './i/loadingPreviewMobile.svg'

import styles from './PreviewBlock.module.scss'

const PreviewBlock = ({ project: { previewUrl, mobilePreviewUrl }, isLoading }) => (
    <div className={styles.previewBlock}>
        <DesktopLayout imageSrc={isLoading ? loadingPreviewDesktop : previewUrl || imageDesktopDefault} />
        <MobileLayout imageSrc={isLoading ? loadingPreviewMobile : mobilePreviewUrl || imageMobileDefault} />
    </div>
)
export default PreviewBlock
