import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import rainMan from '../../../i/rainMan.jpg'

import './Content.scss'

const ForMemberOfOrgExpirationContent = ({ orgName, onClose }) => {
    const { t } = useTranslation()

    return (
        <div className="subscription-expired-modal__content">
            <img src={rainMan} alt="" />
            <div className="title">{t('Oh, no! Your organization seems to have a subscription issue.')}</div>
            <div className="text">
                <p>
                    <Trans
                        i18nKey="It looks like the owner of <b>{{orgName}}</b> organization hasn't renewed the subscription."
                        components={{ b: <b /> }}
                        values={{ orgName }}
                    />
                </p>
            </div>
            <div className="text">
                <p>
                    {t(
                        "Don't worry, as soon as your subscription is renewed, you can get back to work on your projects.",
                    )}
                </p>
            </div>
            <div className="button-container">
                <button className="ok-button" onClick={onClose}>
                    {t('All clear')}
                </button>
            </div>
        </div>
    )
}

export default ForMemberOfOrgExpirationContent
