import i18n from 'i18n'

import TriviaQuiz from './views/TriviaQuiz/TriviaQuiz'
import LeadForm from './views/LeadForm/LeadForm'
import Memory from './views/Memory/Memory'
import FindPair from './views/FindPair/FindPair'
import HiddenObjects from './views/HiddenObjects/HiddenObjects'
import PersonalityQuiz from './views/PersonalityQuiz/PersonalityQuiz'
import Puzzle from './views/Puzzle/Puzzle'
import TreasureHunt from './views/TreasureHunt/TreasureHunt'
import Cookies from './views/Cookies/Cookies'
import Horoscope from './views/Horoscope/Horoscope'
import Matching from './views/Matching/Matching'
import WheelSpin from './views/WheelSpin/WheelSpin'
import Crossword from './views/Crossword/Crossword'
import SlidingPuzzle from './views/SlidingPuzzle/SlidingPuzzle'
import RankBattle from './views/RankBattle/RankBattle'

import BLOCK from 'common/constants/BlockTypes/BlocksEnum'

export const viewsComponents = {
    [BLOCK.quiz]: TriviaQuiz,
    [BLOCK.leadForm]: LeadForm,
    [BLOCK.memoryCards]: Memory,
    [BLOCK.findPair]: FindPair,
    [BLOCK.hiddenObjects]: HiddenObjects,
    [BLOCK.personalityQuiz]: PersonalityQuiz,
    [BLOCK.puzzle]: Puzzle,
    [BLOCK.treasureHunt]: TreasureHunt,
    [BLOCK.cookies]: Cookies,
    [BLOCK.horoscope]: Horoscope,
    [BLOCK.matching]: Matching,
    [BLOCK.wheelSpin]: WheelSpin,
    [BLOCK.crossword]: Crossword,
    [BLOCK.slidingPuzzle]: SlidingPuzzle,
    [BLOCK.rankBattle]: RankBattle,
}

export const viewsTitle = {
    [BLOCK.quiz]: i18n.t('Trivia Quiz'),
    [BLOCK.leadForm]: i18n.t('Lead form'),
    [BLOCK.memoryCards]: i18n.t('Memory'),
    [BLOCK.findPair]: i18n.t('Find One Pair'),
    [BLOCK.hiddenObjects]: i18n.t('Hidden objects'),
    [BLOCK.personalityQuiz]: i18n.t('Personality Quiz'),
    [BLOCK.puzzle]: i18n.t('Puzzle'),
    [BLOCK.treasureHunt]: i18n.t('Treasure Hunt'),
    [BLOCK.cookies]: i18n.t('Cookies'),
    [BLOCK.horoscope]: i18n.t('Horoscope'),
    [BLOCK.matching]: i18n.t('Match Up'),
    [BLOCK.wheelSpin]: i18n.t('Spin the Wheel'),
    [BLOCK.crossword]: i18n.t('Crossword'),
    [BLOCK.slidingPuzzle]: i18n.t('Sliding Puzzle'),
    [BLOCK.rankBattle]: i18n.t('Rank Battle'),
}
