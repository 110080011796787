import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Button from 'components/Form/Button/Button'

import PresetItem from './components/PresetItem/PresetItem'

import styles from './TimePresetModal.module.scss'

const TimePresetModal = ({ presets, onClose, onSave }) => {
    const { t } = useTranslation()

    const [_presets, setPresets] = useState(presets)

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose}>
            <div className={styles.timePresetModal}>
                <div className={styles.title}>{t('Time preset')}</div>
                <div className={styles.description}>
                    {t(
                        'You can set the time to complete each level. After this time, the level results will be summed up, and a new level will automatically start.',
                    )}
                </div>
                <ul className={styles.presetsList}>
                    {_presets.map((value, index) => (
                        <PresetItem
                            key={index}
                            label={t('Round {{number}}', { number: index + 1 })}
                            value={value}
                            onChange={v => {
                                const newPresets = cloneDeep(_presets)
                                if (!v) newPresets[index] = ''
                                else newPresets[index] = Number(v)
                                setPresets(newPresets)
                            }}
                            units={t('sec')}
                        />
                    ))}
                </ul>
                <div className={styles.buttons}>
                    <Button
                        className={styles.cancelButton}
                        backgroundColor="transparent"
                        borderColor={'#787878'}
                        color={'#787878'}
                        onClick={() => onClose()}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(_presets)
                            onClose()
                        }}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default TimePresetModal
