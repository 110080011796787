export default class YandexMetric {
    #id

    constructor({ id }) {
        this.#id = id
    }

    initHeadScript = () => {
        const scriptElement = document.createElement('script')

        scriptElement.innerHTML = `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
           m[i].l=1*new Date();
           for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
           k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
           (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        
           ym(${this.#id}, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true
           });
        `

        document.head.insertBefore(scriptElement, document.head.childNodes[0])
    }

    initBodyNoScript = () => {
        const noScriptElement = document.createElement('noscript')

        noScriptElement.innerHTML = `<div><img src="https://mc.yandex.ru/watch/${
            this.#id
        }" style="position:absolute; left:-9999px;" alt="" /></div>`

        document.body.insertBefore(noScriptElement, document.body.childNodes[0])
    }

    // Public methods
    init = () => {
        try {
            this.initHeadScript()
            this.initBodyNoScript()
            console.log('"Yandex Metric" initialization')
        } catch (err) {
            console.error(err)
        }
    }

    sendGoal = (targetName, payload = {}) => {
        if (!window.ym || !targetName) return
        window.ym(this.#id, 'reachGoal', targetName, payload)
    }
}
