export const ADD_LOADING_PROCESS = processId => {
    return {
        type: 'ADD_LOADING_PROCESS',
        payload: processId,
    }
}
export const REMOVE_LOADING_PROCESS = processId => {
    return {
        type: 'REMOVE_LOADING_PROCESS',
        payload: processId,
    }
}
