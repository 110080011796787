import React from 'react'
import classNames from 'classnames'

import styles from './NoData.module.scss'

const NoData = ({ image, text, className }) => (
    <div className={classNames(styles.noData, { [className]: !!className })}>
        <div className={styles.imageBox}>
            <img src={image} alt="" />
        </div>
        <p className={styles.text}>{text}</p>
    </div>
)

export default NoData
