import React from 'react'
import {cloneDeep, isEqual, set} from 'lodash'
import { withTranslation } from 'react-i18next'

import VideoTips from 'components/Tips/VideoTips'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { MailChimpProvider } from 'components/MailChimp/MailChimpContext'
import { WebhooksProvider } from 'components/Webhooks/WebhooksContext'
import ProviderComposer, { provider } from 'components/ProviderComposer/ProviderComposer'
import Toast from 'components/Toast/Toast'

import pagesService from 'common/services/PagesService'
import roundsService from 'common/services/RoundsService'
import blockService from 'common/services/BlockService'

import Devtools from './components/Devtools/Devtools'

import HowTrialWorksModal from './Modals/HowTrialWorksModal/HowTrialWorksModal'
import ModalController from './Modals/ModalController'

import { BLOCK_DICTIONARY, BLOCK_SCHEMA } from './schema'
import BlocksController from './Blocks/BlocksController'
import Add from './Blocks/components/Add/Add'

import BlocksPanel from './Panels/Blocks/BlocksPanel'
import ControlPanel from './Panels/Control'
import InternalPages from './Panels/InternalPages/InternalPages'
import InternalRounds from './Panels/InternalRounds/InternalRounds'

import Normalizer from './util/normalizer'
import DataSchema from './util/schema'
import { PROJECT_DATA_TYPES, DEFAULT_ARTBOARD_BACKGROUND_COLOR } from './constants'
import { APP_SCHEMA } from './schema'

import './index.scss'
import './Blocks/Blocks.scss'
import {getMultiplayerBlockInPage} from "../../../../utils/multiplayer";

const PAGES_KEY = 'projectStructureJson.pages'
const ROUNDS_KEY = 'projectStructureJson.pages'

class Editor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            selectedPage: this.props.projectStructureJson.pages[0],
            selectedBlock: null,
            selectedBlockArrayProperty: null,

            openBlockPane: false,
            insertBeforeId: null,

            confirmationDialog: {
                isOpen: false,
                type: null, // block-remove, etc.
                payload: {},
            },

            modal: {
                name: null,
                isOpen: false,
                payload: {},
            },
        }
    }

    updateStateData = async (type, _data) => {
        const { onChangeProject } = this.props

        if (type === PROJECT_DATA_TYPES.app) {
            await onChangeProject('projectStructureJson.app', _data)
        }
    }

    onOpenBlockPane = id => {
        this.setState({
            selectedBlock: null,
            selectedBlockArrayProperty: null,
            openBlockPane: true,
            insertBeforeId: id,
        })
    }

    onInlineControlAction = async ({ action, blockId }, force = false) => {
        switch (action) {
            case 'clone': {
                await this.cloneBlock(blockId)
                break
            }
            case 'delete': {
                if (force) {
                    await this.deleteBlock(blockId)
                } else {
                    this.showConfirmationDialog('block-remove', { id: blockId })
                }
                break
            }
            case 'up': {
                this.moveBlockUp(blockId)
                break
            }
            case 'down': {
                this.moveBlockDown(blockId)
                break
            }
            default:
                break
        }
    }

    moveBlockDown = async blockId => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage } = this.state

        const { newPages, updatedPage, movedBlock } = blockService.moveDown(pages, selectedPage.id, blockId)

        await onChangeProject(PAGES_KEY, newPages)
        this.setState({
            selectedPage: updatedPage,
            selectedBlock: movedBlock,
        })
    }
    moveBlockUp = async blockId => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage } = this.state

        const { newPages, updatedPage, movedBlock } = blockService.moveUp(pages, selectedPage.id, blockId)

        await onChangeProject(PAGES_KEY, newPages)
        await this.setState({
            selectedPage: updatedPage,
            selectedBlock: movedBlock,
        })
    }
    onAddBlock = async (blockType, insertBeforeId = null, blockData = {}, blockId = null, isPreventSelect = false) => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage } = this.state

        const { newBlock, newPages, updatedPage } = blockService.addItem(
            pages,
            selectedPage.id,
            insertBeforeId,
            blockType,
            blockData,
            blockId,
        )
        await onChangeProject(PAGES_KEY, newPages)

        this.onBlockSelect(newBlock)
        this.setState({
            selectedPage: updatedPage,
            insertBeforeId: null,
            openBlockPane: false,
        })
    }
    cloneBlock = async blockId => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage } = this.state
        this.setState({ isLoading: true })
        try {
            const { newPages, updatedPage, clonedBlock } = await blockService.cloneItem(pages, selectedPage.id, blockId)
            await onChangeProject(PAGES_KEY, newPages)

            this.onBlockSelect(clonedBlock)
            this.setState({
                selectedPage: updatedPage,
                insertBeforeId: null,
            })
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deleteBlock = async blockId => {
        const {
            projectId,
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage } = this.state
        this.setState({ isLoading: false })
        try {
            const { newPages, updatedPage } = await blockService.deleteItem(pages, selectedPage.id, blockId, projectId)
            await onChangeProject(PAGES_KEY, newPages)
            await this.setState({
                selectedPage: updatedPage,
            })
            this.onBlockSelect(null)
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: true })
        }
    }

    showConfirmationDialog = (type, payload) => {
        this.setState({
            confirmationDialog: {
                isOpen: true,
                type,
                payload,
            },
        })
    }

    closeConfirmationDialog = () => {
        this.setState({
            confirmationDialog: {
                isOpen: false,
                type: null,
                payload: {},
            },
        })
    }

    openModalByName = (name, payload = {}) => {
        this.setState({
            modal: {
                name,
                isOpen: true,
                payload,
            },
        })
    }

    closeModal = () => {
        this.setState({
            modal: {
                name: null,
                isOpen: false,
                payload: null,
            },
        })
    }

    onPageSelect = page => {
        const { selectedPage } = this.state
        if (selectedPage && page && selectedPage.id === page.id) {
            return
        }
        this.setState({ selectedPage: page, selectedBlock: null, selectedBlockArrayProperty: null })
    }
    onRoundSelect = round => {
        const { selectedPage } = this.state
        if (selectedPage && round && selectedPage.id === round.id) {
            return
        }
        this.setState({ selectedPage: round, selectedBlock: null, selectedBlockArrayProperty: null })
    }

    onBlockSelect = block => {
        const { selectedBlock, selectedBlockArrayProperty } = this.state

        if (selectedBlock && block && selectedBlock.id === block.id) {
            if (selectedBlockArrayProperty) {
                this.setState({
                    selectedBlockArrayProperty: null,
                })
            }
            return
        }

        this.setState({
            selectedBlock: block,
            selectedBlockArrayProperty: null,
        })
    }

    onOverlayClick = () => {
        const { selectedBlock } = this.state
        if (selectedBlock) {
            this.setState({
                selectedBlock: null,
                selectedBlockArrayProperty: null,
            })
        }
    }

    changeData = async (target, payload) => {
        if (target !== PROJECT_DATA_TYPES.app) return
        const {
            projectStructureJson: { app },
        } = this.props

        const _app = {
            ...cloneDeep(app),
            ...payload,
        }

        const normalizedBlock = new Normalizer(new DataSchema(APP_SCHEMA)).process(_app)

        if (isEqual(app, normalizedBlock)) return

        await this.updateStateData(PROJECT_DATA_TYPES.app, _app)
    }

    changeBlocks = async payload => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props
        const { selectedPage, selectedBlock } = this.state
        const _blocks = cloneDeep(selectedPage.blocks)
        // TODO need refactor. just inline code from changeData method and remove case option by PROJECT_DATA_TYPES.blocks
        for (const [id, value] of Object.entries(payload)) {
            const blockIndex = _blocks.findIndex(b => b.id === id)
            if (blockIndex === -1) {
                return
            }
            let block = cloneDeep(_blocks[blockIndex])

            if (value.payload && value.payload.selectedBlockArrayProperty) {
                // это запрос на редактирование элемента дочернего массива блока
                const { arrName, index, schema } = value.payload.selectedBlockArrayProperty

                block[arrName][index] = new Normalizer(new DataSchema(schema)).process({
                    ...block[arrName][index],
                    ...value.data,
                })
            } else {
                block = { ...block, ...value.data }
            }

            const normalizedBlock = new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)

            if (!isEqual(_blocks[blockIndex], normalizedBlock)) {
                _blocks[blockIndex] = normalizedBlock
                const newPages = pages.map(page => (page.id === selectedPage.id ? { ...page, blocks: _blocks } : page))
                const newSelectPage = newPages.find(page => page.id === selectedPage.id)
                let newSelectedBlock = selectedBlock
                if (selectedBlock) {
                    newSelectedBlock = newSelectPage.blocks.find(_block => _block.id === selectedBlock.id)
                }
                await this.setState({
                    selectedPage: newSelectPage,
                    selectedBlock: newSelectedBlock,
                })
                await onChangeProject(PAGES_KEY, newPages)
            }
        }
    }

    addPage = async () => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const { newPages, newPage } = pagesService.addItem(pages)

        await onChangeProject(PAGES_KEY, newPages)
        this.onPageSelect(newPage)
    }
    clonePage = async pageId => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newPages, clonedPage } = await pagesService.cloneItem(pages, pageId)
            await onChangeProject(PAGES_KEY, newPages)
            this.onPageSelect(clonedPage)
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deletePage = async deletingPageId => {
        const {
            projectId,
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newPages, index } = await pagesService.deleteItem(pages, deletingPageId, projectId)
            await onChangeProject(PAGES_KEY, newPages)
            if (index === 0) this.onPageSelect(newPages[index])
            else this.onPageSelect(newPages[index - 1])
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: false })
        }
    }
    movePage = async (currentIndex, newIndex) => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const { newPages } = pagesService.moveItem(pages, currentIndex, newIndex)
        await onChangeProject(PAGES_KEY, newPages)
    }
    renamePage = async (id, name) => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const newPages = pages.map(page => (page.id !== id ? page : { ...page, name }))
        await onChangeProject(PAGES_KEY, newPages)
    }

    addRound = async () => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const { newRounds, newRound } = roundsService.addItem(pages)

        await onChangeProject(ROUNDS_KEY, newRounds)
        this.onPageSelect(newRound)
    }
    cloneRound = async pageId => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newRounds, clonedRound } = await roundsService.cloneItem(pages, pageId)
            await onChangeProject(ROUNDS_KEY, newRounds)
            this.onPageSelect(clonedRound)
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deleteRound = async deletingRoundId => {
        const {
            projectId,
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newRounds, index } = await roundsService.deleteItem(pages, deletingRoundId, projectId)
            await onChangeProject(ROUNDS_KEY, newRounds)
            this.onPageSelect(newRounds[index === 0 ? 0 : index - 1])
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: false })
        }
    }
    moveRound = async (currentIndex, newIndex) => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const { newRounds } = roundsService.moveItem(pages, currentIndex, newIndex)
        await onChangeProject(ROUNDS_KEY, newRounds)
    }
    renameRound = async (id, name) => {
        const {
            projectStructureJson: { pages },
            onChangeProject,
        } = this.props

        const newRounds = pages.map(page => (page.id !== id ? page : { ...page, name }))
        await onChangeProject(ROUNDS_KEY, newRounds)
    }

    /**
     * Запросить контролы для элемента в массиве
     * В блоке может быть массив, например массив точек в зум-карте.
     * Это возможность для каждой точки показать контролы при клике на нее
     *
     * @param {*} id идентификатор блока
     * @param {*} arrName имя свойства
     * @param {*} index
     */
    requestArrayElementControls = (id, arrName, index) => {
        const { selectedPage } = this.state

        const block = selectedPage.blocks.find(b => b.id === id),
            schema = BLOCK_SCHEMA[`${block.t}.${arrName}`]

        if (schema) {
            this.setState({
                selectedBlock: block,
                selectedBlockArrayProperty: { arrName, index, schema },
            })
        } else {
            this.setState({
                selectedBlock: null,
                selectedBlockArrayProperty: null,
            })
            console.error(`Schema not found for ${block.t}.${arrName}`)
        }
    }

    render() {
        const {
            i18n,
            projectStructureJson: { app, pages, integrations },
            isMultiplayerGame,
        } = this.props
        const {
            selectedPage,
            selectedBlock,
            selectedBlockArrayProperty,
            openBlockPane,
            insertBeforeId,
            confirmationDialog,
            modal,
        } = this.state

        const getBlockPageStyles = () => {
            if (isMultiplayerGame) {
                return {
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',
                    boxShadow: 'none',
                }
            }

            let backgroundColor = DEFAULT_ARTBOARD_BACKGROUND_COLOR,
                backgroundImage = 'none',
                backgroundSize = 'cover',
                backgroundRepeat = 'no-repeat'

            if (app.backgroundImage) backgroundImage = `url(${app.backgroundImage})`
            if (!app.isTransparentBackground) backgroundColor = app.bg

            return {
                backgroundColor,
                backgroundImage,
                backgroundSize,
                backgroundRepeat,
            }
        }

        const getMultiplayerBackgroundStyles = () => {
            if (!isMultiplayerGame) return {}

            let backgroundImage = 'none',
                backgroundColor = '#fff'
            if (app.multiplayerBackgroundImage) backgroundImage = `url(${app.multiplayerBackgroundImage})`
            if (app.colorTheme) backgroundColor = app.colorTheme

            return {
                backgroundColor,
                backgroundImage,
            }
        }

        return (
            <ProviderComposer
                providers={[
                    provider(MailChimpProvider, {
                        projectId: this.props.projectId,
                        blockId: selectedBlock && selectedBlock.id,
                    }),
                    provider(WebhooksProvider, {
                        projectId: this.props.projectId,
                        blockId: selectedBlock && selectedBlock.id,
                    }),
                ]}
            >
                <div className="artboard">
                    <div className="overlay" onClick={this.onOverlayClick} />

                    {isMultiplayerGame ? (
                        <InternalRounds
                            rounds={pages}
                            selectedRound={selectedPage}
                            onAddRound={this.addRound}
                            onCloneRound={this.cloneRound}
                            onDeleteRound={this.deleteRound}
                            onRenameRound={this.renameRound}
                            onSelectRound={this.onRoundSelect}
                            onChangePosition={this.moveRound}
                        />
                    ) : (
                        <InternalPages
                            pages={pages}
                            selectedPage={selectedPage}
                            onAddPage={this.addPage}
                            onClonePage={this.clonePage}
                            onDeletePage={this.deletePage}
                            onRenamePage={this.renamePage}
                            onSelectPage={this.onPageSelect}
                            onChangePosition={this.movePage}
                        />
                    )}
                    <VideoTips isMultiplayerGame={isMultiplayerGame} blocks={selectedPage.blocks} />

                    <BlocksPanel
                        isMultiplayerGame={isMultiplayerGame}
                        selectedPage={selectedPage}
                        open={openBlockPane}
                        onAdd={this.onAddBlock}
                        insertBeforeId={insertBeforeId}
                        onClose={() => this.setState({ openBlockPane: false })}
                    />

                    {isMultiplayerGame && (
                        <div className="multiplayer-background" style={getMultiplayerBackgroundStyles()} />
                    )}
                    {selectedPage && (
                        <div className="block_page__container">
                            <div className="block_page__overlay" onClick={this.onOverlayClick} />
                            <div className="block_page" style={getBlockPageStyles()} onClick={this.onOverlayClick}>
                                {selectedPage.blocks.map((block, index) => (
                                    <ErrorBoundary key={block.id}>
                                        <BlocksController
                                            pages={pages}
                                            isMultiplayerGame={isMultiplayerGame}
                                            selectedPage={selectedPage}
                                            block={block}
                                            appData={app}
                                            isSelected={selectedBlock && selectedBlock.id === block.id}
                                            isLast={index === selectedPage.blocks.length - 1}
                                            isFirst={index === 0}
                                            methods={{
                                                onBlockSelect: this.onBlockSelect,
                                                changeBlocks: this.changeBlocks,
                                                requestArrayElementControls: this.requestArrayElementControls,
                                                onOpenBlockPane: this.onOpenBlockPane,
                                                onInlineControlAction: this.onInlineControlAction,
                                            }}
                                        />
                                    </ErrorBoundary>
                                ))}
                                <Add
                                    onAdd={this.onAddBlock}
                                    onOpenBlockPane={this.onOpenBlockPane}
                                    isHasBlocks={!!selectedPage.blocks.length}
                                    isHasMultiplayerBlocks={!!getMultiplayerBlockInPage(selectedPage)}
                                    isMultiplayerGame={isMultiplayerGame}
                                />
                            </div>
                        </div>
                    )}

                    <ControlPanel
                        methods={{
                            openModalByName: this.openModalByName,
                        }}
                        blockName={
                            !!selectedBlock
                                ? BLOCK_DICTIONARY[selectedBlock.t].labelForRightPanel
                                : i18n.t('Project settings')
                        }
                        schema={!!selectedBlock ? BLOCK_SCHEMA[selectedBlock.t] : APP_SCHEMA}
                        blockType={selectedBlock ? selectedBlock.t : null}
                        isMultiplayerGame={isMultiplayerGame}
                        target={!!selectedBlock ? PROJECT_DATA_TYPES.blocks : PROJECT_DATA_TYPES.app}
                        totalPagesCount={pages.length}
                        blockData={!!selectedBlock ? selectedBlock : app}
                        selectedBlockArrayProperty={selectedBlockArrayProperty}
                        onUpdate={async _data => {
                            if (selectedBlock) await this.changeBlocks(_data)
                            else await this.changeData(PROJECT_DATA_TYPES.app, _data)
                        }}
                        isApp={!selectedBlock}
                        integrations={integrations}
                    />
                </div>
                {confirmationDialog.isOpen && confirmationDialog.type === 'block-remove' && (
                    <ConfirmationDialog
                        onClose={() => this.closeConfirmationDialog()}
                        onAction={() =>
                            this.onInlineControlAction(
                                {
                                    action: 'delete',
                                    blockId: confirmationDialog.payload.id,
                                },
                                true,
                            ) && this.closeConfirmationDialog()
                        }
                        data={{
                            headText: i18n.t('Are you sure you want to delete this block?'),
                            noteText: i18n.t('The content of the block will also be deleted.'),
                            actionVariant: 'primary-danger',
                            actionText: i18n.t('Delete'),
                            cancelText: i18n.t('Cancel'),
                        }}
                    />
                )}
                <Devtools
                    selectedBlockJson={selectedBlock}
                    projectJson={this.props.projectStructureJson}
                    onChangeProject={async (value, path) => {
                        const { projectStructureJson, onChangeProject } = this.props

                        const tmpProject = cloneDeep(projectStructureJson)

                        set(tmpProject, path, value)

                        tmpProject.app = new Normalizer(new DataSchema(APP_SCHEMA)).process(tmpProject.app)
                        tmpProject.pages = tmpProject.pages.map(page => ({
                            ...page,
                            blocks: page.blocks.map(block => new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)),
                        }))

                        await onChangeProject('projectStructureJson', tmpProject)

                        const { selectedPage } = this.state
                        if (selectedPage) {
                            const { pages } = this.props.projectStructureJson
                            const newPageIndex = pages.findIndex(page => page.id === selectedPage.id)
                            if (newPageIndex !== -1) this.setState({ selectedPage: pages[newPageIndex], selectedBlock: null, selectedBlockArrayProperty: null })
                            else this.setState({ selectedPage: pages[0], selectedBlock: null, selectedBlockArrayProperty: null })
                        }
                    }}
                />
                <HowTrialWorksModal />
                <ModalController
                    modal={modal}
                    data={{ app, pages }}
                    methods={{
                        closeModal: this.closeModal,
                        changeData: this.changeData,
                        changeBlocks: this.changeBlocks,
                    }}
                />
            </ProviderComposer>
        )
    }
}

export default withTranslation('translations')(Editor)
