import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'

import { isEuVersion } from 'common/constants/env'

import { getFullUrlToSsr } from 'utils/router'

import styles from './CreateProjectMenu.module.scss'

const CreateProjectMenu = ({ onCreateFromScratch }) => {
    const { t } = useTranslation()

    return (
        <DropdownMenu
            placement={'bottomRight'}
            actionHtml={
                <button
                    className="button-style create-project-link"
                    data-size="md"
                    data-variant="primary-light"
                    data-test="create-project-dropdown"
                >
                    +<span>&nbsp;{t('Create')}</span>
                </button>
            }
            menuClassName={styles.menuClassName}
            menuItemClassName={styles.menuItemClassName}
            items={[
                <div
                    className={styles.createProjectMenuItem}
                    onClick={() => onCreateFromScratch()}
                    data-test="create-project-from-scratch"
                >
                    <div
                        className={classNames(
                            styles.createProjectMenuItemImage,
                            styles.createProjectMenuItemImageScratch,
                        )}
                    />
                    <div className={styles.createProjectMenuItemTexts}>
                        <div className={styles.createProjectMenuItemTitle}>{t('Create a project from scratch')}</div>
                        <div className={styles.createProjectMenuItemDescription}>
                            {t('Start with a blank page and add text, images, games, or any other blocks.')}
                        </div>
                    </div>
                </div>,
                <a
                    className={styles.createProjectMenuItem}
                    href={getFullUrlToSsr('/template-gallery')}
                    data-test="create-project-from-template"
                >
                    <div
                        className={classNames(
                            styles.createProjectMenuItemImage,
                            styles.createProjectMenuItemImageTemplate,
                        )}
                    />
                    <div className={styles.createProjectMenuItemTexts}>
                        <div className={styles.createProjectMenuItemTitle}>{t('Choose a template')}</div>
                        <div className={styles.createProjectMenuItemDescription}>
                            {t('Select a pre-designed project and modify it as you like.')}
                        </div>
                    </div>
                </a>,
                !isEuVersion && (
                    <div
                        className={styles.createProjectMenuItem}
                        onClick={() => onCreateFromScratch(true)}
                        data-test="create-real-time-game-event"
                    >
                        <div
                            className={classNames(
                                styles.createProjectMenuItemImage,
                                styles.createProjectMenuItemImageRealTimeGame,
                            )}
                        >
                            <div className={styles.createProjectMenuItemLabel}>beta</div>
                        </div>
                        <div className={styles.createProjectMenuItemTexts}>
                            <div className={styles.createProjectMenuItemTitle}>
                                {t('Create a real-time game event')}
                            </div>
                            <div className={styles.createProjectMenuItemDescription}>
                                {t(
                                    'Create a game event with or without a host in which all players start playing simultaneously and compete.',
                                )}
                            </div>
                        </div>
                    </div>
                ),
            ]}
        />
    )
}

export default CreateProjectMenu
