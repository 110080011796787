import React from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep, set } from 'lodash'

import { MODE } from '../../../../constants'

import AddButton from '../../components/AddButton/AddButton'

import InputBox from './components/InputBox/InputBox'
import AnswersList from './components/AnswersList/AnswersList'
import Answer from './components/Answer/Answer'
import { DEFAULT_ANSWER_TEXT, MAX_ANSWERS_COUNT } from './components/Answer/constants'

import { MIN_QUESTIONS_COUNT } from './constants'

import styles from './Question.module.scss'

const Question = ({ index, data: { question, questionsCount }, onChange, onRemove, errors, mode }) => {
    const { t } = useTranslation()

    const onUpdate = props => {
        const _question = cloneDeep(question)

        for (const { path, value } of props) {
            set(_question, path, value)
        }

        onChange(_question)
    }

    return (
        <div className={styles.question}>
            <div className={styles.number}>{index + 1}.</div>
            <div className={styles.mainWrapper}>
                <InputBox
                    value={question.value}
                    onChange={v =>
                        onUpdate([
                            {
                                path: 'value',
                                value: v,
                            },
                        ])
                    }
                    isReadonly={mode === MODE.VIEW}
                    error={errors?.text}
                    isShowRemove={mode !== MODE.VIEW && questionsCount > MIN_QUESTIONS_COUNT}
                    onRemove={() => onRemove()}
                    removeTitle={t('Remove question')}
                />
                <AnswersList>
                    {question.answers.map((answer, i) => (
                        <Answer
                            isReadonly={mode === MODE.VIEW}
                            key={i}
                            index={i}
                            data={{
                                answer,
                                correctAnswersIndexes: question.correctAnswersIndexes,
                                answersCount: question.answers.length,
                            }}
                            error={errors?.answers[i]?.text}
                            onChangeText={v =>
                                onUpdate([
                                    {
                                        path: `answers[${i}]`,
                                        value: v,
                                    },
                                ])
                            }
                            onChangeCorrect={v =>
                                onUpdate([
                                    {
                                        path: 'correctAnswersIndexes',
                                        value: v,
                                    },
                                ])
                            }
                            onRemove={() => {
                                const _answers = cloneDeep(question.answers)
                                _answers.splice(i, 1)

                                const _correctAnswersIndexes = cloneDeep(question.correctAnswersIndexes)
                                    .filter(item => item !== i)
                                    .map(el => {
                                        if (el > i) return el - 1
                                        else return el
                                    })

                                onUpdate([
                                    {
                                        path: `correctAnswersIndexes`,
                                        value: _correctAnswersIndexes,
                                    },
                                    {
                                        path: 'answers',
                                        value: _answers,
                                    },
                                ])
                            }}
                        />
                    ))}
                </AnswersList>
                {mode !== MODE.VIEW && MAX_ANSWERS_COUNT > question.answers.length && (
                    <AddButton
                        text={t('Add answer')}
                        className={styles.addAnswerButton}
                        onClick={() =>
                            onUpdate([
                                {
                                    path: `answers[${question.answers.length}]`,
                                    value: `${DEFAULT_ANSWER_TEXT} ${question.answers.length + 1}`,
                                },
                            ])
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default Question
