import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'
import ReactResizeDetector from 'react-resize-detector'
import OutsideClickHandler from 'react-outside-click-handler'

import { API__AUTH } from 'api'
import { COMMON__SET_LANGUAGE, SERVICE__SET_NAV_MENU_WIDTH, SERVICE__STYLES__SET_HEADER_SIZES } from 'store/actions'
import Logo from 'svg/header_logo.svg'
import { IconTriangleBottom } from 'svg'
import languages, { findLanguageByCode } from 'i18n/languages'
import { getFullUrlToSsr } from 'utils/router'
import { isEuNorwayVersion } from 'utils/env'
import { getAvatarText } from 'common/utils/getAvatarText'
import { USER_PUBLIC_PROFILE_STATUSES } from 'common/constants/user'
import { isEducation } from 'common/utils/permissions'

import Profile from './Profile'

import { HomeIcon, PublicIcon, ChartIcon, AccountIcon, PaymentsIcon, PurchasesIcon } from './svg'
import newTabIcon from './svg/newTab.svg'

import Modal from '../ModalLegacy'

import styles from './Header.module.scss'

class Header extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props)
        this.state = {
            isActiveMenu: false,
            is_open_lng_menu: false,
            is_open_lng_menu_mobile: false,

            is_open_more_menu: false,
            more_btn_width_: {
                en: 81,
                ru: 75,
            },

            drop_menu_active_label: null,

            menu: this.getMenu(),
            menu_profile: this.getMenuProfile(),
        }

        this.ref_menu = null
        this.refs_list = []
    }

    async componentDidMount() {
        this._isMounted = true
    }

    async componentDidUpdate(prevProps) {
        const { user_details, userPublicProfile } = this.props
        if (prevProps.user_details.roleInProjectDto !== user_details.roleInProjectDto) {
            this.setState({
                menu: this.getMenu(),
            })
        }
        if (prevProps.userPublicProfile !== userPublicProfile) {
            this.setState({
                menu_profile: this.getMenuProfile(),
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getMenu = () => {
        const { t } = this.props

        if (isEuNorwayVersion()) {
            return [
                {
                    auth_required: true,
                    has_child: false,
                    to: '/my-projects',
                    label: t('My projects'),
                    width: {
                        en: 68, // real val = this + 16 (side padding fix)
                        ru: 86, // real val = this + 16 (side padding fix)
                    },
                },
                {
                    auth_required: true,
                    has_child: false,
                    isExternal: true,
                    to: getFullUrlToSsr('/template-gallery'),
                    label: t('Template gallery'),
                    width: {
                        en: 118,
                        ru: 142,
                    },
                },
                {
                    auth_required: true,
                    has_child: false,
                    to: '/billing-and-payments?tab=subscription',
                    label: t('Subscription'),
                    width: {
                        en: 90,
                        ru: 74,
                    },
                    'data-test': 'subscription-page',
                },
                {
                    auth_required: false,
                    has_child: true,
                    label: t('Resources'),
                    width: {
                        en: 161,
                        ru: 137,
                    },
                    children: [
                        {
                            isExternal: true,
                            newTab: true,
                            to: 'https://help.interacty.me',
                            label: t('Help center'),
                        },
                        {
                            isExternal: true,
                            newTab: true,
                            to: 'https://www.youtube.com/channel/UCsXVopApOxKt0b9JIkkhZog/videos',
                            label: t('Youtube channel'),
                        },
                    ],
                },
            ]
        }

        let result = [
            {
                auth_required: true,
                has_child: false,
                to: '/my-projects',
                label: t('My projects'),
                width: {
                    en: 68, // real val = this + 16 (side padding fix)
                    ru: 86, // real val = this + 16 (side padding fix)
                },
            },
            {
                auth_required: true,
                has_child: false,
                isExternal: true,
                to: getFullUrlToSsr('/template-gallery'),
                label: t('Template gallery'),
                width: {
                    en: 118,
                    ru: 142,
                },
            },
        ]
        if (isEducation()) {
            result = [
                ...result,
                {
                    auth_required: true,
                    has_child: false,
                    isExternal: true,
                    to: getFullUrlToSsr('/community'),
                    label: t('Community'),
                    width: {
                        en: 118,
                        ru: 142,
                    },
                },
            ]
        }
        result = [
            ...result,
            {
                auth_required: true,
                has_child: false,
                to: '/billing-and-payments?tab=subscription',
                label: t('Subscription'),
                width: {
                    en: 90,
                    ru: 74,
                },
                'data-test': 'subscription-page',
            },
            {
                auth_required: false,
                has_child: true,
                label: t('Resources'),
                width: {
                    en: 161,
                    ru: 137,
                },
                children: [
                    {
                        isExternal: true,
                        newTab: true,
                        to: 'https://help.interacty.me',
                        label: t('Help center'),
                    },
                    {
                        isExternal: true,
                        newTab: true,
                        to: 'https://www.youtube.com/channel/UCsXVopApOxKt0b9JIkkhZog/videos',
                        label: t('Youtube channel'),
                    },
                    {
                        isExternal: true,
                        newTab: true,
                        to: 'https://interacty.frill.co/roadmap',
                        label: t('Roadmap'),
                    },
                    {
                        isExternal: true,
                        newTab: true,
                        to: 'https://interacty.frill.co/b/y0g48n0e/feature-ideas',
                        label: t('Request feature'),
                    },
                ],
            },
            {
                auth_required: false,
                guest_only: false,
                has_child: false,
                isExternal: true,
                to: getFullUrlToSsr('/contacts'),
                label: t('Contacts'),
                width: {
                    en: 66,
                    ru: 74,
                },
            },
        ]

        return result
    }
    getMenuProfile = () => {
        const { userPublicProfile, t, user_details } = this.props
        return [
            {
                Icon: HomeIcon,
                to: '/my-projects',
                label: t('My projects'),
            },
            userPublicProfile &&
                userPublicProfile.publicStatus === USER_PUBLIC_PROFILE_STATUSES.PUBLIC && {
                    Icon: PublicIcon,
                    to: getFullUrlToSsr(`/authors/${user_details.id}`),
                    isExternal: true,
                    label: t('My public page '),
                    labelIcon: newTabIcon,
                },
            userPublicProfile &&
                userPublicProfile.publicStatus === USER_PUBLIC_PROFILE_STATUSES.PUBLIC && {
                    Icon: ChartIcon,
                    to: '/public-page-statistic',
                    label: t('Public page stats'),
                },
            isEducation() && {
                Icon: PurchasesIcon,
                to: '/my-purchases',
                label: t('My purchases'),
            },
            {
                Icon: AccountIcon,
                to: '/settings',
                label: t('Account settings'),
            },
            {
                Icon: PaymentsIcon,
                to: '/billing-and-payments',
                label: t('Billing and payments'),
            },
        ]
    }

    toggleMenu = () => {
        const { isActiveMenu } = this.state
        this.setState({ isActiveMenu: !isActiveMenu })
    }

    onResize__header = async (width, height) => {
        const {
            header_width,
            header_height,
            nav_menu_width,
            P__SERVICE__STYLES__SET_HEADER_SIZES,
            P__SERVICE__SET_NAV_MENU_WIDTH,
        } = this.props
        if (width !== header_width || header_height !== height) {
            await P__SERVICE__STYLES__SET_HEADER_SIZES({
                width,
                height,
            })
        }
        if (this.ref_menu && nav_menu_width !== this.ref_menu.offsetWidth) {
            P__SERVICE__SET_NAV_MENU_WIDTH(this.ref_menu.offsetWidth)
        }
    }

    handleClick__change_language = item => async evt => {
        evt.preventDefault()
        try {
            if (item.is_enabled) {
                const { P__COMMON__SET_LANGUAGE } = this.props
                await P__COMMON__SET_LANGUAGE({ code: item.code })
                document.location.reload()
            }
        } catch (err) {
            console.error(err)
        }
    }

    handleClick__open_lng_menu_mobile = async evt => {
        evt.preventDefault()
        await this.setState({
            is_open_lng_menu_mobile: true,
        })
    }
    handleClick__close_lng_menu_mobile = async evt => {
        evt.preventDefault()
        await this.setState({
            is_open_lng_menu_mobile: false,
        })
    }

    handleClick__toggle_more_menu = async evt => {
        evt.preventDefault()
        await this.setState(prevState => ({
            is_open_more_menu: !prevState.is_open_more_menu,
        }))
    }
    handleClick__close_more_menu = async () => {
        await this.setState({
            is_open_more_menu: false,
        })
    }

    handleClick__toggle_drop_menu = label => async evt => {
        evt.preventDefault()
        await this.setState(prevState => ({
            drop_menu_active_label: prevState.drop_menu_active_label === label ? null : label,
        }))
    }
    handleClick__close_drop_menu = (from_mobile_menu, close_burger) => async () => {
        // evt.preventDefault()
        const { isActiveMenu } = this.state

        if (from_mobile_menu) {
            await this.setState({
                drop_menu_active_label: null,
                isActiveMenu: close_burger,
            })
        } else {
            if (!isActiveMenu) {
                await this.setState({
                    drop_menu_active_label: null,
                })
            }
        }
    }

    render() {
        const { user_details, t, i18n, header_height, nav_menu_width, location } = this.props
        const { menu } = this.state
        const {
            isActiveMenu,
            is_open_lng_menu_mobile,
            is_open_more_menu,
            menu_profile,
            more_btn_width_,
            drop_menu_active_label,
        } = this.state

        const burgerClassName = `hamburger hamburger--collapse ${isActiveMenu ? '  is-active' : ''}`

        const current_lng = findLanguageByCode(i18n.language)

        const menu_formatted = () => {
            const filtered = menu.filter(
                item =>
                    (!item.auth_required && (!item.guest_only || (item.guest_only && !user_details))) ||
                    (item.auth_required && user_details),
            )

            const side_padding = 32

            const width_values_arr = filtered.map(item => {
                return item.width[current_lng.code]
            })
            let last_visible_index = 0
            let accumulated_width = width_values_arr.reduce((acc, val) => acc + val + side_padding, 0) - side_padding

            if (accumulated_width > nav_menu_width) {
                for (let i = width_values_arr.length - 1; i !== 0; i--) {
                    if (
                        accumulated_width -
                            width_values_arr[i] -
                            side_padding +
                            (more_btn_width_[current_lng.code] + 16) <=
                        nav_menu_width
                    ) {
                        last_visible_index = i - 1
                        break
                    } else {
                        accumulated_width -= width_values_arr[i] + side_padding
                    }
                }
            } else {
                last_visible_index = width_values_arr.length - 1
            }

            return filtered.map((item, index) => ({
                id: index,
                is_hidden: index > last_visible_index,
                isExternal: item.isExternal,
                newTab: item.newTab,
                has_child: item.has_child,
                to: item.has_child ? null : item.to,
                children: item.has_child ? item.children : null,
                label: item.label,
                isNew: item.isNew,
                'data-test': item['data-test'],
            }))
        }
        const is_visible_more_menu = menu_formatted().findIndex(item => item.is_hidden) !== -1

        const firstName = () => {
            let result = null
            if (user_details.firstName && user_details.firstName.length) {
                result = user_details.firstName
            }
            return result
        }
        const fullName = () => {
            let result = null
            if (user_details.firstName && user_details.firstName.length) {
                result = user_details.firstName
            }
            if (user_details.lastName && user_details.lastName.length) {
                result = `${result} ${user_details.lastName}`
            }
            return result
        }

        return (
            <header className="header" id="header">
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={0}
                    handleWidth
                    handleHeight
                    onResize={this.onResize__header}
                />
                <div
                    className={`header-inner container-fluid${is_visible_more_menu ? ' with-more-menu' : ''}${
                        isActiveMenu ? ' is-active' : ''
                    }`}
                >
                    <div className="left" id="left-side">
                        <div className="left-inner">
                            <div className="left-inner-2">
                                <div className="logo">
                                    <a href={getFullUrlToSsr()}>
                                        <img src={Logo} alt="" />
                                    </a>
                                </div>
                                <nav className="menu" ref={ref => (this.ref_menu = ref)}>
                                    <ul id="menu-list">
                                        {menu_formatted()
                                            .filter(item => !item.is_hidden)
                                            .map(item => {
                                                return (
                                                    <li
                                                        className={`${item.has_child ? 'with-dropdown' : ''}${
                                                            item.has_child && item.label === drop_menu_active_label
                                                                ? ' is-active'
                                                                : ''
                                                        }`}
                                                        ref={ref => (this.refs_list[item.id] = ref)}
                                                        key={item.id}
                                                        data-test={item['data-test']}
                                                    >
                                                        {item.isExternal ? (
                                                            <a href={item.to} target={item.newTab ? '_blank' : '_self'}>
                                                                {item.label}
                                                                {item.isNew && (
                                                                    <div className={styles.newBadge}>
                                                                        <div className={styles.newBadgeContent}>
                                                                            new
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </a>
                                                        ) : !item.has_child ? (
                                                            <NavLink
                                                                exact
                                                                activeClassName="is-active"
                                                                to={item.to}
                                                                onClick={this.handleClick__close_drop_menu(true)}
                                                            >
                                                                {item.label}
                                                            </NavLink>
                                                        ) : (
                                                            <OutsideClickHandler
                                                                onOutsideClick={this.handleClick__close_drop_menu(
                                                                    false,
                                                                )}
                                                            >
                                                                <div
                                                                    className="current"
                                                                    onClick={this.handleClick__toggle_drop_menu(
                                                                        item.label,
                                                                    )}
                                                                >
                                                                    {item.label}&nbsp;
                                                                    <IconTriangleBottom />
                                                                </div>
                                                                <ul className="list">
                                                                    {item.children.map(item => {
                                                                        return (
                                                                            <li key={item.label}>
                                                                                {item.icon ? (
                                                                                    <img src={item.icon} alt="icon" />
                                                                                ) : null}
                                                                                {item.isExternal ? (
                                                                                    <a
                                                                                        href={item.to}
                                                                                        target={
                                                                                            item.newTab
                                                                                                ? '_blank'
                                                                                                : '_self'
                                                                                        }
                                                                                    >
                                                                                        {item.label}
                                                                                    </a>
                                                                                ) : (
                                                                                    <NavLink
                                                                                        exact
                                                                                        activeClassName="is-active"
                                                                                        to={item.to}
                                                                                        onClick={this.handleClick__close_drop_menu(
                                                                                            false,
                                                                                        )}
                                                                                    >
                                                                                        {item.label}
                                                                                    </NavLink>
                                                                                )}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </OutsideClickHandler>
                                                        )}
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                    {is_visible_more_menu ? (
                                        <div className={`hide-menu${is_open_more_menu ? ' is-active' : ''}`}>
                                            <OutsideClickHandler onOutsideClick={this.handleClick__close_more_menu}>
                                                <div className="current" onClick={this.handleClick__toggle_more_menu}>
                                                    {t('More')}&nbsp;
                                                    <IconTriangleBottom />
                                                </div>
                                                <ul className="list">
                                                    {menu_formatted()
                                                        .filter(item => item.is_hidden)
                                                        .map(item => {
                                                            return (
                                                                <li key={item.id}>
                                                                    {!item.has_child ? (
                                                                        <NavLink
                                                                            exact
                                                                            activeClassName="is-active"
                                                                            to={item.to}
                                                                            onClick={this.handleClick__close_drop_menu(
                                                                                true,
                                                                            )}
                                                                        >
                                                                            {item.label}
                                                                        </NavLink>
                                                                    ) : null}
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                            </OutsideClickHandler>
                                        </div>
                                    ) : null}
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className={burgerClassName} onClick={this.toggleMenu}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner" />
                            </div>
                        </div>
                        {user_details && (
                            <Profile
                                menu={menu_profile}
                                avaText={getAvatarText(user_details)}
                                ava={user_details.avatarUrl}
                                firstName={firstName()}
                                fullName={fullName()}
                            />
                        )}
                    </div>

                    <nav
                        className={`menu-mobile${isActiveMenu ? ' is-active' : ''}`}
                        style={{ top: `${header_height}px` }}
                    >
                        {!!user_details && (
                            <>
                                <div className="account">
                                    <div
                                        className="ava"
                                        style={{
                                            backgroundImage: user_details.avatarUrl
                                                ? `url(${user_details.avatarUrl})`
                                                : 'none',
                                            backgroundColor: user_details.avatarUrl ? 'transparent' : '#2990fb',
                                        }}
                                    >
                                        {!user_details.avatarUrl && getAvatarText(user_details)}
                                    </div>
                                    <div className="info">
                                        {fullName() ? <div className="full_name">{fullName()}</div> : null}
                                        <div className="email">{user_details.email}</div>
                                    </div>
                                </div>
                                <div className="divider" />
                            </>
                        )}
                        <ul>
                            {menu_formatted().map(item => {
                                return (
                                    <li
                                        className={`${item.has_child ? 'with-dropdown' : ''}${
                                            item.has_child && item.label === drop_menu_active_label ? ' is-active' : ''
                                        }`}
                                        key={item.id}
                                    >
                                        {!item.has_child ? (
                                            item.isExternal ? (
                                                <a href={item.to} target={item.newTab ? '_blank' : '_self'}>
                                                    {item.label}
                                                    {item.isNew && (
                                                        <div className={styles.newBadge}>
                                                            <div className={styles.newBadgeContent}>new</div>
                                                        </div>
                                                    )}
                                                </a>
                                            ) : (
                                                <NavLink
                                                    exact
                                                    activeClassName="is-active"
                                                    to={item.to}
                                                    onClick={this.handleClick__close_drop_menu(true)}
                                                >
                                                    {item.label}
                                                </NavLink>
                                            )
                                        ) : (
                                            <div className="dropdown-block">
                                                <div
                                                    className="value"
                                                    onClick={this.handleClick__toggle_drop_menu(item.label)}
                                                >
                                                    {item.label}&nbsp;
                                                    <IconTriangleBottom />
                                                </div>
                                                <ul>
                                                    {item.children.map(item => {
                                                        return (
                                                            <li key={item.label}>
                                                                {item.isExternal ? (
                                                                    <a
                                                                        href={item.to}
                                                                        target={item.newTab ? '_blank' : '_self'}
                                                                    >
                                                                        {item.label}
                                                                    </a>
                                                                ) : (
                                                                    <NavLink
                                                                        exact
                                                                        activeClassName="is-active"
                                                                        to={item.to}
                                                                        onClick={this.handleClick__close_drop_menu(
                                                                            true,
                                                                        )}
                                                                    >
                                                                        {item.label}
                                                                    </NavLink>
                                                                )}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                        {user_details && (
                            <>
                                <div className="divider" />
                                <ul>
                                    {menu_profile.map((item, index) => {
                                        if (!item) return null
                                        const Icon = item.Icon
                                        return (
                                            <li className={styles.item} key={index}>
                                                {!!Icon && (
                                                    <div className={styles.icon}>
                                                        <Icon isActive={location.pathname === item.to} />
                                                    </div>
                                                )}
                                                {item.isExternal ? (
                                                    <a href={item.to} onClick={this.handleClick__close_drop_menu(true)}>
                                                        {item.label}
                                                    </a>
                                                ) : (
                                                    <NavLink
                                                        exact
                                                        activeClassName="is-active"
                                                        to={item.to}
                                                        onClick={this.handleClick__close_drop_menu(true)}
                                                    >
                                                        {item.label}
                                                    </NavLink>
                                                )}
                                                {!!item.labelIcon && (
                                                    <div className={styles.labelIcon}>
                                                        <img src={item.labelIcon} alt="" />
                                                    </div>
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className="current-lng-string">
                                    <div className="box" onClick={this.handleClick__open_lng_menu_mobile}>
                                        {current_lng && <img src={current_lng.icon} alt="" />}
                                        <p>{current_lng.label}</p>
                                    </div>
                                </div>
                                <div className="divider" />
                                <button className="logout" onClick={() => API__AUTH.LOGOUT()}>
                                    {t('Logout')}
                                </button>
                            </>
                        )}

                        <Modal
                            settings={{ overlay_close: true }}
                            classes={{ overlay: '', modal: 'langs-style', closeButton: '', closeIcon: '' }}
                            data={{ open: is_open_lng_menu_mobile, max_width: 190 }}
                            events={{ onCloseModal: this.handleClick__close_lng_menu_mobile }}
                        >
                            <ul className="langs-modal-menu">
                                {languages
                                    .filter(el => el.is_visible)
                                    .map(el => {
                                        return (
                                            <li
                                                key={el.code}
                                                className={`${i18n.language === el.code ? 'is-active ' : ''}${
                                                    el.is_enabled ? '' : 'is-disabled '
                                                }`}
                                                onClick={this.handleClick__change_language(el)}
                                            >
                                                <img src={el.icon} alt="" />
                                                <span className={`${i18n.language === el.code ? 'is-active' : ''}`}>
                                                    {el.label}
                                                </span>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </Modal>
                    </nav>
                </div>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    userPublicProfile: state.userPublicProfile,
    user_details: state.user_details,
    header_width: state._service.styles.header_width,
    header_height: state._service.styles.header_height,
    nav_menu_width: state._service.nav_menu_width,
})

const mapDispatchToProps = dispatch => ({
    P__SERVICE__STYLES__SET_HEADER_SIZES: obj => dispatch(SERVICE__STYLES__SET_HEADER_SIZES(obj)),
    P__SERVICE__SET_NAV_MENU_WIDTH: value => dispatch(SERVICE__SET_NAV_MENU_WIDTH(value)),
    P__COMMON__SET_LANGUAGE: value => dispatch(COMMON__SET_LANGUAGE(value)),
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('translations'),
    withRouter,
)(Header)
