import React from 'react'
import { Trans } from 'react-i18next'

import girlImage from './i/girl.png'
import newTabIcon from './i/newTab.svg'

import styles from './FreeOffer.module.scss'

const FreeOffer = () => (
    <div className={styles.freeOffer}>
        <div className={styles.content}>
            <img className={styles.image} src={girlImage} alt="" />
            <p className={styles.text}>
                <Trans
                    i18nKey="Want to upgrade your plan for free? <lnk>Check out our offer<icon/></lnk>"
                    components={{
                        lnk: (
                            <a
                                className={styles.link}
                                target="_blank"
                                href={
                                    'https://help.interacty.me/en/content/how-teachers-can-get-an-interacty-subscription-for-free'
                                }
                            />
                        ),
                        icon: <img className={styles.icon} src={newTabIcon} alt="" />,
                    }}
                />
            </p>
        </div>
    </div>
)

export default FreeOffer
