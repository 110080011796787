export const COMMON__SET_LANGUAGE = obj => {
    return {
        type: 'COMMON__SET_LANGUAGE',
        payload: obj,
    }
}

export const COMMON__SET_CLIENT_COUNTRY = obj => {
    return {
        type: 'COMMON__SET_CLIENT_COUNTRY',
        payload: obj,
    }
}

export const COMMON__SET_PAYMENT_CURRENCY = obj => {
    return {
        type: 'COMMON__SET_PAYMENT_CURRENCY',
        payload: obj,
    }
}
