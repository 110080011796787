import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import Tags from 'components/Form/Tags/Tags'
import Toast from 'components/Toast/Toast'

import BaseDialog from 'components/Modal/BaseDialog/BaseDialog'
import Form from 'components/Form/components/Helpers/Form/Form'
import FormInputs from 'components/Form/components/Helpers/FormInputs/FormInputs'
import FormInputBlock from 'components/Form/components/Helpers/FormInputBlock/FormInputBlock'
import FormButtons from 'components/Form/components/Helpers/FormButtons/FormButtons'
import FormTextArea from 'components/Form/components/Helpers/FormTextArea/FormTextArea'
import FormTextInput from 'components/Form/components/Helpers/FormTextInput/FormTextInput'
import FormSelect from 'components/Form/components/Helpers/FormSelect/FormSelect'

import countryDataset from 'datasets/country.json'
import languageDataset from 'datasets/language.json'

import { createCollector, createValidator, required } from 'shared/validation'

import { API__USER } from 'api'

import { getFullUrlToSsr } from 'utils/router'

import { getInitialStates } from './utils'

import FillMoreInfoModal from 'components/Modal/FillMoreInfoModal/FillMoreInfoModal'
import { FIELDS } from 'components/Modal/FillMoreInfoModal/constants'

import unpublishIcon from './i/unpublish.svg'
import arrowIcon from './i/arrow.svg'

import styles from './Public.module.scss'

const Public = ({ userDetails, publicProfile, headerLogoUrl, onSuccess, tags, isPublished }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [initialStates, setInitialStates] = useState(() => getInitialStates(publicProfile))
    useEffect(() => setInitialStates(getInitialStates(publicProfile)), [publicProfile])

    const [selectedTags, setSelectedTags] = useState(initialStates.selectedTags)

    const [title, setTitle] = useState(initialStates.title)
    const [description, setDescription] = useState(initialStates.description)
    const [country, setCountry] = useState(initialStates.country)
    const [language, setLanguage] = useState(initialStates.language)

    const [errors, setErrors] = useState({})

    const [isOpenedFillProfileModal, setIsOpenedFillProfileModal] = useState(false)
    const [isOpenedSuccessPublishModal, setIsOpenedSuccessPublishModal] = useState(false)

    const [isDisabledSave, setIsDisabledSave] = useState(false)

    useEffect(() => {
        let result = true
        if (title.trim() !== initialStates.title) result = false
        if (description.trim() !== initialStates.description) result = false
        if (country && country.value !== initialStates.country?.value) result = false
        if (language && language.value !== initialStates.language?.value) result = false
        if (!isEqual(selectedTags.sort(), initialStates.selectedTags.sort())) result = false
        setIsDisabledSave(result)
    }, [title, description, country, language, selectedTags, tags.content, initialStates])

    const onSave = async () => {
        try {
            setIsLoading(true)
            const fields = {
                title: title.trim(),
                description: description.trim(),
            }

            if (country) fields.countryCode = country.value
            if (language) fields.languageCode = language.value

            const updatedProfile = await API__USER.UPDATE_PUBLIC_PROFILE(userDetails.id, {
                ...fields,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                avatarUrl: userDetails.avatarUrl,
                email: userDetails.email,
                headerLogoUrl,
                tagIds: selectedTags,
            })

            await onSuccess(updatedProfile)

            Toast('success', {
                message: t('Your profile information is updated successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    const onUnpublish = async () => {
        try {
            const updatedProfile = await API__USER.CHANGE_PROFILE_ACTIVATION(userDetails.id, false)

            await onSuccess(updatedProfile)

            Toast('success', {
                message: t('Your public page is unpublish successfully'),
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    const onPublish = async () => {
        try {
            const fields = {
                country,
                language,
                title: title.trim(),
                description: description.trim(),
            }

            const validationRules = {
                country: [required()],
                language: [required()],
                title: [required()],
                description: [required()],
            }
            const isValid = createValidator(validationRules)(fields)
            const errors = createCollector(validationRules)(fields)

            setErrors(errors)
            if (!isValid) return

            delete fields.country
            fields.countryCode = country.value
            delete fields.language
            fields.languageCode = language.value

            if (!userDetails.firstName || !userDetails.lastName || !userDetails.avatarUrl) {
                setIsOpenedFillProfileModal(true)
                return
            }

            await API__USER.UPDATE_PUBLIC_PROFILE(userDetails.id, {
                ...fields,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                avatarUrl: userDetails.avatarUrl,
                email: userDetails.email,
                headerLogoUrl,
                tagIds: selectedTags,
            })
            const updatedProfile = await API__USER.CHANGE_PROFILE_ACTIVATION(userDetails.id, true)

            await onSuccess(updatedProfile)
            setIsOpenedSuccessPublishModal(true)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.public}>
            <Form>
                <FormInputs>
                    <FormInputBlock>
                        <ul className={styles.combinedBlocks}>
                            <li className={styles.combinedBlock}>
                                <FormSelect
                                    errorMessages={errors.country || []}
                                    label={t('Country')}
                                    value={country}
                                    onUpdate={val => {
                                        setCountry(val)
                                        setErrors({ ...errors, country: [] })
                                    }}
                                    options={countryDataset.map(item => ({
                                        value: item.code,
                                        label: item.name,
                                    }))}
                                />
                            </li>
                            <li className={styles.combinedBlock}>
                                <FormSelect
                                    errorMessages={errors.language || []}
                                    label={t('Language')}
                                    value={language}
                                    onUpdate={val => {
                                        setLanguage(val)
                                        setErrors({ ...errors, language: [] })
                                    }}
                                    options={languageDataset.map(item => ({
                                        value: item.code,
                                        label: item.label,
                                    }))}
                                />
                            </li>
                        </ul>
                    </FormInputBlock>
                    <FormInputBlock>
                        <FormTextInput
                            label={t('Where do you teach / work')}
                            value={title}
                            onUpdate={v => {
                                setTitle(v)
                                setErrors({ ...errors, title: [] })
                            }}
                            maxLength={200}
                            errorMessages={errors.title || []}
                        />
                    </FormInputBlock>
                    <FormInputBlock>
                        <FormTextArea
                            label={t('About me')}
                            value={description}
                            onUpdate={value => {
                                setDescription(value)
                                setErrors({ ...errors, description: [] })
                            }}
                            maxLength={600}
                            placeholder={t('Tell us about your teacher experience')}
                            errorMessages={errors.description || []}
                        />
                    </FormInputBlock>
                    {/*<FormInputBlock>*/}
                    {/*    <Tags*/}
                    {/*        labelClassName={styles.label}*/}
                    {/*        title={t('Select subjects that describe your content')}*/}
                    {/*        selected={selectedTags}*/}
                    {/*        maxLength={5}*/}
                    {/*        list={tags.content.map(el => ({ value: el.id, label: el.name }))}*/}
                    {/*        onSelect={tag => {*/}
                    {/*            if (selectedTags.includes(tag.value)) {*/}
                    {/*                setSelectedTags(selectedTags.filter(el => el !== tag.value))*/}
                    {/*                return*/}
                    {/*            }*/}
                    {/*            setSelectedTags([...selectedTags, tag.value])*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</FormInputBlock>*/}
                </FormInputs>
                <FormButtons className={isPublished ? styles.formButtons : styles.isPublishFormButtons}>
                    {isPublished && (
                        <div
                            className={classNames(styles.unpublishButton, {
                                [styles.isDisabledUnpublishButton]: isLoading,
                            })}
                            onClick={() => onUnpublish()}
                        >
                            <img src={unpublishIcon} alt="unpublish" />
                            {t('Unpublish')}
                        </div>
                    )}
                    <div className={styles.buttons}>
                        <button
                            className="button"
                            data-size="md"
                            data-variant="secondary"
                            disabled={isDisabledSave || isLoading}
                            onClick={() => onSave()}
                        >
                            {t('Save')}
                        </button>
                        {isPublished ? (
                            <button
                                className={classNames('button', styles.blueButton)}
                                data-size="md"
                                data-variant="primary"
                                disabled={isLoading}
                                onClick={() => window.open(getFullUrlToSsr(`/authors/${userDetails.id}`), '_blank')}
                            >
                                {t('My public page')}
                                <img src={arrowIcon} alt="arrow" />
                            </button>
                        ) : (
                            <button
                                className={classNames('button', styles.blueButton)}
                                data-size="md"
                                data-variant="primary"
                                disabled={isLoading}
                                onClick={() => onPublish()}
                            >
                                {t('Activate public page')}
                            </button>
                        )}
                    </div>
                </FormButtons>
            </Form>

            {isOpenedSuccessPublishModal && (
                <BaseDialog
                    title={t('Your profile is now public!')}
                    note={t('See what it looks like and add your first projects.')}
                    onClose={() => setIsOpenedSuccessPublishModal(false)}
                >
                    <button
                        className={classNames('button', styles.successPublicModalButton)}
                        data-size="md"
                        data-variant="primary"
                        disabled={isLoading}
                        onClick={() => window.open(getFullUrlToSsr(`/authors/${userDetails.id}`), '_blank')}
                    >
                        {t('View my public page')}
                        <img src={arrowIcon} alt="arrow" />
                    </button>
                </BaseDialog>
            )}
            {isOpenedFillProfileModal && (
                <FillMoreInfoModal
                    fieldsToFill={[
                        userDetails.firstName && FIELDS.FIRST_NAME,
                        userDetails.lastName && FIELDS.LAST_NAME,
                        userDetails.avatarUrl && FIELDS.AVATAR,
                    ]}
                    fields={[FIELDS.FIRST_NAME, FIELDS.LAST_NAME, FIELDS.AVATAR]}
                    onClose={() => setIsOpenedFillProfileModal(false)}
                />
            )}
        </div>
    )
}

export default Public
