import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import Toast from 'components/Toast/Toast'
import { SUPPORT_IMAGE_TYPES } from 'components/AssetManager/utils/constants'

import { ExitIcon, IconRename, TripleDotsIcon, PhotoIcon, EditMembers, IconDelete } from 'svg'

import { getUint8Array } from 'utils/file'

import { API__ORGANIZATIONS } from 'api'

import './Dropdown.scss'

const Dropdown = ({
    organization,
    onLeaveFromOrganization,
    setIsEditableName,
    onUpdateOrganization,
    currentUserId,
    onOpenModalManageTeam,
}) => {
    const { t } = useTranslation()

    const uploadLogoRef = useRef(null)

    const isOwner = organization.ownerId === currentUserId

    const actionMenuItems = [
        isOwner && (
            <p className="organization-panel__dropdown-menu-item" onClick={() => uploadLogoRef.current.click()}>
                <input
                    ref={uploadLogoRef}
                    className="upload-logo-input"
                    type="file"
                    onChange={async ({ target }) => {
                        if (!target.files.length) return
                        try {
                            const file = target.files[0]
                            const uint8Array = await getUint8Array(file)
                            const url = await API__ORGANIZATIONS.SET_ORGANIZATION_LOGO(
                                organization.id,
                                file.type,
                                uint8Array,
                            )
                            onUpdateOrganization(organization, { logoURL: url })
                        } catch (err) {
                            console.error(err)
                            Toast('error', {})
                        }
                    }}
                    value={undefined}
                    accept={Array.from(SUPPORT_IMAGE_TYPES).join(',')}
                />
                <span className="organization-panel__dropdown-menu-icon">
                    <PhotoIcon />
                </span>

                {t('Change logo')}
            </p>
        ),
        isOwner && organization.logoURL && (
            <p
                className="organization-panel__dropdown-menu-item"
                onClick={() => onUpdateOrganization(organization, { logoURL: '' })}
            >
                <span className="organization-panel__dropdown-menu-icon">
                    <IconDelete />
                </span>
                {t('Remove logo')}
            </p>
        ),
        isOwner && (
            <p className="organization-panel__dropdown-menu-item" onClick={() => setIsEditableName(true)}>
                <span className="organization-panel__dropdown-menu-icon">
                    <IconRename />
                </span>
                {t('Rename')}
            </p>
        ),
        isOwner && (
            <p className="organization-panel__dropdown-menu-item" onClick={evt => onOpenModalManageTeam(evt)}>
                <span className="organization-panel__dropdown-menu-icon">
                    <EditMembers />
                </span>
                {t('Manage team')}
            </p>
        ),
        !isOwner && (
            <p
                className="organization-panel__dropdown-menu-item"
                onClick={evt => onLeaveFromOrganization(organization.id, evt)}
            >
                <span className="organization-panel__dropdown-menu-icon">
                    <ExitIcon />
                </span>
                {t('Leave')}
            </p>
        ),
    ]

    return (
        <DropdownMenu
            className="organization-panel__dropdown-menu"
            actionHtml={<TripleDotsIcon />}
            items={actionMenuItems}
        />
    )
}

export default Dropdown
