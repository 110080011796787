import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './CardsGroup.module.scss'

const CardsGroup = ({ children }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.cardsGroup}>
            <h2 className={styles.title}>{t('Available plans')}</h2>
            <ul className={styles.list}>{children}</ul>
        </div>
    )
}

export default CardsGroup
