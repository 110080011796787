import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import Playground from './views/Playground/Playground'
import Cards from './views/Cards/Cards'

import { TABS, TABS_ARRAY } from './constants'
import {
    createRankBattleBlock,
    createCards,
    BLOCK_KEYS,
    BLOCK_KEYS_BY_TABS,
    Playground as PlaygroundModel,
} from './rankBattleService'
import { validateTabs } from './validation'

import styles from './RankBattleModal.module.scss'

const RankBattleModal = ({ methods, data: { structure, colorTheme, fontFamily, id } }) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(
        Object.keys(structure).length === 0 ? createRankBattleBlock() : cloneDeep(structure),
    )
    const [activeTab, setActiveTab] = useState(TABS.PLAYGROUND)
    const [errors, setErrors] = useState({})

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useEffect(() => {
        const cardsCount = tmpStructure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardsCount]
        const cards = cloneDeep(tmpStructure[BLOCK_KEYS.CARDS])

        if (cardsCount > cards.length) {
            setTmpStructure(prevState => {
                return {
                    ...prevState,
                    [BLOCK_KEYS.CARDS]: [...cards, ...createCards(cardsCount - cards.length, cards.length + 1)],
                }
            })
        }
        if (cardsCount < cards.length) {
            cards.splice(cardsCount, cards.length - cardsCount)
            setTmpStructure(prevState => {
                return {
                    ...prevState,
                    [BLOCK_KEYS.CARDS]: cards,
                }
            })
        }
    }, [tmpStructure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardsCount]])

    const onSave = async () => {
        try {
            const validationResult = validateTabs(tmpStructure)
            if (!validationResult.isHasError) {
                await methods.save({ [id]: { data: { struct: tmpStructure } } })
                methods.closeModal()
            } else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const onUpdatePlayground = data => {
        if (errors[BLOCK_KEYS.PLAYGROUND]) {
            const _errors = {}
            Object.keys(data).forEach(key => (_errors[key] = []))
            setErrors(prevState => ({
                ...prevState,
                [BLOCK_KEYS.PLAYGROUND]: {
                    ...prevState[BLOCK_KEYS.PLAYGROUND],
                    ..._errors,
                },
            }))
        }
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.PLAYGROUND]: {
                ...prevState[BLOCK_KEYS.PLAYGROUND],
                ...data,
            },
        }))
    }
    const onUpdateCard = (sectionIndex, sectionId, data) => {
        if (errors[BLOCK_KEYS.CARDS]) {
            const _errors = {}
            Object.keys(data).forEach(key => (_errors[key] = []))
            const sectionsErrors = cloneDeep(errors[BLOCK_KEYS.CARDS])
            sectionsErrors.set(sectionId, {
                ...sectionsErrors.get(sectionId),
                ..._errors,
            })
            setErrors(prevState => ({
                ...prevState,
                [BLOCK_KEYS.CARDS]: sectionsErrors,
            }))
        }

        const sections = cloneDeep(tmpStructure[BLOCK_KEYS.CARDS])
        sections[sectionIndex] = {
            ...sections[sectionIndex],
            ...data,
        }

        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.CARDS]: sections,
        }))
    }

    const onUpdateCardsPosition = (currentIndex, newIndex) => {
        const cards = cloneDeep(tmpStructure[BLOCK_KEYS.CARDS])
        const movingItem = cards[currentIndex]
        cards[currentIndex] = cards[newIndex]
        cards[newIndex] = movingItem
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.CARDS]: cards,
        }))
    }

    const onChangeTab = useCallback(
        tabId => {
            const validationResult = validateTabs(tmpStructure, BLOCK_KEYS_BY_TABS[activeTab])
            if (!validationResult.isHasError) setActiveTab(tabId)
            else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            }
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Rank Battle')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'RANK_BATTLE',
                }}
                onSave={onSave}
            >
                <div className={styles.rankBattleModal}>
                    <div className={styles.body}>
                        {activeTab === TABS.PLAYGROUND && (
                            <Playground
                                header={TABS_ARRAY[0].label}
                                errors={errors?.[BLOCK_KEYS.PLAYGROUND]}
                                colorTheme={colorTheme}
                                fontFamily={fontFamily}
                                structure={tmpStructure[BLOCK_KEYS.PLAYGROUND]}
                                allStructure={tmpStructure}
                                onUpdate={onUpdatePlayground}
                            />
                        )}
                        {activeTab === TABS.CARDS && (
                            <Cards
                                header={TABS_ARRAY[1].label}
                                errors={errors?.[BLOCK_KEYS.CARDS]}
                                colorTheme={colorTheme}
                                cardType={tmpStructure[BLOCK_KEYS.PLAYGROUND][PlaygroundModel.cardType]}
                                structure={tmpStructure[BLOCK_KEYS.CARDS]}
                                onUpdateCard={onUpdateCard}
                                onUpdateCardsPosition={onUpdateCardsPosition}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={() => methods.closeModal()}
            />
        </Modal>
    )
}

export default RankBattleModal
