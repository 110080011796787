import i18n from 'i18n'

import { SECTION_WIN_PROBABILITY, SECTION_WIN_PROBABILITY_FACTORS } from '../../../../../../constants'

export const SECTION_WIN_PROBABILITY_DATA = {
    [SECTION_WIN_PROBABILITY.NORMAL]: {
        value: SECTION_WIN_PROBABILITY.NORMAL,
        getLabel: percentage => `${i18n.t('Normal')} (${percentage}%)`,
        factor: SECTION_WIN_PROBABILITY_FACTORS[SECTION_WIN_PROBABILITY.NORMAL],
    },
    [SECTION_WIN_PROBABILITY.VERY_HIGH]: {
        value: SECTION_WIN_PROBABILITY.VERY_HIGH,
        getLabel: percentage => `${i18n.t('Very High')} (${percentage}%)`,
        factor: SECTION_WIN_PROBABILITY_FACTORS[SECTION_WIN_PROBABILITY.VERY_HIGH],
    },
    [SECTION_WIN_PROBABILITY.HIGH]: {
        value: SECTION_WIN_PROBABILITY.HIGH,
        getLabel: percentage => `${i18n.t('High')} (${percentage}%)`,
        factor: SECTION_WIN_PROBABILITY_FACTORS[SECTION_WIN_PROBABILITY.HIGH],
    },
    [SECTION_WIN_PROBABILITY.LOW]: {
        value: SECTION_WIN_PROBABILITY.LOW,
        getLabel: percentage => `${i18n.t('Low')} (${percentage}%)`,
        factor: SECTION_WIN_PROBABILITY_FACTORS[SECTION_WIN_PROBABILITY.LOW],
    },
    [SECTION_WIN_PROBABILITY.VERY_LOW]: {
        value: SECTION_WIN_PROBABILITY.VERY_LOW,
        getLabel: percentage => `${i18n.t('Very Low')} (${percentage}%)`,
        factor: SECTION_WIN_PROBABILITY_FACTORS[SECTION_WIN_PROBABILITY.VERY_LOW],
    },
}
