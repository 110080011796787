import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { getClassSizeCardText } from './utils'

import BlockTitle from '../BlockTitle/BlockTitle'

import invertColor from '../../../../../util/invertColor'

import styles from './Preview.module.scss'

const DefaultPreview = ({ backgroundColor, text }) => {
    const { t } = useTranslation()

    const mainCardStyles = {
        backgroundColor: backgroundColor,
        color: invertColor(backgroundColor, true),
    }

    return (
        <div className={styles.preview}>
            <BlockTitle text={t('Preview')} />
            <div className={styles.block} style={mainCardStyles}>
                <span className={classNames(styles.text, styles[getClassSizeCardText(text.length)])}>{text}</span>
            </div>
        </div>
    )
}

export default DefaultPreview
