import i18n from 'i18n'

import { MAX_QUESTIONS_COUNT, MIN_QUESTIONS_COUNT } from './components/EditPlayground/components/Question/constants'

export const validate = ({ name, questions }) => {
    let isValid = true
    const errors = {
        name: {
            text: '',
        },
        questions: questions.map(question => ({
            text: '',
            answers: question.answers.map(() => ({ text: '' })),
        })),
    }

    if (!name.trim()) {
        errors.name.text = i18n.t('Field cannot be empty')
        isValid = false
    }

    if (questions.length < MIN_QUESTIONS_COUNT) {
        errors.name.text = i18n.t('Min questions count is {{count}}', { count: MIN_QUESTIONS_COUNT })
        isValid = false
    }
    if (questions.length > MAX_QUESTIONS_COUNT) {
        errors.name.text = i18n.t('Max questions count is {{count}}', { count: MAX_QUESTIONS_COUNT })
        isValid = false
    }

    for (const [questionIndex, question] of questions.entries()) {
        if (!question.correctAnswersIndexes.length) {
            errors.questions[questionIndex].text = i18n.t('No correct answer provided')
            isValid = false
        } else {
            let isHasRealCorrectAnswer = false
            for (const correctAnswerIndex of question.correctAnswersIndexes) {
                if (question.answers.length > correctAnswerIndex) {
                    isHasRealCorrectAnswer = true
                    break
                }
            }
            if (!isHasRealCorrectAnswer) {
                errors.questions[questionIndex].text = i18n.t('No correct answer provided')
                isValid = false
            }
        }

        if (!question.value.trim()) {
            errors.questions[questionIndex].text = i18n.t('Field cannot be empty')
            isValid = false
        }
        for (const [answerIndex, answer] of question.answers.entries()) {
            if (!answer.trim()) {
                errors.questions[questionIndex].answers[answerIndex].text = i18n.t('Field cannot be empty')
                isValid = false
            }
        }
    }

    return {
        errors,
        isValid,
    }
}
