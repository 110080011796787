import React from 'react'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import NavigationListBar from '../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../Controls/NavigationListBar/hooks/useNavigation'

import { QuizResult as ResultModel } from '../../quizModels'

import ResultList from '../components/ResultList'

const ResultView = ({ pages, tmpStructure, errors, onUpdateStructure, getQuestionsCount }) => {
    const { t } = useTranslation()

    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    const onAddResult = () => {
        const result = new ResultModel()
        const newResultList = tmpStructure.results.concat(result)
        onUpdateStructure({
            results: newResultList,
        })
    }

    const onCloneResult = id => {
        const sourceResult = tmpStructure.results.find(card => card.id === id)
        const clonedResult = new ResultModel(sourceResult)
        const newResultList = tmpStructure.results.concat(clonedResult)
        onUpdateStructure({
            results: newResultList,
        })
    }

    const onRemoveResult = id => {
        const newResultList = tmpStructure.results.filter(item => id !== item.id)
        onUpdateStructure({
            results: newResultList,
        })
    }

    const onChangePosition = async (currentIndex, newIndex) => {
        const results = cloneDeep(tmpStructure.results)
        const movingItem = results[currentIndex]
        results[currentIndex] = results[newIndex]
        results[newIndex] = movingItem
        onUpdateStructure({
            results: results,
        })
    }

    const onUpdateResult = (id, newFields) => {
        onUpdateStructure({
            results: tmpStructure.results.map(result => (result.id === id ? { ...result, ...newFields } : result)),
        })
    }

    return (
        <>
            <>
                <aside>
                    <NavigationListBar
                        titleOfItem={t('Result')}
                        focusedItemId={focusedElementId}
                        items={tmpStructure.results}
                        iconAccessor={'image'}
                        maxLength={getQuestionsCount(tmpStructure) + 1}
                        onClickItem={scrollContainerToElement}
                        onClickAddNew={onAddResult}
                    />
                </aside>
                <div className="body">
                    <div className="tab-wrapper tab-wrapper--3">
                        <div className="workplace">
                            <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                                <div className="scrollbar-inner">
                                    <ResultList
                                        tmpStructure={tmpStructure}
                                        resultDistribution={tmpStructure.resultDistribution}
                                        errors={errors}
                                        pages={pages}
                                        getQuestionsCount={getQuestionsCount}
                                        onUpdateResult={onUpdateResult}
                                        onAddResult={onAddResult}
                                        onCloneResult={onCloneResult}
                                        onRemoveResult={onRemoveResult}
                                        onChangePosition={onChangePosition}
                                        onSetFocusToResult={onSetFocusedElementId}
                                    />
                                </div>
                            </ScrollableBlock>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default ResultView
