export const CARD_TYPES = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
}

export const OPTIMAL_CARD_WIDTH = 384
export const CARD_OFFSET = 8
export const BORDER_OFFSET = 8

export const COLORS_KEYS = {
    CARD_BACKGROUND: 'CARD_BACKGROUND',
    CARD_LEADER_BORDER: 'CARD_LEADER_BORDER',
    MAIN_TEXT: 'MAIN_TEXT',
    MAIN_TEXT_HIGHLIGHT: 'MAIN_TEXT_HIGHLIGHT',
    VOTES_MOBILE_BORDER: 'VOTES_MOBILE_BORDER',
    VOTES_TEXT: 'VOTES_TEXT',
    VOTES_LIKE_ICON: 'VOTES_LIKE_ICON',
    VOTES_CHECK_ICON: 'VOTES_CHECK_ICON',
}
