import React from 'react'
import classNames from 'classnames'

import { isBlockedPixabay } from 'utils/common'

import { ASSET_TABS, ASSET_TABS_INFO } from '../../utils/constants'

import './Menu.scss'

const DEFAULT_TABS = [ASSET_TABS.TAB_MY_LIBRARY, ASSET_TABS.TAB_UNSPLASH_LIBRARY, ASSET_TABS.TAB_PIXABAY]

const Menu = ({ className, tabs = DEFAULT_TABS, currentTab, onSwitchTab }) => (
    <div className={classNames('asset-menu', { [className]: !!className })}>
        {tabs.map(tab => {
            if (tab === ASSET_TABS.TAB_PIXABAY && isBlockedPixabay()) return
            return (
                <div
                    key={tab}
                    className={classNames('asset-menu__tab', { 'asset-menu__tab--active': tab === currentTab })}
                    onClick={() => onSwitchTab(tab)}
                >
                    <div className="asset-menu__tab--title">{ASSET_TABS_INFO[tab].title}</div>
                    {!!ASSET_TABS_INFO[tab].note && (
                        <div className="asset-menu__tab--note">{ASSET_TABS_INFO[tab].note}</div>
                    )}
                </div>
            )
        })}
    </div>
)

export default Menu
