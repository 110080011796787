import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import {
    ORGANIZATIONS__INIT,
    ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED,
    ORGANIZATIONS__SET_SELECTED_FOLDER_ID,
    ORGANIZATIONS__SET_SELECTED_MEMBER,
    ORGANIZATIONS_REFRESH,
} from 'store/actions'

import { CONDITIONS, hasPermission, isSubscriptionExpired } from 'common/utils/permissions'

import { API__ORGANIZATIONS } from 'api'

import { MODAL_TYPES } from '../../modals/ModalController'

import { ALL_PROJECTS_MEMBER } from '../../utils/constants'

import UserList from './components/UserList/UserList'
import { OrgItem } from './components/OrgItem/OrgItem'
import './OrganizationPanel.scss'

const OrganizationPanel = ({ selectedMember, onOpenModal, onScrollToTop }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {
        organizations: { organizationList, selectedOrganizationId, memberList, selectedFolderId },
        userDetails,
    } = useSelector(state => ({
        organizations: state.organizations,
        userDetails: state.user_details,
    }))

    const onSelectMember = member => {
        if (selectedMember !== member) {
            dispatch(ORGANIZATIONS__SET_SELECTED_MEMBER(member))
            onScrollToTop()
        } else if (member === ALL_PROJECTS_MEMBER && selectedFolderId !== null) {
            dispatch(ORGANIZATIONS__SET_SELECTED_FOLDER_ID(null))
        }
    }

    const onSelectOrganization = async organization => {
        if (!organization.isApproved) await onOpenModal(MODAL_TYPES.decisionOrganizationInviteModal, organization)
        else if (organization.id !== selectedOrganizationId) {
            dispatch(ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED(organization.id))
            onScrollToTop()
        }
    }

    const onLeaveFromOrganization = (orgId, evt) => {
        evt.preventDefault()

        const refreshOrganizations = () => {
            if (orgId === selectedOrganizationId) dispatch(ORGANIZATIONS__INIT(userDetails.id))
            else dispatch(ORGANIZATIONS_REFRESH())
        }

        API__ORGANIZATIONS.LEAVE_FROM_ORGANIZATION(orgId, userDetails.id)
            .then(() => refreshOrganizations())
            .catch(err => {
                const {
                    response: { status },
                } = err
                if (status === 403) {
                    refreshOrganizations()
                }
            })
    }

    const onUpdateOrganization = async (organization, data) => {
        API__ORGANIZATIONS.UPDATE_ORGANIZATION(organization.id, data)
            .then(() => {
                dispatch(ORGANIZATIONS_REFRESH())
            })
            .catch(err => console.error(err))
    }

    const onOpenModalManageTeam = (evt, isCheckInvitePermission = false) => {
        if (isSubscriptionExpired()) {
            onOpenModal(MODAL_TYPES.subscriptionExpired, null, evt)
            return
        }
        if (isCheckInvitePermission && !hasPermission(CONDITIONS.CAN_INVITE_IN_TEAM)) {
            onOpenModal(
                MODAL_TYPES.upgradeDialog,
                { text: t('Upgrade your plan to invite more members to your team') },
                evt,
            )
            return
        }
        onOpenModal(MODAL_TYPES.manageTeamModal, null, evt)
    }

    return (
        <div className="organization-panel">
            <div className="organization-panel__content">
                {organizationList.map(organization => {
                    const isSelectedOrganization = selectedOrganizationId === organization.id
                    return (
                        <div
                            key={organization.id}
                            className={classNames('organization-panel__space-block', {
                                selected: isSelectedOrganization,
                            })}
                        >
                            <OrgItem
                                organization={organization}
                                currentUserId={userDetails.id}
                                onSelect={onSelectOrganization}
                                onUpdateOrganization={onUpdateOrganization}
                                onLeaveFromOrganization={onLeaveFromOrganization}
                                onOpenModalManageTeam={onOpenModalManageTeam}
                            />
                            <div className="organization-panel__users-block">
                                <UserList
                                    selectedMember={selectedMember}
                                    currentUserId={userDetails.id}
                                    memberList={memberList}
                                    onSelectMember={onSelectMember}
                                />
                                {organization.ownerId === userDetails.id && (
                                    <div
                                        className="organization-panel__invite-button"
                                        onClick={evt => onOpenModalManageTeam(evt, true)}
                                    >
                                        {t('Invite members')}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default OrganizationPanel
