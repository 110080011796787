import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { getClassSizeCardText } from './utils'

import BlockTitle from '../BlockTitle/BlockTitle'

import styles from './Preview.module.scss'

const MatchingPreview = ({ text }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.preview}>
            <BlockTitle text={t('Preview')} />
            <div className={styles.blockMatching}>
                <div className={styles.matchingPreview} />
                <div className={styles.matchingTextWrapper}>
                    <span
                        className={classNames(
                            styles.text,
                            styles.matchingText,
                            styles[getClassSizeCardText(text.length)],
                        )}
                    >
                        {text}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default MatchingPreview
