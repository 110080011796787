import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import openCenteredPopup from 'common/utils/openCenteredPopup'

import { isBlockedMeta } from 'utils/common'

import { SOCIAL_BLOCKS } from './constants'

import styles from './SocialMedia.module.scss'

const SocialMedia = ({ projectUrl }) => {
    const { t } = useTranslation()

    const [blocks] = useState(() => {
        const blocksArray = Object.values(SOCIAL_BLOCKS)
        return blocksArray.filter(block => (block.isMeta ? !isBlockedMeta() : true))
    })

    return (
        <div className={styles.socialMedia}>
            <p className={styles.label}>{t('Share your Project as Social media post')}</p>
            <ul className={styles.socialMediaList}>
                {blocks.map(block => (
                    <li
                        className={styles.socialMediaItem}
                        key={block.url}
                        title={block.text}
                        onClick={() =>
                            openCenteredPopup({
                                url: block.url.replace('%URL%', projectUrl),
                                title: `Share ${block.alt}`,
                                height: 500,
                                width: 600,
                            })
                        }
                    >
                        <img src={block.icon} alt={block.alt} />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default SocialMedia
