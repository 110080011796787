import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ASSET_MANAGER__MANAGER_SET_TAB } from 'store/actions'
import { ASSET_TABS } from './utils/constants'
import { AssetManagerContext } from './AssetManagerContext'

export function useAssetManager(passAllArgs = false) {
    const dispatch = useDispatch()
    const { managerTab } = useSelector(state => state.assetManager)
    const { setIsOpen, setPlaceItem, setSetting } = useContext(AssetManagerContext)

    const chooseAsset = (value, onPlaceItem, setting) => {
        const onPlaceItemWrapper = data => {
            const args = passAllArgs ? data : data.src
            onPlaceItem(args)
        }
        setSetting(setting)
        if (setting?.tab && setting?.tab !== managerTab) {
            dispatch(ASSET_MANAGER__MANAGER_SET_TAB(setting?.tab))
        } else if (!setting?.tab && managerTab !== ASSET_TABS.TAB_MY_LIBRARY) {
            dispatch(ASSET_MANAGER__MANAGER_SET_TAB(ASSET_TABS.TAB_MY_LIBRARY))
        }
        setPlaceItem({ callback: onPlaceItemWrapper })
        setIsOpen(true)
    }

    const deleteAsset = (value, onPlaceItem) => {
        onPlaceItem('')
    }

    return { chooseAsset, deleteAsset }
}
