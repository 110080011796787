import { ORGANIZATION_MEMBER_STATUS } from 'common/constants/organizationConstants.js'

export const mapMemberName = member =>
    member.firstName || member.lastName ? `${member.firstName} ${member.lastName}` : member.email

export const mapOrganizationMembers = members =>
    members.map(member => ({
        ...member,
        isApprove: ORGANIZATION_MEMBER_STATUS.APPROVED === member.approvalStatus,
        name: mapMemberName(member),
    }))
