import React, { useState } from 'react'

import './Upload.scss'

const Upload = ({ variant, size, children, onUpload, accept, className = '', onClick, uploadRef }) => {
    const [_value, _setValue] = useState([])
    const onChange = async ev => {
        onUpload(ev.target.files)
        _setValue([])
    }

    return (
        <>
            <input
                ref={uploadRef}
                className="form-upload"
                type="file"
                id="upload"
                onChange={onChange}
                value={_value}
                accept={accept}
            />
            <label
                className={`form-upload-label ${className}`}
                htmlFor="upload"
                data-variant={variant}
                data-size={size}
                onClick={onClick}
            >
                {children}
            </label>
        </>
    )
}

export default Upload
