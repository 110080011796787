import React from 'react'

import Row from './components/Row/Row'
import Head from './components/Head/Head'

import { COLUMNS_DATA } from './constants'

import styles from './Table.module.scss'

const Table = ({ userId, data }) => (
    <div className={styles.table}>
        <Head columnsData={COLUMNS_DATA} />
        <ul className={styles.rows}>
            {data.map((item, i) => (
                <Row key={i} userId={userId} data={item} />
            ))}
        </ul>
    </div>
)

export default Table
