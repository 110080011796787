import store from "store"

import { MAIN_SERVER } from "./instance"

export const GET_CHAT_COMPLETIONS = async (prompt = '', { model = 'gpt-4-turbo', role = 'system' } = {}) => {
    const {
        user_details: { id },
    } = store.getState()

    const { choices } = await MAIN_SERVER({
        method: 'post',
        url: `/openai/${id}/chat/completions`,
        data: {
            model,
            role,
            prompt,
            isStream: false,
        },
        timeout: 60000,
    })

    return choices[0].message.content
}