import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'

import { downloadFile } from 'common/utils/files'

import Title from '../Title/Title'

import styles from './QrBlock.module.scss'

const QrBlock = ({ url }) => {
    const { t } = useTranslation()

    const qrRef = useRef(null)

    const downloadQrCode = () => {
        if (!qrRef?.current) return
        const qrCodeURL = qrRef.current.children[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
        downloadFile(qrCodeURL, 'QR_Code.png')
    }

    return (
        <div className={styles.qrBlock}>
            <Title isCentered>{t('Get QR code')}</Title>
            <div className={styles.qr} ref={qrRef} onClick={downloadQrCode} title={t('Download')}>
                <QRCode value={url} size={128} />
            </div>
        </div>
    )
}

export default QrBlock
