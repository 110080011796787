import React from 'react'

import Modal from 'components/Modal/Modal'

import styles from './YoutubeModal.module.scss'

const YoutubeModal = ({ title, videoId, onClose }) => (
    <Modal contentClassName={styles.youtubeModal} onClose={onClose}>
        {({ width }) => (
            <div className={styles.content}>
                <iframe
                    width="100%"
                    height={width / 1.78}
                    src={`https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1`}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        )}
    </Modal>
)

export default YoutubeModal
