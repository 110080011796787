import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Modal from 'components/Modal/Modal'
import { IconCheckMark, IconStopHand } from 'svg'

import './DeleteProjectModal.scss'

const DeleteProjectModal = ({ data, onCloseModal, onDeleteProject }) => {
    const { t } = useTranslation()
    const [deleteAgreement, setDeleteAgreement] = useState(false)

    return (
        <Modal isShowCloseIcon closeOnOverlayClick={false} closeOnEsc={false} onClose={onCloseModal}>
            <div className="delete-project-modal">
                <div className="main">
                    <div className="title">{t('Wait!')}</div>
                    <div className="icon">
                        <IconStopHand />
                    </div>
                    <div className="text">
                        <p>
                            {t('You are about to delete the project')} “{data.name}”.
                        </p>
                        <p>
                            <Trans
                                i18nKey="This action <tag>cannot be undone</tag>."
                                components={{
                                    tag: <span style={{ color: '#fd8d8d' }} />,
                                }}
                            />
                        </p>
                    </div>
                    <div className="check-agree">
                        <div
                            className={`checkbox-box ${deleteAgreement ? 'is-checked' : ''}`}
                            onClick={() => setDeleteAgreement(!deleteAgreement)}
                        >
                            <IconCheckMark />
                        </div>
                        <p className="us-n" onClick={() => setDeleteAgreement(!deleteAgreement)}>
                            {t('I understand that all project data will be deleted')}
                        </p>
                    </div>
                    <div className="buttons">
                        <button
                            className="button"
                            disabled={!deleteAgreement}
                            data-size="md"
                            data-variant="primary-danger"
                            onClick={evt => onDeleteProject(data, evt)}
                        >
                            {t('Delete')}
                        </button>
                        <button className="button" data-size="md" data-variant="secondary" onClick={onCloseModal}>
                            {t('Cancel')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteProjectModal
