import React from 'react'
import { cloneDeep } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link } from 'react-router-dom'

import Toast from 'components/Toast/Toast'
import { MAILCHIMP_ID } from 'components/MailChimp/constants'
import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog'
import { CONDITIONS, hasPermission } from 'common/utils/permissions'

import { API__PROJECTS, API__MAILCHIMP, API__WEBHOOKS } from 'api'

import { IconClose } from 'svg'

import GoogleAnalyticsBlock from './components/GoogleAnalyticsBlock'
import GoogleTagManagerBlock from './components/GoogleTagManagerBlock'
import EmailBlock from './components/EmailBlock'
import MailchimpBlock from './components/MailchimpBlock'
import WebhooksBlock from './components/WebhooksBlock'
import YandexMetricBlock from './components/YandexMetricBlock'

import { INTEGRATIONS_MAP, INTEGRATION_IDS } from './utils/constants'

import './index.scss'

class IntegrationsTab extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenUpgradeModal: false,
            activeItem: {
                isOpen: false,
                isChecked: false,
                id: null,
                settings: null,
            },
            integrationsMap: INTEGRATIONS_MAP,
        }
    }

    async componentDidMount() {
        const {
            data: { integrations, projectId },
            selectedOrganizationId,
        } = this.props
        // Google Analytics
        if (integrations && integrations.googleAnalytics) {
            await this.updateIntegrationMap(INTEGRATION_IDS.ga, !!integrations.googleAnalytics.id, {
                id: integrations.googleAnalytics.id || '',
            })
        }
        // Google Tag Manager
        if (integrations && integrations.googleTagManager) {
            await this.updateIntegrationMap(INTEGRATION_IDS.gtm, !!integrations.googleTagManager.id, {
                id: integrations.googleTagManager.id || '',
            })
        }
        // email
        if (integrations && integrations.email) {
            await this.updateIntegrationMap(INTEGRATION_IDS.email, !!integrations.email.email, {
                email: integrations.email.email || '',
            })
        }
        if (integrations && integrations.yandexMetric) {
            await this.updateIntegrationMap(INTEGRATION_IDS.ym, !!integrations.yandexMetric.id, {
                id: integrations.yandexMetric.id || '',
            })
        }

        // webhooks
        if (integrations && integrations.webhooks) {
            await this.updateIntegrationMap(INTEGRATION_IDS.webhooks, !!integrations.webhooks.url, {
                url: integrations.webhooks.url || '',
            })
        }

        if (hasPermission(CONDITIONS.CAN_USE_INTEGRATIONS)) {
            API__MAILCHIMP.GET_ENABLED_INTEGRATIONS(selectedOrganizationId, projectId).then(_integrations => {
                const isMailChimpConnected = _integrations.includes(MAILCHIMP_ID)
                this.setState(prevState => ({
                    integrationsMap: {
                        ...prevState.integrationsMap,
                        [INTEGRATION_IDS.mch]: {
                            ...prevState.integrationsMap[INTEGRATION_IDS.mch],
                            isChecked: isMailChimpConnected,
                        },
                    },
                }))
            })
            API__WEBHOOKS.GET_WEBHOOKS_BY_PROJECT({ organizationId: selectedOrganizationId, projectId }).then(
                webhooks => {
                    const isWebhooksEnabled = webhooks.some(webhook => !webhook.disabled)
                    this.setState(prevState => ({
                        integrationsMap: {
                            ...prevState.integrationsMap,
                            [INTEGRATION_IDS.webhooks]: {
                                ...prevState.integrationsMap[INTEGRATION_IDS.webhooks],
                                isChecked: isWebhooksEnabled,
                            },
                        },
                    }))
                },
            )
        }
    }

    openSettings = (id, disabled) => {
        if (disabled) {
            return
        }

        if (INTEGRATIONS_MAP[id].condition && !hasPermission(INTEGRATIONS_MAP[id].condition)) {
            this.setState({
                isOpenUpgradeModal: true,
            })
            return
        }
        const { integrationsMap } = this.state
        this.setState({
            activeItem: {
                isOpen: true,
                ...integrationsMap[id],
            },
        })
    }

    closeSettings = () => {
        this.setState({
            activeItem: {
                isOpen: false,
                isChecked: false,
                id: null,
                settings: null,
            },
        })
    }

    updateIntegrationMap = async (itemId, isChecked, fields) => {
        const { integrationsMap } = this.state
        const _integrationsMap = cloneDeep(integrationsMap)
        _integrationsMap[itemId].isChecked = isChecked
        _integrationsMap[itemId].settings = { ..._integrationsMap[itemId].settings, ...fields }
        await this.setState({
            integrationsMap: _integrationsMap,
        })
    }

    updateIntegration = async (isActivateIntegration, fields) => {
        try {
            const {
                methods,
                i18n,
                data: { projectId },
            } = this.props
            const { activeItem } = this.state

            switch (activeItem.id) {
                case INTEGRATION_IDS.ga: {
                    await methods.changeProject('projectStructureJson.integrations.googleAnalytics.id', fields.id)
                    break
                }
                case INTEGRATION_IDS.gtm: {
                    await methods.changeProject('projectStructureJson.integrations.googleTagManager.id', fields.id)
                    break
                }
                case INTEGRATION_IDS.ym: {
                    await methods.changeProject('projectStructureJson.integrations.yandexMetric.id', fields.id)
                    break
                }
                case INTEGRATION_IDS.email: {
                    if (isActivateIntegration) {
                        await API__PROJECTS.SET_EMAIL_INTEGRATION(fields.email, projectId)
                    } else {
                        await API__PROJECTS.DISABLE_EMAIL_INTEGRATION(projectId)
                    }
                    await methods.changeProject('projectStructureJson.integrations.email.email', fields.email)
                    break
                }
                default:
                    break
            }
            await this.updateIntegrationMap(activeItem.id, isActivateIntegration, fields)
            this.closeSettings()
            Toast('success', {
                title: i18n.t('Saved'),
                time: 2000,
            })
        } catch (err) {
            console.error(err)
            Toast('error', {})
        }
    }

    closeUpgradeModal = () => {
        this.setState({
            isOpenUpgradeModal: false,
        })
    }

    onUpgradeClick = async () => {
        const { history } = this.props
        history.push('/billing-and-payments?tab=subscription')
    }

    render() {
        const {
            data: { projectUuid },
            i18n,
        } = this.props
        const { integrationsMap, activeItem, isOpenUpgradeModal } = this.state

        return (
            <>
                <div className="integrations">
                    <h1>
                        <Trans
                            i18nKey="Add integrations or <0>skip</0> this step"
                            components={[<Link to={`/editor/${projectUuid}/sharing-options`}>, </Link>]}
                        />
                    </h1>

                    <div className="integrations-wrap">
                        <ul className="cards">
                            {Object.values(integrationsMap)
                                .filter(item => !item.onCheckHidden())
                                .map(item => (
                                    <li
                                        key={item.id}
                                        className={`cards-item${item.isChecked ? ' is-checked' : ''}${
                                            item.isDisabled ? ' is-disabled' : ''
                                        }`}
                                        onClick={() => this.openSettings(item.id, item.isDisabled)}
                                    >
                                        <div className="switcher"></div>
                                        <div className="image">
                                            <img src={item.image} alt="" />
                                        </div>
                                        {item.isDisabled ? (
                                            <div className="coming-soon">{i18n.t('Coming soon')}</div>
                                        ) : (
                                            <p>{item.description}</p>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    </div>

                    <div className={`settings-window${activeItem.isOpen ? ' is-active' : ''}`}>
                        <OutsideClickHandler onOutsideClick={this.closeSettings}>
                            <div className="wrapper">
                                <div className="close-btn" onClick={() => this.closeSettings()}>
                                    <IconClose />
                                </div>
                                <div className="content">
                                    {activeItem.id === INTEGRATION_IDS.ga && (
                                        <GoogleAnalyticsBlock
                                            isChecked={activeItem.isChecked}
                                            settings={activeItem.settings}
                                            updateIntegration={this.updateIntegration}
                                        />
                                    )}
                                    {activeItem.id === INTEGRATION_IDS.email && (
                                        <EmailBlock
                                            isChecked={activeItem.isChecked}
                                            settings={activeItem.settings}
                                            updateIntegration={this.updateIntegration}
                                        />
                                    )}
                                    {activeItem.id === INTEGRATION_IDS.ym && (
                                        <YandexMetricBlock
                                            isChecked={activeItem.isChecked}
                                            settings={activeItem.settings}
                                            updateIntegration={this.updateIntegration}
                                        />
                                    )}
                                    {activeItem.id === INTEGRATION_IDS.gtm && (
                                        <GoogleTagManagerBlock
                                            isChecked={activeItem.isChecked}
                                            settings={activeItem.settings}
                                            updateIntegration={this.updateIntegration}
                                        />
                                    )}
                                    {activeItem.id === INTEGRATION_IDS.mch && <MailchimpBlock />}
                                    {activeItem.id === INTEGRATION_IDS.webhooks && <WebhooksBlock />}
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>

                {isOpenUpgradeModal && (
                    <UpgradeDialog onClose={() => this.closeUpgradeModal()} onUpgrade={() => this.onUpgradeClick()} />
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    selectedOrganizationId: state.organizations.selectedOrganizationId,
})

export default compose(connect(mapStateToProps, null), withTranslation('translations'))(IntegrationsTab)
