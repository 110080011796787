import React, { useState, useEffect } from 'react'

const useAudioPlayer = () => {
    const [audioElement, setAudioElement] = useState(null)
    const [playedUrl, setPlayedUrl] = useState('')
    const [elementId, setElementId] = useState(null)

    useEffect(() => {
        const audio = new Audio(null)

        setAudioElement(audio)
        audio.addEventListener('ended', () => {
            setPlayedUrl(null)
        })

        return () => {
            audio.removeAttribute('src')
            audio.load()
        }
    }, [])

    const onPlayAudio = (url, _id = null) => {
        if (playedUrl && playedUrl === url) {
            setPlayedUrl(null)
            setElementId(null)
            audioElement.pause()
        } else {
            setPlayedUrl(url)
            setElementId(_id)
            audioElement.setAttribute('src', url)
            audioElement.load()
            audioElement.play()
        }
    }

    const isAudioPlay = !!playedUrl

    return { onPlayAudio, isAudioPlay, playedUrl, elementId }
}

export default useAudioPlayer
