import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import Image from 'components/Form/Image'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import AddListItemButton from '../AddListItemButton/AddListItemButton'

import styles from './NavigationListBar.module.scss'

const NavigationListBar = ({
    titleOfItem,
    items = [],
    focusedItemId,
    maxLength,
    onClickItem,
    colorTheme,
    iconAccessor = 'coverImage',
    heightSubtract = 0,
    onClickAddNew,
    isHideAdd = false,
    isShowIcon = true,
    navigationListBarClassName,
}) => {
    const [prevItemsLength, setPrevItemsLength] = useState(items.length)
    const scrollableNodeRef = useRef(null)

    const [isAllowedAdd, setIsAllowedAdd] = useState(false)

    useEffect(() => {
        if (isHideAdd) return
        setIsAllowedAdd(items && items.length < maxLength)
    }, [items, maxLength])

    useEffect(() => {
        if (items.length > prevItemsLength) onClickItem(items[items.length - 1].id)
        setPrevItemsLength(items.length)
    }, [items.length])

    return (
        <ScrollableBlock
            maxHeight={heightSubtract ? `calc(100% - ${heightSubtract})` : '100%'}
            scrollableNodeRef={scrollableNodeRef}
        >
            <div
                className={classNames(styles.navigationListBar, {
                    [navigationListBarClassName]: !!navigationListBarClassName,
                })}
            >
                {!!items.length && (
                    <ul className={classNames(styles.list, { [styles.isFullList]: !isAllowedAdd })}>
                        {items.map((item, index) => (
                            <li className={styles.item} key={item.id}>
                                {isShowIcon && (
                                    <div
                                        className={classNames(styles.itemIcon, {
                                            [styles.isActiveItemIcon]: focusedItemId === item.id,
                                        })}
                                        style={{ backgroundColor: !!item[iconAccessor] ? 'none' : colorTheme }}
                                        onClick={() => onClickItem(item.id)}
                                    >
                                        {!!item[iconAccessor] && (
                                            <Image src={item[iconAccessor]} className={styles.itemIconImage} />
                                        )}
                                    </div>
                                )}
                                <div
                                    className={classNames(styles.itemText, {
                                        [styles.isActiveItemText]: focusedItemId === item.id,
                                    })}
                                    onClick={() => onClickItem(item.id)}
                                >
                                    {`${titleOfItem} ${index + 1}`}
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                {isAllowedAdd && <AddListItemButton length={items.length} onClick={onClickAddNew} />}
            </div>
        </ScrollableBlock>
    )
}

export default NavigationListBar
