import TextInput from './Controls/TextInput'
import TextArea from './Controls/TextArea'
import UploadButton from './Controls/UploadButton/UploadButton'
import ColorPicker from './Controls/ColorPickerControl'
import Checkbox from './Controls/Checkbox'
import RadioButton from './Controls/RadioButton'
import Select from './Controls/Select'
import ArrayWithModal from './Controls/ArrayWithModal'
import LinkWithModal from './Controls/LinkWithModal'
import InputWithModal from './Controls/InputWithModal'
import PaddingEditor from './Controls/PaddingEditor/PaddingEditor'
import DemoWithModal from './Controls/DemoWithModal/DemoWithModal'
import CssCodeEditor from './Controls/CodeEditor/CSS/CSS'

export const CONTROLS_COMPONENTS = {
    TextInput,
    TextArea,
    UploadButton,
    ColorPicker,
    Checkbox,
    RadioButton,
    Select,
    ArrayWithModal,
    LinkWithModal,
    InputWithModal,
    PaddingEditor,
    DemoWithModal,
    CssCodeEditor,
}

export const CONTROLS_ENUM = {
    TextInput: 'TextInput',
    TextArea: 'TextArea',
    UploadButton: 'UploadButton',
    ColorPicker: 'ColorPicker',
    Checkbox: 'Checkbox',
    RadioButton: 'RadioButton',
    Select: 'Select',
    ArrayWithModal: 'ArrayWithModal',
    LinkWithModal: 'LinkWithModal',
    InputWithModal: 'InputWithModal',
    PaddingEditor: 'PaddingEditor',
    DemoWithModal: 'DemoWithModal',
    CssCodeEditor: 'CssCodeEditor',
}
