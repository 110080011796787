import classNames from 'classnames'

import Select from 'components/Form/Select/Select'

import styles from './Item.module.scss'

const Item = ({ isActive = false, label, onSelect, selectData }) => (
    <li
        className={classNames(styles.item, { [styles.isActiveItem]: isActive })}
        onClick={() => (isActive ? {} : onSelect())}
    >
        <div className={styles.main}>
            <div className={classNames(styles.checkbox, { [styles.isActiveCheckbox]: isActive })} />
            <p className={classNames(styles.label, { [styles.isActiveLabel]: isActive })}>{label}</p>
        </div>
        {!!selectData && (
            <div className={classNames(styles.select, { [styles.isActiveSelect]: isActive })}>
                <Select
                    value={selectData.value}
                    onUpdate={value => selectData.onChange(value)}
                    options={selectData.options}
                />
            </div>
        )}
    </li>
)

export default Item
