import React, {useTranslation} from "react-i18next"

import aiIcon from "./i/ai.svg"

import styles from "./AiButton.module.scss"

const AiButton = ({ onClick }) => {
    const { t } = useTranslation()

    return (
        <div
            title={`${t('AI Generator')} (beta)`}
            className={styles.aiButton}
            onClick={onClick}
        >
            <div className={styles.aiButtonMain}>
                {t('AI Generator')}
                <img src={aiIcon}/>
            </div>
            <div className={styles.aiButtonBeta}>beta</div>
        </div>
    )
}

export default AiButton