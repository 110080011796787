import React from 'react'

export default ({ show, width, align, children }) => {
    let styles = {}
    if (width) {
        styles.width = width
    }

    return (
        <div className={`menu-list ${show ? 'is-visible' : ''}`} data-align={align} style={styles}>
            {children}
        </div>
    )
}
