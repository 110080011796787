import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Modal from 'components/Modal/Modal'

import cloudIcon from './i/cloud.svg'
import crossIcon from './i/cross.svg'

import styles from './PdfGeneration.module.scss'

const PdfGeneration = ({ onClose, payload }) => {
    const { t } = useTranslation()

    useEffect(() => {
        download()
    }, [])

    const download = () => {
        if (!payload?.url) return
        const link = document.createElement('a')
        link.href = payload.url
        link.setAttribute('download', payload.fileName || 'file.pdf')
        document.body.appendChild(link)
        link.click()
    }

    return (
        <Modal closeOnEsc={true} closeOnOverlayClick={false} onClose={onClose}>
            <div className={styles.pdfGeneration}>
                <div className={styles.closeIcon} onClick={() => onClose()}>
                    <img src={crossIcon} alt="close" />
                </div>
                <div className={styles.imageBox}>
                    <img src={cloudIcon} alt="cloud" />
                </div>
                <div className={styles.title}>{t('Your PDF is ready')}</div>
                <p className={styles.text}>
                    <Trans
                        i18nKey="The download will start automatically.<br/>To download manually, <lnk>click here</lnk>."
                        components={{
                            lnk: <span className={styles.downloadLnk} onClick={() => download()} />,
                        }}
                    />
                </p>
                <button className="button" data-variant="primary" data-size="lg" onClick={() => onClose()}>
                    {t('Got it!')}
                </button>
            </div>
        </Modal>
    )
}

export default PdfGeneration
