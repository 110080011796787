import React from 'react'
import { useTranslation } from 'react-i18next'

import UploadMedia from '../../UploadMedia'

import './EmptyGrid.scss'

const EmptyGrid = ({ isAudio }) => {
    const { t } = useTranslation()

    return (
        <div className="empty-grid">
            <h1>{t('Start adding your files')}</h1>
            <UploadMedia size="md" variant="primary-light">
                + {isAudio ? t('Upload audio') : t('Upload image')}
            </UploadMedia>
            <p>
                {isAudio
                    ? t('We support mp3, wav and ogg file formats')
                    : t('We support jpg, png and gif file formats')}
            </p>
        </div>
    )
}

export default EmptyGrid
