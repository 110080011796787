export const KEYBOARD_KEYS = {
    BACKSPACE: 'Backspace',
    SPACE: ' ',
    TAB: 'Tab',
    ENTER: 'Enter',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    DASH: '-',
}
