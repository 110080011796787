import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RemoveDataModal from './components/RemoveDataModal/RemoveDataModal'

import questionIcon from './i/question.svg'

import styles from './RemoveInterval.module.scss'

const RemoveInterval = ({ data, projectId, onUpdate }) => {
    const { t } = useTranslation()
    const [noteText, setNoteText] = useState('')
    const [actionText, setActionText] = useState('')
    const [isOpenedRemoveDataModal, setIsOpenedRemoveDataModal] = useState(false)

    useEffect(() => {
        if (data) {
            setNoteText(t('Data deletion:'))
            setActionText(t('Every {{count}} days', { count: data.removingPeriodInDays }))
        } else {
            setNoteText(t('Data deletion is disabled.'))
            setActionText(t('Set up'))
        }
    }, [data])

    return (
        <div className={styles.removeInterval}>
            {noteText}{' '}
            <span className={styles.removeIntervalAction} onClick={() => setIsOpenedRemoveDataModal(true)}>
                {actionText}
            </span>
            <a
                href="https://help.interacty.me/en/content/how-to-automatically-remove-user-data-from-statistics"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={questionIcon} alt="question" />
            </a>
            {isOpenedRemoveDataModal && (
                <RemoveDataModal
                    projectId={projectId}
                    data={data}
                    onUpdate={onUpdate}
                    onClose={() => setIsOpenedRemoveDataModal(false)}
                />
            )}
        </div>
    )
}

export default RemoveInterval
