import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Band.scss'

import IconArrowBack from './i/backArrow.svg'

const Band = ({ backPath, projectName }) => {
    const { t } = useTranslation()
    return (
        <div className="project-analytics-band">
            <div className="project-analytics-band__container">
                <Link className="back" to={backPath}>
                    <img src={IconArrowBack} alt="" />
                    <span>{t('Back to projects')}</span>
                </Link>
                <div className="title">
                    <span>{t('Analytics')}</span> | {projectName}
                </div>
            </div>
        </div>
    )
}

export default Band
