import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Error = () => {
    const { t } = useTranslation()

    return (
        <div className="error-box">
            <Helmet>
                <title>{t('Error')} | Interacty</title>
            </Helmet>
            <div className="error-box_code">500</div>
            <div className="error-box_content">
                <div className="error-box_content--text">{t('Looks like something went wrong')}</div>
                <div className="error-box_content--link">
                    <Link to="/">{t('Go home')}</Link>
                </div>
            </div>
        </div>
    )
}

export default Error
