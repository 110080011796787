import React from 'react'

import icon_addEmbed from '../../i/embedInteractyProject_icon.svg'

/**
 * Interacty project
 */
class EmbedProject extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            blockData: { t },
        } = this.props

        return (
            <div className={`block __${t}`}>
                <img className="ico" src={icon_addEmbed}></img>Interacty Project visible in preview or published mode
            </div>
        )
    }
}

export default EmbedProject
