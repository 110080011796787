export const getClassSizeCardText = length => {
    return length <= 6 ? 'big' : length <= 30 ? 'medium' : 'small'
}

export const getCardSize = (pairsCount, width) => {
    if (width < 516) return 66

    if (pairsCount <= 4) return 160
    if (pairsCount <= 6) return 110
    return 82
}
