import i18n from 'i18next'

import { itemRules, maxLength, required, customFunction } from 'shared/validation'

import playground2Icon from 'components/Modal/components/Body/components/SideNav/i/playground2.svg'
import questions2Icon from 'components/Modal/components/Body/components/SideNav/i/questions2.svg'
import mapIcon from 'components/Modal/components/Body/components/SideNav/i/map.svg'

export const TABS = {
    playground: 1,
    tiles: 2,
    finalScreen: 3,
}

export const TABS_ARRAY = [
    {
        id: 1,
        label: i18n.t('Playground'),
        icon: playground2Icon,
    },
    {
        id: 2,
        label: i18n.t('Quests'),
        icon: questions2Icon,
    },
    {
        id: 3,
        label: i18n.t('Treasure map'),
        isHideFromMultiplayer: true,
        icon: mapIcon,
    },
]

export const LAYOUT_TEMPLATE = [
    {
        value: 1,
        rows: 1,
        cols: 1,
        label: i18n.t('{{count}} quest(s)', { count: 1 }),
    },
    {
        value: 4,
        rows: 2,
        cols: 2,
        label: i18n.t('{{count}} quest(s)', { count: 4 }),
    },
    {
        value: 6,
        rows: 2,
        cols: 3,
        label: i18n.t('{{count}} quest(s)', { count: 6 }),
    },
    {
        value: 9,
        rows: 3,
        cols: 3,
        label: i18n.t('{{count}} quest(s)', { count: 9 }),
    },
]

export const TEXT_POSITION_OPTIONS = [
    {
        value: 'center',
        label: i18n.t('Center'),
    },
    {
        value: 'bottom',
        label: i18n.t('Bottom'),
    },
]

export const PLAYGROUND_FIELDS = {
    layout: 'layout',
    cardProportions: 'cardProportions',
    isShowQuestCover: 'isShowQuestCover',
    coverPosition: 'coverPosition',
}

export const TILES_FIELDS = {
    tileList: 'tileList',
}

export const TAB_TO_FIELD_MAP = {
    [TABS.playground]: 'playground',
    [TABS.tiles]: 'tiles',
    [TABS.finalScreen]: 'finalScreen',
}

export const VALIDATE_RULES_FIELDS = {
    playground: {
        layout: [required()],
    },
    tiles: {
        tileList: [
            itemRules({
                id: [required()],
                password: [
                    required(),
                    customFunction(({ value }) => !!getFormattedPassword(value), i18n.t('This field is required')),
                ],
                buttonLink: [],
                buttonText: [],
                color: [],
                headerImgSrc: [],
                imgSrc: [],
                overlaySrc: [],
                quest: [maxLength(60)],
                questDescription: [],
            }),
        ],
    },
    finalScreen: {
        header: [required()],
    },
}

export const colNumberToSize = {
    1: 'large',
    2: 'medium',
    3: 'small',
}

export const getFormattedPassword = (password, separator = '|') => {
    const passwordsArray = password.split(separator).filter(v => !!v.trim())
    return passwordsArray.map(password => password.trim()).join(separator)
}
