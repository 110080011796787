import React from 'react'

import Modal from '../Modal'
import TrialActivatedModal from '../TrialEndedModal/components/TrialActivatedModal/TrialActivatedModal'

import './TrialExtensionModal.scss'

const TrialExtensionModal = ({ onClose }) => (
    <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={() => onClose()} isShowCloseIcon>
        <TrialActivatedModal onClose={onClose} />
    </Modal>
)

export default TrialExtensionModal
