import { Grid } from '@mui/material'
import React from 'react'

import FolderItem from './FolderItem'

const stubFolder = {
    id: 'f55540bb-cb24-4475-9326-512fbe3731f9',
    name: '',
    projectCount: null,
}

const StubFolderList = () => (
    <Grid item xs={2} sm={1} md={3} key="stub1">
        <FolderItem folder={stubFolder} isLoading={true} />
    </Grid>
)

export default StubFolderList
