import React, { useState } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import classnames from 'classnames'

import { TreasureHuntTileWrapper } from './TreasureHuntTileWrapper'

import './TreasureHuntPreview.scss'

export const TreasureHuntPreview = ({ tiles, playground, isModal, colorTheme }) => {
    const { layout, cardProportions, isShowQuestCover, coverPosition } = playground

    const [width, setWidth] = useState(0)
    const onResizeContainer = _width => setWidth(_width)

    return (
        <ReactResizeDetector refreshMode="debounce" refreshRate={0} handleWidth onResize={onResizeContainer}>
            <div className={classnames('treasure-hunt-preview', { 'is-modal': isModal })}>
                <div className="treasure-hunt-preview__tiles">
                    {tiles.map((tile, i) => (
                        <TreasureHuntTileWrapper
                            key={i}
                            tile={tile}
                            width={width}
                            colorTheme={colorTheme}
                            rows={layout.rows}
                            cols={layout.cols}
                            isShowQuestCover={isShowQuestCover}
                            cardProportions={cardProportions}
                            coverPosition={coverPosition}
                        />
                    ))}
                </div>
            </div>
        </ReactResizeDetector>
    )
}
