import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Aside from './components/Aside/Aside'
import Content from './components/Content/Content'

import Authorization from './Views/Authorization'
import Registration from './Views/Registration'
import PasswordRecovery from './Views/PasswordRecovery'
import AppSumoCompleteRegistration from './Views/AppSumoCompleteRegistration'
import Activation from './Views/Activation'

import useAction from './hooks/useAction'

import { ACTIONS } from './constants'

import styles from './Auth.module.scss'

const Auth = () => {
    const { t } = useTranslation()
    const { action } = useAction()

    return (
        <div className={styles.auth}>
            <Helmet>
                <title>{t('Auth')} | Interacty</title>
            </Helmet>
            <Aside />
            <Content>
                {action === ACTIONS.AUTHENTICATION && <Authorization />}
                {action === ACTIONS.REGISTRATION && <Registration />}
                {action === ACTIONS.PASSWORD_RECOVERY && <PasswordRecovery />}
                {action === ACTIONS.ACTIVATION && <Activation />}
                {action === ACTIONS.APPSUMO_COMPLETE_REGISTRATION && <AppSumoCompleteRegistration />}
            </Content>
        </div>
    )
}

export default Auth
