import { cloneDeep, map } from 'lodash'
import { createCollector, createValidator } from 'shared/validation'
import { QuizCover, QuizResult } from '../../quizModels'
import getCartesianProduct from './cartesianProduct.js'
import { PERSONALITY_RULES_FIELDS, TAB_TO_FIELD_MAP, VALIDATION_RULES } from './constants.js'
import { Question } from './models.js'

export const isValidPersonality = createValidator(PERSONALITY_RULES_FIELDS)
export const collectPersonalityErrors = createCollector(PERSONALITY_RULES_FIELDS)

export const validateTabs = (tmpStructure, tabsValidationResult, activeTab) => {
    const normalizedStructure = getNormalizedStructure(tmpStructure)
    let activeTabField = TAB_TO_FIELD_MAP[activeTab] || null
    const isValid = isValidPersonality(normalizedStructure, activeTabField)
    const errors = collectPersonalityErrors(normalizedStructure, activeTabField)

    return {
        normalizedStructure,
        errors,
        activeTabHasError: activeTabField && !isValid,
        hasError: !isValid,
    }
}

export function getNormalizedStructure(struct) {
    const _tmpStructure = cloneDeep(struct)

    // cover
    _tmpStructure.cover.header = _tmpStructure.cover.header.trim()
    _tmpStructure.cover.description = _tmpStructure.cover.description.trim()
    _tmpStructure.cover.buttonText = _tmpStructure.cover.buttonText.trim()
    _tmpStructure.cover.image = _tmpStructure.cover.image ? _tmpStructure.cover.image.trim() : null
    _tmpStructure.cover.imageDisclaimer = _tmpStructure.cover.imageDisclaimer.trim()
    // questions
    _tmpStructure.questions = _tmpStructure.questions.map(qEl => {
        return {
            ...qEl,
            text: qEl.text.trim(),
            image: qEl.image ? qEl.image.trim() : null,
            imageDisclaimer: qEl.imageDisclaimer.trim(),
            answers: qEl.answers.map(aEl => {
                return {
                    ...aEl,
                    text: aEl.text.trim(),
                    image: aEl.image ? aEl.image.trim() : null,
                    imageLabel: aEl.imageLabel.trim(),
                }
            }),
        }
    })
    // results
    _tmpStructure.results = _tmpStructure.results.map(elR => {
        return {
            ...elR,
            header: elR.header.trim(),
            description: elR.description.trim(),
            image: elR.image ? elR.image.trim() : null,
            imageDisclaimer: elR.imageDisclaimer.trim(),
        }
    })

    return _tmpStructure
}

export const createNewProject = () => {
    const questions = map(Array.from({ length: VALIDATION_RULES.minQuestions }), () => new Question())
    const results = map(Array.from({ length: VALIDATION_RULES.minResults }), () => new QuizResult())
    return { cover: new QuizCover(), questions, results }
}

export const calculateResultDistribution = (questionList = [], resultsIds = []) => {
    const possibleChainsCount = questionList.reduce((acc, question) => {
        return (acc *= question.answers.length)
    }, 1)

    if (possibleChainsCount <= Math.pow(3, 10)) {
        // equivalent - 10q and 3o for every q

        const weightDistribution = questionList.map(question =>
            question.answers.map(answer => {
                return resultsIds.map(id => {
                    const link = answer.links.find(link => link.resultId === id)
                    return link ? link.weight : 0
                })
            }),
        )

        return calculateProbality(weightDistribution, resultsIds, possibleChainsCount)
    } else {
        //todo: no logic
        return resultsIds.reduce((acc, id) => {
            acc[id] = null
            return acc
        }, {})
    }
}

/**
 * @param weightDistribution {Array.<Array.<Array.<number>>>} - example [ [ [0,2], [2,0] ], [ [1,0], [2,1] ] ]
 * @param resultsIds {String[]}
 * @param possibleChainsCount {number}
 */
export const calculateProbality = (weightDistribution, resultsIds, possibleChainsCount) => {
    const resultsLength = resultsIds.length
    let scores = new Array(resultsLength).fill(0)
    for (const values of getCartesianProduct(weightDistribution)) {
        let tmp = new Array(resultsLength).fill(0)
        values.forEach(el => {
            for (let i = 0; i < resultsLength; i++) {
                tmp[i] += el[i]
            }
        })
        const max = Math.max.apply(null, tmp)
        const maxLength = tmp.filter(el => el === max).length
        tmp.forEach((el, i) => {
            if (maxLength === resultsLength || el === max) {
                scores[i] = Math.round((scores[i] + 1 / maxLength) * 100) / 100
            }
        })
    }

    const probability = {}

    scores.forEach((el, i) => {
        if (el === 0) {
            probability[resultsIds[i]] = 0
        } else {
            probability[resultsIds[i]] = Math.round(Math.round(((el * 100) / possibleChainsCount) * 100) / 100)
        }
    })

    return probability
}
