import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import InputFile from '../../../InputFile/InputFile'

import styles from './AddButton.module.scss'

const AddButton = ({ onAdd, projectId }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const uploadRef = useRef(null)

    return (
        <div className={styles.addImage}>
            <InputFile
                ref={uploadRef}
                isGalleryImage
                projectId={projectId}
                onChangeLoading={v => setIsLoading(v)}
                onChange={url => onAdd(url)}
            />
            <span
                className={classNames(styles.addImageButton, { [styles.isLoadingImageButton]: isLoading })}
                onClick={() => uploadRef.current.click()}
            >
                + {t('Add image')}
            </span>
        </div>
    )
}

export default AddButton
