import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getLineStyles, getValues } from './utils'

import unlimitedIcon from './i/unlimited.png'

import styles from './ListItem.module.scss'

const ListItem = ({ label, icon, value, total, type, isDanger }) => {
    const { t } = useTranslation()
    const [lineStyles, setLineStyles] = useState(() => getLineStyles(value, total))
    const [values, setValues] = useState(() => getValues(value, total, type))

    useEffect(() => {
        setLineStyles(getLineStyles(value, total, isDanger))
        setValues(getValues(value, total, type))
    }, [value, total, type])

    return (
        <li className={styles.item}>
            <div className={styles.info}>
                <div className={styles.leftSide}>
                    <div className={styles.icon}>
                        <img src={icon} alt="icon" />
                    </div>
                    {label}
                </div>
                <div className={styles.limit}>
                    {values.isUnlimited ? (
                        <div className={styles.limitIcon} title={t('Unlimited')}>
                            <img src={unlimitedIcon} alt="" />
                        </div>
                    ) : (
                        <>
                            <span>
                                {values.value}
                                {!!values.valueUnits && ` ${values.valueUnits}`}
                            </span>
                            /{values.total} {values.totalUnits}
                        </>
                    )}
                </div>
            </div>
            <div className={styles.line}>
                <div className={styles.filledLine} style={lineStyles} />
            </div>
        </li>
    )
}

export default ListItem
