import React from 'react'

import { isEducation, isAppSumoUser } from 'common/utils/permissions'

import { isEuNorwayVersion } from 'utils/env'

import BusinessPlans from './components/Plans/BusinessPlans'
import EducationPlans from './components/Plans/EducationPlans'
import AppSumoFeatures from './components/Features/AppSumo'
import SubscriptionsList from './components/SubscriptionsList/SubscriptionsList'

const Subscription = () => (
    <div className="subscriptions">
        <SubscriptionsList />
        {!isEuNorwayVersion() &&
            (isAppSumoUser() ? <AppSumoFeatures /> : isEducation() ? <EducationPlans /> : <BusinessPlans />)}
    </div>
)

export default Subscription
