import React, { useState, useEffect } from 'react'

import { API__PROJECTS } from 'api'

import Overview from './components/Overview/Overview'
import RemoveInterval from './components/RemoveInterval/RemoveInterval'

import GeoData from '../../components/GeoData/GeoData'
import DateRangePanel from '../../components/DateRangePanel/DateRangePanel'
import ComponentsList from '../../components/ComponentsList/ComponentsList'

import styles from './Dashboard.module.scss'

const DashboardLayout = ({ projectId }) => {
    const [dateRange, setDateRange] = useState(null)
    const [isLoadedRemoveInterval, setIsLoadedRemoveInterval] = useState(false)
    const [removeInterval, setRemoveInterval] = useState(null)

    useEffect(() => {
        updateRemoveInterval()
            .then(() => setIsLoadedRemoveInterval(true))
            .catch(() => {})
    }, [])

    const updateRemoveInterval = async () => {
        try {
            const data = await API__PROJECTS.GET_PROJECT_STATISTIC_REMOVE_INTERVAL(projectId)
            setRemoveInterval(data)
        } catch (err) {
            setRemoveInterval(null)
        }
    }

    return (
        <>
            <div className={styles.dashboardTopBar}>
                <DateRangePanel onChangeRange={v => setDateRange(v)} />
                {isLoadedRemoveInterval && (
                    <RemoveInterval projectId={projectId} data={removeInterval} onUpdate={updateRemoveInterval} />
                )}
            </div>
            {dateRange && (
                <ComponentsList>
                    <Overview projectId={projectId} dateRange={dateRange} />
                    <GeoData projectId={projectId} isPreview dateRange={dateRange} />
                </ComponentsList>
            )}
        </>
    )
}

export default DashboardLayout
