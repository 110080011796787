import { isEuVersion } from 'common/constants/env'

import { LOCALES } from 'i18n/languages'

import store from 'store'

const isUserLocaleNorway = () => {
    const { language } = store.getState()

    if (!language) return false
    return language === LOCALES.NO
}

export const isEuNorwayVersion = () => isEuVersion && isUserLocaleNorway()
