import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/id'
import 'moment/locale/tr'
import 'moment/locale/ko'
import 'moment/locale/nb'
import 'moment/locale/sv'
import 'moment/locale/ja'
import 'moment/locale/fi'
import 'moment/locale/lv'
import 'moment/locale/uk'
import 'moment/locale/ro'

import COOKIES from 'common/constants/cookies'

import { LOCALES, DEFAULT_LOCALE } from 'i18n/languages'

import { getCookie } from 'utils/cookies'

const userLng = getCookie(COOKIES.LOCALE) || DEFAULT_LOCALE

export const getMoment = moment

export const FORMATS = {
    dateTime: {
        [LOCALES.EN]: 'MMM D, YYYY (LT)',
        [LOCALES.RU]: 'D MMM YYYY (LT)',
        [LOCALES.UK]: 'D MMM YYYY (LT)',
        [LOCALES.ES]: 'MMM D, YYYY (LT)',
        [LOCALES.PT]: 'MMM D, YYYY (LT)',
        [LOCALES.FR]: 'MMM D, YYYY (LT)',
        [LOCALES.DE]: 'MMM D, YYYY (LT)',
        [LOCALES.IT]: 'MMM D, YYYY (LT)',
        [LOCALES.NL]: 'MMM D, YYYY (LT)',
        [LOCALES.PL]: 'MMM D, YYYY (LT)',
        [LOCALES.ID]: 'MMM D, YYYY (LT)',
        [LOCALES.ID]: 'MMM D, YYYY (LT)',
        [LOCALES.TR]: 'MMM D, YYYY (LT)',
        [LOCALES.KO]: 'MMM D, YYYY (LT)',
        [LOCALES.NO]: 'MMM D, YYYY (LT)',
        [LOCALES.SV]: 'MMM D, YYYY (LT)',
        [LOCALES.JP]: 'MMM D, YYYY (LT)',
        [LOCALES.FI]: 'MMM D, YYYY (LT)',
        [LOCALES.LV]: 'MMM D, YYYY (LT)',
        [LOCALES.DA]: 'MMM D, YYYY (LT)',
        [LOCALES.RO]: 'MMM D, YYYY (LT)',
    },
    date: {
        [LOCALES.EN]: 'MMM D, YYYY',
        [LOCALES.RU]: 'D MMM YYYY',
        [LOCALES.UK]: 'D MMM YYYY',
        [LOCALES.ES]: 'MMM D, YYYY',
        [LOCALES.PT]: 'MMM D, YYYY',
        [LOCALES.FR]: 'MMM D, YYYY',
        [LOCALES.DE]: 'MMM D, YYYY',
        [LOCALES.IT]: 'MMM D, YYYY',
        [LOCALES.NL]: 'MMM D, YYYY',
        [LOCALES.PL]: 'MMM D, YYYY',
        [LOCALES.ID]: 'MMM D, YYYY',
        [LOCALES.TR]: 'MMM D, YYYY',
        [LOCALES.KO]: 'MMM D, YYYY',
        [LOCALES.NO]: 'MMM D, YYYY',
        [LOCALES.SV]: 'MMM D, YYYY',
        [LOCALES.JP]: 'MMM D, YYYY',
        [LOCALES.FI]: 'MMM D, YYYY',
        [LOCALES.LV]: 'MMM D, YYYY',
        [LOCALES.DA]: 'MMM D, YYYY',
        [LOCALES.RO]: 'MMM D, YYYY',
    },
    shortDate: {
        [LOCALES.EN]: 'MMM D',
        [LOCALES.RU]: 'D MMM',
        [LOCALES.UK]: 'D MMM',
        [LOCALES.ES]: 'MMM D',
        [LOCALES.PT]: 'MMM D',
        [LOCALES.FR]: 'MMM D',
        [LOCALES.DE]: 'MMM D',
        [LOCALES.IT]: 'MMM D',
        [LOCALES.NL]: 'MMM D',
        [LOCALES.PL]: 'MMM D',
        [LOCALES.ID]: 'MMM D',
        [LOCALES.TR]: 'MMM D',
        [LOCALES.KO]: 'MMM D',
        [LOCALES.NO]: 'MMM D',
        [LOCALES.SV]: 'MMM D',
        [LOCALES.JP]: 'MMM D',
        [LOCALES.FI]: 'MMM D',
        [LOCALES.LV]: 'MMM D',
        [LOCALES.DA]: 'MMM D',
        [LOCALES.RO]: 'MMM D',
    },
    shortCycle: {
        seconds: {
            [LOCALES.EN]: 'sec',
            [LOCALES.RU]: 'сек',
            [LOCALES.UK]: 'січ',
            [LOCALES.ES]: 'seg',
            [LOCALES.PT]: 'seg',
            [LOCALES.FR]: 'sec',
            [LOCALES.DE]: 'sek',
            [LOCALES.IT]: 'sec',
            [LOCALES.NL]: 'sec',
            [LOCALES.PL]: 'sek',
            [LOCALES.ID]: 'det',
            [LOCALES.TR]: 'sn',
            [LOCALES.KO]: '초',
            [LOCALES.NO]: 'sec',
            [LOCALES.SV]: 'sek',
            [LOCALES.JP]: '秒',
            [LOCALES.FI]: 'sek',
            [LOCALES.LV]: 'sek',
            [LOCALES.DA]: 'sek',
            [LOCALES.RO]: 'sec',
        },
        minutes: {
            [LOCALES.EN]: 'min',
            [LOCALES.RU]: 'мин',
            [LOCALES.UK]: 'хв',
            [LOCALES.ES]: 'min',
            [LOCALES.PT]: 'min',
            [LOCALES.FR]: 'min',
            [LOCALES.DE]: 'min',
            [LOCALES.IT]: 'min',
            [LOCALES.NL]: 'min',
            [LOCALES.PL]: 'min',
            [LOCALES.ID]: 'men',
            [LOCALES.TR]: 'dk',
            [LOCALES.KO]: '분',
            [LOCALES.NO]: 'min',
            [LOCALES.SV]: 'min',
            [LOCALES.JP]: '分',
            [LOCALES.FI]: 'min',
            [LOCALES.LV]: 'min',
            [LOCALES.DA]: 'min',
            [LOCALES.RO]: 'min',
        },
        hour: {
            [LOCALES.EN]: 'h',
            [LOCALES.RU]: 'ч',
            [LOCALES.UK]: 'г',
            [LOCALES.ES]: 'h',
            [LOCALES.PT]: 'h',
            [LOCALES.FR]: 'h',
            [LOCALES.DE]: 's',
            [LOCALES.IT]: 'o',
            [LOCALES.NL]: 'u',
            [LOCALES.PL]: 'g',
            [LOCALES.ID]: 'j',
            [LOCALES.TR]: 's',
            [LOCALES.KO]: '시간',
            [LOCALES.NO]: 't',
            [LOCALES.SV]: 't',
            [LOCALES.JP]: '時',
            [LOCALES.FI]: 't',
            [LOCALES.LV]: 'h',
            [LOCALES.DA]: 't',
            [LOCALES.RO]: 'o',
        },
        day: {
            [LOCALES.EN]: 'day',
            [LOCALES.RU]: 'дней',
            [LOCALES.UK]: 'день',
            [LOCALES.ES]: 'días',
            [LOCALES.PT]: 'dia',
            [LOCALES.FR]: 'jours',
            [LOCALES.DE]: 'tage',
            [LOCALES.IT]: 'giorni',
            [LOCALES.NL]: 'dag',
            [LOCALES.PL]: 'dzień',
            [LOCALES.ID]: 'hari',
            [LOCALES.TR]: 'gün',
            [LOCALES.KO]: '일',
            [LOCALES.NO]: 'dag',
            [LOCALES.SV]: 'dag',
            [LOCALES.JP]: '日',
            [LOCALES.FI]: 'päivä',
            [LOCALES.LV]: 'diena',
            [LOCALES.DA]: 'dag',
            [LOCALES.RO]: 'zi',
        },
        weeks: {
            [LOCALES.EN]: 'weeks',
            [LOCALES.RU]: 'недель',
            [LOCALES.UK]: 'тиждень',
            [LOCALES.ES]: 'semanas',
            [LOCALES.PT]: 'semanas',
            [LOCALES.FR]: 'semaines',
            [LOCALES.DE]: 'wochen',
            [LOCALES.IT]: 'settimane',
            [LOCALES.NL]: 'weken',
            [LOCALES.PL]: 'tygodnie',
            [LOCALES.ID]: 'minggu',
            [LOCALES.TR]: 'hafta',
            [LOCALES.KO]: '주',
            [LOCALES.NO]: 'uker',
            [LOCALES.SV]: 'veckor',
            [LOCALES.JP]: '週',
            [LOCALES.FI]: 'viikot',
            [LOCALES.LV]: 'nedēļas',
            [LOCALES.DA]: 'uger',
            [LOCALES.RO]: 'săptămâni',
        },
    },
}

export const getDateTime = (timestamp, formats = FORMATS.dateTime, lng = userLng) => {
    let format = typeof formats === 'string' ? formats : formats[lng]
    return moment(timestamp).locale(lng).format(format)
}

export const getDate = (timestamp, formats = FORMATS.date, lng = userLng) => {
    return getDateTime(timestamp, formats, lng)
}

export const getShortDate = (timestamp, formats = FORMATS.shortDate, lng = userLng) =>
    getDateTime(timestamp, formats, lng)

const hour = 60 * 60
const day = hour * 24
const month = day * 30
const year = month * 12

const hourStrRepr = 'HOUR'
const dayStrRepr = 'DAY'
const monthStrRepr = 'MONTH'
const yearStrRepr = 'YEAR'

export const getTime = data => (data && data.getTime()) || new Date.getTime()

export const getTimeStep = (fromDate, toDate, formatAsNumber = true) => {
    const from = moment(fromDate)
    const to = moment(toDate)
    const diff = to.diff(from, 'days')

    if (diff <= 1) return formatAsNumber ? hour : hourStrRepr
    if (diff < 125) return formatAsNumber ? day : dayStrRepr // 125 days
    if (diff < 125 * 30) return formatAsNumber ? month : monthStrRepr // 125 month
    if (diff < 125 * 30 * 12) return formatAsNumber ? year : yearStrRepr // 125 years
    return formatAsNumber ? Math.round((toDate - fromDate) / 6000) : yearStrRepr
}
