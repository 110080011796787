import React from 'react'
import { useTranslation } from 'react-i18next'
import { InView } from 'react-intersection-observer'

import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import ImageSelector from 'components/Form/ImageSelector/ImageSelector'

import ListActionBar from '../../../../../../../../Controls/ListActionBar/ListActionBar'

import { Card as CardModel } from '../../../../../../rankBattleService'
import { CARD_TYPES, MAX_CARD_TEXT_LENGTH, MAX_CARD_DESCRIPTION_LENGTH } from '../../../../../../constants'

import styles from './Card.module.scss'

const Card = ({
    errors,
    cardType,
    card,
    cardsCount,
    handleSectionVisible,
    index,
    onUpdateCard,
    onUpdateCardsPosition,
}) => {
    const { t } = useTranslation()

    return (
        <InView
            className={styles.section}
            as="li"
            key={card.id}
            onChange={handleSectionVisible}
            id={`item_${card.id}`}
            threshold={0.1}
        >
            <div className={styles.sectionHead}>
                <h2 className={styles.sectionTitle}>
                    {t('Card')} {index + 1}
                </h2>
                <ListActionBar
                    isDisabledAdd
                    isDisabledRemove
                    index={index}
                    length={cardsCount}
                    onChangePosition={onUpdateCardsPosition}
                />
            </div>
            <div className={styles.content}>
                {cardType === CARD_TYPES.IMAGE && (
                    <ImageSelector
                        borderRadius={4}
                        imageUrl={card[CardModel.imageUrl]}
                        onUpdate={v => onUpdateCard({ [CardModel.imageUrl]: v })}
                    />
                )}
                <div className={styles.mainContent}>
                    {cardType === CARD_TYPES.TEXT && (
                        <>
                            <TextInput
                                maxLength={MAX_CARD_TEXT_LENGTH}
                                className={styles.sectionNameControl}
                                label={t('Text')}
                                isRequired={cardType === CARD_TYPES.TEXT}
                                errorMessages={errors?.[CardModel.text]}
                                value={card[CardModel.text]}
                                onUpdate={v => onUpdateCard({ [CardModel.text]: v })}
                            />
                            <br />
                        </>
                    )}
                    <TextArea
                        maxLength={MAX_CARD_DESCRIPTION_LENGTH}
                        label={t('Description')}
                        errorMessages={errors?.[CardModel.description]}
                        value={card[CardModel.description]}
                        onUpdate={v => onUpdateCard({ [CardModel.description]: v })}
                    />
                </div>
            </div>
        </InView>
    )
}

export default Card
