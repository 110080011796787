import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'

import './NotificationDialog.scss'

const NotificationDialog = ({ open, title, description, closeOnEsc = true, closeOnOverlayClick = true, onClose }) => {
    const { t } = useTranslation()

    if (!open) return null

    return (
        <Modal closeOnEsc={closeOnEsc} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
            <div className="notification-dialog">
                <div className="notification-dialog__close" onClick={onClose} />
                <h2 className="notification-dialog__head">{title}</h2>
                {description && <div className="notification-dialog__note">{description}</div>}
                <div className="notification-dialog__buttons">
                    <button
                        className="notification-dialog__button button"
                        data-size="md"
                        data-variant="primary"
                        onClick={onClose}
                    >
                        {t('Ok')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default NotificationDialog
