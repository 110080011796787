import React from 'react'
import Tooltip from 'components/Tooltip/Tooltip'
import ColorPicker from 'components/Form/ColorPicker/ColorPicker'

const ColorPickerControl = ({ value, onUpdate, id, name, tooltipText, schema: { label = '' } }) => (
    <div className="control-box">
        <p className="label">
            {label}
            {<Tooltip tooltipText={tooltipText} />}
        </p>
        <ColorPicker value={value} onUpdate={v => onUpdate(id, { [name]: v })} />
    </div>
)

export default ColorPickerControl
