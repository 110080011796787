import { GAME_TYPES } from '../../../../../../../components/Modals/SlidingPuzzleModal/constants'

export const getTileStyles = ({
    gameType,
    tile,
    tileSize,
    fontSize,
    imageInfo,
    containerWidth,
    numberOfCells,
    position,
    isHighlightCorrect,
    isShowImageNumbers,
    tilesCount,
    correctColor,
    fontFamily,
    colorTheme,
    ceilBorderWidth,
    boxBorderWidth,
}) => {
    const wrapperStyles = {
        width: tileSize,
        height: tileSize,
        left: (tile.index % Math.sqrt(tilesCount)) * tileSize,
        top: Math.floor(tile.index / Math.sqrt(tilesCount)) * tileSize,
        border: `${ceilBorderWidth}px solid transparent`,
    }
    const innerStyles = {
        fontSize,
        fontFamily: `"${fontFamily}", sans-serif`,
        color: '#fff',
        textShadow: 'none',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
    }

    if (gameType === GAME_TYPES.NUMBERS) {
        if (isHighlightCorrect) {
            innerStyles.backgroundColor = tile.value === tile.index + 1 ? correctColor : colorTheme
        } else {
            innerStyles.backgroundColor = colorTheme
        }
    }

    if (gameType === GAME_TYPES.IMAGE) {
        if (!imageInfo) return {}
        innerStyles.backgroundImage = `url(${imageInfo.url})`
        if (isShowImageNumbers) {
            innerStyles.fontSize = fontSize / 2
            innerStyles.textShadow = '1px 1px 3px #3c3c3c'
        } else {
            innerStyles.fontSize = 0
        }

        if (isHighlightCorrect && tile.value === tile.index + 1) {
            if (isShowImageNumbers) innerStyles.color = correctColor
            else innerStyles.boxShadow = `inset 0 0 4px 2px ${correctColor}`
        }

        const isHorizontal = imageInfo.height > imageInfo.width

        let bgOffsetX = 0,
            bgOffsetY = 0

        const imagePlaceWidth = containerWidth - boxBorderWidth * 2
        if (isHorizontal) {
            const ratio = imageInfo.width / imagePlaceWidth
            const bgWidth = imagePlaceWidth,
                bgHeight = imageInfo.height / ratio
            innerStyles.backgroundSize = `${bgWidth}px ${bgHeight}px`
            bgOffsetY = (bgHeight - bgWidth) / 2
        } else {
            const ratio = imageInfo.height / imagePlaceWidth
            const bgWidth = imageInfo.width / ratio,
                bgHeight = imagePlaceWidth
            innerStyles.backgroundSize = `${bgWidth}px ${bgHeight}px`
            bgOffsetX = (bgWidth - bgHeight) / 2
        }

        const bgPosX = -((imagePlaceWidth / numberOfCells) * position.x) - bgOffsetX
        const bgPosY = -((imagePlaceWidth / numberOfCells) * position.y) - bgOffsetY

        innerStyles.backgroundPosition = `${bgPosX}px ${bgPosY}px`
    }

    return {
        wrapperStyles,
        innerStyles,
    }
}
