import React from 'react'
import classNames from 'classnames'

import styles from './SideNav.module.scss'

const SideNav = ({ items, activeItemId, onChangeItem }) => (
    <div className={styles.sideNav}>
        <ul className={styles.itemsList}>
            {Object.values(items).map(item => (
                <li
                    key={item.id}
                    className={classNames(styles.item, {
                        [styles.isActiveItem]: activeItemId === item.id,
                    })}
                    onClick={() => onChangeItem(item.id)}
                >
                    {item.icon && (
                        <div className={styles.itemIcon}>
                            <img src={item.icon} alt="" />
                        </div>
                    )}
                    <p className={styles.itemLabel}>{item.label}</p>
                </li>
            ))}
        </ul>
    </div>
)

export default SideNav
