import React, { useContext, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import useHint from 'components/Hint/useHint'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import { getLabelByProcesses } from './utils'

import './TopBar.scss'

const HINT_PUBLISH_NAME = 'top-bar-publish-button'
const HINT_SAVE_NAME = 'top-bar-save-button'

const TopBar = ({ methods, history, data: { project, processes } }) => {
    const { t } = useTranslation()
    const publishRef = useRef()
    const saveRef = useRef()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useHint([
        {
            isShow: true,
            parentRef: publishRef,
            hintName: HINT_PUBLISH_NAME,
            text: (
                <p>
                    <Trans
                        i18nKey="To get a link and see the project live, click Publish. <colored>Don't worry, no one will see the project until you share it.</colored>"
                        components={{
                            colored: <span className="top-bar__hint-span-text" />,
                        }}
                    />
                </p>
            ),
            placement: 'bottom-end',
        },
        {
            isShow: true,
            parentRef: saveRef,
            hintName: HINT_SAVE_NAME,
            text: t(
                'Your work is automatically saved.\nNo additional actions needed. Use this button when you want to exit the Editor',
            ),
            placement: 'bottom-start',
        },
    ])

    return (
        <div className="top-bar">
            <div className="left">
                <button
                    ref={saveRef}
                    className={classNames('btn', 'btn--save', { 'is-disabled': processes.saving })}
                    data-test="exit-from-project"
                    onClick={async () => {
                        await methods.save()
                        history.push('/my-projects')
                    }}
                    title={t('Save & Exit')}
                >
                    {t('Save & Exit')}
                </button>
                <div className="name-box">
                    <span
                        className="status-dot"
                        data-enabled={!!getLabelByProcesses(processes).length}
                        title={getLabelByProcesses(processes)}
                    />
                    <input
                        type="text"
                        placeholder="Project name"
                        value={project.name}
                        maxLength={128}
                        onChange={evt => methods.changeProject('name', evt.target.value)}
                    />
                    <div className="notify">{getLabelByProcesses(processes)}</div>
                </div>
            </div>
            <ul className="center">
                <li>
                    <NavLink exact activeClassName="is-active" to={`/editor/${project.projectId}`}>
                        {t('Editor')}
                    </NavLink>
                </li>
                {!isMultiplayerGame && (
                    <li>
                        <NavLink exact activeClassName="is-active" to={`/editor/${project.projectId}/integrations`}>
                            {t('Integrations')}
                        </NavLink>
                    </li>
                )}
                <li>
                    <NavLink exact activeClassName="is-active" to={`/editor/${project.projectId}/sharing-options`}>
                        {t('Sharing options')}
                    </NavLink>
                </li>
            </ul>
            <div className="right">
                {!isMultiplayerGame && (
                    <NavLink
                        activeClassName="is-active"
                        className="btn btn--preview"
                        to={`/editor/${project.projectId}/preview`}
                        title={t('Preview')}
                    >
                        {t('Preview')}
                    </NavLink>
                )}
                <button
                    ref={publishRef}
                    className="btn btn--publish"
                    onClick={() => methods.publishProject()}
                    disabled={processes.publishing}
                    title={t('Publish')}
                >
                    {t('Publish')}
                </button>
            </div>
        </div>
    )
}

export default TopBar
