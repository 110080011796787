import React from 'react'
import { useTranslation } from 'react-i18next'

import cardIcon from './i/card.svg'
import yoomoneyIcon from './i/yoomoney.png'
import sbpIcon from './i/sbp.png'

import styles from './PaymentMethod.module.scss'

const ALTERNATIVE_PAYMENT_METHODS = {
    YOO_MONEY: 'yoo_money',
    SBP: 'sbp',
}

const getPaymentMethodIcon = method => {
    let icon = cardIcon
    if (method === ALTERNATIVE_PAYMENT_METHODS.YOO_MONEY) icon = yoomoneyIcon
    if (method === ALTERNATIVE_PAYMENT_METHODS.SBP) icon = sbpIcon
    return <img className={styles.icon} src={icon} />
}

const PaymentMethod = ({ method, digits }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.paymentMethod}>
            {method ? (
                <div className={styles.method}>
                    {getPaymentMethodIcon(method)}
                    {!!digits && <span className="subscriptions__state__item-value">**** {digits}</span>}
                </div>
            ) : (
                <p className="subscriptions__state__item-value">{t('N/A')}</p>
            )}
        </div>
    )
}

export default PaymentMethod
