import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import { isUrl } from 'utils/validator'

import WebhooksMappingBlock from '../WebhooksMappingBlock/WebhooksMappingBlock'

import icon from '../../i/webhooks_icon.png'

import styles from './WebhooksMappingView.module.scss'

function WebhooksMappingView({ list, onSendTestData, onRemove, onSwitchWebhook, onAddWebhook, onUpdateWebhookUrl }) {
    const { t } = useTranslation()
    const [url, setUrl] = useState('')
    const [error, setError] = useState([])

    const onAdd = () => {
        if (isUrl(url)) {
            onAddWebhook(url)
            setUrl('')
        } else {
            setError([t('Invalid format')])
        }
    }

    const changeValue = evt => {
        setUrl(evt)
        setError([])
    }

    return (
        <div className={styles.WebhooksMappingView}>
            <aside className={styles.aside}>
                <img src={icon} alt="webhooks" width={100} height={100} />
            </aside>
            <div className={styles.main}>
                <div className={styles.inputWrapper}>
                    <TextInput value={url} onUpdate={changeValue} label={t('Webhook URL')} errorMessages={error} />
                    <button
                        className={classNames(styles.button, 'button')}
                        data-variant="primary"
                        data-size="md"
                        disabled={!url}
                        onClick={onAdd}
                    >
                        {t('Add')}
                    </button>
                </div>
                <div className={styles.list}>
                    <h1 className={styles.tableName}>{t('Connected Webhooks')}</h1>
                    {list.map(item => (
                        <WebhooksMappingBlock
                            key={item.id}
                            item={item}
                            onRemove={onRemove}
                            onSwitchWebhook={onSwitchWebhook}
                            onSendTestData={onSendTestData}
                            onUpdateWebhookUrl={onUpdateWebhookUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WebhooksMappingView
