import React from 'react'

import styles from './Column.module.scss'

const Column = ({ data: { width, label }, children }) => (
    <div className={styles.column} style={{ width }}>
        <div className={styles.label}>{label}</div>
        <div className={styles.content}>{children}</div>
    </div>
)

export default Column
