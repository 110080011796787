import { InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PROJECT_STATUSES } from 'common/constants/projects'

import useRouter from 'hooks/useRouter'

import DropDownArrowIcon from './DropDownArrowIcon'

import styles from './ProjectStatusesFilter.module.scss'

export const FILTER_QUERY_PARAM_NAME = 'filter'
export const ALL_PROJECT_FILTER_ITEM = 'ALL'

function ProjectStatusesFilter({ selected, onChange }) {
    const { t } = useTranslation()
    const { queryParams, addQueryParam, removeQueryParam } = useRouter()

    useEffect(() => {
        const param = queryParams[FILTER_QUERY_PARAM_NAME]
        if (param && PROJECT_STATUSES[param]) {
            addQueryParam(FILTER_QUERY_PARAM_NAME, param)
            onChange(param)
            return
        }
        onChange(ALL_PROJECT_FILTER_ITEM)
    }, [])

    useEffect(() => {
        if (selected === ALL_PROJECT_FILTER_ITEM) removeQueryParam(FILTER_QUERY_PARAM_NAME)
        else addQueryParam(FILTER_QUERY_PARAM_NAME, selected)
    }, [selected])

    return (
        <div className={styles.filterRoot}>
            <InputLabel className={styles.label}>{t('Filter')}</InputLabel>
            <Select
                classes={{
                    select: styles.dropdown,
                }}
                variant="standard"
                value={selected}
                autoWidth
                disableUnderline
                IconComponent={props => <DropDownArrowIcon props={props} />}
                onChange={evt => onChange(evt.target.value, evt)}
            >
                <MenuItem value={ALL_PROJECT_FILTER_ITEM}>{t('All')}</MenuItem>
                <MenuItem value={PROJECT_STATUSES.DRAFT}>{t('Drafts')}</MenuItem>
                <MenuItem value={PROJECT_STATUSES.PUBLISHED}>{t('Live ')}</MenuItem>
                <MenuItem value={PROJECT_STATUSES.TEMPLATE}>{t('Templates')}</MenuItem>
            </Select>
        </div>
    )
}

export default ProjectStatusesFilter
