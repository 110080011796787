import React, { useRef, useContext } from 'react'
import classNames from 'classnames'

import useDoubleClick from 'hooks/useDoubleClick'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import EditBlockButton from './components/EditBlockButton/EditBlockButton'
import PreviewBlock from './components/PreviewBlock/PreviewBlock'

import styles from './ModalEditedBlock.module.scss'

const ModalEditedBlock = ({ onEdit = () => {}, className, children }) => {
    const blockRef = useRef(null)

    const { isMultiplayerGame } = useContext(ProjectContext)

    useDoubleClick(blockRef, { onDoubleClick: () => onEdit() })

    return (
        <div ref={blockRef} className={classNames(styles.modalEditedBlock, { [className]: !!className })}>
            <EditBlockButton onClick={onEdit} />
            {!isMultiplayerGame && <PreviewBlock />}
            <div className={styles.overlay} />
            <div className={styles.preview}>{children}</div>
        </div>
    )
}

export default ModalEditedBlock
