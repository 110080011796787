import React from 'react'
import invertColor from '../../../../../util/invertColor'
import { PuzzlePlaygroundPreviewTable } from './PuzzlePlaygroundPreviewTable'
import { DRAGZONE, DROPZONE } from '../utils'
import Image from 'components/Form/Image'
import './PuzzlePlaygroundPreview.scss'

export const PuzzlePlaygroundPreview = ({
    image,
    layout,
    height,
    isShowCover,
    coverHeader,
    coverButtonText,
    isModal,
    colorTheme,
}) => {
    const numberOfCells = layout.value
    const blockHeight = isModal ? height * 2 : height
    const blockWidth = isModal ? height : height * 2

    return (
        <div className="puzzle-block-horizontal" style={{ height: blockHeight, width: blockWidth }}>
            <div className="puzzle-block-horizontal__inline-block">
                <PuzzlePlaygroundPreviewTable
                    isModal={isModal}
                    numberOfCells={numberOfCells}
                    height={height}
                    zoneType={DRAGZONE}
                />
            </div>
            <div className="puzzle-block-horizontal__img-container">
                <Image className="puzzle-block-horizontal__img" src={image} />
                <div className="puzzle-block-horizontal__img-grid" style={{ height, width: height }}>
                    <PuzzlePlaygroundPreviewTable
                        isModal={isModal}
                        numberOfCells={numberOfCells}
                        height={height}
                        zoneType={DROPZONE}
                    />
                </div>
            </div>
            <div className="puzzle-block-horizontal__inline-block">
                <PuzzlePlaygroundPreviewTable
                    isModal={isModal}
                    numberOfCells={numberOfCells}
                    height={height}
                    zoneType={DRAGZONE}
                />
            </div>
            {isShowCover && (
                <div className="puzzle-playground-preview__cover-wrapper">
                    <div className={`puzzle-playground-preview__cover ${isModal ? 'is-modal' : ''}`}>
                        <div className="puzzle-playground-preview__cover-header">{coverHeader}</div>
                        <button
                            className="puzzle-playground-preview__cover-btn"
                            style={{
                                color: invertColor(colorTheme, true),
                                backgroundColor: colorTheme,
                            }}
                        >
                            {coverButtonText ? coverButtonText : ''}
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
