import React from 'react'
import { withTranslation } from 'react-i18next'
import { cloneDeep, isEqual } from 'lodash'

import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import Modal from 'components/Modal/Modal'
import StopPanel from 'components/Modal/components/StopPanel/StopPanel'

import QuestionView from './views/QuestionView/QuestionView'
import ResultView from './views/ResultView/ResultView'
import StartScreenView from './views/StartView/StartView'

import { PERSONALITY_TABS, PERSONALITY_TABS_ARRAY } from './utils/constants'
import { getNormalizedStructure, createNewProject, validateTabs } from './utils/utils'

import iconGirl from '../i/girl.svg'

import './PersonalityQuizModal.scss'

class PersonalityQuizModal extends React.Component {
    constructor(props) {
        super(props)

        const { data } = props
        let tmpStructure = cloneDeep(data.structure)
        if (Object.keys(tmpStructure).length === 0) {
            tmpStructure = createNewProject()
        }

        this.state = {
            tmpStructure: tmpStructure,
            tabsValidationResult: {
                isCurrentTab: false,
                errors: { cover: {}, questions: [], results: [] },
            },
            activeTab: PERSONALITY_TABS.startView,
            isShowClosingConfirmation: false,
            isShowStopPanel: false,
        }
    }

    onSave = async () => {
        const {
            methods: { save, closeModal },
            data: { id },
        } = this.props
        const { tmpStructure, tabsValidationResult } = this.state

        try {
            const validationResult = validateTabs(tmpStructure, tabsValidationResult)

            if (!validationResult.hasError) {
                validationResult.normalizedStructure._isValid = true

                await save({
                    [id]: {
                        data: {
                            struct: validationResult.normalizedStructure,
                        },
                    },
                })

                closeModal()
            } else {
                this.setState({
                    isShowStopPanel: validationResult.hasError,
                    tabsValidationResult: {
                        isCurrentTab: false,
                        errors: validationResult.errors,
                    },
                })
            }
        } catch (err) {
            console.error(err)
        }
    }

    onClose = () => {
        const {
            data,
            methods: { closeModal },
        } = this.props
        const { tmpStructure } = this.state
        const normalizedStructure = getNormalizedStructure(tmpStructure)

        if (isEqual(data.structure, normalizedStructure)) {
            closeModal()
        } else {
            this.setState({ isShowClosingConfirmation: true })
        }
    }

    onChangeTab = tabIndex => {
        try {
            const { tmpStructure, tabsValidationResult, activeTab } = this.state

            const validationResult = validateTabs(tmpStructure, tabsValidationResult, activeTab)

            if (validationResult.activeTabHasError) {
                this.setState({
                    isShowStopPanel: true,
                    tabsValidationResult: {
                        isCurrentTab: validationResult.activeTabHasError,
                        errors: validationResult.errors,
                    },
                })
            } else {
                this.setState({
                    activeTab: tabIndex,
                    tabsValidationResult: {
                        isCurrentTab: validationResult.activeTabHasError,
                        errors: validationResult.errors,
                    },
                })
            }
        } catch (err) {
            console.error(err)
        }
    }

    onUpdateStructure = (newFields, callback) => {
        this.setState(
            prevState => ({
                ...prevState,
                tmpStructure: {
                    ...prevState.tmpStructure,
                    ...newFields,
                },
            }),
            callback,
        )
    }

    onUpdateCover = (newFields, callback) => {
        this.setState(
            prevState => ({
                ...prevState,
                tmpStructure: {
                    ...prevState.tmpStructure,
                    cover: {
                        ...prevState.tmpStructure.cover,
                        ...newFields,
                    },
                },
            }),
            callback,
        )
    }

    render() {
        const { tmpStructure, activeTab, tabsValidationResult, isShowClosingConfirmation, isShowStopPanel } = this.state
        const {
            methods: { closeModal },
            data: { colorTheme, pages },
            t,
        } = this.props

        return (
            <Modal closeOnOverlayClick={false} closeOnEsc={false}>
                <Header title={t('Personality options')} onClose={this.onClose} />
                <Body
                    sideNav={{
                        items: PERSONALITY_TABS_ARRAY,
                        activeItemId: activeTab,
                        onChangeItem: this.onChangeTab,
                        analyticsBlockName: 'PERSONALITY_QUIZ',
                    }}
                    onSave={this.onSave}
                >
                    <div className="personality-modal-wrapper">
                        <div className="personality-modal-wrapper__body">
                            {activeTab === PERSONALITY_TABS.startView && (
                                <StartScreenView
                                    t={t}
                                    colorTheme={colorTheme}
                                    tmpStructure={tmpStructure}
                                    validationResults={tabsValidationResult.errors.cover}
                                    methods={{
                                        onUpdateCover: this.onUpdateCover,
                                    }}
                                />
                            )}
                            {activeTab === PERSONALITY_TABS.questionList && (
                                <QuestionView
                                    t={t}
                                    tmpStructure={tmpStructure}
                                    validationResults={tabsValidationResult.errors.questions}
                                    methods={{
                                        onUpdateStructure: this.onUpdateStructure,
                                    }}
                                />
                            )}
                            {activeTab === PERSONALITY_TABS.resultList && (
                                <ResultView
                                    t={t}
                                    pages={pages}
                                    tmpStructure={tmpStructure}
                                    validationResults={tabsValidationResult.errors.results}
                                    methods={{
                                        onUpdateStructure: this.onUpdateStructure,
                                    }}
                                />
                            )}
                        </div>
                        {isShowStopPanel && (
                            <StopPanel
                                t={t}
                                headText={
                                    tabsValidationResult.isCurrentTab ? t('Oh! Need more information') : t('Oh, no!')
                                }
                                descriptionText={
                                    tabsValidationResult.isCurrentTab
                                        ? t(
                                              'Please fill all required fields on this tab for the quiz to work correctly.',
                                          )
                                        : t('Please fill all required fields for the quiz to work correctly.')
                                }
                                image={iconGirl}
                                onBack={() => this.setState({ isShowStopPanel: false })}
                                isShowQuit={false}
                            />
                        )}
                        {isShowClosingConfirmation && (
                            <StopPanel
                                t={t}
                                onBack={() => this.setState({ isShowClosingConfirmation: false })}
                                onQuit={closeModal}
                            />
                        )}
                    </div>
                </Body>
            </Modal>
        )
    }
}

export default withTranslation('translations')(PersonalityQuizModal)
