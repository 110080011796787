import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast.jsx'
import { API__ORGANIZATIONS } from 'api'

import './DecisionOrganizationInviteModal.scss'
import { ORGANIZATIONS_REFRESH } from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

const DecisionOrganizationInviteModal = ({ data, onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { userDetails } = useSelector(state => ({ userDetails: state.user_details }))

    const onDecline = () => {
        API__ORGANIZATIONS.DECLINE_ORGANIZATION_INVITE(data.id, userDetails.id)
            .then(() => {
                dispatch(ORGANIZATIONS_REFRESH())
                onClose()
            })
            .catch(error => {
                Toast('error', { message: t('Sorry, looks like there was some error. Please, try again later.') })
                console.error(error)
            })
    }

    const onApprove = () => {
        API__ORGANIZATIONS.APPROVE_ORGANIZATION_INVITE(data.id, userDetails.id)
            .then(() => {
                dispatch(ORGANIZATIONS_REFRESH())
                onClose()
            })
            .catch(error => {
                Toast('error', { message: t('Sorry, looks like there was some error. Please, try again later.') })
                console.error(error)
            })
    }
    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose}>
            <div className="organization-invite-modal">
                <h1 className="organization-invite-modal__title">{t('You are invited to the team')}</h1>
                <p className="organization-invite-modal__description"></p>
                <div className="organization-invite-modal__org-logo">
                    {data.logoURL ? <img src={data.logoURL} /> : data.name.charAt(0).toUpperCase()}
                </div>
                <div className="organization-invite-modal__org-name">{data.name}</div>

                <div className="organization-invite-modal__buttons">
                    <button className="button" data-size="lg" data-variant="secondary" onClick={onDecline}>
                        {t('Reject')}
                    </button>
                    <button className="button" data-size="lg" data-variant="primary" onClick={onApprove}>
                        {t('Accept')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default DecisionOrganizationInviteModal
