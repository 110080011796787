import { cloneDeep } from 'lodash'

export const mapWheelStatistic = (statistic, blockProjection) => {
    if (statistic.blockId !== blockProjection.blockId) {
        return null
    }

    const mappedProjection = cloneDeep(blockProjection.metadata)

    mappedProjection.sections = mappedProjection.sections.map(section => {
        let resultStat = statistic.wheelSections.find(sectionStat => sectionStat.sectionId === section.id)
        if (!resultStat) {
            resultStat = {
                percent: 0,
                users: 0,
            }
        }
        return {
            ...section,
            ...resultStat,
        }
    })

    return mappedProjection
}
