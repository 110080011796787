import React from 'react'

import styles from './Item.module.scss'

const Item = ({ item, fontFamily }) => (
    <li id={item.id} className={styles.item} style={{ fontFamily }}>
        <div className={styles.position}>
            {item.position < 10 ? (
                <>
                    <span>0</span>
                    {item.position}
                </>
            ) : (
                item.position
            )}
            .&nbsp;
        </div>
        {item.question}
    </li>
)

export default Item
