import React from 'react'
import { toast } from 'react-toastify'

import i18n from 'i18n'

import './Toast.scss'

import defaultIconSuccess from './i/success.svg'
import defaultIconError from './i/error.svg'
import defaultIconInfo from './i/info.svg'

const Body = ({ title, message, icon, IconComponent = () => null }) => (
    <div className={`toast-box`}>
        {(!!icon || !!IconComponent) && (
            <div className="toast-box__icon">
                <IconComponent />
                {!!icon && <img src={icon} alt="" />}
            </div>
        )}
        {
            <div className={`toast-box__content${!message ? ' toast-box__content--centered' : ''}`}>
                {title && (
                    <div className={`toast-box__title${message ? ' toast-box__title--with-offset' : ''}`}>{title}</div>
                )}
                {message && <div className="toast-box__message">{message}</div>}
            </div>
        }
    </div>
)

function Toast(type = 'success', { title, message, time = 4000, icon, IconComponent = null } = {}) {
    const allowedTypes = ['success', 'error', 'info']

    if (allowedTypes.indexOf(type) !== -1) {
        // Overloading
        switch (type) {
            case 'success': {
                title = title || i18n.t('Success!')
                icon = icon || defaultIconSuccess
                break
            }
            case 'info': {
                title = title || i18n.t('Information')
                icon = icon || defaultIconInfo
                break
            }
            case 'error': {
                title = title || i18n.t('Failed...')
                message = message || i18n.t('Looks like there was some kind of mistake. Please try again later')
                icon = icon || defaultIconError
                break
            }
            default:
                break
        }

        return toast[type](
            <Body
                title={title}
                message={message}
                icon={IconComponent ? null : icon}
                IconComponent={() => IconComponent}
            />,
            {
                autoClose: time,
                position: 'top-right',
                pauseOnHover: true,
                hideProgressBar: false,
                className: 'custom-toast-1',
                bodyClassName: 'toast-body',
                progressClassName: 'toast-progress',
            },
        )
    } else {
        console.warn(`Invalid toast type. Received: "${type}", allowed: "${allowedTypes.join(', ')}"`)
    }
}

export const dismiss = toastId => toast.dismiss(toastId)

export default Toast
