import { MAIN_SERVER } from './instance'

export const GET_USERS_PROJECTS_ROLES = async () =>
    MAIN_SERVER({
        method: 'get',
        url: `users/roles-in-project`,
    })

export const GET_CLIENT_COUNTRY = async () =>
    MAIN_SERVER({
        method: 'get',
        url: `users/country-code`,
    })
