import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { getDateTime } from '../../utils/dateTime.js'

export const getJSONFromExcel = async file => {
    const workbook = await new ExcelJS.Workbook().xlsx.load(file)
    const worksheet = workbook.getWorksheet(1)
    const headers = worksheet.getRow(1).values
    const rows = worksheet.getRows(2, worksheet.lastRow.number)

    const json = {}

    rows.forEach(row => {
        const values = row.values
        if (values.length) {
            json[values[1]] = {}
            for (let i = 2; i < headers.length; i++) {
                if (values[i] !== undefined) {
                    json[values[1]] = { ...json[values[1]], [headers[i]]: values[i] }
                }
            }
        }
    })

    return json
}

export const getExcelFromJSON = json => {
    if (json) {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet()

        const jsonAsArray = Object.entries(json)
        const columns = jsonAsArray.reduce((acc, [key, values]) => {
            Object.keys(values).forEach(keyLocale => {
                if (!acc[keyLocale]) {
                    acc[keyLocale] = {
                        key: keyLocale,
                        header: keyLocale,
                        width: 20,
                        style: { alignment: { wrapText: true } },
                    }
                }
            })
            return acc
        }, {})

        worksheet.columns = [
            {
                key: 'key',
                header: 'key',
                width: 20,
                style: { alignment: { wrapText: true } },
            },
            ...Object.values(columns),
        ]

        jsonAsArray.forEach(([key, values]) => {
            worksheet.addRow({
                key,
                ...values,
            })
        })

        workbook.xlsx.writeBuffer().then(buf => {
            saveAs(new Blob([buf]), `ProjectTexts${getDateTime()}.xlsx`)
        })
    } else {
        console.error('json is undefined')
    }
}
