import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import COOKIES from 'common/constants/cookies'

import { getCookie, setCookie } from 'utils/cookies'
import { getFullUrlToSsr } from 'utils/router'

import cookieImage from './i/cookie.png'

import styles from './CookiesBanner.module.scss'

const CookiesBanner = () => {
    const { t } = useTranslation()
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (getCookie(COOKIES.ACCEPT_COOKIES)) return
        setIsVisible(true)
    }, [])

    if (!isVisible) return null

    const onAccept = () => {
        setCookie(COOKIES.ACCEPT_COOKIES, true)
        setIsVisible(false)
    }

    return (
        <div className={styles.cookiesBanner}>
            <div className={styles.closeCross} onClick={() => onAccept()}></div>
            <div className={styles.imageSide}>
                <img src={cookieImage} alt="" />
            </div>
            <div className={styles.textSide}>
                <p className={styles.text}>
                    <Trans
                        i18nKey='We use cookies to optimize your experience of this website. By clicking "Accept cookies" or using our site, you consent to cookies. For further information, please view our <cookieTag>Cookie Policy</cookieTag> and <privacyTag>Privacy Policy</privacyTag>.'
                        components={{
                            cookieTag: <a href={getFullUrlToSsr('/cookie-policy')} target="_blank" />,
                            privacyTag: (
                                <a href={getFullUrlToSsr('/privacy-policy')} target="_blank">
                                    {t('Privacy Policy')}
                                </a>
                            ),
                        }}
                    />
                </p>
                <button className="button" data-variant="primary" data-size="md" onClick={() => onAccept()}>
                    {t('Accept cookies')}
                </button>
            </div>
        </div>
    )
}

export default CookiesBanner
