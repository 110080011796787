import React from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_IMAGE_URL } from 'common/constants/constants'
import { imgLoader } from 'common/services/imageLoader'
import { useAssetManager } from 'components/AssetManager'
import { ASSET_TABS } from 'components/AssetManager/utils/constants'
import { TooltipWrapper } from 'components/Tooltip/Tooltip'

import invertColor from '../../../util/invertColor'
import { CARD_TYPES } from '../Modals/MemoryCardsModal/utils/constants'

import { getClassSizeCardText } from './utils'
import { ReactComponent as TextIcon } from './i/text_icon.svg'
import { ReactComponent as ImageIcon } from './i/camera.svg'
import { ReactComponent as AudioIcon } from './i/musical_note.svg'
import audioFile from '../../../i/audio_file.svg'
import { IconPlay, IconPause } from 'svg'

import './PairCard.scss'

const ASSET_MANAGER_SETTINGS = {
    tab: ASSET_TABS.TAB_AUDIO,
    managerTabs: [ASSET_TABS.TAB_AUDIO, ASSET_TABS.TAB_FREE_AUDIO],
}

const PairCard = ({
    card,
    onTextIconClick,
    onImageSelect,
    onAudioSelect,
    onPlayAudio,
    playedElementId,
    isAudioPlay,
    styles,
    colorTheme = '#69B1FC',
}) => {
    const { t } = useTranslation()
    const { chooseAsset } = useAssetManager(true)

    const cardStyles = { ...styles }

    if (card.cardType === CARD_TYPES.text) {
        cardStyles.color = invertColor(card.bgColor, true)
        cardStyles.backgroundColor = card.bgColor
    }
    if (card.cardType === CARD_TYPES.image) {
        const imageUrl = card.src ? imgLoader(card.src) : DEFAULT_IMAGE_URL
        cardStyles.backgroundImage = `url(${imageUrl})`
    }
    if (card.cardType === CARD_TYPES.audio) {
        cardStyles.backgroundImage = `url("${audioFile}")`
        cardStyles.backgroundPosition = 'center'
        cardStyles.backgroundSize = '70%'
        cardStyles.backgroundRepeat = 'no-repeat'
        cardStyles.backgroundColor = colorTheme
    }

    return (
        <div className="modal-pairs__card" style={cardStyles}>
            {card.cardType === CARD_TYPES.text && (
                <p className={`modal-pairs__card-text ${getClassSizeCardText(card.text.length)}`}>{card.text}</p>
            )}
            {card.cardType === CARD_TYPES.audio && (
                <div className="modal-pairs__card-audio" onClick={() => onPlayAudio(card.src, card.id)}>
                    {isAudioPlay && playedElementId === card.id ? <IconPause /> : <IconPlay />}
                </div>
            )}
            <div className="modal-pairs__card-actions">
                <TooltipWrapper tooltipText={t('Add text')} position="bottom" mouseEnterDelay="0.5">
                    <div className="modal-pairs__svg modal-pairs__text-svg" onClick={onTextIconClick}>
                        <TextIcon />
                    </div>
                </TooltipWrapper>
                <TooltipWrapper tooltipText={t('Add image')} position="bottom" mouseEnterDelay="0.5">
                    <div
                        className="modal-pairs__svg modal-pairs__camera-svg"
                        onClick={() => chooseAsset(card.src, onImageSelect)}
                    >
                        <ImageIcon />
                    </div>
                </TooltipWrapper>
                <TooltipWrapper tooltipText={t('Add audio')} position="bottom" mouseEnterDelay="0.5">
                    <div
                        className="modal-pairs__svg modal-pairs__audio-svg"
                        onClick={() => chooseAsset(card.src, onAudioSelect, ASSET_MANAGER_SETTINGS)}
                    >
                        <AudioIcon />
                    </div>
                </TooltipWrapper>
            </div>
        </div>
    )
}

export default PairCard
