import React from 'react'
import ReactResizeDetector from 'react-resize-detector'

import {
    DEFAULT_IMAGE_BG_BIG_URL,
    DEFAULT_IMAGE_BG_BIG_BRIGHT_URL,
} from '../../../../../../common/constants/constants.js'
import iconThenNowDelimiter from '../../i/then_now_delimiter.svg'
import { imgLoader } from 'common/services/imageLoader'

/**
 * Then/Now
 */

class ThenNow extends React.Component {
    constructor(props) {
        super(props)
        this.state = { width: 800, position: 50, opacity: 1 }
        this.containerRef = React.createRef()
    }

    onResize__container = width => {
        this.setState({ width, position: 50 })
    }

    setDelimiterPosition = position => {
        if (position < 0) position = 0
        else if (position > 100) position = 100
        this.setState({ position })
    }

    mouseDownEventHandler = event => {
        const isTouch = !!event.touches
        const clientX = isTouch ? event.touches[0].clientX : event.clientX
        const container = this.containerRef.current
        const position = Math.round(
            ((clientX - container.getBoundingClientRect().left) / container.getBoundingClientRect().width) * 100,
        )
        this.setDelimiterPosition(position)
        this.setState({ opacity: 0.7 })

        const onMove = moveEvent => {
            if (!isTouch) moveEvent.preventDefault()
            const _clientX = isTouch ? moveEvent.touches[0].clientX : moveEvent.clientX
            const _position = Math.round(
                ((_clientX - container.getBoundingClientRect().left) / container.getBoundingClientRect().width) * 100,
            )
            this.setDelimiterPosition(_position)
        }

        const onUp = () => {
            this.setState({ opacity: 1 })
            document.removeEventListener(isTouch ? 'touchmove' : 'mousemove', onMove)
            document.removeEventListener(isTouch ? 'touchend' : 'mouseup', onUp)
        }

        document.addEventListener(isTouch ? 'touchmove' : 'mousemove', onMove)
        document.addEventListener(isTouch ? 'touchend' : 'mouseup', onUp)
    }

    render() {
        const { leftSrc, rightSrc, imageProportions } = this.props
        const { width, position, opacity } = this.state
        const imgPrp = imageProportions.value.split('|')
        const height = Math.round((width * +imgPrp[1]) / +imgPrp[0]) + 'px'

        return (
            <div
                className="then-now-block"
                style={{ height }}
                ref={this.containerRef}
                onMouseDown={this.mouseDownEventHandler}
                onTouchStart={this.mouseDownEventHandler}
            >
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={0}
                    handleWidth
                    onResize={this.onResize__container}
                />
                <div
                    className="then-now-block__img"
                    style={{
                        backgroundImage: leftSrc ? `url(${imgLoader(leftSrc)})` : `url(${DEFAULT_IMAGE_BG_BIG_URL})`,
                    }}
                />
                <div className="then-now-block__img-container">
                    <div
                        className="then-now-block__img-inner-container"
                        style={{
                            left: `${position}%`,
                            overflow: 'hidden',
                        }}
                    >
                        <div className="then-now-block__img-inner-container" style={{ left: `-${position}%` }}>
                            <div
                                className="then-now-block__img"
                                style={{
                                    backgroundImage: rightSrc
                                        ? `url(${imgLoader(rightSrc)})`
                                        : `url(${DEFAULT_IMAGE_BG_BIG_BRIGHT_URL})`,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="then-now-block__delimiter" style={{ left: `${position}%`, opacity }}>
                    <img
                        src={iconThenNowDelimiter}
                        onDragStart={event => {
                            event.preventDefault()
                            return false
                        }}
                        alt=""
                        style={{ opacity }}
                    />
                </div>
            </div>
        )
    }
}

export default ThenNow
