export const isYearlySubscription = subscription => {
    if (!subscription) return false
    if (subscription.product.billingCycleType !== 'MONTH') return false
    if (subscription.product.billingCycleValue !== 12) return false
    return true
}

export const isMonthlySubscription = subscription => {
    if (!subscription) return false
    if (subscription.product.billingCycleType !== 'MONTH') return false
    if (subscription.product.billingCycleValue >= 12) return false
    return true
}
