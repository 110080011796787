import React from 'react'
import classNames from 'classnames'
import { Trans } from 'react-i18next'

import Loader from 'components/Loader/Loader'

import BankItem from './components/BankItem/BankItem'

import styles from './BanksList.module.scss'

const BanksList = ({ list, onOpen, onDelete, isLoadingList, onUpload, onOpenNew, isPublic }) => {
    if (!list.length) {
        if (isLoadingList) return <Loader className={styles.loader} />
        if (!isLoadingList)
            return (
                <div className={styles.note}>
                    <Trans i18nKey="You haven't uploaded any questions banks yet." />
                    &nbsp;
                    <Trans
                        i18nKey="To add a new question bank, <lnkUpload>upload CSV / XLSX file</lnkUpload> or <lnkCreate>create from scratch</lnkCreate>."
                        components={{
                            lnkUpload: <span onClick={() => onUpload()} />,
                            lnkCreate: <span onClick={() => onOpenNew()} />,
                        }}
                    />
                </div>
            )
    }

    return (
        <div>
            <ul className={classNames(styles.banksList, { [styles.isLoadingBanksList]: isLoadingList })}>
                {list.map(bank => (
                    <BankItem key={bank.bankId} bank={bank} onDelete={onDelete} onOpen={onOpen} isPublic={isPublic} />
                ))}
            </ul>
            {!isPublic && (
                <div className={styles.note}>
                    <br />
                    <Trans
                        i18nKey="To add a new question bank, <lnk>upload CSV / XLSX file</lnk>."
                        components={{
                            lnk: <span onClick={() => onUpload()} />,
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default BanksList
