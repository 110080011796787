import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import styles from './InlineInput.module.scss'

const InlineInput = ({
    value,
    className,
    inputClassName,
    maxLength = 200,
    isEditable = false,
    isLoading = false,
    onSave = () => {},
}) => {
    const [_value, _setValue] = useState(() => value || '')

    const inputRef = useRef()

    useEffect(() => {
        if (isEditable) inputRef.current.select()
    }, [isEditable])

    const _onSave = () => {
        if (isEditable) {
            const v = _value.trim()
            _setValue(v)
            onSave(v)
        }
    }

    return (
        <div
            className={classNames({
                [styles.inlineInputWrapper]: true,
                [className]: !!className,
            })}
        >
            <input
                ref={inputRef}
                className={classNames({
                    [styles.inlineInput]: true,
                    [inputClassName]: !!inputClassName,
                    [styles.isLoading]: isLoading,
                })}
                readOnly={!isEditable}
                maxLength={maxLength}
                onKeyDown={({ key }) => {
                    if (key === 'Enter') _onSave()
                }}
                type="text"
                value={_value}
                title={_value}
                onBlur={_onSave}
                onChange={({ target }) => _setValue(target.value)}
                onClick={evt => evt.stopPropagation()}
            />
        </div>
    )
}

export default InlineInput
