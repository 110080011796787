import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Image from 'components/Form/Image.jsx'

import popupMch from '../i/popup-mch.png'
import loginMch from '../i/login-mch.png'
import mappingMch from '../i/mapping-mch.png'

const MailchimpBlock = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className="content__title">{t('Collecting leads with Mailchimp')}</div>

            <div className="content__sub-title">
                {t('To transfer data to your Mailchimp account, follow these steps:')}
            </div>
            <div className="content__sub-title">
                <p>1. {t('Open your project in the editor')}</p>
                <p>
                    2.{' '}
                    {t('Select a block with the ability to collect data (for example, a lead form) and click "Edit"')}
                </p>
            </div>
            <Image className="content__image" src={popupMch} />
            <div className="content__sub-title">
                <p>3. {t('Click on the "Set up" button next to the Mailchimp logo')}</p>
                <p>4. {t('Log in to Mailchimp')}</p>
            </div>
            <Image className="content__image" src={loginMch} />
            <div className="content__sub-title">5. {t('Perform field mapping')}</div>
            <Image className="content__image" src={mappingMch} />
            <div className="content__sub-title">{t('Everything is ready for data collection!')}</div>
            <div className="content__sub-title">
                <Trans
                    i18nKey="For more information click <lnk>here</lnk>"
                    components={{
                        lnk: (
                            <a
                                target="_blank"
                                href={'https://help.interacty.me/content/how-to-collecting-leads-with-mailchimp'}
                            />
                        ),
                    }}
                />
            </div>
        </>
    )
}

export default MailchimpBlock
