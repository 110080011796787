import React from 'react'

import styles from './Info.module.scss'

const Info = ({ title, linkText, linkUrl }) => (
    <div className={styles.info}>
        <p className={styles.title}>{title}</p>
        {!!linkText && !!linkUrl && (
            <a className={styles.link} href={linkUrl} target="_blank">
                {linkText}
            </a>
        )}
    </div>
)

export default Info
