import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { REASONS } from './constants'

import styles from './Reason.module.scss'

const Reason = ({ onClose, onSubmit }) => {
    const { t } = useTranslation()

    const [reason, setReason] = useState(null)
    const [comment, setComment] = useState('')

    useEffect(() => {
        setComment('')
    }, [reason])

    return (
        <div className={styles.reason}>
            <div className={styles.box}>
                <div className={styles.text}>
                    <div className={styles.title}>{t('Help us become better')}</div>
                    <div className={styles.description}>
                        {t(
                            'At Interacty, we want to hear about your experience and why you decided to disable auto-renewal. Your feedback will help us improve our service.',
                        )}
                    </div>
                </div>
                <ul className={styles.list}>
                    {REASONS.map(item => (
                        <li
                            key={item.id}
                            onClick={() => setReason(item)}
                            className={classNames(styles.item, {
                                [styles.isActiveItem]: reason && reason.id === item.id,
                            })}
                        >
                            <div className={styles.option}>
                                <div className={styles.itemRound} />
                                <p className={styles.itemLabel}>{item.label}</p>
                            </div>
                            {reason && reason.id === item.id && (
                                <div className={styles.commentBox}>
                                    <textarea
                                        placeholder={t(
                                            'Please write more about the reason for cancellation, it will help make our service better',
                                        )}
                                        maxLength={1000}
                                        value={comment}
                                        onChange={evt => setComment(evt.target.value)}
                                        className={styles.textarea}
                                        rows={3}
                                    />
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.buttons}>
                <button
                    className={classNames('button', styles.button)}
                    data-size="md"
                    data-variant="secondary"
                    onClick={() => onClose()}
                >
                    {t('Do not cancel')}
                </button>
                <button
                    disabled={!reason}
                    className={classNames('button', styles.button)}
                    data-size="md"
                    data-variant="primary-danger"
                    onClick={() => onSubmit({ reason, comment })}
                >
                    {t('Disable auto-renewal')}
                </button>
            </div>
        </div>
    )
}

export default Reason
