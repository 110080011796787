import React from 'react'

import Modal from '../Modal'

import defaultImage from './i/woman.svg'

import styles from './BaseDialog.module.scss'

const BaseDialog = ({ title, note, image = defaultImage, onClose, bottomText, children }) => (
    <Modal onClose={onClose} isShowCloseIcon={true}>
        <div className={styles.baseDialog}>
            <h4 className={styles.title}>{title}</h4>
            {!!note && <p className={styles.note}>{note}</p>}
            {!!image && (
                <div className={styles.image}>
                    <img src={image} alt="image" />
                </div>
            )}
            <div className={styles.content}>{children}</div>
            {!!bottomText && <div className={styles.bottomText}>{bottomText}</div>}
        </div>
    </Modal>
)

export default BaseDialog
