import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import classNames from 'classnames'

import { COST } from '../../constants'

import MainInfo from './components/MainInfo/MainInfo'
import PricesList from './components/PricesList/PricesList'
import PriceItem from './components/PriceItem/PriceItem'

import styles from './PriceSelector.module.scss'

const PriceSelector = ({ cost, onChangeTemplateCost, paidList }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.priceSelector}>
            <p className={styles.label}>{t('Price')}</p>
            <ul className={styles.list}>
                <li className={classNames(styles.item, { [styles.isSelectedItem]: cost === COST.FREE })}>
                    <MainInfo
                        title={
                            <Trans
                                i18nKey="I want to <bold>share</bold> my project as a template <bold>for free</bold>"
                                components={{
                                    bold: <span />,
                                }}
                            />
                        }
                        description={t(
                            'Teachers and students can use your project for educational purposes, as well as create their own projects based on your template.',
                        )}
                        onSelect={() => onChangeTemplateCost(COST.FREE)}
                        isSelected={cost === COST.FREE}
                    />
                </li>
                <li className={classNames(styles.item, { [styles.isSelectedItem]: cost === COST.PAID })}>
                    <MainInfo
                        title={
                            <Trans
                                i18nKey="I want to <bold>sell</bold> the project"
                                components={{
                                    bold: <span />,
                                }}
                            />
                        }
                        description={t(
                            'Teachers and students can open the project from the link and use it for educational purposes.',
                        )}
                        onSelect={() => onChangeTemplateCost(COST.PAID)}
                        isSelected={cost === COST.PAID}
                    />
                    <PricesList isDarken={cost === COST.FREE}>
                        {paidList.map(item => (
                            <PriceItem key={item.title} {...item} />
                        ))}
                    </PricesList>
                </li>
            </ul>
        </div>
    )
}

export default PriceSelector
