import React from 'react'
import { useTranslation } from 'react-i18next'

const TrialOverBanner = () => {
    const { t } = useTranslation()

    return (
        <div className="subscriptions__banner">
            <div className="subscriptions__banner__description">
                {t(
                    'Sorry, the trial period is over. Take a look at the packages to find out how you can keep creating engaging content!',
                )}
            </div>
        </div>
    )
}

export default TrialOverBanner
