import React from 'react'
import classNames from 'classnames'

import styles from './Sections.module.scss'

const Sections = ({ borderWidth, wheelSize, children }) => {
    return (
        <ul
            className={classNames(styles.wheelSections)}
            style={{
                borderWidth: borderWidth,
                width: wheelSize,
                height: wheelSize,
            }}
        >
            {children}
        </ul>
    )
}

export default Sections
