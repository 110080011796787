import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import styles from './GamePreview.module.scss'

const GamePreview = ({ width = 340, className, children }) => {
    const { t } = useTranslation()

    return (
        <ScrollableBlock className={styles.gamePreview} styles={{ width }}>
            <div className={classNames(styles.main, { [className]: !!className })}>
                <h4 className={styles.title}>{t('Approximate preview')}</h4>
                <div className={styles.content}>{children}</div>
            </div>
        </ScrollableBlock>
    )
}

export default GamePreview
