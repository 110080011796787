import React from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from 'components/Form/Checkbox/Checkbox'

import InputBox from '../../components/InputBox/InputBox'

import { ABC, MIN_ANSWERS_COUNT } from './constants'

import styles from './Answer.module.scss'

const Answer = ({
    index,
    data: { answer, correctAnswersIndexes, answersCount },
    onChangeText,
    onChangeCorrect,
    onRemove,
    error,
    isReadonly,
}) => {
    const { t } = useTranslation()

    return (
        <div className={styles.answer}>
            <Checkbox
                isDisabled={isReadonly}
                value={correctAnswersIndexes.includes(index)}
                className={styles.checkboxInput}
                onUpdate={v => {
                    if (v) onChangeCorrect([...correctAnswersIndexes, index])
                    else onChangeCorrect(correctAnswersIndexes.filter(item => item !== index))
                }}
            />
            <InputBox
                isReadonly={isReadonly}
                letter={`${ABC[index]}.`}
                value={answer}
                onChange={v => onChangeText(v)}
                isShowRemove={!isReadonly && answersCount > MIN_ANSWERS_COUNT}
                onRemove={() => onRemove()}
                removeTitle={t('Remove answer')}
                error={error}
            />
        </div>
    )
}

export default Answer
