import React from 'react'
import Toggle from 'components/Form/Toggle/Toggle'

import './PairsFeedbackToggle.scss'

const PairsFeedbackToggle = ({ label, isShowFeedback, onUpdate }) => (
    <div className="pairs-feedback-modal">
        <div className="pairs-feedback-modal__toggle">
            <Toggle label={label} value={isShowFeedback} onUpdate={value => onUpdate(value)} />
        </div>
        <div className="pairs-feedback-modal__preview">
            <div className="pairs-feedback-modal__preview-image back" />
            <div className="pairs-feedback-modal__preview-image front" />
        </div>
    </div>
)

export default PairsFeedbackToggle
