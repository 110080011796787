import store from 'store'

import { EDUCATION_GROUP_ID, PRODUCT_LIST_FREE, PRODUCT_LIST } from 'common/constants/ProductConstants'
import { SUBSCRIPTION_TYPES, FEATURE_TYPES } from 'common/constants/subscription'

export const CONDITIONS = {
    CAN_USE_CUSTOM_PROJECT_STYLES: 'CAN_USE_CUSTOM_PROJECT_STYLES',
    CAN_USE_GAMIFICATION: 'CAN_USE_GAMIFICATION',
    CAN_USE_SDK_INTEGRATION: 'CAN_USE_SDK_INTEGRATION',
    CAN_USE_LEAD_FORM_IN_BLOCKS: 'CAN_USE_LEAD_FORM_IN_BLOCKS',
    CAN_USE_FULL_STATISTICS: 'CAN_USE_FULL_STATISTICS',
    CAN_USE_PREMIUM_BLOCKS: 'CAN_USE_PREMIUM_BLOCKS',
    CAN_INVITE_IN_TEAM: 'CAN_INVITE_IN_TEAM',
    CAN_USE_CUSTOM_PROJECT_ID: 'CAN_USE_CUSTOM_PROJECT_ID',
    CAN_USE_INTEGRATIONS: 'CAN_USE_INTEGRATIONS',
    CAN_USE_GOOGLE_ANALYTICS: 'CAN_USE_GOOGLE_ANALYTICS',
    CAN_USE_CUSTOM_DOMAINS: 'CAN_USE_CUSTOM_DOMAINS',
    CAN_USE_WHITE_LABEL: 'CAN_USE_WHITE_LABEL',
}
export const isConstraintUnlimited = constraint => getConstraintLimit(constraint) === -1

const CONDITIONS_RULES = {
    [CONDITIONS.CAN_USE_CUSTOM_PROJECT_STYLES]: ({ featureList }) => featureList.includes('CUSTOM_CSS_STYLES'),
    [CONDITIONS.CAN_USE_GAMIFICATION]: ({ featureList }) => featureList.includes('GAMIFICATION'),
    [CONDITIONS.CAN_USE_SDK_INTEGRATION]: ({ featureList }) => featureList.includes('SDK_INTEGRATION'),
    [CONDITIONS.CAN_USE_LEAD_FORM_IN_BLOCKS]: ({ featureList }) => featureList.includes('LEAD_COLLECTION_FORM'),
    [CONDITIONS.CAN_USE_FULL_STATISTICS]: ({ featureList }) => featureList.includes('FULL_STATISTICS'),
    [CONDITIONS.CAN_USE_PREMIUM_BLOCKS]: ({ featureList }) => featureList.includes('PREMIUM_BLOCKS'),
    [CONDITIONS.CAN_USE_CUSTOM_PROJECT_ID]: ({ featureList }) => featureList.includes('CUSTOM_PROJECT_URL'),
    [CONDITIONS.CAN_USE_INTEGRATIONS]: ({ featureList }) => featureList.includes('INTEGRATIONS'),
    [CONDITIONS.CAN_USE_GOOGLE_ANALYTICS]: ({ featureList }) => featureList.includes('GOOGLE_ANALYTICS'),
    [CONDITIONS.CAN_USE_CUSTOM_DOMAINS]: ({ featureList }) => featureList.includes('CUSTOM_DOMAINS'),
    [CONDITIONS.CAN_USE_WHITE_LABEL]: ({ featureList }) => featureList.includes('NO_LOGO'),
    [CONDITIONS.CAN_INVITE_IN_TEAM]: ({ constraints, memberList }) =>
        constraints.find(
            constraint =>
                constraint.featureProduct.featureType === 'TEAM' &&
                (isConstraintUnlimited(constraint) || getConstraintLimit(constraint) > memberList.length - 1),
        ),
}

export const getConstraintLimit = constraint => {
    if (!constraint) return 0
    return constraint.customPlanLimit || constraint.planLimit
}

export const hasPermission = (conditions = []) => {
    const { organizations, projectSubscription } = store.getState()
    let result = true
    const subscriptionData = {
        productCode: organizations.selectedSubscription.product.productCode,
        featureList: organizations.selectedSubscription.product.featureProducts.map(el => el.featureType),
        constraints: organizations.selectedSubscription.constraints,
        memberList: organizations.memberList,
    }
    if (projectSubscription && !projectSubscription.expired) {
        subscriptionData.productCode = projectSubscription.product.productCode
        subscriptionData.featureList = projectSubscription.product.featureProducts.map(el => el.featureType)
    }

    for (const condition of Array.isArray(conditions) ? conditions : [conditions]) {
        if (!CONDITIONS.hasOwnProperty(condition)) {
            result = false
            break
        }

        const isAllowed = CONDITIONS_RULES[condition](subscriptionData)
        if (!isAllowed) {
            result = false
            break
        }
    }
    return result
}

export const isProjectLimitExceeded = overrideSubscription => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    const constraints = overrideSubscription ? overrideSubscription.constraints : selectedSubscription.constraints

    const constraint = constraints.find(
        constraint => constraint.featureProduct.featureType === FEATURE_TYPES.PROJECTS_COUNT,
    )

    if (constraint) {
        if (isConstraintUnlimited(constraint)) return false
        return constraint.spent >= getConstraintLimit(constraint)
    }
    return false
}

export const isOwner = () => {
    const {
        organizations: { organizationList, selectedOrganizationId },
        user_details,
    } = store.getState()
    const selectedOrganization = organizationList.find(org => org.id === selectedOrganizationId)
    return selectedOrganization?.ownerId === user_details?.id
}

export const isEducation = (isUseOrganizationSubscription = false) => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()
    if (isUseOrganizationSubscription) return selectedSubscription.product.group === EDUCATION_GROUP_ID

    const { user_details } = store.getState()
    if (user_details) {
        return user_details.roleInProjectDto
            ? user_details.roleInProjectDto.userGroupInProjectDto.customId === EDUCATION_GROUP_ID
            : false
    }
    return false
}

export const isTrialSubscription = () => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    return selectedSubscription && selectedSubscription.product.productCode === PRODUCT_LIST_FREE.Trial
}

export const isDeprecatedSubscription = () => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    return selectedSubscription && selectedSubscription.product.productCode === PRODUCT_LIST_FREE.Free
}

export const isSubscriptionActivated = () => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    return selectedSubscription && selectedSubscription.started !== null
}

export const isSubscriptionExpired = overrideSubscription => {
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    if (overrideSubscription && selectedSubscription)
        return overrideSubscription.expired && selectedSubscription.expired

    return selectedSubscription && selectedSubscription.expired
}

export const isFreeSubscription = () => {
    const {
        organizations: { selectedSubscription, projectSubscription },
    } = store.getState()

    const FREE_CODES = Object.values(PRODUCT_LIST_FREE)

    const isMainSubscriptionFree = selectedSubscription && FREE_CODES.includes(selectedSubscription.product.productCode)

    if (!isMainSubscriptionFree) return false

    const isHasProjectSubscription = projectSubscription && !projectSubscription.expired

    return !isHasProjectSubscription
}

export const isHasActiveProjectSubscription = () => {
    const { user_subscriptions } = store.getState()

    const projectSubscriptions = user_subscriptions.filter(
        sub => sub.type === SUBSCRIPTION_TYPES.PROJECT && !sub.expired,
    )

    return !!projectSubscriptions.length
}

export const isAppSumoUser = () => {
    const { user_details } = store.getState()
    if (user_details) return user_details.fromAppSumo
    return false
}

export const isAppSumoUserTear = tears => {
    if (!isAppSumoUser()) return false
    const {
        organizations: { selectedSubscription },
    } = store.getState()
    const splittedSubscriptionName = selectedSubscription.product.name.split(' ')
    return splittedSubscriptionName[1] && tears.includes(splittedSubscriptionName[1])
}

export const isProPlusOrBetter = () => {
    if (isEducation()) return false
    const {
        organizations: { selectedSubscription },
    } = store.getState()

    const isIncluded = [
        PRODUCT_LIST.ProfessionalPlus,
        PRODUCT_LIST.ProfessionalPlusYear,
        PRODUCT_LIST.Organizations,
        PRODUCT_LIST.OrganizationsYear,
        PRODUCT_LIST.Enterprise,
        PRODUCT_LIST.EnterpriseYear,
    ].includes(selectedSubscription.product.productCode)

    return isIncluded && !selectedSubscription.expired
}

export const isAppSumoUserOrBusinessSubscription = () => isAppSumoUser() || !isEducation(true)
