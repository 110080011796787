import React, { useState, useEffect } from 'react'

import useWindowResize from 'hooks/useWindowResize'

import { Item as ItemModel } from '../../../../../../components/Modals/CrosswordModal/crosswordService'
import { ORIENTATIONS } from '../../../../../../components/Modals/CrosswordModal/constants'

import { MIN_CEIL_SIZE_TO_ADAPTIVE, ADAPTIVE_GAP, MAX_CEIL_SIZE } from '../constants'

const useCrossword = (items, playgroundWidth) => {
    const [tilesContainerWidth, setTilesContainerWidth] = useState(0)
    const [borderWidth, setBorderWidth] = useState(0)
    const [hintFontSize, setHintFontSize] = useState(0)
    const [fontSize, setFontSize] = useState(0)

    const [colsCount, setColsCount] = useState(0)
    const [rowsCount, setRowsCount] = useState(0)
    const [inputtableTilesDataByIndex, setInputtableTilesDataByIndex] = useState({})

    const { height: screenHeight } = useWindowResize()

    useEffect(() => {
        const maxX = items
            .filter(item => item[ItemModel.orientation] === ORIENTATIONS.ACROSS)
            .reduce((prev, cur) => {
                const currentValue = cur[ItemModel.startX] + cur.answer.length - 1
                if (currentValue > prev) return currentValue
                return prev
            }, 1)
        const maxY = items
            .filter(item => item[ItemModel.orientation] === ORIENTATIONS.DOWN)
            .reduce((prev, cur) => {
                const currentValue = cur[ItemModel.startY] + cur.answer.length - 1
                if (currentValue > prev) return currentValue
                return prev
            }, 1)
        setColsCount(maxX)
        setRowsCount(maxY)

        const _inputtableTilesDataByIndex = {}
        const _tilesIndexesByItemsIndexes = {}
        items.forEach((item, itemIndex) => {
            const { answer, startX, startY, orientation, position } = item
            const answerLength = answer.length

            const startIndex = startX - 1 + maxX * (startY - 1)

            const answerArray = answer.split('')
            let answerIndex = 0

            const tilesIndexes = []

            const interate = index => {
                tilesIndexes.push(index)

                if (_inputtableTilesDataByIndex[index]) {
                    _inputtableTilesDataByIndex[index] = {
                        ..._inputtableTilesDataByIndex[index],
                        relatedItemsIndexes: [..._inputtableTilesDataByIndex[index].relatedItemsIndexes, itemIndex],
                    }
                } else {
                    _inputtableTilesDataByIndex[index] = {
                        value: '',
                        correctValue: answerArray[answerIndex].toUpperCase(),
                        isHighlight: false,
                        isFocused: false,
                        isCorrectWord: false,
                        hint: index === startIndex ? position : null,
                        relatedItemsIndexes: [itemIndex],
                    }
                }

                answerIndex++
            }

            if (orientation === ORIENTATIONS.ACROSS) {
                for (let i = startIndex; i < startIndex + answerLength; i++) {
                    interate(i)
                }
            }
            if (orientation === ORIENTATIONS.DOWN) {
                for (let i = startIndex; i <= startIndex + (answerLength - 1) * maxX; i += maxX) {
                    interate(i)
                }
            }

            _tilesIndexesByItemsIndexes[itemIndex] = tilesIndexes
        })

        setInputtableTilesDataByIndex(_inputtableTilesDataByIndex)
    }, [items])

    useEffect(() => {
        if (!playgroundWidth || !colsCount) return
        let _tilesContainerWidth = playgroundWidth
        let cellSize = playgroundWidth / colsCount
        if (cellSize > MAX_CEIL_SIZE) {
            cellSize = MAX_CEIL_SIZE
            _tilesContainerWidth = cellSize * colsCount
        }

        if (cellSize > MIN_CEIL_SIZE_TO_ADAPTIVE && screenHeight) {
            const totalHeight = rowsCount * cellSize + ADAPTIVE_GAP * 2
            if (totalHeight > screenHeight) {
                const diff = totalHeight - screenHeight
                cellSize = cellSize - diff / rowsCount
                _tilesContainerWidth = cellSize * colsCount
            }
        }

        let _borderWidth = 2,
            _hintFontSize = 12,
            _fontSize = 34

        if (cellSize < 74) {
            _fontSize = 28
        }
        if (cellSize < 66) {
            _fontSize = 24
        }
        if (cellSize < 58) {
            _fontSize = 20
        }
        if (cellSize < 52) {
            _hintFontSize = 10
            _fontSize = 16
        }
        if (cellSize < 42) {
            _borderWidth = 1
            _hintFontSize = 8
            _fontSize = 14
        }
        if (cellSize < 36) {
            _hintFontSize = 6
            _fontSize = 12
        }
        if (cellSize < 28) {
            _fontSize = 10
        }
        if (cellSize < 24) {
            _hintFontSize = 5
            _fontSize = 8
        }

        setTilesContainerWidth(_tilesContainerWidth)
        setBorderWidth(_borderWidth)
        setHintFontSize(_hintFontSize)
        setFontSize(_fontSize)
    }, [playgroundWidth, colsCount, screenHeight])

    return {
        tilesContainerWidth,
        borderWidth,
        fontSize,
        hintFontSize,

        colsCount,
        rowsCount,
        inputtableTilesDataByIndex,
    }
}

export default useCrossword
