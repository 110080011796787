import React from 'react'
import { CHART_CODES } from '../constants'

import Wrapper from './Wrapper/Wrapper'
import { msFormat } from './utils'

const AverageAttentionTime = ({ projectId, dateRange, t }) => (
    <Wrapper
        projectId={projectId}
        code={CHART_CODES.AVERAGE_ATTENTION_TIME}
        description={t('Average time spent by a users on the project')}
        isInteger={false}
        axisLeftFormat={ms => {
            const result = msFormat(ms)
            const parts = result.split(' ')
            if (parts.length <= 1) {
                return (parseFloat(result) | 0).toString()
            }

            if (parseFloat(parts[0]) > 10) {
                parts[0] = parseFloat(parts[0]) | 0
            }
            parts[1] = parts[1].substring(0, 1)

            return parts.join('')
        }}
        dateRange={dateRange}
    />
)

export default AverageAttentionTime
