import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import helpIcon from './i/help.png'
import youtubeIcon from './i/youtube.svg'

import styles from './Description.module.scss'

const Description = ({ title, subTitle, width = 300, isAddContacts, className }) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.description, { [className]: !!className })} style={{ width }}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
            {isAddContacts && (
                <ul className={styles.contactsList}>
                    <li className={styles.contact}>
                        <div className={styles.contactIconBox}>
                            <img className={styles.contactIcon} src={helpIcon} alt="help" />
                        </div>
                        <a className={styles.contactLink} target="_blank" href="https://help.interacty.me/">
                            {t('Visit our Help Center')}
                        </a>
                    </li>
                    <li className={styles.contact}>
                        <div className={styles.contactIconBox}>
                            <img className={styles.contactIcon} src={youtubeIcon} alt="help" />
                        </div>
                        <a className={styles.contactLink} target="_blank" href="https://www.youtube.com/@Interacty">
                            {t('Subscribe to our YouTube channel')}
                        </a>
                    </li>
                </ul>
            )}
        </div>
    )
}

export default Description
