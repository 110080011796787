import React, { useEffect, useState } from 'react'

import Image from 'components/Form/Image.jsx'
import Tooltip from 'components/Tooltip/Tooltip'

import upArrow from './i/up-arrow.svg'
import rightArrow from './i/right-arrow.svg'
import downArrow from './i/down-arrow.svg'
import leftArrow from './i/left-arrow.svg'

import './PaddingEditor.scss'

const MIN_PADDING = 0
const MAX_PADDING = 99
const PADDING_INDEX_DIRECTION = {
    top: 0,
    right: 1,
    bottom: 2,
    left: 3,
}
const PADDING_DIRECTION_PREFIX = {
    [PADDING_INDEX_DIRECTION.top]: downArrow,
    [PADDING_INDEX_DIRECTION.right]: leftArrow,
    [PADDING_INDEX_DIRECTION.bottom]: upArrow,
    [PADDING_INDEX_DIRECTION.left]: rightArrow,
}
const PaddingEditor = ({
    value,
    onUpdate,
    name,
    id,
    payload,
    tooltipText,
    descriptionText,
    schema: { label = '' },
}) => {
    const [_value, _setValue] = useState(() => {
        if (typeof value === 'string') {
            return value.split(' ').map(val => parseInt(val, 10))
        }
        return [0, 0, 0, 0]
    })

    useEffect(() => {
        if (typeof value === 'string') {
            _setValue(value.split(' ').map(val => parseInt(val, 10)))
            return
        }
        _setValue([0, 0, 0, 0])
    }, [value])

    const onChange = (newValue, index) => {
        if (!newValue && typeof newValue === 'string') {
            const newValueArray = _value.map((val, i) => (i === index ? null : val))
            _setValue(newValueArray)
        } else {
            if (newValue < MIN_PADDING) return
            if (newValue > MAX_PADDING) return
            const newValueArray = _value.map((val, i) => (i === index ? Number(newValue) : val))
            _setValue(newValueArray)
            const cssViewValue = newValueArray.map(val => `${val}px`).join(' ')
            onUpdate(id, { [name]: cssViewValue }, payload)
        }
    }

    return (
        <div className="control-box">
            {!!label.length && (
                <p className="label">
                    {label}
                    {<Tooltip tooltipText={tooltipText} />}
                </p>
            )}
            <div className="content padding-editor">
                {_value.map((val, index) => (
                    <div key={index} className="padding-editor__input-box">
                        <Image className="prefix" src={PADDING_DIRECTION_PREFIX[index]} />
                        <input
                            type="number"
                            min={MIN_PADDING}
                            max={MAX_PADDING}
                            value={val}
                            onChange={evt => onChange(evt.target.value, index)}
                            onBlur={() => {
                                if (!val) onChange(0, index)
                            }}
                        />
                    </div>
                ))}
            </div>
            {!!descriptionText && <p className="description">{descriptionText}</p>}
        </div>
    )
}

export default PaddingEditor
