import React from 'react'
import classNames from 'classnames'
import { default as RcTooltip } from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

import './Tooltip.scss'

const Tooltip = ({ text, tooltipText, onClick, position = 'bottomLeft' }) => {
    const handleTooltipIconClick = evt => {
        if (onClick) {
            evt.stopPropagation()
            onClick(evt)
        }
    }

    if (!tooltipText) return null

    return (
        <RcTooltip
            placement={position}
            overlay={
                typeof tooltipText === 'string' ? <span className="tooltip__box">{tooltipText}</span> : tooltipText
            }
        >
            <span className={classNames('tooltip', { clickable: !!onClick })} onClick={handleTooltipIconClick}>
                {text ? text : <span className="tooltip-controls-icon" />}
            </span>
        </RcTooltip>
    )
}

export const TooltipWrapper = ({
    tooltipText,
    position = 'bottomLeft',
    isHidden = false,
    mouseEnterDelay = 0,
    children,
}) => {
    const props = {
        placement: position,
        overlay: <span className="tooltip__box">{tooltipText}</span>,
        mouseEnterDelay: mouseEnterDelay,
    }
    if (isHidden) props.visible = false

    return <RcTooltip {...props}>{children}</RcTooltip>
}

export default Tooltip
