import React from 'react'
import { useTranslation } from 'react-i18next'

import checkmarkIcon from './i/checkmark.svg'

import styles from './StripeStatusBadge.module.scss'

const StripeStatusBadge = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.stripeStatusBadge}>
            <img src={checkmarkIcon} alt="checkmark" />
            {t('Stripe connected')}
        </div>
    )
}

export default StripeStatusBadge
