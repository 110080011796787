import React from 'react'
import { useTranslation } from 'react-i18next'

import Select, { VARIANT } from 'components/Form/Select/Select'

import styles from './FormSelect.module.scss'

const FormSelect = ({ ...props }) => {
    const { t } = useTranslation()

    return (
        <Select
            {...props}
            placeholder={t('Select')}
            variant={VARIANT.increased}
            labelClassName={styles.label}
            fullWide
            isSearchable
        />
    )
}

export default FormSelect
