import React from 'react'
import { useTranslation } from 'react-i18next'

import { getDate } from 'utils/dateTime'
import { getFullUrlToSsr } from 'utils/router'

import useRouter from 'hooks/useRouter'

import { API__PAYMENTS } from 'api'

import { COLUMNS_DATA, COLUMNS } from '../../constants'

import Column from './components/Column/Column'

import styles from './Row.module.scss'

const Row = ({ userId, data }) => {
    const { t } = useTranslation()
    const { historyPush } = useRouter()

    return (
        <li className={styles.row}>
            {Object.values(COLUMNS).map(column => (
                <Column data={COLUMNS_DATA[column]}>
                    {column === COLUMNS.DATE && <div className={styles.date}>{getDate(data.purchaseDate)}</div>}
                    {column === COLUMNS.PRODUCT_NAME && (
                        <div className={styles.productName}>
                            <div className={styles.productImage} style={{ backgroundImage: `url(${data.imageUrl})` }} />
                            <div className={styles.texts}>
                                <p className={styles.title}>{data.productName}</p>
                                <div className={styles.productPageLink}>
                                    <a
                                        href={getFullUrlToSsr(`/author-templates/${data.contentId}`)}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t('Product page')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    {column === COLUMNS.TYPE && <div className={styles.type}>{t('Template')}</div>}
                    {column === COLUMNS.TOTAL && <div className={styles.total}>${data.total / 100}</div>}
                    {column === COLUMNS.ACTIONS && (
                        <button
                            className="button"
                            data-variant="primary"
                            data-size="lg"
                            onClick={() =>
                                historyPush(
                                    `/editor?templateId=${data.content}&profileId=${data.authorId}&contentId=${data.contentId}`,
                                )
                            }
                        >
                            {t('Use template')}
                        </button>
                    )}
                    {column === COLUMNS.INVOICE && !!data.invoiceId && (
                        <div
                            className={styles.invoice}
                            onClick={async () => await API__PAYMENTS.GET_PUBLIC_CONTENT_INVOICE(userId, data.invoiceId)}
                        >
                            {t('View invoice')}
                        </div>
                    )}
                </Column>
            ))}
        </li>
    )
}

export default Row
