import axios from 'axios'

import store from 'store'

import { API__AUTH } from 'api'

import { MODAL_MANAGER_SET_MODAL } from 'store/actions/modalManager'

import { MODALS } from 'components/ModalManager/constants'

const INSTANCE = axios.create({
    baseURL: '/api',
    timeout: 30000,
    withCredentials: true,
})

INSTANCE.interceptors.request.use(
    config => {
        const { language } = store.getState()
        config.headers['Accept-Language'] = language

        return config
    },
    async err => {
        return Promise.reject(err)
    },
)
INSTANCE.interceptors.response.use(
    async res => {
        return res.data
    },
    async err => {
        if (err.response) {
            if (isUnauthorizedError(err.response)) await API__AUTH.LOGOUT()
            if (isPaymentRequiredError(err.response)) {
                if (err.response.data?.kind?.code === 46)
                    store.dispatch(MODAL_MANAGER_SET_MODAL(MODALS.STORAGE_LIMITS_MODAL))
                if (err.response.data?.kind?.code === 47)
                    store.dispatch(MODAL_MANAGER_SET_MODAL(MODALS.PROJECTS_LIMITS_MODAL))
            }
        }
        return Promise.reject(err)
    },
)

const isUnauthorizedError = ({ status }) => status === 401
const isPaymentRequiredError = ({ status }) => status === 402

export default INSTANCE
