import React from 'react'
import { useTranslation } from 'react-i18next'

import womanWithGift from './i/womanWithGift.svg'

import './TrialActivatedModal.scss'

const TrialActivatedModal = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <div className="trial-activated-modal">
            <div className="title">{t('Free trial')}</div>
            <img src={womanWithGift} alt="" />
            <p>{t('Free period activated for 7 days')}</p>
            <div className="button-container">
                <button className="ok-button" onClick={onClose}>
                    {t('OK')}
                </button>
            </div>
        </div>
    )
}

export default TrialActivatedModal
