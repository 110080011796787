import React from 'react'
import Image from 'components/Form/Image'

import './Preview.scss'

const Preview = ({ headerText = '', descriptionText = '', imageSrc = '', imageDisclaimerText = '' }) => {
    return (
        <div className="lead-form-final-review">
            {imageSrc.length ? <Image className="image" src={imageSrc} /> : null}
            {headerText.length ? <div className="header">{headerText}</div> : null}
            {descriptionText.length ? <div className="description">{descriptionText}</div> : null}
            {imageSrc.length && imageDisclaimerText.length ? (
                <div className="image-disclaimer">{imageDisclaimerText}</div>
            ) : null}
        </div>
    )
}

export default Preview
