function checkAccess(type) {
    try {
        let storage = window[type]
        let x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    } catch (e) {
        return false
    }
}

const _global = {}

function getValue(key, castTo = null) {
    let result
    if (checkAccess('localStorage')) result = window.localStorage.getItem(key)
    else if (checkAccess('sessionStorage')) result = window.sessionStorage.getItem(key)
    else {
        result = _global[key]
    }

    return castTo && castTo === Object ? JSON.parse(result) : result
}

function setValue(key, value) {
    let _value = value
    if (_value instanceof Object) _value = JSON.stringify(value)
    window.localStorage.setItem(key, _value)
}

export default {
    checkAccess,
    getValue,
    setValue,
}
