import React from 'react'

import PasswordInput from 'components/Form/TextInput/Wrappers/PasswordInput/PasswordInput'

import styles from './FormPasswordInput.module.scss'

const FormPasswordInput = ({ ...props }) => (
    <PasswordInput {...props} inputClassName={styles.input} labelClassName={styles.label} />
)

export default FormPasswordInput
