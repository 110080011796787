import React from 'react'

export function FolderIcon({ isLoading }) {
    return (
        <svg
            style={{ flexShrink: 0 }}
            width="28"
            height="26"
            viewBox="0 0 28 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 1C0 0.447716 0.447715 0 1 0H8.2093C8.54669 0 8.86133 0.170125 9.04607 0.452438L11.5164 4.22756C11.7012 4.50987 12.0158 4.68 12.3532 4.68H27C27.5523 4.68 28 5.12772 28 5.68V22C28 24.2091 26.2091 26 24 26H4C1.79086 26 0 24.2091 0 22V1Z"
                fill={isLoading ? '#f6f6f6' : '#416FF2'}
            />
            <path
                d="M2.625 2.6001H9.46094H11.8535H24.5V15.1668H2.625V2.6001Z"
                fill={isLoading ? '#f6f6f6' : '#ECF0F3'}
            />
            <path
                d="M0 4.7666H8.75H11.8125H27C27.5523 4.7666 28 5.21432 28 5.7666V24.9999C28 25.5522 27.5523 25.9999 27 25.9999H1C0.447715 25.9999 0 25.5522 0 24.9999V4.7666Z"
                fill={isLoading ? '#f6f6f6' : '#5694F7'}
            />
        </svg>
    )
}
