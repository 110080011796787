import i18n from 'i18n'

import { getUniqueId } from 'common/utils/getUniqueId'

import { CARD_PROPORTIONS } from 'components/Form/CardProportions/constants'

import { TABS, DEFAULT_CARDS_COUNT, DEFAULT_CARD_TYPE, DEFAULT_IMAGE_URL } from './constants'

export class Playground {
    cardsCount // <Number>
    cardType // <String> Card type
    cardProportions // <String> Card proportions

    constructor({
        cardsCount = DEFAULT_CARDS_COUNT,
        cardType = DEFAULT_CARD_TYPE,
        cardProportions = CARD_PROPORTIONS[0].value,
    } = {}) {
        this.cardsCount = cardsCount
        this.cardType = cardType
        this.cardProportions = cardProportions
    }

    static get cardsCount() {
        return 'cardsCount'
    }

    static get cardType() {
        return 'cardType'
    }

    static get cardProportions() {
        return 'cardProportions'
    }
}

export class Card {
    id // <String> ID
    text // <String> Text
    imageUrl // <String> Image URL
    description // <String> Description

    constructor({ number, imageUrl = DEFAULT_IMAGE_URL, description = '' } = {}) {
        this.id = getUniqueId()
        this.text = i18n.t('Card') + (number ? ` ${number}` : '')
        this.imageUrl = imageUrl
        this.description = description
    }

    static get id() {
        return 'id'
    }

    static get text() {
        return 'text'
    }

    static get imageUrl() {
        return 'imageUrl'
    }

    static get description() {
        return 'description'
    }
}

export const BLOCK_KEYS = {
    PLAYGROUND: 'playground',
    CARDS: 'cards',
}

export const BLOCK_KEYS_BY_TABS = {
    [TABS.PLAYGROUND]: BLOCK_KEYS.PLAYGROUND,
    [TABS.CARDS]: BLOCK_KEYS.CARDS,
}

export function createCards(count = 1, fromNumber = 1) {
    return [...Array(count).keys()].map(index => new Card({ number: index + fromNumber }))
}

export function createRankBattleBlock() {
    return {
        [BLOCK_KEYS.PLAYGROUND]: new Playground(),
        [BLOCK_KEYS.CARDS]: [...Array(DEFAULT_CARDS_COUNT).keys()].map(i => i + 1).map(number => new Card({ number })),
    }
}
