import { blocksGetter, getterValue, arrayItemRules } from 'common/utils/getterService.js'
import BLOCK from 'common/constants/BlockTypes/BlocksEnum'

const answerRules = {
    text: [getterValue()],
    imageLabel: [getterValue()],
    description: [getterValue()],
    imageDescription: [getterValue()],
}

const questionRules = {
    text: [getterValue()],
    imageDisclaimer: [getterValue()],
    answers: [arrayItemRules(answerRules)],
}

const resultRules = {
    header: [getterValue()],
    description: [getterValue()],
    imageDisclaimer: [getterValue()],
}

const pinsRules = {
    btext: [getterValue()],
    d: [getterValue()],
    h: [getterValue()],
}

const pairRules = {
    description: [getterValue()],
    firstImage: { text: [getterValue()] },
    secondImage: { text: [getterValue()] },
}

const tilesRules = {
    buttonText: [getterValue()],
    quest: [getterValue()],
    questDescription: [getterValue()],
}

const cardRules = {
    description: [getterValue()],
    disclaimer: [getterValue()],
    header: [getterValue()],
}

const slideRules = {
    description: [getterValue()],
    imageCaption: [getterValue()],
    header: [getterValue()],
}

const leadFormFieldsRules = {
    label: [getterValue()],
}

const leadFormRules = {
    final: {
        descriptionText: [getterValue()],
        headerText: [getterValue()],
        imageDisclaimerText: [getterValue()],
    },
    form: {
        buttonText: [getterValue()],
        descriptionText: [getterValue()],
        headerText: [getterValue()],
        fields: [arrayItemRules(leadFormFieldsRules)],
    },
}

const timeIsUpScreenRules = {
    actionButtonText: [getterValue()],
    description: [getterValue()],
    header: [getterValue()],
    imageDisclaimer: [getterValue()],
}

const memoryRules = {
    actionButtonText: [getterValue()],
    legalStatement: [getterValue()],
    struct: {
        playground: {
            coverButtonText: [getterValue()],
            coverHeader: [getterValue()],
        },
        finalScreen: {
            description: [getterValue()],
            header: [getterValue()],
            imageDisclaimer: [getterValue()],
        },
        pairs: {
            pairList: [arrayItemRules(pairRules)],
        },
    },
    timeIsUpScreen: timeIsUpScreenRules,
    leadFormStruct: leadFormRules,
}

export const BLOCKS_TEXT_SCHEME = {
    [BLOCK.text]: { text: [getterValue()] },
    [BLOCK.flipCards]: {
        backText: [getterValue()],
        frontText: [getterValue()],
    },
    [BLOCK.button]: { text: [getterValue()] },
    [BLOCK.interactiveImage]: {
        pins: [arrayItemRules(pinsRules)],
    },
    [BLOCK.hiddenObjects]: {
        coverBtnText: [getterValue()],
        coverHeader: [getterValue()],
        legalStatement: [getterValue()],
        sucBtext: [getterValue()],
        sucd: [getterValue()],
        suct: [getterValue()],
        pins: [arrayItemRules(pinsRules)],
        timeIsUpScreen: timeIsUpScreenRules,
        leadFormStruct: leadFormRules,
    },
    [BLOCK.quiz]: {
        callToActionText: [getterValue()],
        leadFormStruct: leadFormRules,
        struct: {
            cover: {
                header: [getterValue()],
                description: [getterValue()],
                buttonText: [getterValue()],
                imageDisclaimer: [getterValue()],
            },
            questions: [arrayItemRules(questionRules)],
            results: [arrayItemRules(resultRules)],
        },
    },
    [BLOCK.memoryCards]: memoryRules,
    [BLOCK.timeline]: {
        imageDescription: [getterValue()],
        markSubtitle: [getterValue()],
        markTitle: [getterValue()],
        text: [getterValue()],
    },
    [BLOCK.cookies]: {
        callToActionText: [getterValue()],
        struct: {
            cards: [arrayItemRules(cardRules)],
        },
        leadFormStruct: leadFormRules,
    },
    [BLOCK.horoscope]: {
        callToActionText: [getterValue()],
        struct: {
            cards: [arrayItemRules(cardRules)],
        },
        leadFormStruct: leadFormRules,
    },
    [BLOCK.personalityQuiz]: {
        callToActionText: [getterValue()],
        struct: {
            cover: {
                header: [getterValue()],
                description: [getterValue()],
                buttonText: [getterValue()],
                imageDisclaimer: [getterValue()],
            },
            questions: [arrayItemRules(questionRules)],
            results: [arrayItemRules(resultRules)],
        },
        leadFormStruct: leadFormRules,
    },
    [BLOCK.puzzle]: {
        actionButtonText: [getterValue()],
        legalStatement: [getterValue()],
        struct: {
            playground: {
                coverButtonText: [getterValue()],
                coverHeader: [getterValue()],
            },
            finalScreen: {
                description: [getterValue()],
                header: [getterValue()],
                imageDisclaimer: [getterValue()],
            },
        },
        timeIsUpScreen: timeIsUpScreenRules,
        leadFormStruct: leadFormRules,
    },
    [BLOCK.leadForm]: {
        struct: leadFormRules,
    },
    [BLOCK.findPair]: memoryRules,
    [BLOCK.slideshow]: {
        struct: {
            slides: [arrayItemRules(slideRules)],
        },
    },
    [BLOCK.treasureHunt]: {
        actionButtonText: [getterValue()],
        struct: {
            finalScreen: {
                description: [getterValue()],
                header: [getterValue()],
                imageDisclaimer: [getterValue()],
            },
            tiles: {
                tileList: [arrayItemRules(tilesRules)],
            },
        },
        leadFormStruct: leadFormRules,
    },
}

export const BLOCK_LIST_SCHEME = {
    blocks: [blocksGetter(BLOCKS_TEXT_SCHEME)],
}
