import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { API__USER } from 'api'

import Toast from 'components/Toast/Toast'

import i18n from 'i18n'

import useUserPublicProfile from 'hooks/useUserPublicProfile'
import useInitialLoading from 'hooks/useInitialLoading'

import { getDate, getDateTime } from 'utils/dateTime'
import { getFullUrlToSsr } from 'utils/router'

import Card from './components/Card/Card'
import ActivityList from './components/ActivityList/ActivityList'
import ActivityItem from './components/ActivityItem/ActivityItem'
import StripeStatusBadge from './components/StripeStatusBadge/StripeStatusBadge'
import NoData from './components/NoData/NoData'
import DateSelect from './components/DateSelect/DateSelect'
import PayoutsDisabled from './components/PayoutsDisabled/PayoutsDisabled'
import Info from './components/Info/Info'
import PayoutText from './components/PayoutText/PayoutText'
import EarnText from './components/EarnText/EarnText'
import Table from './components/Table/Table'
import ServiceCommission from './components/ServiceCommission/ServiceCommission'

import profileViewsIcon from './i/profileViews.svg'
import projectViewsIcon from './i/projectViews.svg'
import cloneIcon from './i/clone.svg'
// import followersIcon from './i/followers.svg'
import girlImage from './i/girl.png'
import manImage from './i/man.png'

import { DATES, DATES_OPTIONS, SERVICE_COMMISSION_PERCENTAGE } from './constants'

import { getPayoutStatusText, getPayoutStatusColor, getSumWithCommission } from './utils'

import newTabIcon from './i/newTab.svg'

import styles from './PublicPageStatistic.module.scss'

export const TABLE_SALES_COLUMNS = [
    {
        label: i18n.t('Date'),
        width: 25,
        component: item => getDate(item.purchaseDate),
    },
    {
        label: i18n.t('Project'),
        width: 55,
        component: item => (
            <>
                {item.publicContentTitle}
                <a
                    className={styles.tableIconLink}
                    href={getFullUrlToSsr(`/author-templates/${item.publicContentId}`)}
                    rel="noreferrer noopener"
                >
                    <img className={styles.tableIcon} src={newTabIcon} alt="" />
                </a>
            </>
        ),
    },
    {
        label: i18n.t('Amount'),
        width: 20,
        component: item => <div className={styles.tablePurchasePrice}>{item.purchasePrice / 100} USD</div>,
    },
]
export const TABLE_PAYOUTS_COLUMNS = [
    {
        label: i18n.t('Amount'),
        width: 30,
        component: item => <div className={styles.tablePayoutAmount}>{`$${item.amount / 100}`}</div>,
    },
    {
        label: i18n.t('Status'),
        width: 30,
        component: item => (
            <div className={styles.tableStatusText} style={{ color: getPayoutStatusColor(item.paymentStatus) }}>
                {getPayoutStatusText(item.paymentStatus)}
            </div>
        ),
    },
    {
        label: i18n.t('Date'),
        width: 40,
        component: item => getDateTime(item.created),
    },
]

const PublicPageStatistic = () => {
    const { t } = useTranslation()

    const { isReady, onReady } = useInitialLoading()

    const { userPublicProfile } = useUserPublicProfile()

    const { user_details } = useSelector(state => state)

    const [profileStatistic, setProfileStatistic] = useState(null)
    const [isPayoutsEnabled, setIsPayoutsEnabled] = useState(false)

    const [isLoadingSales, setIsLoadingSales] = useState(false)
    const [totalSalesSum, setTotalSalesSum] = useState(null)
    const [sales, setSales] = useState([])
    const [dateSales, setDateSales] = useState(DATES_OPTIONS[DATES.ALL_TIME])

    const [isLoadingPayouts, setIsLoadingPayouts] = useState(false)
    const [totalPayoutsSum, setTotalPayoutsSum] = useState(null)
    const [payouts, setPayouts] = useState([])
    const [datePayouts, setDatePayouts] = useState(DATES_OPTIONS[DATES.ALL_TIME])

    useEffect(() => {
        API__USER.GET_USER_PUBLIC_PROFILE_STATISTIC_ACTIVITY(user_details.id)
            .then(data => setProfileStatistic(data))
            .catch(err => {
                console.error(err)
                Toast('error', {})
            })
            .finally(() => onReady())
    }, [])

    const getSales = async () => {
        try {
            setIsLoadingSales(true)
            const data = await API__USER.GET_USER_PUBLIC_PROFILE_SALES(user_details.id, {
                ...dateSales.getTimesRange(),
            })
            setSales(data.sales.content)
            setTotalSalesSum(data.totalSalesSum)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoadingSales(false)
        }
    }
    const getPayouts = async () => {
        try {
            setIsLoadingPayouts(true)
            const data = await API__USER.GET_USER_PUBLIC_PROFILE_PAYOUTS(user_details.id, {
                ...datePayouts.getTimesRange(),
            })
            setPayouts(data.payouts.content)
            setTotalPayoutsSum(data.totalPayoutsSum)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoadingPayouts(false)
        }
    }

    useEffect(() => {
        if (userPublicProfile.payoutProfile?.payoutsEnabled) setIsPayoutsEnabled(true)
        else setIsPayoutsEnabled(false)
    }, [userPublicProfile.payoutProfile])

    useEffect(() => {
        getSales()
    }, [dateSales])
    useEffect(() => {
        getPayouts()
    }, [datePayouts])

    return (
        <>
            <Helmet>
                <title>{t('Public page stats')} | Interacty</title>
            </Helmet>
            {isReady && (
                <div className={styles.publicPageStatistic}>
                    <h1 className={styles.title}>{t('Dashboard')}</h1>
                    {profileStatistic && (
                        <Card title={t('Activity')}>
                            <ActivityList>
                                <ActivityItem
                                    icon={profileViewsIcon}
                                    value={profileStatistic.profileViews}
                                    label={t('Profile views')}
                                />
                                <ActivityItem
                                    icon={projectViewsIcon}
                                    value={profileStatistic.contentViews}
                                    label={t('Project views')}
                                />
                                <ActivityItem
                                    icon={cloneIcon}
                                    value={profileStatistic.templateClones}
                                    label={t('Template clones')}
                                />
                                {/*<ActivityItem icon={followersIcon} value={profileStatistic.followers} label={t('Followers')} />*/}
                            </ActivityList>
                        </Card>
                    )}
                    <div className={styles.cards}>
                        <Card
                            isLoading={isLoadingSales}
                            title={t('Sales history')}
                            isHalf
                            info={!payouts.length && <Info title={t('Your sales history will be displayed here.')} />}
                            control={
                                <DateSelect
                                    options={Object.values(DATES_OPTIONS)}
                                    value={dateSales}
                                    onChange={v => setDateSales(v)}
                                />
                            }
                        >
                            {!sales.length ? (
                                <NoData
                                    image={manImage}
                                    text={t('You have no sales by this date')}
                                    className={styles.noDateSales}
                                />
                            ) : (
                                <>
                                    <Table data={sales} columns={TABLE_SALES_COLUMNS} />
                                    <ServiceCommission
                                        commissionSum={getSumWithCommission(
                                            totalSalesSum,
                                            SERVICE_COMMISSION_PERCENTAGE,
                                        )}
                                    />
                                    <EarnText
                                        sum={getSumWithCommission(totalSalesSum, 100 - SERVICE_COMMISSION_PERCENTAGE)}
                                    />
                                </>
                            )}
                        </Card>
                        <Card
                            isLoading={isLoadingPayouts}
                            title={t('Payment history')}
                            isHalf
                            info={
                                <Info
                                    title={t('Your payout history will be displayed here.')}
                                    // linkText={t('Read about payment terms')}
                                    // linkUrl={'/'}
                                />
                            }
                            text={
                                isPayoutsEnabled &&
                                totalPayoutsSum && <PayoutText sum={totalPayoutsSum / 100} date={null} />
                            }
                            badge={isPayoutsEnabled && <StripeStatusBadge />}
                            select={
                                isPayoutsEnabled && (
                                    <div className={styles.payoutDateSelectBox}>
                                        <DateSelect
                                            options={Object.values(DATES_OPTIONS)}
                                            value={datePayouts}
                                            onChange={v => setDatePayouts(v)}
                                        />
                                    </div>
                                )
                            }
                        >
                            {isPayoutsEnabled ? (
                                <>
                                    {!payouts.length ? (
                                        <NoData
                                            image={girlImage}
                                            text={t('You have no payouts by this date')}
                                            className={styles.noDatePayouts}
                                        />
                                    ) : (
                                        <Table data={payouts} columns={TABLE_PAYOUTS_COLUMNS} />
                                    )}
                                </>
                            ) : (
                                <PayoutsDisabled />
                            )}
                        </Card>
                    </div>
                </div>
            )}
        </>
    )
}

export default PublicPageStatistic
