import React, { useRef } from 'react'

import useResize from 'hooks/useResize'

import Tile from './components/Tile/Tile'
import Questions from './components/Questions/Questions'

import useCrossword from './hooks/useCrossword'

import styles from './Playground.module.scss'

const Playground = ({ items = [], fontFamily, colorTheme = '#2990fb', letterColor = '#3c3c3c' }) => {
    const playgroundRef = useRef(null)
    const tilesContainerRef = useRef(null)
    const tilesListRef = useRef(null)
    const [playgroundWidth] = useResize(playgroundRef)
    const {
        tilesContainerWidth,
        borderWidth,
        fontSize,
        hintFontSize,
        colsCount,
        rowsCount,
        inputtableTilesDataByIndex,
    } = useCrossword(items, playgroundWidth)

    return (
        <div className={styles.playground} ref={playgroundRef}>
            <div className={styles.tilesContainer} ref={tilesContainerRef} style={{ maxWidth: tilesContainerWidth }}>
                <ul ref={tilesListRef} className={styles.tilesList} style={{ '--columnsCount': colsCount }}>
                    {new Array(colsCount * rowsCount).fill('').map((_, index) => (
                        <Tile
                            key={index}
                            borderWidth={borderWidth}
                            fontSize={fontSize}
                            hintFontSize={hintFontSize}
                            colorTheme={colorTheme}
                            data={inputtableTilesDataByIndex[index]}
                            letterColor={letterColor}
                            fontFamily={`"${fontFamily}", sans-serif`}
                            isHasLeftNeighbor={
                                index % colsCount === 0 ? false : !!inputtableTilesDataByIndex[index - 1]
                            }
                            isHasRightNeighbor={
                                index % colsCount === colsCount - 1 ? false : !!inputtableTilesDataByIndex[index + 1]
                            }
                            isHasUpNeighbor={!!inputtableTilesDataByIndex[index - colsCount]}
                            isHasDownNeighbor={!!inputtableTilesDataByIndex[index + colsCount]}
                        />
                    ))}
                </ul>
            </div>
            <Questions items={items} fontFamily={`"${fontFamily}", sans-serif`} />
        </div>
    )
}

export default Playground
