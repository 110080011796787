import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Image from 'components/Form/Image'

import { PERSONALITY_LINKS_ARRAY } from '../../utils/constants'

import './LinkBar.scss'

const LinkBar = ({ selectedElements, probability, tmpStructure, methods, t }) => {
    const [answerList, setAnswerList] = useState([])
    const [answerLinks, setAnswerLinks] = useState([])

    useEffect(() => {
        if (selectedElements) {
            const question = tmpStructure.questions.find(question => question.id === selectedElements.questionId)
            if (question) {
                setAnswerList(question.answers)
                const answer = question.answers.find(answer => answer.id === selectedElements.answerId)
                if (answer) {
                    setAnswerLinks(answer.links)
                }
            }
        }
    }, [selectedElements, tmpStructure.questions])

    const onUpdateLinks = (resultId, weight) => {
        const links = cloneDeep(answerLinks)
        const findLink = links.find(link => link.resultId === resultId)
        if (findLink) {
            findLink.weight = weight
        } else {
            links.push({ resultId, weight })
        }
        const newAnswerList = answerList.map(answer =>
            answer.id === selectedElements.answerId ? { ...answer, links } : answer,
        )

        methods.onUpdateLinks(newAnswerList)
    }

    const renderedLinks = resultId => {
        return PERSONALITY_LINKS_ARRAY.map((link, index) => {
            const findLink = answerLinks.find(link => link.resultId === resultId)
            return (
                <div
                    key={index}
                    className={`link-bar__block-button ${link.label} ${
                        (findLink && findLink.weight === link.weight) || (!findLink && link.weight === 0)
                            ? 'is-active'
                            : ''
                    }`}
                    onClick={() => onUpdateLinks(resultId, link.weight)}
                >
                    {link.label}
                </div>
            )
        })
    }

    return (
        <ScrollableBlock>
            <div className="link-bar">
                <h2 className="link-bar__title">{t('Setting up links')}</h2>
                {tmpStructure.results.map(result => {
                    return (
                        <div className={`link-bar__block ${!result.image ? 'no-image' : ''}`} key={result.id}>
                            {/*{typeof probability[result.id] === 'number' ? (*/}
                            {/*    <div className="link-bar__block-percentage">{probability[result.id]}%</div>*/}
                            {/*) : null}*/}
                            {result.image ? (
                                <div>
                                    <Image className="link-bar__block-img" src={result.image} />
                                    <h2 className="link-bar__block-title">{result.header}</h2>
                                </div>
                            ) : (
                                <div className="link-bar__block-title-wrapper">
                                    <h2 className="link-bar__block-title">{result.header}</h2>
                                    <p className="link-bar__block-content">{result.description}</p>
                                </div>
                            )}
                            <div className="link-bar__block-buttons">{renderedLinks(result.id)}</div>
                        </div>
                    )
                })}
            </div>
        </ScrollableBlock>
    )
}

export default LinkBar
