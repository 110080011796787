import React from 'react'
import classNames from 'classnames'

import styles from './FormButtons.module.scss'

const FormButtons = ({ className, children }) => (
    <div className={classNames(styles.formButtons, { [className]: !!className })}>{children}</div>
)

export default FormButtons
