import React from 'react'

import preloaderImage from './i/preloader.gif'

import './Groups.scss'

export default ({ list, isLoading, onSelect = () => {} }) => {
    return isLoading ? (
        <div className="user-role-modal__preloader">
            <img src={preloaderImage} alt="" />
        </div>
    ) : list.length ? (
        <ul className="user-role-modal__groups">
            {list.map(item => (
                <li key={item.id} data-test="sector-item" onClick={() => onSelect(item)}>
                    <div className="image" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>
                    <p>{item.groupTranslationDto.text}</p>
                </li>
            ))}
        </ul>
    ) : null
}
