import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ASSET_MANAGER__MANAGER_SET_TAB } from 'store/actions'

import AssetManager from './index'

let AssetManagerContext
const { Provider } = (AssetManagerContext = React.createContext())

const AssetManagerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [placeItem, setPlaceItem] = useState({ callback: null })
    const [settings, setSetting] = useState({})
    const { managerTab } = useSelector(state => state.assetManager)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!settings?.tab || settings.tab === managerTab) return
        dispatch(ASSET_MANAGER__MANAGER_SET_TAB(settings?.tab))
    }, [settings?.tab])

    return (
        <Provider value={{ setIsOpen, setPlaceItem, setSetting }}>
            <AssetManager
                isOpen={isOpen}
                onPlaceItem={placeItem.callback}
                onClose={() => setIsOpen(false)}
                settings={settings}
            />
            {children}
        </Provider>
    )
}

export { AssetManagerContext, AssetManagerProvider }
