import BLOCK from 'common/constants/BlockTypes/BlocksEnum'

import ACTIONS from 'shared/constants/actions'

export const getInitialYandexMetricStructure = blockType => {
    if (!Object.values(BLOCK).includes(blockType)) return {}

    if (
        [
            BLOCK.slidingPuzzle,
            BLOCK.crossword,
            BLOCK.findPair,
            BLOCK.hiddenObjects,
            BLOCK.matching,
            BLOCK.puzzle,
            BLOCK.memoryCards,
        ].includes(blockType)
    ) {
        return {
            [ACTIONS.GAME_OPENED]: '',
            [ACTIONS.GAME_STARTED]: '',
            [ACTIONS.LEAD_FORM_SUBMITTED]: '',
            [ACTIONS.GAME_COMPLETED]: '',
            [ACTIONS.TIME_IS_UP]: '',
            [ACTIONS.CTA_CLICK]: '',
            [ACTIONS.GAME_RESTARTED]: '',
            [ACTIONS.SEC_IN_GAME_5]: '',
            [ACTIONS.SEC_IN_GAME_10]: '',
        }
    }

    if (
        [
            BLOCK.quiz,
            BLOCK.cookies,
            BLOCK.horoscope,
            BLOCK.personalityQuiz,
            BLOCK.treasureHunt,
            BLOCK.wheelSpin,
        ].includes(blockType)
    ) {
        return {
            [ACTIONS.GAME_OPENED]: '',
            [ACTIONS.GAME_STARTED]: '',
            [ACTIONS.LEAD_FORM_SUBMITTED]: '',
            [ACTIONS.GAME_COMPLETED]: '',
            [ACTIONS.CTA_CLICK]: '',
            [ACTIONS.GAME_RESTARTED]: '',
            [ACTIONS.SEC_IN_GAME_5]: '',
            [ACTIONS.SEC_IN_GAME_10]: '',
        }
    }

    if ([BLOCK.rankBattle].includes(blockType)) {
        return {
            [ACTIONS.GAME_OPENED]: '',
            [ACTIONS.GAME_STARTED]: '',
            [ACTIONS.SEC_IN_GAME_5]: '',
            [ACTIONS.SEC_IN_GAME_10]: '',
        }
    }

    return {}
}
