import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
    ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID,
    ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID,
    ASSET_MANAGER__PIXABAY_LIBRARY__SEARCH,
    ASSET_MANAGER__PIXABAY_LIBRARY__UP_IMAGES_PAGE,
} from 'store/actions/asset_manager'

import ImageCropper from 'components/Modal/ImageCropper/ImageCropper'
import { getFileDataURLFromUrl } from 'utils/file'

import Search from '../../components/Search'
import PlaceBar from '../../components/PlaceBar'
import MediaGrid, { TYPES } from '../../components/MediaGrid/MediaGrid'

import { ASSET_TABS_INFO, ASSET_TABS } from '../../utils/constants'

import './PixabayLibrary.scss'

const PixabayLibrary = ({
    onPlaceItem,
    isCompressible,
    setIsLoading,
    onPlace,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [cropperImageData, setCropperImageData] = useState(null)
    const { list, selectedItemId, isLoadList, isLoadError } = useSelector(state => state.assetManager.library)

    const placeImage = useCallback(async () => {
        try {
            setIsLoading(true)

            const selectedItem = list.content.find(item => item.originalItemId === selectedItemId)
            if (!selectedItem) return

            const data = await getFileDataURLFromUrl(selectedItem.previewUrl)
            setCropperImageData(data)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }, [onPlaceItem, list, selectedItemId])

    return (
        <div className="pixabay-library">
            <div className="pixabay-library__header">
                <h3 className="pixabay-library__header_title">
                    <div className="pixabay-library__header_title--title">
                        {ASSET_TABS_INFO[ASSET_TABS.TAB_PIXABAY].title}
                    </div>
                    <div className="pixabay-library__header_title--note">
                        {ASSET_TABS_INFO[ASSET_TABS.TAB_PIXABAY].note}
                    </div>
                </h3>
                <p className="pixabay-library__header_description">
                    <Trans
                        i18nKey="Start with freely usable images from Pixabay library. Read more about the <lnk>Pixabay License</lnk>"
                        components={{
                            lnk: (
                                <a
                                    href="https://pixabay.com/service/license/"
                                    className="pixabay-library__link"
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </p>
            </div>
            <Search onSearchValueChange={value => dispatch(ASSET_MANAGER__PIXABAY_LIBRARY__SEARCH(value))} />
            <MediaGrid
                type={TYPES.PIXABAY}
                data={list.content}
                selectedId={selectedItemId}
                isLoadData={isLoadList}
                isCanLoadData={!list.last && !isLoadError}
                onUpPage={() => dispatch(ASSET_MANAGER__PIXABAY_LIBRARY__UP_IMAGES_PAGE())}
                onSelectImage={id => dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(id))}
                onUnselectImage={id => dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))}
            />
            {!!selectedItemId && <PlaceBar onClick={placeImage} buttonText={t('Next')} />}
            {!!cropperImageData && (
                <ImageCropper
                    data={cropperImageData}
                    setIsLoading={setIsLoading}
                    isCompressible={isCompressible}
                    onCancel={() => setCropperImageData(null)}
                    onPlace={image => {
                        onPlace(image)
                        setCropperImageData(null)
                    }}
                />
            )}
        </div>
    )
}

export default PixabayLibrary
