import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
    ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID,
    ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID,
    ASSET_MANAGER__UNSPLASH_LIBRARY__SEARCH,
    ASSET_MANAGER__UNSPLASH_LIBRARY__UP_IMAGES_PAGE,
} from 'store/actions/asset_manager'

import ImageCropper from 'components/Modal/ImageCropper/ImageCropper'
import { getFileDataURLFromUrl } from 'utils/file'

import Search from '../../components/Search'
import PlaceBar from '../../components/PlaceBar'
import MediaGrid from '../../components/MediaGrid/MediaGrid'

import { ASSET_TABS_INFO, ASSET_TABS } from '../../utils/constants'

import './UnsplashLibrary.scss'

const UnsplashLibrary = ({
    onPlaceItem,
    isCompressible,
    setIsLoading,
    onPlace,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [cropperImageData, setCropperImageData] = useState(null)
    const { list, selectedItemId, isLoadList, isLoadError } = useSelector(state => state.assetManager.library)

    const placeImage = useCallback(async () => {
        try {
            setIsLoading(true)
            const selectedItem = list.content.find(el => el.id === selectedItemId)
            if (!selectedItem) return
            const data = await getFileDataURLFromUrl(selectedItem.urls.regular || selectedItem.url)
            setCropperImageData(data)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }, [onPlaceItem, list, selectedItemId])

    return (
        <div className="unsplash-library">
            <div className="unsplash-library__header">
                <h3 className="unsplash-library__header_title">
                    <div className="unsplash-library__header_title--title">
                        {ASSET_TABS_INFO[ASSET_TABS.TAB_UNSPLASH_LIBRARY].title}
                    </div>
                    <div className="unsplash-library__header_title--note">
                        {ASSET_TABS_INFO[ASSET_TABS.TAB_UNSPLASH_LIBRARY].note}
                    </div>
                </h3>
                <p className="unsplash-library__header_description">
                    <Trans
                        i18nKey="Start with freely usable images from Unsplash library. Read more about the <lnk>Unsplash License</lnk>"
                        components={{
                            lnk: (
                                <a
                                    href="https://unsplash.com/license"
                                    className="unsplash-library__link"
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </p>
            </div>
            <Search onSearchValueChange={value => dispatch(ASSET_MANAGER__UNSPLASH_LIBRARY__SEARCH(value))} />
            <MediaGrid
                data={list.content}
                selectedId={selectedItemId}
                isLoadData={isLoadList}
                isCanLoadData={!list.last && !isLoadError}
                onUpPage={() => dispatch(ASSET_MANAGER__UNSPLASH_LIBRARY__UP_IMAGES_PAGE())}
                onSelectImage={id => dispatch(ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID(id))}
                onUnselectImage={id => dispatch(ASSET_MANAGER__LIBRARY__DEL_IF_EQUALS_SELECTED_ITEM_ID(id))}
            />
            {!!selectedItemId && (
                <PlaceBar onClick={placeImage} buttonText={t('Next')}>
                    <div className="unsplash-library__place_bar_description">
                        <p>{t('This image is provided by Unsplash.')}</p>
                        <p>
                            {t('By using it, you agree to comply with')}&nbsp;
                            <a className="unsplash-library__link" href="https://unsplash.com/terms" target="_blank">
                                {t("Unsplash's Terms. Learn more")}
                            </a>
                        </p>
                    </div>
                </PlaceBar>
            )}

            {!!cropperImageData && (
                <ImageCropper
                    data={cropperImageData}
                    setIsLoading={setIsLoading}
                    isCompressible={isCompressible}
                    onCancel={() => setCropperImageData(null)}
                    onPlace={image => {
                        onPlace(image)
                        setCropperImageData(null)
                    }}
                />
            )}
        </div>
    )
}

export default UnsplashLibrary
