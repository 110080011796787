export class PuzzleBoard {
    sideTilesCount
    board = []
    emptyTile = {
        x: 0,
        y: 0,
    }

    constructor(sideTilesCount) {
        this.sideTilesCount = sideTilesCount
    }

    generate = () => {
        this.board = new Array(this.sideTilesCount)
        for (let i = 0; i < this.sideTilesCount; ++i) {
            this.board[i] = new Array(this.sideTilesCount)
            for (let j = 0; j < this.sideTilesCount; ++j) {
                this.board[i][j] = {}
                this.board[i][j].x = i
                this.board[i][j].y = j
            }
        }
        this.initTiles()
        this.initEmpty()
        if (!this.isSolvable(this.sideTilesCount, this.emptyTile.y + 1)) {
            if (this.emptyTile.y === 0 && this.emptyTile.x <= 1) {
                this.swapTiles(
                    this.sideTilesCount - 2,
                    this.sideTilesCount - 1,
                    this.sideTilesCount - 1,
                    this.sideTilesCount - 1,
                )
            } else {
                this.swapTiles(0, 0, 1, 0)
            }
            this.initEmpty(this.sideTilesCount)
        }

        return this.getUseReadyArray()
    }

    getUseReadyArray = () => {
        const result = []
        for (let i = 0; i < this.sideTilesCount; ++i) {
            for (let j = 0; j < this.sideTilesCount; ++j) {
                const index = j * this.sideTilesCount + i
                const x = this.board[i][j].x
                const y = this.board[i][j].y

                result[index] = {
                    value: y * this.sideTilesCount + x + 1,
                    index,
                    positionX: x,
                    positionY: y,
                }
            }
        }
        return result
    }

    initTiles = () => {
        let i = this.sideTilesCount * this.sideTilesCount - 1
        while (i > 0) {
            const j = Math.floor(Math.random() * i)
            const xi = i % this.sideTilesCount
            const yi = Math.floor(i / this.sideTilesCount)
            const xj = j % this.sideTilesCount
            const yj = Math.floor(j / this.sideTilesCount)
            this.swapTiles(xi, yi, xj, yj)
            --i
        }
    }
    swapTiles = (i, j, k, l) => {
        let temp = this.board[i][j]
        this.board[i][j] = this.board[k][l]
        this.board[k][l] = temp
    }

    initEmpty = () => {
        for (let j = 0; j < this.sideTilesCount; ++j) {
            for (let i = 0; i < this.sideTilesCount; ++i) {
                if (this.board[i][j].x === this.sideTilesCount - 1 && this.board[i][j].y === this.sideTilesCount - 1) {
                    this.emptyTile.x = i
                    this.emptyTile.y = j
                }
            }
        }
    }

    isSolvable = (size, emptyRow) => {
        if (size % 2 === 1) {
            return this.sumInversions() % 2 === 0
        } else {
            return (this.sumInversions() + size - emptyRow) % 2 === 0
        }
    }

    sumInversions = () => {
        let inversions = 0
        for (let j = 0; j < this.sideTilesCount; ++j) {
            for (let i = 0; i < this.sideTilesCount; ++i) {
                inversions += this.countInversions(i, j, this.sideTilesCount)
            }
        }
        return inversions
    }

    countInversions = (i, j) => {
        let inversions = 0
        const tileNum = j * this.sideTilesCount + i
        const lastTile = this.sideTilesCount * this.sideTilesCount
        const tileValue = this.board[i][j].y * this.sideTilesCount + this.board[i][j].x
        for (let q = tileNum + 1; q < lastTile; ++q) {
            const k = q % this.sideTilesCount
            const l = Math.floor(q / this.sideTilesCount)

            const compValue = this.board[k][l].y * this.sideTilesCount + this.board[k][l].x
            if (tileValue > compValue && tileValue !== lastTile - 1) {
                ++inversions
            }
        }
        return inversions
    }
}
