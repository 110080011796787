export const TYPES = {
    DEFAULT: 'DEFAULT',
    MATCHING: 'MATCHING',
}

export const SETTINGS = {
    [TYPES.DEFAULT]: {},
    [TYPES.MATCHING]: {
        color: '#000',
        areaHeight: 106,
    },
}
