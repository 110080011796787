import React from 'react'

import styles from './LoaderEllipsis.module.scss'

const LoaderEllipsis = () => (
    <div className={styles.loaderEllipsis}>
        <div className={styles.ellipse} />
        <div className={styles.ellipse} />
        <div className={styles.ellipse} />
        <div className={styles.ellipse} />
    </div>
)

export default LoaderEllipsis
