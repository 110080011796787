import React, { useState } from 'react'

import WheelSpinModal from '../components/Modals/WheelSpinModal/WheelSpinModal'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

import Preview from './components/Preview/Preview'

const WheelSpin = ({ struct, colorTheme, colorScheme, fontFamily, onUpdate, id }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            <Preview
                colorTheme={colorTheme}
                colorScheme={colorScheme}
                fontFamily={fontFamily}
                sections={struct.sections}
                sectionType={struct.playground.sectionType}
                spinImageUrl={struct.playground.spinImageUrl}
                isShowCover={struct.playground.isShowCover}
                coverHeader={struct.playground.coverHeader}
                coverButtonText={struct.playground.coverButtonText}
            />

            {isShowModal && (
                <WheelSpinModal
                    data={{
                        structure: struct,
                        colorTheme,
                        colorScheme,
                        fontFamily,
                        id,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default WheelSpin
