const Minus = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_482)">
            <path d="M15.8334 10.8333H4.16669V9.16666H15.8334V10.8333Z" fill="#D8D8D8" />
        </g>
        <defs>
            <clipPath id="clip0_1_482">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default Minus
