import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { API__ANALYTICS } from 'api'

import Modal from 'components/Modal/Modal'

import { getDateTime } from 'utils/dateTime'

import { mapLeadInfo } from '../../../../../utils'

import { getColumnView } from '../utils'

import styles from './LeadInfoModal.module.scss'

const LeadInfoModal = ({ projectId, sessionId, blockProjection, dateRange, onClose, isRating }) => {
    const { t } = useTranslation()
    const [isReady, setIsReady] = useState(false)
    const [data, setData] = useState({})

    async function fetchData(_sessionId) {
        const query = isRating ? API__ANALYTICS.GET_DETAILED_RATING_INFO : API__ANALYTICS.GET_DETAILED_LEAD_INFO
        const result = await query({
            projectId,
            sessionId: _sessionId,
            blockId: blockProjection.blockId,
            from: dateRange.from,
            to: dateRange.to,
        })
        setData(mapLeadInfo(result, blockProjection))
        setIsReady(true)
    }

    useEffect(() => {
        if (sessionId) fetchData(sessionId)
    }, [sessionId])

    return (
        <>
            <Modal isShowCloseIcon onClose={onClose}>
                <div className={styles.leadInfoModal}>
                    <h2 className={styles.leadInfoModalHeader}>{t('Lead info')}</h2>
                    {isReady && (
                        <div className={styles.leadInfoModalBody}>
                            <p className={styles.leadInfoModalTimeStamp}>
                                {t('Lead Time')}: {getDateTime(data.created, 'LLLL')}
                            </p>
                            <ul className={styles.leadInfoModalFields}>
                                {data.fields.map(field => (
                                    <li key={field?.key} className={styles.leadInfoModalField}>
                                        {t(field?.label)}: {getColumnView(field)}
                                    </li>
                                ))}
                            </ul>

                            {data.gamificationDataArray && (
                                <div>
                                    <h3 className={styles.leadInfoModalBlockTitle}>{t('Leader board result')}</h3>
                                    <ul className={styles.leadInfoModalFields}>
                                        {data.gamificationDataArray.map((column, index) => (
                                            <li key={index} className={styles.leadInfoModalField}>
                                                {t(column.headerName)}: {getColumnView(column)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {data.quizzes &&
                                data.quizzes.map(
                                    quiz =>
                                        !!quiz?.quizResults.length &&
                                        !!quiz?.quizAnswers.length && (
                                            <div key={quiz?.blockId}>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Quiz result')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        <li className={styles.leadInfoModalField}>
                                                            {quiz?.quizResults.map((result, index) => (
                                                                <p key={result?.id + index}>{result?.header}</p>
                                                            ))}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Quiz answers')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        {quiz?.quizAnswers.map(question => (
                                                            <li
                                                                key={question?.id}
                                                                className={styles.leadInfoModalField}
                                                            >
                                                                <p>{question?.text}</p>
                                                                <div className={styles.leadInfoModalFieldDescription}>
                                                                    {question?.answers.map((answer, index) => (
                                                                        <p key={answer?.id + index}>{answer?.text}</p>
                                                                    ))}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ),
                                )}

                            {data.wheels &&
                                data.wheels.map(
                                    wheel =>
                                        !!wheel?.wheelSections.length && (
                                            <div key={wheel?.blockId}>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Wheel result')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        <li className={styles.leadInfoModalField}>
                                                            {wheel?.wheelSections.map(section => {
                                                                if (!section) return null
                                                                return (
                                                                    <p key={section.id}>
                                                                        {section.resultHeader}
                                                                        {!!section.resultDescription &&
                                                                            ` | ${section.resultDescription}`}
                                                                    </p>
                                                                )
                                                            })}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ),
                                )}

                            <div>
                                <h3 className={styles.leadInfoModalBlockTitle}>{t('Additional Information')}</h3>
                                <ul className={styles.leadInfoModalFields}>
                                    <li className={styles.leadInfoModalField}>
                                        <p>{t('Source')}</p>
                                        <p>{data.source}</p>
                                    </li>
                                    <li className={styles.leadInfoModalField}>
                                        <p>{t('IP address')}</p>
                                        <p>{data.ipAddress}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default LeadInfoModal
