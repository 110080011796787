import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import NavigationListBar from '../../../Controls/NavigationListBar/NavigationListBar'
import useNavigation from '../../../Controls/NavigationListBar/hooks/useNavigation'

import { MAX_CARDS } from '../cardService'
import CardEditorList from '../components/CardEditorList'

import './CardsView.scss'

const CardsView = ({ cards, pages, errors, methods }) => {
    const { t } = useTranslation()
    const {
        scrollableContentNodeRef,
        focusedElementId,
        scrollContainerToElement,
        onSetFocusedElementId,
    } = useNavigation('item_')

    return (
        <div className="cards-view">
            <aside className="cards-view__navigation">
                <NavigationListBar
                    titleOfItem={t('Card')}
                    focusedItemId={focusedElementId}
                    items={cards}
                    maxLength={MAX_CARDS}
                    onClickAddNew={methods.addNewCard}
                    onClickItem={scrollContainerToElement}
                />
            </aside>
            <div className="cards-view__body">
                <ScrollableBlock scrollableNodeRef={scrollableContentNodeRef}>
                    <div className="scrollbar-inner">
                        <CardEditorList
                            pages={pages}
                            cards={cards}
                            cardsErrors={errors}
                            titleOfItem={t('Card')}
                            onSetFocusedElementId={onSetFocusedElementId}
                            methods={methods}
                        />
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}

export default CardsView
