import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import pauseSvg from './i/pause.svg'
import playSvg from './i/play.svg'
import backSvg from './i/back.svg'

import styles from './Preview.module.scss'

const Preview = ({ name, onChangeName, onSave, isPlaying, onPause, onPlay, onBack }) => {
    const { t } = useTranslation()

    const [nameError, setNameError] = useState('')

    useEffect(() => {
        setNameError('')
    }, [name])

    const save = async () => {
        if (!name.trim()) {
            setNameError(t('This field is required'))
            return
        }
        if (name.trim().length > 128) {
            setNameError(t('Must be no more than {{max}} characters', { max: 128 }))
            return
        }

        await onSave()
    }

    return (
        <div className={styles.preview}>
            <div className={styles.back} onClick={onBack}>
                <img src={backSvg} alt="back" />
                {t('Back')}
            </div>

            <div className={styles.controlBox}>
                <div className={styles.control} onClick={() => (isPlaying ? onPause() : onPlay())}>
                    <img src={isPlaying ? pauseSvg : playSvg} alt={isPlaying ? 'pause' : 'play'} />
                </div>
                <p className={styles.controlNote}>{isPlaying ? t('Click to stop') : t('Click to listen')}</p>
            </div>

            <TextInput
                inputClassName={styles.nameInput}
                className={styles.nameWrapper}
                errorMessages={nameError ? [nameError] : []}
                label={t('File name')}
                value={name}
                onUpdate={value => onChangeName(value)}
            />
            <div className={styles.buttonWrapper}>
                <button
                    className={classNames('button', styles.uploadButton)}
                    data-variant="primary"
                    data-size="lg"
                    onClick={save}
                >
                    {t('Save')}
                </button>
            </div>
        </div>
    )
}

export default Preview
