import React from 'react'
import classNames from 'classnames'

import styles from './BlockTitle.module.scss'

const BlockTitle = ({ text, length, maxLength }) => (
    <div className={styles.block}>
        <h3 className={styles.title}>{text}</h3>
        {maxLength && (
            <div className={classNames(styles.count, { [styles.error]: length > maxLength })}>
                {length}/{maxLength}
            </div>
        )}
    </div>
)

export default BlockTitle
